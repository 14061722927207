import {
  ArrowDownIcon,
  ArrowSmRightIcon,
  ArrowUpIcon,
  LinkIcon,
  PencilAltIcon,
} from "@heroicons/react/outline";
import { compact } from "lodash";
import moment from "moment";
import pluralize from "pluralize";
import { PropsWithChildren, useState } from "react";
import { TbPlaylistAdd } from "react-icons/tb";
import {
  ArtifactType,
  GoalArtifactSidebarFragmentFragment,
  GoalScope,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import ArtifactCreationDialog from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import SidebarLabel from "@apps/artifact-sidebar/components/sidebar-label";
import SidebarRow from "@apps/artifact-sidebar/components/sidebar-row";
import ArtifactDueDate from "@apps/artifact/components/artifact-due-date";
import ArtifactTeams from "@apps/artifact/components/artifact-teams";
import GoalParticipantsDialog from "@apps/artifact/components/goal-participants-dialog";
import GoalScopeComponent from "@apps/artifact/components/goal-scope";
import GoalStartDate from "@apps/artifact/components/goal-start-date";
import GoalState from "@apps/artifact/components/goal-state";
import GoalVisibilityDropdown from "@apps/artifact/components/goal-visibility";
import CheckinDialog from "@apps/checkin-dialog/checkin-dialog";
import GoalAlignmentPickerDialogWithExistingGoal from "@apps/goal-alignment-dialog-picker/goal-alignment-dialog-picker-with-existing-goal";
import GoalProgress, {
  GoalProgressView,
} from "@apps/goal-progress/goal-progress";
import useLabel from "@apps/use-label/use-label";
import Avatars from "@components/avatar/avatars";
import Button, { buttonTheme } from "@components/button/button";
import Dropdown from "@components/dropdown/dropdown";
import GoalIcon from "@components/goal-icon/goal-icon";
import AppLink from "@components/link/link";
import Tooltip from "@components/tooltip/tooltip";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull, getProgressTypeLabel } from "@helpers/helpers";

import ArtifactSidebarAlignedGoalItem from "./aligned-goal-item";
import GoalEditValues from "./components/goal-edit-values";
import GoalKeyResultList from "./components/goal-key-result-list";

const SidebarContainer = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => (
  <div className={classNames("flex w-full flex-col gap-3 bg-white", className)}>
    {children}
  </div>
);

const GoalParticipantRow = ({
  participants,
  canUpdate,
  onClick,
  label = "Owner",
}: {
  participants: BasicUser[];
  canUpdate: boolean;
  onClick: () => void;
  label: string;
}) => (
  <SidebarRow>
    <SidebarLabel>{pluralize(label, 2)}</SidebarLabel>
    <div className="flex items-center gap-1 text-sm text-gray-600">
      <button
        className="flex items-center gap-1 px-2 py-1 -ml-0.5 group rounded hover:bg-gray-100"
        onClick={onClick}
        disabled={!canUpdate}
      >
        {participants.length > 0 && (
          <Avatars
            className={classNames(
              "flex items-center pl-1.5",
              participants.length === 1 && "w-5" // otherwise single avatar gets squished
            )}
            avatarClassName={classNames(
              "contrast-100 rounded-full ring-2 -ml-1.5 ring-white",
              "bg-gray-600 text-gray-200"
            )}
            extraClassName="w-5 h-5 rounded-full -ml-2 text-2xs flex items-center justify-center z-1 bg-gray-200"
            users={participants}
            max={2}
            size="5"
          />
        )}
        {participants.length === 0 && (
          <span className="text-gray-400">None</span>
        )}
        {participants.length === 1 && <span>{participants[0].name}</span>}
        {participants.length > 1 && (
          <span className="text-gray-400">
            {participants.length}{" "}
            {pluralize(label.toLowerCase(), participants.length)}
          </span>
        )}
        {canUpdate && (
          <div>
            <PencilAltIcon
              className="w-5 h-5 text-gray-400 flex-none opacity-0 group-hover:opacity-100"
              aria-label={`Edit ${label.toLowerCase()}s button`}
            />
          </div>
        )}
      </button>
    </div>
  </SidebarRow>
);

const ArtifactSidebarGoalSection = ({
  artifact,
}: {
  artifact: GoalArtifactSidebarFragmentFragment;
}) => {
  const label = useLabel();
  const [isShowingCheckinDialog, setIsShowingCheckinDialog] = useState(false);
  const [isEditingGoalValues, setIsEditingGoalValues] = useState(false);
  const [showParticipantDialog, setShowParticipantDialog] = useState(false);
  const [createGoalDialogAlignType, setCreateGoalDialogAlignType] = useState<
    undefined | "child" | "parent"
  >(undefined);
  const [isOpenGoalAlignmentDialog, setIsOpenGoalAlignmentDialog] = useState<
    undefined | "child" | "parent"
  >();
  const [childGoalAlignmentDialogRef, setChildGoalAlignmentPopoverRef] =
    useState<HTMLElement | null>(null);
  const [parentGoalAlignmentDialogRef, setParentGoalAlignmentPopoverRef] =
    useState<HTMLElement | null>(null);

  const childGoals = artifact?.childGoals
    ? assertEdgesNonNull(artifact.childGoals)
    : [];
  const topLevelGoal = artifact?.topLevelGoal;
  const owners = artifact?.owners ? assertEdgesNonNull(artifact.owners) : [];
  const contributors = artifact?.contributors
    ? assertEdgesNonNull(artifact.contributors)
    : [];

  return (
    <>
      {showParticipantDialog && (
        <GoalParticipantsDialog
          artifact={artifact}
          onClose={() => setShowParticipantDialog(false)}
        />
      )}
      {isShowingCheckinDialog && (
        <CheckinDialog
          artifact={artifact}
          onClose={() => setIsShowingCheckinDialog(false)}
        />
      )}
      {createGoalDialogAlignType !== undefined && (
        <ArtifactCreationDialog
          formOptions={{
            title: ``,
            artifactType: ArtifactType.Goal,
            parentGoal:
              createGoalDialogAlignType === "parent" && artifact.title
                ? {
                    value: artifact.id,
                    label: artifact.title || "",
                    htmlLabel: (
                      <div className="flex items-center gap-1">
                        <GoalIcon
                          scope={artifact.scope}
                          state={artifact.state}
                        />{" "}
                        {artifact.title}
                      </div>
                    ),
                  }
                : undefined,
            childGoals:
              createGoalDialogAlignType === "child" && artifact.title
                ? [
                    {
                      value: artifact.id,
                      label: artifact.title || "",
                      htmlLabel: (
                        <div className="flex items-center gap-1">
                          <GoalIcon
                            scope={artifact.scope}
                            state={artifact.state}
                          />{" "}
                          {artifact.title}
                        </div>
                      ),
                    },
                  ]
                : undefined,
          }}
          hideTypeSelector
          onClose={() => setCreateGoalDialogAlignType(undefined)}
        />
      )}
      <div className="flex flex-col bg-white">
        <SidebarContainer className="py-4">
          <SidebarRow>
            <SidebarLabel>Progress</SidebarLabel>
            <div className="flex-1 pl-0 flex gap-1.5 items-center">
              <GoalProgress
                artifact={artifact}
                goalProgressView={GoalProgressView.pillAndProgressBar}
                className="flex-1 px-2 py-1 hover:bg-gray-100 rounded-md"
              />
            </div>
          </SidebarRow>
          <SidebarRow>
            <SidebarLabel>Progress type</SidebarLabel>
            <div className="flex-1 flex items-center">
              <button
                className={classNames(
                  "text-sm text-gray-600 rounded-md px-2 py-1",
                  artifact.canUpdate.permission && "hover:bg-gray-100"
                )}
                disabled={!artifact.canUpdate.permission}
                onClick={() => setIsEditingGoalValues(true)}
              >
                {getProgressTypeLabel(artifact)}
              </button>
              {artifact.kpi && (
                <Tooltip text={`Connected to KPI: ${artifact.kpi.title}`}>
                  <div className="flex items-center">
                    <Button
                      theme={buttonTheme.iconGray}
                      to={`/kpis/${artifact.kpi.id}`}
                      icon
                    >
                      <LinkIcon className="w-4 h-4" />
                    </Button>
                  </div>
                </Tooltip>
              )}
              {isEditingGoalValues && (
                <GoalEditValues
                  artifact={artifact}
                  onClose={() => setIsEditingGoalValues(false)}
                />
              )}
            </div>
          </SidebarRow>
          <SidebarRow>
            <SidebarLabel>
              {label("goal", { capitalize: true })} type
            </SidebarLabel>
            <GoalScopeComponent artifact={artifact} className="" />
          </SidebarRow>
          <SidebarRow>
            <SidebarLabel>State</SidebarLabel>
            <GoalState artifact={artifact} className="text-sm" />
          </SidebarRow>
          <SidebarRow>
            <SidebarLabel>Visibility</SidebarLabel>
            <GoalVisibilityDropdown artifact={artifact} />
          </SidebarRow>
          <SidebarRow>
            <SidebarLabel>Dates</SidebarLabel>
            <div className="flex items-center text-sm tracking-tight gap-1">
              <div className="group">
                <GoalStartDate
                  artifact={artifact}
                  size="4"
                  alwaysShowCalendarIcon
                  className="text-sm py-1 px-2 gap-1.5 tracking-tight capitalize"
                  labelName="start date"
                  emptyValue="Start"
                  tooltipPrefix={artifact.startDate ? "Start date: " : ""}
                  maxDate={
                    artifact.dueDate ? moment(artifact.dueDate).toDate() : null
                  }
                />
              </div>
              <ArrowSmRightIcon className="h-4 w-4 text-gray-400" />
              <div className="group">
                <ArtifactDueDate
                  artifact={artifact}
                  size="4"
                  alwaysShowCalendarIcon
                  labelName="due date"
                  className="text-sm py-1 px-2 gap-1.5 tracking-tight capitalize"
                  emptyValue="Due"
                  tooltipPrefix={artifact.dueDate ? "Due date: " : ""}
                  minDate={
                    artifact.startDate
                      ? moment(artifact.startDate).toDate()
                      : null
                  }
                />
              </div>
            </div>
          </SidebarRow>
          <GoalParticipantRow
            participants={owners}
            canUpdate={artifact.canUpdate?.permission}
            onClick={() => {
              setShowParticipantDialog(true);
            }}
            label="Owner"
          />
          <GoalParticipantRow
            participants={contributors}
            canUpdate={artifact.canUpdate?.permission}
            onClick={() => {
              setShowParticipantDialog(true);
            }}
            label="Contributor"
          />
          {artifact.scope === GoalScope.Team && (
            <SidebarRow>
              <SidebarLabel>Teams</SidebarLabel>
              <div className="pl-1">
                <ArtifactTeams artifact={artifact} />
              </div>
            </SidebarRow>
          )}
        </SidebarContainer>

        <div className="px-4 sm:px-6 py-4 bg-white flex flex-col gap-4 items-start text-gray-700 border-t">
          <GoalKeyResultList goalArtifact={artifact} />
        </div>

        <div className="px-4 sm:px-6 pt-4 py-8 bg-white flex flex-col gap-4 items-start text-gray-700 border-t">
          <div className="w-full flex flex-col gap-6">
            <div className="flex flex-col gap-1">
              <div className="flex justify-between items-center mb-1">
                <div className="text-sm font-medium text-gray-800">
                  {label("goal", { capitalize: true })} Alignment
                </div>
                <div className="flex items-center gap-2 empty:hidden">
                  {(artifact.parentGoal ||
                    (artifact.childGoals?.edges || []).length > 0) && (
                    <AppLink
                      className="text-blue-link hover:underline text-xs tracking-tight mr-1"
                      to={`/goal-alignment?goalId=${artifact.id}`}
                    >
                      View full alignment
                    </AppLink>
                  )}
                </div>
              </div>

              {!artifact.parentGoal &&
                artifact.canUpdate.permission &&
                !artifact.parentGoal && (
                  <div className="mt-2 flex">
                    <Dropdown
                      options={compact([
                        {
                          label: `Create parent ${label("goal")}`,
                          onClick: () => setCreateGoalDialogAlignType("child"),
                        },
                        artifact.canUpdate.permission && {
                          label: `Align existing ${label("goal")}`,
                          onClick: () => setIsOpenGoalAlignmentDialog("parent"),
                        },
                      ])}
                    >
                      <button
                        className="text-gray-600 font-medium text-sm hover:underline flex items-center gap-1.5"
                        ref={setParentGoalAlignmentPopoverRef}
                      >
                        <TbPlaylistAdd className="w-5 h-5" />
                        Add parent {label("goal")}
                      </button>
                    </Dropdown>
                    {artifact.canUpdate.permission &&
                      isOpenGoalAlignmentDialog &&
                      parentGoalAlignmentDialogRef &&
                      isOpenGoalAlignmentDialog === "parent" && (
                        <GoalAlignmentPickerDialogWithExistingGoal
                          artifactId={artifact.id}
                          alignmentType="parent"
                          onClose={() =>
                            setIsOpenGoalAlignmentDialog(undefined)
                          }
                          externalReferenceElement={
                            parentGoalAlignmentDialogRef
                          }
                        />
                      )}
                  </div>
                )}

              <div className="empty:hidden relative">
                {topLevelGoal && topLevelGoal.id !== artifact.parentGoal?.id && (
                  <>
                    <ArtifactSidebarAlignedGoalItem
                      key={topLevelGoal.id}
                      artifact={artifact}
                      alignedGoal={topLevelGoal}
                      alignmentType="ancestor"
                    />
                    <div className="border-l-2 border-gray-300 border-dotted h-3 ml-[9px] -my-0.5 z-10 relative" />
                  </>
                )}
                {artifact.parentGoal && (
                  <>
                    <ArtifactSidebarAlignedGoalItem
                      artifact={artifact}
                      alignedGoal={artifact.parentGoal}
                      alignmentType="parent"
                    />
                    <ArrowUpIcon className="h-3 w-3 text-gray-400 ml-1 -my-1 z-10 relative" />
                  </>
                )}
                <ArtifactSidebarAlignedGoalItem
                  key={artifact.id}
                  artifact={artifact}
                  alignedGoal={artifact}
                />

                {childGoals.length > 0 && (
                  <ArrowDownIcon className="h-3 w-3 text-gray-400 ml-1 -my-1 z-10 relative" />
                )}
                {childGoals.length > 0 &&
                  childGoals.map((childGoal) => (
                    <>
                      <ArtifactSidebarAlignedGoalItem
                        key={childGoal.id}
                        artifact={artifact}
                        alignedGoal={childGoal}
                        alignmentType="child"
                      />
                      <div className="h-1 w-4" />
                    </>
                  ))}
              </div>
              {artifact.canUpdate.permission && (
                <div className="flex">
                  <Dropdown
                    options={compact([
                      {
                        label: `Create sub ${label("goal")}`,
                        onClick: () => setCreateGoalDialogAlignType("parent"),
                      },
                      artifact.canUpdate.permission && {
                        label: `Align existing ${label("goal")}`,
                        onClick: () => setIsOpenGoalAlignmentDialog("child"),
                      },
                    ])}
                  >
                    <button
                      className="text-gray-600 font-medium text-sm hover:underline flex items-center gap-1.5"
                      ref={setChildGoalAlignmentPopoverRef}
                    >
                      <TbPlaylistAdd className="w-5 h-5" />
                      Add sub {label("goal")}
                    </button>
                  </Dropdown>
                  {isOpenGoalAlignmentDialog &&
                    childGoalAlignmentDialogRef &&
                    isOpenGoalAlignmentDialog === "child" && (
                      <GoalAlignmentPickerDialogWithExistingGoal
                        artifactId={artifact.id}
                        alignmentType="child"
                        onClose={() => setIsOpenGoalAlignmentDialog(undefined)}
                        externalReferenceElement={childGoalAlignmentDialogRef}
                      />
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArtifactSidebarGoalSection;
