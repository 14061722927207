import { useMutation } from "@apollo/client";
import { PauseIcon, PencilIcon, TrashIcon } from "@heroicons/react/outline";
import { useCallback } from "react";
import { MdContentCopy } from "react-icons/md";
import {
  AssessmentType,
  ComplianceProgramState,
  DeleteProgramMutation,
  DeleteProgramMutationVariables,
  ExportComplianceProgramToCsvMutationMutation,
  ExportComplianceProgramToCsvMutationMutationVariables,
  GetComplianceProgramReportQuery,
  SaveComplianceProgramMutation,
  SaveComplianceProgramMutationVariables,
} from "types/graphql-schema";

import exportComplianceProgramToCsvMutation from "@apps/programs/graphql/export-compliance-program-to-csv-mutation";
import {
  currentOrganizationVar,
  currentUserVar,
  successNotificationVar,
} from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import { useLink } from "@components/link/link";
import { useNotificationError } from "@components/use-error/use-error";
import useConfirm from "@helpers/hooks/use-confirm";
import usePkParams from "@helpers/hooks/use-pk-params";

import createOrUpdateComplianceProgramMutation from "../graphql/create-or-update-compliance-program-mutation";
import deleteComplianceProgramMutation from "../graphql/delete-compliance-program-mutation";
import getComplianceProgramQuery from "../graphql/get-compliance-program-query";
import getComplianceProgramsQuery from "../graphql/get-compliance-programs-query";

export enum ProgramTabType {
  ASSESSMENTS = "assessments",
  SELF_ASSESSMENTS = "self-assessments",
  ONE_ON_ONES = "one-on-ones",
  SUMMARY = "summary",
}

export type ProgramTab = {
  type: ProgramTabType;
  key: string;
  assessmentGroup: number | null;
  assessmentType?: AssessmentType;
  selfAssessment?: boolean;
};

const ComplianceProgramHeaderButtons = ({
  complianceProgram,
}: {
  complianceProgram: NonNullable<
    GetComplianceProgramReportQuery["complianceProgram"]
  >;
}) => {
  const link = useLink();
  const currentUser = currentUserVar();
  const organization = currentOrganizationVar();
  const { onError } = useNotificationError();
  const complianceProgramId = usePkParams("complianceProgramId");

  const { ConfirmationDialog: ConfirmDeleteDialog, confirm: confirmDelete } =
    useConfirm(
      "Are you sure?",
      "Are you sure you want to delete this program? All assessment data will be deleted."
    );

  const [saveComplianceProgram, { loading: isPublishingProgram }] = useMutation<
    SaveComplianceProgramMutation,
    SaveComplianceProgramMutationVariables
  >(createOrUpdateComplianceProgramMutation);

  const [exportToCSV, { loading: isExportingProgram }] = useMutation<
    ExportComplianceProgramToCsvMutationMutation,
    ExportComplianceProgramToCsvMutationMutationVariables
  >(exportComplianceProgramToCsvMutation);

  const [deleteProgram, { loading: isDeletingProgram }] = useMutation<
    DeleteProgramMutation,
    DeleteProgramMutationVariables
  >(deleteComplianceProgramMutation);

  const publishProgram = useCallback(() => {
    saveComplianceProgram({
      variables: {
        complianceProgramId,
        state: ComplianceProgramState.Published,
        organizationId: organization.id,
      },
      onError,
      refetchQueries: [getComplianceProgramQuery],
      onCompleted: () =>
        successNotificationVar({
          title: "Program published",
        }),
    });
  }, [complianceProgramId, onError, organization, saveComplianceProgram]);

  const exportProgram = useCallback(() => {
    exportToCSV({ variables: { complianceProgramId }, onError });
    successNotificationVar({
      title: `CSV export will be emailed to ${currentUser.email}`,
    });
  }, [exportToCSV, complianceProgramId, onError, currentUser]);

  const handleDeleteProgram = useCallback(async () => {
    const confirm = await confirmDelete();
    if (!confirm) return;
    deleteProgram({
      variables: {
        complianceProgramId,
      },
      onError,
      refetchQueries: [getComplianceProgramsQuery],
      onCompleted: () => {
        successNotificationVar({ title: "Program deleted" });
        link.redirect(`/programs`);
      },
    });
  }, [confirmDelete, deleteProgram, complianceProgramId, onError, link]);

  const handlePauseProgram = useCallback(async () => {
    saveComplianceProgram({
      variables: {
        complianceProgramId,
        state: ComplianceProgramState.Paused,
      },
      onError,
      refetchQueries: [getComplianceProgramQuery],
      onCompleted: () =>
        successNotificationVar({
          title: "Program paused",
        }),
    });
  }, [saveComplianceProgram, complianceProgramId, onError]);

  return (
    <div className="flex items-center gap-2">
      <Button
        small
        tooltip={
          !complianceProgram.canUpdate?.permission
            ? complianceProgram.canUpdate?.reason ?? "Can't edit"
            : "Edit program"
        }
        disabled={
          !complianceProgram.canUpdate?.permission ||
          isPublishingProgram ||
          isDeletingProgram ||
          isExportingProgram
        }
        to={`/programs/${complianceProgram.id}/edit`}
      >
        <PencilIcon className="h-5 w-5" />
      </Button>
      <Button
        small
        disabled={
          isPublishingProgram || isDeletingProgram || isExportingProgram
        }
        tooltip="Clone program"
        to={`/programs/${complianceProgram.id}/duplicate`}
      >
        <MdContentCopy className="h-5 w-5" />
      </Button>
      <Button
        small
        theme={buttonTheme.redDanger}
        tooltip={
          !complianceProgram.canDelete?.permission
            ? complianceProgram.canDelete?.reason ?? "Can't edit"
            : "Delete program"
        }
        disabled={
          !complianceProgram.canDelete?.permission ||
          isPublishingProgram ||
          isDeletingProgram ||
          isExportingProgram
        }
        onClick={handleDeleteProgram}
      >
        <TrashIcon className="h-5 w-5" />
      </Button>
      {complianceProgram.state === ComplianceProgramState.Published && (
        <Button
          small
          className="hidden sm:flex"
          tooltip="Pause program"
          disabled={
            !complianceProgram.canUpdate?.permission ||
            isPublishingProgram ||
            isDeletingProgram ||
            isExportingProgram
          }
          onClick={handlePauseProgram}
        >
          <PauseIcon className="h-5 w-5" />
        </Button>
      )}
      {complianceProgram.state !== ComplianceProgramState.Published && (
        <Button
          small
          className="hidden sm:flex"
          disabled={isPublishingProgram}
          onClick={publishProgram}
          theme={buttonTheme.primary}
        >
          {complianceProgram.state === ComplianceProgramState.Draft
            ? "Publish"
            : "Resume"}
        </Button>
      )}
      {complianceProgram.state === ComplianceProgramState.Published && (
        <Button
          small
          disabled={isExportingProgram}
          onClick={exportProgram}
          theme={buttonTheme.primary}
        >
          Export to CSV
        </Button>
      )}
      <ConfirmDeleteDialog />
    </div>
  );
};
export default ComplianceProgramHeaderButtons;
