import { gql } from "@apollo/client";

export default gql`
  fragment GetAssessmentAsessmentGroupSection on AssessmentGroupSectionNode {
    id
    title
    description
    questions {
      edges {
        node {
          id
          question {
            ... on RangeAssessmentQuestionNode {
              id
              title
              description
              startValue
              endValue
              labels
              labelDescriptions
              isCommentMandatory
              responses
              questionType
              responseVisibility
            }
            ... on MultiChoiceAssessmentQuestionNode {
              id
              title
              description
              options
              optionDescriptions
              isCommentMandatory
              responses
              questionType
              responseVisibility
            }
            ... on TextAssessmentQuestionNode {
              id
              title
              description
              isCommentMandatory
              responses
              questionType
              responseVisibility
            }
          }
          weight
        }
      }
    }
  }
  query getAssessment($assessmentId: Int!, $organizationId: Int!) {
    assessment(assessmentId: $assessmentId) {
      id
      state
      submittedDatetime
      periodStartDate
      periodEndDate
      dueDate
      canUpdate {
        permission
        reason
      }
      complianceProgram {
        id
        title
      }
      template {
        id
        assessmentType
        delivery
        anonymity
        isQuestionWeightingEnabled
        questionSets {
          edges {
            node {
              id
              title
              sections {
                edges {
                  node {
                    ...GetAssessmentAsessmentGroupSection
                  }
                }
              }
            }
          }
        }
      }
      responder {
        id
        name
        avatar
      }
      target {
        id
        name
        email
        firstName
        avatar
        organizationSpecificInfo(organizationId: $organizationId) {
          position
          jobLevel
        }
      }
      answers {
        edges {
          node {
            ... on RangeAssessmentAnswerNode {
              id
              question {
                id
              }
              integerAnswer
              comment
            }
            ... on MultiChoiceAssessmentAnswerNode {
              id
              question {
                id
              }
              choices
              comment
            }
            ... on TextAssessmentAnswerNode {
              id
              question {
                id
              }
              textAnswer
              comment
            }
          }
        }
      }
      relatedMeetings {
        edges {
          node {
            id
            title
            meetingGroupId
            startDatetime
            endDatetime
            draft
          }
        }
      }
    }
  }
`;
