import { gql } from "@apollo/client";

import { OrganizationCareerTrackFragment } from "./get-organization-career-track-query";

export default gql`
  ${OrganizationCareerTrackFragment}
  mutation saveOrgWideCareerTrack(
    $careerTrackId: Int
    $organizationId: Int
    $teamId: Int
    $title: String
    $roles: [CareerTrackRoleInput]
    $additionalCompetencies: [Int]
  ) {
    createOrUpdateCareerTrack(
      careerTrackId: $careerTrackId
      organizationId: $organizationId
      teamId: $teamId
      title: $title
      roles: $roles
      additionalCompetencies: $additionalCompetencies
    ) {
      careerTrack {
        ...OrganizationCareerTrackFragment
      }
    }
  }
`;
