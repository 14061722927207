import { gql } from "@apollo/client";

export default gql`
  mutation saveAssessmentQuestion(
    $title: String
    $description: String
    $organizationId: Int
    $questionId: Int
    $startValue: Int
    $endValue: Int
    $isCommentMandatory: Boolean
    $labels: [String]
    $labelDescriptions: [String]
    $options: [String]
    $optionDescriptions: [String]
    $responses: AssessmentQuestionResponses
    $responseVisibility: AssessmentQuestionResponseVisibility
    $questionType: AssessmentQuestionType
    $archived: Boolean
    $categories: [String]
  ) {
    createOrUpdateAssessmentQuestion(
      organizationId: $organizationId
      questionId: $questionId
      title: $title
      description: $description
      startValue: $startValue
      endValue: $endValue
      isCommentMandatory: $isCommentMandatory
      labels: $labels
      labelDescriptions: $labelDescriptions
      options: $options
      optionDescriptions: $optionDescriptions
      responses: $responses
      responseVisibility: $responseVisibility
      questionType: $questionType
      archived: $archived
      categories: $categories
    ) {
      question {
        ... on RangeAssessmentQuestionNode {
          id
          title
          description
          archived
          answersExist
          isCommentMandatory
          responses
          responseVisibility
          questionType
          created
          assessmentType
          canUpdate {
            permission
            reason
          }
          canDelete {
            permission
            reason
          }
          creator {
            id
            name
          }
          startValue
          endValue
          labels
          labelDescriptions
        }
        ... on MultiChoiceAssessmentQuestionNode {
          id
          title
          description
          archived
          answersExist
          isCommentMandatory
          responses
          responseVisibility
          questionType
          created
          assessmentType
          canUpdate {
            permission
            reason
          }
          canDelete {
            permission
            reason
          }
          creator {
            id
            name
          }
          options
          optionDescriptions
        }
        ... on TextAssessmentQuestionNode {
          id
          title
          description
          archived
          answersExist
          isCommentMandatory
          responses
          responseVisibility
          questionType
          created
          assessmentType
          canUpdate {
            permission
            reason
          }
          canDelete {
            permission
            reason
          }
          creator {
            id
            name
          }
        }
      }
    }
  }
`;
