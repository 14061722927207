import { useCallback } from "react";

import MeetingOverview from "@apps/meetings/components/meetings-overview";
import { currentUserVar } from "@cache/cache";
import { useLink } from "@components/link/link";
import {
  UserComboboxOptionType,
  UserComboboxUserOption,
} from "@components/user-combobox/user-combobox-list";

const ExtensionMeetings = () => {
  const link = useLink();
  const currentUser = currentUserVar();
  const selectedUser: UserComboboxUserOption = {
    id: currentUser.id,
    name: currentUser.name,
    type: UserComboboxOptionType.USER,
  };

  const handleClickMeeting = useCallback(
    (meetingId: number, meetingGroupId: number) => {
      link.redirect(`/extension/meeting/${meetingGroupId}/${meetingId}`);
    },
    [link]
  );

  return (
    <div className="bg-white">
      <MeetingOverview
        selectedUser={selectedUser}
        embeddedInSidebar
        filterClassName="px-6"
        meetingListClassName="px-6"
        onClickMeetingLink={handleClickMeeting}
      />
    </div>
  );
};

export default ExtensionMeetings;
