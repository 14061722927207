import { gql } from "@apollo/client";

export default gql`
  fragment organizationCompetencyCriteria on CompetencyNode {
    criteria {
      edges {
        node {
          id
          content
          level
        }
      }
    }
  }
  fragment organizationCompetency on CompetencyNode {
    id
    title
    description
    appliesTo
    appliesToManagementRoles
    appliesToIcRoles
    appliesToTeams {
      edges {
        node {
          id
          title
        }
      }
    }
    appliesToCareerTracks {
      edges {
        node {
          id
        }
      }
    }
    criteriaUniqueness
    ...organizationCompetencyCriteria
  }
  query getOrganizationCompetencies($organizationId: Int!) {
    competencies(organizationId: $organizationId) {
      edges {
        node {
          ...organizationCompetency
        }
      }
    }
  }
`;
