import {
  AddonSession,
  MeetingInfo,
} from "@googleworkspace/meet-addons/meet.addons";
import { useEffect, useState } from "react";

import AppError from "@components/error/error";
import Loading from "@components/loading/loading";

import MeetAddonMeeting from "./meeting";

const MeetAddonMeetingMainStage = ({ session }: { session: AddonSession }) => {
  const [error, setError] = useState<Error | null>(null);
  const [meetingInfo, setMeetingInfo] = useState<MeetingInfo | null>(null);

  const handleSetUpMainStage = async () => {
    try {
      const mainStageClient = await session.createMainStageClient();
      mainStageClient
        .getMeetingInfo()
        .then((meetingInfo) => setMeetingInfo(meetingInfo));
    } catch (error) {
      if (error instanceof Error) {
        setError(error);
      } else {
        setError(new Error("Unknown error"));
      }
    }
  };

  useEffect(() => {
    handleSetUpMainStage();
  }, []);

  return error ? (
    <div className="p-4">
      <AppError
        title="Error rendering the side panel"
        description={error.message}
      />
    </div>
  ) : meetingInfo ? (
    <div>
      <MeetAddonMeeting meetingInfo={meetingInfo} />
    </div>
  ) : (
    <div className="text-center p-4">
      <Loading size={5}>Loading side panel...</Loading>
    </div>
  );
};

export default MeetAddonMeetingMainStage;
