import { gql } from "@apollo/client";

export default gql`
  mutation saveComplianceProgram(
    $complianceProgramId: Int
    $organizationId: Int
    $title: String
    $dueDate: Date
    $startDate: Date
    $periodStartDate: Date
    $periodEndDate: Date
    $removeProgramPeriod: Boolean
    $topicTemplates: [TopicTemplateComplianceRequirement]
    $assessmentTemplates: [AssessmentTemplateComplianceRequirement]
    $state: ComplianceProgramState
    $appliesTo: ComplianceProgramAppliesTo
    $appliesToTeams: [Int]
    $appliesToManagers: [Int]
    $appliesToUsers: [Int]
    $excludedUsers: [Int]
    $excludedHireDateAfter: Date
    $removeExcludedHireDateAfter: Boolean
    $recurrence: ComplianceProgramRecurrence
    $ongoing: Boolean
    $startDateOffset: Int
    $startDateDueDateOffset: Int
    $startDatePeriodStartOffset: Int
    $startDatePeriodEndOffset: Int
  ) {
    createOrUpdateComplianceProgram(
      complianceProgramId: $complianceProgramId
      organizationId: $organizationId
      title: $title
      dueDate: $dueDate
      startDate: $startDate
      periodStartDate: $periodStartDate
      periodEndDate: $periodEndDate
      removeProgramPeriod: $removeProgramPeriod
      topicTemplates: $topicTemplates
      assessmentTemplates: $assessmentTemplates
      state: $state
      appliesTo: $appliesTo
      appliesToTeams: $appliesToTeams
      appliesToManagers: $appliesToManagers
      appliesToUsers: $appliesToUsers
      excludedUsers: $excludedUsers
      excludedHireDateAfter: $excludedHireDateAfter
      removeExcludedHireDateAfter: $removeExcludedHireDateAfter
      recurrence: $recurrence
      ongoing: $ongoing
      startDateOffset: $startDateOffset
      startDateDueDateOffset: $startDateDueDateOffset
      startDatePeriodStartOffset: $startDatePeriodStartOffset
      startDatePeriodEndOffset: $startDatePeriodEndOffset
    ) {
      complianceProgram {
        id
      }
    }
  }
`;
