import { gql } from "@apollo/client";

export default gql`
  query getAssessmentGroup($assessmentGroupId: Int!) {
    assessmentGroup(assessmentGroupId: $assessmentGroupId) {
      id
      title
      isQuestionWeightingEnabled
      created
      creator {
        id
        name
      }
      canUpdate {
        permission
        reason
      }
      sections {
        edges {
          node {
            id
            title
            description
            questions {
              edges {
                node {
                  id
                  question {
                    id
                  }
                  weight
                }
              }
            }
          }
        }
      }
    }
  }
`;
