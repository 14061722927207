import { TrashIcon } from "@heroicons/react/outline";
import { useMemo, useState } from "react";
import { createPortal } from "react-dom";
import {
  CareerTrackRoleType,
  SaveOrgWideCareerTrackMutationVariables,
} from "types/graphql-schema";

import Avatar from "@components/avatar/avatar";
import Button, { buttonTheme } from "@components/button/button";
import Input from "@components/input/input";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";
import {
  UserComboboxOption,
  UserComboboxOptionType,
} from "@components/user-combobox/user-combobox-list";

import TeamMemberCombobox from "../../team-member-combobox";
import { CareerTrackFormRole } from "./career-track-form";

const RoleDetailModal = ({
  associatedTeam,
  careerTrackData,
  level,
  onClose,
  role,
  onRoleChange,
}: {
  associatedTeam: UserComboboxOption | null;
  careerTrackData: Omit<SaveOrgWideCareerTrackMutationVariables, "roles">;
  onClose: () => void;
  level: number;
  role: CareerTrackFormRole;
  onRoleChange: (role: CareerTrackFormRole) => void;
}) => {
  const [proposedRole, setProposedRole] = useState<CareerTrackFormRole>(role);
  const saveTooltip = useMemo(() => {
    if (!proposedRole.title?.trim()) return "Please enter a role name";
    return null;
  }, [proposedRole]);
  const canSave = !saveTooltip;

  return createPortal(
    <Modal open onClose={onClose}>
      <div className="p-6 flex flex-col">
        <ModalTitle>Role Details</ModalTitle>

        <div className="flex flex-col gap-2 text-sm mt-6">
          <div className="flex items-center">
            <div className="w-1/3 text-gray-500">Department</div>
            <div className="w-2/3">
              {associatedTeam &&
                associatedTeam.type === UserComboboxOptionType.TEAM &&
                associatedTeam.title}
            </div>
          </div>
          <div className="flex items-center">
            <div className="w-1/3 text-gray-500">Track</div>
            <div className="w-2/3">{careerTrackData.title}</div>
          </div>
          <div className="flex items-center">
            <div className="w-1/3 text-gray-500">Level</div>
            <div className="w-2/3">{level}</div>
          </div>
        </div>

        <div className="mt-6">
          <div className="text-gray-500 text-xs uppercase font-semibold">
            Role name
          </div>
          <Input
            className="mt-2"
            value={proposedRole.title ?? ""}
            onChange={(evt) => {
              setProposedRole({
                ...proposedRole,
                title: evt.target.value,
              });
            }}
          />
          <div className="text-xs mt-1">
            <label className="flex items-center gap-1.5 cursor-pointer">
              <input
                type="checkbox"
                checked={
                  proposedRole.roleType === CareerTrackRoleType.Management
                }
                onChange={(evt) => {
                  setProposedRole({
                    ...proposedRole,
                    roleType: evt.target.checked
                      ? CareerTrackRoleType.Management
                      : CareerTrackRoleType.Ic,
                  });
                }}
              />
              Management role
            </label>
          </div>
        </div>

        <div className="mt-6">
          <div className="text-gray-500 text-xs uppercase font-semibold">
            Role members
          </div>
          <TeamMemberCombobox
            excludeUserIds={proposedRole.assignToUsers.map(({ id }) => id)}
            onSelect={(userOpt) => {
              if (!userOpt || userOpt.type !== UserComboboxOptionType.USER) {
                return;
              }
              setProposedRole({
                ...proposedRole,
                assignToUsers: [...proposedRole.assignToUsers, userOpt],
              });
            }}
          />
          <div className="mt-2 flex flex-col text-sm text-gray-600 divide-y border-t border-b empty:hidden">
            {proposedRole.assignToUsers.map((user) => (
              <div
                key={user.id}
                className="flex justify-between items-center gap-2 px-3 py-1.5 hover:bg-gray-50"
              >
                <div className="flex items-center gap-2">
                  <Avatar user={user} size="4" />
                  {user.name}
                </div>
                <button
                  type="button"
                  aria-label="Delete team member button"
                  onClick={() => {
                    setProposedRole({
                      ...proposedRole,
                      assignToUsers: proposedRole.assignToUsers.filter(
                        ({ id }) => id !== user.id
                      ),
                    });
                  }}
                  className="rounded hover:bg-gray-200 text-gray-500 hover:text-gray-800 px-1 py-0.5"
                >
                  <TrashIcon className="h-4 w-4" />
                </button>
              </div>
            ))}
          </div>
        </div>

        <div className="flex items-center justify-end gap-2 mt-4">
          <div className="flex items-center justify-end gap-2">
            <Button theme={buttonTheme.text} text="Discard" onClick={onClose} />
            <Button
              disabled={!canSave}
              theme="primary"
              tooltip={saveTooltip}
              text="Save"
              onClick={() => {
                onRoleChange(proposedRole);
                onClose();
              }}
            />
          </div>
        </div>
      </div>
    </Modal>,
    document.body
  );
};

export default RoleDetailModal;
