import { useMutation, useQuery } from "@apollo/client";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import { useCallback, useEffect, useMemo, useState } from "react";
import { MdArchive, MdContentCopy, MdUnarchive } from "react-icons/md";
import {
  GetAssessmentGroupsQuery,
  GetAssessmentGroupsQueryVariables,
  RemoveAssessmentGroupMutation,
  RemoveAssessmentGroupMutationVariables,
  SaveAssessmentGroupMutation,
  SaveAssessmentGroupMutationVariables,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar, successNotificationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Heading from "@components/heading/heading";
import Loading from "@components/loading/loading";
import ManagedTable from "@components/table/managed-table";
import {
  ToggleButtonGroup,
  ToggleButtonGroupTheme,
  ToggleButtonGroupType,
} from "@components/toggle-button-group/toggle-button-group";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";
import useConfirm from "@helpers/hooks/use-confirm";

import createOrUpdateAssessmentGroupMutation from "../graphql/create-or-update-assessment-group-mutation";
import deleteAssessmentGroupMutation from "../graphql/delete-assessment-group-mutation";
import getAssessmentGroupsQuery from "../graphql/get-assessment-groups-query";
import { bgClassName } from "../helpers";

enum AssessmentGroupsTab {
  active = "active",
  archived = "archived",
}

const AssessmentGroups = () => {
  const label = useLabel();
  const organization = currentOrganizationVar();
  const { ConfirmationDialog, confirm } = useConfirm(
    "Are you sure?",
    `Are you sure you want to delete this ${label("review")} template?`
  );
  const [showArchived, setShowArchived] = useState(false);

  const { data, loading, refetch } = useQuery<
    GetAssessmentGroupsQuery,
    GetAssessmentGroupsQueryVariables
  >(getAssessmentGroupsQuery, {
    variables: { organizationId: organization.id, archived: false },
    onError: onNotificationErrorHandler(),
  });

  const [removeAssessmentGroup, { loading: isDeletingGroup }] = useMutation<
    RemoveAssessmentGroupMutation,
    RemoveAssessmentGroupMutationVariables
  >(deleteAssessmentGroupMutation);

  const [createOrUpdateAssessmentGroup, { loading: isArchivingGroup }] =
    useMutation<
      SaveAssessmentGroupMutation,
      SaveAssessmentGroupMutationVariables
    >(createOrUpdateAssessmentGroupMutation);

  const assessmentGroups = useMemo(() => {
    return data?.assessmentGroups
      ? assertEdgesNonNull(data.assessmentGroups)
      : [];
  }, [data]);

  useEffect(() => {
    refetch({ archived: showArchived });
  }, [refetch, showArchived]);

  const handleDeleteAssessmentGroup = useCallback(
    async (assessmentGroupId: number) => {
      const confirmation = await confirm();
      if (confirmation) {
        removeAssessmentGroup({
          variables: { assessmentGroupId },
          refetchQueries: [getAssessmentGroupsQuery],
          onCompleted: () =>
            successNotificationVar({
              title: `${label("review", {
                capitalize: true,
              })} template deleted`,
            }),
          onError: onNotificationErrorHandler(),
        });
      }
    },
    [confirm, removeAssessmentGroup, label]
  );

  const handleChangeArchiveStatus = useCallback(
    (assessmentGroupId: number, archived: boolean) => {
      createOrUpdateAssessmentGroup({
        variables: {
          assessmentGroupId,
          archived,
        },
        refetchQueries: [getAssessmentGroupsQuery],
        onCompleted: () => {
          successNotificationVar({
            title: archived ? "Template archived" : "Template unarchived",
          });
        },
        onError: onNotificationErrorHandler(),
      });
    },
    [createOrUpdateAssessmentGroup]
  );

  const handleToggleArchivedFilter = useCallback(
    (option: ToggleButtonGroupType<AssessmentGroupsTab>) => {
      setShowArchived(option.value === AssessmentGroupsTab.archived);
    },
    []
  );

  if (loading) {
    return (
      <Loading className={classNames(bgClassName, "p-6 w-full mx-auto")}>
        Loading
      </Loading>
    );
  }

  return (
    <div
      className={classNames(bgClassName, "p-6 w-full")}
      aria-label="Assessments > groups"
    >
      <ConfirmationDialog />
      <div className="flex w-full justify-between items-center">
        <Heading
          small
          title={`${label("review", { capitalize: true })} Templates`}
        />
        <div className="flex items-center gap-2">
          {isDeletingGroup && <Loading mini size="5" />}
          <Button
            disabled={isDeletingGroup}
            theme={buttonTheme.default}
            to="/assessments/assessment-templates/new"
            text={`New ${label("review")} template`}
          />
        </div>
      </div>

      <div className="mt-4">
        <ManagedTable
          sortingEnabled
          searchEnabled
          sortKey="title"
          sortDir="asc"
          data={assessmentGroups}
          aboveTable={(searchInput) => (
            <>
              <div className="flex items-center gap-4 my-2">
                {searchInput}
                <ToggleButtonGroup<AssessmentGroupsTab>
                  theme={ToggleButtonGroupTheme.buttons}
                  buttons={[
                    {
                      active: !showArchived,
                      title: "Active",
                      value: AssessmentGroupsTab.active,
                    },
                    {
                      active: showArchived,
                      title: "Archived",
                      value: AssessmentGroupsTab.archived,
                    },
                  ]}
                  onClick={handleToggleArchivedFilter}
                />
              </div>
            </>
          )}
          columns={[
            {
              header: "Title",
              field: "title",
              sortKey: "title",
            },
            {
              header: "",
              render: (row) => {
                const deleteOrArchiveButton =
                  !row.archived && !row.canDelete?.permission ? (
                    <Button
                      disabled={isDeletingGroup || isArchivingGroup}
                      theme={buttonTheme.redDanger}
                      tooltip="Archive"
                      onClick={() => handleChangeArchiveStatus(row.id, true)}
                    >
                      <MdArchive className="h-4 w-4" />
                    </Button>
                  ) : row.archived ? (
                    <Button
                      disabled={isDeletingGroup || isArchivingGroup}
                      theme={buttonTheme.lightBlue}
                      tooltip="Unarchive"
                      onClick={() => handleChangeArchiveStatus(row.id, false)}
                    >
                      <MdUnarchive className="h-4 w-4" />
                    </Button>
                  ) : (
                    <Button
                      disabled={
                        !row.canDelete?.permission ||
                        isDeletingGroup ||
                        isArchivingGroup
                      }
                      tooltip={
                        !row.canDelete?.permission
                          ? row.canDelete?.reason ?? undefined
                          : "Delete"
                      }
                      theme={buttonTheme.redDanger}
                      onClick={() => handleDeleteAssessmentGroup(row.id)}
                    >
                      <TrashIcon className="h-4 w-4" />
                    </Button>
                  );
                return (
                  <div className="flex items-center gap-2 justify-end">
                    <Button
                      disabled={isDeletingGroup}
                      theme={buttonTheme.lightBlue}
                      to={`/assessments/assessment-templates/${row.id}/edit`}
                    >
                      <PencilIcon className="h-4 w-4" />
                    </Button>
                    <Button
                      disabled={isDeletingGroup}
                      theme={buttonTheme.lightBlue}
                      to={`/assessments/assessment-templates/${row.id}/duplicate`}
                    >
                      <MdContentCopy className="h-4 w-4" />
                    </Button>
                    {deleteOrArchiveButton}
                  </div>
                );
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default AssessmentGroups;
