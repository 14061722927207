import { Popover } from "@headlessui/react";
import {
  LockClosedIcon,
  LockOpenIcon,
  MicrophoneIcon,
  PlayIcon,
} from "@heroicons/react/outline";
import { useState } from "react";
import {
  GetMeetingGroupNewPageQueryQuery,
  MeetingViewMeetingNodeNewPageFragmentFragment,
} from "types/graphql-schema";

import MeetingDropdownMenu from "@apps/meeting-dropdown-menu/meeting-dropdown-menu";
import MeetingVideoConferenceButton from "@apps/meeting-new/components/meeting/video-conference-button";
import MeetingPresentationDialog from "@apps/meeting-presentation-dialog/meeting-presentation-dialog";
import MeetingContentAccessDropdown from "@apps/meeting-settings/components/content-access-dropdown";
import Layout from "@components/layout/layout";
import Tooltip from "@components/tooltip/tooltip";
import { classNames } from "@helpers/css";

import { getMeetingAllowVisibilityLabel } from "../helpers";
import AIAssistMeetingBot from "./meeting-sidebar/ai-assist-bot";
import PublishMeetingButton from "./publish-meeting-button";
import ShareNotesDialog from "./share-meeting/share-meeting-notes-dialog";

export enum MeetingViewEnum {
  notes = "Notes",
  summary = "Summary",
}

const MeetingPageHeader = ({
  meetingGroup,
  meeting,
}: {
  meetingGroup: NonNullable<GetMeetingGroupNewPageQueryQuery["meetingGroup"]>;
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
}) => {
  const [isShowingPresentationMode, setIsShowingPresentationMode] =
    useState(false);
  const [isShowingShareNotesDialog, setIsShowingShareNotesDialog] =
    useState(false);

  const handleShareNotesDialog = () => {
    setIsShowingShareNotesDialog(true);
  };

  const handleCloseInvitationDialog = () => {
    setIsShowingShareNotesDialog(false);
  };
  return (
    <>
      {isShowingShareNotesDialog && (
        <ShareNotesDialog
          meeting={meeting}
          onClose={handleCloseInvitationDialog}
          isInviteDialog={false}
        />
      )}
      {isShowingPresentationMode && (
        <MeetingPresentationDialog
          meeting={meeting}
          onClose={() => setIsShowingPresentationMode(false)}
        />
      )}
      <>
        {meeting.draft && (
          <div className="flex items-center mr-4 empty:hidden">
            <PublishMeetingButton meeting={meeting}>
              Publish
            </PublishMeetingButton>
          </div>
        )}

        <Tooltip text="Present meeting notes">
          <button
            className={classNames(
              Layout.headerIconButtonClassName,
              "hidden sm:block"
            )}
            onClick={() => setIsShowingPresentationMode(true)}
          >
            <PlayIcon className="h-5 w-5" />
          </button>
        </Tooltip>

        <MeetingVideoConferenceButton
          meeting={meeting}
          className={classNames(
            Layout.headerIconButtonClassName,
            "hidden sm:block"
          )}
          textClassName="hidden"
          iconClassName="h-5 w-5"
          compact
        />

        {/* Privacy */}
        <div className="relative hidden sm:block">
          <MeetingContentAccessDropdown
            meetingGroup={meetingGroup}
            alignLeft={false}
          >
            <Tooltip
              text={`These people can access the notes of this meeting: ${getMeetingAllowVisibilityLabel(
                {
                  allowManagementTreeVisibility:
                    meetingGroup.allowManagementTreeVisibility,
                  allowAdminVisibility: meetingGroup.allowAdminVisibility,
                  allowOrgVisibility: meetingGroup.allowOrgVisibility,
                }
              )}`}
            >
              <Popover.Button
                className={
                  meetingGroup?.canUpdate?.permission
                    ? Layout.headerIconButtonClassName
                    : Layout.headerDisableIconButtonClassName
                }
                aria-label="Meeting permission dropdown menu button"
                disabled={!meetingGroup?.canUpdate?.permission}
              >
                {meetingGroup.allowAdminVisibility ||
                meetingGroup.allowOrgVisibility ? (
                  <LockOpenIcon className="h-5 w-5" />
                ) : (
                  <LockClosedIcon className="h-5 w-5" />
                )}
              </Popover.Button>
            </Tooltip>
          </MeetingContentAccessDropdown>
        </div>

        {/* Microphone */}
        <div className="relative hidden sm:block">
          <Popover>
            <Tooltip
              text={`Open recording options (
                ${
                  meeting.hasBotInMeeting && meeting.botIsRecording
                    ? `recording`
                    : "not recording"
                })`}
            >
              <Popover.Button
                className={classNames(
                  Layout.headerIconButtonClassName,
                  meeting.hasBotInMeeting &&
                    meeting.botIsRecording &&
                    "text-red-600"
                )}
              >
                <MicrophoneIcon className="h-5 w-5" />
              </Popover.Button>
            </Tooltip>
            <Popover.Panel className="right-0 absolute mt-1 z-dropdown bg-white shadow-lg rounded-md w-96 px-2 flex flex-col gap-4 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
              <AIAssistMeetingBot meeting={meeting} />
            </Popover.Panel>
          </Popover>
        </div>

        <MeetingDropdownMenu
          meeting={meeting}
          meetingGroup={meetingGroup}
          className={Layout.headerIconButtonClassName}
          size="5"
          onOpenSendNotesDialog={handleShareNotesDialog}
        />
      </>
    </>
  );
};

export default MeetingPageHeader;
