import { useLocation } from "react-router-dom";
import { AssessmentState } from "types/graphql-schema";
import { BasicUser, TFLocationState } from "types/topicflow";

import useCreateEmptyAssessment from "@apps/assessments/hooks/use-create-empty-assessment";
import Avatar from "@components/avatar/avatar";
import Button, { buttonTheme } from "@components/button/button";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import Table, {
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeadCell,
  TableHeadRow,
} from "@components/table/table";
import { classNames } from "@helpers/css";

type ComplianceProgramUserActivityAssessmentType = {
  id: number | null;
  target?: BasicUser | null;
  state: null | AssessmentState;
};

const ComplianceProgramUserActivityAssessmentItem = ({
  assessment,
  complianceProgramId,
  assessmentTemplateId,
}: {
  assessmentTemplateId: number;
  complianceProgramId: number;
  assessment: ComplianceProgramUserActivityAssessmentType;
}) => {
  const location = useLocation<TFLocationState>();
  const { handleCreateEmptyAssessment, isLoading: isCreatingEmptyAssessment } =
    useCreateEmptyAssessment(`/programs/${complianceProgramId}/user-activity`);

  const handleClickCreateAssessment = () => {
    if (assessment.target) {
      handleCreateEmptyAssessment(
        complianceProgramId,
        assessmentTemplateId,
        assessment.target.id
      );
    }
  };

  return (
    <TableBodyRow key={assessment.target?.id}>
      <TableBodyCell>
        <div className="flex items-center gap-2 text-gray-800">
          <Avatar user={assessment.target} size="5" />
          {assessment.target?.name}
        </div>
      </TableBodyCell>
      <TableBodyCell>
        <span
          className={classNames(
            "inline-block py-2 rounded-md w-36 text-center",
            assessment.state === AssessmentState.Draft
              ? "bg-amber-100 text-amber-700"
              : assessment.state === AssessmentState.Submitted
              ? "bg-green-100 text-green-700"
              : "bg-gray-100 text-gray-600"
          )}
        >
          {assessment.state === AssessmentState.Draft
            ? "In progress"
            : assessment.state === AssessmentState.Submitted
            ? "Submitted"
            : "Not started"}
        </span>
      </TableBodyCell>
      <TableBodyCell>
        <div className="flex items-center gap-2 text-gray-800">
          {assessment.id ? (
            <AppLink
              to={{
                pathname: `/assessments/assessment/${assessment.id}`,
                state: {
                  previousPathname: `${location.pathname}${location.search}`,
                },
              }}
              className="text-blue-link hover:underline"
            >
              View assessment
            </AppLink>
          ) : (
            <Button
              theme={buttonTheme.primary}
              small
              onClick={handleClickCreateAssessment}
              text="Start assessment"
              disabled={isCreatingEmptyAssessment}
            />
          )}
          {isCreatingEmptyAssessment && <Loading mini size={4} />}
        </div>
      </TableBodyCell>
    </TableBodyRow>
  );
};

const ComplianceProgramUserActivityAssessmentList = ({
  assessments,
  complianceProgramId,
  assessmentTemplateId,
}: {
  assessmentTemplateId: number;
  complianceProgramId: number;
  assessments: ComplianceProgramUserActivityAssessmentType[];
}) => {
  return (
    <TableContainer>
      <Table>
        <TableHeadRow>
          <TableHeadCell width="72">Target</TableHeadCell>
          <TableHeadCell width="56">Status</TableHeadCell>
          <TableHeadCell>Assessment</TableHeadCell>
        </TableHeadRow>
        <TableBody>
          {assessments.map((assessment) => (
            <ComplianceProgramUserActivityAssessmentItem
              key={assessment.target?.id}
              assessment={assessment}
              complianceProgramId={complianceProgramId}
              assessmentTemplateId={assessmentTemplateId}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ComplianceProgramUserActivityAssessmentList;
