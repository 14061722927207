import { useQuery } from "@apollo/client";
import { XIcon } from "@heroicons/react/outline";
import { uniq } from "lodash";
import { useEffect, useMemo, useState } from "react";
import {
  AlignmentGoalFragmentFragment,
  GetGoalOverviewSelectedEntityQueryQuery,
  GetGoalOverviewSelectedEntityQueryQueryVariables,
  GoalScope,
  GoalState,
} from "types/graphql-schema";
import { DateRangeEnum } from "types/topicflow";

import getGoalOverviewSelectedEntityQuery from "@apps/goal-overview/graphql/get-goal-overview-selected-entity-query";
import GoalPageHeader from "@apps/goal-overview/header";
import useLabel from "@apps/use-label/use-label";
import useUiPreferenceCache from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import { currentOrganizationVar } from "@cache/cache";
import { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import Tooltip from "@components/tooltip/tooltip";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import {
  UserComboboxOption,
  UserComboboxOptionType,
} from "@components/user-combobox/user-combobox-list";
import { classNames } from "@helpers/css";
import { dateRangeToDateArray } from "@helpers/helpers";
import useUrlQueryParams from "@helpers/hooks/use-url-query-params";

import GoalAlignmentHeader from "./goal-alignment-header";
import GoalAlignmentTree from "./goal-alignment-tree";
import {
  getFirst3LevelGoalIds,
  useFetchAlignedGoals,
} from "./use-fetch-alignment-goals";

export enum AllDateRangeEnum {
  all = "all",
}

export type AlignedGoalType = {
  __typename: "GoalArtifactNode";
} & AlignmentGoalFragmentFragment;

export type AlignmentGoalsByIdType = {
  [key: number]: AlignedGoalType;
};

export type AlignmentChildGoalIdsByParentIdType = {
  [key: number]: number[];
};

type GoalAlignmentDateRangeType = AllDateRangeEnum | DateRangeEnum;

export const goalAlignmentPageCount = 50;

export const getGoalDescendantIds = (
  goalId: number,
  allChildGoalIdsByParentId: AlignmentChildGoalIdsByParentIdType
): number[] => {
  const childGoalIds = allChildGoalIdsByParentId[goalId];
  if (!childGoalIds) return [];
  return childGoalIds.concat(
    ...childGoalIds.map((childGoalId) =>
      getGoalDescendantIds(childGoalId, allChildGoalIdsByParentId)
    )
  );
};

export const isGoalInSearchResultPath = (
  goalId: number,
  searchResultGoalIds?: number[],
  goalIdsInSearchResultPaths?: number[]
) => {
  return (
    searchResultGoalIds === undefined ||
    goalIdsInSearchResultPaths === undefined ||
    searchResultGoalIds?.includes(goalId) ||
    goalIdsInSearchResultPaths?.includes(goalId)
  );
};

const GoalAlignment = () => {
  const label = useLabel();
  const link = useLink();
  const currentOrganization = currentOrganizationVar();
  const { uiPreferenceCache } = useUiPreferenceCache();
  const { dateRange, contextId, goalId, contextType } = useUrlQueryParams<{
    dateRange: DateRangeEnum;
    contextId?: string;
    contextType?: UserComboboxOptionType;
    goalId?: string;
  }>({
    dateRange: DateRangeEnum.thisQuarter,
  });

  const [selectedEntity, setSelectedEntity] =
    useState<UserComboboxOption | null>(null);
  const [selectedDateRange, setSelectedDateRange] =
    useState<GoalAlignmentDateRangeType>(dateRange);

  const variables = useMemo(() => {
    return {
      first: goalAlignmentPageCount,
      after: null,
      goalDueBetweenDates:
        selectedDateRange === AllDateRangeEnum.all || goalId
          ? undefined
          : dateRangeToDateArray({
              range: selectedDateRange,
              quarterStartMonth: currentOrganization.quarterStartMonth,
            }),
      goalStates:
        goalId || uiPreferenceCache.objectiveAlignmentIsShowingClosed
          ? null
          : [GoalState.Draft, GoalState.Open],
      goalScopes: [GoalScope.Organization, GoalScope.Team, GoalScope.Personal],
      searchGoalOwners:
        !goalId && contextId && contextType === UserComboboxOptionType.USER
          ? [parseInt(contextId)]
          : undefined,
      searchGoalTeams:
        !goalId && contextId && contextType === UserComboboxOptionType.TEAM
          ? [parseInt(contextId)]
          : undefined,
      inPathOfGoalId: goalId ? parseInt(goalId) : undefined,
      hasSearch: !goalId && !!contextType && !!contextId,
    };
  }, [
    currentOrganization.quarterStartMonth,
    selectedDateRange,
    uiPreferenceCache.objectiveAlignmentIsShowingClosed,
    contextId,
    contextType,
    goalId,
  ]);

  const {
    goals,
    goalsById,
    childGoalIdsByParentId,
    searchResultAncestorGoalIds,
    searchResultGoalIds,
    loading,
  } = useFetchAlignedGoals({ variables });

  const userId =
    contextType === UserComboboxOptionType.USER && contextId
      ? parseInt(contextId)
      : -1;
  const teamId =
    contextType === UserComboboxOptionType.TEAM && contextId
      ? parseInt(contextId)
      : -1;
  useQuery<
    GetGoalOverviewSelectedEntityQueryQuery,
    GetGoalOverviewSelectedEntityQueryQueryVariables
  >(getGoalOverviewSelectedEntityQuery, {
    variables: {
      userId: userId,
      hasUserId: userId > 0,
      teamId: teamId,
      hasTeamId: teamId > 0,
      organizationId: -1,
      hasOrganizationId: false,
    },
    skip: userId < 1 && teamId < 1,
    onCompleted: (response) => {
      if (response.user) {
        setSelectedEntity({
          id: response.user.id,
          name: response.user.name,
          type: UserComboboxOptionType.USER,
          avatar: response.user.avatar,
          email: response.user.email,
        });
      } else if (response.team) {
        setSelectedEntity({
          id: response.team.id,
          title: response.team.title,
          type: UserComboboxOptionType.TEAM,
        });
      }
    },
    onError: onNotificationErrorHandler(),
  });

  const handleClearGoalId = () => {
    const search = new URLSearchParams(window.location.search);
    search.delete("goalId");
    link.replace({
      pathname: "/goal-alignment",
      search: search.toString(),
    });
  };

  const topLevelGoals = useMemo(
    () => goals.filter((goal) => !goal.parentGoalId),
    [goals]
  );
  const topLevelAlignedGoals = useMemo(
    () =>
      topLevelGoals.filter((goal) =>
        [GoalScope.Organization, GoalScope.Team].includes(goal.scope)
      ),
    [topLevelGoals]
  );
  const topLevelUnalignedGoals = useMemo(
    () =>
      topLevelGoals.filter(
        (goal) => ![GoalScope.Organization, GoalScope.Team].includes(goal.scope)
      ),
    [topLevelGoals]
  );
  const topLevelGoalIds = useMemo(() => {
    return topLevelGoals.map((goal) => goal.id);
  }, [topLevelGoals]);

  const [expandedGoalIds, setExpandedGoalIds] = useState<number[]>(
    searchResultAncestorGoalIds !== undefined
      ? []
      : getFirst3LevelGoalIds(topLevelGoalIds, childGoalIdsByParentId)
  );

  const handleExpandAll = () => {
    setExpandedGoalIds(Object.keys(childGoalIdsByParentId).map(Number));
  };

  const handleCollapseAll = () => {
    setExpandedGoalIds([]);
  };

  const handleToggleExpandedGoal = (goalIds: number[], toExpand: boolean) => {
    const newExpandedGoalIds = toExpand
      ? expandedGoalIds.concat(goalIds)
      : expandedGoalIds.filter((id) => !goalIds.includes(id));
    setExpandedGoalIds(uniq(newExpandedGoalIds));
  };

  // only reset default expanded goals after loading goals is done
  useEffect(() => {
    if (!loading) {
      // expand all goals when looking at the full alignment of a goal
      if (goalId) {
        setExpandedGoalIds(Object.keys(childGoalIdsByParentId).map(Number));
      }

      // expand only ancestors in a search
      else if (
        searchResultAncestorGoalIds !== undefined &&
        searchResultAncestorGoalIds.length > 0
      ) {
        setExpandedGoalIds(searchResultAncestorGoalIds);
      }

      // when showing all goals, expand first 3 levels
      else if (searchResultAncestorGoalIds === undefined) {
        const first3LevelGoalIds = getFirst3LevelGoalIds(
          topLevelGoalIds,
          childGoalIdsByParentId
        );
        setExpandedGoalIds(first3LevelGoalIds);
      }
    }
  }, [loading]);

  const noResultsInSearch =
    searchResultGoalIds !== undefined && searchResultGoalIds.length === 0;
  const highlightedGoalIds = goalId ? [parseInt(goalId)] : searchResultGoalIds;
  const isShowingKeyResults =
    uiPreferenceCache.objectiveAlignmentIsShowingKeyResults;
  const focusedGoal = goalId ? goalsById[parseInt(goalId)] : undefined;

  return (
    <div aria-label="Goal alignment" className="flex flex-col flex-1 fs-unmask">
      <GoalPageHeader />
      <div className="pb-16">
        <div className="max-w-screen-xl mx-auto p-4 w-full flex flex-col gap-8">
          <GoalAlignmentHeader
            selectedDateRange={selectedDateRange}
            onChangeSelectedDateRange={setSelectedDateRange}
            selectedEntity={selectedEntity}
            onChangeSelectedEntity={setSelectedEntity}
            onClickExpandAll={handleExpandAll}
            onClickCollapseAll={handleCollapseAll}
            loading={loading && goals.length > 0}
            focusedGoal={focusedGoal}
          />

          {goals.length === 0 && loading && (
            <Loading>Loading {label("goal", { pluralize: true })}</Loading>
          )}

          {!loading &&
            ((topLevelAlignedGoals.length === 0 &&
              topLevelUnalignedGoals.length === 0) ||
              noResultsInSearch) && (
              <div className="text-sm text-gray-500">
                No {label("goal", { pluralize: true })}.
              </div>
            )}

          {goalId && topLevelAlignedGoals.length > 0 && (
            <div>
              {focusedGoal ? (
                <div>
                  <div className="mb-4 flex items-center gap-2">
                    <div className="text-xl font-medium">
                      Aligned{" "}
                      {label("goal", { pluralize: true, capitalize: true })}
                    </div>
                    {focusedGoal && (
                      <Tooltip
                        text={`Clear filtering alignment for ${focusedGoal.title}`}
                      >
                        <button
                          onClick={handleClearGoalId}
                          className="text-gray-500 tracking-tight text-xs px-2 py-0.5 flex items-center gap-1 rounded-full border bg-gray-100 hover:bg-white"
                        >
                          Viewing:{" "}
                          <span className="font-medium text-gray-600">
                            {focusedGoal.title}
                          </span>
                          <XIcon className="w-4 h-4 text-gray-400" />
                        </button>
                      </Tooltip>
                    )}
                  </div>
                  <div className="-ml-1">
                    <GoalAlignmentTree
                      goals={topLevelGoals}
                      goalsById={goalsById}
                      childGoalIdsByParentId={childGoalIdsByParentId}
                      isShowingKeyResults={isShowingKeyResults}
                      indent={0}
                      highlightedGoalIds={highlightedGoalIds}
                      orderPriorityGoalIds={searchResultAncestorGoalIds}
                      expandedGoalIds={expandedGoalIds}
                      onToggleExpandedGoal={handleToggleExpandedGoal}
                    />
                  </div>
                </div>
              ) : (
                <div className="text-sm text-gray-500">
                  No {label("goal", { pluralize: true })}.
                </div>
              )}
            </div>
          )}

          {!goalId && !noResultsInSearch && topLevelAlignedGoals.length > 0 && (
            <div>
              <div className="text-xl font-medium mb-4">
                Aligned {label("goal", { pluralize: true, capitalize: true })}
              </div>
              <div className="-ml-1">
                <GoalAlignmentTree
                  goals={topLevelAlignedGoals}
                  goalsById={goalsById}
                  childGoalIdsByParentId={childGoalIdsByParentId}
                  isShowingKeyResults={isShowingKeyResults}
                  indent={0}
                  highlightedGoalIds={highlightedGoalIds}
                  orderPriorityGoalIds={searchResultAncestorGoalIds}
                  expandedGoalIds={expandedGoalIds}
                  onToggleExpandedGoal={handleToggleExpandedGoal}
                />
              </div>
            </div>
          )}

          {!goalId && !noResultsInSearch && topLevelUnalignedGoals.length > 0 && (
            <div
              className={classNames(
                topLevelAlignedGoals.length > 0 && "border-t pt-8"
              )}
            >
              <div className="text-xl font-medium mb-4">
                Unaligned {label("goal", { pluralize: true, capitalize: true })}
              </div>
              <div className="-ml-1">
                <GoalAlignmentTree
                  goals={topLevelUnalignedGoals}
                  goalsById={goalsById}
                  childGoalIdsByParentId={childGoalIdsByParentId}
                  isShowingKeyResults={isShowingKeyResults}
                  indent={0}
                  highlightedGoalIds={highlightedGoalIds}
                  orderPriorityGoalIds={searchResultAncestorGoalIds}
                  expandedGoalIds={expandedGoalIds}
                  onToggleExpandedGoal={handleToggleExpandedGoal}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GoalAlignment;
