import { useQuery } from "@apollo/client";
import { withErrorBoundary } from "@sentry/react";
import { uniqBy } from "lodash";
import moment from "moment";
import { useState } from "react";
import {
  DashboardPeopleTableUserFragmentFragment,
  DashboardPeopleTableUserMeetingFragmentFragment,
  GetPeopleTableQueryQuery,
  GetPeopleTableQueryQueryVariables,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import useLabel from "@apps/use-label/use-label";
import useUiPreferenceCache from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import { currentOrganizationVar, currentUserVar } from "@cache/cache";
import Error from "@components/error/error";
import Layout from "@components/layout/layout";
import Table, {
  TableBody,
  TableContainer,
  TableHeadCell,
  TableHeadRow,
  TableTheme,
} from "@components/table/table";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

import getPeopleTableQuery from "../graphql/get-people-table-query";
import DashboardPeopleTableRow from "./people-table-row";
import DashboardTeamSettingsAddButton from "./team-settings-add-button";

export const peopleTableGoalMax = 5;

const PeopleTableHead = ({
  isShowingOneonone = false,
}: {
  isShowingOneonone?: boolean;
}) => {
  const currentOrganization = currentOrganizationVar();
  const label = useLabel();
  return (
    <TableHeadRow>
      <TableHeadCell>Name</TableHeadCell>
      {currentOrganization.featureFlags.goals && (
        <TableHeadCell widthPx={120}>
          {label("goal", { pluralize: true, capitalize: true })}
        </TableHeadCell>
      )}
      <TableHeadCell widthPx={140}>
        Next{" "}
        {isShowingOneonone
          ? label("oneonone", { pluralize: false, capitalize: true })
          : "Meeting"}
      </TableHeadCell>
      {currentOrganization.featureFlags.recognitions && (
        <TableHeadCell widthPx={140}>Last {label("recognition")}</TableHeadCell>
      )}
    </TableHeadRow>
  );
};

export type PeopleTableRowUser = DashboardPeopleTableUserFragmentFragment & {
  meetings: DashboardPeopleTableUserMeetingFragmentFragment[];
};

const _DashboardPeopleTable = ({
  selectedUser,
}: {
  selectedUser: BasicUser;
}) => {
  const [isShowingEditDialog, setIsShowingEditDialog] = useState(false);
  const currentUser = currentUserVar();
  const { uiPreferenceCache } = useUiPreferenceCache();
  const { data, loading } = useQuery<
    GetPeopleTableQueryQuery,
    GetPeopleTableQueryQueryVariables
  >(getPeopleTableQuery, {
    notifyOnNetworkStatusChange: true,
    skip: !uiPreferenceCache.dashboardConnectionsContainerExpanded,
    variables: {
      userId: selectedUser.id,
      now: moment().startOf("hour").format(),
      peopleTableGoalMax,
    },
    onError: onNotificationErrorHandler(),
  });

  const user = data?.user;
  const managers = (
    user?.managers ? assertEdgesNonNull(user.managers) : []
  ).map((user) => ({ ...user, meetings: assertEdgesNonNull(user.meetings) }));
  const reports = (
    user?.directReports ? assertEdgesNonNull(user.directReports) : []
  ).map((user) => ({ ...user, meetings: assertEdgesNonNull(user.meetings) }));
  const favourites = user?.favouritesGroups
    ? assertEdgesNonNull(user.favouritesGroups)
    : [];
  const allDirectTeamUsers = managers.reduce((memo, manager) => {
    const managerReports = (
      manager?.directReports ? assertEdgesNonNull(manager.directReports) : []
    ).map((user) => ({ ...user, meetings: assertEdgesNonNull(user.meetings) }));
    return [...memo, ...managerReports];
  }, [] as PeopleTableRowUser[]);
  const directTeamUsers = uniqBy(allDirectTeamUsers, "id").filter(
    ({ id }) => id !== selectedUser.id
  );
  const favouriteUsers = favourites.reduce((memo, favouriteGroup) => {
    const users = assertEdgesNonNull(favouriteGroup.users).map((user) => ({
      ...user,
      meetings: user?.meetings ? assertEdgesNonNull(user.meetings) : [],
    }));
    return [...memo, ...users];
  }, [] as PeopleTableRowUser[]);

  // RENDER
  return (
    <>
      {isShowingEditDialog && (
        <DashboardTeamSettingsAddButton
          managers={managers}
          reports={reports}
          favouriteUsers={favouriteUsers}
          onClose={() => setIsShowingEditDialog(false)}
        />
      )}
      <Layout.MainSection
        title={
          selectedUser.id === currentUser.id
            ? "My Connections"
            : `${selectedUser.firstName}'s Connections`
        }
        loading={!data && loading}
        options={
          user?.id === currentUser.id
            ? [
                {
                  label: "Edit",
                  onClick: ({ syntheticEvent }) => {
                    syntheticEvent.preventDefault();
                    syntheticEvent.stopPropagation();
                    setIsShowingEditDialog(true);
                  },
                },
              ]
            : undefined
        }
        expandedUiPreferenceKey="dashboardConnectionsContainerExpanded"
        className="@container/people-table"
        aria-label="People table container"
      >
        <Layout.MainSubSection
          title="Reports"
          emptyPlaceholder={reports.length === 0 && "No reports."}
          loading={!data && loading}
        >
          <TableContainer theme={TableTheme.whiteHeader}>
            <Table className="table-fixed" theme={TableTheme.whiteHeader}>
              <PeopleTableHead isShowingOneonone />
              <TableBody>
                {reports.map((report) => (
                  <DashboardPeopleTableRow
                    selectedUser={selectedUser}
                    user={report}
                    key={report.id}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Layout.MainSubSection>
        <Layout.MainSubSection
          title="Managers"
          emptyPlaceholder={managers.length === 0 && "No managers."}
          loading={!data && loading}
        >
          <TableContainer theme={TableTheme.whiteHeader}>
            <Table className="table-fixed" theme={TableTheme.whiteHeader}>
              <PeopleTableHead isShowingOneonone />
              <TableBody>
                {managers.map((manager) => (
                  <DashboardPeopleTableRow
                    selectedUser={selectedUser}
                    user={manager}
                    key={manager.id}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Layout.MainSubSection>
        <Layout.MainSubSection
          title="Direct team"
          emptyPlaceholder={directTeamUsers.length === 0 && "No direct team."}
          loading={!data && loading}
        >
          <TableContainer theme={TableTheme.whiteHeader}>
            <Table className="table-fixed" theme={TableTheme.whiteHeader}>
              <PeopleTableHead />
              <TableBody>
                {directTeamUsers.map((directTeamUser) => (
                  <DashboardPeopleTableRow
                    selectedUser={selectedUser}
                    user={directTeamUser}
                    key={directTeamUser.id}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Layout.MainSubSection>
        <Layout.MainSubSection
          title="Followers"
          emptyPlaceholder={favouriteUsers.length === 0 && "No followers."}
        >
          <TableContainer theme={TableTheme.whiteHeader}>
            <Table className="table-fixed" theme={TableTheme.whiteHeader}>
              <PeopleTableHead />
              <TableBody>
                {favouriteUsers.map((favouriteUser) => (
                  <DashboardPeopleTableRow
                    selectedUser={selectedUser}
                    user={favouriteUser}
                    key={favouriteUser.id}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Layout.MainSubSection>
      </Layout.MainSection>
    </>
  );
};

const DashboardPeopleTable = withErrorBoundary(_DashboardPeopleTable, {
  fallback: <Error description={"The people table could not be rendered."} />,
});
export default DashboardPeopleTable;
