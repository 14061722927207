import { AddonSession, meet } from "@googleworkspace/meet-addons/meet.addons";
import { useEffect, useState } from "react";
import { Route, useRouteMatch } from "react-router-dom";
import { Switch } from "react-router-dom";

import Pusher from "@apps/pusher/pusher";
import AppError from "@components/error/error";
import Loading from "@components/loading/loading";

import MeetAddonMeetingMainStage from "./components/main-stage";
import MeetAddonMeetingSidePanel from "./components/side-panel";

const MeetAddon = () => {
  const { path } = useRouteMatch();
  const [error, setError] = useState<Error | null>(null);
  const [session, setSession] = useState<AddonSession | null>(null);

  const handleSetUpAddOnSession = async () => {
    try {
      const session = await meet.addon.createAddonSession({
        cloudProjectNumber: window.TF.googleMeetAddOnCloudProjectNumber,
      });
      setSession(session);
    } catch (error) {
      if (error instanceof Error) {
        setError(error);
      } else {
        setError(new Error("Unknown error"));
      }
    }
  };

  useEffect(() => {
    handleSetUpAddOnSession();
  }, []);

  return error ? (
    <div className="p-4">
      <AppError
        title="Error rendering the Google Meet add-on"
        description={error.message}
      />
    </div>
  ) : session ? (
    <Switch>
      <Route exact path={`${path}/side-panel`}>
        <Pusher>
          <MeetAddonMeetingSidePanel session={session} />
        </Pusher>
      </Route>
      <Route path={`${path}/main-stage`}>
        <Pusher>
          <MeetAddonMeetingMainStage session={session} />
        </Pusher>
      </Route>
    </Switch>
  ) : (
    <div className="text-center p-4">
      <Loading size={5}>Loading meeting informations...</Loading>
    </div>
  );
};

export default MeetAddon;
