import { useQuery } from "@apollo/client";
import { ArrowSmLeftIcon } from "@heroicons/react/outline";
import { isNumber } from "@tiptap/core";
import { useCallback, useState } from "react";
import {
  GetMeetingNewPageQueryQuery,
  GetMeetingNewPageQueryQueryVariables,
  GetPreviousRecurringMeetingQueryQuery,
  GetPreviousRecurringMeetingQueryQueryVariables,
  MeetingViewMeetingNodeNewPageFragmentFragment,
} from "types/graphql-schema";

import getMeetingQuery from "@apps/meeting-new/graphql/get-meeting-query";
import getPreviousRecurringMeetingQuery from "@apps/meeting-new/graphql/get-previous-recurring-meeting-query";
import MeetingOverview from "@apps/meetings/components/meetings-overview";
import { currentUserVar } from "@cache/cache";
import AppError from "@components/error/error";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import {
  UserComboboxOptionType,
  UserComboboxUserOption,
} from "@components/user-combobox/user-combobox-list";

import { MeetingPageContent } from "../meeting-page";
import MeetingSidebarRecurringMeetings from "./recurring-meetings";

const MeetingSidebarMeetings = ({
  meeting,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
}) => {
  const [view, setView] = useState<
    "overview" | "load-previous-meeting" | number
  >(meeting.isRecurring ? "load-previous-meeting" : "overview");

  const currentUser = currentUserVar();
  const selectedUser: UserComboboxUserOption = {
    type: UserComboboxOptionType.USER,
    name: currentUser.name,
    id: currentUser.id,
  };

  const { loading, error, data } = useQuery<
    GetMeetingNewPageQueryQuery,
    GetMeetingNewPageQueryQueryVariables
  >(getMeetingQuery, {
    skip: view === "overview" || view === "load-previous-meeting",
    variables: { meetingId: isNumber(view) ? view : -1 },
    onError: onNotificationErrorHandler(),
  });
  useQuery<
    GetPreviousRecurringMeetingQueryQuery,
    GetPreviousRecurringMeetingQueryQueryVariables
  >(getPreviousRecurringMeetingQuery, {
    skip: view !== "load-previous-meeting",
    variables: { beforeMeetingId: meeting.id },
    onError: onNotificationErrorHandler(),
    onCompleted: (data) => {
      setView(data.previousMeeting?.id ?? "overview");
    },
  });
  const sidebarMeeting = data?.meeting;
  const sidebarMeetingGroup = sidebarMeeting?.meetingGroup;

  const handleChangeMeetingId = useCallback((id: number) => {
    setView(id);
  }, []);

  return (
    <div className="">
      {view === "overview" ? (
        <MeetingOverview
          selectedUser={selectedUser}
          embeddedInSidebar
          filterClassName="px-6"
          meetingListClassName="px-6"
          onClickMeetingLink={handleChangeMeetingId}
        />
      ) : view === "load-previous-meeting" ? (
        <Loading size={5} className="p-6 text-sm">
          Loading meeting...
        </Loading>
      ) : (
        <>
          <button
            className="flex items-center gap-2 w-full text-left px-6 py-4 text-sm text-gray-500 hover:underline border-b font-medium"
            onClick={() => setView("overview")}
          >
            <ArrowSmLeftIcon className="w-4 h-4" />
            Find another meeting
          </button>
          {loading ? (
            <Loading size={5} className="p-6 text-sm">
              Loading meeting...
            </Loading>
          ) : error || !sidebarMeeting || !sidebarMeetingGroup ? (
            <AppError
              className="p-6"
              title="Meeting not found"
              description={error?.message}
            />
          ) : (
            <>
              {sidebarMeeting.isRecurring && (
                <MeetingSidebarRecurringMeetings
                  meeting={sidebarMeeting}
                  onChangeMeetingId={handleChangeMeetingId}
                />
              )}
              <div className="p-6">
                <MeetingPageContent
                  meetingGroupId={sidebarMeetingGroup.id}
                  meeting={sidebarMeeting}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MeetingSidebarMeetings;
