import { useQuery } from "@apollo/client";
import { useLocation, useParams } from "react-router-dom";
import {
  GetMeetingForExtensionQueryQuery,
  GetMeetingForExtensionQueryQueryVariables,
} from "types/graphql-schema";

import { MeetingPageContent } from "@apps/meeting-new/components/meeting-page";
import Container from "@components/container/container";
import Error from "@components/error/error";
import GraphqlError from "@components/error/graphql-error";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

import getMeetingForExtensionQuery from "../graphql/get-meeting-for-extension-query";

const ExtensionMeetingContainer = ({
  meetingCode,
}: {
  meetingCode?: string;
}) => {
  const params = useParams<{ meetingGroupId: string; meetingId: string }>();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const queryParamGoogleMeetUrl = urlParams.get("googleMeetUrl");
  const googleMeetUrl =
    queryParamGoogleMeetUrl !== null
      ? queryParamGoogleMeetUrl
      : meetingCode !== undefined
      ? `https://meet.google.com/${meetingCode}`
      : "";
  const meetingId = params.meetingId ? Number(params.meetingId) : undefined;

  const { loading, error, data } = useQuery<
    GetMeetingForExtensionQueryQuery,
    GetMeetingForExtensionQueryQueryVariables
  >(getMeetingForExtensionQuery, {
    skip: !googleMeetUrl && !meetingId,
    fetchPolicy: "network-only",
    variables: { googleMeetUrl, meetingId },
    onError: onNotificationErrorHandler(),
  });
  const meeting = data?.meeting;
  const meetingGroup = data?.meeting?.meetingGroup;

  // Renders
  if (error) {
    return (
      <Container>
        <div className="mx-4">
          <GraphqlError
            error={error}
            whatDidNotWork="The meeting could not be loaded."
          />
        </div>
      </Container>
    );
  }
  if (!data && loading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  if (
    (!googleMeetUrl && !meetingId) ||
    ((googleMeetUrl || meetingId) &&
      !loading &&
      (!meeting || !meetingGroup?.id)) ||
    !data ||
    !meeting ||
    !meetingGroup?.id
  ) {
    return (
      <Container>
        <div className="mx-4">
          <Error title="No meetings associated with this Google Meet." />
        </div>
        <div className="mt-6 mx-4 flex justify-center">
          <AppLink
            to="/extension/meetings"
            className="text-blue-800 hover:underline"
          >
            View today's meetings
          </AppLink>
        </div>
      </Container>
    );
  }

  return (
    <div className="bg-white p-4">
      <MeetingPageContent meetingGroupId={meetingGroup.id} meeting={meeting} />
    </div>
  );
};

export default ExtensionMeetingContainer;
