import { XIcon } from "@heroicons/react/solid";
import { PropsWithChildren } from "react";

const ModalTitle = ({
  onClose,
  children,
}: PropsWithChildren<{
  onClose?: () => void;
}>) => (
  <div className="flex items-center justify-between gap-2">
    <h3 className="flex-1 text-lg leading-6 font-medium text-gray-900">
      {children}
    </h3>
    {onClose && (
      <button
        className="text-gray-600 hover:text-gray-900"
        onClick={onClose}
        aria-label="Close modal button"
      >
        <span className="sr-only">Close topic sidebar</span>
        <XIcon className="h-6 w-6" />
      </button>
    )}
  </div>
);

export default ModalTitle;
