import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { sortBy } from "lodash";
import { MouseEvent, useState } from "react";
import {
  AlignmentGoalFragmentFragment,
  GetGoalToAlignQueryQuery,
  GoalScope,
} from "types/graphql-schema";

import {
  AlignmentChildGoalIdsByParentIdType,
  AlignmentGoalsByIdType,
} from "@apps/goal-alignment/goal-alignment";
import { classNames } from "@helpers/css";

import { getGoalAlignmentDisabledText } from "../helpers";
import AlignmentPopoverGoalItem from "./alignment-goal-item";

const MiniGoalAlignmentTreeItem = ({
  goal,
  goalToAlignTo,
  goalsById,
  childGoalIdsByParentId,
  indent,
  allowedGoalScopes,
  onSelectGoal,
  alignmentType,
  canCauseParentLoops,
}: {
  goalToAlignTo?:
    | ({ __typename: "GoalArtifactNode" } & NonNullable<
        GetGoalToAlignQueryQuery["artifact"]
      >)
    | undefined;
  goalsById: AlignmentGoalsByIdType;
  childGoalIdsByParentId: AlignmentChildGoalIdsByParentIdType;
  allowedGoalScopes: GoalScope[];
  indent: number;
  goal: { __typename: "GoalArtifactNode" } & AlignmentGoalFragmentFragment;
  alignmentType: "child" | "parent";
  onSelectGoal: (
    selectedGoal: {
      __typename: "GoalArtifactNode";
    } & AlignmentGoalFragmentFragment
  ) => void;
  canCauseParentLoops: boolean;
}) => {
  const [isExpanded, setIsExpanded] = useState(indent < 3);
  const childGoalIds = childGoalIdsByParentId[goal.id] || [];
  const _childGoals = childGoalIds.map((id) => goalsById[id]);
  const childGoals = sortBy(_childGoals, (childGoal) =>
    childGoal.scope === GoalScope.Organization
      ? 1
      : childGoal.scope === GoalScope.Team
      ? 2
      : 3
  );

  const handleToggleChildGoals = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const disabledText = getGoalAlignmentDisabledText(
    goalToAlignTo,
    goal,
    allowedGoalScopes,
    alignmentType,
    canCauseParentLoops
  );

  const handleSelectGoal = () => {
    if (!disabledText) {
      onSelectGoal(goal);
    }
  };

  const isSameGoal = goalToAlignTo?.id === goal.id;

  const initialMargin = 16;
  return (
    <div>
      <button
        type="button"
        key={goal.id}
        className={classNames(
          "w-full flex gap-[3px] text-left py-[2px] pr-3 group",
          disabledText ? "cursor-default" : "hover:bg-black/5 cursor-pointer"
        )}
        style={{ paddingLeft: `${indent * 14 + initialMargin}px` }}
        onClick={handleSelectGoal}
      >
        <span
          role="button"
          className={classNames(
            "z-1 mt-[1px] px-[2px] py-[3px] rounded hover:bg-gray-200 text-gray-500",
            childGoalIds.length === 0 && "opacity-0"
          )}
          onClick={handleToggleChildGoals}
        >
          {isExpanded ? (
            <ChevronDownIcon className="h-3 w-3" />
          ) : (
            <ChevronRightIcon className="h-3 w-3" />
          )}
        </span>
        <AlignmentPopoverGoalItem disabledText={disabledText} goal={goal} />
      </button>
      {isExpanded && childGoals.length > 0 && (
        <div className="relative">
          <div
            className={`w-px absolute top-0 bottom-0 left-0 bg-black/5 z-0`}
            style={{ marginLeft: `${initialMargin + (indent + 1) * 14 + 8}px` }}
          />
          <div className="z-1">
            {childGoals.map((childGoal) => (
              <MiniGoalAlignmentTreeItem
                key={childGoal.id}
                goal={childGoal}
                goalsById={goalsById}
                childGoalIdsByParentId={childGoalIdsByParentId}
                indent={indent + 1}
                onSelectGoal={onSelectGoal}
                allowedGoalScopes={allowedGoalScopes}
                goalToAlignTo={goalToAlignTo}
                alignmentType={alignmentType}
                canCauseParentLoops={
                  canCauseParentLoops ||
                  (isSameGoal && alignmentType === "parent")
                }
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MiniGoalAlignmentTreeItem;
