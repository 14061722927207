import { useMutation } from "@apollo/client";
import { LockClosedIcon } from "@heroicons/react/outline";
import moment from "moment";
import { useCallback, useMemo } from "react";
import {
  AssessmentGroupAnonymity,
  AssessmentState,
  GetAssessmentQuery,
  UnsubmitAssessmentMutation,
  UnsubmitAssessmentMutationVariables,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import {
  currentUserVar,
  isAdminVar,
  successNotificationVar,
} from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Button from "@components/button/button";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assessmentTypeLabels } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { assertNonNull } from "@helpers/helpers";

import getAssessmentQuery from "../../graphql/get-assessment-query";
import reopenAssessmentMutation from "../../graphql/reopen-assessment-mutation";
import { bgClassName } from "../../helpers";

const AssessmentSidebarDetails = ({
  myAssessmentsData,
  isLoadingAssessment,
}: {
  myAssessmentsData?: GetAssessmentQuery;
  isLoadingAssessment: boolean;
}) => {
  const currentUser = currentUserVar();
  const isAdmin = isAdminVar();
  const label = useLabel();

  const assessment = useMemo(
    () => (myAssessmentsData ? myAssessmentsData.assessment : null),
    [myAssessmentsData]
  );
  const complianceProgram = useMemo(
    () => (assessment ? assertNonNull(assessment.complianceProgram) : null),
    [assessment]
  );

  const target = useMemo(
    () => (assessment ? assertNonNull(assessment.target) : null),
    [assessment]
  );
  const responder = useMemo(() => assessment?.responder ?? null, [assessment]);
  const isSelfAssessment = useMemo(
    () => target?.id === responder?.id,
    [responder, target]
  );

  const [reopenAssessment, { loading: isReopeningAssessment }] = useMutation<
    UnsubmitAssessmentMutation,
    UnsubmitAssessmentMutationVariables
  >(reopenAssessmentMutation);

  const handleReopenAssessment = useCallback(() => {
    if (!assessment) {
      return;
    }
    reopenAssessment({
      variables: {
        assessmentId: assessment.id,
      },
      refetchQueries: [getAssessmentQuery],
      onCompleted: () => {
        successNotificationVar({
          title: `${label("review", {
            capitalize: true,
          })} reopened. The responder can now edit their responses.`,
        });
      },
      onError: onNotificationErrorHandler(),
    });
  }, [assessment, reopenAssessment, label]);

  if (isLoadingAssessment) {
    return (
      <Loading className={classNames(bgClassName, "p-6 w-full mx-auto")}>
        Loading
      </Loading>
    );
  }

  if (!assessment) {
    return (
      <div
        className={classNames(bgClassName, "flex-1 flex justify-center p-10")}
      >
        {label("review", { capitalize: true })} not found
      </div>
    );
  }

  const leftCellClassName = "py-2 font-medium";
  const rightCellClassName = "py-2";

  return (
    <div className="flex flex-col text-sm gap-6">
      <table className="text-gray-600">
        <tr>
          <td className={leftCellClassName}>Assessment type</td>
          <td className={rightCellClassName}>
            {
              assessmentTypeLabels[
                assertNonNull(assessment.template.assessmentType)
              ]
            }{" "}
            {label("review")}
          </td>
        </tr>

        {isSelfAssessment && (
          <tr>
            <td className={leftCellClassName}>Self assessement</td>
            <td className={rightCellClassName}>
              <div className="flex items-center">
                <Avatar
                  className="mr-1"
                  user={assertNonNull(target)}
                  size="5"
                />{" "}
                {`${target?.name} (Self ${label("review")})`}
              </div>
            </td>
          </tr>
        )}
        {!isSelfAssessment && (
          <>
            <tr>
              <td className={leftCellClassName}>Responder</td>
              <td className={rightCellClassName}>
                <div className="flex items-center">
                  <Avatar className="mr-1" user={responder} size="5" />{" "}
                  {responder?.name ?? "Anonymous"}
                </div>
              </td>
            </tr>
            <tr>
              <td className={leftCellClassName}>Subject</td>
              <td className={rightCellClassName}>
                <div className="flex items-center">
                  <Avatar
                    className="mr-1"
                    user={assertNonNull(target)}
                    size="5"
                  />{" "}
                  {target?.name}
                </div>
              </td>
            </tr>
            {target?.organizationSpecificInfo?.position && (
              <tr>
                <td className={leftCellClassName}>Position</td>
                <td className={rightCellClassName}>
                  {target?.organizationSpecificInfo?.position}
                </td>
              </tr>
            )}
            {target?.organizationSpecificInfo?.jobLevel && (
              <tr>
                <td className={leftCellClassName}>Job level</td>
                <td className={rightCellClassName}>
                  {target?.organizationSpecificInfo?.jobLevel}
                </td>
              </tr>
            )}
          </>
        )}

        <tr>
          <td className={leftCellClassName}>Program</td>
          <td className={rightCellClassName}>{complianceProgram?.title}</td>
        </tr>
        <tr>
          <td className={leftCellClassName}>Program period</td>
          <td className={rightCellClassName}>
            {assessment?.periodStartDate && assessment?.periodEndDate
              ? `${moment(assessment.periodStartDate).format(
                  "MMM D, YYYY"
                )} - ${moment(assessment.periodEndDate).format("MMM D, YYYY")}`
              : "Not set"}
          </td>
        </tr>
        <tr>
          <td className={leftCellClassName}>Due date</td>
          <td className={rightCellClassName}>
            {moment(assessment?.dueDate).format("MMM D, YYYY")}
          </td>
        </tr>
        {assessment.state === AssessmentState.Submitted && (
          <tr>
            <td className={leftCellClassName}>Submitted</td>
            <td className={rightCellClassName}>
              <div className="text-green-700">
                {moment(assessment.submittedDatetime).format(
                  "MMM D, YYYY @ h:mma"
                )}
              </div>
            </td>
          </tr>
        )}
        {assessment.state === AssessmentState.Submitted && isAdmin && (
          <tr>
            <td className={leftCellClassName} colSpan={2}>
              <div className="flex items-center w-full gap-2">
                <Button
                  className="mr-2"
                  onClick={handleReopenAssessment}
                  disabled={isReopeningAssessment}
                >
                  Reopen
                </Button>
                {isReopeningAssessment && <Loading size="5" mini />}
              </div>
            </td>
          </tr>
        )}
      </table>

      {[
        AssessmentGroupAnonymity.SemiAnonymous,
        AssessmentGroupAnonymity.Anonymous,
      ].includes(assessment.template.anonymity) &&
        assessment.state !== AssessmentState.Submitted &&
        currentUser.id === responder?.id &&
        !isSelfAssessment && (
          <div className="border rounded-md border-gray-200 text-gray-500 py-3 px-4">
            <LockClosedIcon className="h-4 w-4 inline mr-1" /> Your responses
            will appear anonymous
            {assessment.template.anonymity ===
            AssessmentGroupAnonymity.SemiAnonymous
              ? " to the subject"
              : ""}
            . Anonymity will be affected if you put identifiable content in your
            comments.
          </div>
        )}
    </div>
  );
};

export default AssessmentSidebarDetails;
