import { gql } from "@apollo/client";

import MeetingViewMeetingNodeFragment from "./meeting-view-meeting-node-fragment";

export default gql`
  ${MeetingViewMeetingNodeFragment}
  query getMeetingNewPageQuery($meetingId: Int!) {
    meeting(meetingId: $meetingId) {
      ...MeetingViewMeetingNodeNewPageFragment
    }
  }
`;
