import {
  AddonSession,
  MeetSidePanelClient,
  MeetingInfo,
} from "@googleworkspace/meet-addons/meet.addons";
import { useEffect, useState } from "react";

import AppError from "@components/error/error";
import Loading from "@components/loading/loading";

import MeetAddonMeeting from "./meeting";

const MeetAddonMeetingSidePanel = ({ session }: { session: AddonSession }) => {
  const [error, setError] = useState<Error | null>(null);
  const [meetingInfo, setMeetingInfo] = useState<MeetingInfo | null>(null);
  const [sidePanelClient, setSidePanelClient] =
    useState<MeetSidePanelClient | null>(null);

  const handleSetUpSidePanel = async () => {
    try {
      const sidePanelClient = await session.createSidePanelClient();
      setSidePanelClient(sidePanelClient);
      sidePanelClient
        .getMeetingInfo()
        .then((meetingInfo) => setMeetingInfo(meetingInfo));
    } catch (error) {
      if (error instanceof Error) {
        setError(error);
      } else {
        setError(new Error("Unknown error"));
      }
    }
  };

  const handleSetUpMainStage = async () => {
    if (!sidePanelClient) return;
    sidePanelClient.startActivity({
      mainStageUrl: `${window.location.origin}/meet-addon/main-stage`,
    });
  };

  useEffect(() => {
    handleSetUpSidePanel();
  }, []);

  return error ? (
    <div className="p-4">
      <AppError
        title="Error rendering the side panel"
        description={error.message}
      />
    </div>
  ) : meetingInfo ? (
    <div>
      <div className="flex justify-center py-1 text-xs tracking-tight">
        <button
          onClick={handleSetUpMainStage}
          className="text-blue-600 hover:underline"
        >
          Set up main stage
        </button>
      </div>
      <MeetAddonMeeting meetingInfo={meetingInfo} />
    </div>
  ) : (
    <div className="text-center p-4">
      <Loading size={5}>Loading side panel...</Loading>
    </div>
  );
};

export default MeetAddonMeetingSidePanel;
