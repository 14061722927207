import { useMenuState } from "@szhsin/react-menu";
import moment from "moment";
import { MouseEvent, useState } from "react";
import { CalendarEventFragmentFragment } from "types/graphql-schema";

import MeetingDropdownMenu from "@apps/meeting-dropdown-menu/meeting-dropdown-menu";
import Tooltip from "@components/tooltip/tooltip";
import { meetingStatus } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { isTailwindLgActive } from "@helpers/helpers";

import { minimumEventHeight, minuteInPixels } from "../../calendar";

const topPixelMargin = 30;

const Event = ({
  event,
  width = "100%",
  left = "0",
  onClickMeetingLink,
}: {
  event: CalendarEventFragmentFragment & {
    height: number;
    startInMinutes: number;
  };
  width?: string;
  left?: string;
  onClickMeetingLink: (meetingId: number) => void;
}) => {
  const [menuProps, toggleMenu] = useMenuState();
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const handleContextMenu = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setAnchorPoint({ x: e.clientX, y: e.clientY });
    toggleMenu(true);
  };

  const isDisabled =
    event.meeting?.status === meetingStatus.cancelled || event.meeting?.ignored;

  const EventTag = event.canRead.permission ? `a` : "span";

  const handleClickMeetingLink = (e: MouseEvent<HTMLAnchorElement>) => {
    if (event.canRead.permission && isTailwindLgActive()) {
      e.preventDefault();
      e.stopPropagation();
      onClickMeetingLink(event.id);
    }
  };

  // RENDER
  return (
    <div
      key={event.id}
      className="absolute"
      style={{
        width,
        left,
        height: event.height,
        top: event.startInMinutes * minuteInPixels + topPixelMargin,
      }}
    >
      {event.canRead.permission && !!event.meeting?.meetingGroup && (
        <MeetingDropdownMenu
          meetingGroup={{
            id: event?.meetingGroupId,
            facilitator: event.meeting.meetingGroup.facilitator,
            isFormalOneonone: event.meeting.meetingGroup.isFormalOneonone,
          }}
          meeting={event.meeting}
          isContextMenu={true}
          onToggleMenu={toggleMenu}
          anchorPoint={anchorPoint}
          {...menuProps}
        />
      )}
      <Tooltip
        text={
          event.canRead.permission
            ? null
            : "You do not have permission to view this event"
        }
      >
        <EventTag
          href={
            event.canRead.permission
              ? `/meeting/${event.meetingGroupId}/${event.id}`
              : undefined
          }
          className={classNames(
            "group absolute inset-0.5 flex flex-col overflow-y-auto rounded-lg px-2 py-0.5 text-xs leading-4 border",
            isDisabled
              ? "bg-gray-100 hover:bg-gray-200 border-transparent"
              : event.meeting?.draft
              ? "bg-amber-50 hover:bg-amber-100 border-dashed border-amber-300"
              : !event.canRead.permission
              ? "bg-slate-50 border-transparent"
              : "bg-blue-50 hover:bg-blue-100 border-transparent"
          )}
          title={event.title ? event.title : undefined}
          onContextMenu={
            event.canRead.permission ? handleContextMenu : undefined
          }
          onClick={handleClickMeetingLink}
        >
          {event.height > minimumEventHeight * 2 ? (
            <div
              className={classNames(
                isDisabled
                  ? "text-gray-700 line-through"
                  : event.meeting?.draft
                  ? "text-amber-800"
                  : !event.canRead.permission
                  ? "text-gray-500"
                  : "text-blue-700"
              )}
            >
              <div className="font-semibold fs-mask">{event.title}</div>
              <time
                dateTime={moment(event.startDatetime).format(
                  "YYYY-MM-DDTHH:mm:ss"
                )}
                className="block opacity-80"
              >
                {moment(event.startDatetime).format("LT")} -{" "}
                {moment(event.endDatetime).format("LT")}
              </time>
            </div>
          ) : (
            <div
              className={classNames(
                "order-1 font-semibold",
                isDisabled
                  ? "text-gray-700 line-through"
                  : event.meeting?.draft
                  ? "text-amber-800"
                  : !event.canRead.permission
                  ? "text-gray-500"
                  : "text-blue-700"
              )}
            >
              {event.title}{" "}
              <time
                dateTime={moment(event.startDatetime).format(
                  "YYYY-MM-DDTHH:mm:ss"
                )}
              >
                {moment(event.startDatetime).format("LT")}
              </time>
            </div>
          )}
        </EventTag>
      </Tooltip>
    </div>
  );
};

export default Event;
