import { useMutation, useQuery } from "@apollo/client";
import { compact, max, omit } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { Prompt } from "react-router-dom";
import {
  CompetencyAppliesTo,
  CompetencyCriteriaInput,
  CompetencyCriteriaUniqueness,
  GetOrgWideCompetencyQuery,
  GetOrgWideCompetencyQueryVariables,
  SaveOrgWideCompetencyMutation,
  SaveOrgWideCompetencyMutationVariables,
} from "types/graphql-schema";
import { v4 as uuidv4 } from "uuid";

import {
  competencyAppliesToLabels,
  competencyCriteriaUniquenessLabels,
} from "@apps/org-settings/constants";
import createOrUpdateOrgWideCompetencyMutation from "@apps/org-settings/graphql/create-or-update-org-wide-competency-mutation";
import getOrganizationCompetencyQuery from "@apps/org-settings/graphql/get-organization-competency-query";
import TeamPicker, {
  TeamPickerTeam,
} from "@apps/reporting/components/team-picker";
import useLabel from "@apps/use-label/use-label";
import { successNotificationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Heading from "@components/heading/heading";
import Input from "@components/input/input";
import Loading from "@components/loading/loading";
import MotionDiv from "@components/motion/motion-div";
import Select, { SelectOption } from "@components/select/select";
import { useNotificationError } from "@components/use-error/use-error";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

import CareerTrackPicker, {
  CareerTrackPickerTrack,
} from "./career-track-picker";
import CriteriaEditor from "./criteria-editor";
import { roleLevelIterator } from "./helpers";

const competencyAppliesToOptions = [
  {
    label: competencyAppliesToLabels[CompetencyAppliesTo.AllRoles],
    value: CompetencyAppliesTo.AllRoles,
  },
  {
    label: competencyAppliesToLabels[CompetencyAppliesTo.SomeRoles],
    value: CompetencyAppliesTo.SomeRoles,
  },
];

const criteriaUniquenessOptions = [
  {
    label:
      competencyCriteriaUniquenessLabels[
        CompetencyCriteriaUniqueness.SameAcrossRoles
      ],
    value: CompetencyCriteriaUniqueness.SameAcrossRoles,
  },
  {
    label:
      competencyCriteriaUniquenessLabels[
        CompetencyCriteriaUniqueness.DifferentForEachRole
      ],
    value: CompetencyCriteriaUniqueness.DifferentForEachRole,
  },
];

const Controls = ({
  isSaving,
  onCancel,
  canSave,
  onSave,
}: {
  isSaving: boolean;
  onCancel: () => void;
  canSave: boolean;
  onSave: () => void;
}) => {
  const label = useLabel();

  return (
    <div className="flex items-center justify-end gap-2 mt-4">
      <Button
        disabled={isSaving}
        theme={buttonTheme.text}
        text="Discard changes"
        onClick={onCancel}
      />
      <Button
        disabled={isSaving || !canSave}
        theme="primary"
        text={`Save ${label("competency", { capitalize: true })}`}
        onClick={onSave}
      />
    </div>
  );
};

const CompetencyForm = ({
  organizationId,
  competencyId,
  onCompetencySaved,
  onCancel,
  startingData,
  isInModal,
}: {
  onCancel: () => void;
  onCompetencySaved: (competencyId: number) => void;
  organizationId: number;
  competencyId?: number | null;
  startingData?: Partial<
    Omit<SaveOrgWideCompetencyMutationVariables, "criteria">
  >;
  isInModal?: boolean;
}) => {
  const label = useLabel();
  const { onError } = useNotificationError({
    errorMatches: [
      {
        match: "A competency with that title already exists",
        title: `A ${label(
          "competency"
        )} with that title already exists, please choose another title.`,
      },
    ],
  });

  const [isFormDirty, setIsFormDirty] = useState(false);

  const [levelCount, setLevelCount] = useState(1);

  const isNew = competencyId === null || competencyId === undefined;

  const [competencyData, setCompetencyData] = useState<
    Omit<SaveOrgWideCompetencyMutationVariables, "criteria">
  >({
    title: "",
    description: "",
    appliesTo: CompetencyAppliesTo.AllRoles,
    criteriaUniqueness: CompetencyCriteriaUniqueness.SameAcrossRoles,
    appliesToIcRoles: false,
    appliesToManagementRoles: false,
    competencyId,
    organizationId,
    ...startingData,
  });
  const [appliesToTeams, setAppliesToTeams] = useState<TeamPickerTeam[]>([]);
  const [appliesToCareerTracks, setAppliesToCareerTracks] = useState<
    CareerTrackPickerTrack[]
  >([]);
  const [criteria, setCriteria] = useState<
    (CompetencyCriteriaInput & { uuid: string })[]
  >([]);

  const { data, loading } = useQuery<
    GetOrgWideCompetencyQuery,
    GetOrgWideCompetencyQueryVariables
  >(getOrganizationCompetencyQuery, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    variables: {
      competencyId: competencyId ?? 0,
    },
    skip: isNew,
    onError,
    onCompleted: (data) => {
      const criteria = assertEdgesNonNull(data?.competency?.criteria);
      setCompetencyData({
        ...data.competency,
        appliesToTeams: assertEdgesNonNull(data.competency?.appliesToTeams).map(
          ({ id }) => id
        ),
        appliesToCareerTracks: assertEdgesNonNull(
          data.competency?.appliesToCareerTracks
        ).map(({ id }) => id),
      });
      setAppliesToTeams(assertEdgesNonNull(data.competency?.appliesToTeams));
      setAppliesToCareerTracks(
        assertEdgesNonNull(data.competency?.appliesToCareerTracks)
      );
      setCriteria(
        criteria.map((criteria) => ({
          level: criteria.level,
          content: criteria.content,
          id: criteria.id,
          uuid: uuidv4(),
        }))
      );
      setLevelCount(
        assertNonNull(max(criteria.map((criteria) => criteria.level)))
      );
    },
  });

  const [saveCompetency, { loading: isSaving }] = useMutation<
    SaveOrgWideCompetencyMutation,
    SaveOrgWideCompetencyMutationVariables
  >(createOrUpdateOrgWideCompetencyMutation, {
    onCompleted: (response) => {
      successNotificationVar({
        title: `${label("competency", { capitalize: true })} saved`,
      });
      onCompetencySaved(
        assertNonNull(response.createOrUpdateCompetency?.competency?.id)
      );
    },
  });

  const handleSaveCompetency = useCallback(() => {
    setIsFormDirty(false);
    saveCompetency({
      variables: {
        ...competencyData,
        criteria:
          competencyData.criteriaUniqueness ===
          CompetencyCriteriaUniqueness.DifferentForEachRole
            ? []
            : criteria.map((item) => omit(item, "uuid")),
        competencyId: competencyId,
        appliesToTeams: appliesToTeams.map(({ id }) => id),
        appliesToCareerTracks: appliesToCareerTracks.map(({ id }) => id),
      },
      onError,
    });
  }, [
    appliesToTeams,
    appliesToCareerTracks,
    competencyData,
    competencyId,
    criteria,
    onError,
    saveCompetency,
  ]);

  const handleChangeAppliesTo = useCallback(
    (opt: SelectOption<CompetencyAppliesTo>) => {
      setIsFormDirty(true);
      setCompetencyData({
        ...competencyData,
        appliesTo: opt.value,
        appliesToManagementRoles:
          opt.value === CompetencyAppliesTo.SomeRoles
            ? competencyData.appliesToManagementRoles
            : false,
        appliesToIcRoles:
          opt.value === CompetencyAppliesTo.SomeRoles
            ? competencyData.appliesToIcRoles
            : false,
      });
      setAppliesToTeams(
        opt.value === CompetencyAppliesTo.SomeRoles ? appliesToTeams : []
      );
      setAppliesToCareerTracks(
        opt.value === CompetencyAppliesTo.SomeRoles ? appliesToCareerTracks : []
      );
    },
    [appliesToCareerTracks, appliesToTeams, competencyData]
  );

  const canSave = useMemo(() => {
    return (
      competencyData.title &&
      competencyData.title.trim() !== "" &&
      criteria.every((criteria) => !isEmptyValue(criteria.content))
    );
  }, [competencyData, criteria]);

  return (
    <>
      <Prompt
        when={isFormDirty}
        message="Are you sure you want to leave? The changes you made will be lost."
      />
      {loading && (
        <div className="flex justify-center mt-8 mb-12">
          <Loading />
        </div>
      )}
      {!loading && !data?.competency && !isNew && (
        <div className="flex justify-center mt-8">
          <div className="text-gray-500">
            {label("competency", { capitalize: true })} not found
          </div>
        </div>
      )}

      {!loading && (data?.competency || isNew) && (
        <>
          <div className="flex justify-between items-center mb-6">
            <Heading
              small
              title={`${!isNew ? "Edit" : "New"} ${label("competency")}`}
              backUrl={
                isInModal
                  ? undefined
                  : `/settings/organization/${organizationId}/competencies`
              }
            />
            <Controls
              isSaving={isSaving}
              onCancel={onCancel}
              canSave={!!canSave}
              onSave={handleSaveCompetency}
            />
          </div>
          <div className="my-6">
            <div className="flex flex-col gap-8">
              <div className="flex">
                <div className="mt-5 text-gray-500 text-xs uppercase font-semibold w-48">
                  Name
                </div>
                <Input
                  aria-label="Competency title input"
                  disabled={isSaving}
                  className="w-fit flex-1 mt-1"
                  value={competencyData.title ?? ""}
                  onChange={(evt) => {
                    setIsFormDirty(true);
                    setCompetencyData({
                      ...competencyData,
                      title: evt.target.value,
                    });
                  }}
                />
              </div>
              <div className="flex">
                <div className="mt-5 text-gray-500 text-xs uppercase font-semibold w-48">
                  Description
                </div>
                <TextareaWysiwyg
                  editable={!isSaving}
                  className="mt-1 bg-white"
                  value={competencyData.description}
                  deps={[competencyData.title]}
                  onChangeValue={(description) => {
                    setIsFormDirty(true);
                    setCompetencyData({
                      ...competencyData,
                      description,
                    });
                  }}
                />
              </div>
              <div className="flex">
                <div className="mt-3 text-gray-500 text-xs uppercase font-semibold w-48">
                  Applies to
                </div>
                <Select
                  disabled={isSaving}
                  width="w-56"
                  value={competencyData.appliesTo}
                  options={competencyAppliesToOptions}
                  onChange={handleChangeAppliesTo}
                  aria-label="Competency applies to select"
                />
              </div>
              {competencyData.appliesTo === CompetencyAppliesTo.SomeRoles && (
                <MotionDiv initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                  <>
                    <div className="flex">
                      <div className="mt-3 text-gray-500 text-xs uppercase font-semibold w-48"></div>
                      <div className="flex items-center gap-8">
                        <label className="flex items-center gap-1.5 cursor-pointer">
                          <input
                            type="checkbox"
                            checked={
                              competencyData.appliesToManagementRoles ?? false
                            }
                            onChange={(e) => {
                              setIsFormDirty(true);
                              setCompetencyData({
                                ...competencyData,
                                appliesToManagementRoles: e.target.checked,
                              });
                            }}
                          />
                          All managers
                        </label>
                        <label className="flex items-center gap-1.5 cursor-pointer">
                          <input
                            type="checkbox"
                            checked={competencyData.appliesToIcRoles ?? false}
                            onChange={(e) => {
                              setIsFormDirty(true);
                              setCompetencyData({
                                ...competencyData,
                                appliesToIcRoles: e.target.checked,
                              });
                            }}
                          />
                          All individual contributors
                        </label>
                      </div>
                    </div>
                    <div className="flex mt-4">
                      <div className="mt-3 text-gray-500 text-xs uppercase font-semibold w-48"></div>
                      <div className="flex items-center gap-4">
                        <div>
                          {label("team", { capitalize: true, pluralize: true })}
                          :
                        </div>
                        <TeamPicker
                          canChange
                          teams={appliesToTeams}
                          onAddTeam={(team) => {
                            setIsFormDirty(true);
                            setAppliesToTeams([...appliesToTeams, team]);
                          }}
                          onRemoveTeam={(team) => {
                            setIsFormDirty(true);
                            setAppliesToTeams(
                              appliesToTeams.filter((t) => t.id !== team.id)
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex mt-4">
                      <div className="mt-3 text-gray-500 text-xs uppercase font-semibold w-48"></div>
                      <div className="flex items-center gap-4">
                        <div>Career Tracks:</div>
                        <CareerTrackPicker
                          canChange
                          careerTracks={appliesToCareerTracks}
                          onAddCareerTrack={(track) => {
                            setIsFormDirty(true);
                            setAppliesToCareerTracks([
                              ...appliesToCareerTracks,
                              track,
                            ]);
                          }}
                          onRemoveCareerTrack={(track) => {
                            setIsFormDirty(true);
                            setAppliesToCareerTracks(
                              appliesToCareerTracks.filter(
                                (t) => t.id !== track.id
                              )
                            );
                          }}
                        />
                      </div>
                    </div>
                  </>
                </MotionDiv>
              )}
              <div className="flex">
                <div className="mt-3 text-gray-500 text-xs uppercase font-semibold w-48">
                  Criteria uniqueness
                </div>
                <Select
                  disabled={isSaving}
                  width="w-56"
                  value={competencyData.criteriaUniqueness}
                  options={criteriaUniquenessOptions}
                  onChange={(opt) => {
                    setIsFormDirty(true);
                    setCompetencyData({
                      ...competencyData,
                      criteriaUniqueness: opt.value,
                    });
                  }}
                  aria-label="Competency criteria uniqueness select"
                />
              </div>
            </div>

            {competencyData.criteriaUniqueness ===
            CompetencyCriteriaUniqueness.SameAcrossRoles ? (
              <MotionDiv
                key="criteria"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              >
                <div className="font-bold mt-8">Criteria</div>
                <div className="border rounded-md mt-4">
                  {roleLevelIterator(levelCount).map((level) => (
                    <div className="flex border-b last:border-b-0" key={level}>
                      <div className="bg-gray-100 p-4 font-semibold border-r w-48">
                        Level {level}
                      </div>
                      <div className="p-4 flex-1">
                        <CriteriaEditor
                          disabled={isSaving}
                          editable
                          onAddCriteria={() => {
                            setIsFormDirty(true);
                            setCriteria([
                              ...criteria,
                              { level, content: "", uuid: uuidv4() },
                            ]);
                          }}
                          onPasteMultipleCriteria={(items) => {
                            setIsFormDirty(true);
                            const updatingCriteriaUuids = compact(
                              items.map(({ uuid }) => uuid)
                            );
                            setCriteria([
                              ...criteria.filter(
                                (criteria) =>
                                  !updatingCriteriaUuids.includes(criteria.uuid)
                              ),
                              ...items.map((item) => ({
                                level,
                                content: item.content,
                                uuid: item.uuid || uuidv4(),
                              })),
                            ]);
                          }}
                          onDeleteCriteria={(uuid) => {
                            setIsFormDirty(true);
                            setCriteria(
                              criteria.filter(
                                (criteria) => criteria.uuid !== uuid
                              )
                            );
                          }}
                          onCriteriaChange={(uuid, content) => {
                            setIsFormDirty(true);
                            setCriteria((prev) =>
                              prev.map((item) => {
                                if (item.uuid === uuid) {
                                  return {
                                    ...item,
                                    content,
                                  };
                                }
                                return item;
                              })
                            );
                          }}
                          criteria={criteria.filter((c) => c.level === level)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <Button
                  className="mt-4"
                  disabled={isSaving}
                  text="Add Level"
                  onClick={() => setLevelCount((levelCount) => levelCount + 1)}
                />
              </MotionDiv>
            ) : (
              <MotionDiv
                initial={{ opacity: 0 }}
                key="no-criteria"
                animate={{ opacity: 1 }}
                className="flex justify-center mt-10"
              >
                <div className="text-gray-500">
                  {label("competency", { capitalize: true })} criteria will be
                  defined on the role
                </div>
              </MotionDiv>
            )}

            <Controls
              isSaving={isSaving}
              onCancel={onCancel}
              canSave={!!canSave}
              onSave={handleSaveCompetency}
            />
          </div>
        </>
      )}
    </>
  );
};

export default CompetencyForm;
