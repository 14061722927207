import { useMenuState } from "@szhsin/react-menu";
import moment from "moment";
import { MouseEvent, useState } from "react";
import { CalendarEventFragmentFragment } from "types/graphql-schema";

import MeetingDropdownMenu from "@apps/meeting-dropdown-menu/meeting-dropdown-menu";
import { classNames } from "@helpers/css";
import { formatTime, getUrl, isTailwindLgActive } from "@helpers/helpers";

const MonthEvent = ({
  event,
  onClickMeetingLink,
}: {
  event: CalendarEventFragmentFragment & { isDisabled: boolean };
  onClickMeetingLink: (meetingId: number) => void;
}) => {
  const [menuState, toggleMenu] = useMenuState();
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const handleContextMenu = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setAnchorPoint({ x: e.clientX, y: e.clientY });
    toggleMenu(true);
  };

  const handleClickMeetingLink = (e: MouseEvent<HTMLAnchorElement>) => {
    if (isTailwindLgActive()) {
      e.preventDefault();
      e.stopPropagation();
      onClickMeetingLink(event.id);
    }
  };

  // RENDER
  return (
    <li key={event.id}>
      {event.canRead.permission && event.meeting?.meetingGroup?.facilitator && (
        <MeetingDropdownMenu
          meetingGroup={{
            id: event.meetingGroupId,
            isFormalOneonone: !!event.meeting.meetingGroup.isFormalOneonone,
            facilitator: event.meeting.meetingGroup.facilitator,
          }}
          meeting={event.meeting}
          isContextMenu={true}
          onToggleMenu={toggleMenu}
          anchorPoint={anchorPoint}
          {...menuState}
        />
      )}
      <a
        href={getUrl({
          meetingId: event.id,
          meetingGroupId: event.meetingGroupId,
        })}
        className={classNames(
          "group flex rounded mb-1 px-1 border",
          event.isDisabled
            ? "bg-gray-50 border-transparent"
            : event.meeting?.draft
            ? "bg-amber-50 hover:bg-amber-100 border-dashed border-amber-300"
            : !event.canRead.permission
            ? "bg-slate-50 border-transparent"
            : "bg-blue-50 hover:bg-blue-100 border-transparent"
        )}
        onContextMenu={handleContextMenu}
        onClick={handleClickMeetingLink}
      >
        <div
          className={classNames(
            "flex-auto truncate font-medium",
            event.isDisabled
              ? "text-gray-400 line-through"
              : event.meeting?.draft
              ? "text-amber-800"
              : !event.canRead.permission
              ? "text-gray-500"
              : "text-blue-700"
          )}
        >
          {event.title}
        </div>
        <time
          dateTime={event.startDatetime || undefined}
          className={classNames(
            "ml-2 hidden flex-none @6xl/calendar:block",
            event.isDisabled
              ? "text-gray-400 line-through"
              : event.meeting?.draft
              ? "text-amber-800"
              : !event.canRead.permission
              ? "text-gray-400"
              : "text-blue-600"
          )}
        >
          {formatTime(moment(event.startDatetime).format("h:mma"))}
        </time>
      </a>
    </li>
  );
};

export default MonthEvent;
