import {
  FloatingPortal,
  autoUpdate,
  flip,
  offset,
  shift,
  useDelayGroup,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  useRole,
  useTransitionStyles,
} from "@floating-ui/react";
import * as Sentry from "@sentry/react";
import {
  Children,
  HTMLProps,
  cloneElement,
  createContext,
  forwardRef,
  isValidElement,
  useContext,
  useMemo,
  useState,
} from "react";

export function useTooltip() {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(false);

  const data = useFloating({
    placement: "top",
    open: uncontrolledOpen,
    onOpenChange: setUncontrolledOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(5), flip(), shift()],
  });

  const context = data.context;
  const { delay } = useDelayGroup(context);
  const hover = useHover(context, {
    move: false,
    enabled: true,
    delay,
  });
  const focus = useFocus(context, { enabled: true });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });
  const interactions = useInteractions([hover, focus, dismiss, role]);

  return useMemo(
    () => ({
      open: uncontrolledOpen,
      setOpen: setUncontrolledOpen,
      ...interactions,
      ...data,
    }),
    [uncontrolledOpen, setUncontrolledOpen, interactions, data]
  );
}

type ContextType = ReturnType<typeof useTooltip> | null;

const TooltipContext = createContext<ContextType>(null);

export const useTooltipState = () => {
  const context = useContext(TooltipContext);
  if (context == null) {
    throw new Error("Tooltip components must be wrapped in <Tooltip />");
  }
  return context;
};

export const TooltipTrigger = forwardRef<HTMLElement, HTMLProps<HTMLElement>>(
  function TooltipTrigger({ children, ...props }, propRef) {
    const state = useTooltipState();

    const childrenRef = (children as any).ref;
    const ref = useMergeRefs([state.refs.setReference, propRef, childrenRef]);

    if (isValidElement(children)) {
      return cloneElement(
        children,
        state.getReferenceProps({
          ref,
          ...props,
          ...children.props,
          "data-state": state.open ? "open" : "closed",
        })
      );
    }

    if (import.meta.env.DEV) {
      throw new Error("TooltipTrigger must be a valid React element");
    } else {
      Sentry.captureException("TooltipTrigger must be a valid React element");
      return children;
    }
  }
);

export const TooltipContent = forwardRef<
  HTMLDivElement,
  { children: string | TrustedHTML }
>(function TooltipContent({ children, ...props }, propRef) {
  const state = useTooltipState();
  const ref = useMergeRefs([state.refs.setFloating, propRef]);

  const { isMounted, styles } = useTransitionStyles(state.context, {
    duration: 0,
    initial: { opacity: 0 },
  });

  if (!isMounted) return null;

  return (
    <FloatingPortal>
      <div
        ref={ref}
        className="bg-black/80 text-white rounded-md text-sm py-1 px-2 max-w-64 z-tooltip"
        style={{
          ...state.floatingStyles,
          ...styles,
        }}
        dangerouslySetInnerHTML={{ __html: children }}
        {...state.getFloatingProps(props)}
      />
    </FloatingPortal>
  );
});

export const Tooltip = ({
  children,
  text,
}: {
  children: any;
  text?: null | string | TrustedHTML;
}) => {
  const tooltip = useTooltip();

  if (!text) {
    return children;
  }
  if (!waffle.flag_is_active("new-tooltip")) {
    // https://react-tooltip.com/
    return Children.map(children, function (child) {
      return cloneElement(child, {
        "data-tooltip-id": "topicflow-tooltip",
        "data-tooltip-html": text,
        "data-tooltip-delay-show": 100,
        "data-tooltip-delay-hide": 0,
        "data-tooltip-class-name": "topicflow-tooltip",
      });
    });
  } else {
    return (
      <TooltipContext.Provider value={tooltip}>
        <TooltipTrigger>{children}</TooltipTrigger>
        <TooltipContent>{text}</TooltipContent>
      </TooltipContext.Provider>
    );
  }
};

export default Tooltip;
