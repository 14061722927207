import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { useLink } from "@components/link/link";

import CompetencyForm from "./competency-form";

const CompetencyFormPage = ({ organizationId }: { organizationId: number }) => {
  const link = useLink();
  const params = useParams<{ competencyId: string }>();
  const competencyId = useMemo(() => {
    if (params.competencyId?.match(/\d+/)) {
      return parseInt(params.competencyId);
    }
    return null;
  }, [params]);

  return (
    <CompetencyForm
      organizationId={organizationId}
      competencyId={competencyId}
      onCompetencySaved={() =>
        link.redirect(`/settings/organization/${organizationId}/competencies`)
      }
      onCancel={() =>
        link.redirect(`/settings/organization/${organizationId}/competencies`)
      }
    />
  );
};

export default CompetencyFormPage;
