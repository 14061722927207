import { useMemo } from "react";

import Avatar, { AvatarProps } from "@components/avatar/avatar";
import Tooltip from "@components/tooltip/tooltip";
import { classNames } from "@helpers/css";

type AvatarsUser = AvatarProps["user"] & {
  disabled?: boolean;
  tooltipSuffix?: string;
};

type Props = Omit<AvatarProps, "user"> & {
  users: AvatarsUser[];
  max: number;
  className?: string;
  avatarClassName?: string;
  extraClassName?: string;
};

const Avatars: React.FC<Props> = ({
  users,
  className,
  avatarClassName,
  extraClassName = "",
  max,
  ...props
}) => {
  const maxWithExtraPill = Math.max(max - 1, 1);
  const extraUserNames = useMemo(
    () =>
      users
        .slice(max - 1)
        .map((user) => user.name)
        .join(", "),
    [users, max]
  );
  return (
    <div className={className} aria-label="Avatars">
      {users.slice(0, maxWithExtraPill).map((user) => (
        <div
          key={user.name}
          className={classNames("shrunk-0", avatarClassName)}
        >
          <Avatar
            user={user}
            disabled={user.disabled}
            tooltipSuffix={user.tooltipSuffix}
            {...props}
          />
        </div>
      ))}
      {users.length > maxWithExtraPill && (
        <span
          className={classNames(
            `text-gray-500 text-sm tracking-tight shrunk-0 select-none`,
            extraClassName
          )}
        >
          <Tooltip text={extraUserNames}>
            <span className="self-center shrink-0 leading-3 h-3">
              +{users.length - maxWithExtraPill}
            </span>
          </Tooltip>
        </span>
      )}
    </div>
  );
};

export default Avatars;
