import {
  PencilIcon,
  PlusIcon,
  StarIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { useState } from "react";
import {
  CareerTrackRoleType,
  SaveOrgWideCareerTrackMutationVariables,
} from "types/graphql-schema";

import Avatars from "@components/avatar/avatars";
import Button, { buttonTheme } from "@components/button/button";
import Layout from "@components/layout/layout";
import Tooltip from "@components/tooltip/tooltip";
import { UserComboboxOption } from "@components/user-combobox/user-combobox-list";
import { classNames } from "@helpers/css";

import { CareerTrackFormRole } from "./career-track-form";
import RoleDetailModal from "./role-detail-modal";

const RoleHeading = ({
  level,
  disabled,
  role,
  onRoleChange,
  onNewRole,
  onRemoveRole,
  associatedTeam,
  careerTrackData,
}: {
  associatedTeam: UserComboboxOption | null;
  careerTrackData: Omit<SaveOrgWideCareerTrackMutationVariables, "roles">;
  level: number;
  disabled: boolean;
  role?: CareerTrackFormRole;
  onRoleChange: (role: CareerTrackFormRole) => void;
  onNewRole: () => void;
  onRemoveRole: () => void;
}) => {
  const [isShowingEditRoleModal, setShowingEditRoleModal] = useState(false);
  return (
    <>
      {isShowingEditRoleModal && role && (
        <RoleDetailModal
          role={role}
          onClose={() => {
            setShowingEditRoleModal(false);
          }}
          onRoleChange={onRoleChange}
          level={level}
          associatedTeam={associatedTeam}
          careerTrackData={careerTrackData}
        />
      )}

      <div className="flex items-center justify-between">
        <div className="text-xs text-gray-400">Level {level}</div>
        {role ? (
          <div className="flex items-center gap-2">
            <Button
              theme={buttonTheme.text}
              icon
              className={classNames(
                "text-inherit",
                Layout.headerIconButtonClassName
              )}
              disabled={disabled}
              onClick={() => setShowingEditRoleModal(true)}
              tooltip="Edit role"
            >
              <PencilIcon className="h-4 w-4" />
            </Button>
            <Button
              theme={buttonTheme.text}
              icon
              className={classNames(
                "text-inherit",
                Layout.headerIconButtonClassName
              )}
              disabled={disabled}
              onClick={onRemoveRole}
              tooltip="Delete role"
            >
              <TrashIcon className="h-4 w-4" />
            </Button>
          </div>
        ) : (
          <Button
            theme={buttonTheme.text}
            icon
            className={classNames(
              "text-inherit",
              Layout.headerIconButtonClassName
            )}
            disabled={disabled}
            onClick={() => {
              onNewRole();
              setShowingEditRoleModal(true);
            }}
            tooltip="Add role"
          >
            <PlusIcon className="h-4 w-4" />
          </Button>
        )}
      </div>
      <div className="flex items-center gap-2">
        {role && (
          <div className="font-bold">
            {role.title?.trim() === "" ? "(Untitled)" : role.title}
          </div>
        )}
        {role?.roleType === CareerTrackRoleType.Management && (
          <Tooltip text="Management role">
            <span>
              <StarIcon className="mt-0.5 h-4 w-4" />
            </span>
          </Tooltip>
        )}
      </div>
      {role && role.assignToUsers.length > 0 ? (
        <div className="mt-2">
          <Avatars
            max={10}
            users={role.assignToUsers}
            className={classNames(
              "flex items-center",
              role.assignToUsers.length === 1 ? "w-5" : "pl-1" // otherwise single avatar gets squished
            )}
            avatarClassName={classNames(
              "contrast-100 rounded-full",
              role.assignToUsers.length > 1 && "-ml-1"
            )}
            extraClassName="w-6 h-6 rounded-full -ml-1.5 text-2xs flex items-center justify-center z-1 bg-gray-100 text-gray-600"
            size="5"
          />
        </div>
      ) : role && role.assignToUsers.length === 0 ? (
        <div className="h-5 mt-2 text-xs italic text-gray-400">
          Nobody assigned
        </div>
      ) : null}
    </>
  );
};

export default RoleHeading;
