import { useQuery } from "@apollo/client";
import moment from "moment";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  ComplianceProgramRecurrence,
  ComplianceProgramState,
  GetComplianceProgramsQuery,
  GetComplianceProgramsQueryVariables,
} from "types/graphql-schema";
import { DateRangeEnum } from "types/topicflow";

import { AllDateRangeEnum } from "@apps/goal-alignment/goal-alignment";
import useLabel from "@apps/use-label/use-label";
import useUiPreferenceCache from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import {
  currentOrganizationVar,
  currentUserVar,
  isAdminVar,
} from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Heading from "@components/heading/heading";
import Input from "@components/input/input";
import AppLink, { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import { Select, SelectOption } from "@components/select/select";
import ManagedTable from "@components/table/managed-table";
import useDebounce from "@components/use-debounce/use-debounce";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull, dateRangeToDateArray } from "@helpers/helpers";
import { capitalize } from "@helpers/string";

import getComplianceProgramsQuery from "../graphql/get-compliance-programs-query";
import ComplianceProgramRecurrencTooltip from "./compliance-program-recurrence-tooltip";

type DateRangeType = AllDateRangeEnum | DateRangeEnum;

const defaultDateRange = DateRangeEnum.thisQuarter;

const CompliancePrograms = () => {
  const organization = currentOrganizationVar();
  const location = useLocation();
  const currentUser = currentUserVar();
  const link = useLink();
  const isAdmin = isAdminVar();
  const label = useLabel();
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const searchParams = new URLSearchParams(location.search);
  const [search, setSearch] = useState(searchParams.get("search") || "");
  const debouncedSearch = useDebounce(search, 500);
  const defaultOnlyProgramsIAmPartOf =
    isAdmin && searchParams.has("onlyProgramsIAmPartOf")
      ? searchParams.get("onlyProgramsIAmPartOf") === "1"
      : uiPreferenceCache.programsShowOnlyMyProgramsFlag;
  const [onlyProgramsIAmPartOf, setOnlyProgramsIAmPartOf] = useState(
    defaultOnlyProgramsIAmPartOf
  );
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeType>(
    (searchParams.get("selectedDateRange") as DateRangeType) || defaultDateRange
  );
  const dateRanges =
    selectedDateRange === AllDateRangeEnum.all
      ? undefined
      : dateRangeToDateArray({
          range: selectedDateRange,
          quarterStartMonth: organization.quarterStartMonth,
        });

  const { data, loading, fetchMore } = useQuery<
    GetComplianceProgramsQuery,
    GetComplianceProgramsQueryVariables
  >(getComplianceProgramsQuery, {
    variables: {
      search: debouncedSearch,
      applicableUser: onlyProgramsIAmPartOf ? currentUser.id : undefined,
      organizationId: organization.id,
      dueDateGte: dateRanges ? dateRanges[0] : null,
      dueDateLte: dateRanges ? dateRanges[1] : null,
    },
    onError: onNotificationErrorHandler(),
  });

  const compliancePrograms = useMemo(() => {
    return data?.compliancePrograms
      ? assertEdgesNonNull(data.compliancePrograms)
      : [];
  }, [data]);

  const anytimeOption = {
    value: AllDateRangeEnum.all,
    label: `Due anytime`,
    selectedLabel: `Due anytime`,
    selected: selectedDateRange === null,
  };
  const dateRangeOptions = [
    anytimeOption,
    ...Object.values(DateRangeEnum).map((dateRange) => ({
      value: dateRange,
      label: label(dateRange, { capitalize: true }),
      selected: selectedDateRange === dateRange,
      description: dateRangeToDateArray({
        range: dateRange,
        quarterStartMonth: organization.quarterStartMonth,
      })
        .map((date) => moment(date).format("ll"))
        .join(" - "),
    })),
  ];

  const handleChangeDateRange = (option: SelectOption<DateRangeType>) => {
    setSelectedDateRange(option.value);
  };

  const handleChangeOnlyProgramsIAmPartOf = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const programsShowOnlyMyProgramsFlag = e.target.checked;
    setOnlyProgramsIAmPartOf(programsShowOnlyMyProgramsFlag);
    saveUiPreference({ programsShowOnlyMyProgramsFlag });
  };

  const handleClickLoadMore = () => {
    if (data?.compliancePrograms.pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          after: data.compliancePrograms.pageInfo.endCursor,
          merge: true,
        },
      });
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams();
    if (search) {
      searchParams.set("search", search);
    }
    if (isAdmin && onlyProgramsIAmPartOf) {
      searchParams.set(
        "onlyProgramsIAmPartOf",
        onlyProgramsIAmPartOf ? "1" : "0"
      );
    }
    if (selectedDateRange !== defaultDateRange) {
      searchParams.set("selectedDateRange", selectedDateRange);
    }
    link.replace(
      `/programs${searchParams.toString() ? `?${searchParams.toString()}` : ""}`
    );
  }, [search, onlyProgramsIAmPartOf, selectedDateRange]);

  return (
    <div className="p-6 w-full" aria-label="Assessments > compliance programs">
      <div className="flex w-full justify-between items-center">
        <Heading small title="All programs" />
        {isAdmin && (
          <Button
            theme={buttonTheme.default}
            to="/programs/new"
            text="New program"
          />
        )}
      </div>

      <div className="mt-4">
        <div className="flex items-center gap-4 mb-4">
          <Input
            className="max-w-48"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
          />
          <Select<DateRangeType>
            options={dateRangeOptions}
            value={selectedDateRange}
            onChange={handleChangeDateRange}
          />
          {isAdmin && (
            <label className="flex items-center gap-1 text-sm cursor-pointer">
              <input
                type="checkbox"
                checked={onlyProgramsIAmPartOf}
                onChange={handleChangeOnlyProgramsIAmPartOf}
              />
              My programs
            </label>
          )}
          {(loading || search !== debouncedSearch) && <Loading size={5} mini />}
          <div className="flex-1" />
        </div>
        <div>
          <ManagedTable
            sortingEnabled
            sortKey="state"
            sortDir="asc"
            data={compliancePrograms}
            rowClassName={(row) =>
              row.state === ComplianceProgramState.Draft ? "bg-gray-50" : ""
            }
            emptyPlaceholder={<div className="text-gray-500">No programs.</div>}
            columns={[
              {
                header: "Title",
                field: "title",
                sortKey: "title",
                render: (row) => (
                  <div className="flex items-center gap-2">
                    {waffle.flag_is_active("new-program-reporting-page") ? (
                      <AppLink
                        className="text-blue-link hover:underline"
                        to={`/programs/${row.id}/reporting`}
                      >
                        {row.title}
                      </AppLink>
                    ) : (
                      <div>{row.title}</div>
                    )}
                    {row.recurrence !== ComplianceProgramRecurrence.None && (
                      <ComplianceProgramRecurrencTooltip
                        recurrence={row.recurrence}
                      />
                    )}
                  </div>
                ),
              },
              {
                header: "State",
                render: (row) => capitalize(row.state),
                sortKey: "state",
              },
              {
                header: "Due date",
                render: (row) =>
                  row.ongoing
                    ? "Ongoing"
                    : moment(row.dueDate).format("MMM D, YYYY"),
                sortKey: "duedate",
                value: (row) => moment(row.dueDate).format(),
              },
              {
                header: "",
                render: (row) =>
                  !waffle.flag_is_active("new-program-reporting-page") && (
                    <div className="flex items-center gap-4">
                      <Button
                        theme={buttonTheme.lightBlue}
                        to={`/programs/${row.id}`}
                      >
                        View
                      </Button>
                      {row.canUpdate?.permission && (
                        <Button
                          theme={buttonTheme.lightBlue}
                          to={`/programs/${row.id}/admin`}
                        >
                          Manage
                        </Button>
                      )}
                    </div>
                  ),
                cellClassName: "text-right",
              },
            ]}
          />
          {data?.compliancePrograms.pageInfo.hasNextPage && (
            <div className="flex justify-center mt-6">
              <Button text="Load more" small onClick={handleClickLoadMore} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompliancePrograms;
