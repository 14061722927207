import { gql } from "@apollo/client";

export default gql`
  query getAssessmentGroups($organizationId: Int!, $archived: Boolean) {
    assessmentGroups(organizationId: $organizationId, archived: $archived) {
      edges {
        node {
          id
          title
          created
          delivery
          providers
          anonymity
          archived
          creator {
            id
            name
          }
          canUpdate {
            permission
            reason
          }
          canDelete {
            permission
            reason
          }
        }
      }
    }
  }
`;
