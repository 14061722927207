import { gql } from "@apollo/client";

export default gql`
  fragment AssessmentDeliveryAssessmentGroupSection on AssessmentGroupSectionNode {
    id
    title
    description
    questions {
      edges {
        node {
          id
          question {
            ... on RangeAssessmentQuestionNode {
              id
              title
              description
              startValue
              endValue
              labels
              labelDescriptions
              isCommentMandatory
              responses
              questionType
              responseVisibility
            }
            ... on MultiChoiceAssessmentQuestionNode {
              id
              title
              description
              options
              optionDescriptions
              isCommentMandatory
              responses
              questionType
              responseVisibility
            }
            ... on TextAssessmentQuestionNode {
              id
              title
              description
              isCommentMandatory
              responses
              questionType
              responseVisibility
            }
          }
          weight
        }
      }
    }
  }

  query getAssessmentDelivery($assessmentDeliveryId: Int!) {
    assessmentDelivery(id: $assessmentDeliveryId) {
      id
      state
      summary
      excludedAnswerIds
      deliveryDatetime
      dueDate
      periodStartDate
      periodEndDate
      canUpdate {
        permission
        reason
      }
      creator {
        id
        name
      }
      target {
        id
        name
        email
        firstName
        avatar
      }
      complianceProgram {
        id
        title
      }
      template {
        id
        assessmentType
        anonymity
        isQuestionWeightingEnabled
        hasSelfAssessment
        questionSets {
          edges {
            node {
              id
              title
              sections {
                edges {
                  node {
                    ...AssessmentDeliveryAssessmentGroupSection
                  }
                }
              }
            }
          }
        }
      }
      answers {
        edges {
          node {
            ... on RangeAssessmentAnswerNode {
              id
              responder {
                id
                name
                email
                firstName
                avatar
              }
              question {
                id
              }
              integerAnswer
              comment
            }
            ... on MultiChoiceAssessmentAnswerNode {
              id
              responder {
                id
                name
                email
                firstName
                avatar
              }
              question {
                id
              }
              choices
              comment
            }
            ... on TextAssessmentAnswerNode {
              id
              responder {
                id
                name
                email
                firstName
                avatar
              }
              question {
                id
              }
              textAnswer
              comment
            }
          }
        }
      }
    }
  }
`;
