import { gql } from "@apollo/client";

export const DashboardInsightFragment = gql`
  fragment DashboardInsight on InsightNode {
    id
    type
    report {
      id
      firstName
      name
      avatar
      email
    }
    manager {
      id
      firstName
      name
      avatar
      email
    }
    artifact {
      id
      ... on GoalArtifactNode {
        id
        title
        artifactType
        progressType
      }
      ... on FeedbackArtifactNode {
        recipients(first: 1) {
          edges {
            node {
              id
              name
              avatar
            }
          }
        }
      }
    }
    matchingOneononesForComplianceProgram(first: 1) {
      edges {
        node {
          id
          meetingGroupId
        }
      }
    }
    unscheduledMatchingOneononesForComplianceProgram(first: 1) {
      edges {
        node {
          id
          meetingGroupId
        }
      }
    }
    assessmentGroup {
      id
      title
    }
    complianceProgram {
      id
      title
      requiredTopicTemplates(first: 1) {
        edges {
          node {
            id
            title
          }
        }
      }
    }
  }
`;

export default gql`
  ${DashboardInsightFragment}
  query getDashboardInsights($personId: Int!) {
    user(userId: $personId) {
      id
      insights {
        totalCount
        edges {
          node {
            ...DashboardInsight
          }
        }
      }
    }
  }
`;
