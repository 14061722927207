import { gql } from "@apollo/client";

export default gql`
  fragment MeetingViewComplainceProgramAssessmentTemplateNewPage on AssessmentTemplateNode {
    id
    assessmentType
    assessments {
      edges {
        node {
          id
          responder {
            id
          }
          target {
            id
            name
          }
        }
      }
    }
  }
  query getMeetingInsightsAndRequirementsNewPageQuery($meetingId: Int) {
    meeting(meetingId: $meetingId) {
      id
      insights {
        edges {
          node {
            id
            type
            manager {
              id
            }
            report {
              id
            }
            complianceProgram {
              id
              title
              requiredTopicTemplates(first: 1) {
                edges {
                  node {
                    id
                    title
                  }
                }
              }
              performanceAssessmentTemplate {
                ...MeetingViewComplainceProgramAssessmentTemplateNewPage
              }
              managerAssessmentTemplate {
                ...MeetingViewComplainceProgramAssessmentTemplateNewPage
              }
              peerAssessmentTemplate {
                ...MeetingViewComplainceProgramAssessmentTemplateNewPage
              }
            }
            assessmentTemplate {
              id
            }
          }
        }
      }
      relatedAssessments {
        edges {
          node {
            id
            complianceProgram {
              id
              title
            }
          }
        }
      }
    }
  }
`;
