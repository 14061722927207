import { useMutation } from "@apollo/client";
import { FormEvent, useState } from "react";

import { currentUserVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Button, { buttonTheme } from "@components/button/button";
import Input from "@components/input/input";
import Loading from "@components/loading/loading";
import { useNotificationError } from "@components/use-error/use-error";

import updateUserMutation from "../../graphql/update-user-mutation";

const Name = () => {
  const currentUser = currentUserVar();
  const [firstName, setFirstName] = useState(currentUser.firstName);
  const [lastName, setLastName] = useState(currentUser.lastName);
  const [updateUser, { loading }] = useMutation(updateUserMutation);
  const { onError } = useNotificationError();

  const handleSubmitUserForm = (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (!firstName.trim() || !lastName.trim()) {
      return;
    }
    updateUser({
      variables: {
        userId: currentUser.id,
        firstName,
        lastName,
      },
      onError,
    });
  };

  const handleCancel = () => {
    setFirstName(currentUser.firstName);
    setLastName(currentUser.lastName);
  };

  return (
    <form onSubmit={handleSubmitUserForm}>
      <div className="flex items-center gap-6">
        <Avatar user={currentUser} size="16" />
        <div>
          <Input
            required
            label="First name"
            aria-label="Account first name input"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div>
          <Input
            required
            label="Last name"
            aria-label="Account last name input"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
      </div>
      {firstName.trim().length > 0 &&
        lastName.trim().length > 0 &&
        (firstName !== currentUser.firstName ||
          lastName !== currentUser.lastName) && (
          <div className="mt-4 flex items-centee gap-2 ml-22">
            <Button
              type="submit"
              small
              text="Save"
              theme={buttonTheme.primary}
              disabled={loading}
              aria-label="Save name button"
            />
            <Button
              type="button"
              small
              text="Cancel"
              disabled={loading}
              onClick={handleCancel}
            />
            {loading && <Loading size={5} />}
          </div>
        )}
    </form>
  );
};

export default Name;
