import { useMutation, useQuery } from "@apollo/client";
import { Popover } from "@headlessui/react";
import { isArray, max, omit, reduce } from "lodash";
import { useCallback, useMemo, useRef, useState } from "react";
import { Prompt, useParams } from "react-router-dom";
import {
  CareerTrackRoleInput,
  CareerTrackRoleType,
  CompetencyCriteriaInput,
  CompetencyCriteriaUniqueness,
  CompetencyNode,
  GetOrganizationCareerTrackQuery,
  GetOrganizationCareerTrackQueryVariables,
  GetOrganizationCompetenciesQuery,
  GetOrganizationCompetenciesQueryVariables,
  OrganizationCareerTrackFragmentFragment,
  OrganizationCompetencyCriteriaFragment,
  RoleResponsibilityInput,
  SaveOrgWideCareerTrackMutation,
  SaveOrgWideCareerTrackMutationVariables,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";
import { v4 as uuidv4 } from "uuid";

import createOrUpdateCareerTrackMutation from "@apps/org-settings/graphql/create-or-update-career-track-mutation";
import getOrganizationCareerTrackQuery from "@apps/org-settings/graphql/get-organization-career-track-query";
import getOrganizationCompetenciesQuery from "@apps/org-settings/graphql/get-organization-competencies-query";
import useLabel from "@apps/use-label/use-label";
import { successNotificationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import ComboboxGeneric from "@components/combobox/generic-combobox";
import Heading from "@components/heading/heading";
import Input from "@components/input/input";
import { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import { useNotificationError } from "@components/use-error/use-error";
import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import {
  UserComboboxOption,
  UserComboboxOptionType,
} from "@components/user-combobox/user-combobox-list";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";
import useUrlQueryParams from "@helpers/hooks/use-url-query-params";

import CompetencyFormModal from "../competency-form-modal";
import CriteriaEditor from "../criteria-editor";
import {
  doesCompetencyApplyToCareerTrack,
  emptyRoleColWidth,
  roleColWidth,
  roleLevelIterator,
  stickyColWidth,
} from "../helpers";
import StickyColumnShadow from "../sticky-column-shadow";
import { CriteriaByCompetencyIdMap } from "../types";
import CareerTrackCompetencyRow from "./career-track-competency-row";
import EmptyCriteriaCell from "./empty-criteria-cell";
import RoleHeading from "./role-heading";
import TitleRow from "./title-row";

export type CareerTrackFormResponsibility = RoleResponsibilityInput & {
  uuid: string;
};

export type CareerTrackFormRole = Omit<
  CareerTrackRoleInput,
  "responsibilities" | "assignToUsers"
> & {
  uuid: string;
  criteriaByCompetencyId: CriteriaByCompetencyIdMap;
  responsibilities: CareerTrackFormResponsibility[];
  assignToUsers: BasicUser[];
};

const Controls = ({
  isSaving,
  canSave,
  organizationId,
  saveTooltip,
  onSave,
  associatedTeamId,
}: {
  isSaving: boolean;
  organizationId: number;
  canSave: boolean;
  saveTooltip: string | null;
  onSave: () => void;
  associatedTeamId?: number | null;
}) => {
  return (
    <div className="flex items-center justify-end gap-2">
      {isSaving && <Loading mini size={5} />}
      <Button
        disabled={isSaving}
        theme={buttonTheme.text}
        text="Discard changes"
        to={`/settings/organization/${organizationId}/competencies?team=${associatedTeamId}`}
      />
      <Button
        disabled={isSaving || !canSave}
        theme="primary"
        tooltip={saveTooltip}
        text={`Save Career track`}
        onClick={onSave}
      />
    </div>
  );
};

const CareerTrackForm = ({ organizationId }: { organizationId: number }) => {
  const label = useLabel();
  const link = useLink();
  const tableRef = useRef<HTMLDivElement>(null);
  const { onError } = useNotificationError();
  const params = useParams<{ careerTrackId: string }>();
  const { teamStr } = useUrlQueryParams<{ teamStr?: string }>({
    teamStr: undefined,
  });
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [levelCount, setLevelCount] = useState(1);
  const [newCompetencyTitle, setNewCompetencyTitle] = useState<string | null>(
    null
  );

  const careerTrackId = useMemo(() => {
    if (params.careerTrackId?.match(/\d+/)) {
      return parseInt(params.careerTrackId);
    }
    return null;
  }, [params]);
  const isNew = careerTrackId === null;

  const [careerTrackData, setCareerTrackData] = useState<
    Omit<SaveOrgWideCareerTrackMutationVariables, "roles">
  >({
    title: "",
    organizationId,
  });
  const [associatedTeam, setAssociatedTeam] =
    useState<UserComboboxOption | null>(
      teamStr
        ? {
            id: parseInt(teamStr.split(":")[0]),
            title: teamStr.split(":")[1],
            type: UserComboboxOptionType.TEAM,
          }
        : null
    );
  const [roles, setRoles] = useState<CareerTrackFormRole[]>([]);

  const {
    data: competenciesData,
    loading: isCompetenciesLoading,
    refetch: refetchCompetencies,
  } = useQuery<
    GetOrganizationCompetenciesQuery,
    GetOrganizationCompetenciesQueryVariables
  >(getOrganizationCompetenciesQuery, {
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
    variables: {
      organizationId,
    },
    onError,
  });

  const updateCareerTrackFormState = (
    careerTrack: OrganizationCareerTrackFragmentFragment
  ) => {
    const roles = assertEdgesNonNull(careerTrack.roles);
    setCareerTrackData({
      ...careerTrack,
    });
    setRoles(
      roles.map((role) => {
        const roleCompetencies = assertEdgesNonNull(role.competencies);
        const roleResponsibilities = assertEdgesNonNull(
          role.responsibilities
        ).map((responsibility) => ({
          ...responsibility,
          uuid: uuidv4(),
        }));
        const criteriaByCompetencyId: CriteriaByCompetencyIdMap =
          roleCompetencies.reduce((acc, competency) => {
            const criteria = assertEdgesNonNull(competency.criteria).map(
              ({ id, content, level }) => ({
                id,
                content,
                level,
                uuid: uuidv4(),
              })
            );
            return {
              ...acc,
              [competency.id]: [...(acc[competency.id] ?? []), ...criteria],
            };
          }, {} as CriteriaByCompetencyIdMap);
        return {
          level: role.level,
          title: role.title,
          roleType: role.roleType,
          id: role.id,
          uuid: uuidv4(),
          criteriaByCompetencyId,
          responsibilities: roleResponsibilities,
          assignToUsers: assertEdgesNonNull(role.assignedUsers),
        };
      })
    );
    setLevelCount(
      roles.length > 0 ? max(roles.map((role) => role.level)) ?? 1 : 1
    );
    setAssociatedTeam({
      id: assertNonNull(careerTrack?.team?.id),
      title: assertNonNull(careerTrack?.team?.title),
      type: UserComboboxOptionType.TEAM,
    });
  };

  const { data, loading: isCareerTrackLoading } = useQuery<
    GetOrganizationCareerTrackQuery,
    GetOrganizationCareerTrackQueryVariables
  >(getOrganizationCareerTrackQuery, {
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
    variables: {
      careerTrackId: careerTrackId ?? 0,
    },
    skip: isNew,
    onError,
    onCompleted: (data) => {
      const careerTrack = data.careerTrack;
      if (careerTrack) {
        updateCareerTrackFormState(careerTrack);
      }
    },
  });

  const [saveCareerTrack, { loading: isSaving }] = useMutation<
    SaveOrgWideCareerTrackMutation,
    SaveOrgWideCareerTrackMutationVariables
  >(createOrUpdateCareerTrackMutation, {
    onCompleted: (response) => {
      const careerTrack = response.createOrUpdateCareerTrack?.careerTrack;
      if (careerTrack) {
        updateCareerTrackFormState(careerTrack);
        successNotificationVar({
          title: `Careeer track saved`,
          timeout: 2000,
        });
        setIsFormDirty(false);
        if (isNew) {
          link.replace(
            `/settings/organization/${organizationId}/career-track/edit/${careerTrack.id}`
          );
        }
      } else {
        link.redirect(
          `/settings/organization/${organizationId}/competencies?team=${associatedTeam?.id}`
        );
      }
    },
  });

  const fullCompetenciesList: (Pick<
    CompetencyNode,
    "id" | "title" | "criteriaUniqueness" | "appliesTo"
  > &
    OrganizationCompetencyCriteriaFragment)[] = useMemo(() => {
    const competencyList = competenciesData
      ? assertEdgesNonNull(competenciesData?.competencies).filter(
          (competency) =>
            doesCompetencyApplyToCareerTrack(
              competency,
              roles,
              careerTrackData,
              careerTrackId,
              associatedTeam
            )
        )
      : [];
    return competencyList.map((competency) => {
      return {
        ...competency,
        criteria: {
          __typename: "CompetencyCriteriaConnection",
          edges: competency.criteria.edges.map((criteriaNode) => {
            return {
              ...criteriaNode,
              __typename: "CompetencyCriteriaEdge",
              node: criteriaNode
                ? {
                    __typename: "CompetencyCriteriaNode",
                    id: assertNonNull(criteriaNode.node?.id),
                    content: assertNonNull(criteriaNode.node?.content),
                    level: assertNonNull(criteriaNode.node?.level),
                    uuid: uuidv4(),
                  }
                : null,
            };
          }),
        },
      };
    });
  }, [associatedTeam, careerTrackData, careerTrackId, competenciesData, roles]);

  const otherCompetencyOptions = useMemo(() => {
    const excludedIds = fullCompetenciesList.map(({ id }) => id);
    const others = competenciesData
      ? assertEdgesNonNull(competenciesData?.competencies).filter(
          ({ id }) => !excludedIds.includes(id)
        )
      : [];
    return others.map((competency) => ({
      label: competency.title,
      value: competency.id,
    }));
  }, [competenciesData, fullCompetenciesList]);

  const handleSaveCareerTrack = useCallback(() => {
    setIsFormDirty(false);
    saveCareerTrack({
      variables: {
        ...careerTrackData,
        roles: roles.map((item) => {
          const roleCriteria = reduce<
            CriteriaByCompetencyIdMap,
            CompetencyCriteriaInput[]
          >(
            item.criteriaByCompetencyId,
            (acc, criteria, criteriaId) => {
              const competency = fullCompetenciesList.find(
                (competency) => competency.id === parseInt(criteriaId)
              );
              if (
                !competency ||
                competency.criteriaUniqueness ===
                  CompetencyCriteriaUniqueness.SameAcrossRoles
              ) {
                return acc;
              }

              const newCriteria: CompetencyCriteriaInput[] = criteria.map(
                (criteriaItem) => ({
                  ...omit(criteriaItem, "uuid"),
                  competencyId: competency.id,
                })
              );

              return [...acc, ...newCriteria];
            },
            []
          );
          return {
            ...omit(item, "uuid", "criteriaByCompetencyId"),
            responsibilities: item.responsibilities
              .filter(
                (responsibility) =>
                  (responsibility.content || "").trim().length > 0
              )
              .map((responsibility) =>
                omit(responsibility, "uuid", "__typename")
              ),
            roleSpecificCriteria: roleCriteria,
            assignToUsers: item.assignToUsers.map(({ id }) => id),
          };
        }),
        careerTrackId: careerTrackId,
        teamId: assertNonNull(associatedTeam?.id),
      },
      onError,
    });
  }, [
    fullCompetenciesList,
    associatedTeam,
    careerTrackData,
    careerTrackId,
    onError,
    roles,
    saveCareerTrack,
  ]);

  const { options, setQuery, query } = useUserComboboxQuery({
    types: [UserComboboxOptionType.TEAM],
    selected: associatedTeam ? associatedTeam : undefined,
  });

  const handleAddAdditionalCompetency = useCallback((competencyId: number) => {
    setIsFormDirty(true);
    setCareerTrackData((prev) => ({
      ...prev,
      additionalCompetencies:
        prev.additionalCompetencies && isArray(prev.additionalCompetencies)
          ? [...prev.additionalCompetencies.map(assertNonNull), competencyId]
          : [competencyId],
    }));
  }, []);

  const loading = isCareerTrackLoading || isCompetenciesLoading;
  const saveTooltip = useMemo(() => {
    if (!associatedTeam) return "Please select a team";
    if (roles.some((role) => !role.title || role.title?.trim() === ""))
      return "Every role must have a title";
    if (!careerTrackData.title?.trim()) return "Please enter a title";
    return null;
  }, [associatedTeam, careerTrackData.title, roles]);
  const canSave = !saveTooltip;

  return (
    <div>
      <Prompt
        when={isFormDirty}
        message="Are you sure you want to leave? The changes you made will be lost."
      />
      {newCompetencyTitle !== null && (
        <CompetencyFormModal
          organizationId={organizationId}
          startingData={{ title: newCompetencyTitle }}
          onClose={() => setNewCompetencyTitle(null)}
          onCompetencySaved={(competencyId) => {
            setIsFormDirty(true);
            setNewCompetencyTitle(null);
            handleAddAdditionalCompetency(competencyId);
            refetchCompetencies();
          }}
        />
      )}
      <div className="flex justify-between items-center mb-6">
        <Heading
          small
          title={`${!isNew ? "Edit" : "New"} Career Track`}
          backUrl={`/settings/organization/${organizationId}/competencies?team=${associatedTeam?.id}`}
        />

        {!loading && (data?.careerTrack || isNew) && (
          <Controls
            organizationId={organizationId}
            onSave={handleSaveCareerTrack}
            canSave={canSave}
            isSaving={isSaving}
            saveTooltip={saveTooltip}
            associatedTeamId={associatedTeam?.id}
          />
        )}
      </div>

      {loading && (
        <div className="flex justify-center mt-8 mb-12">
          <Loading />
        </div>
      )}
      {!loading && !data?.careerTrack && !isNew && (
        <div className="flex justify-center mt-8">
          <div className="text-gray-500">Career Track not found</div>
        </div>
      )}

      {!loading && (data?.careerTrack || isNew) && (
        <div className="my-6">
          <div className="flex flex-col gap-8">
            <div className="flex">
              <div className="mt-5 text-gray-500 text-xs uppercase font-semibold w-48">
                Name
              </div>
              <Input
                aria-label="Competency title input"
                disabled={isSaving}
                className="w-fit flex-1 mt-1"
                value={careerTrackData.title ?? ""}
                onChange={(evt) => {
                  setIsFormDirty(true);
                  setCareerTrackData({
                    ...careerTrackData,
                    title: evt.target.value,
                  });
                }}
              />
            </div>
            <div className="flex">
              <div className="mt-3 text-gray-500 text-xs uppercase font-semibold w-48">
                Associated {label("team")}
              </div>
              <UserCombobox
                disabled={isSaving}
                options={options}
                value={associatedTeam}
                onChangeValue={(team) => {
                  setIsFormDirty(true);
                  setAssociatedTeam(team);
                }}
                query={query}
                onChangeQuery={setQuery}
                placeholder={`${label("team", { capitalize: true })}: None`}
              />
            </div>
          </div>

          <div className="flex items-center justify-between mt-8 mb-4">
            <div className="font-bold">
              {`${label("competency", {
                capitalize: true,
                pluralize: true,
              })}, Responsibilities, and KPIs`}
            </div>
            <div className="flex items-center gap-2">
              <ComboboxGeneric
                aria-label="Add Competency dropdown"
                value={null}
                options={otherCompetencyOptions}
                createable
                onAddNewValue={(competencyTitle) =>
                  setNewCompetencyTitle(competencyTitle)
                }
                onChangeValue={(opt) => {
                  handleAddAdditionalCompetency(opt.value);
                }}
              >
                {({ setReferenceElement }) => (
                  <Popover.Button
                    className="inline-flex justify-center font-medium rounded-md border fs-unmask border-gray-300 bg-white text-gray-700 focus:ring-0 focus:outline-none hover:bg-gray-50 text-sm py-2 px-4"
                    ref={setReferenceElement}
                    disabled={isSaving}
                  >
                    Add Competency
                  </Popover.Button>
                )}
              </ComboboxGeneric>
              <Button
                disabled={isSaving}
                text="Add Level"
                onClick={() => {
                  setIsFormDirty(true);
                  setLevelCount((levelCount) => levelCount + 1);
                  setTimeout(() => {
                    if (tableRef.current) {
                      tableRef.current.scrollTo({
                        left: 1000000,
                        behavior: "smooth",
                      });
                    }
                  }, 100);
                }}
              />
            </div>
          </div>

          <div
            className={classNames(
              "m-auto w-full mb-4",
              isSaving && "opacity-50 pointer-events-none"
            )}
          >
            <div className="relative overflow-x-auto" ref={tableRef}>
              <table className="text-sm table-fixed">
                <thead>
                  <tr>
                    <th
                      className={classNames(
                        stickyColWidth,
                        "border border-l-0 sticky left-0 bg-white"
                      )}
                    >
                      <StickyColumnShadow />
                    </th>
                    {roleLevelIterator(levelCount).map((level) => {
                      const role = roles.find((role) => role.level === level);
                      return (
                        <th
                          key={level}
                          className={classNames(
                            role && roleColWidth,
                            !role && emptyRoleColWidth,
                            "p-2 border text-left font-normal align-top"
                          )}
                        >
                          <RoleHeading
                            associatedTeam={associatedTeam}
                            careerTrackData={careerTrackData}
                            level={level}
                            disabled={isSaving}
                            role={role}
                            onNewRole={() => {
                              setIsFormDirty(true);
                              setRoles((prev) => [
                                ...prev,
                                {
                                  uuid: uuidv4(),
                                  level,
                                  title: "",
                                  roleType: CareerTrackRoleType.Ic,
                                  criteriaByCompetencyId: {},
                                  responsibilities: [],
                                  assignToUsers: [],
                                },
                              ]);
                            }}
                            onRoleChange={(newRole) => {
                              if (!role) {
                                return;
                              }
                              setRoles((prev) =>
                                prev.map((oldRole) => {
                                  if (oldRole.uuid === role.uuid) {
                                    return {
                                      ...oldRole,
                                      ...newRole,
                                    };
                                  }
                                  return oldRole;
                                })
                              );
                              setIsFormDirty(true);
                            }}
                            onRemoveRole={() => {
                              if (!role) {
                                return;
                              }
                              setRoles((prev) =>
                                prev.filter(
                                  (prevRole) => prevRole.uuid !== role.uuid
                                )
                              );
                              setIsFormDirty(true);
                            }}
                          />
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  <TitleRow
                    levelCount={levelCount}
                    title={`${label("competency", {
                      capitalize: true,
                      pluralize: true,
                    })}`}
                    roleLevels={roles.map((role) => assertNonNull(role.level))}
                  />

                  {fullCompetenciesList.map((competency) => (
                    <CareerTrackCompetencyRow
                      key={competency.id}
                      onCompetenciesUpdated={refetchCompetencies}
                      organizationId={organizationId}
                      competency={competency}
                      levelCount={levelCount}
                      roles={roles}
                      onCriteriaChange={(roleUuid, level, items) => {
                        setIsFormDirty(true);
                        setRoles((prev) =>
                          prev.map((prevRole) => {
                            if (roleUuid === prevRole.uuid) {
                              const prevCriteria =
                                prevRole.criteriaByCompetencyId[
                                  competency.id
                                ] ?? [];
                              return {
                                ...prevRole,
                                criteriaByCompetencyId: {
                                  ...prevRole.criteriaByCompetencyId,
                                  [competency.id]: [
                                    ...prevCriteria.map((criteria) => {
                                      const newCriteria = items.find(
                                        ({ uuid }) => uuid === criteria.uuid
                                      );
                                      if (newCriteria) {
                                        return {
                                          ...criteria,
                                          content: newCriteria.content,
                                        };
                                      }
                                      return criteria;
                                    }),
                                    ...items
                                      .filter(({ uuid }) => !uuid)
                                      .map((item) => ({
                                        level,
                                        content: item.content,
                                        uuid: uuidv4(),
                                      })),
                                  ],
                                },
                              };
                            }
                            return prevRole;
                          })
                        );
                      }}
                      onDeleteCriteria={(roleUuid, criteriaUuid) => {
                        setIsFormDirty(true);
                        setRoles((prev) =>
                          prev.map((prevRole) => {
                            if (roleUuid === prevRole.uuid) {
                              return {
                                ...prevRole,
                                criteriaByCompetencyId: {
                                  ...prevRole.criteriaByCompetencyId,
                                  [competency.id]:
                                    prevRole.criteriaByCompetencyId[
                                      competency.id
                                    ]?.filter(
                                      (criteria) =>
                                        criteria.uuid !== criteriaUuid
                                    ),
                                },
                              };
                            }
                            return prevRole;
                          })
                        );
                      }}
                    />
                  ))}

                  <TitleRow
                    levelCount={levelCount}
                    title="Responsibilities and KPIs"
                    roleLevels={roles.map((role) => assertNonNull(role.level))}
                  />

                  <tr className="h-52 align-top">
                    <td
                      className={classNames(
                        stickyColWidth,
                        "p-4 border border-l-0 sticky left-0 bg-white z-10"
                      )}
                    >
                      <div className="font-medium">Responsibilities</div>

                      <StickyColumnShadow />
                    </td>
                    {roleLevelIterator(levelCount).map((level) => {
                      const role = roles.find((role) => role.level === level);

                      if (!role) {
                        return <EmptyCriteriaCell key={level} />;
                      }

                      const responsibilities = role.responsibilities ?? [];

                      return (
                        <td
                          className={classNames(roleColWidth, "p-4 border")}
                          key={level}
                        >
                          <CriteriaEditor
                            addButtonText="Add Responsibility"
                            criteria={responsibilities}
                            editable
                            onAddCriteria={() => {
                              setIsFormDirty(true);
                              setRoles((prev) => {
                                return prev.map((prevRole) => {
                                  if (prevRole.uuid !== role.uuid) {
                                    return prevRole;
                                  }
                                  return {
                                    ...prevRole,
                                    responsibilities: [
                                      ...responsibilities,
                                      { content: "", uuid: uuidv4() },
                                    ],
                                  };
                                });
                              });
                            }}
                            onPasteMultipleCriteria={(items) => {
                              setIsFormDirty(true);
                              setRoles((prev) => {
                                return prev.map((prevRole) => {
                                  if (prevRole.uuid !== role.uuid) {
                                    return prevRole;
                                  }
                                  const updatingUuids = items.map(
                                    ({ uuid }) => uuid
                                  );
                                  return {
                                    ...prevRole,
                                    responsibilities: [
                                      ...responsibilities.filter(
                                        (responsibility) =>
                                          !updatingUuids.includes(
                                            responsibility.uuid
                                          )
                                      ),
                                      ...items.map((item) => ({
                                        content: item.content,
                                        uuid: item.uuid || uuidv4(),
                                      })),
                                    ],
                                  };
                                });
                              });
                            }}
                            onDeleteCriteria={(uuid) => {
                              setIsFormDirty(true);
                              setRoles((prev) => {
                                return prev.map((prevRole) => {
                                  if (prevRole.uuid !== role.uuid) {
                                    return prevRole;
                                  }
                                  return {
                                    ...prevRole,
                                    responsibilities:
                                      prevRole.responsibilities.filter(
                                        (responsibility) =>
                                          responsibility &&
                                          responsibility.uuid !== uuid
                                      ),
                                  };
                                });
                              });
                            }}
                            onCriteriaChange={(uuid, content) => {
                              setIsFormDirty(true);
                              setRoles((prev) => {
                                return prev.map((prevRole) => {
                                  if (prevRole.uuid !== role.uuid) {
                                    return prevRole;
                                  }
                                  return {
                                    ...prevRole,
                                    responsibilities:
                                      prevRole.responsibilities.map(
                                        (responsibility) => {
                                          if (responsibility.uuid !== uuid) {
                                            return responsibility;
                                          }
                                          return {
                                            ...responsibility,
                                            content,
                                          };
                                        }
                                      ),
                                  };
                                });
                              });
                            }}
                          />
                        </td>
                      );
                    })}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <Controls
            organizationId={organizationId}
            onSave={handleSaveCareerTrack}
            canSave={canSave}
            isSaving={isSaving}
            saveTooltip={saveTooltip}
            associatedTeamId={associatedTeam?.id}
          />
        </div>
      )}
    </div>
  );
};

export default CareerTrackForm;
