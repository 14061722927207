import { gql } from "@apollo/client";

export default gql`
  fragment ComplianceProgramTarget on UserNode {
    id
    name
    avatar
    managers {
      edges {
        node {
          id
        }
      }
    }
    teams {
      edges {
        node {
          id
        }
      }
    }
  }

  fragment ComplianceProgramForReportAssessmentGroupSection on AssessmentGroupSectionNode {
    id
    title
    description
    questions {
      edges {
        node {
          id
          question {
            ... on RangeAssessmentQuestionNode {
              id
              title
              description
              startValue
              endValue
              labels
              questionType
              responses
            }
            ... on MultiChoiceAssessmentQuestionNode {
              id
              title
              description
              options
              questionType
              responses
            }
            ... on TextAssessmentQuestionNode {
              id
              title
              description
              questionType
              responses
            }
          }
        }
      }
    }
  }

  fragment ComplianceProgramForReportAssessmentGroup on AssessmentGroupNode {
    id
    title
    sections {
      edges {
        node {
          ...ComplianceProgramForReportAssessmentGroupSection
        }
      }
    }
  }

  query getComplianceProgramForReport($complianceProgramId: Int!) {
    complianceProgram(pk: $complianceProgramId) {
      id
      title
      performanceAssessmentTemplate {
        id
        assessmentType
        isOnlySelfAssessment
        questionSets {
          edges {
            node {
              ...ComplianceProgramForReportAssessmentGroup
            }
          }
        }
      }
      managerAssessmentTemplate {
        id
        assessmentType
        isOnlySelfAssessment
        questionSets {
          edges {
            node {
              ...ComplianceProgramForReportAssessmentGroup
            }
          }
        }
      }
      peerAssessmentTemplate {
        id
        assessmentType
        isOnlySelfAssessment
        questionSets {
          edges {
            node {
              ...ComplianceProgramForReportAssessmentGroup
            }
          }
        }
      }
      assessments(state: submitted) {
        edges {
          node {
            id
            state
            responder {
              id
              name
              avatar
            }
            target {
              ...ComplianceProgramTarget
            }
            answers {
              edges {
                node {
                  ... on RangeAssessmentAnswerNode {
                    id
                    question {
                      id
                    }
                    integerAnswer
                    comment
                  }
                  ... on MultiChoiceAssessmentAnswerNode {
                    id
                    question {
                      id
                    }
                    choices
                    comment
                  }
                  ... on TextAssessmentAnswerNode {
                    id
                    question {
                      id
                    }
                    textAnswer
                    comment
                  }
                }
              }
            }
            template {
              id
            }
          }
        }
      }
      managerEffectivenessReport {
        edges {
          node {
            manager {
              ...ComplianceProgramTarget
              directReports {
                totalCount
              }
            }
            assessments {
              totalCount
            }
            questions {
              edges {
                node {
                  question {
                    id
                  }
                  avgAnswer
                }
              }
            }
          }
        }
      }
    }
  }
`;
