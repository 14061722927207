import useLabel from "@apps/use-label/use-label";
import Layout from "@components/layout/layout";
import ManagedTable from "@components/table/managed-table";

import {
  complianceProgramReportingTableColNameClassName,
  complianceProgramReportingTableColsClassName,
} from "./compliance-program-reporting";
import {
  ComplianceProgramReportingEntityTableCol,
  ComplianceProgramReportingEntityTableParticipantStatusWithNominations,
} from "./compliance-program-reporting-entity-table";
import ProgramProgressPill from "./program-progress-pill";

const ComplianceProgramReportingUserTable = ({
  participantStatusesWithMatchingTeam,
  cols,
}: {
  participantStatusesWithMatchingTeam: ComplianceProgramReportingEntityTableParticipantStatusWithNominations[];
  cols: ComplianceProgramReportingEntityTableCol[];
}) => {
  const label = useLabel();
  return (
    <Layout.MainSubSection title="Status of Individuals">
      {participantStatusesWithMatchingTeam.length === 0 ? (
        <div className="text-gray-500 text-sm">
          No {label("team")} members participating to this {label("program")}.
        </div>
      ) : (
        <ManagedTable
          scroll
          sortingEnabled
          sortDir="asc"
          sortKey="name"
          data={participantStatusesWithMatchingTeam}
          columns={[
            {
              headerClassName: complianceProgramReportingTableColNameClassName,
              cellClassName: complianceProgramReportingTableColNameClassName,
              header: `Name`,
              render: (
                data: ComplianceProgramReportingEntityTableParticipantStatusWithNominations
              ) => {
                return data.user.name;
              },
              sortKey: "name",
              value: (
                data: ComplianceProgramReportingEntityTableParticipantStatusWithNominations
              ) => {
                return data.user.name;
              },
            },
            ...cols.map((col) => ({
              headerClassName: complianceProgramReportingTableColsClassName,
              cellClassName: complianceProgramReportingTableColsClassName,
              header: col.label,
              render: (
                data: ComplianceProgramReportingEntityTableParticipantStatusWithNominations
              ) => {
                return <ProgramProgressPill status={data[col.keyName]} />;
              },
            })),
            {
              header: ``,
              render: () => "",
            },
          ]}
        />
      )}
    </Layout.MainSubSection>
  );
};

export default ComplianceProgramReportingUserTable;
