import { useQuery } from "@apollo/client";
import { compact, uniqBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import {
  ComplianceProgramParticipantStatus,
  GetComplianceProgramReportQuery,
  GetComplianceProgramReportQueryVariables,
} from "types/graphql-schema";

import { currentOrganizationVar } from "@cache/cache";
import Layout from "@components/layout/layout";
import AppLink, { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import {
  UserComboboxOption,
  UserComboboxOptionType,
  UserComboboxOrgOption,
  UserComboboxTeamOption,
} from "@components/user-combobox/user-combobox-list";
import { classNames } from "@helpers/css";
import {
  assertEdgesNonNull,
  assertEdgesNonNullWithStringId,
} from "@helpers/helpers";
import usePkParams from "@helpers/hooks/use-pk-params";
import useUrlQueryParams from "@helpers/hooks/use-url-query-params";

import getComplianceProgramReportQuery from "../graphql/get-compliance-program-report-query";
import ComplianceProgramHeaderButtons from "./compliance-program-header-buttons";
import ComplianceProgramReportingDetails from "./compliance-program-reporting-details";
import ComplianceProgramReportingEntityTable, {
  ComplianceProgramReportingEntityTableCol,
} from "./compliance-program-reporting-entity-table";
import ComplianceProgramReportingUserTable from "./compliance-program-reporting-user-table";

export const complianceProgramReportingTableColsClassName =
  "w-72 max-w-72 shrink-0";
export const complianceProgramReportingTableColNameClassName = classNames(
  complianceProgramReportingTableColsClassName,
  "truncate"
);

function typeGuardIsUserComboboxTeamOption(
  obj: UserComboboxOption
): obj is UserComboboxTeamOption {
  return obj.type === UserComboboxOptionType.TEAM;
}

const ComplianceProgramReporting = () => {
  const complianceProgramId = usePkParams("complianceProgramId");
  const link = useLink();
  const url = `/programs/${complianceProgramId}/reporting`;
  const currentOrganization = currentOrganizationVar();
  const urlParams = useUrlQueryParams({ team: null });
  const teamUrlParam = urlParams.team ? parseInt(urlParams.team) : null;

  const defaultOrgContext: UserComboboxOrgOption = useMemo(
    () => ({
      id: currentOrganization.id,
      name: currentOrganization.name,
      type: UserComboboxOptionType.ORG,
    }),
    [currentOrganization.id, currentOrganization.name]
  );

  const [context, setContext] = useState<
    UserComboboxTeamOption | UserComboboxOrgOption | null
  >(null);

  const {
    options,
    query,
    setQuery,
    loading: isLoadingUsers,
  } = useUserComboboxQuery({
    types: [UserComboboxOptionType.ORG, UserComboboxOptionType.TEAM],
    queryOptions: { skip: !complianceProgramId },
    selected: context,
  });
  const optionMatchingUrlParam = teamUrlParam
    ? options
        .filter(typeGuardIsUserComboboxTeamOption)
        .find((option) => option.id === teamUrlParam)
    : defaultOrgContext;

  useEffect(() => {
    if (
      optionMatchingUrlParam &&
      (context?.id !== optionMatchingUrlParam.id ||
        context?.type !== optionMatchingUrlParam.type)
    ) {
      setContext(optionMatchingUrlParam);
    }
  }, [context?.id, context?.type, optionMatchingUrlParam]);

  const handleChangeContext = (value: UserComboboxOption) => {
    if (
      value.type === UserComboboxOptionType.ORG ||
      value.type === UserComboboxOptionType.TEAM
    ) {
      link.redirect(`${url}?${value.type}=${value.id}`);
    }
  };

  const handleClearContext = () => {
    link.redirect(`${url}?${defaultOrgContext.type}=${defaultOrgContext.id}`);
  };

  const { data: complianceProgramData, loading: isLoadingComplianceProgram } =
    useQuery<
      GetComplianceProgramReportQuery,
      GetComplianceProgramReportQueryVariables
    >(getComplianceProgramReportQuery, {
      fetchPolicy: "network-only",
      variables: { complianceProgramId },
      onError: onNotificationErrorHandler(),
    });

  const complianceProgram = useMemo(
    () => complianceProgramData?.complianceProgram,
    [complianceProgramData]
  );

  const performanceAssessmentTemplate = useMemo(
    () =>
      complianceProgram?.performanceAssessmentTemplate
        ? complianceProgram.performanceAssessmentTemplate
        : null,
    [complianceProgram]
  );
  const managerAssessmentTemplate = useMemo(
    () =>
      complianceProgram?.managerAssessmentTemplate
        ? complianceProgram.managerAssessmentTemplate
        : null,
    [complianceProgram]
  );
  const peerAssessmentTemplate = useMemo(
    () =>
      complianceProgram?.peerAssessmentTemplate
        ? complianceProgram.peerAssessmentTemplate
        : null,
    [complianceProgram]
  );
  const topicTemplate = useMemo(
    () =>
      complianceProgram?.requiredTopicTemplates
        ? assertEdgesNonNull(complianceProgram.requiredTopicTemplates)[0]
        : null,
    [complianceProgram]
  );

  const nominationCountByUserId = useMemo(() => {
    const assessmentsOpenForNominations =
      complianceProgramData?.assessmentsOpenForNominations
        ? assertEdgesNonNullWithStringId(
            complianceProgramData.assessmentsOpenForNominations
          )
        : [];
    return assessmentsOpenForNominations.reduce(
      (memo, assessmentOpenForNominations) => {
        const userId = assessmentOpenForNominations.targetUser.id;
        memo[userId] = assessmentOpenForNominations.nominations.totalCount;
        return memo;
      },
      {} as Record<number, number>
    );
  }, [complianceProgramData]);

  const participantStatuses = useMemo(() => {
    return compact(
      complianceProgram?.participantStatus?.edges.map((edge) => edge?.node)
    ).map((participantStatus) => ({
      ...participantStatus,
      nominations:
        nominationCountByUserId[participantStatus.user.id] &&
        nominationCountByUserId[participantStatus.user.id] > 0
          ? ComplianceProgramParticipantStatus.NotStarted
          : ComplianceProgramParticipantStatus.Complete,
    }));
  }, [complianceProgram, nominationCountByUserId]);

  const flattenedParticipantStatuses = useMemo(() => {
    return participantStatuses.map((participantStatus) => {
      const flattenedTeams: UserComboboxTeamOption[] = assertEdgesNonNull(
        participantStatus.user.teams
      ).map((node) => ({ ...node, type: UserComboboxOptionType.TEAM }));
      return {
        ...participantStatus,
        user: {
          ...participantStatus.user,
          flattenedTeams: flattenedTeams,
          teamIds: flattenedTeams.map((team) => team.id),
          manager: participantStatus.user.managers.edges[0]?.node,
        },
      };
    });
  }, [participantStatuses]);

  const teams = useMemo(() => {
    const concatenatedTeams = flattenedParticipantStatuses.reduce(
      (memo, participantStatus) => {
        return [...memo, ...participantStatus.user.flattenedTeams];
      },
      [] as UserComboboxTeamOption[]
    );
    return uniqBy(concatenatedTeams, "id");
  }, [flattenedParticipantStatuses]);

  const participantStatusesWithMatchingTeam = useMemo(() => {
    return flattenedParticipantStatuses.filter(
      (participantStatus) =>
        context?.type === UserComboboxOptionType.TEAM &&
        participantStatus.user.teamIds.includes(context.id)
    );
  }, [flattenedParticipantStatuses, context?.id, context?.type]);

  const contextWithParticipantStatuses = useMemo(() => {
    return context
      ? {
          ...context,
          participantStatuses:
            context?.type === UserComboboxOptionType.TEAM
              ? participantStatusesWithMatchingTeam
              : participantStatuses,
        }
      : null;
  }, [context, participantStatusesWithMatchingTeam, participantStatuses]);

  const teamsWithParticipantStatuses = useMemo(() => {
    return teams.map((team) => ({
      ...team,
      participantStatuses: flattenedParticipantStatuses.filter(
        (participantStatus) => participantStatus.user.teamIds.includes(team.id)
      ),
    }));
  }, [teams, flattenedParticipantStatuses]);

  const cols: ComplianceProgramReportingEntityTableCol[] = compact([
    {
      label: "Nominations",
      keyName: "nominations",
    },
    performanceAssessmentTemplate && {
      label: "Performance Assessment",
      keyName: "performanceSelfAssessmentStatus",
    },
    performanceAssessmentTemplate &&
      performanceAssessmentTemplate.hasSelfAssessment && {
        label: "PerformanceSelf Assessment",
        keyName: "performanceSelfAssessmentStatus",
      },
    peerAssessmentTemplate && {
      label: "Peer Assessment",
      keyName: "peerAssessmentStatus",
    },
    peerAssessmentTemplate &&
      peerAssessmentTemplate.hasSelfAssessment && {
        label: "Peer Self Assessment",
        keyName: "peerSelfAssessmentStatus",
      },
    managerAssessmentTemplate && {
      label: "Manager Assessment",
      keyName: "managerAssessmentStatus",
    },
    managerAssessmentTemplate &&
      managerAssessmentTemplate.hasSelfAssessment && {
        label: "Manager Self Assessment",
        keyName: "managerSelfAssessmentStatus",
      },
    topicTemplate && {
      label: "1-on-1 Meetings",
      keyName: "oneononeStatus",
    },
  ]);
  return (
    <Layout>
      <Layout.Header
        breadcrumbs={compact([
          { title: "Programs", url: "/programs" },
          complianceProgram && {
            title: complianceProgram.title,
            url: url,
          },
        ])}
      />
      <Layout.Container>
        {isLoadingComplianceProgram ? (
          <Layout.Main fullWidth>
            <Loading className="mt-12">Loading program...</Loading>
          </Layout.Main>
        ) : complianceProgram ? (
          <Layout.Main fullWidth>
            <Layout.MainSection
              title={
                <AppLink className="hover:underline" to={url}>
                  {complianceProgram.title}
                </AppLink>
              }
              rightSide={
                <ComplianceProgramHeaderButtons
                  complianceProgram={complianceProgram}
                />
              }
            >
              <Layout.MainSubSection
                title="Program Details"
                expandedUiPreferenceKey="programReportingDetailsExpanded"
              >
                <ComplianceProgramReportingDetails
                  complianceProgram={complianceProgram}
                />
              </Layout.MainSubSection>
              <Layout.MainSubSection title="Program Progress">
                <div className="flex items-center gap-4 mb-6">
                  <UserCombobox
                    disabled={isLoadingComplianceProgram}
                    className="w-64"
                    query={query}
                    options={options}
                    value={context}
                    onChangeValue={handleChangeContext}
                    onChangeQuery={setQuery}
                    loading={isLoadingUsers}
                    placeholder="Select..."
                    clearable={
                      context !== null &&
                      context.type !== UserComboboxOptionType.ORG
                    }
                    onClearValue={handleClearContext}
                  />
                  {isLoadingUsers && <Loading mini size={5} />}
                </div>
                {contextWithParticipantStatuses && (
                  <div>
                    <ComplianceProgramReportingEntityTable
                      complianceProgramId={complianceProgram.id}
                      entities={[contextWithParticipantStatuses]}
                      cols={cols}
                    />
                  </div>
                )}
              </Layout.MainSubSection>
              {context?.type === UserComboboxOptionType.ORG && (
                <Layout.MainSubSection title="Departments">
                  <ComplianceProgramReportingEntityTable
                    complianceProgramId={complianceProgram.id}
                    entities={teamsWithParticipantStatuses}
                    cols={cols}
                  />
                </Layout.MainSubSection>
              )}
              {context?.type === UserComboboxOptionType.ORG && (
                <ComplianceProgramReportingUserTable
                  participantStatusesWithMatchingTeam={participantStatuses}
                  cols={cols}
                />
              )}
              {context?.type === UserComboboxOptionType.TEAM && (
                <ComplianceProgramReportingUserTable
                  participantStatusesWithMatchingTeam={
                    participantStatusesWithMatchingTeam
                  }
                  cols={cols}
                />
              )}
            </Layout.MainSection>
          </Layout.Main>
        ) : (
          <Layout.Main fullWidth>Program not found.</Layout.Main>
        )}
      </Layout.Container>
    </Layout>
  );
};

export default ComplianceProgramReporting;
