/* DO NOT EDIT THIS FILE!!!! This file is auto generated by npm run graphql:types */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: number; output: number };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: string; output: string };
  DateTime: { input: string; output: string };
  Decimal: { input: any; output: any };
  JSONString: { input: any; output: any };
};

export type AcceptInvitationMutation = {
  readonly __typename: "AcceptInvitationMutation";
  readonly invitation?: Maybe<InvitationNode>;
  readonly user?: Maybe<UserNode>;
};

/** An enumeration. */
export enum AccountToOrganizationMembershipStatus {
  Active = "active",
  Inactive = "inactive",
}

export type ActionItemArtifactConnection = {
  readonly __typename: "ActionItemArtifactConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ActionItemArtifactEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `ActionItemArtifact` and its cursor. */
export type ActionItemArtifactEdge = {
  readonly __typename: "ActionItemArtifactEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<ActionItemArtifactNode>;
};

export type ActionItemArtifactNode = ArtifactInterface & {
  readonly __typename: "ActionItemArtifactNode";
  readonly actionItemState?: Maybe<Scalars["Int"]["output"]>;
  readonly activities: ArtifactActivityNodeConnection;
  readonly artifactType: ArtifactType;
  readonly assignableUsers?: Maybe<UserNodeConnection>;
  readonly assignee?: Maybe<UserNode>;
  readonly canDelete: PermissionNode;
  readonly canRead: PermissionNode;
  readonly canUpdate: PermissionNode;
  readonly commentCount?: Maybe<Scalars["Int"]["output"]>;
  readonly comments?: Maybe<CommentNodeConnection>;
  readonly completedAt?: Maybe<Scalars["DateTime"]["output"]>;
  readonly created: Scalars["DateTime"]["output"];
  readonly createdInMeeting?: Maybe<MeetingNode>;
  readonly creator?: Maybe<UserNode>;
  readonly description?: Maybe<Scalars["JSONString"]["output"]>;
  readonly dueDate?: Maybe<Scalars["Date"]["output"]>;
  readonly eventChannelName?: Maybe<Scalars["String"]["output"]>;
  readonly id: Scalars["Int"]["output"];
  readonly isComplete?: Maybe<Scalars["Boolean"]["output"]>;
  readonly isStale: Scalars["Boolean"]["output"];
  readonly organization?: Maybe<OrganizationNode>;
  readonly progress: Scalars["Float"]["output"];
  readonly relatedArtifacts?: Maybe<ArtifactToArtifactConnection>;
  readonly title?: Maybe<Scalars["String"]["output"]>;
  readonly topics?: Maybe<ArtifactToTopicConnection>;
  readonly updated?: Maybe<Scalars["DateTime"]["output"]>;
  readonly uuid?: Maybe<Scalars["String"]["output"]>;
  readonly websocketToken?: Maybe<Scalars["String"]["output"]>;
};

export type ActionItemArtifactNodeActivitiesArgs = {
  activityType?: InputMaybe<ArtifactActivityType>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActionItemArtifactNodeAssignableUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  hasNoManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActiveOrInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  reportIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type ActionItemArtifactNodeCommentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  artifactId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  isInlineComment?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  textUuid?: InputMaybe<Scalars["String"]["input"]>;
  topicId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActionItemArtifactNodeRelatedArtifactsArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActionItemArtifactNodeTopicsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assignee?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasActionItems?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasArtifacts?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDecisions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDocuments?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasGoals?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInFlowId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInMeetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<TopicState>;
  titleContains?: InputMaybe<Scalars["String"]["input"]>;
  topicIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type ActionItemState = {
  readonly __typename: "ActionItemState";
  readonly isComplete: Scalars["Boolean"]["output"];
  readonly isDefaultIncomplete: Scalars["Boolean"]["output"];
  readonly label: Scalars["String"]["output"];
  readonly value: Scalars["Int"]["output"];
};

export type AddBotToMeetingMutation = {
  readonly __typename: "AddBotToMeetingMutation";
  readonly meeting?: Maybe<MeetingNode>;
};

export type AddFavouriteArtifact = {
  readonly __typename: "AddFavouriteArtifact";
  readonly artifact?: Maybe<ArtifactInterface>;
  readonly user?: Maybe<UserNode>;
};

export type AddTeamMemberMutation = {
  readonly __typename: "AddTeamMemberMutation";
  readonly team?: Maybe<TeamNode>;
  readonly user?: Maybe<UserNode>;
};

export type AddTemplateToMeetingMutation = {
  readonly __typename: "AddTemplateToMeetingMutation";
  readonly meeting?: Maybe<MeetingNode>;
  readonly meetingGroup?: Maybe<MeetingGroupNode>;
  readonly topicTemplate?: Maybe<TopicTemplateNode>;
};

export type AdditionalArtifactInput = {
  /** Set the state of an action item */
  readonly actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  /** The id of the assignee for an action item or goal artifact */
  readonly assigneeId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Children of the current goal */
  readonly childGoalIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  readonly contributorIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** The current value of a goal */
  readonly currentValue?: InputMaybe<Scalars["Float"]["input"]>;
  /** The decision for a decision artifact */
  readonly decision?: InputMaybe<Scalars["String"]["input"]>;
  readonly decisionState?: InputMaybe<DecisionState>;
  readonly documentVisibility?: InputMaybe<DocumentVisibility>;
  /** The due date for an action item, goal artifact or asana task */
  readonly dueDate?: InputMaybe<Scalars["Date"]["input"]>;
  readonly feedbackAdminsCanView?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly feedbackCreatorCanView?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly feedbackMessage?: InputMaybe<Scalars["JSONString"]["input"]>;
  readonly feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  readonly feedbackRecipientsCanView?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly feedbackRecipientsManagersCanView?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** The id of the user will be providing the feedback */
  readonly feedbackSenderId?: InputMaybe<Scalars["Int"]["input"]>;
  readonly feedbackState?: InputMaybe<FeedbackState>;
  readonly goalKpiId?: InputMaybe<Scalars["Int"]["input"]>;
  readonly goalScope?: InputMaybe<GoalScope>;
  /** The status of a goal */
  readonly goalStatus?: InputMaybe<GoalStatus>;
  readonly goalVisibility?: InputMaybe<GoalVisibility>;
  /** Can only be used when creating a new goal */
  readonly keyResults?: InputMaybe<ReadonlyArray<InputMaybe<KeyResultInput>>>;
  /** Will use the latest measurement of the given KPI */
  readonly kpiId?: InputMaybe<Scalars["Int"]["input"]>;
  /** The id of the KPI measurement to display */
  readonly kpiMeasurementId?: InputMaybe<Scalars["Int"]["input"]>;
  readonly ownerIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** The id of a parent goal for this goal */
  readonly parentGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Set the progress of an action item or asana task */
  readonly progress?: InputMaybe<Scalars["Float"]["input"]>;
  readonly progressType?: InputMaybe<GoalProgressType>;
  /** The rating that is being collected for a rating artifact */
  readonly ratingId?: InputMaybe<Scalars["Int"]["input"]>;
  /** The id of the core value to be used for the recognition */
  readonly recognitionCoreValueId?: InputMaybe<Scalars["Int"]["input"]>;
  readonly recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  readonly recognitionVisibility?: InputMaybe<RecognitionVisibility>;
  /** The query string for a github issues artifact */
  readonly searchQuery?: InputMaybe<Scalars["String"]["input"]>;
  /** The start date for a goal */
  readonly startDate?: InputMaybe<Scalars["Date"]["input"]>;
  /** The start value of a goal */
  readonly startValue?: InputMaybe<Scalars["Float"]["input"]>;
  readonly state?: InputMaybe<GoalState>;
  /** The target value of a goal */
  readonly targetValue?: InputMaybe<Scalars["Float"]["input"]>;
  readonly teamIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** The URL of an Asana task */
  readonly url?: InputMaybe<Scalars["String"]["input"]>;
};

/** An enumeration. */
export enum AdoptionReportAttribute {
  Email = "email",
  LastSeen = "last_seen",
  ManagerEmail = "manager_email",
  ManagerName = "manager_name",
  Name = "name",
}

/** An enumeration. */
export enum AdoptionReportMetric {
  ActionItemsCreated = "action_items_created",
  AssessmentsAsTarget = "assessments_as_target",
  CareerGoalUpdates = "career_goal_updates",
  DecisionsCreated = "decisions_created",
  DocumentsCreated = "documents_created",
  GoalUpdates = "goal_updates",
  GoalsCreated = "goals_created",
  OneononeMeetingsAsManager = "oneonone_meetings_as_manager",
  OneononeMeetingsAsParticipant = "oneonone_meetings_as_participant",
  RecognitionsGiven = "recognitions_given",
  RecognitionsReceived = "recognitions_received",
}

export type AlignGoalMutation = {
  readonly __typename: "AlignGoalMutation";
  readonly goal?: Maybe<ArtifactInterface>;
  readonly previousParentGoal?: Maybe<ArtifactInterface>;
};

export type ArtifactActivityNode = DjangoNode &
  Node & {
    readonly __typename: "ArtifactActivityNode";
    readonly activityType: ArtifactActivityType;
    readonly actor?: Maybe<UserNode>;
    readonly artifact?: Maybe<ArtifactInterface>;
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly changedFields?: Maybe<
      ReadonlyArray<Maybe<ArtifactChangedFieldNode>>
    >;
    readonly created: Scalars["DateTime"]["output"];
    readonly id: Scalars["ID"]["output"];
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
  };

export type ArtifactActivityNodeConnection = {
  readonly __typename: "ArtifactActivityNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ArtifactActivityNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `ArtifactActivityNode` and its cursor. */
export type ArtifactActivityNodeEdge = {
  readonly __typename: "ArtifactActivityNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<ArtifactActivityNode>;
};

/** An enumeration. */
export enum ArtifactActivityType {
  ArtifactCreation = "artifact_creation",
  ArtifactUpdate = "artifact_update",
  CommentAdded = "comment_added",
  GoalCheckin = "goal_checkin",
  KeyResultAdded = "key_result_added",
  KeyResultRemoved = "key_result_removed",
  KeyResultUpdated = "key_result_updated",
  ReferencedFromArtifact = "referenced_from_artifact",
  ReferencedFromTopic = "referenced_from_topic",
}

export type ArtifactChangedFieldNode = {
  readonly __typename: "ArtifactChangedFieldNode";
  readonly changedFields?: Maybe<
    ReadonlyArray<Maybe<ArtifactChangedFieldNode>>
  >;
  readonly fieldName?: Maybe<Scalars["String"]["output"]>;
  readonly newValue?: Maybe<Scalars["String"]["output"]>;
  readonly newValueObject?: Maybe<ChangedFieldRelatedObjectUnion>;
  readonly previousValue?: Maybe<Scalars["String"]["output"]>;
  readonly previousValueObject?: Maybe<ChangedFieldRelatedObjectUnion>;
};

export type ArtifactConnection = {
  readonly __typename: "ArtifactConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ArtifactEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `Artifact` and its cursor. */
export type ArtifactEdge = {
  readonly __typename: "ArtifactEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<ArtifactInterface>;
};

export type ArtifactFilterInput = {
  readonly actionItemAssignedToMembersOfTeam?: InputMaybe<
    Scalars["Int"]["input"]
  >;
  readonly actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  readonly actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  readonly actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  readonly actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  readonly actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  readonly actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  readonly ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  readonly artifactType?: InputMaybe<ArtifactType>;
  readonly artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  /** Filter artifacts to only ones that the given user id can see */
  readonly asUser?: InputMaybe<Scalars["Int"]["input"]>;
  /** Filter artifacts to only ones that the given user id can see */
  readonly asUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  readonly assignedToMembersOfOrganizationId?: InputMaybe<
    Scalars["Int"]["input"]
  >;
  /** Expects a flow id, and will filter to only include action items completed after the end time of the last meeting in that series */
  readonly completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  /** Expects a meeting group id, and will filter to only include action items completed after the end time of the last meeting in that series */
  readonly completedActionItemsForMeetingGroup?: InputMaybe<
    Scalars["Int"]["input"]
  >;
  /** Expects a flow id, and will filter to only include goals completed after the end time of the previous meeting in the series, or within the last 7 days, whichever is longer */
  readonly completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  /** Expects a meeting group id, and will filter to only include goals completed after the end time of the previous meeting in the series, or within the last 7 days, whichever is longer */
  readonly completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  readonly createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  /** Filter artifacts created by given user id */
  readonly createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  /** Filter artifacts created by given user ids */
  readonly createdByIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  readonly createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  readonly excludeIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  readonly feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  readonly feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  readonly feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  readonly feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  readonly feedbackState?: InputMaybe<FeedbackState>;
  readonly feedbackStates?: InputMaybe<
    ReadonlyArray<InputMaybe<FeedbackState>>
  >;
  readonly flowId?: InputMaybe<Scalars["Int"]["input"]>;
  readonly goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  readonly goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  readonly goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  readonly goalOwners?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  readonly goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  readonly goalScope?: InputMaybe<GoalScope>;
  readonly goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  readonly goalState?: InputMaybe<GoalState>;
  readonly goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  readonly goalStatus?: InputMaybe<GoalStatus>;
  readonly goalTeams?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  readonly goalVisibility?: InputMaybe<GoalVisibility>;
  /** Expects a number indicating within how many days the goals have been completed. */
  readonly goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  readonly groupBy?: InputMaybe<Scalars["String"]["input"]>;
  readonly ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  readonly idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  readonly inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  readonly isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  readonly meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  readonly orderBy?: InputMaybe<Scalars["String"]["input"]>;
  readonly orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  readonly orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  readonly organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  readonly recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  readonly recognitionReceivedByMembersOfTeam?: InputMaybe<
    Scalars["Int"]["input"]
  >;
  readonly recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  readonly recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  readonly relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  readonly search?: InputMaybe<Scalars["String"]["input"]>;
  /** Filters down to team objectives related to the given user */
  readonly teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ArtifactInterface = {
  readonly activities: ArtifactActivityNodeConnection;
  readonly artifactType: ArtifactType;
  readonly assignableUsers?: Maybe<UserNodeConnection>;
  readonly canDelete: PermissionNode;
  readonly canRead: PermissionNode;
  readonly canUpdate: PermissionNode;
  readonly commentCount?: Maybe<Scalars["Int"]["output"]>;
  readonly comments?: Maybe<CommentNodeConnection>;
  readonly created: Scalars["DateTime"]["output"];
  readonly createdInMeeting?: Maybe<MeetingNode>;
  readonly creator?: Maybe<UserNode>;
  readonly description?: Maybe<Scalars["JSONString"]["output"]>;
  readonly eventChannelName?: Maybe<Scalars["String"]["output"]>;
  readonly id: Scalars["Int"]["output"];
  readonly isStale: Scalars["Boolean"]["output"];
  readonly organization?: Maybe<OrganizationNode>;
  readonly relatedArtifacts?: Maybe<ArtifactToArtifactConnection>;
  readonly title?: Maybe<Scalars["String"]["output"]>;
  readonly topics?: Maybe<ArtifactToTopicConnection>;
  readonly updated?: Maybe<Scalars["DateTime"]["output"]>;
  readonly uuid?: Maybe<Scalars["String"]["output"]>;
  readonly websocketToken?: Maybe<Scalars["String"]["output"]>;
};

export type ArtifactInterfaceActivitiesArgs = {
  activityType?: InputMaybe<ArtifactActivityType>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ArtifactInterfaceAssignableUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  hasNoManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActiveOrInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  reportIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type ArtifactInterfaceCommentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  artifactId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  isInlineComment?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  textUuid?: InputMaybe<Scalars["String"]["input"]>;
  topicId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ArtifactInterfaceRelatedArtifactsArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ArtifactInterfaceTopicsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assignee?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasActionItems?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasArtifacts?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDecisions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDocuments?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasGoals?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInFlowId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInMeetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<TopicState>;
  titleContains?: InputMaybe<Scalars["String"]["input"]>;
  topicIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type ArtifactToArtifactConnection = {
  readonly __typename: "ArtifactToArtifactConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ArtifactToArtifactEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `ArtifactToArtifact` and its cursor. */
export type ArtifactToArtifactEdge = {
  readonly __typename: "ArtifactToArtifactEdge";
  readonly connectionCreated?: Maybe<Scalars["DateTime"]["output"]>;
  readonly connectionCreator?: Maybe<UserNode>;
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<ArtifactInterface>;
};

export type ArtifactToTopicConnection = {
  readonly __typename: "ArtifactToTopicConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ArtifactToTopicEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `ArtifactToTopic` and its cursor. */
export type ArtifactToTopicEdge = {
  readonly __typename: "ArtifactToTopicEdge";
  readonly connectionCreated?: Maybe<Scalars["DateTime"]["output"]>;
  readonly connectionCreator?: Maybe<UserNode>;
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<TopicNode>;
};

/** An enumeration. */
export enum ArtifactType {
  ActionItem = "action_item",
  AsanaTask = "asana_task",
  Decision = "decision",
  Document = "document",
  Feedback = "feedback",
  GithubIssues = "github_issues",
  Goal = "goal",
  Kpi = "kpi",
  Rating = "rating",
  Recognition = "recognition",
}

export type AsanaTaskArtifactNode = ArtifactInterface & {
  readonly __typename: "AsanaTaskArtifactNode";
  readonly activities: ArtifactActivityNodeConnection;
  readonly artifactType: ArtifactType;
  readonly assignableUsers?: Maybe<UserNodeConnection>;
  readonly assignee?: Maybe<UserNode>;
  readonly canDelete: PermissionNode;
  readonly canRead: PermissionNode;
  readonly canUpdate: PermissionNode;
  readonly commentCount?: Maybe<Scalars["Int"]["output"]>;
  readonly comments?: Maybe<CommentNodeConnection>;
  readonly completedAt?: Maybe<Scalars["DateTime"]["output"]>;
  readonly created: Scalars["DateTime"]["output"];
  readonly createdInMeeting?: Maybe<MeetingNode>;
  readonly creator?: Maybe<UserNode>;
  readonly description?: Maybe<Scalars["JSONString"]["output"]>;
  readonly dueDate?: Maybe<Scalars["DateTime"]["output"]>;
  readonly eventChannelName?: Maybe<Scalars["String"]["output"]>;
  readonly id: Scalars["Int"]["output"];
  readonly isComplete?: Maybe<Scalars["Boolean"]["output"]>;
  readonly isStale: Scalars["Boolean"]["output"];
  readonly isSynced?: Maybe<Scalars["Boolean"]["output"]>;
  readonly organization?: Maybe<OrganizationNode>;
  readonly relatedArtifacts?: Maybe<ArtifactToArtifactConnection>;
  readonly title?: Maybe<Scalars["String"]["output"]>;
  readonly topics?: Maybe<ArtifactToTopicConnection>;
  readonly updated?: Maybe<Scalars["DateTime"]["output"]>;
  readonly uuid?: Maybe<Scalars["String"]["output"]>;
  readonly websocketToken?: Maybe<Scalars["String"]["output"]>;
};

export type AsanaTaskArtifactNodeActivitiesArgs = {
  activityType?: InputMaybe<ArtifactActivityType>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AsanaTaskArtifactNodeAssignableUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  hasNoManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActiveOrInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  reportIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type AsanaTaskArtifactNodeCommentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  artifactId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  isInlineComment?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  textUuid?: InputMaybe<Scalars["String"]["input"]>;
  topicId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AsanaTaskArtifactNodeRelatedArtifactsArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AsanaTaskArtifactNodeTopicsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assignee?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasActionItems?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasArtifacts?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDecisions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDocuments?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasGoals?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInFlowId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInMeetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<TopicState>;
  titleContains?: InputMaybe<Scalars["String"]["input"]>;
  topicIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type AsanaTaskPreviewNode = {
  readonly __typename: "AsanaTaskPreviewNode";
  readonly artifact?: Maybe<AsanaTaskArtifactNode>;
  readonly title?: Maybe<Scalars["String"]["output"]>;
};

export type AssessmentAnswerConnection = {
  readonly __typename: "AssessmentAnswerConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<AssessmentAnswerEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `AssessmentAnswer` and its cursor. */
export type AssessmentAnswerEdge = {
  readonly __typename: "AssessmentAnswerEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<AssessmentAnswerInterface>;
};

export type AssessmentAnswerInterface = {
  readonly assessment: AssessmentNode;
  readonly canDelete: PermissionNode;
  readonly canRead: PermissionNode;
  readonly canUpdate: PermissionNode;
  readonly comment?: Maybe<Scalars["JSONString"]["output"]>;
  readonly created: Scalars["DateTime"]["output"];
  readonly id: Scalars["Int"]["output"];
  readonly question: AssessmentQuestionInterface;
  readonly responder?: Maybe<UserNode>;
  readonly updated: Scalars["DateTime"]["output"];
};

export type AssessmentDeliveryNode = DjangoNode &
  Node & {
    readonly __typename: "AssessmentDeliveryNode";
    readonly answers: AssessmentAnswerConnection;
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly complianceProgram?: Maybe<ComplianceProgramNode>;
    readonly creator?: Maybe<UserNode>;
    readonly deliveryDatetime?: Maybe<Scalars["DateTime"]["output"]>;
    readonly dueDate?: Maybe<Scalars["Date"]["output"]>;
    readonly excludedAnswerIds: ReadonlyArray<Maybe<Scalars["Int"]["output"]>>;
    readonly id: Scalars["ID"]["output"];
    readonly lastSubmissionDatetime?: Maybe<Scalars["DateTime"]["output"]>;
    readonly organization: OrganizationNode;
    readonly periodEndDate?: Maybe<Scalars["Date"]["output"]>;
    readonly periodStartDate?: Maybe<Scalars["Date"]["output"]>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly state: AssessmentDeliveryState;
    readonly summary?: Maybe<Scalars["JSONString"]["output"]>;
    readonly target?: Maybe<UserNode>;
    readonly template: AssessmentTemplateNode;
  };

export type AssessmentDeliveryNodeAnswersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssessmentDeliveryNodeConnection = {
  readonly __typename: "AssessmentDeliveryNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<AssessmentDeliveryNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `AssessmentDeliveryNode` and its cursor. */
export type AssessmentDeliveryNodeEdge = {
  readonly __typename: "AssessmentDeliveryNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<AssessmentDeliveryNode>;
};

/** An enumeration. */
export enum AssessmentDeliveryState {
  Delivered = "delivered",
  Draft = "draft",
}

/** An enumeration. */
export enum AssessmentGroupAnonymity {
  Anonymous = "anonymous",
  NotAnonymous = "not_anonymous",
  SemiAnonymous = "semi_anonymous",
}

/** An enumeration. */
export enum AssessmentGroupDelivery {
  Full = "full",
  None = "none",
  Partial = "partial",
}

export type AssessmentGroupNode = DjangoNode &
  Node & {
    readonly __typename: "AssessmentGroupNode";
    readonly anonymity: AssessmentGroupAnonymity;
    readonly archived: Scalars["Boolean"]["output"];
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly created: Scalars["DateTime"]["output"];
    readonly creator?: Maybe<UserNode>;
    readonly delivery: AssessmentGroupDelivery;
    readonly hasSelfAssessment: Scalars["Boolean"]["output"];
    readonly id: Scalars["ID"]["output"];
    readonly isOnlySelfAssessment: Scalars["Boolean"]["output"];
    readonly isQuestionWeightingEnabled: Scalars["Boolean"]["output"];
    readonly organization: OrganizationNode;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly providers: AssessmentGroupProviders;
    readonly sections: AssessmentGroupSectionNodeConnection;
    readonly title: Scalars["String"]["output"];
    readonly updated: Scalars["DateTime"]["output"];
  };

export type AssessmentGroupNodeSectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssessmentGroupNodeConnection = {
  readonly __typename: "AssessmentGroupNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<AssessmentGroupNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `AssessmentGroupNode` and its cursor. */
export type AssessmentGroupNodeEdge = {
  readonly __typename: "AssessmentGroupNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<AssessmentGroupNode>;
};

/** An enumeration. */
export enum AssessmentGroupProviders {
  Default = "default",
  ManagerSelect = "manager_select",
  SubjectSelect = "subject_select",
}

export type AssessmentGroupQuestionM2MNode = DjangoNode &
  Node & {
    readonly __typename: "AssessmentGroupQuestionM2MNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly id: Scalars["ID"]["output"];
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly question: AssessmentQuestionInterface;
    readonly weight: Scalars["Int"]["output"];
  };

export type AssessmentGroupQuestionM2MNodeConnection = {
  readonly __typename: "AssessmentGroupQuestionM2MNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<AssessmentGroupQuestionM2MNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `AssessmentGroupQuestionM2MNode` and its cursor. */
export type AssessmentGroupQuestionM2MNodeEdge = {
  readonly __typename: "AssessmentGroupQuestionM2MNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<AssessmentGroupQuestionM2MNode>;
};

export type AssessmentGroupSectionInput = {
  readonly description?: InputMaybe<Scalars["String"]["input"]>;
  readonly questionIds: ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>;
  readonly questionWeights?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  readonly title?: InputMaybe<Scalars["String"]["input"]>;
};

export type AssessmentGroupSectionNode = DjangoNode &
  Node & {
    readonly __typename: "AssessmentGroupSectionNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly description?: Maybe<Scalars["JSONString"]["output"]>;
    readonly id: Scalars["ID"]["output"];
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly questions: AssessmentGroupQuestionM2MNodeConnection;
    readonly title?: Maybe<Scalars["String"]["output"]>;
  };

export type AssessmentGroupSectionNodeQuestionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssessmentGroupSectionNodeConnection = {
  readonly __typename: "AssessmentGroupSectionNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<AssessmentGroupSectionNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `AssessmentGroupSectionNode` and its cursor. */
export type AssessmentGroupSectionNodeEdge = {
  readonly __typename: "AssessmentGroupSectionNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<AssessmentGroupSectionNode>;
};

export type AssessmentNode = DjangoNode &
  Node & {
    readonly __typename: "AssessmentNode";
    readonly answers: AssessmentAnswerConnection;
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly complianceProgram?: Maybe<ComplianceProgramNode>;
    readonly created: Scalars["DateTime"]["output"];
    readonly creator?: Maybe<UserNode>;
    readonly dueDate?: Maybe<Scalars["Date"]["output"]>;
    readonly id: Scalars["ID"]["output"];
    readonly organization: OrganizationNode;
    readonly periodEndDate?: Maybe<Scalars["Date"]["output"]>;
    readonly periodStartDate?: Maybe<Scalars["Date"]["output"]>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly relatedMeetings: MeetingNodeConnection;
    readonly responder?: Maybe<UserNode>;
    readonly state: AssessmentState;
    readonly submittedDatetime?: Maybe<Scalars["DateTime"]["output"]>;
    readonly target?: Maybe<UserNode>;
    readonly template: AssessmentTemplateNode;
    readonly updated: Scalars["DateTime"]["output"];
  };

export type AssessmentNodeAnswersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssessmentNodeRelatedMeetingsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  endDatetime?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  excludeMeetingGroupIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  fromMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  ignored?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFormalOneonone?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Decimal"]["input"]>;
  participantCount?: InputMaybe<Scalars["Int"]["input"]>;
  participants?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  startDatetime?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<EventStatus>;
  status_In?: InputMaybe<ReadonlyArray<InputMaybe<EventStatus>>>;
  templateId?: InputMaybe<Scalars["Int"]["input"]>;
  unscheduled?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AssessmentNodeConnection = {
  readonly __typename: "AssessmentNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<AssessmentNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `AssessmentNode` and its cursor. */
export type AssessmentNodeEdge = {
  readonly __typename: "AssessmentNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<AssessmentNode>;
};

export type AssessmentNominationNode = DjangoNode &
  Node & {
    readonly __typename: "AssessmentNominationNode";
    readonly assessmentTemplate: AssessmentTemplateNode;
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly complianceProgram: ComplianceProgramNode;
    readonly created: Scalars["DateTime"]["output"];
    readonly creator: UserNode;
    readonly id: Scalars["ID"]["output"];
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly responder: UserNode;
    readonly target: UserNode;
    readonly updated: Scalars["DateTime"]["output"];
  };

export type AssessmentNominationNodeConnection = {
  readonly __typename: "AssessmentNominationNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<AssessmentNominationNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `AssessmentNominationNode` and its cursor. */
export type AssessmentNominationNodeEdge = {
  readonly __typename: "AssessmentNominationNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<AssessmentNominationNode>;
};

export type AssessmentOpenForNominationConnection = {
  readonly __typename: "AssessmentOpenForNominationConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<AssessmentOpenForNominationEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `AssessmentOpenForNomination` and its cursor. */
export type AssessmentOpenForNominationEdge = {
  readonly __typename: "AssessmentOpenForNominationEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<AssessmentOpenForNominationNode>;
};

export type AssessmentOpenForNominationNode = {
  readonly __typename: "AssessmentOpenForNominationNode";
  readonly assessmentTemplate: AssessmentTemplateNode;
  readonly complianceProgram: ComplianceProgramNode;
  readonly id?: Maybe<Scalars["String"]["output"]>;
  readonly nominations: AssessmentNominationNodeConnection;
  readonly targetUser: UserNode;
};

export type AssessmentOpenForNominationNodeNominationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssessmentQuestionAnswerInput = {
  readonly choices?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  readonly comment?: InputMaybe<Scalars["String"]["input"]>;
  readonly integerAnswer?: InputMaybe<Scalars["Int"]["input"]>;
  readonly questionId: Scalars["Int"]["input"];
  readonly textAnswer?: InputMaybe<Scalars["String"]["input"]>;
};

export type AssessmentQuestionCategoryNode = DjangoNode &
  Node & {
    readonly __typename: "AssessmentQuestionCategoryNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly id: Scalars["ID"]["output"];
    readonly organization: OrganizationNode;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly title: Scalars["String"]["output"];
  };

export type AssessmentQuestionCategoryNodeConnection = {
  readonly __typename: "AssessmentQuestionCategoryNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<AssessmentQuestionCategoryNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `AssessmentQuestionCategoryNode` and its cursor. */
export type AssessmentQuestionCategoryNodeEdge = {
  readonly __typename: "AssessmentQuestionCategoryNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<AssessmentQuestionCategoryNode>;
};

export type AssessmentQuestionConnection = {
  readonly __typename: "AssessmentQuestionConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<AssessmentQuestionEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `AssessmentQuestion` and its cursor. */
export type AssessmentQuestionEdge = {
  readonly __typename: "AssessmentQuestionEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<AssessmentQuestionInterface>;
};

export type AssessmentQuestionInterface = {
  readonly answers: AssessmentAnswerConnection;
  readonly answersExist: Scalars["Boolean"]["output"];
  readonly archived: Scalars["Boolean"]["output"];
  readonly assessmentType: AssessmentType;
  readonly canDelete: PermissionNode;
  readonly canRead: PermissionNode;
  readonly canUpdate: PermissionNode;
  readonly categories: AssessmentQuestionCategoryNodeConnection;
  readonly created: Scalars["DateTime"]["output"];
  readonly creator?: Maybe<UserNode>;
  readonly description?: Maybe<Scalars["JSONString"]["output"]>;
  readonly id: Scalars["Int"]["output"];
  readonly isCommentMandatory: Scalars["Boolean"]["output"];
  readonly organization: OrganizationNode;
  readonly questionType: AssessmentQuestionType;
  readonly responseVisibility: AssessmentQuestionResponseVisibility;
  readonly responses: AssessmentQuestionResponses;
  readonly title: Scalars["String"]["output"];
  readonly updated: Scalars["DateTime"]["output"];
};

export type AssessmentQuestionInterfaceAnswersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssessmentQuestionInterfaceCategoriesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

/** An enumeration. */
export enum AssessmentQuestionResponseVisibility {
  All = "all",
  HiddenFromSubject = "hidden_from_subject",
}

/** An enumeration. */
export enum AssessmentQuestionResponses {
  Both = "both",
  ExcludeSelfAssessment = "exclude_self_assessment",
  SelfAssessmentOnly = "self_assessment_only",
}

/** An enumeration. */
export enum AssessmentQuestionType {
  Multichoice = "multichoice",
  Range = "range",
  Text = "text",
}

/** An enumeration. */
export enum AssessmentState {
  Draft = "draft",
  Submitted = "submitted",
}

export type AssessmentTemplateComplianceRequirement = {
  readonly anonymity?: InputMaybe<AssessmentGroupAnonymity>;
  readonly assessmentType: AssessmentType;
  readonly delivery?: InputMaybe<AssessmentGroupDelivery>;
  readonly id?: InputMaybe<Scalars["Int"]["input"]>;
  readonly isQuestionWeightingEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly providers?: InputMaybe<AssessmentGroupProviders>;
  readonly questionSetIds: ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>;
};

export type AssessmentTemplateNode = DjangoNode &
  Node & {
    readonly __typename: "AssessmentTemplateNode";
    readonly anonymity: AssessmentGroupAnonymity;
    readonly assessmentType: AssessmentType;
    readonly assessments: AssessmentNodeConnection;
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly created: Scalars["DateTime"]["output"];
    readonly creator?: Maybe<UserNode>;
    readonly delivery: AssessmentGroupDelivery;
    readonly hasSelfAssessment: Scalars["Boolean"]["output"];
    readonly id: Scalars["ID"]["output"];
    readonly isOnlySelfAssessment: Scalars["Boolean"]["output"];
    readonly isQuestionWeightingEnabled: Scalars["Boolean"]["output"];
    readonly organization: OrganizationNode;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly providers: AssessmentGroupProviders;
    readonly questionSets: AssessmentGroupNodeConnection;
    readonly updated: Scalars["DateTime"]["output"];
  };

export type AssessmentTemplateNodeAssessmentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assessmentType?: InputMaybe<AssessmentType>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  responderId?: InputMaybe<Scalars["Int"]["input"]>;
  selfAssessment?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<AssessmentState>;
  targetId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssessmentTemplateNodeQuestionSetsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
  assessmentType?: InputMaybe<AssessmentType>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** An enumeration. */
export enum AssessmentType {
  Manager = "manager",
  Peer = "peer",
  Performance = "performance",
}

export type AssociateArtifactWithTopicOrArtifactMutation = {
  readonly __typename: "AssociateArtifactWithTopicOrArtifactMutation";
  readonly artifact?: Maybe<ArtifactInterface>;
  readonly otherArtifact?: Maybe<ArtifactInterface>;
  readonly topic?: Maybe<TopicNode>;
};

export type AttendeeInput = {
  readonly email: Scalars["String"]["input"];
  readonly role?: InputMaybe<AttendeeRole>;
};

/** An enumeration. */
export enum AttendeeRole {
  Optional = "optional",
  Required = "required",
  Resource = "resource",
}

/** An enumeration. */
export enum AttendeeStatus {
  Accepted = "accepted",
  Declined = "declined",
  NotResponded = "not_responded",
  Tentative = "tentative",
}

/** Contains only common fields, so it can be used in other unions (like SearchUnion) */
export type BaseArtifactNode = DjangoNode &
  Node & {
    readonly __typename: "BaseArtifactNode";
    readonly artifactType: ArtifactType;
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly created?: Maybe<Scalars["DateTime"]["output"]>;
    readonly creator?: Maybe<UserNode>;
    readonly id: Scalars["ID"]["output"];
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly specificArtifact?: Maybe<ArtifactInterface>;
    readonly title: Scalars["String"]["output"];
    readonly topics?: Maybe<TopicNodeConnection>;
    readonly updated?: Maybe<Scalars["DateTime"]["output"]>;
  };

/** Contains only common fields, so it can be used in other unions (like SearchUnion) */
export type BaseArtifactNodeTopicsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assignee?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasActionItems?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasArtifacts?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDecisions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDocuments?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasGoals?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInFlowId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInMeetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<TopicState>;
  titleContains?: InputMaybe<Scalars["String"]["input"]>;
  topicIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type BasicFeedbackArtifactNode = DjangoNode &
  Node & {
    readonly __typename: "BasicFeedbackArtifactNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly created: Scalars["DateTime"]["output"];
    readonly creator?: Maybe<UserNode>;
    readonly feedbackArtifact?: Maybe<FeedbackArtifactNode>;
    readonly feedbackState: FeedbackState;
    readonly id: Scalars["ID"]["output"];
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly recipients?: Maybe<UserNodeConnection>;
    readonly sender?: Maybe<UserNode>;
    readonly title?: Maybe<Scalars["String"]["output"]>;
  };

export type BasicFeedbackArtifactNodeRecipientsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  hasNoManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActiveOrInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  reportIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type BasicFeedbackArtifactNodeConnection = {
  readonly __typename: "BasicFeedbackArtifactNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<BasicFeedbackArtifactNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `BasicFeedbackArtifactNode` and its cursor. */
export type BasicFeedbackArtifactNodeEdge = {
  readonly __typename: "BasicFeedbackArtifactNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<BasicFeedbackArtifactNode>;
};

export type BasicMeetingNode = DjangoNode &
  Node & {
    readonly __typename: "BasicMeetingNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead: PermissionNode;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly endDatetime?: Maybe<Scalars["DateTime"]["output"]>;
    readonly freebusyOnly?: Maybe<Scalars["Boolean"]["output"]>;
    readonly id: Scalars["ID"]["output"];
    readonly isCurrentUserParticipant: Scalars["Boolean"]["output"];
    readonly meeting?: Maybe<MeetingNode>;
    readonly meetingGroupId: Scalars["Int"]["output"];
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly startDatetime?: Maybe<Scalars["DateTime"]["output"]>;
    readonly title?: Maybe<Scalars["String"]["output"]>;
  };

export type BasicMeetingNodeConnection = {
  readonly __typename: "BasicMeetingNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<BasicMeetingNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `BasicMeetingNode` and its cursor. */
export type BasicMeetingNodeEdge = {
  readonly __typename: "BasicMeetingNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<BasicMeetingNode>;
};

export type CalendarMeetingGroupNode = DjangoNode &
  Node & {
    readonly __typename: "CalendarMeetingGroupNode";
    readonly allowAdminVisibility?: Maybe<Scalars["Boolean"]["output"]>;
    readonly artifacts?: Maybe<ArtifactConnection>;
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly created: Scalars["DateTime"]["output"];
    readonly creator: UserNode;
    readonly description: Scalars["String"]["output"];
    readonly draft: Scalars["Boolean"]["output"];
    readonly hasTemplate?: Maybe<Scalars["Boolean"]["output"]>;
    readonly id: Scalars["ID"]["output"];
    readonly meetings?: Maybe<MeetingNodeConnection>;
    readonly onlineConference?: Maybe<OnlineConference>;
    readonly organization?: Maybe<OrganizationNode>;
    readonly organizer?: Maybe<UserNode>;
    readonly participants?: Maybe<MeetingParticipantConnection>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly recurrenceParameters?: Maybe<FrequencyNode>;
    readonly recurrenceRule?: Maybe<Scalars["String"]["output"]>;
    readonly syncCredentials?: Maybe<UserSyncCredentialsNode>;
    readonly templateSeries?: Maybe<TemplateSeriesNodeConnection>;
    readonly title?: Maybe<Scalars["String"]["output"]>;
    readonly topics?: Maybe<TopicNodeConnection>;
    readonly zoom?: Maybe<ZoomMeetingNode>;
  };

export type CalendarMeetingGroupNodeArtifactsArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CalendarMeetingGroupNodeMeetingsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  endDatetime?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  excludeMeetingGroupIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  fromMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  ignored?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFormalOneonone?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Decimal"]["input"]>;
  participantCount?: InputMaybe<Scalars["Int"]["input"]>;
  participants?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  startDatetime?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<EventStatus>;
  status_In?: InputMaybe<ReadonlyArray<InputMaybe<EventStatus>>>;
  templateId?: InputMaybe<Scalars["Int"]["input"]>;
  unscheduled?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CalendarMeetingGroupNodeParticipantsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type CalendarMeetingGroupNodeTemplateSeriesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CalendarMeetingGroupNodeTopicsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assignee?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasActionItems?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasArtifacts?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDecisions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDocuments?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasGoals?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInFlowId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInMeetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<TopicState>;
  titleContains?: InputMaybe<Scalars["String"]["input"]>;
  topicIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type CalendarMeetingGroupNodeConnection = {
  readonly __typename: "CalendarMeetingGroupNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<CalendarMeetingGroupNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `CalendarMeetingGroupNode` and its cursor. */
export type CalendarMeetingGroupNodeEdge = {
  readonly __typename: "CalendarMeetingGroupNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<CalendarMeetingGroupNode>;
};

export type CancelMeetingMutation = {
  readonly __typename: "CancelMeetingMutation";
  readonly meeting?: Maybe<MeetingNode>;
};

export type CareerTrackNode = DjangoNode &
  Node & {
    readonly __typename: "CareerTrackNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly id: Scalars["ID"]["output"];
    readonly organization?: Maybe<OrganizationNode>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly roles: CareerTrackRoleNodeConnection;
    readonly team?: Maybe<TeamNode>;
    readonly title: Scalars["String"]["output"];
  };

export type CareerTrackNodeRolesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CareerTrackNodeConnection = {
  readonly __typename: "CareerTrackNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<CareerTrackNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `CareerTrackNode` and its cursor. */
export type CareerTrackNodeEdge = {
  readonly __typename: "CareerTrackNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<CareerTrackNode>;
};

export type CareerTrackRoleInput = {
  readonly assignToUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  readonly id?: InputMaybe<Scalars["Int"]["input"]>;
  readonly level?: InputMaybe<Scalars["Int"]["input"]>;
  readonly responsibilities?: InputMaybe<
    ReadonlyArray<InputMaybe<RoleResponsibilityInput>>
  >;
  readonly roleSpecificCriteria?: InputMaybe<
    ReadonlyArray<InputMaybe<CompetencyCriteriaInput>>
  >;
  readonly roleType?: InputMaybe<CareerTrackRoleType>;
  readonly title?: InputMaybe<Scalars["String"]["input"]>;
};

export type CareerTrackRoleNode = DjangoNode &
  Node & {
    readonly __typename: "CareerTrackRoleNode";
    readonly assignedUsers: UserNodeConnection;
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly competencies: CompetencyConnection;
    readonly id: Scalars["ID"]["output"];
    readonly level: Scalars["Int"]["output"];
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly responsibilities: CareerTrackRoleResponsibilityNodeConnection;
    readonly roleType: CareerTrackRoleType;
    readonly title: Scalars["String"]["output"];
  };

export type CareerTrackRoleNodeAssignedUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  hasNoManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActiveOrInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  reportIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type CareerTrackRoleNodeCompetenciesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CareerTrackRoleNodeResponsibilitiesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CareerTrackRoleNodeConnection = {
  readonly __typename: "CareerTrackRoleNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<CareerTrackRoleNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `CareerTrackRoleNode` and its cursor. */
export type CareerTrackRoleNodeEdge = {
  readonly __typename: "CareerTrackRoleNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<CareerTrackRoleNode>;
};

export type CareerTrackRoleResponsibilityNode = DjangoNode &
  Node & {
    readonly __typename: "CareerTrackRoleResponsibilityNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly content: Scalars["JSONString"]["output"];
    readonly id: Scalars["ID"]["output"];
    readonly ordinal: Scalars["Int"]["output"];
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
  };

export type CareerTrackRoleResponsibilityNodeConnection = {
  readonly __typename: "CareerTrackRoleResponsibilityNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<CareerTrackRoleResponsibilityNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `CareerTrackRoleResponsibilityNode` and its cursor. */
export type CareerTrackRoleResponsibilityNodeEdge = {
  readonly __typename: "CareerTrackRoleResponsibilityNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<CareerTrackRoleResponsibilityNode>;
};

/** An enumeration. */
export enum CareerTrackRoleType {
  Ic = "ic",
  Management = "management",
}

export type ChangeBotRecordingStatusMutation = {
  readonly __typename: "ChangeBotRecordingStatusMutation";
  readonly meeting?: Maybe<MeetingNode>;
};

export type ChangedFieldRelatedObjectUnion =
  | BaseArtifactNode
  | CommentNode
  | KeyResultNode
  | TopicNode
  | UserNode;

export type ChatMessageConnection = {
  readonly __typename: "ChatMessageConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ChatMessageEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `ChatMessage` and its cursor. */
export type ChatMessageEdge = {
  readonly __typename: "ChatMessageEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<ChatMessageNode>;
};

export type ChatMessageNode = {
  readonly __typename: "ChatMessageNode";
  readonly actionItems?: Maybe<ActionItemArtifactConnection>;
  readonly autogenerated?: Maybe<Scalars["Boolean"]["output"]>;
  readonly content: Scalars["String"]["output"];
  readonly contextEndDate?: Maybe<Scalars["Date"]["output"]>;
  readonly contextMeetings?: Maybe<MeetingNodeConnection>;
  readonly contextStartDate?: Maybe<Scalars["Date"]["output"]>;
  readonly createdArtifact?: Maybe<ArtifactInterface>;
  readonly goals?: Maybe<GoalArtifactConnection>;
  readonly id: Scalars["String"]["output"];
  readonly recognitions?: Maybe<RecognitionArtifactConnection>;
  readonly role: ChatbotRoleEnum;
  readonly suggestedRecognition?: Maybe<SuggestedRecognitionNode>;
};

export type ChatMessageNodeActionItemsArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ChatMessageNodeContextMeetingsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  endDatetime?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  excludeMeetingGroupIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  fromMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  ignored?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFormalOneonone?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Decimal"]["input"]>;
  participantCount?: InputMaybe<Scalars["Int"]["input"]>;
  participants?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  startDatetime?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<EventStatus>;
  status_In?: InputMaybe<ReadonlyArray<InputMaybe<EventStatus>>>;
  templateId?: InputMaybe<Scalars["Int"]["input"]>;
  unscheduled?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ChatMessageNodeGoalsArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ChatMessageNodeRecognitionsArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ChatSessionNode = DjangoNode &
  Node & {
    readonly __typename: "ChatSessionNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly created: Scalars["DateTime"]["output"];
    readonly creator?: Maybe<UserNode>;
    readonly id: Scalars["ID"]["output"];
    readonly messages?: Maybe<ChatMessageConnection>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly updated: Scalars["DateTime"]["output"];
  };

export type ChatSessionNodeMessagesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ChatSessionNodeConnection = {
  readonly __typename: "ChatSessionNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ChatSessionNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `ChatSessionNode` and its cursor. */
export type ChatSessionNodeEdge = {
  readonly __typename: "ChatSessionNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<ChatSessionNode>;
};

export enum ChatbotRoleEnum {
  Assistant = "assistant",
  System = "system",
  User = "user",
}

export type ClearDismissedInsightsMutation = {
  readonly __typename: "ClearDismissedInsightsMutation";
  readonly user?: Maybe<UserNode>;
};

export type ClickupTaskNode = InfoCardInterface & {
  readonly __typename: "ClickupTaskNode";
  readonly assignees: ReadonlyArray<Maybe<ExternalProfileNode>>;
  readonly closedAt?: Maybe<Scalars["DateTime"]["output"]>;
  readonly created: Scalars["DateTime"]["output"];
  readonly description?: Maybe<Scalars["String"]["output"]>;
  readonly id: Scalars["ID"]["output"];
  readonly providerName?: Maybe<Scalars["String"]["output"]>;
  readonly status?: Maybe<InfoTaskStatus>;
  readonly title: Scalars["String"]["output"];
  readonly url: Scalars["String"]["output"];
};

export type CommentNode = DjangoNode &
  Node & {
    readonly __typename: "CommentNode";
    readonly artifact?: Maybe<ArtifactInterface>;
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly comment?: Maybe<Scalars["JSONString"]["output"]>;
    readonly created: Scalars["DateTime"]["output"];
    readonly creator?: Maybe<UserNode>;
    readonly deleted?: Maybe<Scalars["Boolean"]["output"]>;
    readonly id: Scalars["ID"]["output"];
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly textUuid?: Maybe<Scalars["String"]["output"]>;
    readonly topic?: Maybe<TopicNode>;
  };

export type CommentNodeConnection = {
  readonly __typename: "CommentNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<CommentNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `CommentNode` and its cursor. */
export type CommentNodeEdge = {
  readonly __typename: "CommentNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<CommentNode>;
};

/** An enumeration. */
export enum CompetencyAppliesTo {
  AllRoles = "all_roles",
  SomeRoles = "some_roles",
}

export type CompetencyConnection = {
  readonly __typename: "CompetencyConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<CompetencyEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

export type CompetencyCriteriaConnection = {
  readonly __typename: "CompetencyCriteriaConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<CompetencyCriteriaEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `CompetencyCriteria` and its cursor. */
export type CompetencyCriteriaEdge = {
  readonly __typename: "CompetencyCriteriaEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<CompetencyCriteriaNode>;
};

export type CompetencyCriteriaInput = {
  readonly competencyId?: InputMaybe<Scalars["Int"]["input"]>;
  readonly content?: InputMaybe<Scalars["String"]["input"]>;
  readonly id?: InputMaybe<Scalars["Int"]["input"]>;
  readonly level?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CompetencyCriteriaNode = {
  readonly __typename: "CompetencyCriteriaNode";
  readonly content: Scalars["JSONString"]["output"];
  readonly id: Scalars["Int"]["output"];
  readonly level: Scalars["Int"]["output"];
  readonly pk: Scalars["Int"]["output"];
};

/** An enumeration. */
export enum CompetencyCriteriaUniqueness {
  DifferentForEachRole = "different_for_each_role",
  SameAcrossRoles = "same_across_roles",
}

/** A Relay edge containing a `Competency` and its cursor. */
export type CompetencyEdge = {
  readonly __typename: "CompetencyEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<CompetencyNode>;
};

export type CompetencyNode = {
  readonly __typename: "CompetencyNode";
  readonly appliesTo: CompetencyAppliesTo;
  readonly appliesToCareerTracks: CareerTrackNodeConnection;
  readonly appliesToIcRoles: Scalars["Boolean"]["output"];
  readonly appliesToManagementRoles: Scalars["Boolean"]["output"];
  readonly appliesToTeams: TeamNodeConnection;
  readonly criteria: CompetencyCriteriaConnection;
  readonly criteriaUniqueness: CompetencyCriteriaUniqueness;
  readonly description: Scalars["JSONString"]["output"];
  readonly id: Scalars["Int"]["output"];
  readonly pk: Scalars["Int"]["output"];
  readonly title: Scalars["String"]["output"];
};

export type CompetencyNodeAppliesToCareerTracksArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CompetencyNodeAppliesToTeamsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_Icontains?: InputMaybe<Scalars["String"]["input"]>;
  title_Startswith?: InputMaybe<Scalars["String"]["input"]>;
};

export type CompetencyNodeCriteriaArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

/** An enumeration. */
export enum ComplianceProgramAppliesTo {
  Departments = "departments",
  Managers = "managers",
  Organization = "organization",
  Users = "users",
}

export type ComplianceProgramNode = DjangoNode &
  Node & {
    readonly __typename: "ComplianceProgramNode";
    readonly applicableUsers: UserNodeConnection;
    readonly appliesTo: ComplianceProgramAppliesTo;
    readonly appliesToManagers: UserNodeConnection;
    readonly appliesToTeams: TeamNodeConnection;
    readonly appliesToUsers: UserNodeConnection;
    readonly assessments: AssessmentNodeConnection;
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly created: Scalars["DateTime"]["output"];
    readonly dueDate?: Maybe<Scalars["Date"]["output"]>;
    readonly excludedHireDateAfter?: Maybe<Scalars["Date"]["output"]>;
    readonly excludedUsers: UserNodeConnection;
    readonly id: Scalars["ID"]["output"];
    readonly managerAssessmentTemplate?: Maybe<AssessmentTemplateNode>;
    readonly managerEffectivenessReport: ManagerEffectivenessReportConnection;
    readonly matchingOneonones?: Maybe<MeetingNodeConnection>;
    readonly nominationsForUser: AssessmentNominationNodeConnection;
    readonly ongoing: Scalars["Boolean"]["output"];
    readonly participantStatus: ParticipantStatusConnection;
    readonly peerAssessmentTemplate?: Maybe<AssessmentTemplateNode>;
    readonly performanceAssessmentTemplate?: Maybe<AssessmentTemplateNode>;
    readonly periodEndDate?: Maybe<Scalars["Date"]["output"]>;
    readonly periodStartDate?: Maybe<Scalars["Date"]["output"]>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly recurrence: ComplianceProgramRecurrence;
    readonly requiredTopicTemplates: TopicTemplateNodeConnection;
    readonly startDate?: Maybe<Scalars["Date"]["output"]>;
    readonly startDateDueDateOffset: Scalars["Int"]["output"];
    readonly startDateOffset: Scalars["Int"]["output"];
    readonly startDatePeriodEndOffset: Scalars["Int"]["output"];
    readonly startDatePeriodStartOffset: Scalars["Int"]["output"];
    readonly state: ComplianceProgramState;
    readonly title: Scalars["String"]["output"];
    readonly updated: Scalars["DateTime"]["output"];
    readonly usersMissingAssessment: MissingAssessmentConnection;
  };

export type ComplianceProgramNodeApplicableUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  hasNoManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActiveOrInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  reportIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type ComplianceProgramNodeAppliesToManagersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  hasNoManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActiveOrInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  reportIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type ComplianceProgramNodeAppliesToTeamsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_Icontains?: InputMaybe<Scalars["String"]["input"]>;
  title_Startswith?: InputMaybe<Scalars["String"]["input"]>;
};

export type ComplianceProgramNodeAppliesToUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  hasNoManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActiveOrInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  reportIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type ComplianceProgramNodeAssessmentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assessmentType?: InputMaybe<AssessmentType>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  responderId?: InputMaybe<Scalars["Int"]["input"]>;
  selfAssessment?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<AssessmentState>;
  targetId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ComplianceProgramNodeExcludedUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  hasNoManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActiveOrInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  reportIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type ComplianceProgramNodeManagerEffectivenessReportArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ComplianceProgramNodeMatchingOneononesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  endDatetime?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  excludeMeetingGroupIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  fromMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  ignored?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFormalOneonone?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Decimal"]["input"]>;
  participantCount?: InputMaybe<Scalars["Int"]["input"]>;
  participants?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  startDatetime?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<EventStatus>;
  status_In?: InputMaybe<ReadonlyArray<InputMaybe<EventStatus>>>;
  templateId?: InputMaybe<Scalars["Int"]["input"]>;
  unscheduled?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ComplianceProgramNodeNominationsForUserArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ComplianceProgramNodeParticipantStatusArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ComplianceProgramNodeRequiredTopicTemplatesArgs = {
  adhocTemplate?: InputMaybe<Scalars["Boolean"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  globalTemplate?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasRequirements?: InputMaybe<Scalars["Boolean"]["input"]>;
  includesRatingId?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  publicTemplate?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ComplianceProgramNodeUsersMissingAssessmentArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ComplianceProgramNodeConnection = {
  readonly __typename: "ComplianceProgramNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ComplianceProgramNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `ComplianceProgramNode` and its cursor. */
export type ComplianceProgramNodeEdge = {
  readonly __typename: "ComplianceProgramNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<ComplianceProgramNode>;
};

/** An enumeration. */
export enum ComplianceProgramParticipantStatus {
  Complete = "complete",
  InProgress = "in_progress",
  NotApplicable = "not_applicable",
  NotStarted = "not_started",
}

/** An enumeration. */
export enum ComplianceProgramRecurrence {
  Monthly = "monthly",
  None = "none",
  Quarterly = "quarterly",
  Yearly = "yearly",
}

/** An enumeration. */
export enum ComplianceProgramState {
  Draft = "draft",
  Paused = "paused",
  Published = "published",
}

export type ComplianceReportUserConnection = {
  readonly __typename: "ComplianceReportUserConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ComplianceReportUserEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `ComplianceReportUser` and its cursor. */
export type ComplianceReportUserEdge = {
  readonly __typename: "ComplianceReportUserEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<ComplianceReportUserNode>;
};

export type ComplianceReportUserNode = {
  readonly __typename: "ComplianceReportUserNode";
  readonly manager?: Maybe<UserNode>;
  readonly matchedMeeting?: Maybe<MeetingInfoNode>;
  readonly user?: Maybe<UserNode>;
};

export type ConvertSuggestedTopicToTopicMutation = {
  readonly __typename: "ConvertSuggestedTopicToTopicMutation";
  readonly meeting?: Maybe<MeetingNode>;
  readonly topic?: Maybe<TopicNode>;
};

export type CopyTopicsToMeetingMutation = {
  readonly __typename: "CopyTopicsToMeetingMutation";
  readonly copiedTopics?: Maybe<ReadonlyArray<Maybe<TopicNode>>>;
  readonly meeting?: Maybe<MeetingNode>;
};

/** An enumeration. */
export enum CoreUserSyncCredentialsByOrgDefaultEventVisibilityChoices {
  /** default */
  A_1 = "A_1",
  /** freebusy */
  A_2 = "A_2",
  /** public */
  A_3 = "A_3",
  /** hidden */
  A_4 = "A_4",
}

export type CreateFeedbackRequestsMutation = {
  readonly __typename: "CreateFeedbackRequestsMutation";
  readonly feedbackArtifacts?: Maybe<
    ReadonlyArray<Maybe<FeedbackArtifactNode>>
  >;
};

export type CreateGoalCheckinMutation = {
  readonly __typename: "CreateGoalCheckinMutation";
  readonly checkinActivity?: Maybe<ArtifactActivityNode>;
  readonly goal?: Maybe<GoalArtifactNode>;
};

export type CreateInvitationsMutation = {
  readonly __typename: "CreateInvitationsMutation";
  readonly invitationErrors?: Maybe<ReadonlyArray<Maybe<InviteError>>>;
  readonly invitations?: Maybe<ReadonlyArray<Maybe<InvitationNode>>>;
};

export type CreateOrUpdateArtifactMutation = {
  readonly __typename: "CreateOrUpdateArtifactMutation";
  readonly artifact?: Maybe<ArtifactInterface>;
  readonly topic?: Maybe<TopicNode>;
};

export type CreateOrUpdateAssessmentGroupMutation = {
  readonly __typename: "CreateOrUpdateAssessmentGroupMutation";
  readonly assessmentGroup?: Maybe<AssessmentGroupNode>;
};

export type CreateOrUpdateAssessmentMutation = {
  readonly __typename: "CreateOrUpdateAssessmentMutation";
  readonly assessment: AssessmentNode;
};

export type CreateOrUpdateAssessmentQuestionMutation = {
  readonly __typename: "CreateOrUpdateAssessmentQuestionMutation";
  readonly question?: Maybe<AssessmentQuestionInterface>;
};

export type CreateOrUpdateCareerTrackMutation = {
  readonly __typename: "CreateOrUpdateCareerTrackMutation";
  readonly careerTrack?: Maybe<CareerTrackNode>;
};

export type CreateOrUpdateCommentMutation = {
  readonly __typename: "CreateOrUpdateCommentMutation";
  readonly artifact?: Maybe<ArtifactInterface>;
  readonly comment?: Maybe<CommentNode>;
  readonly topic?: Maybe<TopicNode>;
};

export type CreateOrUpdateCompetencyMutation = {
  readonly __typename: "CreateOrUpdateCompetencyMutation";
  readonly competency?: Maybe<CompetencyNode>;
};

export type CreateOrUpdateComplianceProgramMutation = {
  readonly __typename: "CreateOrUpdateComplianceProgramMutation";
  readonly complianceProgram?: Maybe<ComplianceProgramNode>;
};

export type CreateOrUpdateCoreValueMutation = {
  readonly __typename: "CreateOrUpdateCoreValueMutation";
  readonly coreValue?: Maybe<RecognitionCoreValueNode>;
};

export type CreateOrUpdateExplorerBookmarkMutation = {
  readonly __typename: "CreateOrUpdateExplorerBookmarkMutation";
  readonly explorerBookmark?: Maybe<ExplorerFilterBookmarkNode>;
  readonly user?: Maybe<UserNode>;
};

export type CreateOrUpdateFavouritesGroupMutation = {
  readonly __typename: "CreateOrUpdateFavouritesGroupMutation";
  readonly favouritesGroup?: Maybe<FavouritesGroupNode>;
};

export type CreateOrUpdateFlowMutation = {
  readonly __typename: "CreateOrUpdateFlowMutation";
  readonly flow?: Maybe<FlowNode>;
};

export type CreateOrUpdateKpi = {
  readonly __typename: "CreateOrUpdateKPI";
  readonly kpi?: Maybe<KpiNode>;
};

export type CreateOrUpdateKpiGroupMutation = {
  readonly __typename: "CreateOrUpdateKPIGroupMutation";
  readonly kpiGroup?: Maybe<KpiGroupNode>;
};

export type CreateOrUpdateKpiMeasurementMutation = {
  readonly __typename: "CreateOrUpdateKPIMeasurementMutation";
  readonly kpi?: Maybe<KpiNode>;
  readonly kpiMeasurement?: Maybe<KpiMeasurementNode>;
};

export type CreateOrUpdateKvItemMutation = {
  readonly __typename: "CreateOrUpdateKVItemMutation";
  readonly kvItem?: Maybe<UserKvItemNode>;
  readonly user?: Maybe<UserNode>;
};

export type CreateOrUpdateKeyResultMutation = {
  readonly __typename: "CreateOrUpdateKeyResultMutation";
  readonly goal?: Maybe<GoalArtifactNode>;
  readonly keyResult?: Maybe<KeyResultNode>;
};

export type CreateOrUpdateMeetingInExternalCalendarMutation = {
  readonly __typename: "CreateOrUpdateMeetingInExternalCalendarMutation";
  readonly calendarMeetingGroup?: Maybe<CalendarMeetingGroupNode>;
  /** The created meeting, or the first meeting in the recurring series */
  readonly meeting?: Maybe<MeetingNode>;
  readonly meetingGroup?: Maybe<MeetingGroupNode>;
};

export type CreateOrUpdateOrganizationMutation = {
  readonly __typename: "CreateOrUpdateOrganizationMutation";
  readonly organization?: Maybe<OrganizationNode>;
};

export type CreateOrUpdateRatingAnswerMutation = {
  readonly __typename: "CreateOrUpdateRatingAnswerMutation";
  readonly ratingAnswer?: Maybe<RatingAnswerNode>;
  readonly ratingRequirement?: Maybe<MeetingRatingRequirementNode>;
};

export type CreateOrUpdateRatingMutation = {
  readonly __typename: "CreateOrUpdateRatingMutation";
  readonly rating?: Maybe<RatingNode>;
};

export type CreateOrUpdateSlackNotificationForChannelMutation = {
  readonly __typename: "CreateOrUpdateSlackNotificationForChannelMutation";
  readonly notificationSettings?: Maybe<SlackNotificationForChannelNode>;
  readonly organization?: Maybe<OrganizationNode>;
};

export type CreateOrUpdateSlackNotificationsForMeetingGroup = {
  readonly __typename: "CreateOrUpdateSlackNotificationsForMeetingGroup";
  readonly meetingGroup?: Maybe<MeetingGroupNode>;
  readonly notification?: Maybe<SlackNotificationConnectionNode>;
};

export type CreateOrUpdateTeamMutation = {
  readonly __typename: "CreateOrUpdateTeamMutation";
  readonly team?: Maybe<TeamNode>;
};

export type CreateOrUpdateTemplateRequirementsMutation = {
  readonly __typename: "CreateOrUpdateTemplateRequirementsMutation";
  readonly template?: Maybe<TopicTemplateNode>;
};

export type CreateOrUpdateTemplateSeriesForMeetingMutation = {
  readonly __typename: "CreateOrUpdateTemplateSeriesForMeetingMutation";
  readonly templateSeries?: Maybe<TemplateSeriesNode>;
};

export type CreateOrUpdateTopicMutation = {
  readonly __typename: "CreateOrUpdateTopicMutation";
  readonly meeting?: Maybe<MeetingNode>;
  readonly topic?: Maybe<TopicNode>;
  readonly topicTemplate?: Maybe<TopicTemplateNode>;
};

export type CreateOrUpdateTopicTemplateCategoryMutation = {
  readonly __typename: "CreateOrUpdateTopicTemplateCategoryMutation";
  readonly topicTemplateCategory?: Maybe<TemplateCategoryNode>;
};

export type CreateOrUpdateTopicTemplateMutation = {
  readonly __typename: "CreateOrUpdateTopicTemplateMutation";
  readonly topicTemplate?: Maybe<TopicTemplateNode>;
};

export type CreateOrUpdateWorkflowMutation = {
  readonly __typename: "CreateOrUpdateWorkflowMutation";
  readonly workflow?: Maybe<WorkflowNode>;
};

export type CreateTemplateFromMeetingMutation = {
  readonly __typename: "CreateTemplateFromMeetingMutation";
  readonly meeting?: Maybe<MeetingNode>;
  readonly topicTemplate?: Maybe<TopicTemplateNode>;
};

export type DecisionArtifactNode = ArtifactInterface & {
  readonly __typename: "DecisionArtifactNode";
  readonly activities: ArtifactActivityNodeConnection;
  readonly artifactType: ArtifactType;
  readonly assignableUsers?: Maybe<UserNodeConnection>;
  readonly canDelete: PermissionNode;
  readonly canRead: PermissionNode;
  readonly canUpdate: PermissionNode;
  readonly commentCount?: Maybe<Scalars["Int"]["output"]>;
  readonly comments?: Maybe<CommentNodeConnection>;
  readonly created: Scalars["DateTime"]["output"];
  readonly createdInMeeting?: Maybe<MeetingNode>;
  readonly creator?: Maybe<UserNode>;
  readonly decision: Scalars["String"]["output"];
  readonly decisionState?: Maybe<DecisionState>;
  readonly description?: Maybe<Scalars["JSONString"]["output"]>;
  readonly eventChannelName?: Maybe<Scalars["String"]["output"]>;
  readonly id: Scalars["Int"]["output"];
  readonly isDraft?: Maybe<Scalars["Boolean"]["output"]>;
  readonly isStale: Scalars["Boolean"]["output"];
  readonly organization?: Maybe<OrganizationNode>;
  readonly relatedArtifacts?: Maybe<ArtifactToArtifactConnection>;
  readonly title?: Maybe<Scalars["String"]["output"]>;
  readonly topics?: Maybe<ArtifactToTopicConnection>;
  readonly updated?: Maybe<Scalars["DateTime"]["output"]>;
  readonly uuid?: Maybe<Scalars["String"]["output"]>;
  readonly websocketToken?: Maybe<Scalars["String"]["output"]>;
};

export type DecisionArtifactNodeActivitiesArgs = {
  activityType?: InputMaybe<ArtifactActivityType>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DecisionArtifactNodeAssignableUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  hasNoManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActiveOrInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  reportIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type DecisionArtifactNodeCommentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  artifactId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  isInlineComment?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  textUuid?: InputMaybe<Scalars["String"]["input"]>;
  topicId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DecisionArtifactNodeRelatedArtifactsArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DecisionArtifactNodeTopicsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assignee?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasActionItems?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasArtifacts?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDecisions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDocuments?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasGoals?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInFlowId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInMeetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<TopicState>;
  titleContains?: InputMaybe<Scalars["String"]["input"]>;
  topicIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

/** An enumeration. */
export enum DecisionState {
  Decided = "decided",
  Draft = "draft",
}

export type DeleteArtifactMutation = {
  readonly __typename: "DeleteArtifactMutation";
  readonly artifact?: Maybe<ArtifactInterface>;
};

export type DeleteAssessmentAnswerMutation = {
  readonly __typename: "DeleteAssessmentAnswerMutation";
  readonly ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type DeleteAssessmentGroupMutation = {
  readonly __typename: "DeleteAssessmentGroupMutation";
  readonly ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type DeleteAssessmentQuestionMutation = {
  readonly __typename: "DeleteAssessmentQuestionMutation";
  readonly ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type DeleteCareerTrackMutation = {
  readonly __typename: "DeleteCareerTrackMutation";
  readonly ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type DeleteCommentMutation = {
  readonly __typename: "DeleteCommentMutation";
  readonly artifact?: Maybe<ArtifactInterface>;
  readonly topic?: Maybe<TopicNode>;
};

export type DeleteCompetencyMutation = {
  readonly __typename: "DeleteCompetencyMutation";
  readonly ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type DeleteComplianceProgramMutation = {
  readonly __typename: "DeleteComplianceProgramMutation";
  readonly organization?: Maybe<OrganizationNode>;
};

export type DeleteCoreValueMutation = {
  readonly __typename: "DeleteCoreValueMutation";
  readonly ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type DeleteExplorerBookmark = {
  readonly __typename: "DeleteExplorerBookmark";
  readonly user?: Maybe<UserNode>;
};

export type DeleteFavouritesGroupMutation = {
  readonly __typename: "DeleteFavouritesGroupMutation";
  readonly user?: Maybe<UserNode>;
};

export type DeleteFlowMutation = {
  readonly __typename: "DeleteFlowMutation";
  readonly flow?: Maybe<FlowNode>;
};

export type DeleteKpi = {
  readonly __typename: "DeleteKPI";
  readonly ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type DeleteKpiGroupMutation = {
  readonly __typename: "DeleteKPIGroupMutation";
  readonly kpiGroup?: Maybe<KpiGroupNode>;
};

export type DeleteKpiIntegrationMutation = {
  readonly __typename: "DeleteKPIIntegrationMutation";
  readonly kpi?: Maybe<KpiNode>;
};

export type DeleteKpiMeasurement = {
  readonly __typename: "DeleteKPIMeasurement";
  readonly ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type DeleteKeyResultMutation = {
  readonly __typename: "DeleteKeyResultMutation";
  readonly goal?: Maybe<GoalArtifactNode>;
  readonly keyResult?: Maybe<KeyResultNode>;
};

export type DeleteMeetingMutation = {
  readonly __typename: "DeleteMeetingMutation";
  readonly meeting?: Maybe<MeetingNode>;
};

export type DeleteMeetingTranscriptsMutation = {
  readonly __typename: "DeleteMeetingTranscriptsMutation";
  readonly meeting?: Maybe<MeetingNode>;
};

export type DeleteRatingMutation = {
  readonly __typename: "DeleteRatingMutation";
  readonly ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type DeleteSlackNotificationForChannelMutation = {
  readonly __typename: "DeleteSlackNotificationForChannelMutation";
  readonly organization?: Maybe<OrganizationNode>;
};

export type DeleteSlackNotificationsForMeetingGroup = {
  readonly __typename: "DeleteSlackNotificationsForMeetingGroup";
  readonly meetingGroup?: Maybe<MeetingGroupNode>;
  readonly ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type DeleteTeamMutation = {
  readonly __typename: "DeleteTeamMutation";
  readonly team?: Maybe<TeamNode>;
};

export type DeleteTopicMutation = {
  readonly __typename: "DeleteTopicMutation";
  readonly ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type DeleteTopicTemplateCategoryMutation = {
  readonly __typename: "DeleteTopicTemplateCategoryMutation";
  readonly ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type DeleteTopicTemplateMutation = {
  readonly __typename: "DeleteTopicTemplateMutation";
  readonly topicTemplate?: Maybe<TopicTemplateNode>;
};

export type DeleteWorkflowMutation = {
  readonly __typename: "DeleteWorkflowMutation";
  readonly ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type DirectorySyncNode = {
  readonly __typename: "DirectorySyncNode";
  readonly id?: Maybe<Scalars["String"]["output"]>;
  readonly name?: Maybe<Scalars["String"]["output"]>;
  readonly state?: Maybe<Scalars["String"]["output"]>;
  readonly type?: Maybe<Scalars["String"]["output"]>;
};

export type DismissInsightMutation = {
  readonly __typename: "DismissInsightMutation";
  readonly user?: Maybe<UserNode>;
};

export type DjangoNode = {
  readonly canDelete?: Maybe<PermissionNode>;
  readonly canRead?: Maybe<PermissionNode>;
  readonly canUpdate?: Maybe<PermissionNode>;
  readonly id: Scalars["ID"]["output"];
  readonly pk?: Maybe<Scalars["Int"]["output"]>;
};

export type DocumentArtifactNode = ArtifactInterface & {
  readonly __typename: "DocumentArtifactNode";
  readonly activities: ArtifactActivityNodeConnection;
  readonly artifactType: ArtifactType;
  readonly assignableUsers?: Maybe<UserNodeConnection>;
  readonly canDelete: PermissionNode;
  readonly canRead: PermissionNode;
  readonly canUpdate: PermissionNode;
  readonly commentCount?: Maybe<Scalars["Int"]["output"]>;
  readonly comments?: Maybe<CommentNodeConnection>;
  readonly created: Scalars["DateTime"]["output"];
  readonly createdInMeeting?: Maybe<MeetingNode>;
  readonly creator?: Maybe<UserNode>;
  readonly description?: Maybe<Scalars["JSONString"]["output"]>;
  readonly documentVisibility?: Maybe<DocumentVisibility>;
  readonly eventChannelName?: Maybe<Scalars["String"]["output"]>;
  readonly id: Scalars["Int"]["output"];
  readonly isStale: Scalars["Boolean"]["output"];
  readonly organization?: Maybe<OrganizationNode>;
  readonly relatedArtifacts?: Maybe<ArtifactToArtifactConnection>;
  readonly title?: Maybe<Scalars["String"]["output"]>;
  readonly topics?: Maybe<ArtifactToTopicConnection>;
  readonly updated?: Maybe<Scalars["DateTime"]["output"]>;
  readonly uuid?: Maybe<Scalars["String"]["output"]>;
  readonly websocketToken?: Maybe<Scalars["String"]["output"]>;
};

export type DocumentArtifactNodeActivitiesArgs = {
  activityType?: InputMaybe<ArtifactActivityType>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DocumentArtifactNodeAssignableUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  hasNoManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActiveOrInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  reportIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type DocumentArtifactNodeCommentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  artifactId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  isInlineComment?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  textUuid?: InputMaybe<Scalars["String"]["input"]>;
  topicId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DocumentArtifactNodeRelatedArtifactsArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DocumentArtifactNodeTopicsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assignee?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasActionItems?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasArtifacts?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDecisions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDocuments?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasGoals?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInFlowId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInMeetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<TopicState>;
  titleContains?: InputMaybe<Scalars["String"]["input"]>;
  topicIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

/** An enumeration. */
export enum DocumentVisibility {
  Private = "private",
  Public = "public",
}

export type DuplicateGoalMutation = {
  readonly __typename: "DuplicateGoalMutation";
  readonly duplicatedGoal?: Maybe<ArtifactInterface>;
};

export type Email1on1NudgeMutation = {
  readonly __typename: "Email1on1NudgeMutation";
  readonly manager?: Maybe<UserNode>;
  readonly report?: Maybe<UserNode>;
  readonly topicTemplate?: Maybe<TopicTemplateNode>;
};

export type EmailAssessmentReminderMutation = {
  readonly __typename: "EmailAssessmentReminderMutation";
  readonly complianceProgram?: Maybe<ComplianceProgramNode>;
};

export type EmailMeetingInvitesMutation = {
  readonly __typename: "EmailMeetingInvitesMutation";
  readonly invitationErrors?: Maybe<ReadonlyArray<Maybe<InviteError>>>;
  readonly meeting?: Maybe<MeetingNode>;
  readonly recipients?: Maybe<ReadonlyArray<Maybe<UserNode>>>;
};

export type EmailMeetingNotesMutation = {
  readonly __typename: "EmailMeetingNotesMutation";
  readonly meeting?: Maybe<MeetingNode>;
  readonly recipients?: Maybe<ReadonlyArray<Maybe<UserNode>>>;
};

export type EmailOneOnOneReminderMutation = {
  readonly __typename: "EmailOneOnOneReminderMutation";
  readonly complianceProgram?: Maybe<ComplianceProgramNode>;
};

/** An enumeration. */
export enum EmailPreference {
  DailySummaryEmails = "daily_summary_emails",
  NoNotificationEmails = "no_notification_emails",
  NotificationEmails = "notification_emails",
}

export type EventColours = {
  readonly __typename: "EventColours";
  readonly background?: Maybe<Scalars["String"]["output"]>;
  readonly foreground?: Maybe<Scalars["String"]["output"]>;
};

/** An enumeration. */
export enum EventDescriptionLinkSetting {
  Always = "always",
  MeetingsWithTopics = "meetings_with_topics",
  Never = "never",
}

/** An enumeration. */
export enum EventStatus {
  Cancelled = "cancelled",
  Confirmed = "confirmed",
  Tentative = "tentative",
}

export type ExplorerFilterBookmarkNode = DjangoNode &
  Node & {
    readonly __typename: "ExplorerFilterBookmarkNode";
    readonly canDelete: PermissionNode;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate: PermissionNode;
    readonly created: Scalars["DateTime"]["output"];
    readonly creator?: Maybe<UserNode>;
    readonly filters: Scalars["String"]["output"];
    readonly id: Scalars["ID"]["output"];
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly private: Scalars["Boolean"]["output"];
    readonly title: Scalars["String"]["output"];
    readonly updated: Scalars["DateTime"]["output"];
  };

export type ExplorerFilterBookmarkNodeConnection = {
  readonly __typename: "ExplorerFilterBookmarkNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ExplorerFilterBookmarkNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `ExplorerFilterBookmarkNode` and its cursor. */
export type ExplorerFilterBookmarkNodeEdge = {
  readonly __typename: "ExplorerFilterBookmarkNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<ExplorerFilterBookmarkNode>;
};

export type ExportComplianceProgramToCsv = {
  readonly __typename: "ExportComplianceProgramToCSV";
  readonly complianceProgram?: Maybe<ComplianceProgramNode>;
};

export type ExternalProfileNode = {
  readonly __typename: "ExternalProfileNode";
  readonly url?: Maybe<Scalars["String"]["output"]>;
  readonly username?: Maybe<Scalars["String"]["output"]>;
};

export type FavouritesGroupNode = DjangoNode &
  Node & {
    readonly __typename: "FavouritesGroupNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly id: Scalars["ID"]["output"];
    readonly owner?: Maybe<UserNode>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly users?: Maybe<UserNodeConnection>;
  };

export type FavouritesGroupNodeUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  hasNoManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActiveOrInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  reportIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type FavouritesGroupNodeConnection = {
  readonly __typename: "FavouritesGroupNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<FavouritesGroupNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `FavouritesGroupNode` and its cursor. */
export type FavouritesGroupNodeEdge = {
  readonly __typename: "FavouritesGroupNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<FavouritesGroupNode>;
};

export type FeedNode = {
  readonly __typename: "FeedNode";
  readonly action?: Maybe<Scalars["String"]["output"]>;
  readonly actionTimestamp?: Maybe<Scalars["DateTime"]["output"]>;
  readonly actor?: Maybe<UserNode>;
  readonly object?: Maybe<FeedObjectUnion>;
};

export type FeedObjectUnion = CommentNode | TopicNode;

export type FeedbackArtifactNode = ArtifactInterface & {
  readonly __typename: "FeedbackArtifactNode";
  readonly activities: ArtifactActivityNodeConnection;
  readonly adminsCanView: Scalars["Boolean"]["output"];
  readonly artifactType: ArtifactType;
  readonly assignableUsers?: Maybe<UserNodeConnection>;
  readonly canDelete: PermissionNode;
  readonly canRead: PermissionNode;
  readonly canUpdate: PermissionNode;
  readonly commentCount?: Maybe<Scalars["Int"]["output"]>;
  readonly comments?: Maybe<CommentNodeConnection>;
  readonly created: Scalars["DateTime"]["output"];
  readonly createdInMeeting?: Maybe<MeetingNode>;
  readonly creator?: Maybe<UserNode>;
  readonly creatorCanView: Scalars["Boolean"]["output"];
  readonly description?: Maybe<Scalars["JSONString"]["output"]>;
  readonly eventChannelName?: Maybe<Scalars["String"]["output"]>;
  readonly feedbackState: FeedbackState;
  readonly id: Scalars["Int"]["output"];
  readonly isStale: Scalars["Boolean"]["output"];
  readonly message?: Maybe<Scalars["JSONString"]["output"]>;
  readonly organization?: Maybe<OrganizationNode>;
  readonly recipients: UserNodeConnection;
  readonly recipientsCanView: Scalars["Boolean"]["output"];
  readonly recipientsManagersCanView: Scalars["Boolean"]["output"];
  readonly relatedArtifacts?: Maybe<ArtifactToArtifactConnection>;
  readonly sender: UserNode;
  readonly title?: Maybe<Scalars["String"]["output"]>;
  readonly topics?: Maybe<ArtifactToTopicConnection>;
  readonly updated?: Maybe<Scalars["DateTime"]["output"]>;
  readonly uuid?: Maybe<Scalars["String"]["output"]>;
  readonly websocketToken?: Maybe<Scalars["String"]["output"]>;
};

export type FeedbackArtifactNodeActivitiesArgs = {
  activityType?: InputMaybe<ArtifactActivityType>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FeedbackArtifactNodeAssignableUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  hasNoManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActiveOrInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  reportIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type FeedbackArtifactNodeCommentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  artifactId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  isInlineComment?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  textUuid?: InputMaybe<Scalars["String"]["input"]>;
  topicId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FeedbackArtifactNodeRecipientsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  hasNoManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActiveOrInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  reportIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type FeedbackArtifactNodeRelatedArtifactsArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FeedbackArtifactNodeTopicsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assignee?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasActionItems?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasArtifacts?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDecisions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDocuments?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasGoals?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInFlowId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInMeetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<TopicState>;
  titleContains?: InputMaybe<Scalars["String"]["input"]>;
  topicIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

/** An enumeration. */
export enum FeedbackState {
  Draft = "draft",
  Requested = "requested",
  Sent = "sent",
}

/** An enumeration. */
export enum FinalizePermissions {
  AdminsOnly = "admins_only",
  AllParticipants = "all_participants",
  FacilitatorsAndAdmins = "facilitators_and_admins",
  ManagersAndAdmins = "managers_and_admins",
}

export type FlowNode = DjangoNode &
  Node & {
    readonly __typename: "FlowNode";
    readonly artifacts?: Maybe<ArtifactConnection>;
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly creator?: Maybe<UserNode>;
    readonly goals?: Maybe<ArtifactConnection>;
    readonly id: Scalars["ID"]["output"];
    readonly meetingGroups?: Maybe<MeetingGroupNodeConnection>;
    readonly meetings?: Maybe<MeetingNodeConnection>;
    readonly organization?: Maybe<OrganizationNode>;
    readonly participantCount?: Maybe<Scalars["Int"]["output"]>;
    readonly participantEmailPatterns?: Maybe<Scalars["JSONString"]["output"]>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly public: Scalars["Boolean"]["output"];
    readonly title: Scalars["String"]["output"];
  };

export type FlowNodeArtifactsArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FlowNodeGoalsArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FlowNodeMeetingGroupsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type FlowNodeMeetingsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  endDatetime?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  excludeMeetingGroupIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  fromMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  ignored?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFormalOneonone?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Decimal"]["input"]>;
  participantCount?: InputMaybe<Scalars["Int"]["input"]>;
  participants?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  startDatetime?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<EventStatus>;
  status_In?: InputMaybe<ReadonlyArray<InputMaybe<EventStatus>>>;
  templateId?: InputMaybe<Scalars["Int"]["input"]>;
  unscheduled?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type FlowNodeConnection = {
  readonly __typename: "FlowNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<FlowNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `FlowNode` and its cursor. */
export type FlowNodeEdge = {
  readonly __typename: "FlowNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<FlowNode>;
};

export enum FrequencyEnum {
  Daily = "daily",
  Monthly = "monthly",
  Weekly = "weekly",
  Yearly = "yearly",
}

export type FrequencyInput = {
  /** The month(s) that the event should repeat on */
  readonly byMonth?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** The day(s) of month that the event should repeat on */
  readonly byMonthDay?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** An integer (or integers) corresponding to the nth occurrence of the rule inside the frequency period. For example, a bysetpos of -1 if combined with a MONTHLY frequency, and a byweekday of (MO, TU, WE, TH, FR), will result in the last work day of every month */
  readonly bySetPos?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** The day(s) of the week the event should repeat on (monday = 0, tuesday = 1, etc...) */
  readonly byWeekDay?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** The day(s) of the year that the event should repeat on */
  readonly byYearDay?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** How many instances of the recurring event should exist */
  readonly count?: InputMaybe<Scalars["Int"]["input"]>;
  readonly frequency: FrequencyEnum;
  /** How often to do the thing. For example, if frequency was monthly,and interval was 2, that would mean once every two months */
  readonly interval: Scalars["Int"]["input"];
};

export type FrequencyNode = {
  readonly __typename: "FrequencyNode";
  readonly byMonth?: Maybe<ReadonlyArray<Maybe<Scalars["Int"]["output"]>>>;
  /** The day of month that the event should repeat on */
  readonly byMonthDay?: Maybe<ReadonlyArray<Maybe<Scalars["Int"]["output"]>>>;
  /** An integer corresponding to the nth occurrence of the rule inside the frequency period. For example, a bysetpos of -1 if combined with a MONTHLY frequency, and a byweekday of (MO, TU, WE, TH, FR), will result in the last work day of every month */
  readonly bySetPos?: Maybe<ReadonlyArray<Maybe<Scalars["Int"]["output"]>>>;
  /** The day of the week the event should repeat on (monday = 0, tuesday = 1, etc...) */
  readonly byWeekDay?: Maybe<ReadonlyArray<Maybe<Scalars["Int"]["output"]>>>;
  /** The day of the year that the event should repeat on */
  readonly byYearDay?: Maybe<ReadonlyArray<Maybe<Scalars["Int"]["output"]>>>;
  readonly count?: Maybe<Scalars["Int"]["output"]>;
  readonly frequency: FrequencyEnum;
  /** How often to do the thing. For example, if frequency was monthly,and interval was 2, that would mean once every two months */
  readonly interval: Scalars["Int"]["output"];
};

/** An enumeration. */
export enum GithubIntegrationMetricType {
  ClosedIssues = "closed_issues",
  ClosedPrs = "closed_prs",
  CreatedIssues = "created_issues",
  CreatedPrs = "created_prs",
  OpenIssues = "open_issues",
  OpenPrs = "open_prs",
}

/** An enumeration. */
export enum GithubIntegrationTimeBucket {
  Last_7Days = "last_7_days",
  Last_31Days = "last_31_days",
}

export type GithubIssueNode = InfoCardInterface & {
  readonly __typename: "GithubIssueNode";
  readonly assignees: ReadonlyArray<Maybe<ExternalProfileNode>>;
  readonly closedAt?: Maybe<Scalars["DateTime"]["output"]>;
  readonly created: Scalars["DateTime"]["output"];
  readonly description?: Maybe<Scalars["String"]["output"]>;
  readonly githubCursor?: Maybe<Scalars["String"]["output"]>;
  readonly id: Scalars["ID"]["output"];
  readonly isMerged?: Maybe<Scalars["Boolean"]["output"]>;
  readonly providerName?: Maybe<Scalars["String"]["output"]>;
  readonly repository?: Maybe<Scalars["String"]["output"]>;
  readonly repositoryOwner?: Maybe<Scalars["String"]["output"]>;
  readonly status?: Maybe<InfoTaskStatus>;
  readonly title: Scalars["String"]["output"];
  readonly url: Scalars["String"]["output"];
};

export type GithubIssuesArtifactNode = ArtifactInterface & {
  readonly __typename: "GithubIssuesArtifactNode";
  readonly activities: ArtifactActivityNodeConnection;
  readonly artifactType: ArtifactType;
  readonly assignableUsers?: Maybe<UserNodeConnection>;
  readonly canDelete: PermissionNode;
  readonly canRead: PermissionNode;
  readonly canUpdate: PermissionNode;
  readonly commentCount?: Maybe<Scalars["Int"]["output"]>;
  readonly comments?: Maybe<CommentNodeConnection>;
  readonly created: Scalars["DateTime"]["output"];
  readonly createdInMeeting?: Maybe<MeetingNode>;
  readonly creator?: Maybe<UserNode>;
  readonly description?: Maybe<Scalars["JSONString"]["output"]>;
  readonly eventChannelName?: Maybe<Scalars["String"]["output"]>;
  readonly id: Scalars["Int"]["output"];
  readonly isStale: Scalars["Boolean"]["output"];
  readonly issues?: Maybe<ReadonlyArray<Maybe<GithubIssueNode>>>;
  readonly organization?: Maybe<OrganizationNode>;
  readonly relatedArtifacts?: Maybe<ArtifactToArtifactConnection>;
  readonly searchQuery?: Maybe<Scalars["String"]["output"]>;
  readonly title?: Maybe<Scalars["String"]["output"]>;
  readonly topics?: Maybe<ArtifactToTopicConnection>;
  readonly updated?: Maybe<Scalars["DateTime"]["output"]>;
  readonly uuid?: Maybe<Scalars["String"]["output"]>;
  readonly websocketToken?: Maybe<Scalars["String"]["output"]>;
};

export type GithubIssuesArtifactNodeActivitiesArgs = {
  activityType?: InputMaybe<ArtifactActivityType>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GithubIssuesArtifactNodeAssignableUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  hasNoManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActiveOrInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  reportIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type GithubIssuesArtifactNodeCommentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  artifactId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  isInlineComment?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  textUuid?: InputMaybe<Scalars["String"]["input"]>;
  topicId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GithubIssuesArtifactNodeIssuesArgs = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GithubIssuesArtifactNodeRelatedArtifactsArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GithubIssuesArtifactNodeTopicsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assignee?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasActionItems?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasArtifacts?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDecisions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDocuments?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasGoals?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInFlowId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInMeetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<TopicState>;
  titleContains?: InputMaybe<Scalars["String"]["input"]>;
  topicIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type GithubKpiIntegrationInput = {
  readonly metricType?: InputMaybe<GithubIntegrationMetricType>;
  /** The user or organization */
  readonly owner?: InputMaybe<Scalars["String"]["input"]>;
  /** The name of the repository */
  readonly repository?: InputMaybe<Scalars["String"]["input"]>;
  readonly timeBucket?: InputMaybe<GithubIntegrationTimeBucket>;
};

export type GithubKpiIntegrationNode = KpiIntegrationInterface & {
  readonly __typename: "GithubKPIIntegrationNode";
  readonly created?: Maybe<Scalars["DateTime"]["output"]>;
  readonly creator?: Maybe<UserNode>;
  readonly id: Scalars["Int"]["output"];
  readonly integrationType?: Maybe<KpiIntegrationType>;
  readonly kpi?: Maybe<KpiNode>;
  readonly metricType?: Maybe<GithubIntegrationMetricType>;
  readonly organizationIntegrationConnection?: Maybe<OrganizationIntegrationNode>;
  /** GitHub user or organization */
  readonly owner: Scalars["String"]["output"];
  /** GitHub repository */
  readonly repository: Scalars["String"]["output"];
  readonly timeBucket?: Maybe<GithubIntegrationTimeBucket>;
  readonly updated?: Maybe<Scalars["DateTime"]["output"]>;
};

export type GoalArtifactConnection = {
  readonly __typename: "GoalArtifactConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<GoalArtifactEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `GoalArtifact` and its cursor. */
export type GoalArtifactEdge = {
  readonly __typename: "GoalArtifactEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<GoalArtifactNode>;
};

export type GoalArtifactNode = ArtifactInterface & {
  readonly __typename: "GoalArtifactNode";
  readonly activities: ArtifactActivityNodeConnection;
  readonly ancestorGoals?: Maybe<GoalArtifactConnection>;
  readonly artifactType: ArtifactType;
  readonly assignableUsers?: Maybe<UserNodeConnection>;
  readonly canDelete: PermissionNode;
  readonly canRead: PermissionNode;
  readonly canUpdate: PermissionNode;
  readonly childGoals?: Maybe<GoalArtifactConnection>;
  readonly commentCount?: Maybe<Scalars["Int"]["output"]>;
  readonly comments?: Maybe<CommentNodeConnection>;
  readonly contributorCount?: Maybe<Scalars["Int"]["output"]>;
  readonly contributors?: Maybe<UserNodeConnection>;
  readonly created: Scalars["DateTime"]["output"];
  readonly createdInMeeting?: Maybe<MeetingNode>;
  readonly creator?: Maybe<UserNode>;
  readonly currentUserIsOwner?: Maybe<Scalars["Boolean"]["output"]>;
  readonly currentValue: Scalars["Float"]["output"];
  readonly description?: Maybe<Scalars["JSONString"]["output"]>;
  readonly dueDate?: Maybe<Scalars["Date"]["output"]>;
  readonly eventChannelName?: Maybe<Scalars["String"]["output"]>;
  readonly firstOwner?: Maybe<UserNode>;
  readonly goalVisibility: GoalVisibility;
  readonly id: Scalars["Int"]["output"];
  readonly isStale: Scalars["Boolean"]["output"];
  readonly keyResults: KeyResultNodeConnection;
  readonly kpi?: Maybe<KpiNode>;
  readonly organization?: Maybe<OrganizationNode>;
  readonly ownerCount?: Maybe<Scalars["Int"]["output"]>;
  readonly owners?: Maybe<UserNodeConnection>;
  readonly parentGoal?: Maybe<GoalArtifactNode>;
  readonly parentGoalId?: Maybe<Scalars["Int"]["output"]>;
  readonly progress: Scalars["Float"]["output"];
  readonly progressType: GoalProgressType;
  readonly relatedArtifacts?: Maybe<ArtifactToArtifactConnection>;
  readonly scope: GoalScope;
  readonly startDate?: Maybe<Scalars["Date"]["output"]>;
  readonly startValue: Scalars["Float"]["output"];
  readonly state: GoalState;
  readonly status: GoalStatus;
  readonly targetValue: Scalars["Float"]["output"];
  readonly teams?: Maybe<TeamNodeConnection>;
  readonly title?: Maybe<Scalars["String"]["output"]>;
  readonly topLevelGoal?: Maybe<GoalArtifactNode>;
  readonly topics?: Maybe<ArtifactToTopicConnection>;
  readonly updated?: Maybe<Scalars["DateTime"]["output"]>;
  readonly uuid?: Maybe<Scalars["String"]["output"]>;
  readonly websocketToken?: Maybe<Scalars["String"]["output"]>;
};

export type GoalArtifactNodeActivitiesArgs = {
  activityType?: InputMaybe<ArtifactActivityType>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GoalArtifactNodeAncestorGoalsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GoalArtifactNodeAssignableUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  hasNoManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActiveOrInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  reportIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type GoalArtifactNodeChildGoalsArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GoalArtifactNodeCommentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  artifactId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  isInlineComment?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  textUuid?: InputMaybe<Scalars["String"]["input"]>;
  topicId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GoalArtifactNodeContributorsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  hasNoManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActiveOrInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  reportIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type GoalArtifactNodeKeyResultsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GoalArtifactNodeOwnersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  hasNoManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActiveOrInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  reportIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type GoalArtifactNodeRelatedArtifactsArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GoalArtifactNodeTeamsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_Icontains?: InputMaybe<Scalars["String"]["input"]>;
  title_Startswith?: InputMaybe<Scalars["String"]["input"]>;
};

export type GoalArtifactNodeTopicsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assignee?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasActionItems?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasArtifacts?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDecisions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDocuments?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasGoals?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInFlowId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInMeetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<TopicState>;
  titleContains?: InputMaybe<Scalars["String"]["input"]>;
  topicIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type GoalCheckinKeyResultInput = {
  readonly currentValue: Scalars["Float"]["input"];
  readonly keyResultId: Scalars["Int"]["input"];
};

/** An enumeration. */
export enum GoalProgressType {
  AlignedAverage = "aligned_average",
  Boolean = "boolean",
  Currency = "currency",
  Numeric = "numeric",
  Percentage = "percentage",
}

/** An enumeration. */
export enum GoalScope {
  Career = "career",
  Organization = "organization",
  Personal = "personal",
  Team = "team",
}

/** An enumeration. */
export enum GoalState {
  Closed = "closed",
  Draft = "draft",
  Open = "open",
}

/** An enumeration. */
export enum GoalStatus {
  AtRisk = "at_risk",
  None = "none",
  OffTrack = "off_track",
  OnTrack = "on_track",
}

/** An enumeration. */
export enum GoalVisibility {
  Private = "private",
  Public = "public",
}

export type GoogleSheetsKpiIntegrationInput = {
  readonly bottomRowFromColumn?: InputMaybe<Scalars["String"]["input"]>;
  readonly cellId?: InputMaybe<Scalars["String"]["input"]>;
  readonly rightmostColumnFromRow?: InputMaybe<Scalars["String"]["input"]>;
  readonly spreadsheetId?: InputMaybe<Scalars["String"]["input"]>;
};

export type GoogleSheetsKpiIntegrationNode = KpiIntegrationInterface & {
  readonly __typename: "GoogleSheetsKPIIntegrationNode";
  readonly bottomRowFromColumn?: Maybe<Scalars["String"]["output"]>;
  readonly cellId?: Maybe<Scalars["String"]["output"]>;
  readonly created?: Maybe<Scalars["DateTime"]["output"]>;
  readonly creator?: Maybe<UserNode>;
  readonly credentials?: Maybe<UserSyncCredentialsNode>;
  readonly id: Scalars["Int"]["output"];
  readonly integrationType?: Maybe<KpiIntegrationType>;
  readonly kpi?: Maybe<KpiNode>;
  readonly organizationIntegrationConnection?: Maybe<OrganizationIntegrationNode>;
  readonly rightmostColumnFromRow?: Maybe<Scalars["String"]["output"]>;
  readonly spreadsheetId: Scalars["String"]["output"];
  readonly updated?: Maybe<Scalars["DateTime"]["output"]>;
};

export type HubspotDealNode = InfoCardInterface & {
  readonly __typename: "HubspotDealNode";
  readonly amount?: Maybe<Scalars["Float"]["output"]>;
  readonly assignees: ReadonlyArray<Maybe<ExternalProfileNode>>;
  readonly closedAt?: Maybe<Scalars["DateTime"]["output"]>;
  readonly closedWon?: Maybe<Scalars["Boolean"]["output"]>;
  readonly created: Scalars["DateTime"]["output"];
  readonly description?: Maybe<Scalars["String"]["output"]>;
  readonly id: Scalars["ID"]["output"];
  readonly providerName?: Maybe<Scalars["String"]["output"]>;
  readonly stageId?: Maybe<Scalars["String"]["output"]>;
  readonly stageName?: Maybe<Scalars["String"]["output"]>;
  readonly status?: Maybe<InfoTaskStatus>;
  readonly title: Scalars["String"]["output"];
  readonly url: Scalars["String"]["output"];
};

/** An enumeration. */
export enum HubspotIntegrationMetricType {
  DealAmountSum = "deal_amount_sum",
  DealCount = "deal_count",
}

/** An enumeration. */
export enum HubspotIntegrationTimeBucket {
  AllTime = "all_time",
  Last_7Days = "last_7_days",
  Last_31Days = "last_31_days",
  ThisQuarter = "this_quarter",
  ThisYear = "this_year",
}

export type HubspotKpiIntegrationInput = {
  readonly dealStageId?: InputMaybe<Scalars["String"]["input"]>;
  readonly metricType?: InputMaybe<HubspotIntegrationMetricType>;
  readonly timeBucket?: InputMaybe<HubspotIntegrationTimeBucket>;
};

export type HubspotKpiIntegrationNode = KpiIntegrationInterface & {
  readonly __typename: "HubspotKPIIntegrationNode";
  readonly created?: Maybe<Scalars["DateTime"]["output"]>;
  readonly creator?: Maybe<UserNode>;
  readonly dealStageId?: Maybe<Scalars["String"]["output"]>;
  readonly id: Scalars["Int"]["output"];
  readonly integrationType?: Maybe<KpiIntegrationType>;
  readonly kpi?: Maybe<KpiNode>;
  readonly metricType?: Maybe<HubspotIntegrationMetricType>;
  readonly organizationIntegrationConnection?: Maybe<OrganizationIntegrationNode>;
  readonly timeBucket?: Maybe<HubspotIntegrationTimeBucket>;
  readonly updated?: Maybe<Scalars["DateTime"]["output"]>;
};

export type HubspotStageConnection = {
  readonly __typename: "HubspotStageConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<HubspotStageEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `HubspotStage` and its cursor. */
export type HubspotStageEdge = {
  readonly __typename: "HubspotStageEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<HubspotStageNode>;
};

export type HubspotStageNode = {
  readonly __typename: "HubspotStageNode";
  readonly id: Scalars["ID"]["output"];
  readonly pipelineId?: Maybe<Scalars["Int"]["output"]>;
  readonly pipelineName?: Maybe<Scalars["String"]["output"]>;
  readonly stageName?: Maybe<Scalars["String"]["output"]>;
};

export type IgnoreMeetingMutation = {
  readonly __typename: "IgnoreMeetingMutation";
  readonly meeting?: Maybe<MeetingNode>;
  readonly meetingGroup?: Maybe<MeetingGroupNode>;
};

export type IndividualNoteNode = DjangoNode &
  Node & {
    readonly __typename: "IndividualNoteNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly created: Scalars["DateTime"]["output"];
    readonly creator: UserNode;
    readonly enabled: Scalars["Boolean"]["output"];
    readonly hidden: Scalars["Boolean"]["output"];
    readonly id: Scalars["ID"]["output"];
    readonly notes?: Maybe<Scalars["JSONString"]["output"]>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
  };

export type IndividualNoteNodeConnection = {
  readonly __typename: "IndividualNoteNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<IndividualNoteNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `IndividualNoteNode` and its cursor. */
export type IndividualNoteNodeEdge = {
  readonly __typename: "IndividualNoteNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<IndividualNoteNode>;
};

export type InfoCardConnection = {
  readonly __typename: "InfoCardConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<InfoCardEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `InfoCard` and its cursor. */
export type InfoCardEdge = {
  readonly __typename: "InfoCardEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<InfoCardInterface>;
};

export type InfoCardInterface = {
  readonly assignees: ReadonlyArray<Maybe<ExternalProfileNode>>;
  readonly closedAt?: Maybe<Scalars["DateTime"]["output"]>;
  readonly created: Scalars["DateTime"]["output"];
  readonly description?: Maybe<Scalars["String"]["output"]>;
  readonly id: Scalars["ID"]["output"];
  readonly providerName?: Maybe<Scalars["String"]["output"]>;
  readonly status?: Maybe<InfoTaskStatus>;
  readonly title: Scalars["String"]["output"];
  readonly url: Scalars["String"]["output"];
};

/** An enumeration. */
export enum InfoTaskStatus {
  Closed = "closed",
  Open = "open",
}

export type InsightConnection = {
  readonly __typename: "InsightConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<InsightEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `Insight` and its cursor. */
export type InsightEdge = {
  readonly __typename: "InsightEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<InsightNode>;
};

export type InsightNode = {
  readonly __typename: "InsightNode";
  readonly artifact?: Maybe<ArtifactInterface>;
  readonly assessmentGroup?: Maybe<AssessmentGroupNode>;
  readonly assessmentTemplate?: Maybe<AssessmentTemplateNode>;
  readonly complianceProgram?: Maybe<ComplianceProgramNode>;
  readonly id: Scalars["ID"]["output"];
  readonly manager?: Maybe<UserNode>;
  readonly matchingOneononesForComplianceProgram?: Maybe<MeetingNodeConnection>;
  readonly report?: Maybe<UserNode>;
  readonly targetUsers: UserNodeConnection;
  readonly templateRequirement?: Maybe<TemplateRequirementNode>;
  readonly type: InsightType;
  readonly unscheduledMatchingOneononesForComplianceProgram?: Maybe<MeetingNodeConnection>;
};

export type InsightNodeMatchingOneononesForComplianceProgramArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  endDatetime?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  excludeMeetingGroupIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  fromMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  ignored?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFormalOneonone?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Decimal"]["input"]>;
  participantCount?: InputMaybe<Scalars["Int"]["input"]>;
  participants?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  startDatetime?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<EventStatus>;
  status_In?: InputMaybe<ReadonlyArray<InputMaybe<EventStatus>>>;
  templateId?: InputMaybe<Scalars["Int"]["input"]>;
  unscheduled?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type InsightNodeTargetUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  hasNoManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActiveOrInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  reportIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type InsightNodeUnscheduledMatchingOneononesForComplianceProgramArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  endDatetime?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  excludeMeetingGroupIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  fromMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  ignored?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFormalOneonone?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Decimal"]["input"]>;
  participantCount?: InputMaybe<Scalars["Int"]["input"]>;
  participants?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  startDatetime?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<EventStatus>;
  status_In?: InputMaybe<ReadonlyArray<InputMaybe<EventStatus>>>;
  templateId?: InputMaybe<Scalars["Int"]["input"]>;
  unscheduled?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** An enumeration. */
export enum InsightType {
  AddNotesToComplianceProgramMeeting = "ADD_NOTES_TO_COMPLIANCE_PROGRAM_MEETING",
  CompleteAssessment = "COMPLETE_ASSESSMENT",
  CreateComplianceProgramMeeting = "CREATE_COMPLIANCE_PROGRAM_MEETING",
  FeedbackRequest = "FEEDBACK_REQUEST",
  FinalizeComplianceProgramMeeting = "FINALIZE_COMPLIANCE_PROGRAM_MEETING",
  MissingManager = "MISSING_MANAGER",
  NominatePeers = "NOMINATE_PEERS",
  Nudge_1On1 = "NUDGE_1ON1",
  OfftrackGoal = "OFFTRACK_GOAL",
  RequiredTemplate = "REQUIRED_TEMPLATE",
  Schedule_1On1 = "SCHEDULE_1ON1",
  StaleGoal = "STALE_GOAL",
}

/** An enumeration. */
export enum IntegrationTypes {
  Clickup = "clickup",
  Hubspot = "hubspot",
  Jira = "jira",
  Salesforce = "salesforce",
}

export type InvitationInput = {
  readonly favourite?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly inviteMessage: Scalars["String"]["input"];
  readonly inviteeEmail: Scalars["String"]["input"];
  readonly organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  readonly relationships?: InputMaybe<
    ReadonlyArray<InputMaybe<RelationshipInput>>
  >;
  readonly role: OrganizationRole;
};

export type InvitationNode = DjangoNode &
  Node & {
    readonly __typename: "InvitationNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly created: Scalars["DateTime"]["output"];
    readonly id: Scalars["ID"]["output"];
    readonly inviteKey: Scalars["String"]["output"];
    readonly inviteMessage: Scalars["String"]["output"];
    readonly inviteeEmail: Scalars["String"]["output"];
    readonly inviterName?: Maybe<Scalars["String"]["output"]>;
    readonly organizationId?: Maybe<Scalars["Int"]["output"]>;
    readonly organizationMemberCount?: Maybe<Scalars["Int"]["output"]>;
    readonly organizationName?: Maybe<Scalars["String"]["output"]>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly role?: Maybe<OrganizationRole>;
    readonly status?: Maybe<InvitationStatus>;
  };

export type InvitationNodeConnection = {
  readonly __typename: "InvitationNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<InvitationNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `InvitationNode` and its cursor. */
export type InvitationNodeEdge = {
  readonly __typename: "InvitationNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<InvitationNode>;
};

/** An enumeration. */
export enum InvitationStatus {
  Accepted = "accepted",
  Pending = "pending",
}

export type InviteError = {
  readonly __typename: "InviteError";
  readonly inviteeEmail?: Maybe<Scalars["String"]["output"]>;
  readonly message?: Maybe<Scalars["String"]["output"]>;
};

export type JiraIssueNode = InfoCardInterface & {
  readonly __typename: "JiraIssueNode";
  readonly assignees: ReadonlyArray<Maybe<ExternalProfileNode>>;
  readonly closedAt?: Maybe<Scalars["DateTime"]["output"]>;
  readonly created: Scalars["DateTime"]["output"];
  readonly description?: Maybe<Scalars["String"]["output"]>;
  readonly id: Scalars["ID"]["output"];
  readonly providerName?: Maybe<Scalars["String"]["output"]>;
  readonly status?: Maybe<InfoTaskStatus>;
  readonly title: Scalars["String"]["output"];
  readonly url: Scalars["String"]["output"];
};

export type JoinOrganizationMutation = {
  readonly __typename: "JoinOrganizationMutation";
  readonly organization?: Maybe<OrganizationNode>;
  readonly user?: Maybe<UserNode>;
};

export type KpiArtifactNode = ArtifactInterface & {
  readonly __typename: "KPIArtifactNode";
  readonly activities: ArtifactActivityNodeConnection;
  readonly artifactType: ArtifactType;
  readonly assignableUsers?: Maybe<UserNodeConnection>;
  readonly canDelete: PermissionNode;
  readonly canRead: PermissionNode;
  readonly canUpdate: PermissionNode;
  readonly commentCount?: Maybe<Scalars["Int"]["output"]>;
  readonly comments?: Maybe<CommentNodeConnection>;
  readonly created: Scalars["DateTime"]["output"];
  readonly createdInMeeting?: Maybe<MeetingNode>;
  readonly creator?: Maybe<UserNode>;
  readonly description?: Maybe<Scalars["JSONString"]["output"]>;
  readonly eventChannelName?: Maybe<Scalars["String"]["output"]>;
  readonly id: Scalars["Int"]["output"];
  readonly isStale: Scalars["Boolean"]["output"];
  readonly kpi?: Maybe<KpiNode>;
  readonly measurement?: Maybe<KpiMeasurementNode>;
  readonly organization?: Maybe<OrganizationNode>;
  readonly relatedArtifacts?: Maybe<ArtifactToArtifactConnection>;
  readonly title?: Maybe<Scalars["String"]["output"]>;
  readonly topics?: Maybe<ArtifactToTopicConnection>;
  readonly updated?: Maybe<Scalars["DateTime"]["output"]>;
  readonly uuid?: Maybe<Scalars["String"]["output"]>;
  readonly websocketToken?: Maybe<Scalars["String"]["output"]>;
};

export type KpiArtifactNodeActivitiesArgs = {
  activityType?: InputMaybe<ArtifactActivityType>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type KpiArtifactNodeAssignableUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  hasNoManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActiveOrInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  reportIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type KpiArtifactNodeCommentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  artifactId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  isInlineComment?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  textUuid?: InputMaybe<Scalars["String"]["input"]>;
  topicId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type KpiArtifactNodeRelatedArtifactsArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type KpiArtifactNodeTopicsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assignee?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasActionItems?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasArtifacts?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDecisions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDocuments?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasGoals?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInFlowId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInMeetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<TopicState>;
  titleContains?: InputMaybe<Scalars["String"]["input"]>;
  topicIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type KpiGroupNode = DjangoNode &
  Node & {
    readonly __typename: "KPIGroupNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly creator?: Maybe<UserNode>;
    readonly id: Scalars["ID"]["output"];
    readonly kpis?: Maybe<KpiNodeConnection>;
    readonly organization?: Maybe<OrganizationNode>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly title: Scalars["String"]["output"];
  };

export type KpiGroupNodeKpisArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  group?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type KpiGroupNodeConnection = {
  readonly __typename: "KPIGroupNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<KpiGroupNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `KPIGroupNode` and its cursor. */
export type KpiGroupNodeEdge = {
  readonly __typename: "KPIGroupNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<KpiGroupNode>;
};

export type KpiIntegrationConnection = {
  readonly __typename: "KPIIntegrationConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<KpiIntegrationEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `KPIIntegration` and its cursor. */
export type KpiIntegrationEdge = {
  readonly __typename: "KPIIntegrationEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<KpiIntegrationInterface>;
};

export type KpiIntegrationInput = {
  readonly github?: InputMaybe<GithubKpiIntegrationInput>;
  readonly googleSheets?: InputMaybe<GoogleSheetsKpiIntegrationInput>;
  readonly hubspot?: InputMaybe<HubspotKpiIntegrationInput>;
  /** If an integration already exists and is being updated, provide the id */
  readonly integrationId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Required if a new integration is being created */
  readonly integrationType?: InputMaybe<KpiIntegrationType>;
  /** Optionally specify which integration connection to use */
  readonly organizationIntegrationConnectionId?: InputMaybe<
    Scalars["Int"]["input"]
  >;
  readonly salesforce?: InputMaybe<SalesforceKpiIntegrationInput>;
  readonly topicflow?: InputMaybe<TopicflowKpiIntegrationInput>;
};

export type KpiIntegrationInterface = {
  readonly created?: Maybe<Scalars["DateTime"]["output"]>;
  readonly creator?: Maybe<UserNode>;
  readonly id: Scalars["Int"]["output"];
  readonly integrationType?: Maybe<KpiIntegrationType>;
  readonly kpi?: Maybe<KpiNode>;
  readonly organizationIntegrationConnection?: Maybe<OrganizationIntegrationNode>;
  readonly updated?: Maybe<Scalars["DateTime"]["output"]>;
};

/** An enumeration. */
export enum KpiIntegrationType {
  Github = "github",
  Googlesheets = "googlesheets",
  Hubspot = "hubspot",
  Salesforce = "salesforce",
  Topicflow = "topicflow",
}

export type KpiMeasurementNode = DjangoNode &
  Node & {
    readonly __typename: "KPIMeasurementNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly created: Scalars["DateTime"]["output"];
    readonly creator?: Maybe<UserNode>;
    readonly id: Scalars["ID"]["output"];
    readonly kpi?: Maybe<KpiNode>;
    readonly measurement: Scalars["Float"]["output"];
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    /** The timestamp for the measurement itself, not just when it was created in Topicflow */
    readonly timestamp: Scalars["DateTime"]["output"];
  };

export type KpiMeasurementNodeConnection = {
  readonly __typename: "KPIMeasurementNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<KpiMeasurementNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `KPIMeasurementNode` and its cursor. */
export type KpiMeasurementNodeEdge = {
  readonly __typename: "KPIMeasurementNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<KpiMeasurementNode>;
};

export type KpiNode = DjangoNode &
  Node & {
    readonly __typename: "KPINode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate: PermissionNode;
    readonly created: Scalars["DateTime"]["output"];
    readonly creator?: Maybe<UserNode>;
    readonly currentMeasurement?: Maybe<KpiMeasurementNode>;
    readonly defaultSummaryMethod: KpiSummaryMethod;
    readonly defaultSummaryPeriod: KpiSummaryPeriod;
    readonly description?: Maybe<Scalars["JSONString"]["output"]>;
    readonly groups?: Maybe<KpiGroupNodeConnection>;
    readonly id: Scalars["ID"]["output"];
    readonly integration?: Maybe<KpiIntegrationInterface>;
    readonly integrations?: Maybe<KpiIntegrationConnection>;
    readonly linkedGoals?: Maybe<GoalArtifactConnection>;
    readonly linkedKeyResults?: Maybe<KeyResultNodeConnection>;
    readonly measurementForDate?: Maybe<KpiMeasurementNode>;
    readonly measurements?: Maybe<KpiMeasurementNodeConnection>;
    readonly organization?: Maybe<OrganizationNode>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly summarizedMeasurements: SummarizedMeasurementsConnection;
    readonly targetCondition: KpiTargetCondition;
    readonly targetType: KpiTargetType;
    readonly targetValue?: Maybe<Scalars["Float"]["output"]>;
    readonly teams?: Maybe<TeamNodeConnection>;
    readonly title: Scalars["String"]["output"];
    readonly updateKey: Scalars["String"]["output"];
    readonly updateUrl: Scalars["String"]["output"];
    readonly visibility: KpiVisibility;
  };

export type KpiNodeGroupsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type KpiNodeIntegrationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type KpiNodeLinkedGoalsArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type KpiNodeLinkedKeyResultsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type KpiNodeMeasurementForDateArgs = {
  forDate: Scalars["Date"]["input"];
};

export type KpiNodeMeasurementsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
};

export type KpiNodeSummarizedMeasurementsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  summaryMethod?: InputMaybe<KpiSummaryMethod>;
  summaryPeriod?: InputMaybe<KpiSummaryPeriod>;
};

export type KpiNodeTeamsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_Icontains?: InputMaybe<Scalars["String"]["input"]>;
  title_Startswith?: InputMaybe<Scalars["String"]["input"]>;
};

export type KpiNodeConnection = {
  readonly __typename: "KPINodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<KpiNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `KPINode` and its cursor. */
export type KpiNodeEdge = {
  readonly __typename: "KPINodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<KpiNode>;
};

/** An enumeration. */
export enum KpiSummaryMethod {
  Average = "average",
  LastValue = "last_value",
  Sum = "sum",
}

/** An enumeration. */
export enum KpiSummaryPeriod {
  Daily = "daily",
  Monthly = "monthly",
  Weekly = "weekly",
}

/** An enumeration. */
export enum KpiTargetCondition {
  GreaterThan = "greater_than",
  GreaterThanOrEqualTo = "greater_than_or_equal_to",
  LessThan = "less_than",
  LessThanOrEqualTo = "less_than_or_equal_to",
}

/** An enumeration. */
export enum KpiTargetType {
  Growth = "growth",
  Value = "value",
}

/** An enumeration. */
export enum KpiVisibility {
  Org = "org",
  Personal = "personal",
  Teams = "teams",
}

export type KeyResultInput = {
  readonly assigneeId?: InputMaybe<Scalars["Int"]["input"]>;
  readonly currentValue?: InputMaybe<Scalars["Float"]["input"]>;
  readonly description?: InputMaybe<Scalars["String"]["input"]>;
  readonly kpiId?: InputMaybe<Scalars["Int"]["input"]>;
  readonly progressType?: InputMaybe<GoalProgressType>;
  readonly startValue?: InputMaybe<Scalars["Float"]["input"]>;
  readonly targetValue?: InputMaybe<Scalars["Float"]["input"]>;
  readonly title?: InputMaybe<Scalars["String"]["input"]>;
};

export type KeyResultNode = DjangoNode &
  Node & {
    readonly __typename: "KeyResultNode";
    readonly assignee?: Maybe<UserNode>;
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly currentValue: Scalars["Float"]["output"];
    readonly description: Scalars["String"]["output"];
    readonly goal?: Maybe<GoalArtifactNode>;
    readonly id: Scalars["ID"]["output"];
    readonly kpi?: Maybe<KpiNode>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly progressType: GoalProgressType;
    readonly startValue: Scalars["Float"]["output"];
    readonly targetValue: Scalars["Float"]["output"];
    readonly title: Scalars["String"]["output"];
  };

export type KeyResultNodeConnection = {
  readonly __typename: "KeyResultNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<KeyResultNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `KeyResultNode` and its cursor. */
export type KeyResultNodeEdge = {
  readonly __typename: "KeyResultNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<KeyResultNode>;
};

export type LinkCalendarToOrganizationMutation = {
  readonly __typename: "LinkCalendarToOrganizationMutation";
  readonly organization?: Maybe<OrganizationNode>;
  readonly syncCredentials?: Maybe<UserSyncCredentialsNode>;
  readonly user?: Maybe<UserNode>;
};

export type LinkCreatedArtifactToChatMessageMutation = {
  readonly __typename: "LinkCreatedArtifactToChatMessageMutation";
  readonly chatSession?: Maybe<ChatSessionNode>;
};

export type ManagerEffectivenessReportConnection = {
  readonly __typename: "ManagerEffectivenessReportConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ManagerEffectivenessReportEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `ManagerEffectivenessReport` and its cursor. */
export type ManagerEffectivenessReportEdge = {
  readonly __typename: "ManagerEffectivenessReportEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<ManagerEffectivenessReportRowNode>;
};

export type ManagerEffectivenessReportQuestionConnection = {
  readonly __typename: "ManagerEffectivenessReportQuestionConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ManagerEffectivenessReportQuestionEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `ManagerEffectivenessReportQuestion` and its cursor. */
export type ManagerEffectivenessReportQuestionEdge = {
  readonly __typename: "ManagerEffectivenessReportQuestionEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<ManagerEffectivenessReportQuestionNode>;
};

export type ManagerEffectivenessReportQuestionNode = {
  readonly __typename: "ManagerEffectivenessReportQuestionNode";
  readonly avgAnswer: Scalars["Float"]["output"];
  readonly question: AssessmentQuestionInterface;
};

export type ManagerEffectivenessReportRowNode = {
  readonly __typename: "ManagerEffectivenessReportRowNode";
  readonly assessments: AssessmentNodeConnection;
  readonly manager: UserNode;
  readonly questions: ManagerEffectivenessReportQuestionConnection;
};

export type ManagerEffectivenessReportRowNodeAssessmentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assessmentType?: InputMaybe<AssessmentType>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  responderId?: InputMaybe<Scalars["Int"]["input"]>;
  selfAssessment?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<AssessmentState>;
  targetId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ManagerEffectivenessReportRowNodeQuestionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

/** An enumeration. */
export enum MeetingAgendaEmailPreference {
  Always = "always",
  Never = "never",
  WhenAgendaExists = "when_agenda_exists",
  WhenOrganizer = "when_organizer",
}

export enum MeetingEditTypeEnum {
  AllEvents = "all_events",
  SingleEvent = "single_event",
  ThisEventAndFuture = "this_event_and_future",
}

export type MeetingGroupNode = DjangoNode &
  Node & {
    readonly __typename: "MeetingGroupNode";
    readonly addTranscriptionBotToMeetingsByDefault: Scalars["Boolean"]["output"];
    readonly allowAdminVisibility: Scalars["Boolean"]["output"];
    readonly allowExternalViewers: Scalars["Boolean"]["output"];
    readonly allowManagementTreeVisibility: Scalars["Boolean"]["output"];
    readonly allowOrgVisibility: Scalars["Boolean"]["output"];
    readonly artifacts?: Maybe<ArtifactConnection>;
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly created: Scalars["DateTime"]["output"];
    readonly creator?: Maybe<UserNode>;
    readonly description?: Maybe<Scalars["String"]["output"]>;
    readonly facilitator?: Maybe<UserNode>;
    readonly goals?: Maybe<ArtifactConnection>;
    readonly hasTemplate?: Maybe<Scalars["Boolean"]["output"]>;
    readonly id: Scalars["ID"]["output"];
    readonly isFormalOneonone: Scalars["Boolean"]["output"];
    readonly meetings?: Maybe<MeetingNodeConnection>;
    readonly onlineConference: OnlineConference;
    readonly organization?: Maybe<OrganizationNode>;
    readonly participants: MeetingParticipantConnection;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly recurrenceParameters?: Maybe<FrequencyNode>;
    readonly recurrenceRule?: Maybe<Scalars["String"]["output"]>;
    readonly slackNotifications?: Maybe<SlackNotificationConnectionNodeConnection>;
    readonly syncCredentials?: Maybe<UserSyncCredentialsNode>;
    readonly templateSeries?: Maybe<TemplateSeriesNodeConnection>;
    readonly title: Scalars["String"]["output"];
    readonly topicTemplate?: Maybe<TopicTemplateNode>;
    readonly transcribeMeetingsByDefault?: Maybe<Scalars["Boolean"]["output"]>;
  };

export type MeetingGroupNodeArtifactsArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MeetingGroupNodeGoalsArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MeetingGroupNodeMeetingsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  endDatetime?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  excludeMeetingGroupIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  fromMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  ignored?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFormalOneonone?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Decimal"]["input"]>;
  participantCount?: InputMaybe<Scalars["Int"]["input"]>;
  participants?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  startDatetime?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<EventStatus>;
  status_In?: InputMaybe<ReadonlyArray<InputMaybe<EventStatus>>>;
  templateId?: InputMaybe<Scalars["Int"]["input"]>;
  unscheduled?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MeetingGroupNodeParticipantsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type MeetingGroupNodeSlackNotificationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MeetingGroupNodeTemplateSeriesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MeetingGroupNodeConnection = {
  readonly __typename: "MeetingGroupNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<MeetingGroupNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `MeetingGroupNode` and its cursor. */
export type MeetingGroupNodeEdge = {
  readonly __typename: "MeetingGroupNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<MeetingGroupNode>;
};

/**
 * A data type which contains some info that all admins can see, as well as a reference to the actual meeting (for
 * which permissions are checked)
 */
export type MeetingInfoNode = {
  readonly __typename: "MeetingInfoNode";
  readonly id?: Maybe<Scalars["Int"]["output"]>;
  readonly meeting?: Maybe<MeetingNode>;
  readonly startDatetime?: Maybe<Scalars["DateTime"]["output"]>;
};

export type MeetingInterface = {
  readonly startDate?: Maybe<Scalars["Date"]["output"]>;
};

export type MeetingNode = DjangoNode &
  MeetingInterface &
  Node & {
    readonly __typename: "MeetingNode";
    readonly allowExternalViewers?: Maybe<Scalars["Boolean"]["output"]>;
    readonly artifacts?: Maybe<ArtifactConnection>;
    readonly autocompleteTopics?: Maybe<TitleAutocompleteConnection>;
    readonly botIsRecording?: Maybe<Scalars["Boolean"]["output"]>;
    readonly botIsWaitingToJoinMeeting?: Maybe<Scalars["Boolean"]["output"]>;
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canFinalize: PermissionNode;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly colours?: Maybe<EventColours>;
    readonly created: Scalars["DateTime"]["output"];
    readonly creator?: Maybe<UserNode>;
    readonly description?: Maybe<Scalars["String"]["output"]>;
    readonly draft: Scalars["Boolean"]["output"];
    readonly effectiveAllowExternalViewers?: Maybe<
      Scalars["Boolean"]["output"]
    >;
    readonly endDatetime?: Maybe<Scalars["DateTime"]["output"]>;
    readonly externalUrl?: Maybe<Scalars["String"]["output"]>;
    readonly externalViewers?: Maybe<UserNodeConnection>;
    readonly finalizedAt?: Maybe<Scalars["DateTime"]["output"]>;
    readonly finalizedBy?: Maybe<UserNode>;
    readonly flows?: Maybe<FlowNodeConnection>;
    readonly generatedSuggestedTopics?: Maybe<SuggestedTopicNodeConnection>;
    readonly hasBotInMeeting?: Maybe<Scalars["Boolean"]["output"]>;
    readonly hasExternalParticipants?: Maybe<Scalars["Boolean"]["output"]>;
    readonly hasIncompleteMandatoryTopics: Scalars["Boolean"]["output"];
    readonly hasPreviousMeetings?: Maybe<Scalars["Boolean"]["output"]>;
    readonly hasPreviousMeetingsWithTopics?: Maybe<
      Scalars["Boolean"]["output"]
    >;
    readonly hasTranscript?: Maybe<Scalars["Boolean"]["output"]>;
    readonly id: Scalars["ID"]["output"];
    readonly ignored: Scalars["Boolean"]["output"];
    readonly insights: InsightConnection;
    readonly isFinalized: Scalars["Boolean"]["output"];
    readonly isFirstInstanceInSeries?: Maybe<Scalars["Boolean"]["output"]>;
    readonly isLocked: Scalars["Boolean"]["output"];
    readonly isRecurring: Scalars["Boolean"]["output"];
    readonly isSubmitted: Scalars["Boolean"]["output"];
    readonly kvItems?: Maybe<UserKvItemNodeConnection>;
    readonly meetingGroup?: Maybe<MeetingGroupNode>;
    readonly meetingGroupId: Scalars["Int"]["output"];
    readonly notesSummary?: Maybe<Scalars["String"]["output"]>;
    readonly organization?: Maybe<OrganizationNode>;
    readonly organizer?: Maybe<UserNode>;
    readonly participants?: Maybe<MeetingParticipantConnection>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly ratingRequirements?: Maybe<MeetingRatingRequirementNodeConnection>;
    readonly recentlyCompletedAssessmentDeliveries: AssessmentDeliveryNodeConnection;
    readonly recentlyCompletedAssessments: AssessmentNodeConnection;
    readonly relatedAssessments: AssessmentNodeConnection;
    readonly startDate?: Maybe<Scalars["Date"]["output"]>;
    readonly startDatetime?: Maybe<Scalars["DateTime"]["output"]>;
    readonly status?: Maybe<EventStatus>;
    readonly subject?: Maybe<UserNode>;
    readonly suggestedTemplates?: Maybe<TopicTemplateNodeConnection>;
    readonly suggestedTopics?: Maybe<TopicNodeConnection>;
    readonly title?: Maybe<Scalars["String"]["output"]>;
    readonly topics?: Maybe<TopicNodeConnection>;
    readonly transcript?: Maybe<MeetingTranscriptNode>;
    readonly transcriptionStatus?: Maybe<Scalars["Boolean"]["output"]>;
    readonly videoConferenceType?: Maybe<OnlineVideoConference>;
    readonly videoConferenceUrl?: Maybe<Scalars["String"]["output"]>;
    readonly websocketToken?: Maybe<Scalars["String"]["output"]>;
    readonly writableSharingUrl?: Maybe<Scalars["String"]["output"]>;
  };

export type MeetingNodeArtifactsArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MeetingNodeAutocompleteTopicsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  searchTerm: Scalars["String"]["input"];
};

export type MeetingNodeExternalViewersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  hasNoManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActiveOrInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  reportIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type MeetingNodeFlowsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MeetingNodeGeneratedSuggestedTopicsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hidden?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MeetingNodeInsightsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MeetingNodeKvItemsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  key_Startswith?: InputMaybe<Scalars["String"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MeetingNodeParticipantsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type MeetingNodeRatingRequirementsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MeetingNodeRecentlyCompletedAssessmentDeliveriesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assessmentType?: InputMaybe<AssessmentType>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  complianceProgramId?: InputMaybe<Scalars["Int"]["input"]>;
  creatorId?: InputMaybe<Scalars["Int"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  state?: InputMaybe<AssessmentDeliveryState>;
  targetId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MeetingNodeRecentlyCompletedAssessmentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assessmentType?: InputMaybe<AssessmentType>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  responderId?: InputMaybe<Scalars["Int"]["input"]>;
  selfAssessment?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<AssessmentState>;
  targetId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MeetingNodeRelatedAssessmentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assessmentType?: InputMaybe<AssessmentType>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  responderId?: InputMaybe<Scalars["Int"]["input"]>;
  selfAssessment?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<AssessmentState>;
  targetId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MeetingNodeSuggestedTemplatesArgs = {
  adhocTemplate?: InputMaybe<Scalars["Boolean"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  globalTemplate?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasRequirements?: InputMaybe<Scalars["Boolean"]["input"]>;
  includesRatingId?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  publicTemplate?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MeetingNodeSuggestedTopicsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assignee?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasActionItems?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasArtifacts?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDecisions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDocuments?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasGoals?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInFlowId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInMeetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<TopicState>;
  titleContains?: InputMaybe<Scalars["String"]["input"]>;
  topicIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type MeetingNodeTopicsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assignee?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasActionItems?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasArtifacts?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDecisions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDocuments?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasGoals?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInFlowId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInMeetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<TopicState>;
  titleContains?: InputMaybe<Scalars["String"]["input"]>;
  topicIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type MeetingNodeConnection = {
  readonly __typename: "MeetingNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<MeetingNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `MeetingNode` and its cursor. */
export type MeetingNodeEdge = {
  readonly __typename: "MeetingNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<MeetingNode>;
};

export type MeetingParticipantConnection = {
  readonly __typename: "MeetingParticipantConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<MeetingParticipantEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `MeetingParticipant` and its cursor. */
export type MeetingParticipantEdge = {
  readonly __typename: "MeetingParticipantEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<MeetingParticipantNode>;
};

export type MeetingParticipantNode = {
  readonly __typename: "MeetingParticipantNode";
  readonly id?: Maybe<Scalars["String"]["output"]>;
  readonly participantEmail?: Maybe<Scalars["String"]["output"]>;
  readonly role?: Maybe<AttendeeRole>;
  readonly status?: Maybe<AttendeeStatus>;
  readonly user?: Maybe<UserNode>;
};

export type MeetingRatingRequirementNode = DjangoNode &
  Node & {
    readonly __typename: "MeetingRatingRequirementNode";
    readonly allowAdminVisibility: Scalars["Boolean"]["output"];
    readonly allowManagerAnswers: Scalars["Boolean"]["output"];
    readonly allowManagerVisibility: Scalars["Boolean"]["output"];
    readonly allowMeetingParticipantAnswers: Scalars["Boolean"]["output"];
    readonly allowMeetingParticipantVisibility: Scalars["Boolean"]["output"];
    readonly allowOrganizationVisibility: Scalars["Boolean"]["output"];
    readonly allowReportAnswers: Scalars["Boolean"]["output"];
    readonly allowReportVisibility: Scalars["Boolean"]["output"];
    readonly canAnswer?: Maybe<Scalars["Boolean"]["output"]>;
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly canViewAnswers?: Maybe<Scalars["Boolean"]["output"]>;
    readonly creator?: Maybe<UserNode>;
    readonly id: Scalars["ID"]["output"];
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly rating?: Maybe<RatingNode>;
    readonly ratingArtifact?: Maybe<RatingArtifactNode>;
  };

export type MeetingRatingRequirementNodeConnection = {
  readonly __typename: "MeetingRatingRequirementNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<MeetingRatingRequirementNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `MeetingRatingRequirementNode` and its cursor. */
export type MeetingRatingRequirementNodeEdge = {
  readonly __typename: "MeetingRatingRequirementNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<MeetingRatingRequirementNode>;
};

/** An enumeration. */
export enum MeetingSummaryEmailPreference {
  Always = "always",
  None = "none",
  WhenDeclined = "when_declined",
}

export type MeetingTranscriptMessageNode = {
  readonly __typename: "MeetingTranscriptMessageNode";
  readonly created?: Maybe<Scalars["Float"]["output"]>;
  readonly messageId?: Maybe<Scalars["String"]["output"]>;
  readonly speaker?: Maybe<Scalars["String"]["output"]>;
  readonly text: Scalars["String"]["output"];
};

export type MeetingTranscriptNode = DjangoNode &
  Node & {
    readonly __typename: "MeetingTranscriptNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly created: Scalars["DateTime"]["output"];
    readonly creator?: Maybe<UserNode>;
    readonly highlightList?: Maybe<TranscriptHighlightConnection>;
    readonly highlights?: Maybe<Scalars["String"]["output"]>;
    readonly id: Scalars["ID"]["output"];
    readonly lastChunkSummarizationIndex: Scalars["Int"]["output"];
    readonly lastChunkSummarizationTime?: Maybe<Scalars["DateTime"]["output"]>;
    readonly longSummary?: Maybe<Scalars["String"]["output"]>;
    readonly mediumSummary?: Maybe<Scalars["String"]["output"]>;
    readonly meeting?: Maybe<MeetingNode>;
    readonly orderedTranscriptData?: Maybe<
      ReadonlyArray<Maybe<MeetingTranscriptMessageNode>>
    >;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly suggestedArtifacts?: Maybe<SuggestedArtifactNodeConnection>;
    readonly summary?: Maybe<Scalars["String"]["output"]>;
    readonly updated: Scalars["DateTime"]["output"];
    readonly userMapping?: Maybe<Scalars["JSONString"]["output"]>;
  };

export type MeetingTranscriptNodeHighlightListArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MeetingTranscriptNodeSuggestedArtifactsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hidden?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MeetingWithRelevantSectionsNode = {
  readonly __typename: "MeetingWithRelevantSectionsNode";
  readonly meeting?: Maybe<MeetingNode>;
  readonly relevantSections?: Maybe<ReadonlyArray<Maybe<RelevantSectionNode>>>;
};

export type MeetingsByManagerReportConnection = {
  readonly __typename: "MeetingsByManagerReportConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<MeetingsByManagerReportEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `MeetingsByManagerReport` and its cursor. */
export type MeetingsByManagerReportEdge = {
  readonly __typename: "MeetingsByManagerReportEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<MeetingsByManagerReportNode>;
};

export type MeetingsByManagerReportNode = {
  readonly __typename: "MeetingsByManagerReportNode";
  readonly id?: Maybe<Scalars["Int"]["output"]>;
  readonly manager?: Maybe<UserNode>;
  readonly organization?: Maybe<OrganizationNode>;
  readonly reports?: Maybe<UserReportConnection>;
};

export type MeetingsByManagerReportNodeReportsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MissingAssessmentConnection = {
  readonly __typename: "MissingAssessmentConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<MissingAssessmentEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `MissingAssessment` and its cursor. */
export type MissingAssessmentEdge = {
  readonly __typename: "MissingAssessmentEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<MissingAssessmentNode>;
};

export type MissingAssessmentNode = {
  readonly __typename: "MissingAssessmentNode";
  readonly assessmentTemplate: AssessmentTemplateNode;
  readonly assessmentTemplateId?: Maybe<Scalars["Int"]["output"]>;
  readonly dueDate: Scalars["Date"]["output"];
  readonly responder: UserNode;
  readonly target: UserNode;
};

export type MultiChoiceAssessmentAnswerNode = AssessmentAnswerInterface & {
  readonly __typename: "MultiChoiceAssessmentAnswerNode";
  readonly assessment: AssessmentNode;
  readonly canDelete: PermissionNode;
  readonly canRead: PermissionNode;
  readonly canUpdate: PermissionNode;
  readonly choices: ReadonlyArray<Maybe<Scalars["Int"]["output"]>>;
  readonly comment?: Maybe<Scalars["JSONString"]["output"]>;
  readonly created: Scalars["DateTime"]["output"];
  readonly id: Scalars["Int"]["output"];
  readonly question: AssessmentQuestionInterface;
  readonly responder?: Maybe<UserNode>;
  readonly updated: Scalars["DateTime"]["output"];
};

export type MultiChoiceAssessmentQuestionNode = AssessmentQuestionInterface & {
  readonly __typename: "MultiChoiceAssessmentQuestionNode";
  readonly answers: AssessmentAnswerConnection;
  readonly answersExist: Scalars["Boolean"]["output"];
  readonly archived: Scalars["Boolean"]["output"];
  readonly assessmentType: AssessmentType;
  readonly canDelete: PermissionNode;
  readonly canRead: PermissionNode;
  readonly canUpdate: PermissionNode;
  readonly categories: AssessmentQuestionCategoryNodeConnection;
  readonly created: Scalars["DateTime"]["output"];
  readonly creator?: Maybe<UserNode>;
  readonly description?: Maybe<Scalars["JSONString"]["output"]>;
  readonly id: Scalars["Int"]["output"];
  readonly isCommentMandatory: Scalars["Boolean"]["output"];
  readonly optionDescriptions: ReadonlyArray<
    Maybe<Scalars["String"]["output"]>
  >;
  readonly options: ReadonlyArray<Maybe<Scalars["String"]["output"]>>;
  readonly organization: OrganizationNode;
  readonly questionType: AssessmentQuestionType;
  readonly responseVisibility: AssessmentQuestionResponseVisibility;
  readonly responses: AssessmentQuestionResponses;
  readonly title: Scalars["String"]["output"];
  readonly updated: Scalars["DateTime"]["output"];
};

export type MultiChoiceAssessmentQuestionNodeAnswersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MultiChoiceAssessmentQuestionNodeCategoriesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Mutation = {
  readonly __typename: "Mutation";
  readonly acceptInvitation?: Maybe<AcceptInvitationMutation>;
  readonly addBotToMeeting?: Maybe<AddBotToMeetingMutation>;
  readonly addFavouriteArtifact?: Maybe<AddFavouriteArtifact>;
  readonly addTeamMember?: Maybe<AddTeamMemberMutation>;
  readonly addTemplateToMeeting?: Maybe<AddTemplateToMeetingMutation>;
  readonly alignGoal?: Maybe<AlignGoalMutation>;
  readonly associateArtifactWithTopicOrArtifact?: Maybe<AssociateArtifactWithTopicOrArtifactMutation>;
  readonly cancelMeeting?: Maybe<CancelMeetingMutation>;
  readonly changeBotRecordingStatus?: Maybe<ChangeBotRecordingStatusMutation>;
  readonly clearDismissedInsights?: Maybe<ClearDismissedInsightsMutation>;
  readonly convertSuggestedTopicToTopic?: Maybe<ConvertSuggestedTopicToTopicMutation>;
  readonly copyTopicsToMeeting?: Maybe<CopyTopicsToMeetingMutation>;
  readonly createFeedbackRequests?: Maybe<CreateFeedbackRequestsMutation>;
  readonly createGoalCheckin?: Maybe<CreateGoalCheckinMutation>;
  readonly createInvitations?: Maybe<CreateInvitationsMutation>;
  readonly createOrUpdateArtifact?: Maybe<CreateOrUpdateArtifactMutation>;
  readonly createOrUpdateAssessment: CreateOrUpdateAssessmentMutation;
  readonly createOrUpdateAssessmentGroup?: Maybe<CreateOrUpdateAssessmentGroupMutation>;
  readonly createOrUpdateAssessmentQuestion?: Maybe<CreateOrUpdateAssessmentQuestionMutation>;
  readonly createOrUpdateCareerTrack?: Maybe<CreateOrUpdateCareerTrackMutation>;
  readonly createOrUpdateComment?: Maybe<CreateOrUpdateCommentMutation>;
  readonly createOrUpdateCompetency?: Maybe<CreateOrUpdateCompetencyMutation>;
  readonly createOrUpdateComplianceProgram?: Maybe<CreateOrUpdateComplianceProgramMutation>;
  readonly createOrUpdateCoreValue?: Maybe<CreateOrUpdateCoreValueMutation>;
  readonly createOrUpdateExplorerBookmark?: Maybe<CreateOrUpdateExplorerBookmarkMutation>;
  readonly createOrUpdateFavouritesGroup?: Maybe<CreateOrUpdateFavouritesGroupMutation>;
  readonly createOrUpdateFlow?: Maybe<CreateOrUpdateFlowMutation>;
  readonly createOrUpdateKeyResult?: Maybe<CreateOrUpdateKeyResultMutation>;
  readonly createOrUpdateKpi?: Maybe<CreateOrUpdateKpi>;
  readonly createOrUpdateKpiGroup?: Maybe<CreateOrUpdateKpiGroupMutation>;
  readonly createOrUpdateKpiMeasurement?: Maybe<CreateOrUpdateKpiMeasurementMutation>;
  readonly createOrUpdateKvItem?: Maybe<CreateOrUpdateKvItemMutation>;
  readonly createOrUpdateMeetingInExternalCalendar?: Maybe<CreateOrUpdateMeetingInExternalCalendarMutation>;
  readonly createOrUpdateOrganization?: Maybe<CreateOrUpdateOrganizationMutation>;
  readonly createOrUpdateRating?: Maybe<CreateOrUpdateRatingMutation>;
  readonly createOrUpdateRatingAnswer?: Maybe<CreateOrUpdateRatingAnswerMutation>;
  readonly createOrUpdateSlackNotificationForChannel?: Maybe<CreateOrUpdateSlackNotificationForChannelMutation>;
  readonly createOrUpdateSlackNotificationsForMeetingGroup?: Maybe<CreateOrUpdateSlackNotificationsForMeetingGroup>;
  readonly createOrUpdateTeam?: Maybe<CreateOrUpdateTeamMutation>;
  readonly createOrUpdateTemplateRequirements?: Maybe<CreateOrUpdateTemplateRequirementsMutation>;
  readonly createOrUpdateTemplateSeriesForMeeting?: Maybe<CreateOrUpdateTemplateSeriesForMeetingMutation>;
  readonly createOrUpdateTopic?: Maybe<CreateOrUpdateTopicMutation>;
  readonly createOrUpdateTopicTemplate?: Maybe<CreateOrUpdateTopicTemplateMutation>;
  readonly createOrUpdateTopicTemplateCategory?: Maybe<CreateOrUpdateTopicTemplateCategoryMutation>;
  readonly createOrUpdateWorkflow?: Maybe<CreateOrUpdateWorkflowMutation>;
  readonly createTemplateFromMeeting?: Maybe<CreateTemplateFromMeetingMutation>;
  readonly deleteArtifact?: Maybe<DeleteArtifactMutation>;
  readonly deleteAssessmentAnswer: DeleteAssessmentAnswerMutation;
  readonly deleteAssessmentGroup?: Maybe<DeleteAssessmentGroupMutation>;
  readonly deleteAssessmentQuestion?: Maybe<DeleteAssessmentQuestionMutation>;
  readonly deleteCareerTrack?: Maybe<DeleteCareerTrackMutation>;
  readonly deleteComment?: Maybe<DeleteCommentMutation>;
  readonly deleteCompetency?: Maybe<DeleteCompetencyMutation>;
  readonly deleteComplianceProgram?: Maybe<DeleteComplianceProgramMutation>;
  readonly deleteCoreValue?: Maybe<DeleteCoreValueMutation>;
  readonly deleteExplorerBookmark?: Maybe<DeleteExplorerBookmark>;
  readonly deleteFavouritesGroup?: Maybe<DeleteFavouritesGroupMutation>;
  readonly deleteFlow?: Maybe<DeleteFlowMutation>;
  readonly deleteKeyResult?: Maybe<DeleteKeyResultMutation>;
  readonly deleteKpi?: Maybe<DeleteKpi>;
  readonly deleteKpiGroup?: Maybe<DeleteKpiGroupMutation>;
  readonly deleteKpiIntegration?: Maybe<DeleteKpiIntegrationMutation>;
  readonly deleteKpiMeasurement?: Maybe<DeleteKpiMeasurement>;
  readonly deleteMeeting?: Maybe<DeleteMeetingMutation>;
  readonly deleteMeetingTranscripts?: Maybe<DeleteMeetingTranscriptsMutation>;
  readonly deleteRating?: Maybe<DeleteRatingMutation>;
  readonly deleteSlackNotificationForChannel?: Maybe<DeleteSlackNotificationForChannelMutation>;
  readonly deleteSlackNotificationsForMeetingGroup?: Maybe<DeleteSlackNotificationsForMeetingGroup>;
  readonly deleteTeam?: Maybe<DeleteTeamMutation>;
  readonly deleteTopic?: Maybe<DeleteTopicMutation>;
  readonly deleteTopicTemplate?: Maybe<DeleteTopicTemplateMutation>;
  readonly deleteTopicTemplateCategory?: Maybe<DeleteTopicTemplateCategoryMutation>;
  readonly deleteWorkflow?: Maybe<DeleteWorkflowMutation>;
  readonly dismissInsight?: Maybe<DismissInsightMutation>;
  readonly duplicateGoal?: Maybe<DuplicateGoalMutation>;
  readonly email1on1Nudge?: Maybe<Email1on1NudgeMutation>;
  readonly emailAssessmentReminder?: Maybe<EmailAssessmentReminderMutation>;
  readonly emailMeetingInvites?: Maybe<EmailMeetingInvitesMutation>;
  readonly emailMeetingNotes?: Maybe<EmailMeetingNotesMutation>;
  readonly emailOneononeReminder?: Maybe<EmailOneOnOneReminderMutation>;
  readonly exportComplianceProgramToCsv?: Maybe<ExportComplianceProgramToCsv>;
  readonly ignoreMeeting?: Maybe<IgnoreMeetingMutation>;
  readonly joinOrganization?: Maybe<JoinOrganizationMutation>;
  readonly linkCalendarToOrganization?: Maybe<LinkCalendarToOrganizationMutation>;
  readonly linkCreatedArtifactToChatMessage?: Maybe<LinkCreatedArtifactToChatMessageMutation>;
  readonly prepareFileUpload?: Maybe<PrepareFileUploadMutation>;
  readonly removeAsanaIntegration?: Maybe<RemoveAsanaIntegrationMutation>;
  readonly removeBotFromMeeting?: Maybe<RemoveBotFromMeetingMutation>;
  readonly removeClickupIntegration?: Maybe<RemoveClickupIntegrationMutation>;
  readonly removeFavouriteArtifact?: Maybe<RemoveFavouriteArtifact>;
  readonly removeGithubIntegration?: Maybe<RemoveGitHubIntegrationMutation>;
  readonly removeHubspotIntegration?: Maybe<RemoveHubspotIntegrationMutation>;
  readonly removeIntegrationCredentials?: Maybe<RemoveIntegrationCredentialsMutation>;
  readonly removeJiraIntegration?: Maybe<RemoveJiraIntegrationMutation>;
  readonly removeOrgGithubIntegration?: Maybe<RemoveOrganizationGitHubIntegrationMutation>;
  readonly removeSalesforceIntegration?: Maybe<RemoveSalesforceIntegrationMutation>;
  readonly removeSlackIntegration?: Maybe<RemoveSlackIntegrationMutation>;
  readonly removeTeamMember?: Maybe<RemoveTeamMemberMutation>;
  readonly removeTemplateSeriesFromMeeting?: Maybe<RemoveTemplateSeriesFromMeetingMutation>;
  readonly removeUserFromOrganization?: Maybe<RemoveUserFromOrganizationMutation>;
  readonly removeZoomIntegration?: Maybe<RemoveZoomIntegrationMutation>;
  readonly reopenAssessment: ReopenAssessmentMutation;
  readonly reorderArtifact?: Maybe<ReorderArtifactMutation>;
  readonly reorderTopic?: Maybe<ReorderTopicMutation>;
  readonly requestExport?: Maybe<RequestExportMutation>;
  readonly searchCsvExport?: Maybe<SearchCsvExportMutation>;
  readonly sendMessageToChatbot?: Maybe<SendMessageToChatbotMutation>;
  readonly setAssessmentNominations?: Maybe<SetAssessmentNominationsMutation>;
  readonly setFavouriteGroups?: Maybe<SetFavouriteGroupsMutation>;
  readonly setMeetingAgendaAsTemplate?: Maybe<SetMeetingAgendaAsTemplateMutation>;
  readonly setMeetingLocked?: Maybe<SetMeetingLockedMutation>;
  readonly setRelationship?: Maybe<SetRelationshipMutation>;
  readonly setRelationships?: Maybe<SetRelationshipsMutation>;
  readonly startCalendarSync?: Maybe<StartCalendarSyncMutation>;
  readonly submitOneonone?: Maybe<SubmitOneOnOneMutation>;
  readonly toggleIndividualNoteVisibility?: Maybe<ToggleIndividualNoteVisibilityMutation>;
  readonly unlinkIntegrationFromOrganization?: Maybe<UnlinkIntegrationFromOrganizationMutation>;
  readonly updateAssessmentDelivery: UpdateAssessmentDeliveryMutation;
  readonly updateAttendanceStatus?: Maybe<UpdateAttendanceStatusMutation>;
  readonly updateMeeting?: Maybe<UpdateMeetingMutation>;
  readonly updateMeetingFinalizationStatus?: Maybe<UpdateMeetingFinalizationStatus>;
  readonly updateMeetingGroup?: Maybe<UpdateMeetingGroupMutation>;
  readonly updateMeetingTranscript?: Maybe<UpdateMeetingTranscriptMutation>;
  readonly updateMeetingTranscriptionStatus?: Maybe<UpdateMeetingTranscriptionStatusMutation>;
  readonly updateOrganizationMembership?: Maybe<UpdateOrganizationMembershipMutation>;
  readonly updateSuggestedArtifact?: Maybe<UpdateSuggestedArtifactMutation>;
  readonly updateSuggestedTopic?: Maybe<UpdateSuggestedTopicMutation>;
  readonly updateTopicDiscussionNotes?: Maybe<UpdateTopicDiscussionNotesMutation>;
  readonly updateUser?: Maybe<UpdateUserMutation>;
  readonly updateWorkHistory?: Maybe<UpdateWorkHistoryMutation>;
};

export type MutationAcceptInvitationArgs = {
  invitationId: Scalars["Int"]["input"];
};

export type MutationAddBotToMeetingArgs = {
  meetingId: Scalars["Int"]["input"];
};

export type MutationAddFavouriteArtifactArgs = {
  artifactId: Scalars["Int"]["input"];
};

export type MutationAddTeamMemberArgs = {
  teamId: Scalars["Int"]["input"];
  userId: Scalars["Int"]["input"];
};

export type MutationAddTemplateToMeetingArgs = {
  allowAdminVisibility?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowManagementTreeVisibility?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowOrgVisibility?: InputMaybe<Scalars["Boolean"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  setAsSeriesTemplate: Scalars["Boolean"]["input"];
  topicTemplateId: Scalars["Int"]["input"];
};

export type MutationAlignGoalArgs = {
  goalId: Scalars["Int"]["input"];
  parentGoalId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationAssociateArtifactWithTopicOrArtifactArgs = {
  artifactId: Scalars["Int"]["input"];
  otherArtifactId?: InputMaybe<Scalars["Int"]["input"]>;
  topicId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationCancelMeetingArgs = {
  editType: MeetingEditTypeEnum;
  meetingId: Scalars["Int"]["input"];
};

export type MutationChangeBotRecordingStatusArgs = {
  isRecording: Scalars["Boolean"]["input"];
  meetingId: Scalars["Int"]["input"];
};

export type MutationClearDismissedInsightsArgs = {
  userId: Scalars["Int"]["input"];
};

export type MutationConvertSuggestedTopicToTopicArgs = {
  suggestedTopicId: Scalars["Int"]["input"];
};

export type MutationCopyTopicsToMeetingArgs = {
  meetingId: Scalars["Int"]["input"];
  topicIds: ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>;
};

export type MutationCreateFeedbackRequestsArgs = {
  description?: InputMaybe<Scalars["JSONString"]["input"]>;
  feedbackAdminsCanView?: InputMaybe<Scalars["Boolean"]["input"]>;
  feedbackCreatorCanView?: InputMaybe<Scalars["Boolean"]["input"]>;
  feedbackRecipients: ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>;
  feedbackRecipientsCanView?: InputMaybe<Scalars["Boolean"]["input"]>;
  feedbackRecipientsManagersCanView?: InputMaybe<Scalars["Boolean"]["input"]>;
  feedbackSenderIds: ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>;
  feedbackState?: InputMaybe<FeedbackState>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId: Scalars["Int"]["input"];
  relatedArtifactId?: InputMaybe<Scalars["Int"]["input"]>;
  title: Scalars["String"]["input"];
  topicId?: InputMaybe<Scalars["Int"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateGoalCheckinArgs = {
  comment?: InputMaybe<Scalars["JSONString"]["input"]>;
  currentValue?: InputMaybe<Scalars["Float"]["input"]>;
  goalId: Scalars["Int"]["input"];
  goalStatus?: InputMaybe<GoalStatus>;
  keyResults?: InputMaybe<ReadonlyArray<InputMaybe<GoalCheckinKeyResultInput>>>;
};

export type MutationCreateInvitationsArgs = {
  invitationRequests: ReadonlyArray<InputMaybe<InvitationInput>>;
};

export type MutationCreateOrUpdateArtifactArgs = {
  additionalFields?: InputMaybe<AdditionalArtifactInput>;
  artifactId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  description?: InputMaybe<Scalars["JSONString"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  relatedArtifactId?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  topicId?: InputMaybe<Scalars["Int"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateOrUpdateAssessmentArgs = {
  answers?: InputMaybe<
    ReadonlyArray<InputMaybe<AssessmentQuestionAnswerInput>>
  >;
  assessmentId?: InputMaybe<Scalars["Int"]["input"]>;
  assessmentTemplateId: Scalars["Int"]["input"];
  complianceProgramId?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  state?: InputMaybe<AssessmentState>;
  targetId: Scalars["Int"]["input"];
};

export type MutationCreateOrUpdateAssessmentGroupArgs = {
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
  assessmentGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  isQuestionWeightingEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  sections?: InputMaybe<ReadonlyArray<InputMaybe<AssessmentGroupSectionInput>>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateOrUpdateAssessmentQuestionArgs = {
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
  categories?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>
  >;
  description?: InputMaybe<Scalars["String"]["input"]>;
  endValue?: InputMaybe<Scalars["Int"]["input"]>;
  isCommentMandatory?: InputMaybe<Scalars["Boolean"]["input"]>;
  labelDescriptions?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>
  >;
  labels?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
  optionDescriptions?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>
  >;
  options?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  questionId?: InputMaybe<Scalars["Int"]["input"]>;
  questionType?: InputMaybe<AssessmentQuestionType>;
  responseVisibility?: InputMaybe<AssessmentQuestionResponseVisibility>;
  responses?: InputMaybe<AssessmentQuestionResponses>;
  startValue?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateOrUpdateCareerTrackArgs = {
  additionalCompetencies?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  careerTrackId?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  roles?: InputMaybe<ReadonlyArray<InputMaybe<CareerTrackRoleInput>>>;
  teamId?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateOrUpdateCommentArgs = {
  artifactId?: InputMaybe<Scalars["Int"]["input"]>;
  comment: Scalars["String"]["input"];
  commentId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  textUuid?: InputMaybe<Scalars["String"]["input"]>;
  topicId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationCreateOrUpdateCompetencyArgs = {
  appliesTo?: InputMaybe<CompetencyAppliesTo>;
  appliesToCareerTracks?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  appliesToIcRoles?: InputMaybe<Scalars["Boolean"]["input"]>;
  appliesToManagementRoles?: InputMaybe<Scalars["Boolean"]["input"]>;
  appliesToTeams?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  competencyId?: InputMaybe<Scalars["Int"]["input"]>;
  criteria?: InputMaybe<ReadonlyArray<InputMaybe<CompetencyCriteriaInput>>>;
  criteriaUniqueness?: InputMaybe<CompetencyCriteriaUniqueness>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateOrUpdateComplianceProgramArgs = {
  appliesTo?: InputMaybe<ComplianceProgramAppliesTo>;
  appliesToManagers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  appliesToTeams?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  appliesToUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  assessmentTemplates?: InputMaybe<
    ReadonlyArray<InputMaybe<AssessmentTemplateComplianceRequirement>>
  >;
  complianceProgramId?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate?: InputMaybe<Scalars["Date"]["input"]>;
  excludedHireDateAfter?: InputMaybe<Scalars["Date"]["input"]>;
  excludedUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  ongoing?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  periodEndDate?: InputMaybe<Scalars["Date"]["input"]>;
  periodStartDate?: InputMaybe<Scalars["Date"]["input"]>;
  recurrence?: InputMaybe<ComplianceProgramRecurrence>;
  removeExcludedHireDateAfter?: InputMaybe<Scalars["Boolean"]["input"]>;
  removeProgramPeriod?: InputMaybe<Scalars["Boolean"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  startDateDueDateOffset?: InputMaybe<Scalars["Int"]["input"]>;
  startDateOffset?: InputMaybe<Scalars["Int"]["input"]>;
  startDatePeriodEndOffset?: InputMaybe<Scalars["Int"]["input"]>;
  startDatePeriodStartOffset?: InputMaybe<Scalars["Int"]["input"]>;
  state?: InputMaybe<ComplianceProgramState>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  topicTemplates?: InputMaybe<
    ReadonlyArray<InputMaybe<TopicTemplateComplianceRequirement>>
  >;
};

export type MutationCreateOrUpdateCoreValueArgs = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  emoji?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  organizationId: Scalars["Int"]["input"];
  status?: InputMaybe<RecognitionCoreValueStatus>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateOrUpdateExplorerBookmarkArgs = {
  explorerBookmarkId?: InputMaybe<Scalars["Int"]["input"]>;
  filters?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  private?: InputMaybe<Scalars["Boolean"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateOrUpdateFavouritesGroupArgs = {
  favouritesGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  userIds: ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>;
};

export type MutationCreateOrUpdateFlowArgs = {
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  participantCount?: InputMaybe<Scalars["Int"]["input"]>;
  participantEmailPatterns?: InputMaybe<Scalars["String"]["input"]>;
  public?: InputMaybe<Scalars["Boolean"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateOrUpdateKeyResultArgs = {
  assigneeId?: InputMaybe<Scalars["Int"]["input"]>;
  currentValue?: InputMaybe<Scalars["Float"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  goalId?: InputMaybe<Scalars["Int"]["input"]>;
  keyResultId?: InputMaybe<Scalars["Int"]["input"]>;
  kpiId?: InputMaybe<Scalars["Int"]["input"]>;
  progressType?: InputMaybe<GoalProgressType>;
  startValue?: InputMaybe<Scalars["Float"]["input"]>;
  targetValue?: InputMaybe<Scalars["Float"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateOrUpdateKpiArgs = {
  defaultSummaryMethod?: InputMaybe<KpiSummaryMethod>;
  defaultSummaryPeriod?: InputMaybe<KpiSummaryPeriod>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  groupIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  integration?: InputMaybe<KpiIntegrationInput>;
  kpiId?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  targetCondition?: InputMaybe<KpiTargetCondition>;
  targetType?: InputMaybe<KpiTargetType>;
  targetValue?: InputMaybe<Scalars["Float"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  visibility?: InputMaybe<KpiVisibility>;
};

export type MutationCreateOrUpdateKpiGroupArgs = {
  kpiGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  kpiIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateOrUpdateKpiMeasurementArgs = {
  kpiId?: InputMaybe<Scalars["Int"]["input"]>;
  kpiMeasurementId?: InputMaybe<Scalars["Int"]["input"]>;
  measurement: Scalars["Float"]["input"];
  timestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MutationCreateOrUpdateKvItemArgs = {
  key: Scalars["String"]["input"];
  value: Scalars["JSONString"]["input"];
};

export type MutationCreateOrUpdateMeetingInExternalCalendarArgs = {
  attendees: ReadonlyArray<InputMaybe<AttendeeInput>>;
  credentialsId?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  editType?: InputMaybe<MeetingEditTypeEnum>;
  endDatetime?: InputMaybe<Scalars["DateTime"]["input"]>;
  endTimezone?: InputMaybe<Scalars["String"]["input"]>;
  facilitatorId?: InputMaybe<Scalars["Int"]["input"]>;
  isFormalOneonone?: InputMaybe<Scalars["Boolean"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  onlineConference: OnlineConference;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  publish: Scalars["Boolean"]["input"];
  recurrence?: InputMaybe<FrequencyInput>;
  startDatetime?: InputMaybe<Scalars["DateTime"]["input"]>;
  startTimezone?: InputMaybe<Scalars["String"]["input"]>;
  templateId?: InputMaybe<Scalars["Int"]["input"]>;
  title: Scalars["String"]["input"];
};

export type MutationCreateOrUpdateOrganizationArgs = {
  associatedDomainName?: InputMaybe<Scalars["String"]["input"]>;
  competencyLabel?: InputMaybe<Scalars["String"]["input"]>;
  conversationLabel?: InputMaybe<Scalars["String"]["input"]>;
  defaultDecisionState?: InputMaybe<DecisionState>;
  defaultGoalState?: InputMaybe<GoalState>;
  developmentLabel?: InputMaybe<Scalars["String"]["input"]>;
  enableMeetingSummarization?: InputMaybe<Scalars["Boolean"]["input"]>;
  expectationLabel?: InputMaybe<Scalars["String"]["input"]>;
  featureFlags?: InputMaybe<OrganizationFeatureFlagsInput>;
  goalLabel?: InputMaybe<Scalars["String"]["input"]>;
  membershipSetting?: InputMaybe<OrganizationMembershipSetting>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  orgLabel?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  quarterStartMonth?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionLabel?: InputMaybe<Scalars["String"]["input"]>;
  reviewLabel?: InputMaybe<Scalars["String"]["input"]>;
  teamLabel?: InputMaybe<Scalars["String"]["input"]>;
  transcribeMeetingsByDefault?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateOrUpdateRatingArgs = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  endValue?: InputMaybe<Scalars["Int"]["input"]>;
  labels?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  ratingId?: InputMaybe<Scalars["Int"]["input"]>;
  startValue?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateOrUpdateRatingAnswerArgs = {
  integerAnswer: Scalars["Int"]["input"];
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingRatingRequirementId?: InputMaybe<Scalars["Int"]["input"]>;
  ratingAnswerId?: InputMaybe<Scalars["Int"]["input"]>;
  ratingArtifactId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationCreateOrUpdateSlackNotificationForChannelArgs = {
  channel?: InputMaybe<Scalars["String"]["input"]>;
  goalCheckins?: InputMaybe<Scalars["Boolean"]["input"]>;
  notificationId?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitions?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateOrUpdateSlackNotificationsForMeetingGroupArgs = {
  channel?: InputMaybe<Scalars["String"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  notificationId?: InputMaybe<Scalars["Int"]["input"]>;
  sendEmptyAgendaReminder?: InputMaybe<Scalars["Boolean"]["input"]>;
  sendPreparationNotification?: InputMaybe<Scalars["Boolean"]["input"]>;
  sendSummaryNotification?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateOrUpdateTeamArgs = {
  members?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  teamId?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateOrUpdateTemplateRequirementsArgs = {
  hasRequirements: Scalars["Boolean"]["input"];
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  templateId: Scalars["Int"]["input"];
};

export type MutationCreateOrUpdateTemplateSeriesForMeetingArgs = {
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  ratings?: InputMaybe<ReadonlyArray<InputMaybe<TemplateRatingInput>>>;
  templateId?: InputMaybe<Scalars["Int"]["input"]>;
  templateSeriesId?: InputMaybe<Scalars["Int"]["input"]>;
  topics?: InputMaybe<ReadonlyArray<InputMaybe<TemplateTopicInput>>>;
};

export type MutationCreateOrUpdateTopicArgs = {
  afterTopicId?: InputMaybe<Scalars["Int"]["input"]>;
  assignee?: InputMaybe<Scalars["Int"]["input"]>;
  beforeTopicId?: InputMaybe<Scalars["Int"]["input"]>;
  defaultFacilitatorNotes?: InputMaybe<Scalars["String"]["input"]>;
  defaultSubjectNotes?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  discussionNotes?: InputMaybe<Scalars["String"]["input"]>;
  includesIndividualNotes?: InputMaybe<Scalars["Boolean"]["input"]>;
  includesSharedNotes?: InputMaybe<Scalars["Boolean"]["input"]>;
  isMandatory?: InputMaybe<Scalars["Boolean"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  state?: InputMaybe<TopicState>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  topicId?: InputMaybe<Scalars["Int"]["input"]>;
  topicTemplateId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationCreateOrUpdateTopicTemplateArgs = {
  allowAdminVisibility?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowManagementTreeVisibility?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowOrgVisibility?: InputMaybe<Scalars["Boolean"]["input"]>;
  categories?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  finalizePermissions?: InputMaybe<FinalizePermissions>;
  managerInstructions?: InputMaybe<Scalars["String"]["input"]>;
  oneononeTemplate?: InputMaybe<Scalars["Boolean"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  publicTemplate?: InputMaybe<Scalars["Boolean"]["input"]>;
  ratings?: InputMaybe<ReadonlyArray<InputMaybe<TemplateRatingInput>>>;
  reportInstructions?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  topicTemplateId?: InputMaybe<Scalars["Int"]["input"]>;
  topics?: InputMaybe<ReadonlyArray<InputMaybe<TemplateTopicInput>>>;
};

export type MutationCreateOrUpdateTopicTemplateCategoryArgs = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  ordinal?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  topicTemplateCategoryId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationCreateOrUpdateWorkflowArgs = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  state?: InputMaybe<WorkflowState>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  workflowId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationCreateTemplateFromMeetingArgs = {
  meetingId: Scalars["Int"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationDeleteArtifactArgs = {
  artifactId: Scalars["Int"]["input"];
};

export type MutationDeleteAssessmentAnswerArgs = {
  assessmentId?: InputMaybe<Scalars["Int"]["input"]>;
  questionId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationDeleteAssessmentGroupArgs = {
  assessmentGroupId: Scalars["Int"]["input"];
};

export type MutationDeleteAssessmentQuestionArgs = {
  assessmentQuestionId: Scalars["Int"]["input"];
};

export type MutationDeleteCareerTrackArgs = {
  careerTrackId: Scalars["Int"]["input"];
};

export type MutationDeleteCommentArgs = {
  commentId: Scalars["Int"]["input"];
};

export type MutationDeleteCompetencyArgs = {
  competencyId: Scalars["Int"]["input"];
};

export type MutationDeleteComplianceProgramArgs = {
  complianceProgramId: Scalars["Int"]["input"];
};

export type MutationDeleteCoreValueArgs = {
  coreValueId: Scalars["Int"]["input"];
};

export type MutationDeleteExplorerBookmarkArgs = {
  explorerBookmarkId: Scalars["Int"]["input"];
};

export type MutationDeleteFavouritesGroupArgs = {
  favouritesGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  removeAll?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationDeleteFlowArgs = {
  flowId: Scalars["Int"]["input"];
};

export type MutationDeleteKeyResultArgs = {
  keyResultId: Scalars["Int"]["input"];
};

export type MutationDeleteKpiArgs = {
  kpiId: Scalars["Int"]["input"];
};

export type MutationDeleteKpiGroupArgs = {
  kpiGroupId: Scalars["Int"]["input"];
};

export type MutationDeleteKpiIntegrationArgs = {
  integrationId: Scalars["Int"]["input"];
};

export type MutationDeleteKpiMeasurementArgs = {
  measurementId: Scalars["Int"]["input"];
};

export type MutationDeleteMeetingArgs = {
  meetingId: Scalars["Int"]["input"];
};

export type MutationDeleteMeetingTranscriptsArgs = {
  meetingId: Scalars["Int"]["input"];
};

export type MutationDeleteRatingArgs = {
  ratingId: Scalars["Int"]["input"];
};

export type MutationDeleteSlackNotificationForChannelArgs = {
  notificationId: Scalars["Int"]["input"];
};

export type MutationDeleteSlackNotificationsForMeetingGroupArgs = {
  notificationId: Scalars["Int"]["input"];
};

export type MutationDeleteTeamArgs = {
  teamId: Scalars["Int"]["input"];
};

export type MutationDeleteTopicArgs = {
  topicId: Scalars["Int"]["input"];
};

export type MutationDeleteTopicTemplateArgs = {
  topicTemplateId: Scalars["Int"]["input"];
};

export type MutationDeleteTopicTemplateCategoryArgs = {
  topicTemplateCategoryId: Scalars["Int"]["input"];
};

export type MutationDeleteWorkflowArgs = {
  workflowId: Scalars["Int"]["input"];
};

export type MutationDismissInsightArgs = {
  artifactId?: InputMaybe<Scalars["Int"]["input"]>;
  assessmentGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  complianceProgramId?: InputMaybe<Scalars["Int"]["input"]>;
  managerId?: InputMaybe<Scalars["Int"]["input"]>;
  reportId?: InputMaybe<Scalars["Int"]["input"]>;
  templateRequirementId?: InputMaybe<Scalars["Int"]["input"]>;
  type: InsightType;
  userId: Scalars["Int"]["input"];
};

export type MutationDuplicateGoalArgs = {
  dueDate?: InputMaybe<Scalars["Date"]["input"]>;
  duplicateKeyResults?: InputMaybe<Scalars["Boolean"]["input"]>;
  goalId: Scalars["Int"]["input"];
  parentGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  resetProgress?: InputMaybe<Scalars["Boolean"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationEmail1on1NudgeArgs = {
  managerId: Scalars["Int"]["input"];
  reportId: Scalars["Int"]["input"];
  topicTemplateId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationEmailAssessmentReminderArgs = {
  complianceProgramId: Scalars["Int"]["input"];
};

export type MutationEmailMeetingInvitesArgs = {
  meetingId: Scalars["Int"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  recipientEmails: ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>;
};

export type MutationEmailMeetingNotesArgs = {
  meetingId: Scalars["Int"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  recipientEmails: ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>;
};

export type MutationEmailOneononeReminderArgs = {
  complianceProgramId: Scalars["Int"]["input"];
};

export type MutationExportComplianceProgramToCsvArgs = {
  complianceProgramId: Scalars["Int"]["input"];
};

export type MutationIgnoreMeetingArgs = {
  ignore: Scalars["Boolean"]["input"];
  meetingId: Scalars["Int"]["input"];
};

export type MutationJoinOrganizationArgs = {
  organizationId: Scalars["Int"]["input"];
};

export type MutationLinkCalendarToOrganizationArgs = {
  credentialsId: Scalars["Int"]["input"];
  organizationId: Scalars["Int"]["input"];
};

export type MutationLinkCreatedArtifactToChatMessageArgs = {
  artifactId: Scalars["Int"]["input"];
  chatSessionId: Scalars["Int"]["input"];
  messageId: Scalars["String"]["input"];
};

export type MutationPrepareFileUploadArgs = {
  artifactId?: InputMaybe<Scalars["Int"]["input"]>;
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  filename: Scalars["String"]["input"];
  recognitionCoreValueId?: InputMaybe<Scalars["Int"]["input"]>;
  topicId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationRemoveBotFromMeetingArgs = {
  meetingId: Scalars["Int"]["input"];
};

export type MutationRemoveFavouriteArtifactArgs = {
  artifactId: Scalars["Int"]["input"];
};

export type MutationRemoveIntegrationCredentialsArgs = {
  credentialsId: Scalars["Int"]["input"];
};

export type MutationRemoveOrgGithubIntegrationArgs = {
  organizationId: Scalars["Int"]["input"];
};

export type MutationRemoveSlackIntegrationArgs = {
  organizationId: Scalars["Int"]["input"];
};

export type MutationRemoveTeamMemberArgs = {
  teamId: Scalars["Int"]["input"];
  userId: Scalars["Int"]["input"];
};

export type MutationRemoveTemplateSeriesFromMeetingArgs = {
  meetingGroupId: Scalars["Int"]["input"];
};

export type MutationRemoveUserFromOrganizationArgs = {
  orgId: Scalars["Int"]["input"];
  userToRemoveId: Scalars["Int"]["input"];
};

export type MutationReopenAssessmentArgs = {
  assessmentId: Scalars["Int"]["input"];
};

export type MutationReorderArtifactArgs = {
  afterArtifactId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactId: Scalars["Int"]["input"];
  beforeArtifactId?: InputMaybe<Scalars["Int"]["input"]>;
  contextId: Scalars["Int"]["input"];
  contextType: Scalars["String"]["input"];
};

export type MutationReorderTopicArgs = {
  afterTopicId?: InputMaybe<Scalars["Int"]["input"]>;
  beforeTopicId?: InputMaybe<Scalars["Int"]["input"]>;
  topicId: Scalars["Int"]["input"];
};

export type MutationSearchCsvExportArgs = {
  options?: InputMaybe<SearchOptions>;
  searchTerm: Scalars["String"]["input"];
};

export type MutationSendMessageToChatbotArgs = {
  chatSessionId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  message: Scalars["String"]["input"];
};

export type MutationSetAssessmentNominationsArgs = {
  assessmentTemplateId: Scalars["Int"]["input"];
  complianceProgramId: Scalars["Int"]["input"];
  responderIds: ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>;
  targetId: Scalars["Int"]["input"];
};

export type MutationSetFavouriteGroupsArgs = {
  userIds: ReadonlyArray<
    InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>
  >;
};

export type MutationSetMeetingAgendaAsTemplateArgs = {
  meetingId: Scalars["Int"]["input"];
};

export type MutationSetMeetingLockedArgs = {
  isLocked: Scalars["Boolean"]["input"];
  meetingId: Scalars["Int"]["input"];
};

export type MutationSetRelationshipArgs = {
  clearRelationship?: InputMaybe<Scalars["Boolean"]["input"]>;
  meetingFrequency?: InputMaybe<FrequencyInput>;
  organizationId: Scalars["Int"]["input"];
  otherUserId: Scalars["Int"]["input"];
  relationshipType?: InputMaybe<RelationshipType>;
  userId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationSetRelationshipsArgs = {
  organizationId: Scalars["Int"]["input"];
  relationships?: InputMaybe<ReadonlyArray<InputMaybe<RelationshipInput>>>;
  userId: Scalars["Int"]["input"];
};

export type MutationStartCalendarSyncArgs = {
  fullSync?: InputMaybe<Scalars["Boolean"]["input"]>;
  syncCredentialsId: Scalars["Int"]["input"];
};

export type MutationSubmitOneononeArgs = {
  meetingId: Scalars["Int"]["input"];
  unhideIndividualNotes?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationToggleIndividualNoteVisibilityArgs = {
  enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  hidden?: InputMaybe<Scalars["Boolean"]["input"]>;
  topicIds: ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>;
};

export type MutationUnlinkIntegrationFromOrganizationArgs = {
  organizationIntegrationId: Scalars["Int"]["input"];
};

export type MutationUpdateAssessmentDeliveryArgs = {
  assessmentDeliveryId: Scalars["Int"]["input"];
  excludedAnswerIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  state?: InputMaybe<AssessmentDeliveryState>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateAttendanceStatusArgs = {
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  status: AttendeeStatus;
};

export type MutationUpdateMeetingArgs = {
  allowExternalViewers?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearAllowExternalViewers?: InputMaybe<Scalars["Boolean"]["input"]>;
  meetingId: Scalars["Int"]["input"];
};

export type MutationUpdateMeetingFinalizationStatusArgs = {
  isFinalized: Scalars["Boolean"]["input"];
  meetingId: Scalars["Int"]["input"];
};

export type MutationUpdateMeetingGroupArgs = {
  addTranscriptionBotToMeetingsByDefault?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  allowAdminVisibility?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowExternalViewers?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowManagementTreeVisibility?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowOrgVisibility?: InputMaybe<Scalars["Boolean"]["input"]>;
  facilitatorId?: InputMaybe<Scalars["Int"]["input"]>;
  isFormalOneonone?: InputMaybe<Scalars["Boolean"]["input"]>;
  meetingGroupId: Scalars["Int"]["input"];
  transcribeMeetingsByDefault?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationUpdateMeetingTranscriptArgs = {
  meetingId: Scalars["Int"]["input"];
  removeMessageIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>
  >;
  transcriptData?: InputMaybe<Scalars["JSONString"]["input"]>;
  userMapping?: InputMaybe<Scalars["JSONString"]["input"]>;
};

export type MutationUpdateMeetingTranscriptionStatusArgs = {
  meetingId: Scalars["Int"]["input"];
  transcriptionStatus: Scalars["Boolean"]["input"];
};

export type MutationUpdateOrganizationMembershipArgs = {
  employeeId?: InputMaybe<Scalars["String"]["input"]>;
  jobLevel?: InputMaybe<Scalars["String"]["input"]>;
  membershipStatus?: InputMaybe<AccountToOrganizationMembershipStatus>;
  organizationId: Scalars["Int"]["input"];
  position?: InputMaybe<Scalars["String"]["input"]>;
  role?: InputMaybe<OrganizationRole>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  userId: Scalars["Int"]["input"];
};

export type MutationUpdateSuggestedArtifactArgs = {
  hidden?: InputMaybe<Scalars["Boolean"]["input"]>;
  suggestedArtifactId: Scalars["Int"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateSuggestedTopicArgs = {
  hidden?: InputMaybe<Scalars["Boolean"]["input"]>;
  suggestedTopicId: Scalars["Int"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateTopicDiscussionNotesArgs = {
  discussionNotes?: InputMaybe<Scalars["String"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  topicId: Scalars["Int"]["input"];
};

export type MutationUpdateUserArgs = {
  emailPreference?: InputMaybe<EmailPreference>;
  enableDailyCommitmentEmails?: InputMaybe<Scalars["Boolean"]["input"]>;
  enableDailySummaryEmails?: InputMaybe<Scalars["Boolean"]["input"]>;
  enableDraftReminderEmails?: InputMaybe<Scalars["Boolean"]["input"]>;
  eventLinkInDescriptionPreference?: InputMaybe<EventDescriptionLinkSetting>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  meetingPreparationEmailPreference?: InputMaybe<MeetingAgendaEmailPreference>;
  meetingSummaryEmailPreference?: InputMaybe<MeetingSummaryEmailPreference>;
  primaryEmailAddress?: InputMaybe<Scalars["String"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  uiPreferenceCache?: InputMaybe<Scalars["JSONString"]["input"]>;
};

export type MutationUpdateWorkHistoryArgs = {
  data: WorkHistorySummaryInput;
  workHistorySummaryId: Scalars["Int"]["input"];
};

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  readonly id: Scalars["ID"]["output"];
};

/** An enumeration. */
export enum NotesRequirement {
  AllParticipants = "all_participants",
  NotRequired = "not_required",
  OneParticipant = "one_participant",
}

/** An enumeration. */
export enum OnlineConference {
  Native = "native",
  None = "none",
  Zoom = "zoom",
}

/** An enumeration. */
export enum OnlineVideoConference {
  Aroundco = "aroundco",
  Google = "google",
  None = "none",
  Teams = "teams",
  Whereby = "whereby",
  Zoom = "zoom",
}

export type OrganizationFeatureFlags = {
  readonly __typename: "OrganizationFeatureFlags";
  readonly actionItems?: Maybe<Scalars["Boolean"]["output"]>;
  readonly decisions?: Maybe<Scalars["Boolean"]["output"]>;
  readonly documents?: Maybe<Scalars["Boolean"]["output"]>;
  readonly explorer?: Maybe<Scalars["Boolean"]["output"]>;
  readonly feedbacks?: Maybe<Scalars["Boolean"]["output"]>;
  readonly goals?: Maybe<Scalars["Boolean"]["output"]>;
  readonly homepageMyConnections?: Maybe<Scalars["Boolean"]["output"]>;
  readonly kpis?: Maybe<Scalars["Boolean"]["output"]>;
  readonly recognitions?: Maybe<Scalars["Boolean"]["output"]>;
};

export type OrganizationFeatureFlagsInput = {
  readonly actionItems?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly decisions?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly documents?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly explorer?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly feedbacks?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly goals?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly homepageMyConnections?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly kpis?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly recognitions?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type OrganizationInfoNode = {
  readonly __typename: "OrganizationInfoNode";
  readonly organizationId?: Maybe<Scalars["Int"]["output"]>;
  readonly organizationMemberCount?: Maybe<Scalars["Int"]["output"]>;
  readonly organizationName?: Maybe<Scalars["String"]["output"]>;
};

export type OrganizationIntegrationNode = DjangoNode &
  Node & {
    readonly __typename: "OrganizationIntegrationNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly created: Scalars["DateTime"]["output"];
    readonly creator: UserNode;
    readonly id: Scalars["ID"]["output"];
    readonly integrationType: IntegrationTypes;
    readonly organization: OrganizationNode;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly socialAuth: UserSocialAuthNode;
    readonly updated: Scalars["DateTime"]["output"];
  };

export type OrganizationIntegrationNodeConnection = {
  readonly __typename: "OrganizationIntegrationNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<OrganizationIntegrationNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `OrganizationIntegrationNode` and its cursor. */
export type OrganizationIntegrationNodeEdge = {
  readonly __typename: "OrganizationIntegrationNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<OrganizationIntegrationNode>;
};

/** An enumeration. */
export enum OrganizationMembershipSetting {
  AnyoneWithValidDomain = "anyone_with_valid_domain",
  ManualInvitesOnly = "manual_invites_only",
}

export type OrganizationNode = DjangoNode &
  Node & {
    readonly __typename: "OrganizationNode";
    readonly actionItemStates: ReadonlyArray<Maybe<ActionItemState>>;
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly competencyLabel: Scalars["String"]["output"];
    readonly compliancePrograms?: Maybe<ComplianceProgramNodeConnection>;
    readonly conversationLabel: Scalars["String"]["output"];
    readonly coreValues?: Maybe<RecognitionCoreValueNodeConnection>;
    readonly daysLeftOnTrial?: Maybe<Scalars["Int"]["output"]>;
    readonly defaultDecisionState?: Maybe<DecisionState>;
    readonly defaultGoalState?: Maybe<GoalState>;
    readonly developmentLabel: Scalars["String"]["output"];
    readonly directorySync?: Maybe<DirectorySyncNode>;
    readonly domains?: Maybe<ReadonlyArray<Maybe<Scalars["String"]["output"]>>>;
    readonly effectivePricingTier?: Maybe<PricingTier>;
    readonly enableMeetingSummarization: Scalars["Boolean"]["output"];
    readonly expectationLabel: Scalars["String"]["output"];
    readonly featureFlags: OrganizationFeatureFlags;
    readonly goalLabel: Scalars["String"]["output"];
    readonly hasGithubInstallation?: Maybe<Scalars["Boolean"]["output"]>;
    readonly hasSlackConnection?: Maybe<Scalars["Boolean"]["output"]>;
    readonly hasTeamsTranscriptConnection?: Maybe<Scalars["Boolean"]["output"]>;
    readonly id: Scalars["ID"]["output"];
    readonly integrations: OrganizationIntegrationNodeConnection;
    readonly keyResultLabel: Scalars["String"]["output"];
    readonly members: UserMemberNodeConnection;
    readonly membershipSetting?: Maybe<OrganizationMembershipSetting>;
    readonly name: Scalars["String"]["output"];
    readonly oneononeLabel: Scalars["String"]["output"];
    readonly orgLabel: Scalars["String"]["output"];
    readonly personal?: Maybe<Scalars["Boolean"]["output"]>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly pricingTier?: Maybe<PricingTier>;
    readonly pricingTierValidUntil?: Maybe<Scalars["DateTime"]["output"]>;
    readonly publicSlackChannels?: Maybe<SlackChannelConnection>;
    readonly quarterStartMonth: Scalars["Int"]["output"];
    readonly recognitionLabel: Scalars["String"]["output"];
    readonly recognitionStats?: Maybe<RecognitionStatsNode>;
    readonly reviewLabel: Scalars["String"]["output"];
    readonly slackNotifications?: Maybe<SlackNotificationForChannelNodeConnection>;
    readonly teamLabel: Scalars["String"]["output"];
    readonly teams?: Maybe<TeamNodeConnection>;
    readonly topicChannelName?: Maybe<Scalars["String"]["output"]>;
    readonly transcribeMeetingsByDefault?: Maybe<Scalars["Boolean"]["output"]>;
    readonly userMembership?: Maybe<UserMemberNode>;
    readonly userRelationships?: Maybe<RelationshipNodeConnection>;
    readonly workflows?: Maybe<WorkflowNodeConnection>;
  };

export type OrganizationNodeComplianceProgramsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  applicableUser?: InputMaybe<Scalars["Int"]["input"]>;
  assessmentType?: InputMaybe<AssessmentType>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["Date"]["input"]>;
  dueDate_Gt?: InputMaybe<Scalars["Date"]["input"]>;
  dueDate_Gte?: InputMaybe<Scalars["Date"]["input"]>;
  dueDate_Lt?: InputMaybe<Scalars["Date"]["input"]>;
  dueDate_Lte?: InputMaybe<Scalars["Date"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<ComplianceProgramState>;
};

export type OrganizationNodeCoreValuesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<RecognitionCoreValueStatus>;
};

export type OrganizationNodeIntegrationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  integrationType?: InputMaybe<IntegrationTypes>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type OrganizationNodeMembersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeUserId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeUserIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManager?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  isAdmin?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  membershipStatus?: InputMaybe<AccountToOrganizationMembershipStatus>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type OrganizationNodePublicSlackChannelsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type OrganizationNodeRecognitionStatsArgs = {
  coreValueId?: InputMaybe<Scalars["Int"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  recipientId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
};

export type OrganizationNodeSlackNotificationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type OrganizationNodeTeamsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_Icontains?: InputMaybe<Scalars["String"]["input"]>;
  title_Startswith?: InputMaybe<Scalars["String"]["input"]>;
};

export type OrganizationNodeUserMembershipArgs = {
  userId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type OrganizationNodeUserRelationshipsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  primaryRelationshipType?: InputMaybe<RelationshipType>;
  primaryUser?: InputMaybe<Scalars["ID"]["input"]>;
  secondaryUser?: InputMaybe<Scalars["ID"]["input"]>;
  userId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type OrganizationNodeWorkflowsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type OrganizationNodeConnection = {
  readonly __typename: "OrganizationNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<OrganizationNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `OrganizationNode` and its cursor. */
export type OrganizationNodeEdge = {
  readonly __typename: "OrganizationNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<OrganizationNode>;
};

/** An enumeration. */
export enum OrganizationRole {
  Administrator = "administrator",
  Member = "member",
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  readonly __typename: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  readonly endCursor?: Maybe<Scalars["String"]["output"]>;
  /** When paginating forwards, are there more items? */
  readonly hasNextPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, are there more items? */
  readonly hasPreviousPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, the cursor to continue. */
  readonly startCursor?: Maybe<Scalars["String"]["output"]>;
};

export type PaidFeaturesNode = {
  readonly __typename: "PaidFeaturesNode";
  readonly canCreateDecisions?: Maybe<Scalars["Boolean"]["output"]>;
  readonly canCreateGoals?: Maybe<Scalars["Boolean"]["output"]>;
  readonly canCreateGroupGoals?: Maybe<Scalars["Boolean"]["output"]>;
  readonly canCreateOrgTemplates?: Maybe<Scalars["Boolean"]["output"]>;
};

export type ParticipantStatusConnection = {
  readonly __typename: "ParticipantStatusConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ParticipantStatusEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `ParticipantStatus` and its cursor. */
export type ParticipantStatusEdge = {
  readonly __typename: "ParticipantStatusEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<ParticipantStatusNode>;
};

export type ParticipantStatusNode = {
  readonly __typename: "ParticipantStatusNode";
  readonly managerAssessmentStatus: ComplianceProgramParticipantStatus;
  readonly managerSelfAssessmentStatus: ComplianceProgramParticipantStatus;
  readonly meeting?: Maybe<MeetingNode>;
  readonly oneononeStatus: ComplianceProgramParticipantStatus;
  readonly peerAssessmentStatus: ComplianceProgramParticipantStatus;
  readonly peerSelfAssessmentStatus: ComplianceProgramParticipantStatus;
  readonly performanceAssessmentStatus: ComplianceProgramParticipantStatus;
  readonly performanceSelfAssessmentStatus: ComplianceProgramParticipantStatus;
  readonly user: UserNode;
};

export type PermissionNode = {
  readonly __typename: "PermissionNode";
  readonly permission: Scalars["Boolean"]["output"];
  readonly reason: Scalars["String"]["output"];
};

export type PrepareFileUploadMutation = {
  readonly __typename: "PrepareFileUploadMutation";
  readonly artifact?: Maybe<ArtifactInterface>;
  /** The URL to upload the file to */
  readonly fileUploadUrl?: Maybe<Scalars["String"]["output"]>;
  /** The URL that will return the file to a user */
  readonly fileViewingUrl?: Maybe<Scalars["String"]["output"]>;
  readonly recognitionCoreValue?: Maybe<RecognitionCoreValueNode>;
  readonly topic?: Maybe<TopicNode>;
};

/** An enumeration. */
export enum PricingTier {
  Free = "free",
  Pro = "pro",
  Ultimate = "ultimate",
}

export type Query = {
  readonly __typename: "Query";
  readonly adoptionReport: ReportResponseNode;
  readonly artifact: ArtifactInterface;
  readonly artifacts?: Maybe<ArtifactConnection>;
  readonly asanaTaskPreview?: Maybe<AsanaTaskPreviewNode>;
  readonly assessment?: Maybe<AssessmentNode>;
  readonly assessmentDeliveries: AssessmentDeliveryNodeConnection;
  readonly assessmentDelivery?: Maybe<AssessmentDeliveryNode>;
  readonly assessmentGroup?: Maybe<AssessmentGroupNode>;
  readonly assessmentGroups: AssessmentGroupNodeConnection;
  readonly assessmentQuestion?: Maybe<AssessmentQuestionInterface>;
  readonly assessmentQuestionCategories: AssessmentQuestionCategoryNodeConnection;
  readonly assessmentQuestions?: Maybe<AssessmentQuestionConnection>;
  readonly assessments: AssessmentNodeConnection;
  readonly assessmentsOpenForNominations: AssessmentOpenForNominationConnection;
  readonly assignableUsers?: Maybe<UserNodeConnection>;
  readonly calendar?: Maybe<BasicMeetingNodeConnection>;
  readonly careerRole?: Maybe<CareerTrackRoleNode>;
  readonly careerTrack?: Maybe<CareerTrackNode>;
  readonly careerTracks: CareerTrackNodeConnection;
  readonly chatSession?: Maybe<ChatSessionNode>;
  readonly chatSessions?: Maybe<ChatSessionNodeConnection>;
  readonly comment?: Maybe<CommentNode>;
  readonly comments?: Maybe<CommentNodeConnection>;
  readonly competencies: CompetencyConnection;
  readonly competency?: Maybe<CompetencyNode>;
  readonly complianceProgram?: Maybe<ComplianceProgramNode>;
  readonly compliancePrograms: ComplianceProgramNodeConnection;
  readonly feed?: Maybe<ReadonlyArray<Maybe<FeedNode>>>;
  readonly flow?: Maybe<FlowNode>;
  readonly flows?: Maybe<FlowNodeConnection>;
  readonly githubSearch?: Maybe<InfoCardConnection>;
  readonly goalsForUser?: Maybe<GoalArtifactConnection>;
  readonly hubspotPipelineStages?: Maybe<HubspotStageConnection>;
  readonly invitation?: Maybe<InvitationNode>;
  readonly jiraSearch?: Maybe<InfoCardConnection>;
  readonly kpi?: Maybe<KpiNode>;
  readonly kpiGroup?: Maybe<KpiGroupNode>;
  readonly kpiGroups?: Maybe<KpiGroupNodeConnection>;
  readonly kpis?: Maybe<KpiNodeConnection>;
  readonly loggedIn?: Maybe<Scalars["Boolean"]["output"]>;
  readonly me?: Maybe<UserNode>;
  readonly meeting?: Maybe<MeetingNode>;
  readonly meetingGroup?: Maybe<MeetingGroupNode>;
  readonly meetingGroups?: Maybe<MeetingGroupNodeConnection>;
  readonly meetings?: Maybe<MeetingNodeConnection>;
  readonly meetingsByManagerReport?: Maybe<MeetingsByManagerReportConnection>;
  readonly organization?: Maybe<OrganizationNode>;
  /** Feedback requests that have been sent to someone else and not yet answered */
  readonly pendingFeedbackRequests?: Maybe<BasicFeedbackArtifactNodeConnection>;
  readonly rating?: Maybe<RatingNode>;
  readonly ratings?: Maybe<RatingNodeConnection>;
  readonly relationshipWithUser?: Maybe<RelationshipNode>;
  readonly search?: Maybe<SearchConnection>;
  readonly slackNotificationsForOrg?: Maybe<SlackNotificationForChannelNodeConnection>;
  readonly team?: Maybe<TeamNode>;
  readonly teams: TeamNodeConnection;
  readonly topic?: Maybe<TopicNode>;
  readonly topicTemplate?: Maybe<TopicTemplateNode>;
  readonly topicTemplateCategories: TemplateCategoryNodeConnection;
  readonly topicTemplateCategory?: Maybe<TemplateCategoryNode>;
  readonly topicTemplates?: Maybe<TopicTemplateNodeConnection>;
  readonly topics?: Maybe<TopicNodeConnection>;
  readonly unmetManagerEffectivenessCompliancePrograms: ComplianceProgramNodeConnection;
  readonly unmetPeerAssessmentCompliancePrograms: ComplianceProgramNodeConnection;
  readonly unmetPerformanceAssessmentCompliancePrograms: ComplianceProgramNodeConnection;
  readonly urlToInfoCard: InfoCardInterface;
  readonly user?: Maybe<UserNode>;
  readonly users: UserNodeConnection;
  readonly workHistory?: Maybe<WorkHistorySummaryNode>;
  readonly workflow?: Maybe<WorkflowNode>;
  readonly workflows?: Maybe<WorkflowNodeConnection>;
};

export type QueryAdoptionReportArgs = {
  attributes: ReadonlyArray<InputMaybe<AdoptionReportAttribute>>;
  filters: ReadonlyArray<InputMaybe<ReportFilterInput>>;
  metrics: ReadonlyArray<InputMaybe<AdoptionReportMetric>>;
  organizationId: Scalars["Int"]["input"];
};

export type QueryArtifactArgs = {
  artifactId: Scalars["Int"]["input"];
};

export type QueryArtifactsArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryAsanaTaskPreviewArgs = {
  url: Scalars["String"]["input"];
};

export type QueryAssessmentArgs = {
  assessmentId: Scalars["Int"]["input"];
};

export type QueryAssessmentDeliveriesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assessmentType?: InputMaybe<AssessmentType>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  complianceProgramId?: InputMaybe<Scalars["Int"]["input"]>;
  creatorId?: InputMaybe<Scalars["Int"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  state?: InputMaybe<AssessmentDeliveryState>;
  targetId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryAssessmentDeliveryArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryAssessmentGroupArgs = {
  assessmentGroupId: Scalars["Int"]["input"];
};

export type QueryAssessmentGroupsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
  assessmentType?: InputMaybe<AssessmentType>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryAssessmentQuestionArgs = {
  questionId: Scalars["Int"]["input"];
};

export type QueryAssessmentQuestionCategoriesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryAssessmentQuestionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
  assessmentType?: InputMaybe<AssessmentType>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  category?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryAssessmentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assessmentType?: InputMaybe<AssessmentType>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  responderId?: InputMaybe<Scalars["Int"]["input"]>;
  selfAssessment?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<AssessmentState>;
  targetId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryAssessmentsOpenForNominationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  complianceProgramId?: InputMaybe<Scalars["Int"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  targetId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryAssignableUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  artifactId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  hasNoManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActiveOrInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  reportIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  topicId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryCalendarArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  endDatetime?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  excludeMeetingGroupIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forUserId: Scalars["Int"]["input"];
  fromMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  ignored?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFormalOneonone?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Decimal"]["input"]>;
  participantCount?: InputMaybe<Scalars["Int"]["input"]>;
  participants?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  startDatetime?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<EventStatus>;
  status_In?: InputMaybe<ReadonlyArray<InputMaybe<EventStatus>>>;
  templateId?: InputMaybe<Scalars["Int"]["input"]>;
  unscheduled?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryCareerRoleArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryCareerTrackArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryCareerTracksArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId: Scalars["Int"]["input"];
  teamId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryChatSessionArgs = {
  chatSessionId: Scalars["Int"]["input"];
};

export type QueryChatSessionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryCommentArgs = {
  commentId: Scalars["Int"]["input"];
};

export type QueryCommentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  artifactId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  isInlineComment?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  textUuid?: InputMaybe<Scalars["String"]["input"]>;
  topicId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryCompetenciesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId: Scalars["Int"]["input"];
};

export type QueryCompetencyArgs = {
  id: Scalars["Int"]["input"];
  roleId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryComplianceProgramArgs = {
  pk: Scalars["Int"]["input"];
};

export type QueryComplianceProgramsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  applicableUser?: InputMaybe<Scalars["Int"]["input"]>;
  assessmentType?: InputMaybe<AssessmentType>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["Date"]["input"]>;
  dueDate_Gt?: InputMaybe<Scalars["Date"]["input"]>;
  dueDate_Gte?: InputMaybe<Scalars["Date"]["input"]>;
  dueDate_Lt?: InputMaybe<Scalars["Date"]["input"]>;
  dueDate_Lte?: InputMaybe<Scalars["Date"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<ComplianceProgramState>;
};

export type QueryFeedArgs = {
  afterDatetime?: InputMaybe<Scalars["DateTime"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryFlowArgs = {
  flowId: Scalars["Int"]["input"];
};

export type QueryFlowsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGithubSearchArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  searchQuery: Scalars["String"]["input"];
};

export type QueryGoalsForUserArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  forUserId: Scalars["Int"]["input"];
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryHubspotPipelineStagesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceRefresh?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryInvitationArgs = {
  inviteKey?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryJiraSearchArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  jqlQuery: Scalars["String"]["input"];
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryKpiArgs = {
  kpiId: Scalars["Int"]["input"];
};

export type QueryKpiGroupArgs = {
  kpiGroupId: Scalars["Int"]["input"];
};

export type QueryKpiGroupsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryKpisArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  group?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryMeetingArgs = {
  afterMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  beforeMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  googleMeetUrl?: InputMaybe<Scalars["String"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryMeetingGroupArgs = {
  meetingGroupId: Scalars["Int"]["input"];
};

export type QueryMeetingGroupsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryMeetingsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  endDatetime?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  excludeMeetingGroupIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  fromMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  ignored?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFormalOneonone?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Decimal"]["input"]>;
  participantCount?: InputMaybe<Scalars["Int"]["input"]>;
  participants?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  startDatetime?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<EventStatus>;
  status_In?: InputMaybe<ReadonlyArray<InputMaybe<EventStatus>>>;
  templateId?: InputMaybe<Scalars["Int"]["input"]>;
  unscheduled?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryMeetingsByManagerReportArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  personId?: InputMaybe<Scalars["Int"]["input"]>;
  templateId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryOrganizationArgs = {
  pk?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryPendingFeedbackRequestsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  createdByReportsOfUserId?: InputMaybe<Scalars["Int"]["input"]>;
  creatorId?: InputMaybe<Scalars["Int"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  recipientId?: InputMaybe<Scalars["Int"]["input"]>;
  recipientsOfManagerId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryRatingArgs = {
  ratingId: Scalars["Int"]["input"];
};

export type QueryRatingsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryRelationshipWithUserArgs = {
  organizationId: Scalars["Int"]["input"];
  otherUserId: Scalars["Int"]["input"];
};

export type QuerySearchArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  options?: InputMaybe<SearchOptions>;
  searchTerm: Scalars["String"]["input"];
};

export type QuerySlackNotificationsForOrgArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId: Scalars["Int"]["input"];
};

export type QueryTeamArgs = {
  teamId: Scalars["Int"]["input"];
};

export type QueryTeamsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_Icontains?: InputMaybe<Scalars["String"]["input"]>;
  title_Startswith?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryTopicArgs = {
  topicId: Scalars["Int"]["input"];
};

export type QueryTopicTemplateArgs = {
  topicTemplateId: Scalars["Int"]["input"];
};

export type QueryTopicTemplateCategoriesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_Contains?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryTopicTemplateCategoryArgs = {
  categoryId: Scalars["Int"]["input"];
};

export type QueryTopicTemplatesArgs = {
  adhocTemplate?: InputMaybe<Scalars["Boolean"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  globalTemplate?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasRequirements?: InputMaybe<Scalars["Boolean"]["input"]>;
  includesRatingId?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  publicTemplate?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryTopicsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assignee?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasActionItems?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasArtifacts?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDecisions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDocuments?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasGoals?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInFlowId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInMeetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<TopicState>;
  titleContains?: InputMaybe<Scalars["String"]["input"]>;
  topicIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type QueryUnmetManagerEffectivenessComplianceProgramsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  applicableUser?: InputMaybe<Scalars["Int"]["input"]>;
  assessmentType?: InputMaybe<AssessmentType>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["Date"]["input"]>;
  dueDate_Gt?: InputMaybe<Scalars["Date"]["input"]>;
  dueDate_Gte?: InputMaybe<Scalars["Date"]["input"]>;
  dueDate_Lt?: InputMaybe<Scalars["Date"]["input"]>;
  dueDate_Lte?: InputMaybe<Scalars["Date"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<ComplianceProgramState>;
};

export type QueryUnmetPeerAssessmentComplianceProgramsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  applicableUser?: InputMaybe<Scalars["Int"]["input"]>;
  assessmentType?: InputMaybe<AssessmentType>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["Date"]["input"]>;
  dueDate_Gt?: InputMaybe<Scalars["Date"]["input"]>;
  dueDate_Gte?: InputMaybe<Scalars["Date"]["input"]>;
  dueDate_Lt?: InputMaybe<Scalars["Date"]["input"]>;
  dueDate_Lte?: InputMaybe<Scalars["Date"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<ComplianceProgramState>;
};

export type QueryUnmetPerformanceAssessmentComplianceProgramsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  applicableUser?: InputMaybe<Scalars["Int"]["input"]>;
  assessmentType?: InputMaybe<AssessmentType>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["Date"]["input"]>;
  dueDate_Gt?: InputMaybe<Scalars["Date"]["input"]>;
  dueDate_Gte?: InputMaybe<Scalars["Date"]["input"]>;
  dueDate_Lt?: InputMaybe<Scalars["Date"]["input"]>;
  dueDate_Lte?: InputMaybe<Scalars["Date"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<ComplianceProgramState>;
};

export type QueryUrlToInfoCardArgs = {
  forceRefresh?: InputMaybe<Scalars["Boolean"]["input"]>;
  url: Scalars["String"]["input"];
};

export type QueryUserArgs = {
  userId: Scalars["Int"]["input"];
};

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  hasNoManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActiveOrInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  reportIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type QueryWorkHistoryArgs = {
  end: Scalars["Date"]["input"];
  organizationId: Scalars["Int"]["input"];
  start: Scalars["Date"]["input"];
  userId: Scalars["Int"]["input"];
};

export type QueryWorkflowArgs = {
  workflowId: Scalars["Int"]["input"];
};

export type QueryWorkflowsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RangeAssessmentAnswerNode = AssessmentAnswerInterface & {
  readonly __typename: "RangeAssessmentAnswerNode";
  readonly assessment: AssessmentNode;
  readonly canDelete: PermissionNode;
  readonly canRead: PermissionNode;
  readonly canUpdate: PermissionNode;
  readonly comment?: Maybe<Scalars["JSONString"]["output"]>;
  readonly created: Scalars["DateTime"]["output"];
  readonly id: Scalars["Int"]["output"];
  readonly integerAnswer?: Maybe<Scalars["Int"]["output"]>;
  readonly question: AssessmentQuestionInterface;
  readonly responder?: Maybe<UserNode>;
  readonly updated: Scalars["DateTime"]["output"];
};

export type RangeAssessmentQuestionNode = AssessmentQuestionInterface & {
  readonly __typename: "RangeAssessmentQuestionNode";
  readonly answers: AssessmentAnswerConnection;
  readonly answersExist: Scalars["Boolean"]["output"];
  readonly archived: Scalars["Boolean"]["output"];
  readonly assessmentType: AssessmentType;
  readonly canDelete: PermissionNode;
  readonly canRead: PermissionNode;
  readonly canUpdate: PermissionNode;
  readonly categories: AssessmentQuestionCategoryNodeConnection;
  readonly created: Scalars["DateTime"]["output"];
  readonly creator?: Maybe<UserNode>;
  readonly description?: Maybe<Scalars["JSONString"]["output"]>;
  readonly endValue: Scalars["Int"]["output"];
  readonly id: Scalars["Int"]["output"];
  readonly isCommentMandatory: Scalars["Boolean"]["output"];
  readonly labelDescriptions: ReadonlyArray<Maybe<Scalars["String"]["output"]>>;
  readonly labels: ReadonlyArray<Maybe<Scalars["String"]["output"]>>;
  readonly organization: OrganizationNode;
  readonly questionType: AssessmentQuestionType;
  readonly responseVisibility: AssessmentQuestionResponseVisibility;
  readonly responses: AssessmentQuestionResponses;
  readonly startValue: Scalars["Int"]["output"];
  readonly title: Scalars["String"]["output"];
  readonly updated: Scalars["DateTime"]["output"];
};

export type RangeAssessmentQuestionNodeAnswersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RangeAssessmentQuestionNodeCategoriesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RatingAnswerNode = DjangoNode &
  Node & {
    readonly __typename: "RatingAnswerNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly created: Scalars["DateTime"]["output"];
    readonly createdFromArtifact?: Maybe<RatingArtifactNode>;
    readonly createdInMeeting?: Maybe<MeetingNode>;
    readonly creator: UserNode;
    readonly id: Scalars["ID"]["output"];
    readonly integerAnswer: Scalars["Int"]["output"];
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly rating?: Maybe<RatingNode>;
    readonly updated: Scalars["DateTime"]["output"];
  };

export type RatingAnswerNodeConnection = {
  readonly __typename: "RatingAnswerNodeConnection";
  readonly averageRating?: Maybe<Scalars["Float"]["output"]>;
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<RatingAnswerNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars["Int"]["output"]>;
};

/** A Relay edge containing a `RatingAnswerNode` and its cursor. */
export type RatingAnswerNodeEdge = {
  readonly __typename: "RatingAnswerNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<RatingAnswerNode>;
};

export type RatingArtifactNode = ArtifactInterface & {
  readonly __typename: "RatingArtifactNode";
  readonly activities: ArtifactActivityNodeConnection;
  readonly answers?: Maybe<RatingAnswerNodeConnection>;
  readonly artifactType: ArtifactType;
  readonly assignableUsers?: Maybe<UserNodeConnection>;
  readonly canAnswer?: Maybe<Scalars["Boolean"]["output"]>;
  readonly canDelete: PermissionNode;
  readonly canRead: PermissionNode;
  readonly canUpdate: PermissionNode;
  readonly canViewAnswers?: Maybe<Scalars["Boolean"]["output"]>;
  readonly commentCount?: Maybe<Scalars["Int"]["output"]>;
  readonly comments?: Maybe<CommentNodeConnection>;
  readonly created: Scalars["DateTime"]["output"];
  readonly createdInMeeting?: Maybe<MeetingNode>;
  readonly creator?: Maybe<UserNode>;
  readonly description?: Maybe<Scalars["JSONString"]["output"]>;
  readonly eventChannelName?: Maybe<Scalars["String"]["output"]>;
  readonly id: Scalars["Int"]["output"];
  readonly isStale: Scalars["Boolean"]["output"];
  readonly organization?: Maybe<OrganizationNode>;
  readonly rating?: Maybe<RatingNode>;
  readonly relatedArtifacts?: Maybe<ArtifactToArtifactConnection>;
  readonly title?: Maybe<Scalars["String"]["output"]>;
  readonly topics?: Maybe<ArtifactToTopicConnection>;
  readonly updated?: Maybe<Scalars["DateTime"]["output"]>;
  readonly uuid?: Maybe<Scalars["String"]["output"]>;
  readonly websocketToken?: Maybe<Scalars["String"]["output"]>;
};

export type RatingArtifactNodeActivitiesArgs = {
  activityType?: InputMaybe<ArtifactActivityType>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RatingArtifactNodeAnswersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  creator?: InputMaybe<Scalars["Int"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
};

export type RatingArtifactNodeAssignableUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  hasNoManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActiveOrInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  reportIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type RatingArtifactNodeCommentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  artifactId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  isInlineComment?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  textUuid?: InputMaybe<Scalars["String"]["input"]>;
  topicId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RatingArtifactNodeRelatedArtifactsArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RatingArtifactNodeTopicsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assignee?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasActionItems?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasArtifacts?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDecisions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDocuments?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasGoals?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInFlowId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInMeetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<TopicState>;
  titleContains?: InputMaybe<Scalars["String"]["input"]>;
  topicIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type RatingNode = DjangoNode &
  Node & {
    readonly __typename: "RatingNode";
    readonly answers?: Maybe<RatingAnswerNodeConnection>;
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly created: Scalars["DateTime"]["output"];
    readonly creator?: Maybe<UserNode>;
    readonly description: Scalars["JSONString"]["output"];
    readonly endValue: Scalars["Int"]["output"];
    readonly id: Scalars["ID"]["output"];
    readonly labels: ReadonlyArray<Maybe<Scalars["String"]["output"]>>;
    readonly organization?: Maybe<OrganizationNode>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly startValue: Scalars["Int"]["output"];
    readonly title: Scalars["String"]["output"];
    readonly updated: Scalars["DateTime"]["output"];
  };

export type RatingNodeAnswersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  creator?: InputMaybe<Scalars["Int"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
};

export type RatingNodeConnection = {
  readonly __typename: "RatingNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<RatingNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `RatingNode` and its cursor. */
export type RatingNodeEdge = {
  readonly __typename: "RatingNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<RatingNode>;
};

export type RecognitionArtifactConnection = {
  readonly __typename: "RecognitionArtifactConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<RecognitionArtifactEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `RecognitionArtifact` and its cursor. */
export type RecognitionArtifactEdge = {
  readonly __typename: "RecognitionArtifactEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<RecognitionArtifactNode>;
};

export type RecognitionArtifactNode = ArtifactInterface & {
  readonly __typename: "RecognitionArtifactNode";
  readonly activities: ArtifactActivityNodeConnection;
  readonly artifactType: ArtifactType;
  readonly assignableUsers?: Maybe<UserNodeConnection>;
  readonly canDelete: PermissionNode;
  readonly canRead: PermissionNode;
  readonly canUpdate: PermissionNode;
  readonly commentCount?: Maybe<Scalars["Int"]["output"]>;
  readonly comments?: Maybe<CommentNodeConnection>;
  readonly created: Scalars["DateTime"]["output"];
  readonly createdInMeeting?: Maybe<MeetingNode>;
  readonly creator?: Maybe<UserNode>;
  readonly description?: Maybe<Scalars["JSONString"]["output"]>;
  readonly eventChannelName?: Maybe<Scalars["String"]["output"]>;
  readonly id: Scalars["Int"]["output"];
  readonly isStale: Scalars["Boolean"]["output"];
  readonly organization?: Maybe<OrganizationNode>;
  readonly recipients?: Maybe<UserNodeConnection>;
  readonly recognitionCoreValue?: Maybe<RecognitionCoreValueNode>;
  readonly recognitionVisibility: RecognitionVisibility;
  readonly relatedArtifacts?: Maybe<ArtifactToArtifactConnection>;
  readonly title?: Maybe<Scalars["String"]["output"]>;
  readonly topics?: Maybe<ArtifactToTopicConnection>;
  readonly updated?: Maybe<Scalars["DateTime"]["output"]>;
  readonly uuid?: Maybe<Scalars["String"]["output"]>;
  readonly websocketToken?: Maybe<Scalars["String"]["output"]>;
};

export type RecognitionArtifactNodeActivitiesArgs = {
  activityType?: InputMaybe<ArtifactActivityType>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RecognitionArtifactNodeAssignableUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  hasNoManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActiveOrInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  reportIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type RecognitionArtifactNodeCommentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  artifactId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  isInlineComment?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  textUuid?: InputMaybe<Scalars["String"]["input"]>;
  topicId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RecognitionArtifactNodeRecipientsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  hasNoManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActiveOrInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  reportIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type RecognitionArtifactNodeRelatedArtifactsArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RecognitionArtifactNodeTopicsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assignee?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasActionItems?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasArtifacts?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDecisions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDocuments?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasGoals?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInFlowId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInMeetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<TopicState>;
  titleContains?: InputMaybe<Scalars["String"]["input"]>;
  topicIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type RecognitionCoreValueNode = DjangoNode &
  Node & {
    readonly __typename: "RecognitionCoreValueNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly deleted: Scalars["Boolean"]["output"];
    readonly deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
    readonly deletedBy?: Maybe<UserNode>;
    readonly description: Scalars["String"]["output"];
    readonly emoji?: Maybe<Scalars["String"]["output"]>;
    readonly id: Scalars["ID"]["output"];
    readonly image?: Maybe<Scalars["String"]["output"]>;
    readonly organization?: Maybe<OrganizationNode>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly recognitions?: Maybe<ArtifactConnection>;
    readonly status?: Maybe<RecognitionCoreValueStatus>;
    readonly title: Scalars["String"]["output"];
  };

export type RecognitionCoreValueNodeRecognitionsArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RecognitionCoreValueNodeConnection = {
  readonly __typename: "RecognitionCoreValueNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<RecognitionCoreValueNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `RecognitionCoreValueNode` and its cursor. */
export type RecognitionCoreValueNodeEdge = {
  readonly __typename: "RecognitionCoreValueNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<RecognitionCoreValueNode>;
};

/** An enumeration. */
export enum RecognitionCoreValueStatus {
  Active = "active",
  Inactive = "inactive",
}

export type RecognitionStatsNode = {
  readonly __typename: "RecognitionStatsNode";
  readonly recognitionCount?: Maybe<Scalars["Int"]["output"]>;
  readonly userWhoGaveMostRecognition?: Maybe<UserNode>;
  readonly userWhoGaveMostRecognitionCount?: Maybe<Scalars["Int"]["output"]>;
  readonly userWhoReceivedMostRecognition?: Maybe<UserNode>;
  readonly userWhoReceivedMostRecognitionCount?: Maybe<
    Scalars["Int"]["output"]
  >;
};

/** An enumeration. */
export enum RecognitionVisibility {
  Private = "private",
  Public = "public",
}

export type RelationshipConnection = {
  readonly __typename: "RelationshipConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<RelationshipEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `Relationship` and its cursor. */
export type RelationshipEdge = {
  readonly __typename: "RelationshipEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<RelationshipNode>;
};

export type RelationshipInput = {
  readonly otherUserId: Scalars["Int"]["input"];
  readonly relationshipType: RelationshipType;
};

export type RelationshipNode = DjangoNode &
  Node & {
    readonly __typename: "RelationshipNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly created: Scalars["DateTime"]["output"];
    readonly creator?: Maybe<UserNode>;
    readonly id: Scalars["ID"]["output"];
    readonly lastUpdatedBy?: Maybe<UserNode>;
    readonly meetingFrequency?: Maybe<FrequencyNode>;
    readonly organization?: Maybe<OrganizationNode>;
    readonly otherUser?: Maybe<UserNode>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly primaryRelationshipType?: Maybe<RelationshipType>;
    readonly primaryUser?: Maybe<UserNode>;
    readonly relationshipWithOtherUser?: Maybe<RelationshipType>;
    readonly secondaryUser?: Maybe<UserNode>;
    readonly updated: Scalars["DateTime"]["output"];
  };

export type RelationshipNodeOtherUserArgs = {
  forUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RelationshipNodeRelationshipWithOtherUserArgs = {
  forUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RelationshipNodeConnection = {
  readonly __typename: "RelationshipNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<RelationshipNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `RelationshipNode` and its cursor. */
export type RelationshipNodeEdge = {
  readonly __typename: "RelationshipNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<RelationshipNode>;
};

/** An enumeration. */
export enum RelationshipType {
  ManagedBy = "managed_by",
  Manages = "manages",
  MentoredBy = "mentored_by",
  Mentors = "mentors",
}

export type RelevantSectionNode = {
  readonly __typename: "RelevantSectionNode";
  readonly sectionId?: Maybe<Scalars["Int"]["output"]>;
  readonly sectionType?: Maybe<SectionDelimiterType>;
};

export type RemoveAsanaIntegrationMutation = {
  readonly __typename: "RemoveAsanaIntegrationMutation";
  readonly user?: Maybe<UserNode>;
};

export type RemoveBotFromMeetingMutation = {
  readonly __typename: "RemoveBotFromMeetingMutation";
  readonly meeting?: Maybe<MeetingNode>;
};

export type RemoveClickupIntegrationMutation = {
  readonly __typename: "RemoveClickupIntegrationMutation";
  readonly user?: Maybe<UserNode>;
};

export type RemoveFavouriteArtifact = {
  readonly __typename: "RemoveFavouriteArtifact";
  readonly artifact?: Maybe<ArtifactInterface>;
  readonly user?: Maybe<UserNode>;
};

export type RemoveGitHubIntegrationMutation = {
  readonly __typename: "RemoveGitHubIntegrationMutation";
  readonly user?: Maybe<UserNode>;
};

export type RemoveHubspotIntegrationMutation = {
  readonly __typename: "RemoveHubspotIntegrationMutation";
  readonly user?: Maybe<UserNode>;
};

export type RemoveIntegrationCredentialsMutation = {
  readonly __typename: "RemoveIntegrationCredentialsMutation";
  readonly user?: Maybe<UserNode>;
};

export type RemoveJiraIntegrationMutation = {
  readonly __typename: "RemoveJiraIntegrationMutation";
  readonly user?: Maybe<UserNode>;
};

export type RemoveOrganizationGitHubIntegrationMutation = {
  readonly __typename: "RemoveOrganizationGitHubIntegrationMutation";
  readonly organization?: Maybe<OrganizationNode>;
};

export type RemoveSalesforceIntegrationMutation = {
  readonly __typename: "RemoveSalesforceIntegrationMutation";
  readonly user?: Maybe<UserNode>;
};

export type RemoveSlackIntegrationMutation = {
  readonly __typename: "RemoveSlackIntegrationMutation";
  readonly organization?: Maybe<OrganizationNode>;
};

export type RemoveTeamMemberMutation = {
  readonly __typename: "RemoveTeamMemberMutation";
  readonly team?: Maybe<TeamNode>;
  readonly user?: Maybe<UserNode>;
};

export type RemoveTemplateSeriesFromMeetingMutation = {
  readonly __typename: "RemoveTemplateSeriesFromMeetingMutation";
  readonly meetingGroup?: Maybe<MeetingGroupNode>;
};

export type RemoveUserFromOrganizationMutation = {
  readonly __typename: "RemoveUserFromOrganizationMutation";
  readonly org?: Maybe<OrganizationNode>;
  readonly userToRemove?: Maybe<UserNode>;
};

export type RemoveZoomIntegrationMutation = {
  readonly __typename: "RemoveZoomIntegrationMutation";
  readonly user?: Maybe<UserNode>;
};

export type ReopenAssessmentMutation = {
  readonly __typename: "ReopenAssessmentMutation";
  readonly assessment: AssessmentNode;
};

export type ReorderArtifactMutation = {
  readonly __typename: "ReorderArtifactMutation";
  readonly artifact?: Maybe<BaseArtifactNode>;
};

export type ReorderTopicMutation = {
  readonly __typename: "ReorderTopicMutation";
  readonly afterTopic?: Maybe<TopicNode>;
  readonly beforeTopic?: Maybe<TopicNode>;
  readonly meeting?: Maybe<MeetingNode>;
  readonly topic?: Maybe<TopicNode>;
};

export type ReportFilterInput = {
  readonly type: ReportFilterType;
  readonly values: ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>;
};

/** An enumeration. */
export enum ReportFilterType {
  DateRange = "date_range",
  TeamId = "team_id",
  UserId = "user_id",
}

export type ReportResponseNode = {
  readonly __typename: "ReportResponseNode";
  readonly data: ReadonlyArray<Maybe<ReportResponseRowNode>>;
  readonly totalValues: ReadonlyArray<Maybe<Scalars["Float"]["output"]>>;
};

export type ReportResponseRowNode = {
  readonly __typename: "ReportResponseRowNode";
  readonly attributeValues: ReadonlyArray<Maybe<Scalars["String"]["output"]>>;
  readonly metricValues: ReadonlyArray<Maybe<Scalars["Float"]["output"]>>;
};

export type RequestExportMutation = {
  readonly __typename: "RequestExportMutation";
  readonly user?: Maybe<UserNode>;
};

export type RoleResponsibilityInput = {
  readonly content?: InputMaybe<Scalars["String"]["input"]>;
  readonly id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type SalesforceKpiIntegrationInput = {
  readonly kpiFieldName?: InputMaybe<Scalars["String"]["input"]>;
  readonly soqlQuery?: InputMaybe<Scalars["String"]["input"]>;
};

export type SalesforceKpiIntegrationNode = KpiIntegrationInterface & {
  readonly __typename: "SalesforceKPIIntegrationNode";
  readonly created?: Maybe<Scalars["DateTime"]["output"]>;
  readonly creator?: Maybe<UserNode>;
  readonly id: Scalars["Int"]["output"];
  readonly integrationType?: Maybe<KpiIntegrationType>;
  readonly kpi?: Maybe<KpiNode>;
  readonly kpiFieldName: Scalars["String"]["output"];
  readonly organizationIntegrationConnection?: Maybe<OrganizationIntegrationNode>;
  readonly soqlQuery: Scalars["String"]["output"];
  readonly updated?: Maybe<Scalars["DateTime"]["output"]>;
};

export type SalesforceOpportunityNode = InfoCardInterface & {
  readonly __typename: "SalesforceOpportunityNode";
  readonly amount?: Maybe<Scalars["Float"]["output"]>;
  readonly assignees: ReadonlyArray<Maybe<ExternalProfileNode>>;
  readonly closedAt?: Maybe<Scalars["DateTime"]["output"]>;
  readonly created: Scalars["DateTime"]["output"];
  readonly description?: Maybe<Scalars["String"]["output"]>;
  readonly id: Scalars["ID"]["output"];
  readonly providerName?: Maybe<Scalars["String"]["output"]>;
  readonly status?: Maybe<InfoTaskStatus>;
  readonly title: Scalars["String"]["output"];
  readonly url: Scalars["String"]["output"];
};

export type SearchCsvExportMutation = {
  readonly __typename: "SearchCSVExportMutation";
  readonly user?: Maybe<UserNode>;
};

export type SearchConnection = {
  readonly __typename: "SearchConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<SearchEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `Search` and its cursor. */
export type SearchEdge = {
  readonly __typename: "SearchEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<SearchUnion>;
};

export type SearchOptions = {
  readonly artifactFilters?: InputMaybe<ArtifactFilterInput>;
  /** Constrains results to items associated with the specified flow */
  readonly flowId?: InputMaybe<Scalars["Int"]["input"]>;
  /** By default, github searches will be restricted to repos in the organization linked to Topicflow */
  readonly githubSearchEverywhere?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter hubspot deals by their stage id */
  readonly hubspotStageId?: InputMaybe<Scalars["String"]["input"]>;
  /** Constrain results to items associated with the specified meeting group */
  readonly meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Constrains results to items associated with draft or non-draft meetings */
  readonly meetingIsDraft?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly meetingIsFormalOneonone?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly meetingParticipantCount?: InputMaybe<Scalars["Int"]["input"]>;
  readonly meetingParticipants?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  readonly meetingsAfterDatetime?: InputMaybe<Scalars["DateTime"]["input"]>;
  readonly meetingsBeforeDatetime?: InputMaybe<Scalars["DateTime"]["input"]>;
  readonly resultTypes?: InputMaybe<SearchResultTypes>;
};

export type SearchResultTypes = {
  readonly artifacts?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly comments?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly flows?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly githubIssues?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly hubspotDeals?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly jiraIssues?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly meetings?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly topics?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly users?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SearchUnion =
  | BaseArtifactNode
  | CommentNode
  | FlowNode
  | GithubIssueNode
  | HubspotDealNode
  | JiraIssueNode
  | MeetingWithRelevantSectionsNode
  | TopicNode
  | UserNode;

/** An enumeration. */
export enum SectionDelimiterType {
  ActionItem = "action_item",
  Decision = "decision",
  Goal = "goal",
  MeetingMetadata = "meeting_metadata",
  Topic = "topic",
}

export type SendMessageToChatbotMutation = {
  readonly __typename: "SendMessageToChatbotMutation";
  readonly chatSession?: Maybe<ChatSessionNode>;
};

export type SetAssessmentNominationsMutation = {
  readonly __typename: "SetAssessmentNominationsMutation";
  readonly assessmentOpenForNomination?: Maybe<AssessmentOpenForNominationNode>;
  readonly assessmentTemplate?: Maybe<AssessmentGroupNode>;
  readonly complianceProgram?: Maybe<ComplianceProgramNode>;
};

export type SetFavouriteGroupsMutation = {
  readonly __typename: "SetFavouriteGroupsMutation";
  readonly me?: Maybe<UserNode>;
};

export type SetMeetingAgendaAsTemplateMutation = {
  readonly __typename: "SetMeetingAgendaAsTemplateMutation";
  readonly meeting?: Maybe<MeetingNode>;
};

export type SetMeetingLockedMutation = {
  readonly __typename: "SetMeetingLockedMutation";
  /** The modified meeting */
  readonly meeting?: Maybe<MeetingNode>;
};

export type SetRelationshipMutation = {
  readonly __typename: "SetRelationshipMutation";
  readonly me?: Maybe<UserNode>;
  readonly relationship?: Maybe<RelationshipNode>;
};

export type SetRelationshipsMutation = {
  readonly __typename: "SetRelationshipsMutation";
  readonly me?: Maybe<UserNode>;
  readonly relationships?: Maybe<RelationshipConnection>;
};

export type SetRelationshipsMutationRelationshipsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type SlackChannelConnection = {
  readonly __typename: "SlackChannelConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<SlackChannelEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `SlackChannel` and its cursor. */
export type SlackChannelEdge = {
  readonly __typename: "SlackChannelEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<SlackChannelNode>;
};

export type SlackChannelNode = {
  readonly __typename: "SlackChannelNode";
  readonly id?: Maybe<Scalars["String"]["output"]>;
  readonly name?: Maybe<Scalars["String"]["output"]>;
};

export type SlackNotificationConnectionNode = DjangoNode &
  Node & {
    readonly __typename: "SlackNotificationConnectionNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly channel: Scalars["String"]["output"];
    readonly id: Scalars["ID"]["output"];
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly sendEmptyAgendaReminder: Scalars["Boolean"]["output"];
    readonly sendPreparationNotification: Scalars["Boolean"]["output"];
    readonly sendSummaryNotification: Scalars["Boolean"]["output"];
  };

export type SlackNotificationConnectionNodeConnection = {
  readonly __typename: "SlackNotificationConnectionNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<SlackNotificationConnectionNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `SlackNotificationConnectionNode` and its cursor. */
export type SlackNotificationConnectionNodeEdge = {
  readonly __typename: "SlackNotificationConnectionNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<SlackNotificationConnectionNode>;
};

export type SlackNotificationForChannelNode = DjangoNode &
  Node & {
    readonly __typename: "SlackNotificationForChannelNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly channel: Scalars["String"]["output"];
    readonly goalCheckins: Scalars["Boolean"]["output"];
    readonly id: Scalars["ID"]["output"];
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly recognitions: Scalars["Boolean"]["output"];
  };

export type SlackNotificationForChannelNodeConnection = {
  readonly __typename: "SlackNotificationForChannelNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<SlackNotificationForChannelNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `SlackNotificationForChannelNode` and its cursor. */
export type SlackNotificationForChannelNodeEdge = {
  readonly __typename: "SlackNotificationForChannelNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<SlackNotificationForChannelNode>;
};

export type StartCalendarSyncMutation = {
  readonly __typename: "StartCalendarSyncMutation";
  readonly user?: Maybe<UserNode>;
};

export type SubmitOneOnOneMutation = {
  readonly __typename: "SubmitOneOnOneMutation";
  readonly meeting?: Maybe<MeetingNode>;
};

export type SuggestedArtifactNode = DjangoNode &
  Node & {
    readonly __typename: "SuggestedArtifactNode";
    readonly artifactType?: Maybe<ArtifactType>;
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly created: Scalars["DateTime"]["output"];
    readonly description?: Maybe<Scalars["String"]["output"]>;
    readonly hidden: Scalars["Boolean"]["output"];
    readonly id: Scalars["ID"]["output"];
    readonly meeting?: Maybe<MeetingNode>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly suggestedAssignee?: Maybe<UserNode>;
    readonly title: Scalars["String"]["output"];
    readonly transcript?: Maybe<MeetingTranscriptNode>;
    readonly transcriptContext?: Maybe<Scalars["String"]["output"]>;
    readonly updated: Scalars["DateTime"]["output"];
  };

export type SuggestedArtifactNodeConnection = {
  readonly __typename: "SuggestedArtifactNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<SuggestedArtifactNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `SuggestedArtifactNode` and its cursor. */
export type SuggestedArtifactNodeEdge = {
  readonly __typename: "SuggestedArtifactNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<SuggestedArtifactNode>;
};

export type SuggestedMeetingNode = MeetingInterface &
  Node & {
    readonly __typename: "SuggestedMeetingNode";
    /** The ID of the object */
    readonly id: Scalars["ID"]["output"];
    readonly startDate?: Maybe<Scalars["Date"]["output"]>;
  };

export type SuggestedRecognitionNode = {
  readonly __typename: "SuggestedRecognitionNode";
  readonly coreValue?: Maybe<RecognitionCoreValueNode>;
  readonly suggestedAssignee?: Maybe<UserNode>;
  readonly title?: Maybe<Scalars["String"]["output"]>;
};

export type SuggestedTopicNode = DjangoNode &
  Node & {
    readonly __typename: "SuggestedTopicNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly created: Scalars["DateTime"]["output"];
    readonly hidden: Scalars["Boolean"]["output"];
    readonly id: Scalars["ID"]["output"];
    readonly meeting?: Maybe<MeetingNode>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly title: Scalars["String"]["output"];
    readonly topic?: Maybe<TopicNode>;
    readonly updated: Scalars["DateTime"]["output"];
  };

export type SuggestedTopicNodeConnection = {
  readonly __typename: "SuggestedTopicNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<SuggestedTopicNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `SuggestedTopicNode` and its cursor. */
export type SuggestedTopicNodeEdge = {
  readonly __typename: "SuggestedTopicNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<SuggestedTopicNode>;
};

export type SummarizedMeasurements = {
  readonly __typename: "SummarizedMeasurements";
  readonly aggregateMeasurement?: Maybe<Scalars["Float"]["output"]>;
  readonly aggregateMeasurementId?: Maybe<Scalars["String"]["output"]>;
  readonly date?: Maybe<Scalars["Date"]["output"]>;
};

export type SummarizedMeasurementsConnection = {
  readonly __typename: "SummarizedMeasurementsConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<SummarizedMeasurementsEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `SummarizedMeasurements` and its cursor. */
export type SummarizedMeasurementsEdge = {
  readonly __typename: "SummarizedMeasurementsEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<SummarizedMeasurements>;
};

export enum SyncCredentialsTypeEnum {
  Github = "github",
  GoogleCalendar = "google_calendar",
  OutlookCalendar = "outlook_calendar",
  Zoom = "zoom",
}

export type TeamNode = DjangoNode &
  Node & {
    readonly __typename: "TeamNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly created: Scalars["DateTime"]["output"];
    readonly creator?: Maybe<UserNode>;
    readonly id: Scalars["ID"]["output"];
    readonly kpis?: Maybe<KpiNodeConnection>;
    readonly members?: Maybe<UserNodeConnection>;
    readonly organization?: Maybe<OrganizationNode>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly teamGoals?: Maybe<ArtifactConnection>;
    readonly title: Scalars["String"]["output"];
    readonly updated: Scalars["DateTime"]["output"];
  };

export type TeamNodeKpisArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  group?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type TeamNodeMembersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  hasNoManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActiveOrInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  reportIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type TeamNodeTeamGoalsArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TeamNodeConnection = {
  readonly __typename: "TeamNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<TeamNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `TeamNode` and its cursor. */
export type TeamNodeEdge = {
  readonly __typename: "TeamNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<TeamNode>;
};

export type TemplateCategoryNode = DjangoNode &
  Node & {
    readonly __typename: "TemplateCategoryNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly creator?: Maybe<UserNode>;
    readonly id: Scalars["ID"]["output"];
    readonly ordinal?: Maybe<Scalars["Int"]["output"]>;
    readonly organization?: Maybe<OrganizationNode>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly title: Scalars["String"]["output"];
  };

export type TemplateCategoryNodeConnection = {
  readonly __typename: "TemplateCategoryNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<TemplateCategoryNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `TemplateCategoryNode` and its cursor. */
export type TemplateCategoryNodeEdge = {
  readonly __typename: "TemplateCategoryNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<TemplateCategoryNode>;
};

export type TemplateRatingInput = {
  readonly allowAdminVisibility?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly allowManagerAnswers?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly allowManagerVisibility?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly allowMeetingParticipantAnswers?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  readonly allowMeetingParticipantVisibility?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  readonly allowOrganizationVisibility?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  readonly allowReportAnswers?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly allowReportVisibility?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly ratingId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TemplateRatingRequirementNode = DjangoNode &
  Node & {
    readonly __typename: "TemplateRatingRequirementNode";
    readonly allowAdminVisibility: Scalars["Boolean"]["output"];
    readonly allowManagerAnswers: Scalars["Boolean"]["output"];
    readonly allowManagerVisibility: Scalars["Boolean"]["output"];
    readonly allowMeetingParticipantAnswers: Scalars["Boolean"]["output"];
    readonly allowMeetingParticipantVisibility: Scalars["Boolean"]["output"];
    readonly allowOrganizationVisibility: Scalars["Boolean"]["output"];
    readonly allowReportAnswers: Scalars["Boolean"]["output"];
    readonly allowReportVisibility: Scalars["Boolean"]["output"];
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly creator?: Maybe<UserNode>;
    readonly id: Scalars["ID"]["output"];
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly rating?: Maybe<RatingNode>;
  };

export type TemplateRatingRequirementNodeConnection = {
  readonly __typename: "TemplateRatingRequirementNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<TemplateRatingRequirementNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `TemplateRatingRequirementNode` and its cursor. */
export type TemplateRatingRequirementNodeEdge = {
  readonly __typename: "TemplateRatingRequirementNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<TemplateRatingRequirementNode>;
};

export type TemplateRequirementComplianceReportConnection = {
  readonly __typename: "TemplateRequirementComplianceReportConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<TemplateRequirementComplianceReportEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `TemplateRequirementComplianceReport` and its cursor. */
export type TemplateRequirementComplianceReportEdge = {
  readonly __typename: "TemplateRequirementComplianceReportEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<TemplateRequirementComplianceReportNode>;
};

export type TemplateRequirementComplianceReportNode = {
  readonly __typename: "TemplateRequirementComplianceReportNode";
  readonly id?: Maybe<Scalars["Int"]["output"]>;
  readonly manager?: Maybe<UserNode>;
  readonly organization?: Maybe<OrganizationNode>;
  readonly reports?: Maybe<ComplianceReportUserConnection>;
};

export type TemplateRequirementComplianceReportNodeReportsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TemplateRequirementNode = DjangoNode &
  Node & {
    readonly __typename: "TemplateRequirementNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly complianceReport?: Maybe<TemplateRequirementComplianceReportConnection>;
    readonly created: Scalars["DateTime"]["output"];
    readonly creator: UserNode;
    readonly deleted: Scalars["Boolean"]["output"];
    readonly deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
    readonly deletedBy?: Maybe<UserNode>;
    readonly id: Scalars["ID"]["output"];
    readonly organization?: Maybe<OrganizationNode>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly template: TopicTemplateNode;
    readonly topicTemplate?: Maybe<TopicTemplateNode>;
    readonly updated: Scalars["DateTime"]["output"];
  };

export type TemplateRequirementNodeComplianceReportArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  personId?: InputMaybe<Scalars["Int"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
};

export type TemplateRequirementNodeConnection = {
  readonly __typename: "TemplateRequirementNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<TemplateRequirementNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `TemplateRequirementNode` and its cursor. */
export type TemplateRequirementNodeEdge = {
  readonly __typename: "TemplateRequirementNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<TemplateRequirementNode>;
};

export type TemplateSeriesNode = DjangoNode &
  Node & {
    readonly __typename: "TemplateSeriesNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly description: Scalars["String"]["output"];
    readonly id: Scalars["ID"]["output"];
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly title: Scalars["String"]["output"];
    readonly topicTemplates?: Maybe<TemplateSeriesToTopicTemplatesNodeConnection>;
  };

export type TemplateSeriesNodeTopicTemplatesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TemplateSeriesNodeConnection = {
  readonly __typename: "TemplateSeriesNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<TemplateSeriesNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `TemplateSeriesNode` and its cursor. */
export type TemplateSeriesNodeEdge = {
  readonly __typename: "TemplateSeriesNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<TemplateSeriesNode>;
};

export type TemplateSeriesToTopicTemplatesNode = DjangoNode &
  Node & {
    readonly __typename: "TemplateSeriesToTopicTemplatesNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    /** This template should be used every X meeting instances */
    readonly frequency: Scalars["Int"]["output"];
    readonly id: Scalars["ID"]["output"];
    /** This template should be used every X meeting instances, starting at this offset */
    readonly offset: Scalars["Int"]["output"];
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly topicTemplate?: Maybe<TopicTemplateNode>;
  };

export type TemplateSeriesToTopicTemplatesNodeConnection = {
  readonly __typename: "TemplateSeriesToTopicTemplatesNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<TemplateSeriesToTopicTemplatesNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `TemplateSeriesToTopicTemplatesNode` and its cursor. */
export type TemplateSeriesToTopicTemplatesNodeEdge = {
  readonly __typename: "TemplateSeriesToTopicTemplatesNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<TemplateSeriesToTopicTemplatesNode>;
};

export type TemplateTopicInput = {
  readonly defaultFacilitatorNotes?: InputMaybe<Scalars["String"]["input"]>;
  readonly defaultSubjectNotes?: InputMaybe<Scalars["String"]["input"]>;
  readonly description?: InputMaybe<Scalars["String"]["input"]>;
  readonly discussionNotes?: InputMaybe<Scalars["String"]["input"]>;
  readonly id?: InputMaybe<Scalars["Int"]["input"]>;
  readonly includesIndividualNotes?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly includesSharedNotes?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly isMandatory?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly notesRequirement?: InputMaybe<NotesRequirement>;
  readonly title: Scalars["String"]["input"];
};

export type TextAssessmentAnswerNode = AssessmentAnswerInterface & {
  readonly __typename: "TextAssessmentAnswerNode";
  readonly assessment: AssessmentNode;
  readonly canDelete: PermissionNode;
  readonly canRead: PermissionNode;
  readonly canUpdate: PermissionNode;
  readonly comment?: Maybe<Scalars["JSONString"]["output"]>;
  readonly created: Scalars["DateTime"]["output"];
  readonly id: Scalars["Int"]["output"];
  readonly question: AssessmentQuestionInterface;
  readonly responder?: Maybe<UserNode>;
  readonly textAnswer?: Maybe<Scalars["JSONString"]["output"]>;
  readonly updated: Scalars["DateTime"]["output"];
};

export type TextAssessmentQuestionNode = AssessmentQuestionInterface & {
  readonly __typename: "TextAssessmentQuestionNode";
  readonly answers: AssessmentAnswerConnection;
  readonly answersExist: Scalars["Boolean"]["output"];
  readonly archived: Scalars["Boolean"]["output"];
  readonly assessmentType: AssessmentType;
  readonly canDelete: PermissionNode;
  readonly canRead: PermissionNode;
  readonly canUpdate: PermissionNode;
  readonly categories: AssessmentQuestionCategoryNodeConnection;
  readonly created: Scalars["DateTime"]["output"];
  readonly creator?: Maybe<UserNode>;
  readonly description?: Maybe<Scalars["JSONString"]["output"]>;
  readonly id: Scalars["Int"]["output"];
  readonly isCommentMandatory: Scalars["Boolean"]["output"];
  readonly organization: OrganizationNode;
  readonly questionType: AssessmentQuestionType;
  readonly responseVisibility: AssessmentQuestionResponseVisibility;
  readonly responses: AssessmentQuestionResponses;
  readonly title: Scalars["String"]["output"];
  readonly updated: Scalars["DateTime"]["output"];
};

export type TextAssessmentQuestionNodeAnswersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TextAssessmentQuestionNodeCategoriesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TitleAutocompleteConnection = {
  readonly __typename: "TitleAutocompleteConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<TitleAutocompleteEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `TitleAutocomplete` and its cursor. */
export type TitleAutocompleteEdge = {
  readonly __typename: "TitleAutocompleteEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<Scalars["String"]["output"]>;
};

export type ToggleIndividualNoteVisibilityMutation = {
  readonly __typename: "ToggleIndividualNoteVisibilityMutation";
  readonly individualNotes?: Maybe<IndividualNoteNodeConnection>;
  readonly topics?: Maybe<TopicNodeConnection>;
};

export type ToggleIndividualNoteVisibilityMutationIndividualNotesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  creator?: InputMaybe<Scalars["Int"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  topicId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ToggleIndividualNoteVisibilityMutationTopicsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assignee?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasActionItems?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasArtifacts?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDecisions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDocuments?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasGoals?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInFlowId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInMeetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<TopicState>;
  titleContains?: InputMaybe<Scalars["String"]["input"]>;
  topicIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type TopicNode = DjangoNode &
  Node & {
    readonly __typename: "TopicNode";
    readonly artifacts?: Maybe<ArtifactConnection>;
    readonly assignee?: Maybe<UserNode>;
    readonly canDelete: PermissionNode;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate: PermissionNode;
    readonly commentCount?: Maybe<Scalars["Int"]["output"]>;
    readonly comments?: Maybe<CommentNodeConnection>;
    readonly copiedFrom?: Maybe<TopicNode>;
    readonly created: Scalars["DateTime"]["output"];
    readonly creator?: Maybe<UserNode>;
    readonly defaultFacilitatorNotes?: Maybe<Scalars["JSONString"]["output"]>;
    readonly defaultSubjectNotes?: Maybe<Scalars["JSONString"]["output"]>;
    readonly description?: Maybe<Scalars["String"]["output"]>;
    readonly discussionNotes?: Maybe<Scalars["JSONString"]["output"]>;
    readonly eventChannelName?: Maybe<Scalars["String"]["output"]>;
    readonly id: Scalars["ID"]["output"];
    readonly includesIndividualNotes: Scalars["Boolean"]["output"];
    readonly includesIndividualNotesForCurrentUser: Scalars["Boolean"]["output"];
    readonly includesSharedNotes: Scalars["Boolean"]["output"];
    readonly individualNotes?: Maybe<IndividualNoteNodeConnection>;
    readonly isMandatory: Scalars["Boolean"]["output"];
    readonly linkedTemplateTopic?: Maybe<TopicNode>;
    readonly meeting?: Maybe<MeetingNode>;
    readonly notesRequirement: NotesRequirement;
    readonly organization?: Maybe<OrganizationNode>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    /** The most recent linked topic within this meeting series */
    readonly previousTopic?: Maybe<TopicNode>;
    readonly relatedTopics?: Maybe<TopicNodeConnection>;
    readonly relatedTopicsCount?: Maybe<Scalars["Int"]["output"]>;
    readonly relatedTopicsForSidebar?: Maybe<TopicNodeConnection>;
    readonly state?: Maybe<TopicState>;
    readonly title: Scalars["String"]["output"];
    readonly topicTemplate?: Maybe<TopicTemplateNode>;
    readonly topicTemplateId?: Maybe<Scalars["Int"]["output"]>;
    readonly updated: Scalars["DateTime"]["output"];
  };

export type TopicNodeArtifactsArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TopicNodeCommentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  artifactId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  isInlineComment?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  textUuid?: InputMaybe<Scalars["String"]["input"]>;
  topicId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TopicNodeCopiedFromArgs = {
  hasMeeting?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TopicNodeIndividualNotesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  creator?: InputMaybe<Scalars["Int"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  topicId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TopicNodeRelatedTopicsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assignee?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasActionItems?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasArtifacts?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDecisions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDocuments?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasGoals?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInFlowId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInMeetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<TopicState>;
  titleContains?: InputMaybe<Scalars["String"]["input"]>;
  topicIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type TopicNodeRelatedTopicsCountArgs = {
  meetingInMeetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TopicNodeRelatedTopicsForSidebarArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assignee?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasActionItems?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasArtifacts?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDecisions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDocuments?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasGoals?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInFlowId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingInMeetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<TopicState>;
  titleContains?: InputMaybe<Scalars["String"]["input"]>;
  topicIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type TopicNodeConnection = {
  readonly __typename: "TopicNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<TopicNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `TopicNode` and its cursor. */
export type TopicNodeEdge = {
  readonly __typename: "TopicNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<TopicNode>;
};

/** An enumeration. */
export enum TopicState {
  ClosedToDiscussion = "closed_to_discussion",
  Open = "open",
}

export type TopicTemplateComplianceRequirement = {
  readonly id: Scalars["Int"]["input"];
};

export type TopicTemplateNode = DjangoNode &
  Node & {
    readonly __typename: "TopicTemplateNode";
    /** if True, will enable the corresponding flag on any MeetingGroups the template is applied to */
    readonly allowAdminVisibility: Scalars["Boolean"]["output"];
    /** if True, will enable the corresponding flag on any MeetingGroups the template is applied to */
    readonly allowManagementTreeVisibility: Scalars["Boolean"]["output"];
    /** if True, will enable the corresponding flag on any MeetingGroups the template is applied to */
    readonly allowOrgVisibility: Scalars["Boolean"]["output"];
    readonly canDelete: PermissionNode;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate: PermissionNode;
    readonly canUpdateRequirements: Scalars["Boolean"]["output"];
    readonly categories?: Maybe<TemplateCategoryNodeConnection>;
    readonly categoriesList: ReadonlyArray<Maybe<TemplateCategoryNode>>;
    readonly creator?: Maybe<UserNode>;
    readonly description: Scalars["String"]["output"];
    readonly finalizePermissions: FinalizePermissions;
    /** If true, this template is available to all users and organizations. */
    readonly globalTemplate: Scalars["Boolean"]["output"];
    /** Indicates whether this is a required template for at least some users */
    readonly hasRequirements?: Maybe<Scalars["Boolean"]["output"]>;
    readonly id: Scalars["ID"]["output"];
    readonly managerInstructions: Scalars["String"]["output"];
    /** If true, this template was created for a meeting series */
    readonly meetingTemplate: Scalars["Boolean"]["output"];
    readonly oneononeTemplate: Scalars["Boolean"]["output"];
    readonly organization?: Maybe<OrganizationNode>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly publicTemplate: Scalars["Boolean"]["output"];
    readonly ratingRequirements?: Maybe<TemplateRatingRequirementNodeConnection>;
    readonly reportInstructions: Scalars["String"]["output"];
    readonly requirements?: Maybe<TemplateRequirementNodeConnection>;
    readonly title: Scalars["String"]["output"];
    readonly topics?: Maybe<TopicNodeConnection>;
    readonly topicsList: ReadonlyArray<Maybe<TopicNode>>;
    readonly websocketToken?: Maybe<Scalars["String"]["output"]>;
  };

export type TopicTemplateNodeCategoriesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TopicTemplateNodeRatingRequirementsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TopicTemplateNodeRequirementsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TopicTemplateNodeTopicsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TopicTemplateNodeConnection = {
  readonly __typename: "TopicTemplateNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<TopicTemplateNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `TopicTemplateNode` and its cursor. */
export type TopicTemplateNodeEdge = {
  readonly __typename: "TopicTemplateNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<TopicTemplateNode>;
};

/** An enumeration. */
export enum TopicflowIntegrationMetricType {
  ActionItemsCreated = "action_items_created",
  DecisionsCreated = "decisions_created",
  GoalsCreated = "goals_created",
  HoursOfMeetings = "hours_of_meetings",
  NumberOfMeetings = "number_of_meetings",
}

/** An enumeration. */
export enum TopicflowIntegrationTimeBucket {
  Daily = "daily",
  Monthly = "monthly",
  Weekly = "weekly",
}

/** An enumeration. */
export enum TopicflowIntegrationValue {
  AverageOverPeriods = "average_over_periods",
  AveragePerUser = "average_per_user",
  LastTimePeriod = "last_time_period",
}

export type TopicflowKpiIntegrationInput = {
  readonly metricType?: InputMaybe<TopicflowIntegrationMetricType>;
  readonly timeBucket?: InputMaybe<TopicflowIntegrationTimeBucket>;
  /** Only relevant when topicflow_value == average_over_periods */
  readonly timePeriodsForAvgValue?: InputMaybe<Scalars["Int"]["input"]>;
  /** Can be set to GRAPHQL_NONE to include the whole org */
  readonly userId?: InputMaybe<Scalars["Int"]["input"]>;
  readonly value?: InputMaybe<TopicflowIntegrationValue>;
};

export type TopicflowKpiIntegrationNode = KpiIntegrationInterface & {
  readonly __typename: "TopicflowKPIIntegrationNode";
  readonly created?: Maybe<Scalars["DateTime"]["output"]>;
  readonly creator?: Maybe<UserNode>;
  readonly id: Scalars["Int"]["output"];
  readonly integrationType?: Maybe<KpiIntegrationType>;
  readonly kpi?: Maybe<KpiNode>;
  readonly metricType?: Maybe<TopicflowIntegrationMetricType>;
  readonly organizationIntegrationConnection?: Maybe<OrganizationIntegrationNode>;
  readonly timeBucket?: Maybe<TopicflowIntegrationTimeBucket>;
  readonly timePeriodsForAvgValue?: Maybe<Scalars["Int"]["output"]>;
  readonly updated?: Maybe<Scalars["DateTime"]["output"]>;
  readonly user?: Maybe<UserNode>;
  readonly value?: Maybe<TopicflowIntegrationValue>;
};

export type TranscriptHighlightConnection = {
  readonly __typename: "TranscriptHighlightConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<TranscriptHighlightEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `TranscriptHighlight` and its cursor. */
export type TranscriptHighlightEdge = {
  readonly __typename: "TranscriptHighlightEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<TranscriptHighlightNode>;
};

export type TranscriptHighlightNode = {
  readonly __typename: "TranscriptHighlightNode";
  readonly highlight?: Maybe<Scalars["String"]["output"]>;
  readonly id?: Maybe<Scalars["String"]["output"]>;
};

export type UnlinkIntegrationFromOrganizationMutation = {
  readonly __typename: "UnlinkIntegrationFromOrganizationMutation";
  readonly organization?: Maybe<OrganizationNode>;
};

export type UpdateAssessmentDeliveryMutation = {
  readonly __typename: "UpdateAssessmentDeliveryMutation";
  readonly assessmentDelivery: AssessmentDeliveryNode;
};

export type UpdateAttendanceStatusMutation = {
  readonly __typename: "UpdateAttendanceStatusMutation";
  readonly meeting?: Maybe<MeetingNode>;
  readonly meetingGroup?: Maybe<MeetingGroupNode>;
};

export type UpdateMeetingFinalizationStatus = {
  readonly __typename: "UpdateMeetingFinalizationStatus";
  readonly meeting?: Maybe<MeetingNode>;
};

export type UpdateMeetingGroupMutation = {
  readonly __typename: "UpdateMeetingGroupMutation";
  readonly meetingGroup?: Maybe<MeetingGroupNode>;
};

export type UpdateMeetingMutation = {
  readonly __typename: "UpdateMeetingMutation";
  readonly meeting?: Maybe<MeetingNode>;
};

export type UpdateMeetingTranscriptMutation = {
  readonly __typename: "UpdateMeetingTranscriptMutation";
  readonly meeting?: Maybe<MeetingNode>;
};

export type UpdateMeetingTranscriptionStatusMutation = {
  readonly __typename: "UpdateMeetingTranscriptionStatusMutation";
  readonly meeting?: Maybe<MeetingNode>;
};

export type UpdateOrganizationMembershipMutation = {
  readonly __typename: "UpdateOrganizationMembershipMutation";
  readonly member?: Maybe<UserMemberNode>;
};

export type UpdateSuggestedArtifactMutation = {
  readonly __typename: "UpdateSuggestedArtifactMutation";
  readonly suggestedArtifact?: Maybe<SuggestedArtifactNode>;
};

export type UpdateSuggestedTopicMutation = {
  readonly __typename: "UpdateSuggestedTopicMutation";
  readonly meeting?: Maybe<MeetingNode>;
  readonly suggestedTopic?: Maybe<SuggestedTopicNode>;
};

export type UpdateTopicDiscussionNotesMutation = {
  readonly __typename: "UpdateTopicDiscussionNotesMutation";
  readonly topic?: Maybe<TopicNode>;
};

export type UpdateUserMutation = {
  readonly __typename: "UpdateUserMutation";
  readonly user?: Maybe<UserNode>;
};

export type UpdateWorkHistoryMutation = {
  readonly __typename: "UpdateWorkHistoryMutation";
  readonly workHistorySummary?: Maybe<WorkHistorySummaryNode>;
};

export type UserKvItemNode = DjangoNode &
  Node & {
    readonly __typename: "UserKVItemNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly id: Scalars["ID"]["output"];
    readonly key?: Maybe<Scalars["String"]["output"]>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly value?: Maybe<Scalars["JSONString"]["output"]>;
  };

export type UserKvItemNodeConnection = {
  readonly __typename: "UserKVItemNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<UserKvItemNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `UserKVItemNode` and its cursor. */
export type UserKvItemNodeEdge = {
  readonly __typename: "UserKVItemNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<UserKvItemNode>;
};

/** An enumeration. */
export enum UserLastSeen {
  Lte_1Month = "LTE_1_MONTH",
  Lte_1Year = "LTE_1_YEAR",
  Lte_2Month = "LTE_2_MONTH",
  Lte_3Month = "LTE_3_MONTH",
  Lte_7Days = "LTE_7_DAYS",
  Lte_24Hours = "LTE_24_HOURS",
  Never = "NEVER",
  NotRecently = "NOT_RECENTLY",
}

export type UserMemberNode = DjangoNode &
  Node & {
    readonly __typename: "UserMemberNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly employeeId?: Maybe<Scalars["String"]["output"]>;
    readonly id: Scalars["ID"]["output"];
    readonly jobLevel?: Maybe<Scalars["String"]["output"]>;
    readonly membershipStatus?: Maybe<AccountToOrganizationMembershipStatus>;
    readonly organization: OrganizationNode;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly position?: Maybe<Scalars["String"]["output"]>;
    readonly role?: Maybe<OrganizationRole>;
    readonly startDate?: Maybe<Scalars["Date"]["output"]>;
    readonly user?: Maybe<UserNode>;
  };

export type UserMemberNodeConnection = {
  readonly __typename: "UserMemberNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<UserMemberNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `UserMemberNode` and its cursor. */
export type UserMemberNodeEdge = {
  readonly __typename: "UserMemberNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<UserMemberNode>;
};

export type UserNode = DjangoNode &
  Node & {
    readonly __typename: "UserNode";
    readonly activeAssessmentNominationCount: Scalars["Int"]["output"];
    readonly artifacts?: Maybe<ArtifactConnection>;
    readonly avatar?: Maybe<Scalars["String"]["output"]>;
    readonly calendarIsSyncingForFirstTime?: Maybe<
      Scalars["Boolean"]["output"]
    >;
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly directReports: UserNodeConnection;
    readonly email: Scalars["String"]["output"];
    readonly emailPreference?: Maybe<EmailPreference>;
    readonly emails: ReadonlyArray<Scalars["String"]["output"]>;
    readonly emailsWithNonFreeDomains?: Maybe<
      ReadonlyArray<Maybe<Scalars["String"]["output"]>>
    >;
    readonly enableDailyCommitmentEmails?: Maybe<Scalars["Boolean"]["output"]>;
    readonly enableDailySummaryEmails?: Maybe<Scalars["Boolean"]["output"]>;
    readonly enableDraftReminderEmails?: Maybe<Scalars["Boolean"]["output"]>;
    readonly eventLinkInDescriptionPreference?: Maybe<EventDescriptionLinkSetting>;
    readonly explorerBookmarks?: Maybe<ExplorerFilterBookmarkNodeConnection>;
    readonly favouriteArtifacts: ArtifactConnection;
    readonly favouritesGroups?: Maybe<FavouritesGroupNodeConnection>;
    readonly firstName: Scalars["String"]["output"];
    readonly hasAsanaAuth?: Maybe<Scalars["Boolean"]["output"]>;
    readonly hasCalendarSyncError?: Maybe<Scalars["Boolean"]["output"]>;
    readonly hasClickupAuth?: Maybe<Scalars["Boolean"]["output"]>;
    readonly hasGithubAuth?: Maybe<Scalars["Boolean"]["output"]>;
    readonly hasGoogleCalAuth?: Maybe<Scalars["Boolean"]["output"]>;
    readonly hasGoogleSheetsAuth?: Maybe<Scalars["Boolean"]["output"]>;
    readonly hasHubspotAuth?: Maybe<Scalars["Boolean"]["output"]>;
    readonly hasJiraAuth?: Maybe<Scalars["Boolean"]["output"]>;
    readonly hasOrgWithTooManyUsers?: Maybe<Scalars["Boolean"]["output"]>;
    readonly hasOutlookCalAuth?: Maybe<Scalars["Boolean"]["output"]>;
    readonly hasSalesforceAuth?: Maybe<Scalars["Boolean"]["output"]>;
    readonly hasSyncedCalendar?: Maybe<Scalars["Boolean"]["output"]>;
    readonly hasZoomAuth?: Maybe<Scalars["Boolean"]["output"]>;
    readonly id: Scalars["ID"]["output"];
    readonly insights?: Maybe<InsightConnection>;
    readonly invitations?: Maybe<InvitationNodeConnection>;
    readonly kvItems?: Maybe<UserKvItemNodeConnection>;
    readonly lastName: Scalars["String"]["output"];
    readonly lastSeenStr?: Maybe<UserLastSeen>;
    readonly managers: UserNodeConnection;
    readonly meetingPreparationEmailPreference?: Maybe<MeetingAgendaEmailPreference>;
    readonly meetingSummaryEmailPreference?: Maybe<MeetingSummaryEmailPreference>;
    readonly meetings?: Maybe<MeetingNodeConnection>;
    readonly name: Scalars["String"]["output"];
    readonly onlyUserInOrg?: Maybe<Scalars["Boolean"]["output"]>;
    readonly organizationSpecificInfo?: Maybe<UserMemberNode>;
    readonly organizations: OrganizationNodeConnection;
    readonly organizationsAvailableToJoin?: Maybe<
      ReadonlyArray<Maybe<OrganizationInfoNode>>
    >;
    readonly ownedGoals?: Maybe<ArtifactConnection>;
    readonly paidFeatures: PaidFeaturesNode;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly pusherChannelName?: Maybe<Scalars["String"]["output"]>;
    readonly relationshipWithUser?: Maybe<RelationshipNode>;
    readonly relationships?: Maybe<RelationshipNodeConnection>;
    readonly status?: Maybe<UserStatus>;
    readonly syncCredentials?: Maybe<UserSyncCredentialsNodeConnection>;
    readonly teams?: Maybe<TeamNodeConnection>;
    readonly timezone?: Maybe<Scalars["String"]["output"]>;
    readonly tiptapAiJwt?: Maybe<Scalars["String"]["output"]>;
    readonly uiPreferenceCache?: Maybe<Scalars["JSONString"]["output"]>;
    /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
    readonly username: Scalars["String"]["output"];
  };

export type UserNodeArtifactsArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserNodeAvatarArgs = {
  height?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserNodeDirectReportsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  hasNoManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActiveOrInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  reportIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type UserNodeExplorerBookmarksArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  private?: InputMaybe<Scalars["Boolean"]["input"]>;
  title_Icontains?: InputMaybe<Scalars["String"]["input"]>;
  title_Iexact?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserNodeFavouriteArtifactsArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserNodeFavouritesGroupsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserNodeInsightsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserNodeInvitationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<InvitationStatus>;
};

export type UserNodeKvItemsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  key_Startswith?: InputMaybe<Scalars["String"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserNodeManagersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  hasManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  hasNoManagerInOrg?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActiveOrInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  reportIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type UserNodeMeetingsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  endDatetime?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDatetime_Lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  excludeMeetingGroupIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  fromMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  ignored?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFormalOneonone?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Decimal"]["input"]>;
  participantCount?: InputMaybe<Scalars["Int"]["input"]>;
  participants?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  startDatetime?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<EventStatus>;
  status_In?: InputMaybe<ReadonlyArray<InputMaybe<EventStatus>>>;
  templateId?: InputMaybe<Scalars["Int"]["input"]>;
  unscheduled?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UserNodeOrganizationSpecificInfoArgs = {
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserNodeOrganizationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  personal?: InputMaybe<Scalars["Boolean"]["input"]>;
  userIsAdmin?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserNodeOwnedGoalsArgs = {
  actionItemAssignedToMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignee?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemAssignees?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  actionItemDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  actionItemProgress?: InputMaybe<Scalars["Float"]["input"]>;
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemStates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  ancestorsOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  artifactTypes?: InputMaybe<ReadonlyArray<InputMaybe<ArtifactType>>>;
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  assignedToMembersOfOrganizationId?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  completedActionItemsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedActionItemsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForFlow?: InputMaybe<Scalars["Int"]["input"]>;
  completedGoalsForMeetingGroup?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  createdByIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  createdInMeetingId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenders?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackStates?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackState>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  goalContributors?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalDueBetweenDates?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
  >;
  goalInvolvingUsers?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalOwners?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalParentIds?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  goalScope?: InputMaybe<GoalScope>;
  goalScopes?: InputMaybe<ReadonlyArray<InputMaybe<GoalScope>>>;
  goalState?: InputMaybe<GoalState>;
  goalStates?: InputMaybe<ReadonlyArray<InputMaybe<GoalState>>>;
  goalStatus?: InputMaybe<GoalStatus>;
  goalTeams?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  goalVisibility?: InputMaybe<GoalVisibility>;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  groupBy?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  idsToMergeInApolloCache?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValue?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionReceivedByMembersOfTeam?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipients?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
  >;
  relatedToTopic?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserNodeRelationshipWithUserArgs = {
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  otherUserId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserNodeRelationshipsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  primaryRelationshipType?: InputMaybe<RelationshipType>;
  primaryUser?: InputMaybe<Scalars["ID"]["input"]>;
  secondaryUser?: InputMaybe<Scalars["ID"]["input"]>;
};

export type UserNodeSyncCredentialsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserNodeTeamsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  organization?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_Icontains?: InputMaybe<Scalars["String"]["input"]>;
  title_Startswith?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserNodeConnection = {
  readonly __typename: "UserNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<UserNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `UserNode` and its cursor. */
export type UserNodeEdge = {
  readonly __typename: "UserNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<UserNode>;
};

export type UserReportConnection = {
  readonly __typename: "UserReportConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<UserReportEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `UserReport` and its cursor. */
export type UserReportEdge = {
  readonly __typename: "UserReportEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<UserReportNode>;
};

export type UserReportNode = {
  readonly __typename: "UserReportNode";
  readonly lastMeeting?: Maybe<MeetingInfoNode>;
  readonly manager?: Maybe<UserNode>;
  readonly nextMeeting?: Maybe<MeetingInfoNode>;
  readonly user?: Maybe<UserNode>;
};

export type UserSocialAuthNode = DjangoNode &
  Node & {
    readonly __typename: "UserSocialAuthNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly extraData: Scalars["JSONString"]["output"];
    readonly id: Scalars["ID"]["output"];
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly uid: Scalars["String"]["output"];
  };

/** An enumeration. */
export enum UserStatus {
  Active = "active",
  Demo = "demo",
  Inactive = "inactive",
  Invited = "invited",
}

export type UserSyncCredentialsNode = DjangoNode &
  Node & {
    readonly __typename: "UserSyncCredentialsNode";
    readonly calendarSyncMetadata: Scalars["JSONString"]["output"];
    /** for draft meetings, the credentials to use when creating the meeting */
    readonly calendarmeetinggroupSet: CalendarMeetingGroupNodeConnection;
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly credentials: UserSocialAuthNode;
    readonly credentialsUid?: Maybe<Scalars["String"]["output"]>;
    readonly defaultEventVisibility: CoreUserSyncCredentialsByOrgDefaultEventVisibilityChoices;
    readonly googlesheetskpiintegrationSet: ReadonlyArray<GoogleSheetsKpiIntegrationNode>;
    readonly hasCalendarWriteCredentials?: Maybe<Scalars["Boolean"]["output"]>;
    readonly hasValidCalendarCredentials?: Maybe<Scalars["Boolean"]["output"]>;
    readonly id: Scalars["ID"]["output"];
    readonly lastCalendarSyncEnd?: Maybe<Scalars["DateTime"]["output"]>;
    readonly lastCalendarSyncStart?: Maybe<Scalars["DateTime"]["output"]>;
    readonly lastFullSync?: Maybe<Scalars["DateTime"]["output"]>;
    readonly organization?: Maybe<OrganizationNode>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly provider?: Maybe<SyncCredentialsTypeEnum>;
    readonly user?: Maybe<UserNode>;
    readonly webhookExpiration?: Maybe<Scalars["DateTime"]["output"]>;
    readonly webhookId?: Maybe<Scalars["String"]["output"]>;
  };

export type UserSyncCredentialsNodeCalendarmeetinggroupSetArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  ignored?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId?: InputMaybe<Scalars["Decimal"]["input"]>;
};

export type UserSyncCredentialsNodeConnection = {
  readonly __typename: "UserSyncCredentialsNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<UserSyncCredentialsNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `UserSyncCredentialsNode` and its cursor. */
export type UserSyncCredentialsNodeEdge = {
  readonly __typename: "UserSyncCredentialsNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<UserSyncCredentialsNode>;
};

export type WorkHistoryCollaborationConnection = {
  readonly __typename: "WorkHistoryCollaborationConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<WorkHistoryCollaborationEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `WorkHistoryCollaboration` and its cursor. */
export type WorkHistoryCollaborationEdge = {
  readonly __typename: "WorkHistoryCollaborationEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<WorkHistoryCollaborationNode>;
};

export type WorkHistoryCollaborationNode = {
  readonly __typename: "WorkHistoryCollaborationNode";
  readonly collaborationCount: Scalars["Int"]["output"];
  readonly user?: Maybe<UserNode>;
};

export type WorkHistorySummaryInput = {
  readonly sections?: InputMaybe<
    ReadonlyArray<InputMaybe<WorkHistorySummarySectionInput>>
  >;
  readonly summary?: InputMaybe<Scalars["String"]["input"]>;
};

export type WorkHistorySummaryNode = DjangoNode &
  Node & {
    readonly __typename: "WorkHistorySummaryNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly collaborators: WorkHistoryCollaborationConnection;
    readonly created: Scalars["DateTime"]["output"];
    readonly externalEventData: Scalars["JSONString"]["output"];
    readonly id: Scalars["ID"]["output"];
    readonly meetingCount: Scalars["Int"]["output"];
    readonly meetingTimeHours: Scalars["Float"]["output"];
    /** Place for summary subject to override the AI generated content */
    readonly modifiedSummary?: Maybe<Scalars["String"]["output"]>;
    readonly organization?: Maybe<OrganizationNode>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly sections: WorkHistorySummarySectionNodeConnection;
    readonly subject?: Maybe<UserNode>;
    readonly summary: Scalars["String"]["output"];
    readonly summaryEndDate: Scalars["DateTime"]["output"];
    readonly summaryStartDate: Scalars["DateTime"]["output"];
  };

export type WorkHistorySummaryNodeCollaboratorsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type WorkHistorySummaryNodeSectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type WorkHistorySummarySectionInput = {
  readonly content: Scalars["String"]["input"];
  readonly hidden: Scalars["Boolean"]["input"];
  readonly id?: InputMaybe<Scalars["Int"]["input"]>;
  readonly title: Scalars["String"]["input"];
};

export type WorkHistorySummarySectionNode = DjangoNode &
  Node & {
    readonly __typename: "WorkHistorySummarySectionNode";
    readonly aiGenerated: Scalars["Boolean"]["output"];
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly content: Scalars["String"]["output"];
    readonly hidden: Scalars["Boolean"]["output"];
    readonly id: Scalars["ID"]["output"];
    /** Place for summary subject to override the AI generated content */
    readonly modifiedContent?: Maybe<Scalars["String"]["output"]>;
    /** Place for summary subject to override the AI generated content */
    readonly modifiedTitle?: Maybe<Scalars["String"]["output"]>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly title: Scalars["String"]["output"];
  };

export type WorkHistorySummarySectionNodeConnection = {
  readonly __typename: "WorkHistorySummarySectionNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<WorkHistorySummarySectionNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `WorkHistorySummarySectionNode` and its cursor. */
export type WorkHistorySummarySectionNodeEdge = {
  readonly __typename: "WorkHistorySummarySectionNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<WorkHistorySummarySectionNode>;
};

export type WorkflowNode = DjangoNode &
  Node & {
    readonly __typename: "WorkflowNode";
    readonly canDelete?: Maybe<PermissionNode>;
    readonly canRead?: Maybe<PermissionNode>;
    readonly canUpdate?: Maybe<PermissionNode>;
    readonly created?: Maybe<Scalars["DateTime"]["output"]>;
    readonly creator?: Maybe<UserNode>;
    readonly description?: Maybe<Scalars["String"]["output"]>;
    readonly id: Scalars["ID"]["output"];
    readonly organization?: Maybe<OrganizationNode>;
    readonly pk?: Maybe<Scalars["Int"]["output"]>;
    readonly state?: Maybe<WorkflowState>;
    readonly title?: Maybe<Scalars["String"]["output"]>;
    readonly updated?: Maybe<Scalars["DateTime"]["output"]>;
  };

export type WorkflowNodeConnection = {
  readonly __typename: "WorkflowNodeConnection";
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<WorkflowNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** A Relay edge containing a `WorkflowNode` and its cursor. */
export type WorkflowNodeEdge = {
  readonly __typename: "WorkflowNodeEdge";
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  readonly node?: Maybe<WorkflowNode>;
};

/** An enumeration. */
export enum WorkflowState {
  Active = "active",
  Disabled = "disabled",
}

export type ZoomMeetingNode = {
  readonly __typename: "ZoomMeetingNode";
  readonly joinUrl?: Maybe<Scalars["String"]["output"]>;
  readonly meetingId?: Maybe<Scalars["Int"]["output"]>;
};

export type GetAccountQueryVariables = Exact<{ [key: string]: never }>;

export type GetAccountQuery = {
  readonly __typename: "Query";
  readonly me?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly email: string;
    readonly name: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly emailPreference?: EmailPreference | null;
    readonly meetingSummaryEmailPreference?: MeetingSummaryEmailPreference | null;
    readonly timezone?: string | null;
    readonly meetingPreparationEmailPreference?: MeetingAgendaEmailPreference | null;
    readonly enableDailySummaryEmails?: boolean | null;
    readonly enableDailyCommitmentEmails?: boolean | null;
    readonly enableDraftReminderEmails?: boolean | null;
    readonly emails: ReadonlyArray<string>;
    readonly hasAsanaAuth?: boolean | null;
    readonly hasZoomAuth?: boolean | null;
    readonly hasGithubAuth?: boolean | null;
    readonly hasJiraAuth?: boolean | null;
    readonly hasClickupAuth?: boolean | null;
    readonly hasSalesforceAuth?: boolean | null;
    readonly hasHubspotAuth?: boolean | null;
    readonly organizations: {
      readonly __typename: "OrganizationNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "OrganizationNodeEdge";
        readonly node?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
          readonly name: string;
          readonly personal?: boolean | null;
          readonly userMembership?: {
            readonly __typename: "UserMemberNode";
            readonly id: number;
            readonly position?: string | null;
          } | null;
          readonly directorySync?: {
            readonly __typename: "DirectorySyncNode";
            readonly id?: string | null;
            readonly state?: string | null;
            readonly type?: string | null;
            readonly name?: string | null;
          } | null;
          readonly members: {
            readonly __typename: "UserMemberNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "UserMemberNodeEdge";
              readonly node?: {
                readonly __typename: "UserMemberNode";
                readonly id: number;
                readonly user?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    };
    readonly managers: {
      readonly __typename: "UserNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "UserNodeEdge";
        readonly node?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
      } | null>;
    };
    readonly relationships?: {
      readonly __typename: "RelationshipNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "RelationshipNodeEdge";
        readonly node?: {
          readonly __typename: "RelationshipNode";
          readonly id: number;
          readonly relationshipWithOtherUser?: RelationshipType | null;
          readonly organization?: {
            readonly __typename: "OrganizationNode";
            readonly id: number;
          } | null;
          readonly otherUser?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
          } | null;
        } | null;
      } | null>;
    } | null;
    readonly teams?: {
      readonly __typename: "TeamNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "TeamNodeEdge";
        readonly node?: {
          readonly __typename: "TeamNode";
          readonly id: number;
          readonly title: string;
          readonly organization?: {
            readonly __typename: "OrganizationNode";
            readonly id: number;
          } | null;
        } | null;
      } | null>;
    } | null;
    readonly organizationsAvailableToJoin?: ReadonlyArray<{
      readonly __typename: "OrganizationInfoNode";
      readonly organizationId?: number | null;
      readonly organizationName?: string | null;
      readonly organizationMemberCount?: number | null;
    } | null> | null;
    readonly syncCredentials?: {
      readonly __typename: "UserSyncCredentialsNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "UserSyncCredentialsNodeEdge";
        readonly node?: {
          readonly __typename: "UserSyncCredentialsNode";
          readonly id: number;
          readonly provider?: SyncCredentialsTypeEnum | null;
          readonly lastCalendarSyncStart?: string | null;
          readonly lastCalendarSyncEnd?: string | null;
          readonly hasValidCalendarCredentials?: boolean | null;
          readonly hasCalendarWriteCredentials?: boolean | null;
          readonly credentialsUid?: string | null;
          readonly organization?: {
            readonly __typename: "OrganizationNode";
            readonly id: number;
            readonly name: string;
            readonly personal?: boolean | null;
          } | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type GetOrganizationMembersForAccountQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
  search: Scalars["String"]["input"];
  excludeUserIds:
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetOrganizationMembersForAccountQuery = {
  readonly __typename: "Query";
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
    readonly members: {
      readonly __typename: "UserMemberNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "UserMemberNodeEdge";
        readonly node?: {
          readonly __typename: "UserMemberNode";
          readonly id: number;
          readonly user?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
          } | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type JoinOrganizationMutationMutationVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
}>;

export type JoinOrganizationMutationMutation = {
  readonly __typename: "Mutation";
  readonly joinOrganization?: {
    readonly __typename: "JoinOrganizationMutation";
    readonly user?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly syncCredentials?: {
        readonly __typename: "UserSyncCredentialsNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "UserSyncCredentialsNodeEdge";
          readonly node?: {
            readonly __typename: "UserSyncCredentialsNode";
            readonly id: number;
            readonly provider?: SyncCredentialsTypeEnum | null;
            readonly lastCalendarSyncStart?: string | null;
            readonly lastCalendarSyncEnd?: string | null;
            readonly hasValidCalendarCredentials?: boolean | null;
            readonly hasCalendarWriteCredentials?: boolean | null;
            readonly credentialsUid?: string | null;
            readonly organization?: {
              readonly __typename: "OrganizationNode";
              readonly id: number;
              readonly name: string;
              readonly personal?: boolean | null;
            } | null;
          } | null;
        } | null>;
      } | null;
      readonly organizationsAvailableToJoin?: ReadonlyArray<{
        readonly __typename: "OrganizationInfoNode";
        readonly organizationId?: number | null;
        readonly organizationName?: string | null;
        readonly organizationMemberCount?: number | null;
      } | null> | null;
      readonly organizations: {
        readonly __typename: "OrganizationNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "OrganizationNodeEdge";
          readonly node?: {
            readonly __typename: "OrganizationNode";
            readonly id: number;
            readonly name: string;
            readonly personal?: boolean | null;
            readonly userMembership?: {
              readonly __typename: "UserMemberNode";
              readonly id: number;
              readonly position?: string | null;
            } | null;
            readonly directorySync?: {
              readonly __typename: "DirectorySyncNode";
              readonly id?: string | null;
              readonly state?: string | null;
              readonly type?: string | null;
              readonly name?: string | null;
            } | null;
            readonly members: {
              readonly __typename: "UserMemberNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "UserMemberNodeEdge";
                readonly node?: {
                  readonly __typename: "UserMemberNode";
                  readonly id: number;
                  readonly user?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                } | null;
              } | null>;
            };
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type LinkCalendarToOrganizationMutationMutationVariables = Exact<{
  credentialsId: Scalars["Int"]["input"];
  organizationId: Scalars["Int"]["input"];
}>;

export type LinkCalendarToOrganizationMutationMutation = {
  readonly __typename: "Mutation";
  readonly linkCalendarToOrganization?: {
    readonly __typename: "LinkCalendarToOrganizationMutation";
    readonly user?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly syncCredentials?: {
        readonly __typename: "UserSyncCredentialsNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "UserSyncCredentialsNodeEdge";
          readonly node?: {
            readonly __typename: "UserSyncCredentialsNode";
            readonly id: number;
            readonly provider?: SyncCredentialsTypeEnum | null;
            readonly lastCalendarSyncStart?: string | null;
            readonly lastCalendarSyncEnd?: string | null;
            readonly hasValidCalendarCredentials?: boolean | null;
            readonly hasCalendarWriteCredentials?: boolean | null;
            readonly credentialsUid?: string | null;
            readonly organization?: {
              readonly __typename: "OrganizationNode";
              readonly id: number;
              readonly name: string;
              readonly personal?: boolean | null;
            } | null;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type AccountOrganizationFragmentFragment = {
  readonly __typename: "OrganizationNode";
  readonly id: number;
  readonly name: string;
  readonly personal?: boolean | null;
  readonly userMembership?: {
    readonly __typename: "UserMemberNode";
    readonly id: number;
    readonly position?: string | null;
  } | null;
  readonly directorySync?: {
    readonly __typename: "DirectorySyncNode";
    readonly id?: string | null;
    readonly state?: string | null;
    readonly type?: string | null;
    readonly name?: string | null;
  } | null;
  readonly members: {
    readonly __typename: "UserMemberNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "UserMemberNodeEdge";
      readonly node?: {
        readonly __typename: "UserMemberNode";
        readonly id: number;
        readonly user?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
      } | null;
    } | null>;
  };
};

export type RemoveAsanaIntegrationMutationMutationVariables = Exact<{
  [key: string]: never;
}>;

export type RemoveAsanaIntegrationMutationMutation = {
  readonly __typename: "Mutation";
  readonly removeAsanaIntegration?: {
    readonly __typename: "RemoveAsanaIntegrationMutation";
    readonly user?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly hasAsanaAuth?: boolean | null;
    } | null;
  } | null;
};

export type RemoveClickupIntegrationMutationMutationVariables = Exact<{
  [key: string]: never;
}>;

export type RemoveClickupIntegrationMutationMutation = {
  readonly __typename: "Mutation";
  readonly removeClickupIntegration?: {
    readonly __typename: "RemoveClickupIntegrationMutation";
    readonly user?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly hasClickupAuth?: boolean | null;
    } | null;
  } | null;
};

export type RemoveGitHubIntegrationMutationMutationVariables = Exact<{
  [key: string]: never;
}>;

export type RemoveGitHubIntegrationMutationMutation = {
  readonly __typename: "Mutation";
  readonly removeGithubIntegration?: {
    readonly __typename: "RemoveGitHubIntegrationMutation";
    readonly user?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly hasGithubAuth?: boolean | null;
    } | null;
  } | null;
};

export type RemoveHubspotIntegrationMutationMutationVariables = Exact<{
  [key: string]: never;
}>;

export type RemoveHubspotIntegrationMutationMutation = {
  readonly __typename: "Mutation";
  readonly removeHubspotIntegration?: {
    readonly __typename: "RemoveHubspotIntegrationMutation";
    readonly user?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly hasHubspotAuth?: boolean | null;
    } | null;
  } | null;
};

export type RemoveIntegrationCredentialsMutationMutationVariables = Exact<{
  credentialsId: Scalars["Int"]["input"];
}>;

export type RemoveIntegrationCredentialsMutationMutation = {
  readonly __typename: "Mutation";
  readonly removeIntegrationCredentials?: {
    readonly __typename: "RemoveIntegrationCredentialsMutation";
    readonly user?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly syncCredentials?: {
        readonly __typename: "UserSyncCredentialsNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "UserSyncCredentialsNodeEdge";
          readonly node?: {
            readonly __typename: "UserSyncCredentialsNode";
            readonly id: number;
            readonly provider?: SyncCredentialsTypeEnum | null;
            readonly lastCalendarSyncStart?: string | null;
            readonly lastCalendarSyncEnd?: string | null;
            readonly hasValidCalendarCredentials?: boolean | null;
            readonly hasCalendarWriteCredentials?: boolean | null;
            readonly credentialsUid?: string | null;
            readonly organization?: {
              readonly __typename: "OrganizationNode";
              readonly id: number;
              readonly name: string;
              readonly personal?: boolean | null;
            } | null;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type RemoveJiraIntegrationMutationMutationVariables = Exact<{
  [key: string]: never;
}>;

export type RemoveJiraIntegrationMutationMutation = {
  readonly __typename: "Mutation";
  readonly removeJiraIntegration?: {
    readonly __typename: "RemoveJiraIntegrationMutation";
    readonly user?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly hasJiraAuth?: boolean | null;
    } | null;
  } | null;
};

export type RemoveSalesforceIntegrationMutationMutationVariables = Exact<{
  [key: string]: never;
}>;

export type RemoveSalesforceIntegrationMutationMutation = {
  readonly __typename: "Mutation";
  readonly removeSalesforceIntegration?: {
    readonly __typename: "RemoveSalesforceIntegrationMutation";
    readonly user?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly hasSalesforceAuth?: boolean | null;
    } | null;
  } | null;
};

export type RemoveZoomIntegrationMutationMutationVariables = Exact<{
  [key: string]: never;
}>;

export type RemoveZoomIntegrationMutationMutation = {
  readonly __typename: "Mutation";
  readonly removeZoomIntegration?: {
    readonly __typename: "RemoveZoomIntegrationMutation";
    readonly user?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly hasZoomAuth?: boolean | null;
    } | null;
  } | null;
};

export type RequestExportMutationMutationVariables = Exact<{
  [key: string]: never;
}>;

export type RequestExportMutationMutation = {
  readonly __typename: "Mutation";
  readonly requestExport?: {
    readonly __typename: "RequestExportMutation";
    readonly user?: {
      readonly __typename: "UserNode";
      readonly email: string;
    } | null;
  } | null;
};

export type SetRelationshipMutationFromAccountMutationVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
  clearRelationship?: InputMaybe<Scalars["Boolean"]["input"]>;
  otherUserId: Scalars["Int"]["input"];
  userId?: InputMaybe<Scalars["Int"]["input"]>;
  relationshipType?: InputMaybe<RelationshipType>;
}>;

export type SetRelationshipMutationFromAccountMutation = {
  readonly __typename: "Mutation";
  readonly setRelationship?: {
    readonly __typename: "SetRelationshipMutation";
    readonly me?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly relationships?: {
        readonly __typename: "RelationshipNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "RelationshipNodeEdge";
          readonly node?: {
            readonly __typename: "RelationshipNode";
            readonly id: number;
            readonly relationshipWithOtherUser?: RelationshipType | null;
            readonly organization?: {
              readonly __typename: "OrganizationNode";
              readonly id: number;
            } | null;
            readonly otherUser?: {
              readonly __typename: "UserNode";
              readonly id: number;
            } | null;
          } | null;
        } | null>;
      } | null;
      readonly managers: {
        readonly __typename: "UserNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "UserNodeEdge";
          readonly node?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type StartCalendarSyncMutationMutationVariables = Exact<{
  syncCredentialsId: Scalars["Int"]["input"];
  fullSync?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type StartCalendarSyncMutationMutation = {
  readonly __typename: "Mutation";
  readonly startCalendarSync?: {
    readonly __typename: "StartCalendarSyncMutation";
    readonly user?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly syncCredentials?: {
        readonly __typename: "UserSyncCredentialsNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "UserSyncCredentialsNodeEdge";
          readonly node?: {
            readonly __typename: "UserSyncCredentialsNode";
            readonly id: number;
            readonly provider?: SyncCredentialsTypeEnum | null;
            readonly lastCalendarSyncStart?: string | null;
            readonly lastCalendarSyncEnd?: string | null;
            readonly hasValidCalendarCredentials?: boolean | null;
            readonly hasCalendarWriteCredentials?: boolean | null;
            readonly credentialsUid?: string | null;
            readonly organization?: {
              readonly __typename: "OrganizationNode";
              readonly id: number;
              readonly name: string;
              readonly personal?: boolean | null;
            } | null;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type AccountSyncCredentialFragmentFragment = {
  readonly __typename: "UserSyncCredentialsNode";
  readonly id: number;
  readonly provider?: SyncCredentialsTypeEnum | null;
  readonly lastCalendarSyncStart?: string | null;
  readonly lastCalendarSyncEnd?: string | null;
  readonly hasValidCalendarCredentials?: boolean | null;
  readonly hasCalendarWriteCredentials?: boolean | null;
  readonly credentialsUid?: string | null;
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
    readonly name: string;
    readonly personal?: boolean | null;
  } | null;
};

export type UpdateOrganizationMembershipMutationMutationVariables = Exact<{
  userId: Scalars["Int"]["input"];
  organizationId: Scalars["Int"]["input"];
  position?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateOrganizationMembershipMutationMutation = {
  readonly __typename: "Mutation";
  readonly updateOrganizationMembership?: {
    readonly __typename: "UpdateOrganizationMembershipMutation";
    readonly member?: {
      readonly __typename: "UserMemberNode";
      readonly id: number;
      readonly position?: string | null;
    } | null;
  } | null;
};

export type UpdateUserSettingsMutationVariables = Exact<{
  userId: Scalars["Int"]["input"];
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  primaryEmailAddress?: InputMaybe<Scalars["String"]["input"]>;
  emailPreference?: InputMaybe<EmailPreference>;
  meetingSummaryEmailPreference?: InputMaybe<MeetingSummaryEmailPreference>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  meetingPreparationEmailPreference?: InputMaybe<MeetingAgendaEmailPreference>;
  enableDailySummaryEmails?: InputMaybe<Scalars["Boolean"]["input"]>;
  enableDraftReminderEmails?: InputMaybe<Scalars["Boolean"]["input"]>;
  enableDailyCommitmentEmails?: InputMaybe<Scalars["Boolean"]["input"]>;
  eventLinkInDescriptionPreference?: InputMaybe<EventDescriptionLinkSetting>;
}>;

export type UpdateUserSettingsMutation = {
  readonly __typename: "Mutation";
  readonly updateUser?: {
    readonly __typename: "UpdateUserMutation";
    readonly user?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly firstName: string;
      readonly lastName: string;
      readonly emailPreference?: EmailPreference | null;
      readonly email: string;
      readonly meetingSummaryEmailPreference?: MeetingSummaryEmailPreference | null;
      readonly meetingPreparationEmailPreference?: MeetingAgendaEmailPreference | null;
      readonly enableDailySummaryEmails?: boolean | null;
      readonly enableDailyCommitmentEmails?: boolean | null;
      readonly enableDraftReminderEmails?: boolean | null;
      readonly timezone?: string | null;
      readonly eventLinkInDescriptionPreference?: EventDescriptionLinkSetting | null;
    } | null;
  } | null;
};

type ActionItemCollapsibleFragment_ActionItemArtifactNode_Fragment = {
  readonly __typename: "ActionItemArtifactNode";
  readonly isComplete?: boolean | null;
  readonly actionItemState?: number | null;
  readonly dueDate?: string | null;
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly created: string;
  readonly assignee?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly avatar?: string | null;
    readonly name: string;
  } | null;
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
};

type ActionItemCollapsibleFragment_AsanaTaskArtifactNode_Fragment = {
  readonly __typename: "AsanaTaskArtifactNode";
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly created: string;
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
};

type ActionItemCollapsibleFragment_DecisionArtifactNode_Fragment = {
  readonly __typename: "DecisionArtifactNode";
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly created: string;
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
};

type ActionItemCollapsibleFragment_DocumentArtifactNode_Fragment = {
  readonly __typename: "DocumentArtifactNode";
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly created: string;
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
};

type ActionItemCollapsibleFragment_FeedbackArtifactNode_Fragment = {
  readonly __typename: "FeedbackArtifactNode";
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly created: string;
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
};

type ActionItemCollapsibleFragment_GithubIssuesArtifactNode_Fragment = {
  readonly __typename: "GithubIssuesArtifactNode";
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly created: string;
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
};

type ActionItemCollapsibleFragment_GoalArtifactNode_Fragment = {
  readonly __typename: "GoalArtifactNode";
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly created: string;
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
};

type ActionItemCollapsibleFragment_KpiArtifactNode_Fragment = {
  readonly __typename: "KPIArtifactNode";
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly created: string;
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
};

type ActionItemCollapsibleFragment_RatingArtifactNode_Fragment = {
  readonly __typename: "RatingArtifactNode";
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly created: string;
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
};

type ActionItemCollapsibleFragment_RecognitionArtifactNode_Fragment = {
  readonly __typename: "RecognitionArtifactNode";
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly created: string;
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
};

export type ActionItemCollapsibleFragmentFragment =
  | ActionItemCollapsibleFragment_ActionItemArtifactNode_Fragment
  | ActionItemCollapsibleFragment_AsanaTaskArtifactNode_Fragment
  | ActionItemCollapsibleFragment_DecisionArtifactNode_Fragment
  | ActionItemCollapsibleFragment_DocumentArtifactNode_Fragment
  | ActionItemCollapsibleFragment_FeedbackArtifactNode_Fragment
  | ActionItemCollapsibleFragment_GithubIssuesArtifactNode_Fragment
  | ActionItemCollapsibleFragment_GoalArtifactNode_Fragment
  | ActionItemCollapsibleFragment_KpiArtifactNode_Fragment
  | ActionItemCollapsibleFragment_RatingArtifactNode_Fragment
  | ActionItemCollapsibleFragment_RecognitionArtifactNode_Fragment;

export type ChangeActionItemStateMutationMutationVariables = Exact<{
  artifactId: Scalars["Int"]["input"];
  additionalFields: AdditionalArtifactInput;
}>;

export type ChangeActionItemStateMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateArtifact?: {
    readonly __typename: "CreateOrUpdateArtifactMutation";
    readonly artifact?:
      | {
          readonly __typename: "ActionItemArtifactNode";
          readonly actionItemState?: number | null;
          readonly isComplete?: boolean | null;
          readonly id: number;
        }
      | { readonly __typename: "AsanaTaskArtifactNode"; readonly id: number }
      | { readonly __typename: "DecisionArtifactNode"; readonly id: number }
      | { readonly __typename: "DocumentArtifactNode"; readonly id: number }
      | { readonly __typename: "FeedbackArtifactNode"; readonly id: number }
      | { readonly __typename: "GithubIssuesArtifactNode"; readonly id: number }
      | { readonly __typename: "GoalArtifactNode"; readonly id: number }
      | { readonly __typename: "KPIArtifactNode"; readonly id: number }
      | { readonly __typename: "RatingArtifactNode"; readonly id: number }
      | { readonly __typename: "RecognitionArtifactNode"; readonly id: number }
      | null;
  } | null;
};

export type GetActionItemsCollapsibleQueryQueryVariables = Exact<{
  actionItemState?: InputMaybe<Scalars["Int"]["input"]>;
  fetchData: Scalars["Boolean"]["input"];
  assigneeId?: InputMaybe<Scalars["Int"]["input"]>;
  actionItemIsComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  assigneeIds?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  orderContextType?: InputMaybe<Scalars["String"]["input"]>;
  orderContextId?: InputMaybe<Scalars["Int"]["input"]>;
  first: Scalars["Int"]["input"];
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetActionItemsCollapsibleQueryQuery = {
  readonly __typename: "Query";
  readonly artifacts?: {
    readonly __typename: "ArtifactConnection";
    readonly totalCount: number;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly hasNextPage: boolean;
      readonly endCursor?: string | null;
    };
    readonly edges?: ReadonlyArray<{
      readonly __typename: "ArtifactEdge";
      readonly cursor: string;
      readonly node?:
        | {
            readonly __typename: "ActionItemArtifactNode";
            readonly isComplete?: boolean | null;
            readonly actionItemState?: number | null;
            readonly dueDate?: string | null;
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
            readonly created: string;
            readonly assignee?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly avatar?: string | null;
              readonly name: string;
            } | null;
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly organization?: {
              readonly __typename: "OrganizationNode";
              readonly id: number;
            } | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "AsanaTaskArtifactNode";
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
            readonly created: string;
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly organization?: {
              readonly __typename: "OrganizationNode";
              readonly id: number;
            } | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "DecisionArtifactNode";
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
            readonly created: string;
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly organization?: {
              readonly __typename: "OrganizationNode";
              readonly id: number;
            } | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "DocumentArtifactNode";
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
            readonly created: string;
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly organization?: {
              readonly __typename: "OrganizationNode";
              readonly id: number;
            } | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "FeedbackArtifactNode";
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
            readonly created: string;
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly organization?: {
              readonly __typename: "OrganizationNode";
              readonly id: number;
            } | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "GithubIssuesArtifactNode";
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
            readonly created: string;
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly organization?: {
              readonly __typename: "OrganizationNode";
              readonly id: number;
            } | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "GoalArtifactNode";
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
            readonly created: string;
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly organization?: {
              readonly __typename: "OrganizationNode";
              readonly id: number;
            } | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "KPIArtifactNode";
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
            readonly created: string;
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly organization?: {
              readonly __typename: "OrganizationNode";
              readonly id: number;
            } | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "RatingArtifactNode";
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
            readonly created: string;
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly organization?: {
              readonly __typename: "OrganizationNode";
              readonly id: number;
            } | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "RecognitionArtifactNode";
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
            readonly created: string;
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly organization?: {
              readonly __typename: "OrganizationNode";
              readonly id: number;
            } | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | null;
    } | null>;
  } | null;
};

export type ReorderActionItemMutationMutationVariables = Exact<{
  artifactId: Scalars["Int"]["input"];
  beforeArtifactId?: InputMaybe<Scalars["Int"]["input"]>;
  afterArtifactId?: InputMaybe<Scalars["Int"]["input"]>;
  contextType: Scalars["String"]["input"];
  contextId: Scalars["Int"]["input"];
}>;

export type ReorderActionItemMutationMutation = {
  readonly __typename: "Mutation";
  readonly reorderArtifact?: {
    readonly __typename: "ReorderArtifactMutation";
    readonly artifact?: {
      readonly __typename: "BaseArtifactNode";
      readonly id: number;
    } | null;
  } | null;
};

type CreatedArtifactFragment_ActionItemArtifactNode_Fragment = {
  readonly __typename: "ActionItemArtifactNode";
  readonly title?: string | null;
  readonly actionItemState?: number | null;
  readonly isComplete?: boolean | null;
  readonly dueDate?: string | null;
  readonly artifactType: ArtifactType;
  readonly id: number;
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly assignee?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly avatar?: string | null;
    readonly name: string;
  } | null;
};

type CreatedArtifactFragment_AsanaTaskArtifactNode_Fragment = {
  readonly __typename: "AsanaTaskArtifactNode";
  readonly artifactType: ArtifactType;
  readonly id: number;
  readonly title?: string | null;
};

type CreatedArtifactFragment_DecisionArtifactNode_Fragment = {
  readonly __typename: "DecisionArtifactNode";
  readonly decision: string;
  readonly artifactType: ArtifactType;
  readonly id: number;
  readonly title?: string | null;
};

type CreatedArtifactFragment_DocumentArtifactNode_Fragment = {
  readonly __typename: "DocumentArtifactNode";
  readonly artifactType: ArtifactType;
  readonly id: number;
  readonly title?: string | null;
};

type CreatedArtifactFragment_FeedbackArtifactNode_Fragment = {
  readonly __typename: "FeedbackArtifactNode";
  readonly artifactType: ArtifactType;
  readonly id: number;
  readonly title?: string | null;
  readonly recipientsCanView: boolean;
  readonly recipientsManagersCanView: boolean;
  readonly adminsCanView: boolean;
  readonly creatorCanView: boolean;
  readonly feedbackState: FeedbackState;
  readonly message?: any | null;
  readonly created: string;
  readonly creator?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
  readonly feedbackRecipients: {
    readonly __typename: "UserNodeConnection";
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly __typename: "UserNodeEdge";
      readonly node?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly avatar?: string | null;
        readonly name: string;
      } | null;
    } | null>;
  };
  readonly sender: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly avatar?: string | null;
    readonly name: string;
  };
};

type CreatedArtifactFragment_GithubIssuesArtifactNode_Fragment = {
  readonly __typename: "GithubIssuesArtifactNode";
  readonly artifactType: ArtifactType;
  readonly id: number;
  readonly title?: string | null;
};

type CreatedArtifactFragment_GoalArtifactNode_Fragment = {
  readonly __typename: "GoalArtifactNode";
  readonly scope: GoalScope;
  readonly parentGoalId?: number | null;
  readonly artifactType: ArtifactType;
  readonly id: number;
  readonly title?: string | null;
  readonly goalState: GoalState;
  readonly parentGoal?: {
    readonly __typename: "GoalArtifactNode";
    readonly id: number;
    readonly childGoals?: {
      readonly __typename: "GoalArtifactConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "GoalArtifactEdge";
        readonly node?: {
          readonly __typename: "GoalArtifactNode";
          readonly id: number;
        } | null;
      } | null>;
    } | null;
  } | null;
  readonly childGoals?: {
    readonly __typename: "GoalArtifactConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "GoalArtifactEdge";
      readonly node?: {
        readonly __typename: "GoalArtifactNode";
        readonly id: number;
      } | null;
    } | null>;
  } | null;
};

type CreatedArtifactFragment_KpiArtifactNode_Fragment = {
  readonly __typename: "KPIArtifactNode";
  readonly artifactType: ArtifactType;
  readonly id: number;
  readonly title?: string | null;
};

type CreatedArtifactFragment_RatingArtifactNode_Fragment = {
  readonly __typename: "RatingArtifactNode";
  readonly artifactType: ArtifactType;
  readonly id: number;
  readonly title?: string | null;
};

type CreatedArtifactFragment_RecognitionArtifactNode_Fragment = {
  readonly __typename: "RecognitionArtifactNode";
  readonly artifactType: ArtifactType;
  readonly id: number;
  readonly title?: string | null;
  readonly created: string;
  readonly creator?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
  readonly recipients?: {
    readonly __typename: "UserNodeConnection";
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly __typename: "UserNodeEdge";
      readonly node?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly avatar?: string | null;
      } | null;
    } | null>;
  } | null;
  readonly recognitionCoreValue?: {
    readonly __typename: "RecognitionCoreValueNode";
    readonly id: number;
    readonly title: string;
    readonly description: string;
    readonly emoji?: string | null;
    readonly image?: string | null;
  } | null;
};

export type CreatedArtifactFragmentFragment =
  | CreatedArtifactFragment_ActionItemArtifactNode_Fragment
  | CreatedArtifactFragment_AsanaTaskArtifactNode_Fragment
  | CreatedArtifactFragment_DecisionArtifactNode_Fragment
  | CreatedArtifactFragment_DocumentArtifactNode_Fragment
  | CreatedArtifactFragment_FeedbackArtifactNode_Fragment
  | CreatedArtifactFragment_GithubIssuesArtifactNode_Fragment
  | CreatedArtifactFragment_GoalArtifactNode_Fragment
  | CreatedArtifactFragment_KpiArtifactNode_Fragment
  | CreatedArtifactFragment_RatingArtifactNode_Fragment
  | CreatedArtifactFragment_RecognitionArtifactNode_Fragment;

export type CreateArtifactMutationMutationVariables = Exact<{
  artifactType: ArtifactType;
  title: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["JSONString"]["input"]>;
  organizationId: Scalars["Int"]["input"];
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  additionalFields?: InputMaybe<AdditionalArtifactInput>;
}>;

export type CreateArtifactMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateArtifact?: {
    readonly __typename: "CreateOrUpdateArtifactMutation";
    readonly artifact?:
      | {
          readonly __typename: "ActionItemArtifactNode";
          readonly title?: string | null;
          readonly actionItemState?: number | null;
          readonly isComplete?: boolean | null;
          readonly dueDate?: string | null;
          readonly artifactType: ArtifactType;
          readonly id: number;
          readonly organization?: {
            readonly __typename: "OrganizationNode";
            readonly id: number;
          } | null;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly assignee?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly avatar?: string | null;
            readonly name: string;
          } | null;
        }
      | {
          readonly __typename: "AsanaTaskArtifactNode";
          readonly artifactType: ArtifactType;
          readonly id: number;
          readonly title?: string | null;
        }
      | {
          readonly __typename: "DecisionArtifactNode";
          readonly decision: string;
          readonly artifactType: ArtifactType;
          readonly id: number;
          readonly title?: string | null;
        }
      | {
          readonly __typename: "DocumentArtifactNode";
          readonly artifactType: ArtifactType;
          readonly id: number;
          readonly title?: string | null;
        }
      | {
          readonly __typename: "FeedbackArtifactNode";
          readonly artifactType: ArtifactType;
          readonly id: number;
          readonly title?: string | null;
          readonly recipientsCanView: boolean;
          readonly recipientsManagersCanView: boolean;
          readonly adminsCanView: boolean;
          readonly creatorCanView: boolean;
          readonly feedbackState: FeedbackState;
          readonly message?: any | null;
          readonly created: string;
          readonly creator?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
          } | null;
          readonly feedbackRecipients: {
            readonly __typename: "UserNodeConnection";
            readonly totalCount: number;
            readonly edges: ReadonlyArray<{
              readonly __typename: "UserNodeEdge";
              readonly node?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly avatar?: string | null;
                readonly name: string;
              } | null;
            } | null>;
          };
          readonly sender: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly avatar?: string | null;
            readonly name: string;
          };
        }
      | {
          readonly __typename: "GithubIssuesArtifactNode";
          readonly artifactType: ArtifactType;
          readonly id: number;
          readonly title?: string | null;
        }
      | {
          readonly __typename: "GoalArtifactNode";
          readonly scope: GoalScope;
          readonly parentGoalId?: number | null;
          readonly artifactType: ArtifactType;
          readonly id: number;
          readonly title?: string | null;
          readonly goalState: GoalState;
          readonly parentGoal?: {
            readonly __typename: "GoalArtifactNode";
            readonly id: number;
            readonly childGoals?: {
              readonly __typename: "GoalArtifactConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "GoalArtifactEdge";
                readonly node?: {
                  readonly __typename: "GoalArtifactNode";
                  readonly id: number;
                } | null;
              } | null>;
            } | null;
          } | null;
          readonly childGoals?: {
            readonly __typename: "GoalArtifactConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "GoalArtifactEdge";
              readonly node?: {
                readonly __typename: "GoalArtifactNode";
                readonly id: number;
              } | null;
            } | null>;
          } | null;
        }
      | {
          readonly __typename: "KPIArtifactNode";
          readonly artifactType: ArtifactType;
          readonly id: number;
          readonly title?: string | null;
        }
      | {
          readonly __typename: "RatingArtifactNode";
          readonly artifactType: ArtifactType;
          readonly id: number;
          readonly title?: string | null;
        }
      | {
          readonly __typename: "RecognitionArtifactNode";
          readonly artifactType: ArtifactType;
          readonly id: number;
          readonly title?: string | null;
          readonly created: string;
          readonly creator?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
          } | null;
          readonly recipients?: {
            readonly __typename: "UserNodeConnection";
            readonly totalCount: number;
            readonly edges: ReadonlyArray<{
              readonly __typename: "UserNodeEdge";
              readonly node?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
            } | null>;
          } | null;
          readonly recognitionCoreValue?: {
            readonly __typename: "RecognitionCoreValueNode";
            readonly id: number;
            readonly title: string;
            readonly description: string;
            readonly emoji?: string | null;
            readonly image?: string | null;
          } | null;
        }
      | null;
  } | null;
};

export type CreateFeedbackRequestsMutationMutationVariables = Exact<{
  title: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["JSONString"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId: Scalars["Int"]["input"];
  feedbackAdminsCanView?: InputMaybe<Scalars["Boolean"]["input"]>;
  feedbackCreatorCanView?: InputMaybe<Scalars["Boolean"]["input"]>;
  feedbackRecipientsCanView?: InputMaybe<Scalars["Boolean"]["input"]>;
  feedbackRecipientsManagersCanView?: InputMaybe<Scalars["Boolean"]["input"]>;
  feedbackState?: InputMaybe<FeedbackState>;
  feedbackRecipients:
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>;
  feedbackSenderIds:
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>;
}>;

export type CreateFeedbackRequestsMutationMutation = {
  readonly __typename: "Mutation";
  readonly createFeedbackRequests?: {
    readonly __typename: "CreateFeedbackRequestsMutation";
    readonly feedbackArtifacts?: ReadonlyArray<{
      readonly __typename: "FeedbackArtifactNode";
      readonly id: number;
      readonly artifactType: ArtifactType;
      readonly title?: string | null;
      readonly recipientsCanView: boolean;
      readonly recipientsManagersCanView: boolean;
      readonly adminsCanView: boolean;
      readonly creatorCanView: boolean;
      readonly feedbackState: FeedbackState;
      readonly message?: any | null;
      readonly created: string;
      readonly creator?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly avatar?: string | null;
      } | null;
      readonly feedbackRecipients: {
        readonly __typename: "UserNodeConnection";
        readonly totalCount: number;
        readonly edges: ReadonlyArray<{
          readonly __typename: "UserNodeEdge";
          readonly node?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly avatar?: string | null;
            readonly name: string;
          } | null;
        } | null>;
      };
      readonly sender: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly avatar?: string | null;
        readonly name: string;
      };
    } | null> | null;
  } | null;
};

export type GetFullscreenArtifactQueryQueryVariables = Exact<{
  artifactId: Scalars["Int"]["input"];
}>;

export type GetFullscreenArtifactQueryQuery = {
  readonly __typename: "Query";
  readonly artifact:
    | {
        readonly __typename: "ActionItemArtifactNode";
        readonly id: number;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly websocketToken?: string | null;
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
      }
    | {
        readonly __typename: "AsanaTaskArtifactNode";
        readonly id: number;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly websocketToken?: string | null;
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
      }
    | {
        readonly __typename: "DecisionArtifactNode";
        readonly id: number;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly websocketToken?: string | null;
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
      }
    | {
        readonly __typename: "DocumentArtifactNode";
        readonly id: number;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly websocketToken?: string | null;
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
      }
    | {
        readonly __typename: "FeedbackArtifactNode";
        readonly id: number;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly websocketToken?: string | null;
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
      }
    | {
        readonly __typename: "GithubIssuesArtifactNode";
        readonly id: number;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly websocketToken?: string | null;
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
      }
    | {
        readonly __typename: "GoalArtifactNode";
        readonly id: number;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly websocketToken?: string | null;
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
      }
    | {
        readonly __typename: "KPIArtifactNode";
        readonly id: number;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly websocketToken?: string | null;
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
      }
    | {
        readonly __typename: "RatingArtifactNode";
        readonly id: number;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly websocketToken?: string | null;
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
      }
    | {
        readonly __typename: "RecognitionArtifactNode";
        readonly id: number;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly websocketToken?: string | null;
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
      };
};

export type AddFavouriteArtifactMutationMutationVariables = Exact<{
  artifactId: Scalars["Int"]["input"];
}>;

export type AddFavouriteArtifactMutationMutation = {
  readonly __typename: "Mutation";
  readonly addFavouriteArtifact?: {
    readonly __typename: "AddFavouriteArtifact";
    readonly artifact?:
      | { readonly __typename: "ActionItemArtifactNode"; readonly id: number }
      | { readonly __typename: "AsanaTaskArtifactNode"; readonly id: number }
      | { readonly __typename: "DecisionArtifactNode"; readonly id: number }
      | { readonly __typename: "DocumentArtifactNode"; readonly id: number }
      | { readonly __typename: "FeedbackArtifactNode"; readonly id: number }
      | { readonly __typename: "GithubIssuesArtifactNode"; readonly id: number }
      | { readonly __typename: "GoalArtifactNode"; readonly id: number }
      | { readonly __typename: "KPIArtifactNode"; readonly id: number }
      | { readonly __typename: "RatingArtifactNode"; readonly id: number }
      | { readonly __typename: "RecognitionArtifactNode"; readonly id: number }
      | null;
    readonly user?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly favouriteArtifacts: {
        readonly __typename: "ArtifactConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "ArtifactEdge";
          readonly node?:
            | {
                readonly __typename: "ActionItemArtifactNode";
                readonly id: number;
              }
            | {
                readonly __typename: "AsanaTaskArtifactNode";
                readonly id: number;
              }
            | {
                readonly __typename: "DecisionArtifactNode";
                readonly id: number;
              }
            | {
                readonly __typename: "DocumentArtifactNode";
                readonly id: number;
              }
            | {
                readonly __typename: "FeedbackArtifactNode";
                readonly id: number;
              }
            | {
                readonly __typename: "GithubIssuesArtifactNode";
                readonly id: number;
              }
            | { readonly __typename: "GoalArtifactNode"; readonly id: number }
            | { readonly __typename: "KPIArtifactNode"; readonly id: number }
            | { readonly __typename: "RatingArtifactNode"; readonly id: number }
            | {
                readonly __typename: "RecognitionArtifactNode";
                readonly id: number;
              }
            | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type AlignGoalMutationMutationVariables = Exact<{
  goalId: Scalars["Int"]["input"];
  parentGoalId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type AlignGoalMutationMutation = {
  readonly __typename: "Mutation";
  readonly alignGoal?: {
    readonly __typename: "AlignGoalMutation";
    readonly goal?:
      | { readonly __typename: "ActionItemArtifactNode"; readonly id: number }
      | { readonly __typename: "AsanaTaskArtifactNode"; readonly id: number }
      | { readonly __typename: "DecisionArtifactNode"; readonly id: number }
      | { readonly __typename: "DocumentArtifactNode"; readonly id: number }
      | { readonly __typename: "FeedbackArtifactNode"; readonly id: number }
      | { readonly __typename: "GithubIssuesArtifactNode"; readonly id: number }
      | {
          readonly __typename: "GoalArtifactNode";
          readonly id: number;
          readonly childGoals?: {
            readonly __typename: "GoalArtifactConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "GoalArtifactEdge";
              readonly node?: {
                readonly __typename: "GoalArtifactNode";
                readonly id: number;
              } | null;
            } | null>;
          } | null;
          readonly parentGoal?: {
            readonly __typename: "GoalArtifactNode";
            readonly id: number;
            readonly childGoals?: {
              readonly __typename: "GoalArtifactConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "GoalArtifactEdge";
                readonly node?: {
                  readonly __typename: "GoalArtifactNode";
                  readonly id: number;
                } | null;
              } | null>;
            } | null;
          } | null;
        }
      | { readonly __typename: "KPIArtifactNode"; readonly id: number }
      | { readonly __typename: "RatingArtifactNode"; readonly id: number }
      | { readonly __typename: "RecognitionArtifactNode"; readonly id: number }
      | null;
    readonly previousParentGoal?:
      | { readonly __typename: "ActionItemArtifactNode"; readonly id: number }
      | { readonly __typename: "AsanaTaskArtifactNode"; readonly id: number }
      | { readonly __typename: "DecisionArtifactNode"; readonly id: number }
      | { readonly __typename: "DocumentArtifactNode"; readonly id: number }
      | { readonly __typename: "FeedbackArtifactNode"; readonly id: number }
      | { readonly __typename: "GithubIssuesArtifactNode"; readonly id: number }
      | {
          readonly __typename: "GoalArtifactNode";
          readonly id: number;
          readonly childGoals?: {
            readonly __typename: "GoalArtifactConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "GoalArtifactEdge";
              readonly node?: {
                readonly __typename: "GoalArtifactNode";
                readonly id: number;
              } | null;
            } | null>;
          } | null;
          readonly parentGoal?: {
            readonly __typename: "GoalArtifactNode";
            readonly id: number;
          } | null;
        }
      | { readonly __typename: "KPIArtifactNode"; readonly id: number }
      | { readonly __typename: "RatingArtifactNode"; readonly id: number }
      | { readonly __typename: "RecognitionArtifactNode"; readonly id: number }
      | null;
  } | null;
};

export type DeleteGoalKeyResultsMutationVariables = Exact<{
  keyResultId: Scalars["Int"]["input"];
}>;

export type DeleteGoalKeyResultsMutation = {
  readonly __typename: "Mutation";
  readonly deleteKeyResult?: {
    readonly __typename: "DeleteKeyResultMutation";
    readonly keyResult?: {
      readonly __typename: "KeyResultNode";
      readonly id: number;
      readonly title: string;
      readonly startValue: number;
      readonly currentValue: number;
      readonly targetValue: number;
      readonly progressType: GoalProgressType;
      readonly kpi?: {
        readonly __typename: "KPINode";
        readonly id: number;
        readonly title: string;
        readonly currentMeasurement?: {
          readonly __typename: "KPIMeasurementNode";
          readonly id: number;
          readonly measurement: number;
        } | null;
      } | null;
      readonly canUpdate?: {
        readonly __typename: "PermissionNode";
        readonly permission: boolean;
      } | null;
      readonly assignee?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly avatar?: string | null;
        readonly email: string;
      } | null;
    } | null;
    readonly goal?: {
      readonly __typename: "GoalArtifactNode";
      readonly id: number;
      readonly keyResults: {
        readonly __typename: "KeyResultNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "KeyResultNodeEdge";
          readonly node?: {
            readonly __typename: "KeyResultNode";
            readonly id: number;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type ActivityCommentFragmentFragment = {
  readonly __typename: "CommentNode";
  readonly id: number;
  readonly comment?: any | null;
  readonly deleted?: boolean | null;
  readonly creator?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
};

export type ArtifactActivityFragmentFragment = {
  readonly __typename: "ArtifactActivityNode";
  readonly id: number;
  readonly created: string;
  readonly activityType: ArtifactActivityType;
  readonly canDelete?: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
    readonly reason: string;
  } | null;
  readonly canUpdate?: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
    readonly reason: string;
  } | null;
  readonly actor?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
  readonly changedFields?: ReadonlyArray<{
    readonly __typename: "ArtifactChangedFieldNode";
    readonly fieldName?: string | null;
    readonly previousValue?: string | null;
    readonly newValue?: string | null;
    readonly changedFields?: ReadonlyArray<{
      readonly __typename: "ArtifactChangedFieldNode";
      readonly newValue?: string | null;
      readonly previousValue?: string | null;
      readonly fieldName?: string | null;
    } | null> | null;
    readonly newValueObject?:
      | {
          readonly __typename: "BaseArtifactNode";
          readonly id: number;
          readonly title: string;
          readonly artifactType: ArtifactType;
        }
      | {
          readonly __typename: "CommentNode";
          readonly id: number;
          readonly comment?: any | null;
          readonly deleted?: boolean | null;
          readonly creator?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
          } | null;
        }
      | {
          readonly __typename: "KeyResultNode";
          readonly id: number;
          readonly title: string;
        }
      | {
          readonly __typename: "TopicNode";
          readonly id: number;
          readonly title: string;
          readonly meeting?: {
            readonly __typename: "MeetingNode";
            readonly id: number;
            readonly title?: string | null;
            readonly meetingGroupId: number;
          } | null;
        }
      | {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        }
      | null;
    readonly previousValueObject?:
      | {
          readonly __typename: "BaseArtifactNode";
          readonly id: number;
          readonly title: string;
          readonly artifactType: ArtifactType;
        }
      | { readonly __typename: "CommentNode" }
      | {
          readonly __typename: "KeyResultNode";
          readonly id: number;
          readonly title: string;
        }
      | {
          readonly __typename: "TopicNode";
          readonly id: number;
          readonly title: string;
          readonly meeting?: {
            readonly __typename: "MeetingNode";
            readonly id: number;
            readonly title?: string | null;
            readonly meetingGroupId: number;
          } | null;
        }
      | {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        }
      | null;
  } | null> | null;
};

export type GetArtifactActivitiesQueryQueryVariables = Exact<{
  artifactId: Scalars["Int"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetArtifactActivitiesQueryQuery = {
  readonly __typename: "Query";
  readonly artifact:
    | {
        readonly __typename: "ActionItemArtifactNode";
        readonly id: number;
        readonly activities: {
          readonly __typename: "ArtifactActivityNodeConnection";
          readonly totalCount: number;
          readonly pageInfo: {
            readonly __typename: "PageInfo";
            readonly hasNextPage: boolean;
            readonly endCursor?: string | null;
          };
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactActivityNodeEdge";
            readonly node?: {
              readonly __typename: "ArtifactActivityNode";
              readonly id: number;
              readonly created: string;
              readonly activityType: ArtifactActivityType;
              readonly canDelete?: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
                readonly reason: string;
              } | null;
              readonly canUpdate?: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
                readonly reason: string;
              } | null;
              readonly actor?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
              readonly changedFields?: ReadonlyArray<{
                readonly __typename: "ArtifactChangedFieldNode";
                readonly fieldName?: string | null;
                readonly previousValue?: string | null;
                readonly newValue?: string | null;
                readonly changedFields?: ReadonlyArray<{
                  readonly __typename: "ArtifactChangedFieldNode";
                  readonly newValue?: string | null;
                  readonly previousValue?: string | null;
                  readonly fieldName?: string | null;
                } | null> | null;
                readonly newValueObject?:
                  | {
                      readonly __typename: "BaseArtifactNode";
                      readonly id: number;
                      readonly title: string;
                      readonly artifactType: ArtifactType;
                    }
                  | {
                      readonly __typename: "CommentNode";
                      readonly id: number;
                      readonly comment?: any | null;
                      readonly deleted?: boolean | null;
                      readonly creator?: {
                        readonly __typename: "UserNode";
                        readonly id: number;
                        readonly name: string;
                        readonly avatar?: string | null;
                      } | null;
                    }
                  | {
                      readonly __typename: "KeyResultNode";
                      readonly id: number;
                      readonly title: string;
                    }
                  | {
                      readonly __typename: "TopicNode";
                      readonly id: number;
                      readonly title: string;
                      readonly meeting?: {
                        readonly __typename: "MeetingNode";
                        readonly id: number;
                        readonly title?: string | null;
                        readonly meetingGroupId: number;
                      } | null;
                    }
                  | {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    }
                  | null;
                readonly previousValueObject?:
                  | {
                      readonly __typename: "BaseArtifactNode";
                      readonly id: number;
                      readonly title: string;
                      readonly artifactType: ArtifactType;
                    }
                  | { readonly __typename: "CommentNode" }
                  | {
                      readonly __typename: "KeyResultNode";
                      readonly id: number;
                      readonly title: string;
                    }
                  | {
                      readonly __typename: "TopicNode";
                      readonly id: number;
                      readonly title: string;
                      readonly meeting?: {
                        readonly __typename: "MeetingNode";
                        readonly id: number;
                        readonly title?: string | null;
                        readonly meetingGroupId: number;
                      } | null;
                    }
                  | {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    }
                  | null;
              } | null> | null;
            } | null;
          } | null>;
        };
      }
    | {
        readonly __typename: "AsanaTaskArtifactNode";
        readonly id: number;
        readonly activities: {
          readonly __typename: "ArtifactActivityNodeConnection";
          readonly totalCount: number;
          readonly pageInfo: {
            readonly __typename: "PageInfo";
            readonly hasNextPage: boolean;
            readonly endCursor?: string | null;
          };
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactActivityNodeEdge";
            readonly node?: {
              readonly __typename: "ArtifactActivityNode";
              readonly id: number;
              readonly created: string;
              readonly activityType: ArtifactActivityType;
              readonly canDelete?: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
                readonly reason: string;
              } | null;
              readonly canUpdate?: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
                readonly reason: string;
              } | null;
              readonly actor?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
              readonly changedFields?: ReadonlyArray<{
                readonly __typename: "ArtifactChangedFieldNode";
                readonly fieldName?: string | null;
                readonly previousValue?: string | null;
                readonly newValue?: string | null;
                readonly changedFields?: ReadonlyArray<{
                  readonly __typename: "ArtifactChangedFieldNode";
                  readonly newValue?: string | null;
                  readonly previousValue?: string | null;
                  readonly fieldName?: string | null;
                } | null> | null;
                readonly newValueObject?:
                  | {
                      readonly __typename: "BaseArtifactNode";
                      readonly id: number;
                      readonly title: string;
                      readonly artifactType: ArtifactType;
                    }
                  | {
                      readonly __typename: "CommentNode";
                      readonly id: number;
                      readonly comment?: any | null;
                      readonly deleted?: boolean | null;
                      readonly creator?: {
                        readonly __typename: "UserNode";
                        readonly id: number;
                        readonly name: string;
                        readonly avatar?: string | null;
                      } | null;
                    }
                  | {
                      readonly __typename: "KeyResultNode";
                      readonly id: number;
                      readonly title: string;
                    }
                  | {
                      readonly __typename: "TopicNode";
                      readonly id: number;
                      readonly title: string;
                      readonly meeting?: {
                        readonly __typename: "MeetingNode";
                        readonly id: number;
                        readonly title?: string | null;
                        readonly meetingGroupId: number;
                      } | null;
                    }
                  | {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    }
                  | null;
                readonly previousValueObject?:
                  | {
                      readonly __typename: "BaseArtifactNode";
                      readonly id: number;
                      readonly title: string;
                      readonly artifactType: ArtifactType;
                    }
                  | { readonly __typename: "CommentNode" }
                  | {
                      readonly __typename: "KeyResultNode";
                      readonly id: number;
                      readonly title: string;
                    }
                  | {
                      readonly __typename: "TopicNode";
                      readonly id: number;
                      readonly title: string;
                      readonly meeting?: {
                        readonly __typename: "MeetingNode";
                        readonly id: number;
                        readonly title?: string | null;
                        readonly meetingGroupId: number;
                      } | null;
                    }
                  | {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    }
                  | null;
              } | null> | null;
            } | null;
          } | null>;
        };
      }
    | {
        readonly __typename: "DecisionArtifactNode";
        readonly id: number;
        readonly activities: {
          readonly __typename: "ArtifactActivityNodeConnection";
          readonly totalCount: number;
          readonly pageInfo: {
            readonly __typename: "PageInfo";
            readonly hasNextPage: boolean;
            readonly endCursor?: string | null;
          };
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactActivityNodeEdge";
            readonly node?: {
              readonly __typename: "ArtifactActivityNode";
              readonly id: number;
              readonly created: string;
              readonly activityType: ArtifactActivityType;
              readonly canDelete?: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
                readonly reason: string;
              } | null;
              readonly canUpdate?: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
                readonly reason: string;
              } | null;
              readonly actor?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
              readonly changedFields?: ReadonlyArray<{
                readonly __typename: "ArtifactChangedFieldNode";
                readonly fieldName?: string | null;
                readonly previousValue?: string | null;
                readonly newValue?: string | null;
                readonly changedFields?: ReadonlyArray<{
                  readonly __typename: "ArtifactChangedFieldNode";
                  readonly newValue?: string | null;
                  readonly previousValue?: string | null;
                  readonly fieldName?: string | null;
                } | null> | null;
                readonly newValueObject?:
                  | {
                      readonly __typename: "BaseArtifactNode";
                      readonly id: number;
                      readonly title: string;
                      readonly artifactType: ArtifactType;
                    }
                  | {
                      readonly __typename: "CommentNode";
                      readonly id: number;
                      readonly comment?: any | null;
                      readonly deleted?: boolean | null;
                      readonly creator?: {
                        readonly __typename: "UserNode";
                        readonly id: number;
                        readonly name: string;
                        readonly avatar?: string | null;
                      } | null;
                    }
                  | {
                      readonly __typename: "KeyResultNode";
                      readonly id: number;
                      readonly title: string;
                    }
                  | {
                      readonly __typename: "TopicNode";
                      readonly id: number;
                      readonly title: string;
                      readonly meeting?: {
                        readonly __typename: "MeetingNode";
                        readonly id: number;
                        readonly title?: string | null;
                        readonly meetingGroupId: number;
                      } | null;
                    }
                  | {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    }
                  | null;
                readonly previousValueObject?:
                  | {
                      readonly __typename: "BaseArtifactNode";
                      readonly id: number;
                      readonly title: string;
                      readonly artifactType: ArtifactType;
                    }
                  | { readonly __typename: "CommentNode" }
                  | {
                      readonly __typename: "KeyResultNode";
                      readonly id: number;
                      readonly title: string;
                    }
                  | {
                      readonly __typename: "TopicNode";
                      readonly id: number;
                      readonly title: string;
                      readonly meeting?: {
                        readonly __typename: "MeetingNode";
                        readonly id: number;
                        readonly title?: string | null;
                        readonly meetingGroupId: number;
                      } | null;
                    }
                  | {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    }
                  | null;
              } | null> | null;
            } | null;
          } | null>;
        };
      }
    | {
        readonly __typename: "DocumentArtifactNode";
        readonly id: number;
        readonly activities: {
          readonly __typename: "ArtifactActivityNodeConnection";
          readonly totalCount: number;
          readonly pageInfo: {
            readonly __typename: "PageInfo";
            readonly hasNextPage: boolean;
            readonly endCursor?: string | null;
          };
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactActivityNodeEdge";
            readonly node?: {
              readonly __typename: "ArtifactActivityNode";
              readonly id: number;
              readonly created: string;
              readonly activityType: ArtifactActivityType;
              readonly canDelete?: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
                readonly reason: string;
              } | null;
              readonly canUpdate?: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
                readonly reason: string;
              } | null;
              readonly actor?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
              readonly changedFields?: ReadonlyArray<{
                readonly __typename: "ArtifactChangedFieldNode";
                readonly fieldName?: string | null;
                readonly previousValue?: string | null;
                readonly newValue?: string | null;
                readonly changedFields?: ReadonlyArray<{
                  readonly __typename: "ArtifactChangedFieldNode";
                  readonly newValue?: string | null;
                  readonly previousValue?: string | null;
                  readonly fieldName?: string | null;
                } | null> | null;
                readonly newValueObject?:
                  | {
                      readonly __typename: "BaseArtifactNode";
                      readonly id: number;
                      readonly title: string;
                      readonly artifactType: ArtifactType;
                    }
                  | {
                      readonly __typename: "CommentNode";
                      readonly id: number;
                      readonly comment?: any | null;
                      readonly deleted?: boolean | null;
                      readonly creator?: {
                        readonly __typename: "UserNode";
                        readonly id: number;
                        readonly name: string;
                        readonly avatar?: string | null;
                      } | null;
                    }
                  | {
                      readonly __typename: "KeyResultNode";
                      readonly id: number;
                      readonly title: string;
                    }
                  | {
                      readonly __typename: "TopicNode";
                      readonly id: number;
                      readonly title: string;
                      readonly meeting?: {
                        readonly __typename: "MeetingNode";
                        readonly id: number;
                        readonly title?: string | null;
                        readonly meetingGroupId: number;
                      } | null;
                    }
                  | {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    }
                  | null;
                readonly previousValueObject?:
                  | {
                      readonly __typename: "BaseArtifactNode";
                      readonly id: number;
                      readonly title: string;
                      readonly artifactType: ArtifactType;
                    }
                  | { readonly __typename: "CommentNode" }
                  | {
                      readonly __typename: "KeyResultNode";
                      readonly id: number;
                      readonly title: string;
                    }
                  | {
                      readonly __typename: "TopicNode";
                      readonly id: number;
                      readonly title: string;
                      readonly meeting?: {
                        readonly __typename: "MeetingNode";
                        readonly id: number;
                        readonly title?: string | null;
                        readonly meetingGroupId: number;
                      } | null;
                    }
                  | {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    }
                  | null;
              } | null> | null;
            } | null;
          } | null>;
        };
      }
    | {
        readonly __typename: "FeedbackArtifactNode";
        readonly id: number;
        readonly activities: {
          readonly __typename: "ArtifactActivityNodeConnection";
          readonly totalCount: number;
          readonly pageInfo: {
            readonly __typename: "PageInfo";
            readonly hasNextPage: boolean;
            readonly endCursor?: string | null;
          };
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactActivityNodeEdge";
            readonly node?: {
              readonly __typename: "ArtifactActivityNode";
              readonly id: number;
              readonly created: string;
              readonly activityType: ArtifactActivityType;
              readonly canDelete?: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
                readonly reason: string;
              } | null;
              readonly canUpdate?: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
                readonly reason: string;
              } | null;
              readonly actor?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
              readonly changedFields?: ReadonlyArray<{
                readonly __typename: "ArtifactChangedFieldNode";
                readonly fieldName?: string | null;
                readonly previousValue?: string | null;
                readonly newValue?: string | null;
                readonly changedFields?: ReadonlyArray<{
                  readonly __typename: "ArtifactChangedFieldNode";
                  readonly newValue?: string | null;
                  readonly previousValue?: string | null;
                  readonly fieldName?: string | null;
                } | null> | null;
                readonly newValueObject?:
                  | {
                      readonly __typename: "BaseArtifactNode";
                      readonly id: number;
                      readonly title: string;
                      readonly artifactType: ArtifactType;
                    }
                  | {
                      readonly __typename: "CommentNode";
                      readonly id: number;
                      readonly comment?: any | null;
                      readonly deleted?: boolean | null;
                      readonly creator?: {
                        readonly __typename: "UserNode";
                        readonly id: number;
                        readonly name: string;
                        readonly avatar?: string | null;
                      } | null;
                    }
                  | {
                      readonly __typename: "KeyResultNode";
                      readonly id: number;
                      readonly title: string;
                    }
                  | {
                      readonly __typename: "TopicNode";
                      readonly id: number;
                      readonly title: string;
                      readonly meeting?: {
                        readonly __typename: "MeetingNode";
                        readonly id: number;
                        readonly title?: string | null;
                        readonly meetingGroupId: number;
                      } | null;
                    }
                  | {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    }
                  | null;
                readonly previousValueObject?:
                  | {
                      readonly __typename: "BaseArtifactNode";
                      readonly id: number;
                      readonly title: string;
                      readonly artifactType: ArtifactType;
                    }
                  | { readonly __typename: "CommentNode" }
                  | {
                      readonly __typename: "KeyResultNode";
                      readonly id: number;
                      readonly title: string;
                    }
                  | {
                      readonly __typename: "TopicNode";
                      readonly id: number;
                      readonly title: string;
                      readonly meeting?: {
                        readonly __typename: "MeetingNode";
                        readonly id: number;
                        readonly title?: string | null;
                        readonly meetingGroupId: number;
                      } | null;
                    }
                  | {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    }
                  | null;
              } | null> | null;
            } | null;
          } | null>;
        };
      }
    | {
        readonly __typename: "GithubIssuesArtifactNode";
        readonly id: number;
        readonly activities: {
          readonly __typename: "ArtifactActivityNodeConnection";
          readonly totalCount: number;
          readonly pageInfo: {
            readonly __typename: "PageInfo";
            readonly hasNextPage: boolean;
            readonly endCursor?: string | null;
          };
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactActivityNodeEdge";
            readonly node?: {
              readonly __typename: "ArtifactActivityNode";
              readonly id: number;
              readonly created: string;
              readonly activityType: ArtifactActivityType;
              readonly canDelete?: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
                readonly reason: string;
              } | null;
              readonly canUpdate?: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
                readonly reason: string;
              } | null;
              readonly actor?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
              readonly changedFields?: ReadonlyArray<{
                readonly __typename: "ArtifactChangedFieldNode";
                readonly fieldName?: string | null;
                readonly previousValue?: string | null;
                readonly newValue?: string | null;
                readonly changedFields?: ReadonlyArray<{
                  readonly __typename: "ArtifactChangedFieldNode";
                  readonly newValue?: string | null;
                  readonly previousValue?: string | null;
                  readonly fieldName?: string | null;
                } | null> | null;
                readonly newValueObject?:
                  | {
                      readonly __typename: "BaseArtifactNode";
                      readonly id: number;
                      readonly title: string;
                      readonly artifactType: ArtifactType;
                    }
                  | {
                      readonly __typename: "CommentNode";
                      readonly id: number;
                      readonly comment?: any | null;
                      readonly deleted?: boolean | null;
                      readonly creator?: {
                        readonly __typename: "UserNode";
                        readonly id: number;
                        readonly name: string;
                        readonly avatar?: string | null;
                      } | null;
                    }
                  | {
                      readonly __typename: "KeyResultNode";
                      readonly id: number;
                      readonly title: string;
                    }
                  | {
                      readonly __typename: "TopicNode";
                      readonly id: number;
                      readonly title: string;
                      readonly meeting?: {
                        readonly __typename: "MeetingNode";
                        readonly id: number;
                        readonly title?: string | null;
                        readonly meetingGroupId: number;
                      } | null;
                    }
                  | {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    }
                  | null;
                readonly previousValueObject?:
                  | {
                      readonly __typename: "BaseArtifactNode";
                      readonly id: number;
                      readonly title: string;
                      readonly artifactType: ArtifactType;
                    }
                  | { readonly __typename: "CommentNode" }
                  | {
                      readonly __typename: "KeyResultNode";
                      readonly id: number;
                      readonly title: string;
                    }
                  | {
                      readonly __typename: "TopicNode";
                      readonly id: number;
                      readonly title: string;
                      readonly meeting?: {
                        readonly __typename: "MeetingNode";
                        readonly id: number;
                        readonly title?: string | null;
                        readonly meetingGroupId: number;
                      } | null;
                    }
                  | {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    }
                  | null;
              } | null> | null;
            } | null;
          } | null>;
        };
      }
    | {
        readonly __typename: "GoalArtifactNode";
        readonly id: number;
        readonly activities: {
          readonly __typename: "ArtifactActivityNodeConnection";
          readonly totalCount: number;
          readonly pageInfo: {
            readonly __typename: "PageInfo";
            readonly hasNextPage: boolean;
            readonly endCursor?: string | null;
          };
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactActivityNodeEdge";
            readonly node?: {
              readonly __typename: "ArtifactActivityNode";
              readonly id: number;
              readonly created: string;
              readonly activityType: ArtifactActivityType;
              readonly canDelete?: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
                readonly reason: string;
              } | null;
              readonly canUpdate?: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
                readonly reason: string;
              } | null;
              readonly actor?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
              readonly changedFields?: ReadonlyArray<{
                readonly __typename: "ArtifactChangedFieldNode";
                readonly fieldName?: string | null;
                readonly previousValue?: string | null;
                readonly newValue?: string | null;
                readonly changedFields?: ReadonlyArray<{
                  readonly __typename: "ArtifactChangedFieldNode";
                  readonly newValue?: string | null;
                  readonly previousValue?: string | null;
                  readonly fieldName?: string | null;
                } | null> | null;
                readonly newValueObject?:
                  | {
                      readonly __typename: "BaseArtifactNode";
                      readonly id: number;
                      readonly title: string;
                      readonly artifactType: ArtifactType;
                    }
                  | {
                      readonly __typename: "CommentNode";
                      readonly id: number;
                      readonly comment?: any | null;
                      readonly deleted?: boolean | null;
                      readonly creator?: {
                        readonly __typename: "UserNode";
                        readonly id: number;
                        readonly name: string;
                        readonly avatar?: string | null;
                      } | null;
                    }
                  | {
                      readonly __typename: "KeyResultNode";
                      readonly id: number;
                      readonly title: string;
                    }
                  | {
                      readonly __typename: "TopicNode";
                      readonly id: number;
                      readonly title: string;
                      readonly meeting?: {
                        readonly __typename: "MeetingNode";
                        readonly id: number;
                        readonly title?: string | null;
                        readonly meetingGroupId: number;
                      } | null;
                    }
                  | {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    }
                  | null;
                readonly previousValueObject?:
                  | {
                      readonly __typename: "BaseArtifactNode";
                      readonly id: number;
                      readonly title: string;
                      readonly artifactType: ArtifactType;
                    }
                  | { readonly __typename: "CommentNode" }
                  | {
                      readonly __typename: "KeyResultNode";
                      readonly id: number;
                      readonly title: string;
                    }
                  | {
                      readonly __typename: "TopicNode";
                      readonly id: number;
                      readonly title: string;
                      readonly meeting?: {
                        readonly __typename: "MeetingNode";
                        readonly id: number;
                        readonly title?: string | null;
                        readonly meetingGroupId: number;
                      } | null;
                    }
                  | {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    }
                  | null;
              } | null> | null;
            } | null;
          } | null>;
        };
      }
    | {
        readonly __typename: "KPIArtifactNode";
        readonly id: number;
        readonly activities: {
          readonly __typename: "ArtifactActivityNodeConnection";
          readonly totalCount: number;
          readonly pageInfo: {
            readonly __typename: "PageInfo";
            readonly hasNextPage: boolean;
            readonly endCursor?: string | null;
          };
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactActivityNodeEdge";
            readonly node?: {
              readonly __typename: "ArtifactActivityNode";
              readonly id: number;
              readonly created: string;
              readonly activityType: ArtifactActivityType;
              readonly canDelete?: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
                readonly reason: string;
              } | null;
              readonly canUpdate?: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
                readonly reason: string;
              } | null;
              readonly actor?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
              readonly changedFields?: ReadonlyArray<{
                readonly __typename: "ArtifactChangedFieldNode";
                readonly fieldName?: string | null;
                readonly previousValue?: string | null;
                readonly newValue?: string | null;
                readonly changedFields?: ReadonlyArray<{
                  readonly __typename: "ArtifactChangedFieldNode";
                  readonly newValue?: string | null;
                  readonly previousValue?: string | null;
                  readonly fieldName?: string | null;
                } | null> | null;
                readonly newValueObject?:
                  | {
                      readonly __typename: "BaseArtifactNode";
                      readonly id: number;
                      readonly title: string;
                      readonly artifactType: ArtifactType;
                    }
                  | {
                      readonly __typename: "CommentNode";
                      readonly id: number;
                      readonly comment?: any | null;
                      readonly deleted?: boolean | null;
                      readonly creator?: {
                        readonly __typename: "UserNode";
                        readonly id: number;
                        readonly name: string;
                        readonly avatar?: string | null;
                      } | null;
                    }
                  | {
                      readonly __typename: "KeyResultNode";
                      readonly id: number;
                      readonly title: string;
                    }
                  | {
                      readonly __typename: "TopicNode";
                      readonly id: number;
                      readonly title: string;
                      readonly meeting?: {
                        readonly __typename: "MeetingNode";
                        readonly id: number;
                        readonly title?: string | null;
                        readonly meetingGroupId: number;
                      } | null;
                    }
                  | {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    }
                  | null;
                readonly previousValueObject?:
                  | {
                      readonly __typename: "BaseArtifactNode";
                      readonly id: number;
                      readonly title: string;
                      readonly artifactType: ArtifactType;
                    }
                  | { readonly __typename: "CommentNode" }
                  | {
                      readonly __typename: "KeyResultNode";
                      readonly id: number;
                      readonly title: string;
                    }
                  | {
                      readonly __typename: "TopicNode";
                      readonly id: number;
                      readonly title: string;
                      readonly meeting?: {
                        readonly __typename: "MeetingNode";
                        readonly id: number;
                        readonly title?: string | null;
                        readonly meetingGroupId: number;
                      } | null;
                    }
                  | {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    }
                  | null;
              } | null> | null;
            } | null;
          } | null>;
        };
      }
    | {
        readonly __typename: "RatingArtifactNode";
        readonly id: number;
        readonly activities: {
          readonly __typename: "ArtifactActivityNodeConnection";
          readonly totalCount: number;
          readonly pageInfo: {
            readonly __typename: "PageInfo";
            readonly hasNextPage: boolean;
            readonly endCursor?: string | null;
          };
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactActivityNodeEdge";
            readonly node?: {
              readonly __typename: "ArtifactActivityNode";
              readonly id: number;
              readonly created: string;
              readonly activityType: ArtifactActivityType;
              readonly canDelete?: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
                readonly reason: string;
              } | null;
              readonly canUpdate?: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
                readonly reason: string;
              } | null;
              readonly actor?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
              readonly changedFields?: ReadonlyArray<{
                readonly __typename: "ArtifactChangedFieldNode";
                readonly fieldName?: string | null;
                readonly previousValue?: string | null;
                readonly newValue?: string | null;
                readonly changedFields?: ReadonlyArray<{
                  readonly __typename: "ArtifactChangedFieldNode";
                  readonly newValue?: string | null;
                  readonly previousValue?: string | null;
                  readonly fieldName?: string | null;
                } | null> | null;
                readonly newValueObject?:
                  | {
                      readonly __typename: "BaseArtifactNode";
                      readonly id: number;
                      readonly title: string;
                      readonly artifactType: ArtifactType;
                    }
                  | {
                      readonly __typename: "CommentNode";
                      readonly id: number;
                      readonly comment?: any | null;
                      readonly deleted?: boolean | null;
                      readonly creator?: {
                        readonly __typename: "UserNode";
                        readonly id: number;
                        readonly name: string;
                        readonly avatar?: string | null;
                      } | null;
                    }
                  | {
                      readonly __typename: "KeyResultNode";
                      readonly id: number;
                      readonly title: string;
                    }
                  | {
                      readonly __typename: "TopicNode";
                      readonly id: number;
                      readonly title: string;
                      readonly meeting?: {
                        readonly __typename: "MeetingNode";
                        readonly id: number;
                        readonly title?: string | null;
                        readonly meetingGroupId: number;
                      } | null;
                    }
                  | {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    }
                  | null;
                readonly previousValueObject?:
                  | {
                      readonly __typename: "BaseArtifactNode";
                      readonly id: number;
                      readonly title: string;
                      readonly artifactType: ArtifactType;
                    }
                  | { readonly __typename: "CommentNode" }
                  | {
                      readonly __typename: "KeyResultNode";
                      readonly id: number;
                      readonly title: string;
                    }
                  | {
                      readonly __typename: "TopicNode";
                      readonly id: number;
                      readonly title: string;
                      readonly meeting?: {
                        readonly __typename: "MeetingNode";
                        readonly id: number;
                        readonly title?: string | null;
                        readonly meetingGroupId: number;
                      } | null;
                    }
                  | {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    }
                  | null;
              } | null> | null;
            } | null;
          } | null>;
        };
      }
    | {
        readonly __typename: "RecognitionArtifactNode";
        readonly id: number;
        readonly activities: {
          readonly __typename: "ArtifactActivityNodeConnection";
          readonly totalCount: number;
          readonly pageInfo: {
            readonly __typename: "PageInfo";
            readonly hasNextPage: boolean;
            readonly endCursor?: string | null;
          };
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactActivityNodeEdge";
            readonly node?: {
              readonly __typename: "ArtifactActivityNode";
              readonly id: number;
              readonly created: string;
              readonly activityType: ArtifactActivityType;
              readonly canDelete?: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
                readonly reason: string;
              } | null;
              readonly canUpdate?: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
                readonly reason: string;
              } | null;
              readonly actor?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
              readonly changedFields?: ReadonlyArray<{
                readonly __typename: "ArtifactChangedFieldNode";
                readonly fieldName?: string | null;
                readonly previousValue?: string | null;
                readonly newValue?: string | null;
                readonly changedFields?: ReadonlyArray<{
                  readonly __typename: "ArtifactChangedFieldNode";
                  readonly newValue?: string | null;
                  readonly previousValue?: string | null;
                  readonly fieldName?: string | null;
                } | null> | null;
                readonly newValueObject?:
                  | {
                      readonly __typename: "BaseArtifactNode";
                      readonly id: number;
                      readonly title: string;
                      readonly artifactType: ArtifactType;
                    }
                  | {
                      readonly __typename: "CommentNode";
                      readonly id: number;
                      readonly comment?: any | null;
                      readonly deleted?: boolean | null;
                      readonly creator?: {
                        readonly __typename: "UserNode";
                        readonly id: number;
                        readonly name: string;
                        readonly avatar?: string | null;
                      } | null;
                    }
                  | {
                      readonly __typename: "KeyResultNode";
                      readonly id: number;
                      readonly title: string;
                    }
                  | {
                      readonly __typename: "TopicNode";
                      readonly id: number;
                      readonly title: string;
                      readonly meeting?: {
                        readonly __typename: "MeetingNode";
                        readonly id: number;
                        readonly title?: string | null;
                        readonly meetingGroupId: number;
                      } | null;
                    }
                  | {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    }
                  | null;
                readonly previousValueObject?:
                  | {
                      readonly __typename: "BaseArtifactNode";
                      readonly id: number;
                      readonly title: string;
                      readonly artifactType: ArtifactType;
                    }
                  | { readonly __typename: "CommentNode" }
                  | {
                      readonly __typename: "KeyResultNode";
                      readonly id: number;
                      readonly title: string;
                    }
                  | {
                      readonly __typename: "TopicNode";
                      readonly id: number;
                      readonly title: string;
                      readonly meeting?: {
                        readonly __typename: "MeetingNode";
                        readonly id: number;
                        readonly title?: string | null;
                        readonly meetingGroupId: number;
                      } | null;
                    }
                  | {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    }
                  | null;
              } | null> | null;
            } | null;
          } | null>;
        };
      };
};

export type GoalArtifactSidebarFragmentFragment = {
  readonly __typename: "GoalArtifactNode";
  readonly id: number;
  readonly parentGoalId?: number | null;
  readonly title?: string | null;
  readonly created: string;
  readonly artifactType: ArtifactType;
  readonly state: GoalState;
  readonly status: GoalStatus;
  readonly scope: GoalScope;
  readonly goalVisibility: GoalVisibility;
  readonly dueDate?: string | null;
  readonly startDate?: string | null;
  readonly progress: number;
  readonly progressType: GoalProgressType;
  readonly startValue: number;
  readonly currentValue: number;
  readonly targetValue: number;
  readonly isStale: boolean;
  readonly ownerCount?: number | null;
  readonly contributorCount?: number | null;
  readonly childGoals?: {
    readonly __typename: "GoalArtifactConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "GoalArtifactEdge";
      readonly node?: {
        readonly __typename: "GoalArtifactNode";
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly updated?: string | null;
        readonly created: string;
        readonly state: GoalState;
        readonly scope: GoalScope;
        readonly ownerCount?: number | null;
        readonly dueDate?: string | null;
        readonly goalVisibility: GoalVisibility;
        readonly status: GoalStatus;
        readonly progress: number;
        readonly progressType: GoalProgressType;
        readonly currentValue: number;
        readonly startValue: number;
        readonly targetValue: number;
        readonly isStale: boolean;
        readonly parentGoalId?: number | null;
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly firstOwner?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly owners?: {
          readonly __typename: "UserNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "UserNodeEdge";
            readonly node?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
          } | null>;
        } | null;
        readonly activities: {
          readonly __typename: "ArtifactActivityNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactActivityNodeEdge";
            readonly node?: {
              readonly __typename: "ArtifactActivityNode";
              readonly created: string;
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  } | null;
  readonly parentGoal?: {
    readonly __typename: "GoalArtifactNode";
    readonly id: number;
    readonly artifactType: ArtifactType;
    readonly title?: string | null;
    readonly updated?: string | null;
    readonly created: string;
    readonly state: GoalState;
    readonly scope: GoalScope;
    readonly ownerCount?: number | null;
    readonly dueDate?: string | null;
    readonly goalVisibility: GoalVisibility;
    readonly status: GoalStatus;
    readonly progress: number;
    readonly progressType: GoalProgressType;
    readonly currentValue: number;
    readonly startValue: number;
    readonly targetValue: number;
    readonly isStale: boolean;
    readonly parentGoalId?: number | null;
    readonly canUpdate: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
    };
    readonly canDelete: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
    };
    readonly creator?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
      readonly avatar?: string | null;
    } | null;
    readonly firstOwner?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
      readonly avatar?: string | null;
    } | null;
    readonly owners?: {
      readonly __typename: "UserNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "UserNodeEdge";
        readonly node?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
      } | null>;
    } | null;
    readonly activities: {
      readonly __typename: "ArtifactActivityNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "ArtifactActivityNodeEdge";
        readonly node?: {
          readonly __typename: "ArtifactActivityNode";
          readonly created: string;
        } | null;
      } | null>;
    };
  } | null;
  readonly topLevelGoal?: {
    readonly __typename: "GoalArtifactNode";
    readonly id: number;
    readonly artifactType: ArtifactType;
    readonly title?: string | null;
    readonly updated?: string | null;
    readonly created: string;
    readonly state: GoalState;
    readonly scope: GoalScope;
    readonly ownerCount?: number | null;
    readonly dueDate?: string | null;
    readonly goalVisibility: GoalVisibility;
    readonly status: GoalStatus;
    readonly progress: number;
    readonly progressType: GoalProgressType;
    readonly currentValue: number;
    readonly startValue: number;
    readonly targetValue: number;
    readonly isStale: boolean;
    readonly parentGoalId?: number | null;
    readonly canUpdate: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
    };
    readonly canDelete: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
    };
    readonly creator?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
      readonly avatar?: string | null;
    } | null;
    readonly firstOwner?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
      readonly avatar?: string | null;
    } | null;
    readonly owners?: {
      readonly __typename: "UserNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "UserNodeEdge";
        readonly node?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
      } | null>;
    } | null;
    readonly activities: {
      readonly __typename: "ArtifactActivityNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "ArtifactActivityNodeEdge";
        readonly node?: {
          readonly __typename: "ArtifactActivityNode";
          readonly created: string;
        } | null;
      } | null>;
    };
  } | null;
  readonly kpi?: {
    readonly __typename: "KPINode";
    readonly id: number;
    readonly title: string;
    readonly currentMeasurement?: {
      readonly __typename: "KPIMeasurementNode";
      readonly id: number;
      readonly measurement: number;
    } | null;
  } | null;
  readonly owners?: {
    readonly __typename: "UserNodeConnection";
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly __typename: "UserNodeEdge";
      readonly node?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly avatar?: string | null;
        readonly name: string;
      } | null;
    } | null>;
  } | null;
  readonly contributors?: {
    readonly __typename: "UserNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "UserNodeEdge";
      readonly node?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly avatar?: string | null;
        readonly name: string;
      } | null;
    } | null>;
  } | null;
  readonly keyResults: {
    readonly __typename: "KeyResultNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "KeyResultNodeEdge";
      readonly node?: {
        readonly __typename: "KeyResultNode";
        readonly id: number;
        readonly title: string;
        readonly startValue: number;
        readonly currentValue: number;
        readonly targetValue: number;
        readonly progressType: GoalProgressType;
        readonly kpi?: {
          readonly __typename: "KPINode";
          readonly id: number;
          readonly title: string;
          readonly currentMeasurement?: {
            readonly __typename: "KPIMeasurementNode";
            readonly id: number;
            readonly measurement: number;
          } | null;
        } | null;
        readonly canUpdate?: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        } | null;
        readonly assignee?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
          readonly email: string;
        } | null;
      } | null;
    } | null>;
  };
  readonly firstOwner?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly avatar?: string | null;
    readonly name: string;
  } | null;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canRead: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly teams?: {
    readonly __typename: "TeamNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "TeamNodeEdge";
      readonly node?: {
        readonly __typename: "TeamNode";
        readonly id: number;
        readonly title: string;
      } | null;
    } | null>;
  } | null;
};

export type GetArtifactSidebarQueryQueryVariables = Exact<{
  artifactId: Scalars["Int"]["input"];
  keyResultLimit: Scalars["Int"]["input"];
}>;

export type GetArtifactSidebarQueryQuery = {
  readonly __typename: "Query";
  readonly artifact:
    | {
        readonly __typename: "ActionItemArtifactNode";
        readonly isComplete?: boolean | null;
        readonly actionItemState?: number | null;
        readonly dueDate?: string | null;
        readonly id: number;
        readonly title?: string | null;
        readonly description?: any | null;
        readonly artifactType: ArtifactType;
        readonly created: string;
        readonly updated?: string | null;
        readonly websocketToken?: string | null;
        readonly assignee?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly avatar?: string | null;
          readonly name: string;
        } | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canRead: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
        readonly topics?: {
          readonly __typename: "ArtifactToTopicConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactToTopicEdge";
            readonly connectionCreated?: string | null;
            readonly connectionCreator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
            } | null;
            readonly node?: {
              readonly __typename: "TopicNode";
              readonly id: number;
              readonly title: string;
              readonly meeting?: {
                readonly __typename: "MeetingNode";
                readonly id: number;
                readonly meetingGroupId: number;
              } | null;
            } | null;
          } | null>;
        } | null;
        readonly relatedArtifacts?: {
          readonly __typename: "ArtifactToArtifactConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactToArtifactEdge";
            readonly connectionCreated?: string | null;
            readonly connectionCreator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
            } | null;
            readonly node?:
              | {
                  readonly __typename: "ActionItemArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "AsanaTaskArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "DecisionArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "DocumentArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "FeedbackArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "GithubIssuesArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "GoalArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "KPIArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "RatingArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "RecognitionArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | null;
          } | null>;
        } | null;
      }
    | {
        readonly __typename: "AsanaTaskArtifactNode";
        readonly id: number;
        readonly title?: string | null;
        readonly description?: any | null;
        readonly artifactType: ArtifactType;
        readonly created: string;
        readonly updated?: string | null;
        readonly websocketToken?: string | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canRead: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
        readonly topics?: {
          readonly __typename: "ArtifactToTopicConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactToTopicEdge";
            readonly connectionCreated?: string | null;
            readonly connectionCreator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
            } | null;
            readonly node?: {
              readonly __typename: "TopicNode";
              readonly id: number;
              readonly title: string;
              readonly meeting?: {
                readonly __typename: "MeetingNode";
                readonly id: number;
                readonly meetingGroupId: number;
              } | null;
            } | null;
          } | null>;
        } | null;
        readonly relatedArtifacts?: {
          readonly __typename: "ArtifactToArtifactConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactToArtifactEdge";
            readonly connectionCreated?: string | null;
            readonly connectionCreator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
            } | null;
            readonly node?:
              | {
                  readonly __typename: "ActionItemArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "AsanaTaskArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "DecisionArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "DocumentArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "FeedbackArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "GithubIssuesArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "GoalArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "KPIArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "RatingArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "RecognitionArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | null;
          } | null>;
        } | null;
      }
    | {
        readonly __typename: "DecisionArtifactNode";
        readonly decision: string;
        readonly decisionState?: DecisionState | null;
        readonly isDraft?: boolean | null;
        readonly id: number;
        readonly title?: string | null;
        readonly description?: any | null;
        readonly artifactType: ArtifactType;
        readonly created: string;
        readonly updated?: string | null;
        readonly websocketToken?: string | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canRead: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
        readonly topics?: {
          readonly __typename: "ArtifactToTopicConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactToTopicEdge";
            readonly connectionCreated?: string | null;
            readonly connectionCreator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
            } | null;
            readonly node?: {
              readonly __typename: "TopicNode";
              readonly id: number;
              readonly title: string;
              readonly meeting?: {
                readonly __typename: "MeetingNode";
                readonly id: number;
                readonly meetingGroupId: number;
              } | null;
            } | null;
          } | null>;
        } | null;
        readonly relatedArtifacts?: {
          readonly __typename: "ArtifactToArtifactConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactToArtifactEdge";
            readonly connectionCreated?: string | null;
            readonly connectionCreator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
            } | null;
            readonly node?:
              | {
                  readonly __typename: "ActionItemArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "AsanaTaskArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "DecisionArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "DocumentArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "FeedbackArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "GithubIssuesArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "GoalArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "KPIArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "RatingArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "RecognitionArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | null;
          } | null>;
        } | null;
      }
    | {
        readonly __typename: "DocumentArtifactNode";
        readonly documentVisibility?: DocumentVisibility | null;
        readonly id: number;
        readonly title?: string | null;
        readonly description?: any | null;
        readonly artifactType: ArtifactType;
        readonly created: string;
        readonly updated?: string | null;
        readonly websocketToken?: string | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canRead: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
        readonly topics?: {
          readonly __typename: "ArtifactToTopicConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactToTopicEdge";
            readonly connectionCreated?: string | null;
            readonly connectionCreator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
            } | null;
            readonly node?: {
              readonly __typename: "TopicNode";
              readonly id: number;
              readonly title: string;
              readonly meeting?: {
                readonly __typename: "MeetingNode";
                readonly id: number;
                readonly meetingGroupId: number;
              } | null;
            } | null;
          } | null>;
        } | null;
        readonly relatedArtifacts?: {
          readonly __typename: "ArtifactToArtifactConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactToArtifactEdge";
            readonly connectionCreated?: string | null;
            readonly connectionCreator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
            } | null;
            readonly node?:
              | {
                  readonly __typename: "ActionItemArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "AsanaTaskArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "DecisionArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "DocumentArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "FeedbackArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "GithubIssuesArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "GoalArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "KPIArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "RatingArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "RecognitionArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | null;
          } | null>;
        } | null;
      }
    | {
        readonly __typename: "FeedbackArtifactNode";
        readonly id: number;
        readonly title?: string | null;
        readonly description?: any | null;
        readonly artifactType: ArtifactType;
        readonly created: string;
        readonly updated?: string | null;
        readonly websocketToken?: string | null;
        readonly recipientsCanView: boolean;
        readonly recipientsManagersCanView: boolean;
        readonly adminsCanView: boolean;
        readonly creatorCanView: boolean;
        readonly feedbackState: FeedbackState;
        readonly message?: any | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canRead: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
        readonly topics?: {
          readonly __typename: "ArtifactToTopicConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactToTopicEdge";
            readonly connectionCreated?: string | null;
            readonly connectionCreator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
            } | null;
            readonly node?: {
              readonly __typename: "TopicNode";
              readonly id: number;
              readonly title: string;
              readonly meeting?: {
                readonly __typename: "MeetingNode";
                readonly id: number;
                readonly meetingGroupId: number;
              } | null;
            } | null;
          } | null>;
        } | null;
        readonly relatedArtifacts?: {
          readonly __typename: "ArtifactToArtifactConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactToArtifactEdge";
            readonly connectionCreated?: string | null;
            readonly connectionCreator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
            } | null;
            readonly node?:
              | {
                  readonly __typename: "ActionItemArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "AsanaTaskArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "DecisionArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "DocumentArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "FeedbackArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "GithubIssuesArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "GoalArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "KPIArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "RatingArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "RecognitionArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | null;
          } | null>;
        } | null;
        readonly feedbackRecipients: {
          readonly __typename: "UserNodeConnection";
          readonly totalCount: number;
          readonly edges: ReadonlyArray<{
            readonly __typename: "UserNodeEdge";
            readonly node?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly avatar?: string | null;
              readonly name: string;
            } | null;
          } | null>;
        };
        readonly sender: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly avatar?: string | null;
          readonly name: string;
        };
      }
    | {
        readonly __typename: "GithubIssuesArtifactNode";
        readonly id: number;
        readonly title?: string | null;
        readonly description?: any | null;
        readonly artifactType: ArtifactType;
        readonly created: string;
        readonly updated?: string | null;
        readonly websocketToken?: string | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canRead: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
        readonly topics?: {
          readonly __typename: "ArtifactToTopicConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactToTopicEdge";
            readonly connectionCreated?: string | null;
            readonly connectionCreator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
            } | null;
            readonly node?: {
              readonly __typename: "TopicNode";
              readonly id: number;
              readonly title: string;
              readonly meeting?: {
                readonly __typename: "MeetingNode";
                readonly id: number;
                readonly meetingGroupId: number;
              } | null;
            } | null;
          } | null>;
        } | null;
        readonly relatedArtifacts?: {
          readonly __typename: "ArtifactToArtifactConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactToArtifactEdge";
            readonly connectionCreated?: string | null;
            readonly connectionCreator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
            } | null;
            readonly node?:
              | {
                  readonly __typename: "ActionItemArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "AsanaTaskArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "DecisionArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "DocumentArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "FeedbackArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "GithubIssuesArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "GoalArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "KPIArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "RatingArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "RecognitionArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | null;
          } | null>;
        } | null;
      }
    | {
        readonly __typename: "GoalArtifactNode";
        readonly id: number;
        readonly title?: string | null;
        readonly description?: any | null;
        readonly artifactType: ArtifactType;
        readonly created: string;
        readonly updated?: string | null;
        readonly websocketToken?: string | null;
        readonly parentGoalId?: number | null;
        readonly state: GoalState;
        readonly status: GoalStatus;
        readonly scope: GoalScope;
        readonly goalVisibility: GoalVisibility;
        readonly dueDate?: string | null;
        readonly startDate?: string | null;
        readonly progress: number;
        readonly progressType: GoalProgressType;
        readonly startValue: number;
        readonly currentValue: number;
        readonly targetValue: number;
        readonly isStale: boolean;
        readonly ownerCount?: number | null;
        readonly contributorCount?: number | null;
        readonly owners?: {
          readonly __typename: "UserNodeConnection";
          readonly totalCount: number;
          readonly edges: ReadonlyArray<{
            readonly __typename: "UserNodeEdge";
            readonly node?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
          } | null>;
        } | null;
        readonly contributors?: {
          readonly __typename: "UserNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "UserNodeEdge";
            readonly node?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
          } | null>;
        } | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canRead: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
        readonly topics?: {
          readonly __typename: "ArtifactToTopicConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactToTopicEdge";
            readonly connectionCreated?: string | null;
            readonly connectionCreator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
            } | null;
            readonly node?: {
              readonly __typename: "TopicNode";
              readonly id: number;
              readonly title: string;
              readonly meeting?: {
                readonly __typename: "MeetingNode";
                readonly id: number;
                readonly meetingGroupId: number;
              } | null;
            } | null;
          } | null>;
        } | null;
        readonly relatedArtifacts?: {
          readonly __typename: "ArtifactToArtifactConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactToArtifactEdge";
            readonly connectionCreated?: string | null;
            readonly connectionCreator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
            } | null;
            readonly node?:
              | {
                  readonly __typename: "ActionItemArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "AsanaTaskArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "DecisionArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "DocumentArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "FeedbackArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "GithubIssuesArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "GoalArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "KPIArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "RatingArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "RecognitionArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | null;
          } | null>;
        } | null;
        readonly childGoals?: {
          readonly __typename: "GoalArtifactConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "GoalArtifactEdge";
            readonly node?: {
              readonly __typename: "GoalArtifactNode";
              readonly id: number;
              readonly artifactType: ArtifactType;
              readonly title?: string | null;
              readonly updated?: string | null;
              readonly created: string;
              readonly state: GoalState;
              readonly scope: GoalScope;
              readonly ownerCount?: number | null;
              readonly dueDate?: string | null;
              readonly goalVisibility: GoalVisibility;
              readonly status: GoalStatus;
              readonly progress: number;
              readonly progressType: GoalProgressType;
              readonly currentValue: number;
              readonly startValue: number;
              readonly targetValue: number;
              readonly isStale: boolean;
              readonly parentGoalId?: number | null;
              readonly canUpdate: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly creator?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
              readonly firstOwner?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
              readonly owners?: {
                readonly __typename: "UserNodeConnection";
                readonly edges: ReadonlyArray<{
                  readonly __typename: "UserNodeEdge";
                  readonly node?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                } | null>;
              } | null;
              readonly activities: {
                readonly __typename: "ArtifactActivityNodeConnection";
                readonly edges: ReadonlyArray<{
                  readonly __typename: "ArtifactActivityNodeEdge";
                  readonly node?: {
                    readonly __typename: "ArtifactActivityNode";
                    readonly created: string;
                  } | null;
                } | null>;
              };
            } | null;
          } | null>;
        } | null;
        readonly parentGoal?: {
          readonly __typename: "GoalArtifactNode";
          readonly id: number;
          readonly artifactType: ArtifactType;
          readonly title?: string | null;
          readonly updated?: string | null;
          readonly created: string;
          readonly state: GoalState;
          readonly scope: GoalScope;
          readonly ownerCount?: number | null;
          readonly dueDate?: string | null;
          readonly goalVisibility: GoalVisibility;
          readonly status: GoalStatus;
          readonly progress: number;
          readonly progressType: GoalProgressType;
          readonly currentValue: number;
          readonly startValue: number;
          readonly targetValue: number;
          readonly isStale: boolean;
          readonly parentGoalId?: number | null;
          readonly canUpdate: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly creator?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
          } | null;
          readonly firstOwner?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
          } | null;
          readonly owners?: {
            readonly __typename: "UserNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "UserNodeEdge";
              readonly node?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
            } | null>;
          } | null;
          readonly activities: {
            readonly __typename: "ArtifactActivityNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "ArtifactActivityNodeEdge";
              readonly node?: {
                readonly __typename: "ArtifactActivityNode";
                readonly created: string;
              } | null;
            } | null>;
          };
        } | null;
        readonly topLevelGoal?: {
          readonly __typename: "GoalArtifactNode";
          readonly id: number;
          readonly artifactType: ArtifactType;
          readonly title?: string | null;
          readonly updated?: string | null;
          readonly created: string;
          readonly state: GoalState;
          readonly scope: GoalScope;
          readonly ownerCount?: number | null;
          readonly dueDate?: string | null;
          readonly goalVisibility: GoalVisibility;
          readonly status: GoalStatus;
          readonly progress: number;
          readonly progressType: GoalProgressType;
          readonly currentValue: number;
          readonly startValue: number;
          readonly targetValue: number;
          readonly isStale: boolean;
          readonly parentGoalId?: number | null;
          readonly canUpdate: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly creator?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
          } | null;
          readonly firstOwner?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
          } | null;
          readonly owners?: {
            readonly __typename: "UserNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "UserNodeEdge";
              readonly node?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
            } | null>;
          } | null;
          readonly activities: {
            readonly __typename: "ArtifactActivityNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "ArtifactActivityNodeEdge";
              readonly node?: {
                readonly __typename: "ArtifactActivityNode";
                readonly created: string;
              } | null;
            } | null>;
          };
        } | null;
        readonly kpi?: {
          readonly __typename: "KPINode";
          readonly id: number;
          readonly title: string;
          readonly currentMeasurement?: {
            readonly __typename: "KPIMeasurementNode";
            readonly id: number;
            readonly measurement: number;
          } | null;
        } | null;
        readonly keyResults: {
          readonly __typename: "KeyResultNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "KeyResultNodeEdge";
            readonly node?: {
              readonly __typename: "KeyResultNode";
              readonly id: number;
              readonly title: string;
              readonly startValue: number;
              readonly currentValue: number;
              readonly targetValue: number;
              readonly progressType: GoalProgressType;
              readonly kpi?: {
                readonly __typename: "KPINode";
                readonly id: number;
                readonly title: string;
                readonly currentMeasurement?: {
                  readonly __typename: "KPIMeasurementNode";
                  readonly id: number;
                  readonly measurement: number;
                } | null;
              } | null;
              readonly canUpdate?: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              } | null;
              readonly assignee?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
                readonly email: string;
              } | null;
            } | null;
          } | null>;
        };
        readonly firstOwner?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly avatar?: string | null;
          readonly name: string;
        } | null;
        readonly teams?: {
          readonly __typename: "TeamNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "TeamNodeEdge";
            readonly node?: {
              readonly __typename: "TeamNode";
              readonly id: number;
              readonly title: string;
            } | null;
          } | null>;
        } | null;
      }
    | {
        readonly __typename: "KPIArtifactNode";
        readonly id: number;
        readonly title?: string | null;
        readonly description?: any | null;
        readonly artifactType: ArtifactType;
        readonly created: string;
        readonly updated?: string | null;
        readonly websocketToken?: string | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canRead: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
        readonly topics?: {
          readonly __typename: "ArtifactToTopicConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactToTopicEdge";
            readonly connectionCreated?: string | null;
            readonly connectionCreator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
            } | null;
            readonly node?: {
              readonly __typename: "TopicNode";
              readonly id: number;
              readonly title: string;
              readonly meeting?: {
                readonly __typename: "MeetingNode";
                readonly id: number;
                readonly meetingGroupId: number;
              } | null;
            } | null;
          } | null>;
        } | null;
        readonly relatedArtifacts?: {
          readonly __typename: "ArtifactToArtifactConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactToArtifactEdge";
            readonly connectionCreated?: string | null;
            readonly connectionCreator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
            } | null;
            readonly node?:
              | {
                  readonly __typename: "ActionItemArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "AsanaTaskArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "DecisionArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "DocumentArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "FeedbackArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "GithubIssuesArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "GoalArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "KPIArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "RatingArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "RecognitionArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | null;
          } | null>;
        } | null;
      }
    | {
        readonly __typename: "RatingArtifactNode";
        readonly canAnswer?: boolean | null;
        readonly canViewAnswers?: boolean | null;
        readonly id: number;
        readonly title?: string | null;
        readonly description?: any | null;
        readonly artifactType: ArtifactType;
        readonly created: string;
        readonly updated?: string | null;
        readonly websocketToken?: string | null;
        readonly rating?: {
          readonly __typename: "RatingNode";
          readonly id: number;
          readonly title: string;
          readonly description: any;
          readonly startValue: number;
          readonly endValue: number;
          readonly labels: ReadonlyArray<string | null>;
        } | null;
        readonly createdInMeeting?: {
          readonly __typename: "MeetingNode";
          readonly id: number;
          readonly meetingGroupId: number;
        } | null;
        readonly answers?: {
          readonly __typename: "RatingAnswerNodeConnection";
          readonly totalCount?: number | null;
          readonly edges: ReadonlyArray<{
            readonly __typename: "RatingAnswerNodeEdge";
            readonly node?: {
              readonly __typename: "RatingAnswerNode";
              readonly id: number;
              readonly integerAnswer: number;
              readonly creator: {
                readonly __typename: "UserNode";
                readonly id: number;
              };
            } | null;
          } | null>;
        } | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canRead: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
        readonly topics?: {
          readonly __typename: "ArtifactToTopicConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactToTopicEdge";
            readonly connectionCreated?: string | null;
            readonly connectionCreator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
            } | null;
            readonly node?: {
              readonly __typename: "TopicNode";
              readonly id: number;
              readonly title: string;
              readonly meeting?: {
                readonly __typename: "MeetingNode";
                readonly id: number;
                readonly meetingGroupId: number;
              } | null;
            } | null;
          } | null>;
        } | null;
        readonly relatedArtifacts?: {
          readonly __typename: "ArtifactToArtifactConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactToArtifactEdge";
            readonly connectionCreated?: string | null;
            readonly connectionCreator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
            } | null;
            readonly node?:
              | {
                  readonly __typename: "ActionItemArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "AsanaTaskArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "DecisionArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "DocumentArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "FeedbackArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "GithubIssuesArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "GoalArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "KPIArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "RatingArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "RecognitionArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | null;
          } | null>;
        } | null;
      }
    | {
        readonly __typename: "RecognitionArtifactNode";
        readonly id: number;
        readonly title?: string | null;
        readonly description?: any | null;
        readonly artifactType: ArtifactType;
        readonly created: string;
        readonly updated?: string | null;
        readonly websocketToken?: string | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canRead: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
        readonly topics?: {
          readonly __typename: "ArtifactToTopicConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactToTopicEdge";
            readonly connectionCreated?: string | null;
            readonly connectionCreator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
            } | null;
            readonly node?: {
              readonly __typename: "TopicNode";
              readonly id: number;
              readonly title: string;
              readonly meeting?: {
                readonly __typename: "MeetingNode";
                readonly id: number;
                readonly meetingGroupId: number;
              } | null;
            } | null;
          } | null>;
        } | null;
        readonly relatedArtifacts?: {
          readonly __typename: "ArtifactToArtifactConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactToArtifactEdge";
            readonly connectionCreated?: string | null;
            readonly connectionCreator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
            } | null;
            readonly node?:
              | {
                  readonly __typename: "ActionItemArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "AsanaTaskArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "DecisionArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "DocumentArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "FeedbackArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "GithubIssuesArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "GoalArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "KPIArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "RatingArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "RecognitionArtifactNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | null;
          } | null>;
        } | null;
        readonly recipients?: {
          readonly __typename: "UserNodeConnection";
          readonly totalCount: number;
          readonly edges: ReadonlyArray<{
            readonly __typename: "UserNodeEdge";
            readonly node?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
          } | null>;
        } | null;
        readonly recognitionCoreValue?: {
          readonly __typename: "RecognitionCoreValueNode";
          readonly id: number;
          readonly title: string;
          readonly description: string;
          readonly emoji?: string | null;
          readonly image?: string | null;
        } | null;
      };
};

export type GetKpisToConnectToKrQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetKpisToConnectToKrQueryQuery = {
  readonly __typename: "Query";
  readonly kpis?: {
    readonly __typename: "KPINodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "KPINodeEdge";
      readonly node?: {
        readonly __typename: "KPINode";
        readonly id: number;
        readonly title: string;
        readonly visibility: KpiVisibility;
        readonly currentMeasurement?: {
          readonly __typename: "KPIMeasurementNode";
          readonly id: number;
          readonly measurement: number;
        } | null;
        readonly measurements?: {
          readonly __typename: "KPIMeasurementNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "KPIMeasurementNodeEdge";
            readonly node?: {
              readonly __typename: "KPIMeasurementNode";
              readonly id: number;
              readonly measurement: number;
              readonly timestamp: string;
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GoalTeamsFragmentFragment = {
  readonly __typename: "GoalArtifactNode";
  readonly teams?: {
    readonly __typename: "TeamNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "TeamNodeEdge";
      readonly node?: {
        readonly __typename: "TeamNode";
        readonly id: number;
        readonly title: string;
      } | null;
    } | null>;
  } | null;
};

export type ManageGoalKeyResultMutationVariables = Exact<{
  keyResultId?: InputMaybe<Scalars["Int"]["input"]>;
  goalId?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  startValue?: InputMaybe<Scalars["Float"]["input"]>;
  targetValue?: InputMaybe<Scalars["Float"]["input"]>;
  progressType?: InputMaybe<GoalProgressType>;
  assigneeId?: InputMaybe<Scalars["Int"]["input"]>;
  kpiId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type ManageGoalKeyResultMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateKeyResult?: {
    readonly __typename: "CreateOrUpdateKeyResultMutation";
    readonly keyResult?: {
      readonly __typename: "KeyResultNode";
      readonly id: number;
      readonly title: string;
      readonly startValue: number;
      readonly currentValue: number;
      readonly targetValue: number;
      readonly progressType: GoalProgressType;
      readonly kpi?: {
        readonly __typename: "KPINode";
        readonly id: number;
        readonly title: string;
        readonly currentMeasurement?: {
          readonly __typename: "KPIMeasurementNode";
          readonly id: number;
          readonly measurement: number;
        } | null;
      } | null;
      readonly canUpdate?: {
        readonly __typename: "PermissionNode";
        readonly permission: boolean;
      } | null;
      readonly assignee?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly avatar?: string | null;
        readonly email: string;
      } | null;
    } | null;
    readonly goal?: {
      readonly __typename: "GoalArtifactNode";
      readonly id: number;
      readonly contributors?: {
        readonly __typename: "UserNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "UserNodeEdge";
          readonly node?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
          } | null;
        } | null>;
      } | null;
      readonly keyResults: {
        readonly __typename: "KeyResultNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "KeyResultNodeEdge";
          readonly node?: {
            readonly __typename: "KeyResultNode";
            readonly id: number;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type RemoveFavouriteArtifactMutationMutationVariables = Exact<{
  artifactId: Scalars["Int"]["input"];
}>;

export type RemoveFavouriteArtifactMutationMutation = {
  readonly __typename: "Mutation";
  readonly removeFavouriteArtifact?: {
    readonly __typename: "RemoveFavouriteArtifact";
    readonly artifact?:
      | { readonly __typename: "ActionItemArtifactNode"; readonly id: number }
      | { readonly __typename: "AsanaTaskArtifactNode"; readonly id: number }
      | { readonly __typename: "DecisionArtifactNode"; readonly id: number }
      | { readonly __typename: "DocumentArtifactNode"; readonly id: number }
      | { readonly __typename: "FeedbackArtifactNode"; readonly id: number }
      | { readonly __typename: "GithubIssuesArtifactNode"; readonly id: number }
      | { readonly __typename: "GoalArtifactNode"; readonly id: number }
      | { readonly __typename: "KPIArtifactNode"; readonly id: number }
      | { readonly __typename: "RatingArtifactNode"; readonly id: number }
      | { readonly __typename: "RecognitionArtifactNode"; readonly id: number }
      | null;
    readonly user?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly favouriteArtifacts: {
        readonly __typename: "ArtifactConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "ArtifactEdge";
          readonly node?:
            | {
                readonly __typename: "ActionItemArtifactNode";
                readonly id: number;
              }
            | {
                readonly __typename: "AsanaTaskArtifactNode";
                readonly id: number;
              }
            | {
                readonly __typename: "DecisionArtifactNode";
                readonly id: number;
              }
            | {
                readonly __typename: "DocumentArtifactNode";
                readonly id: number;
              }
            | {
                readonly __typename: "FeedbackArtifactNode";
                readonly id: number;
              }
            | {
                readonly __typename: "GithubIssuesArtifactNode";
                readonly id: number;
              }
            | { readonly __typename: "GoalArtifactNode"; readonly id: number }
            | { readonly __typename: "KPIArtifactNode"; readonly id: number }
            | { readonly __typename: "RatingArtifactNode"; readonly id: number }
            | {
                readonly __typename: "RecognitionArtifactNode";
                readonly id: number;
              }
            | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type SearchGoalsToAlignQueryQueryVariables = Exact<{
  excludeIds:
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>;
  goalScopes: ReadonlyArray<InputMaybe<GoalScope>> | InputMaybe<GoalScope>;
  goalParentIds?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  search: Scalars["String"]["input"];
}>;

export type SearchGoalsToAlignQueryQuery = {
  readonly __typename: "Query";
  readonly artifacts?: {
    readonly __typename: "ArtifactConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "ArtifactEdge";
      readonly node?:
        | { readonly __typename: "ActionItemArtifactNode"; readonly id: number }
        | { readonly __typename: "AsanaTaskArtifactNode"; readonly id: number }
        | { readonly __typename: "DecisionArtifactNode"; readonly id: number }
        | { readonly __typename: "DocumentArtifactNode"; readonly id: number }
        | { readonly __typename: "FeedbackArtifactNode"; readonly id: number }
        | {
            readonly __typename: "GithubIssuesArtifactNode";
            readonly id: number;
          }
        | {
            readonly __typename: "GoalArtifactNode";
            readonly title?: string | null;
            readonly scope: GoalScope;
            readonly state: GoalState;
            readonly id: number;
          }
        | { readonly __typename: "KPIArtifactNode"; readonly id: number }
        | { readonly __typename: "RatingArtifactNode"; readonly id: number }
        | {
            readonly __typename: "RecognitionArtifactNode";
            readonly id: number;
          }
        | null;
    } | null>;
  } | null;
};

export type UpdateArtifactTitleMutationMutationVariables = Exact<{
  artifactId: Scalars["Int"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
  additionalFields?: InputMaybe<AdditionalArtifactInput>;
}>;

export type UpdateArtifactTitleMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateArtifact?: {
    readonly __typename: "CreateOrUpdateArtifactMutation";
    readonly artifact?:
      | {
          readonly __typename: "ActionItemArtifactNode";
          readonly id: number;
          readonly title?: string | null;
        }
      | {
          readonly __typename: "AsanaTaskArtifactNode";
          readonly id: number;
          readonly title?: string | null;
        }
      | {
          readonly __typename: "DecisionArtifactNode";
          readonly decision: string;
          readonly id: number;
          readonly title?: string | null;
        }
      | {
          readonly __typename: "DocumentArtifactNode";
          readonly id: number;
          readonly title?: string | null;
        }
      | {
          readonly __typename: "FeedbackArtifactNode";
          readonly id: number;
          readonly title?: string | null;
        }
      | {
          readonly __typename: "GithubIssuesArtifactNode";
          readonly id: number;
          readonly title?: string | null;
        }
      | {
          readonly __typename: "GoalArtifactNode";
          readonly id: number;
          readonly title?: string | null;
        }
      | {
          readonly __typename: "KPIArtifactNode";
          readonly id: number;
          readonly title?: string | null;
        }
      | {
          readonly __typename: "RatingArtifactNode";
          readonly id: number;
          readonly title?: string | null;
        }
      | {
          readonly __typename: "RecognitionArtifactNode";
          readonly id: number;
          readonly title?: string | null;
        }
      | null;
  } | null;
};

export type UpdateSidebarArtifactMutationMutationVariables = Exact<{
  artifactId: Scalars["Int"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
  additionalFields?: InputMaybe<AdditionalArtifactInput>;
  keyResultLimit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type UpdateSidebarArtifactMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateArtifact?: {
    readonly __typename: "CreateOrUpdateArtifactMutation";
    readonly artifact?:
      | {
          readonly __typename: "ActionItemArtifactNode";
          readonly isComplete?: boolean | null;
          readonly dueDate?: string | null;
          readonly id: number;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly assignee?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly avatar?: string | null;
            readonly name: string;
          } | null;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
        }
      | {
          readonly __typename: "AsanaTaskArtifactNode";
          readonly id: number;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
        }
      | {
          readonly __typename: "DecisionArtifactNode";
          readonly decision: string;
          readonly decisionState?: DecisionState | null;
          readonly isDraft?: boolean | null;
          readonly id: number;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
        }
      | {
          readonly __typename: "DocumentArtifactNode";
          readonly id: number;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
        }
      | {
          readonly __typename: "FeedbackArtifactNode";
          readonly id: number;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly recipientsCanView: boolean;
          readonly recipientsManagersCanView: boolean;
          readonly adminsCanView: boolean;
          readonly creatorCanView: boolean;
          readonly feedbackState: FeedbackState;
          readonly message?: any | null;
          readonly created: string;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly creator?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
          } | null;
          readonly feedbackRecipients: {
            readonly __typename: "UserNodeConnection";
            readonly totalCount: number;
            readonly edges: ReadonlyArray<{
              readonly __typename: "UserNodeEdge";
              readonly node?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly avatar?: string | null;
                readonly name: string;
              } | null;
            } | null>;
          };
          readonly sender: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly avatar?: string | null;
            readonly name: string;
          };
        }
      | {
          readonly __typename: "GithubIssuesArtifactNode";
          readonly id: number;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
        }
      | {
          readonly __typename: "GoalArtifactNode";
          readonly id: number;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly parentGoalId?: number | null;
          readonly created: string;
          readonly state: GoalState;
          readonly status: GoalStatus;
          readonly scope: GoalScope;
          readonly goalVisibility: GoalVisibility;
          readonly dueDate?: string | null;
          readonly startDate?: string | null;
          readonly progress: number;
          readonly progressType: GoalProgressType;
          readonly startValue: number;
          readonly currentValue: number;
          readonly targetValue: number;
          readonly isStale: boolean;
          readonly ownerCount?: number | null;
          readonly contributorCount?: number | null;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly childGoals?: {
            readonly __typename: "GoalArtifactConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "GoalArtifactEdge";
              readonly node?: {
                readonly __typename: "GoalArtifactNode";
                readonly id: number;
                readonly artifactType: ArtifactType;
                readonly title?: string | null;
                readonly updated?: string | null;
                readonly created: string;
                readonly state: GoalState;
                readonly scope: GoalScope;
                readonly ownerCount?: number | null;
                readonly dueDate?: string | null;
                readonly goalVisibility: GoalVisibility;
                readonly status: GoalStatus;
                readonly progress: number;
                readonly progressType: GoalProgressType;
                readonly currentValue: number;
                readonly startValue: number;
                readonly targetValue: number;
                readonly isStale: boolean;
                readonly parentGoalId?: number | null;
                readonly canUpdate: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                };
                readonly canDelete: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                };
                readonly creator?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
                readonly firstOwner?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
                readonly owners?: {
                  readonly __typename: "UserNodeConnection";
                  readonly edges: ReadonlyArray<{
                    readonly __typename: "UserNodeEdge";
                    readonly node?: {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    } | null;
                  } | null>;
                } | null;
                readonly activities: {
                  readonly __typename: "ArtifactActivityNodeConnection";
                  readonly edges: ReadonlyArray<{
                    readonly __typename: "ArtifactActivityNodeEdge";
                    readonly node?: {
                      readonly __typename: "ArtifactActivityNode";
                      readonly created: string;
                    } | null;
                  } | null>;
                };
              } | null;
            } | null>;
          } | null;
          readonly parentGoal?: {
            readonly __typename: "GoalArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly updated?: string | null;
            readonly created: string;
            readonly state: GoalState;
            readonly scope: GoalScope;
            readonly ownerCount?: number | null;
            readonly dueDate?: string | null;
            readonly goalVisibility: GoalVisibility;
            readonly status: GoalStatus;
            readonly progress: number;
            readonly progressType: GoalProgressType;
            readonly currentValue: number;
            readonly startValue: number;
            readonly targetValue: number;
            readonly isStale: boolean;
            readonly parentGoalId?: number | null;
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly creator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
            readonly firstOwner?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
            readonly owners?: {
              readonly __typename: "UserNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "UserNodeEdge";
                readonly node?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
              } | null>;
            } | null;
            readonly activities: {
              readonly __typename: "ArtifactActivityNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "ArtifactActivityNodeEdge";
                readonly node?: {
                  readonly __typename: "ArtifactActivityNode";
                  readonly created: string;
                } | null;
              } | null>;
            };
          } | null;
          readonly topLevelGoal?: {
            readonly __typename: "GoalArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly updated?: string | null;
            readonly created: string;
            readonly state: GoalState;
            readonly scope: GoalScope;
            readonly ownerCount?: number | null;
            readonly dueDate?: string | null;
            readonly goalVisibility: GoalVisibility;
            readonly status: GoalStatus;
            readonly progress: number;
            readonly progressType: GoalProgressType;
            readonly currentValue: number;
            readonly startValue: number;
            readonly targetValue: number;
            readonly isStale: boolean;
            readonly parentGoalId?: number | null;
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly creator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
            readonly firstOwner?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
            readonly owners?: {
              readonly __typename: "UserNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "UserNodeEdge";
                readonly node?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
              } | null>;
            } | null;
            readonly activities: {
              readonly __typename: "ArtifactActivityNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "ArtifactActivityNodeEdge";
                readonly node?: {
                  readonly __typename: "ArtifactActivityNode";
                  readonly created: string;
                } | null;
              } | null>;
            };
          } | null;
          readonly kpi?: {
            readonly __typename: "KPINode";
            readonly id: number;
            readonly title: string;
            readonly currentMeasurement?: {
              readonly __typename: "KPIMeasurementNode";
              readonly id: number;
              readonly measurement: number;
            } | null;
          } | null;
          readonly owners?: {
            readonly __typename: "UserNodeConnection";
            readonly totalCount: number;
            readonly edges: ReadonlyArray<{
              readonly __typename: "UserNodeEdge";
              readonly node?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly avatar?: string | null;
                readonly name: string;
              } | null;
            } | null>;
          } | null;
          readonly contributors?: {
            readonly __typename: "UserNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "UserNodeEdge";
              readonly node?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly avatar?: string | null;
                readonly name: string;
              } | null;
            } | null>;
          } | null;
          readonly keyResults: {
            readonly __typename: "KeyResultNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "KeyResultNodeEdge";
              readonly node?: {
                readonly __typename: "KeyResultNode";
                readonly id: number;
                readonly title: string;
                readonly startValue: number;
                readonly currentValue: number;
                readonly targetValue: number;
                readonly progressType: GoalProgressType;
                readonly kpi?: {
                  readonly __typename: "KPINode";
                  readonly id: number;
                  readonly title: string;
                  readonly currentMeasurement?: {
                    readonly __typename: "KPIMeasurementNode";
                    readonly id: number;
                    readonly measurement: number;
                  } | null;
                } | null;
                readonly canUpdate?: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                } | null;
                readonly assignee?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                  readonly email: string;
                } | null;
              } | null;
            } | null>;
          };
          readonly firstOwner?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly avatar?: string | null;
            readonly name: string;
          } | null;
          readonly canUpdate: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canRead: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly teams?: {
            readonly __typename: "TeamNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "TeamNodeEdge";
              readonly node?: {
                readonly __typename: "TeamNode";
                readonly id: number;
                readonly title: string;
              } | null;
            } | null>;
          } | null;
        }
      | {
          readonly __typename: "KPIArtifactNode";
          readonly id: number;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
        }
      | {
          readonly __typename: "RatingArtifactNode";
          readonly id: number;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
        }
      | {
          readonly __typename: "RecognitionArtifactNode";
          readonly id: number;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly created: string;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly creator?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
          } | null;
          readonly recipients?: {
            readonly __typename: "UserNodeConnection";
            readonly totalCount: number;
            readonly edges: ReadonlyArray<{
              readonly __typename: "UserNodeEdge";
              readonly node?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
            } | null>;
          } | null;
          readonly recognitionCoreValue?: {
            readonly __typename: "RecognitionCoreValueNode";
            readonly id: number;
            readonly title: string;
            readonly description: string;
            readonly emoji?: string | null;
            readonly image?: string | null;
          } | null;
        }
      | null;
  } | null;
};

export type GoalArtifactComponentFragmentFragment = {
  readonly __typename: "GoalArtifactNode";
  readonly id: number;
  readonly artifactType: ArtifactType;
  readonly title?: string | null;
  readonly updated?: string | null;
  readonly state: GoalState;
  readonly scope: GoalScope;
  readonly ownerCount?: number | null;
  readonly dueDate?: string | null;
  readonly goalVisibility: GoalVisibility;
  readonly status: GoalStatus;
  readonly progress: number;
  readonly progressType: GoalProgressType;
  readonly currentValue: number;
  readonly startValue: number;
  readonly targetValue: number;
  readonly isStale: boolean;
  readonly parentGoalId?: number | null;
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly creator?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
  readonly firstOwner?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
  readonly owners?: {
    readonly __typename: "UserNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "UserNodeEdge";
      readonly node?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly avatar?: string | null;
      } | null;
    } | null>;
  } | null;
  readonly activities: {
    readonly __typename: "ArtifactActivityNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "ArtifactActivityNodeEdge";
      readonly node?: {
        readonly __typename: "ArtifactActivityNode";
        readonly created: string;
      } | null;
    } | null>;
  };
};

export type RecognitionArtifactComponentFragmentFragment = {
  readonly __typename: "RecognitionArtifactNode";
  readonly recognitionCoreValue?: {
    readonly __typename: "RecognitionCoreValueNode";
    readonly id: number;
    readonly title: string;
    readonly emoji?: string | null;
    readonly image?: string | null;
  } | null;
  readonly recipients?: {
    readonly __typename: "UserNodeConnection";
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly __typename: "UserNodeEdge";
      readonly node?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly avatar?: string | null;
      } | null;
    } | null>;
  } | null;
};

type ArtifactComponentFragment_ActionItemArtifactNode_Fragment = {
  readonly __typename: "ActionItemArtifactNode";
  readonly isComplete?: boolean | null;
  readonly id: number;
  readonly artifactType: ArtifactType;
  readonly title?: string | null;
  readonly updated?: string | null;
  readonly created: string;
  readonly assignee?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly creator?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
};

type ArtifactComponentFragment_AsanaTaskArtifactNode_Fragment = {
  readonly __typename: "AsanaTaskArtifactNode";
  readonly id: number;
  readonly artifactType: ArtifactType;
  readonly title?: string | null;
  readonly updated?: string | null;
  readonly created: string;
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly creator?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
};

type ArtifactComponentFragment_DecisionArtifactNode_Fragment = {
  readonly __typename: "DecisionArtifactNode";
  readonly decision: string;
  readonly decisionState?: DecisionState | null;
  readonly isDraft?: boolean | null;
  readonly id: number;
  readonly artifactType: ArtifactType;
  readonly title?: string | null;
  readonly updated?: string | null;
  readonly created: string;
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly creator?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
};

type ArtifactComponentFragment_DocumentArtifactNode_Fragment = {
  readonly __typename: "DocumentArtifactNode";
  readonly id: number;
  readonly artifactType: ArtifactType;
  readonly title?: string | null;
  readonly updated?: string | null;
  readonly created: string;
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly creator?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
};

type ArtifactComponentFragment_FeedbackArtifactNode_Fragment = {
  readonly __typename: "FeedbackArtifactNode";
  readonly id: number;
  readonly artifactType: ArtifactType;
  readonly title?: string | null;
  readonly updated?: string | null;
  readonly created: string;
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly creator?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
};

type ArtifactComponentFragment_GithubIssuesArtifactNode_Fragment = {
  readonly __typename: "GithubIssuesArtifactNode";
  readonly id: number;
  readonly artifactType: ArtifactType;
  readonly title?: string | null;
  readonly updated?: string | null;
  readonly created: string;
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly creator?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
};

type ArtifactComponentFragment_GoalArtifactNode_Fragment = {
  readonly __typename: "GoalArtifactNode";
  readonly id: number;
  readonly artifactType: ArtifactType;
  readonly title?: string | null;
  readonly updated?: string | null;
  readonly created: string;
  readonly state: GoalState;
  readonly scope: GoalScope;
  readonly ownerCount?: number | null;
  readonly dueDate?: string | null;
  readonly goalVisibility: GoalVisibility;
  readonly status: GoalStatus;
  readonly progress: number;
  readonly progressType: GoalProgressType;
  readonly currentValue: number;
  readonly startValue: number;
  readonly targetValue: number;
  readonly isStale: boolean;
  readonly parentGoalId?: number | null;
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly creator?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
  readonly firstOwner?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
  readonly owners?: {
    readonly __typename: "UserNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "UserNodeEdge";
      readonly node?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly avatar?: string | null;
      } | null;
    } | null>;
  } | null;
  readonly activities: {
    readonly __typename: "ArtifactActivityNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "ArtifactActivityNodeEdge";
      readonly node?: {
        readonly __typename: "ArtifactActivityNode";
        readonly created: string;
      } | null;
    } | null>;
  };
};

type ArtifactComponentFragment_KpiArtifactNode_Fragment = {
  readonly __typename: "KPIArtifactNode";
  readonly id: number;
  readonly artifactType: ArtifactType;
  readonly title?: string | null;
  readonly updated?: string | null;
  readonly created: string;
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly creator?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
};

type ArtifactComponentFragment_RatingArtifactNode_Fragment = {
  readonly __typename: "RatingArtifactNode";
  readonly id: number;
  readonly artifactType: ArtifactType;
  readonly title?: string | null;
  readonly updated?: string | null;
  readonly created: string;
  readonly rating?: {
    readonly __typename: "RatingNode";
    readonly id: number;
    readonly title: string;
  } | null;
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly creator?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
};

type ArtifactComponentFragment_RecognitionArtifactNode_Fragment = {
  readonly __typename: "RecognitionArtifactNode";
  readonly id: number;
  readonly artifactType: ArtifactType;
  readonly title?: string | null;
  readonly updated?: string | null;
  readonly created: string;
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly creator?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
  readonly recognitionCoreValue?: {
    readonly __typename: "RecognitionCoreValueNode";
    readonly id: number;
    readonly title: string;
    readonly emoji?: string | null;
    readonly image?: string | null;
  } | null;
  readonly recipients?: {
    readonly __typename: "UserNodeConnection";
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly __typename: "UserNodeEdge";
      readonly node?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly avatar?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type ArtifactComponentFragmentFragment =
  | ArtifactComponentFragment_ActionItemArtifactNode_Fragment
  | ArtifactComponentFragment_AsanaTaskArtifactNode_Fragment
  | ArtifactComponentFragment_DecisionArtifactNode_Fragment
  | ArtifactComponentFragment_DocumentArtifactNode_Fragment
  | ArtifactComponentFragment_FeedbackArtifactNode_Fragment
  | ArtifactComponentFragment_GithubIssuesArtifactNode_Fragment
  | ArtifactComponentFragment_GoalArtifactNode_Fragment
  | ArtifactComponentFragment_KpiArtifactNode_Fragment
  | ArtifactComponentFragment_RatingArtifactNode_Fragment
  | ArtifactComponentFragment_RecognitionArtifactNode_Fragment;

export type DeleteArtifactMutationMutationVariables = Exact<{
  artifactId: Scalars["Int"]["input"];
}>;

export type DeleteArtifactMutationMutation = {
  readonly __typename: "Mutation";
  readonly deleteArtifact?: {
    readonly __typename: "DeleteArtifactMutation";
    readonly artifact?:
      | { readonly __typename: "ActionItemArtifactNode"; readonly id: number }
      | { readonly __typename: "AsanaTaskArtifactNode"; readonly id: number }
      | { readonly __typename: "DecisionArtifactNode"; readonly id: number }
      | { readonly __typename: "DocumentArtifactNode"; readonly id: number }
      | { readonly __typename: "FeedbackArtifactNode"; readonly id: number }
      | { readonly __typename: "GithubIssuesArtifactNode"; readonly id: number }
      | { readonly __typename: "GoalArtifactNode"; readonly id: number }
      | { readonly __typename: "KPIArtifactNode"; readonly id: number }
      | { readonly __typename: "RatingArtifactNode"; readonly id: number }
      | { readonly __typename: "RecognitionArtifactNode"; readonly id: number }
      | null;
  } | null;
};

export type GetAllOrganizationTeamsQueryQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
}>;

export type GetAllOrganizationTeamsQueryQuery = {
  readonly __typename: "Query";
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
    readonly teams?: {
      readonly __typename: "TeamNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "TeamNodeEdge";
        readonly node?: {
          readonly __typename: "TeamNode";
          readonly id: number;
          readonly title: string;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type GetGoalParticipantsQueryQueryVariables = Exact<{
  artifactId: Scalars["Int"]["input"];
}>;

export type GetGoalParticipantsQueryQuery = {
  readonly __typename: "Query";
  readonly artifact:
    | { readonly __typename: "ActionItemArtifactNode"; readonly id: number }
    | { readonly __typename: "AsanaTaskArtifactNode"; readonly id: number }
    | { readonly __typename: "DecisionArtifactNode"; readonly id: number }
    | { readonly __typename: "DocumentArtifactNode"; readonly id: number }
    | { readonly __typename: "FeedbackArtifactNode"; readonly id: number }
    | { readonly __typename: "GithubIssuesArtifactNode"; readonly id: number }
    | {
        readonly __typename: "GoalArtifactNode";
        readonly id: number;
        readonly canRead: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly keyResults: {
          readonly __typename: "KeyResultNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "KeyResultNodeEdge";
            readonly node?: {
              readonly __typename: "KeyResultNode";
              readonly id: number;
              readonly title: string;
              readonly assignee?: {
                readonly __typename: "UserNode";
                readonly id: number;
              } | null;
            } | null;
          } | null>;
        };
        readonly owners?: {
          readonly __typename: "UserNodeConnection";
          readonly totalCount: number;
          readonly edges: ReadonlyArray<{
            readonly __typename: "UserNodeEdge";
            readonly node?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly avatar?: string | null;
              readonly name: string;
            } | null;
          } | null>;
        } | null;
        readonly contributors?: {
          readonly __typename: "UserNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "UserNodeEdge";
            readonly node?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly avatar?: string | null;
              readonly name: string;
            } | null;
          } | null>;
        } | null;
      }
    | { readonly __typename: "KPIArtifactNode"; readonly id: number }
    | { readonly __typename: "RatingArtifactNode"; readonly id: number }
    | { readonly __typename: "RecognitionArtifactNode"; readonly id: number };
};

export type UpdateArtifactMutationVariables = Exact<{
  artifactId: Scalars["Int"]["input"];
  additionalFields: AdditionalArtifactInput;
}>;

export type UpdateArtifactMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateArtifact?: {
    readonly __typename: "CreateOrUpdateArtifactMutation";
    readonly artifact?:
      | {
          readonly __typename: "ActionItemArtifactNode";
          readonly isComplete?: boolean | null;
          readonly actionItemState?: number | null;
          readonly completedAt?: string | null;
          readonly dueDate?: string | null;
          readonly id: number;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly assignee?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly avatar?: string | null;
            readonly name: string;
          } | null;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
        }
      | {
          readonly __typename: "AsanaTaskArtifactNode";
          readonly id: number;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
        }
      | {
          readonly __typename: "DecisionArtifactNode";
          readonly isDraft?: boolean | null;
          readonly decisionState?: DecisionState | null;
          readonly id: number;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
        }
      | {
          readonly __typename: "DocumentArtifactNode";
          readonly id: number;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
        }
      | {
          readonly __typename: "FeedbackArtifactNode";
          readonly id: number;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
        }
      | {
          readonly __typename: "GithubIssuesArtifactNode";
          readonly id: number;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
        }
      | {
          readonly __typename: "GoalArtifactNode";
          readonly id: number;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly created: string;
          readonly state: GoalState;
          readonly status: GoalStatus;
          readonly scope: GoalScope;
          readonly goalVisibility: GoalVisibility;
          readonly dueDate?: string | null;
          readonly startDate?: string | null;
          readonly progress: number;
          readonly progressType: GoalProgressType;
          readonly startValue: number;
          readonly currentValue: number;
          readonly targetValue: number;
          readonly isStale: boolean;
          readonly ownerCount?: number | null;
          readonly contributorCount?: number | null;
          readonly kpi?: {
            readonly __typename: "KPINode";
            readonly id: number;
            readonly title: string;
            readonly currentMeasurement?: {
              readonly __typename: "KPIMeasurementNode";
              readonly id: number;
              readonly measurement: number;
            } | null;
          } | null;
          readonly contributors?: {
            readonly __typename: "UserNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "UserNodeEdge";
              readonly node?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
            } | null>;
          } | null;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly firstOwner?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly avatar?: string | null;
            readonly name: string;
          } | null;
          readonly canUpdate: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canRead: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
        }
      | {
          readonly __typename: "KPIArtifactNode";
          readonly id: number;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
        }
      | {
          readonly __typename: "RatingArtifactNode";
          readonly id: number;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
        }
      | {
          readonly __typename: "RecognitionArtifactNode";
          readonly id: number;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly created: string;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly creator?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
          } | null;
          readonly recipients?: {
            readonly __typename: "UserNodeConnection";
            readonly totalCount: number;
            readonly edges: ReadonlyArray<{
              readonly __typename: "UserNodeEdge";
              readonly node?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
            } | null>;
          } | null;
          readonly recognitionCoreValue?: {
            readonly __typename: "RecognitionCoreValueNode";
            readonly id: number;
            readonly title: string;
            readonly description: string;
            readonly emoji?: string | null;
            readonly image?: string | null;
          } | null;
        }
      | null;
  } | null;
};

export type UpdateGoalParticipantsMutationMutationVariables = Exact<{
  artifactId: Scalars["Int"]["input"];
  additionalFields: AdditionalArtifactInput;
}>;

export type UpdateGoalParticipantsMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateArtifact?: {
    readonly __typename: "CreateOrUpdateArtifactMutation";
    readonly artifact?:
      | { readonly __typename: "ActionItemArtifactNode" }
      | { readonly __typename: "AsanaTaskArtifactNode" }
      | { readonly __typename: "DecisionArtifactNode" }
      | { readonly __typename: "DocumentArtifactNode" }
      | { readonly __typename: "FeedbackArtifactNode" }
      | { readonly __typename: "GithubIssuesArtifactNode" }
      | {
          readonly __typename: "GoalArtifactNode";
          readonly id: number;
          readonly ownerCount?: number | null;
          readonly contributorCount?: number | null;
          readonly canRead: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly keyResults: {
            readonly __typename: "KeyResultNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "KeyResultNodeEdge";
              readonly node?: {
                readonly __typename: "KeyResultNode";
                readonly id: number;
                readonly assignee?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                } | null;
              } | null;
            } | null>;
          };
          readonly firstOwner?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly avatar?: string | null;
            readonly name: string;
          } | null;
          readonly owners?: {
            readonly __typename: "UserNodeConnection";
            readonly totalCount: number;
            readonly edges: ReadonlyArray<{
              readonly __typename: "UserNodeEdge";
              readonly node?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
            } | null>;
          } | null;
          readonly contributors?: {
            readonly __typename: "UserNodeConnection";
            readonly totalCount: number;
            readonly edges: ReadonlyArray<{
              readonly __typename: "UserNodeEdge";
              readonly node?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
            } | null>;
          } | null;
        }
      | { readonly __typename: "KPIArtifactNode" }
      | { readonly __typename: "RatingArtifactNode" }
      | { readonly __typename: "RecognitionArtifactNode" }
      | null;
  } | null;
};

export type UpdateGoalTeamsMutationMutationVariables = Exact<{
  artifactId: Scalars["Int"]["input"];
  additionalFields: AdditionalArtifactInput;
}>;

export type UpdateGoalTeamsMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateArtifact?: {
    readonly __typename: "CreateOrUpdateArtifactMutation";
    readonly artifact?:
      | { readonly __typename: "ActionItemArtifactNode"; readonly id: number }
      | { readonly __typename: "AsanaTaskArtifactNode"; readonly id: number }
      | { readonly __typename: "DecisionArtifactNode"; readonly id: number }
      | { readonly __typename: "DocumentArtifactNode"; readonly id: number }
      | { readonly __typename: "FeedbackArtifactNode"; readonly id: number }
      | { readonly __typename: "GithubIssuesArtifactNode"; readonly id: number }
      | {
          readonly __typename: "GoalArtifactNode";
          readonly id: number;
          readonly title?: string | null;
          readonly created: string;
          readonly artifactType: ArtifactType;
          readonly state: GoalState;
          readonly status: GoalStatus;
          readonly scope: GoalScope;
          readonly goalVisibility: GoalVisibility;
          readonly dueDate?: string | null;
          readonly startDate?: string | null;
          readonly progress: number;
          readonly progressType: GoalProgressType;
          readonly startValue: number;
          readonly currentValue: number;
          readonly targetValue: number;
          readonly isStale: boolean;
          readonly ownerCount?: number | null;
          readonly contributorCount?: number | null;
          readonly firstOwner?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly avatar?: string | null;
            readonly name: string;
          } | null;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canUpdate: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canRead: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly teams?: {
            readonly __typename: "TeamNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "TeamNodeEdge";
              readonly node?: {
                readonly __typename: "TeamNode";
                readonly id: number;
                readonly title: string;
              } | null;
            } | null>;
          } | null;
        }
      | { readonly __typename: "KPIArtifactNode"; readonly id: number }
      | { readonly __typename: "RatingArtifactNode"; readonly id: number }
      | { readonly __typename: "RecognitionArtifactNode"; readonly id: number }
      | null;
  } | null;
};

export type UpdateGoalVisibilityMutationMutationVariables = Exact<{
  artifactId: Scalars["Int"]["input"];
  additionalFields: AdditionalArtifactInput;
}>;

export type UpdateGoalVisibilityMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateArtifact?: {
    readonly __typename: "CreateOrUpdateArtifactMutation";
    readonly artifact?:
      | { readonly __typename: "ActionItemArtifactNode" }
      | { readonly __typename: "AsanaTaskArtifactNode" }
      | { readonly __typename: "DecisionArtifactNode" }
      | { readonly __typename: "DocumentArtifactNode" }
      | { readonly __typename: "FeedbackArtifactNode" }
      | { readonly __typename: "GithubIssuesArtifactNode" }
      | {
          readonly __typename: "GoalArtifactNode";
          readonly id: number;
          readonly goalVisibility: GoalVisibility;
          readonly canRead: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
        }
      | { readonly __typename: "KPIArtifactNode" }
      | { readonly __typename: "RatingArtifactNode" }
      | { readonly __typename: "RecognitionArtifactNode" }
      | null;
  } | null;
};

export type AskAiSessionChatMessageFragment = {
  readonly __typename: "ChatMessageNode";
  readonly id: string;
  readonly content: string;
  readonly role: ChatbotRoleEnum;
  readonly autogenerated?: boolean | null;
  readonly actionItems?: {
    readonly __typename: "ActionItemArtifactConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "ActionItemArtifactEdge";
      readonly node?: {
        readonly __typename: "ActionItemArtifactNode";
        readonly id: number;
        readonly isComplete?: boolean | null;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly updated?: string | null;
        readonly created: string;
        readonly assignee?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
  readonly goals?: {
    readonly __typename: "GoalArtifactConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "GoalArtifactEdge";
      readonly node?: {
        readonly __typename: "GoalArtifactNode";
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly updated?: string | null;
        readonly created: string;
        readonly state: GoalState;
        readonly scope: GoalScope;
        readonly ownerCount?: number | null;
        readonly dueDate?: string | null;
        readonly goalVisibility: GoalVisibility;
        readonly status: GoalStatus;
        readonly progress: number;
        readonly progressType: GoalProgressType;
        readonly currentValue: number;
        readonly startValue: number;
        readonly targetValue: number;
        readonly isStale: boolean;
        readonly parentGoalId?: number | null;
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly firstOwner?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly owners?: {
          readonly __typename: "UserNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "UserNodeEdge";
            readonly node?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
          } | null>;
        } | null;
        readonly activities: {
          readonly __typename: "ArtifactActivityNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactActivityNodeEdge";
            readonly node?: {
              readonly __typename: "ArtifactActivityNode";
              readonly created: string;
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  } | null;
  readonly recognitions?: {
    readonly __typename: "RecognitionArtifactConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "RecognitionArtifactEdge";
      readonly node?: {
        readonly __typename: "RecognitionArtifactNode";
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly updated?: string | null;
        readonly created: string;
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly recognitionCoreValue?: {
          readonly __typename: "RecognitionCoreValueNode";
          readonly id: number;
          readonly title: string;
          readonly emoji?: string | null;
          readonly image?: string | null;
        } | null;
        readonly recipients?: {
          readonly __typename: "UserNodeConnection";
          readonly totalCount: number;
          readonly edges: ReadonlyArray<{
            readonly __typename: "UserNodeEdge";
            readonly node?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  } | null;
  readonly suggestedRecognition?: {
    readonly __typename: "SuggestedRecognitionNode";
    readonly title?: string | null;
    readonly coreValue?: {
      readonly __typename: "RecognitionCoreValueNode";
      readonly id: number;
      readonly title: string;
      readonly emoji?: string | null;
      readonly image?: string | null;
    } | null;
    readonly suggestedAssignee?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
      readonly avatar?: string | null;
    } | null;
  } | null;
  readonly createdArtifact?:
    | {
        readonly __typename: "ActionItemArtifactNode";
        readonly id: number;
        readonly isComplete?: boolean | null;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly updated?: string | null;
        readonly created: string;
        readonly assignee?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
      }
    | {
        readonly __typename: "AsanaTaskArtifactNode";
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly updated?: string | null;
        readonly created: string;
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
      }
    | {
        readonly __typename: "DecisionArtifactNode";
        readonly id: number;
        readonly decision: string;
        readonly decisionState?: DecisionState | null;
        readonly isDraft?: boolean | null;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly updated?: string | null;
        readonly created: string;
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
      }
    | {
        readonly __typename: "DocumentArtifactNode";
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly updated?: string | null;
        readonly created: string;
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
      }
    | {
        readonly __typename: "FeedbackArtifactNode";
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly updated?: string | null;
        readonly created: string;
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
      }
    | {
        readonly __typename: "GithubIssuesArtifactNode";
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly updated?: string | null;
        readonly created: string;
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
      }
    | {
        readonly __typename: "GoalArtifactNode";
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly updated?: string | null;
        readonly created: string;
        readonly state: GoalState;
        readonly scope: GoalScope;
        readonly ownerCount?: number | null;
        readonly dueDate?: string | null;
        readonly goalVisibility: GoalVisibility;
        readonly status: GoalStatus;
        readonly progress: number;
        readonly progressType: GoalProgressType;
        readonly currentValue: number;
        readonly startValue: number;
        readonly targetValue: number;
        readonly isStale: boolean;
        readonly parentGoalId?: number | null;
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly firstOwner?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly owners?: {
          readonly __typename: "UserNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "UserNodeEdge";
            readonly node?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
          } | null>;
        } | null;
        readonly activities: {
          readonly __typename: "ArtifactActivityNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactActivityNodeEdge";
            readonly node?: {
              readonly __typename: "ArtifactActivityNode";
              readonly created: string;
            } | null;
          } | null>;
        };
      }
    | {
        readonly __typename: "KPIArtifactNode";
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly updated?: string | null;
        readonly created: string;
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
      }
    | {
        readonly __typename: "RatingArtifactNode";
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly updated?: string | null;
        readonly created: string;
        readonly rating?: {
          readonly __typename: "RatingNode";
          readonly id: number;
          readonly title: string;
        } | null;
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
      }
    | {
        readonly __typename: "RecognitionArtifactNode";
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly updated?: string | null;
        readonly created: string;
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly recognitionCoreValue?: {
          readonly __typename: "RecognitionCoreValueNode";
          readonly id: number;
          readonly title: string;
          readonly emoji?: string | null;
          readonly image?: string | null;
        } | null;
        readonly recipients?: {
          readonly __typename: "UserNodeConnection";
          readonly totalCount: number;
          readonly edges: ReadonlyArray<{
            readonly __typename: "UserNodeEdge";
            readonly node?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
          } | null>;
        } | null;
      }
    | null;
};

export type GetAskAiChatSessionMessagesQueryQueryVariables = Exact<{
  chatSessionId: Scalars["Int"]["input"];
}>;

export type GetAskAiChatSessionMessagesQueryQuery = {
  readonly __typename: "Query";
  readonly chatSession?: {
    readonly __typename: "ChatSessionNode";
    readonly id: number;
    readonly messages?: {
      readonly __typename: "ChatMessageConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "ChatMessageEdge";
        readonly node?: {
          readonly __typename: "ChatMessageNode";
          readonly id: string;
          readonly content: string;
          readonly role: ChatbotRoleEnum;
          readonly autogenerated?: boolean | null;
          readonly actionItems?: {
            readonly __typename: "ActionItemArtifactConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "ActionItemArtifactEdge";
              readonly node?: {
                readonly __typename: "ActionItemArtifactNode";
                readonly id: number;
                readonly isComplete?: boolean | null;
                readonly artifactType: ArtifactType;
                readonly title?: string | null;
                readonly updated?: string | null;
                readonly created: string;
                readonly assignee?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
                readonly canUpdate: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                };
                readonly canDelete: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                };
                readonly creator?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
              } | null;
            } | null>;
          } | null;
          readonly goals?: {
            readonly __typename: "GoalArtifactConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "GoalArtifactEdge";
              readonly node?: {
                readonly __typename: "GoalArtifactNode";
                readonly id: number;
                readonly artifactType: ArtifactType;
                readonly title?: string | null;
                readonly updated?: string | null;
                readonly created: string;
                readonly state: GoalState;
                readonly scope: GoalScope;
                readonly ownerCount?: number | null;
                readonly dueDate?: string | null;
                readonly goalVisibility: GoalVisibility;
                readonly status: GoalStatus;
                readonly progress: number;
                readonly progressType: GoalProgressType;
                readonly currentValue: number;
                readonly startValue: number;
                readonly targetValue: number;
                readonly isStale: boolean;
                readonly parentGoalId?: number | null;
                readonly canUpdate: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                };
                readonly canDelete: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                };
                readonly creator?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
                readonly firstOwner?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
                readonly owners?: {
                  readonly __typename: "UserNodeConnection";
                  readonly edges: ReadonlyArray<{
                    readonly __typename: "UserNodeEdge";
                    readonly node?: {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    } | null;
                  } | null>;
                } | null;
                readonly activities: {
                  readonly __typename: "ArtifactActivityNodeConnection";
                  readonly edges: ReadonlyArray<{
                    readonly __typename: "ArtifactActivityNodeEdge";
                    readonly node?: {
                      readonly __typename: "ArtifactActivityNode";
                      readonly created: string;
                    } | null;
                  } | null>;
                };
              } | null;
            } | null>;
          } | null;
          readonly recognitions?: {
            readonly __typename: "RecognitionArtifactConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "RecognitionArtifactEdge";
              readonly node?: {
                readonly __typename: "RecognitionArtifactNode";
                readonly id: number;
                readonly artifactType: ArtifactType;
                readonly title?: string | null;
                readonly updated?: string | null;
                readonly created: string;
                readonly canUpdate: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                };
                readonly canDelete: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                };
                readonly creator?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
                readonly recognitionCoreValue?: {
                  readonly __typename: "RecognitionCoreValueNode";
                  readonly id: number;
                  readonly title: string;
                  readonly emoji?: string | null;
                  readonly image?: string | null;
                } | null;
                readonly recipients?: {
                  readonly __typename: "UserNodeConnection";
                  readonly totalCount: number;
                  readonly edges: ReadonlyArray<{
                    readonly __typename: "UserNodeEdge";
                    readonly node?: {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    } | null;
                  } | null>;
                } | null;
              } | null;
            } | null>;
          } | null;
          readonly suggestedRecognition?: {
            readonly __typename: "SuggestedRecognitionNode";
            readonly title?: string | null;
            readonly coreValue?: {
              readonly __typename: "RecognitionCoreValueNode";
              readonly id: number;
              readonly title: string;
              readonly emoji?: string | null;
              readonly image?: string | null;
            } | null;
            readonly suggestedAssignee?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
          } | null;
          readonly createdArtifact?:
            | {
                readonly __typename: "ActionItemArtifactNode";
                readonly id: number;
                readonly isComplete?: boolean | null;
                readonly artifactType: ArtifactType;
                readonly title?: string | null;
                readonly updated?: string | null;
                readonly created: string;
                readonly assignee?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
                readonly canUpdate: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                };
                readonly canDelete: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                };
                readonly creator?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
              }
            | {
                readonly __typename: "AsanaTaskArtifactNode";
                readonly id: number;
                readonly artifactType: ArtifactType;
                readonly title?: string | null;
                readonly updated?: string | null;
                readonly created: string;
                readonly canUpdate: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                };
                readonly canDelete: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                };
                readonly creator?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
              }
            | {
                readonly __typename: "DecisionArtifactNode";
                readonly id: number;
                readonly decision: string;
                readonly decisionState?: DecisionState | null;
                readonly isDraft?: boolean | null;
                readonly artifactType: ArtifactType;
                readonly title?: string | null;
                readonly updated?: string | null;
                readonly created: string;
                readonly canUpdate: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                };
                readonly canDelete: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                };
                readonly creator?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
              }
            | {
                readonly __typename: "DocumentArtifactNode";
                readonly id: number;
                readonly artifactType: ArtifactType;
                readonly title?: string | null;
                readonly updated?: string | null;
                readonly created: string;
                readonly canUpdate: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                };
                readonly canDelete: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                };
                readonly creator?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
              }
            | {
                readonly __typename: "FeedbackArtifactNode";
                readonly id: number;
                readonly artifactType: ArtifactType;
                readonly title?: string | null;
                readonly updated?: string | null;
                readonly created: string;
                readonly canUpdate: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                };
                readonly canDelete: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                };
                readonly creator?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
              }
            | {
                readonly __typename: "GithubIssuesArtifactNode";
                readonly id: number;
                readonly artifactType: ArtifactType;
                readonly title?: string | null;
                readonly updated?: string | null;
                readonly created: string;
                readonly canUpdate: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                };
                readonly canDelete: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                };
                readonly creator?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
              }
            | {
                readonly __typename: "GoalArtifactNode";
                readonly id: number;
                readonly artifactType: ArtifactType;
                readonly title?: string | null;
                readonly updated?: string | null;
                readonly created: string;
                readonly state: GoalState;
                readonly scope: GoalScope;
                readonly ownerCount?: number | null;
                readonly dueDate?: string | null;
                readonly goalVisibility: GoalVisibility;
                readonly status: GoalStatus;
                readonly progress: number;
                readonly progressType: GoalProgressType;
                readonly currentValue: number;
                readonly startValue: number;
                readonly targetValue: number;
                readonly isStale: boolean;
                readonly parentGoalId?: number | null;
                readonly canUpdate: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                };
                readonly canDelete: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                };
                readonly creator?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
                readonly firstOwner?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
                readonly owners?: {
                  readonly __typename: "UserNodeConnection";
                  readonly edges: ReadonlyArray<{
                    readonly __typename: "UserNodeEdge";
                    readonly node?: {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    } | null;
                  } | null>;
                } | null;
                readonly activities: {
                  readonly __typename: "ArtifactActivityNodeConnection";
                  readonly edges: ReadonlyArray<{
                    readonly __typename: "ArtifactActivityNodeEdge";
                    readonly node?: {
                      readonly __typename: "ArtifactActivityNode";
                      readonly created: string;
                    } | null;
                  } | null>;
                };
              }
            | {
                readonly __typename: "KPIArtifactNode";
                readonly id: number;
                readonly artifactType: ArtifactType;
                readonly title?: string | null;
                readonly updated?: string | null;
                readonly created: string;
                readonly canUpdate: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                };
                readonly canDelete: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                };
                readonly creator?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
              }
            | {
                readonly __typename: "RatingArtifactNode";
                readonly id: number;
                readonly artifactType: ArtifactType;
                readonly title?: string | null;
                readonly updated?: string | null;
                readonly created: string;
                readonly rating?: {
                  readonly __typename: "RatingNode";
                  readonly id: number;
                  readonly title: string;
                } | null;
                readonly canUpdate: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                };
                readonly canDelete: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                };
                readonly creator?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
              }
            | {
                readonly __typename: "RecognitionArtifactNode";
                readonly id: number;
                readonly artifactType: ArtifactType;
                readonly title?: string | null;
                readonly updated?: string | null;
                readonly created: string;
                readonly canUpdate: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                };
                readonly canDelete: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                };
                readonly creator?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
                readonly recognitionCoreValue?: {
                  readonly __typename: "RecognitionCoreValueNode";
                  readonly id: number;
                  readonly title: string;
                  readonly emoji?: string | null;
                  readonly image?: string | null;
                } | null;
                readonly recipients?: {
                  readonly __typename: "UserNodeConnection";
                  readonly totalCount: number;
                  readonly edges: ReadonlyArray<{
                    readonly __typename: "UserNodeEdge";
                    readonly node?: {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    } | null;
                  } | null>;
                } | null;
              }
            | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type LinkArtifactToChatMessageMutationMutationVariables = Exact<{
  chatSessionId: Scalars["Int"]["input"];
  messageId: Scalars["String"]["input"];
  artifactId: Scalars["Int"]["input"];
}>;

export type LinkArtifactToChatMessageMutationMutation = {
  readonly __typename: "Mutation";
  readonly linkCreatedArtifactToChatMessage?: {
    readonly __typename: "LinkCreatedArtifactToChatMessageMutation";
    readonly chatSession?: {
      readonly __typename: "ChatSessionNode";
      readonly id: number;
      readonly messages?: {
        readonly __typename: "ChatMessageConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "ChatMessageEdge";
          readonly node?: {
            readonly __typename: "ChatMessageNode";
            readonly id: string;
            readonly content: string;
            readonly role: ChatbotRoleEnum;
            readonly autogenerated?: boolean | null;
            readonly actionItems?: {
              readonly __typename: "ActionItemArtifactConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "ActionItemArtifactEdge";
                readonly node?: {
                  readonly __typename: "ActionItemArtifactNode";
                  readonly id: number;
                  readonly isComplete?: boolean | null;
                  readonly artifactType: ArtifactType;
                  readonly title?: string | null;
                  readonly updated?: string | null;
                  readonly created: string;
                  readonly assignee?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                  readonly canUpdate: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly creator?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                } | null;
              } | null>;
            } | null;
            readonly goals?: {
              readonly __typename: "GoalArtifactConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "GoalArtifactEdge";
                readonly node?: {
                  readonly __typename: "GoalArtifactNode";
                  readonly id: number;
                  readonly artifactType: ArtifactType;
                  readonly title?: string | null;
                  readonly updated?: string | null;
                  readonly created: string;
                  readonly state: GoalState;
                  readonly scope: GoalScope;
                  readonly ownerCount?: number | null;
                  readonly dueDate?: string | null;
                  readonly goalVisibility: GoalVisibility;
                  readonly status: GoalStatus;
                  readonly progress: number;
                  readonly progressType: GoalProgressType;
                  readonly currentValue: number;
                  readonly startValue: number;
                  readonly targetValue: number;
                  readonly isStale: boolean;
                  readonly parentGoalId?: number | null;
                  readonly canUpdate: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly creator?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                  readonly firstOwner?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                  readonly owners?: {
                    readonly __typename: "UserNodeConnection";
                    readonly edges: ReadonlyArray<{
                      readonly __typename: "UserNodeEdge";
                      readonly node?: {
                        readonly __typename: "UserNode";
                        readonly id: number;
                        readonly name: string;
                        readonly avatar?: string | null;
                      } | null;
                    } | null>;
                  } | null;
                  readonly activities: {
                    readonly __typename: "ArtifactActivityNodeConnection";
                    readonly edges: ReadonlyArray<{
                      readonly __typename: "ArtifactActivityNodeEdge";
                      readonly node?: {
                        readonly __typename: "ArtifactActivityNode";
                        readonly created: string;
                      } | null;
                    } | null>;
                  };
                } | null;
              } | null>;
            } | null;
            readonly recognitions?: {
              readonly __typename: "RecognitionArtifactConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "RecognitionArtifactEdge";
                readonly node?: {
                  readonly __typename: "RecognitionArtifactNode";
                  readonly id: number;
                  readonly artifactType: ArtifactType;
                  readonly title?: string | null;
                  readonly updated?: string | null;
                  readonly created: string;
                  readonly canUpdate: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly creator?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                  readonly recognitionCoreValue?: {
                    readonly __typename: "RecognitionCoreValueNode";
                    readonly id: number;
                    readonly title: string;
                    readonly emoji?: string | null;
                    readonly image?: string | null;
                  } | null;
                  readonly recipients?: {
                    readonly __typename: "UserNodeConnection";
                    readonly totalCount: number;
                    readonly edges: ReadonlyArray<{
                      readonly __typename: "UserNodeEdge";
                      readonly node?: {
                        readonly __typename: "UserNode";
                        readonly id: number;
                        readonly name: string;
                        readonly avatar?: string | null;
                      } | null;
                    } | null>;
                  } | null;
                } | null;
              } | null>;
            } | null;
            readonly suggestedRecognition?: {
              readonly __typename: "SuggestedRecognitionNode";
              readonly title?: string | null;
              readonly coreValue?: {
                readonly __typename: "RecognitionCoreValueNode";
                readonly id: number;
                readonly title: string;
                readonly emoji?: string | null;
                readonly image?: string | null;
              } | null;
              readonly suggestedAssignee?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
            } | null;
            readonly createdArtifact?:
              | {
                  readonly __typename: "ActionItemArtifactNode";
                  readonly id: number;
                  readonly isComplete?: boolean | null;
                  readonly artifactType: ArtifactType;
                  readonly title?: string | null;
                  readonly updated?: string | null;
                  readonly created: string;
                  readonly assignee?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                  readonly canUpdate: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly creator?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                }
              | {
                  readonly __typename: "AsanaTaskArtifactNode";
                  readonly id: number;
                  readonly artifactType: ArtifactType;
                  readonly title?: string | null;
                  readonly updated?: string | null;
                  readonly created: string;
                  readonly canUpdate: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly creator?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                }
              | {
                  readonly __typename: "DecisionArtifactNode";
                  readonly id: number;
                  readonly decision: string;
                  readonly decisionState?: DecisionState | null;
                  readonly isDraft?: boolean | null;
                  readonly artifactType: ArtifactType;
                  readonly title?: string | null;
                  readonly updated?: string | null;
                  readonly created: string;
                  readonly canUpdate: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly creator?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                }
              | {
                  readonly __typename: "DocumentArtifactNode";
                  readonly id: number;
                  readonly artifactType: ArtifactType;
                  readonly title?: string | null;
                  readonly updated?: string | null;
                  readonly created: string;
                  readonly canUpdate: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly creator?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                }
              | {
                  readonly __typename: "FeedbackArtifactNode";
                  readonly id: number;
                  readonly artifactType: ArtifactType;
                  readonly title?: string | null;
                  readonly updated?: string | null;
                  readonly created: string;
                  readonly canUpdate: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly creator?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                }
              | {
                  readonly __typename: "GithubIssuesArtifactNode";
                  readonly id: number;
                  readonly artifactType: ArtifactType;
                  readonly title?: string | null;
                  readonly updated?: string | null;
                  readonly created: string;
                  readonly canUpdate: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly creator?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                }
              | {
                  readonly __typename: "GoalArtifactNode";
                  readonly id: number;
                  readonly artifactType: ArtifactType;
                  readonly title?: string | null;
                  readonly updated?: string | null;
                  readonly created: string;
                  readonly state: GoalState;
                  readonly scope: GoalScope;
                  readonly ownerCount?: number | null;
                  readonly dueDate?: string | null;
                  readonly goalVisibility: GoalVisibility;
                  readonly status: GoalStatus;
                  readonly progress: number;
                  readonly progressType: GoalProgressType;
                  readonly currentValue: number;
                  readonly startValue: number;
                  readonly targetValue: number;
                  readonly isStale: boolean;
                  readonly parentGoalId?: number | null;
                  readonly canUpdate: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly creator?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                  readonly firstOwner?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                  readonly owners?: {
                    readonly __typename: "UserNodeConnection";
                    readonly edges: ReadonlyArray<{
                      readonly __typename: "UserNodeEdge";
                      readonly node?: {
                        readonly __typename: "UserNode";
                        readonly id: number;
                        readonly name: string;
                        readonly avatar?: string | null;
                      } | null;
                    } | null>;
                  } | null;
                  readonly activities: {
                    readonly __typename: "ArtifactActivityNodeConnection";
                    readonly edges: ReadonlyArray<{
                      readonly __typename: "ArtifactActivityNodeEdge";
                      readonly node?: {
                        readonly __typename: "ArtifactActivityNode";
                        readonly created: string;
                      } | null;
                    } | null>;
                  };
                }
              | {
                  readonly __typename: "KPIArtifactNode";
                  readonly id: number;
                  readonly artifactType: ArtifactType;
                  readonly title?: string | null;
                  readonly updated?: string | null;
                  readonly created: string;
                  readonly canUpdate: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly creator?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                }
              | {
                  readonly __typename: "RatingArtifactNode";
                  readonly id: number;
                  readonly artifactType: ArtifactType;
                  readonly title?: string | null;
                  readonly updated?: string | null;
                  readonly created: string;
                  readonly rating?: {
                    readonly __typename: "RatingNode";
                    readonly id: number;
                    readonly title: string;
                  } | null;
                  readonly canUpdate: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly creator?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                }
              | {
                  readonly __typename: "RecognitionArtifactNode";
                  readonly id: number;
                  readonly artifactType: ArtifactType;
                  readonly title?: string | null;
                  readonly updated?: string | null;
                  readonly created: string;
                  readonly canUpdate: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly creator?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                  readonly recognitionCoreValue?: {
                    readonly __typename: "RecognitionCoreValueNode";
                    readonly id: number;
                    readonly title: string;
                    readonly emoji?: string | null;
                    readonly image?: string | null;
                  } | null;
                  readonly recipients?: {
                    readonly __typename: "UserNodeConnection";
                    readonly totalCount: number;
                    readonly edges: ReadonlyArray<{
                      readonly __typename: "UserNodeEdge";
                      readonly node?: {
                        readonly __typename: "UserNode";
                        readonly id: number;
                        readonly name: string;
                        readonly avatar?: string | null;
                      } | null;
                    } | null>;
                  } | null;
                }
              | null;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type SendMessageToAskAiMutationMutationVariables = Exact<{
  chatSessionId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  message: Scalars["String"]["input"];
}>;

export type SendMessageToAskAiMutationMutation = {
  readonly __typename: "Mutation";
  readonly sendMessageToChatbot?: {
    readonly __typename: "SendMessageToChatbotMutation";
    readonly chatSession?: {
      readonly __typename: "ChatSessionNode";
      readonly id: number;
    } | null;
  } | null;
};

export type SaveAssessmentGroupMutationVariables = Exact<{
  title?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  assessmentGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  sections?: InputMaybe<
    | ReadonlyArray<InputMaybe<AssessmentGroupSectionInput>>
    | InputMaybe<AssessmentGroupSectionInput>
  >;
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
  isQuestionWeightingEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type SaveAssessmentGroupMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateAssessmentGroup?: {
    readonly __typename: "CreateOrUpdateAssessmentGroupMutation";
    readonly assessmentGroup?: {
      readonly __typename: "AssessmentGroupNode";
      readonly id: number;
    } | null;
  } | null;
};

export type SaveAssessmentMutationVariables = Exact<{
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  targetId: Scalars["Int"]["input"];
  assessmentTemplateId: Scalars["Int"]["input"];
  assessmentId?: InputMaybe<Scalars["Int"]["input"]>;
  complianceProgramId?: InputMaybe<Scalars["Int"]["input"]>;
  state?: InputMaybe<AssessmentState>;
  answers?: InputMaybe<
    | ReadonlyArray<InputMaybe<AssessmentQuestionAnswerInput>>
    | InputMaybe<AssessmentQuestionAnswerInput>
  >;
}>;

export type SaveAssessmentMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateAssessment: {
    readonly __typename: "CreateOrUpdateAssessmentMutation";
    readonly assessment: {
      readonly __typename: "AssessmentNode";
      readonly id: number;
    };
  };
};

export type SaveAssessmentQuestionMutationVariables = Exact<{
  title?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  questionId?: InputMaybe<Scalars["Int"]["input"]>;
  startValue?: InputMaybe<Scalars["Int"]["input"]>;
  endValue?: InputMaybe<Scalars["Int"]["input"]>;
  isCommentMandatory?: InputMaybe<Scalars["Boolean"]["input"]>;
  labels?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  labelDescriptions?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  options?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  optionDescriptions?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  responses?: InputMaybe<AssessmentQuestionResponses>;
  responseVisibility?: InputMaybe<AssessmentQuestionResponseVisibility>;
  questionType?: InputMaybe<AssessmentQuestionType>;
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
  categories?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
}>;

export type SaveAssessmentQuestionMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateAssessmentQuestion?: {
    readonly __typename: "CreateOrUpdateAssessmentQuestionMutation";
    readonly question?:
      | {
          readonly __typename: "MultiChoiceAssessmentQuestionNode";
          readonly id: number;
          readonly title: string;
          readonly description?: any | null;
          readonly archived: boolean;
          readonly answersExist: boolean;
          readonly isCommentMandatory: boolean;
          readonly responses: AssessmentQuestionResponses;
          readonly responseVisibility: AssessmentQuestionResponseVisibility;
          readonly questionType: AssessmentQuestionType;
          readonly created: string;
          readonly assessmentType: AssessmentType;
          readonly options: ReadonlyArray<string | null>;
          readonly optionDescriptions: ReadonlyArray<string | null>;
          readonly canUpdate: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
            readonly reason: string;
          };
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
            readonly reason: string;
          };
          readonly creator?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
          } | null;
        }
      | {
          readonly __typename: "RangeAssessmentQuestionNode";
          readonly id: number;
          readonly title: string;
          readonly description?: any | null;
          readonly archived: boolean;
          readonly answersExist: boolean;
          readonly isCommentMandatory: boolean;
          readonly responses: AssessmentQuestionResponses;
          readonly responseVisibility: AssessmentQuestionResponseVisibility;
          readonly questionType: AssessmentQuestionType;
          readonly created: string;
          readonly assessmentType: AssessmentType;
          readonly startValue: number;
          readonly endValue: number;
          readonly labels: ReadonlyArray<string | null>;
          readonly labelDescriptions: ReadonlyArray<string | null>;
          readonly canUpdate: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
            readonly reason: string;
          };
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
            readonly reason: string;
          };
          readonly creator?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
          } | null;
        }
      | {
          readonly __typename: "TextAssessmentQuestionNode";
          readonly id: number;
          readonly title: string;
          readonly description?: any | null;
          readonly archived: boolean;
          readonly answersExist: boolean;
          readonly isCommentMandatory: boolean;
          readonly responses: AssessmentQuestionResponses;
          readonly responseVisibility: AssessmentQuestionResponseVisibility;
          readonly questionType: AssessmentQuestionType;
          readonly created: string;
          readonly assessmentType: AssessmentType;
          readonly canUpdate: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
            readonly reason: string;
          };
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
            readonly reason: string;
          };
          readonly creator?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
          } | null;
        }
      | null;
  } | null;
};

export type DeleteAssessmentAnswerMutationMutationVariables = Exact<{
  assessmentId: Scalars["Int"]["input"];
  questionId: Scalars["Int"]["input"];
}>;

export type DeleteAssessmentAnswerMutationMutation = {
  readonly __typename: "Mutation";
  readonly deleteAssessmentAnswer: {
    readonly __typename: "DeleteAssessmentAnswerMutation";
    readonly ok?: boolean | null;
  };
};

export type RemoveAssessmentGroupMutationVariables = Exact<{
  assessmentGroupId: Scalars["Int"]["input"];
}>;

export type RemoveAssessmentGroupMutation = {
  readonly __typename: "Mutation";
  readonly deleteAssessmentGroup?: {
    readonly __typename: "DeleteAssessmentGroupMutation";
    readonly ok?: boolean | null;
  } | null;
};

export type RemoveAssessmentQuestionMutationVariables = Exact<{
  assessmentQuestionId: Scalars["Int"]["input"];
}>;

export type RemoveAssessmentQuestionMutation = {
  readonly __typename: "Mutation";
  readonly deleteAssessmentQuestion?: {
    readonly __typename: "DeleteAssessmentQuestionMutation";
    readonly ok?: boolean | null;
  } | null;
};

export type GetAssessmentDeliveriesReceivedQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
  targetId?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  orderBy: Scalars["String"]["input"];
}>;

export type GetAssessmentDeliveriesReceivedQuery = {
  readonly __typename: "Query";
  readonly assessmentDeliveries: {
    readonly __typename: "AssessmentDeliveryNodeConnection";
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor?: string | null;
      readonly hasNextPage: boolean;
    };
    readonly edges: ReadonlyArray<{
      readonly __typename: "AssessmentDeliveryNodeEdge";
      readonly node?: {
        readonly __typename: "AssessmentDeliveryNode";
        readonly id: number;
        readonly deliveryDatetime?: string | null;
        readonly lastSubmissionDatetime?: string | null;
        readonly target?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly email: string;
          readonly firstName: string;
          readonly avatar?: string | null;
        } | null;
        readonly complianceProgram?: {
          readonly __typename: "ComplianceProgramNode";
          readonly id: number;
          readonly title: string;
        } | null;
        readonly template: {
          readonly __typename: "AssessmentTemplateNode";
          readonly id: number;
          readonly assessmentType: AssessmentType;
        };
      } | null;
    } | null>;
  };
};

export type AssessmentDeliveryAssessmentGroupSectionFragment = {
  readonly __typename: "AssessmentGroupSectionNode";
  readonly id: number;
  readonly title?: string | null;
  readonly description?: any | null;
  readonly questions: {
    readonly __typename: "AssessmentGroupQuestionM2MNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "AssessmentGroupQuestionM2MNodeEdge";
      readonly node?: {
        readonly __typename: "AssessmentGroupQuestionM2MNode";
        readonly id: number;
        readonly weight: number;
        readonly question:
          | {
              readonly __typename: "MultiChoiceAssessmentQuestionNode";
              readonly id: number;
              readonly title: string;
              readonly description?: any | null;
              readonly options: ReadonlyArray<string | null>;
              readonly optionDescriptions: ReadonlyArray<string | null>;
              readonly isCommentMandatory: boolean;
              readonly responses: AssessmentQuestionResponses;
              readonly questionType: AssessmentQuestionType;
              readonly responseVisibility: AssessmentQuestionResponseVisibility;
            }
          | {
              readonly __typename: "RangeAssessmentQuestionNode";
              readonly id: number;
              readonly title: string;
              readonly description?: any | null;
              readonly startValue: number;
              readonly endValue: number;
              readonly labels: ReadonlyArray<string | null>;
              readonly labelDescriptions: ReadonlyArray<string | null>;
              readonly isCommentMandatory: boolean;
              readonly responses: AssessmentQuestionResponses;
              readonly questionType: AssessmentQuestionType;
              readonly responseVisibility: AssessmentQuestionResponseVisibility;
            }
          | {
              readonly __typename: "TextAssessmentQuestionNode";
              readonly id: number;
              readonly title: string;
              readonly description?: any | null;
              readonly isCommentMandatory: boolean;
              readonly responses: AssessmentQuestionResponses;
              readonly questionType: AssessmentQuestionType;
              readonly responseVisibility: AssessmentQuestionResponseVisibility;
            };
      } | null;
    } | null>;
  };
};

export type GetAssessmentDeliveryQueryVariables = Exact<{
  assessmentDeliveryId: Scalars["Int"]["input"];
}>;

export type GetAssessmentDeliveryQuery = {
  readonly __typename: "Query";
  readonly assessmentDelivery?: {
    readonly __typename: "AssessmentDeliveryNode";
    readonly id: number;
    readonly state: AssessmentDeliveryState;
    readonly summary?: any | null;
    readonly excludedAnswerIds: ReadonlyArray<number | null>;
    readonly deliveryDatetime?: string | null;
    readonly dueDate?: string | null;
    readonly periodStartDate?: string | null;
    readonly periodEndDate?: string | null;
    readonly canUpdate?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
      readonly reason: string;
    } | null;
    readonly creator?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
    } | null;
    readonly target?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
      readonly email: string;
      readonly firstName: string;
      readonly avatar?: string | null;
    } | null;
    readonly complianceProgram?: {
      readonly __typename: "ComplianceProgramNode";
      readonly id: number;
      readonly title: string;
    } | null;
    readonly template: {
      readonly __typename: "AssessmentTemplateNode";
      readonly id: number;
      readonly assessmentType: AssessmentType;
      readonly anonymity: AssessmentGroupAnonymity;
      readonly isQuestionWeightingEnabled: boolean;
      readonly hasSelfAssessment: boolean;
      readonly questionSets: {
        readonly __typename: "AssessmentGroupNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "AssessmentGroupNodeEdge";
          readonly node?: {
            readonly __typename: "AssessmentGroupNode";
            readonly id: number;
            readonly title: string;
            readonly sections: {
              readonly __typename: "AssessmentGroupSectionNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "AssessmentGroupSectionNodeEdge";
                readonly node?: {
                  readonly __typename: "AssessmentGroupSectionNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly description?: any | null;
                  readonly questions: {
                    readonly __typename: "AssessmentGroupQuestionM2MNodeConnection";
                    readonly edges: ReadonlyArray<{
                      readonly __typename: "AssessmentGroupQuestionM2MNodeEdge";
                      readonly node?: {
                        readonly __typename: "AssessmentGroupQuestionM2MNode";
                        readonly id: number;
                        readonly weight: number;
                        readonly question:
                          | {
                              readonly __typename: "MultiChoiceAssessmentQuestionNode";
                              readonly id: number;
                              readonly title: string;
                              readonly description?: any | null;
                              readonly options: ReadonlyArray<string | null>;
                              readonly optionDescriptions: ReadonlyArray<
                                string | null
                              >;
                              readonly isCommentMandatory: boolean;
                              readonly responses: AssessmentQuestionResponses;
                              readonly questionType: AssessmentQuestionType;
                              readonly responseVisibility: AssessmentQuestionResponseVisibility;
                            }
                          | {
                              readonly __typename: "RangeAssessmentQuestionNode";
                              readonly id: number;
                              readonly title: string;
                              readonly description?: any | null;
                              readonly startValue: number;
                              readonly endValue: number;
                              readonly labels: ReadonlyArray<string | null>;
                              readonly labelDescriptions: ReadonlyArray<
                                string | null
                              >;
                              readonly isCommentMandatory: boolean;
                              readonly responses: AssessmentQuestionResponses;
                              readonly questionType: AssessmentQuestionType;
                              readonly responseVisibility: AssessmentQuestionResponseVisibility;
                            }
                          | {
                              readonly __typename: "TextAssessmentQuestionNode";
                              readonly id: number;
                              readonly title: string;
                              readonly description?: any | null;
                              readonly isCommentMandatory: boolean;
                              readonly responses: AssessmentQuestionResponses;
                              readonly questionType: AssessmentQuestionType;
                              readonly responseVisibility: AssessmentQuestionResponseVisibility;
                            };
                      } | null;
                    } | null>;
                  };
                } | null;
              } | null>;
            };
          } | null;
        } | null>;
      };
    };
    readonly answers: {
      readonly __typename: "AssessmentAnswerConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "AssessmentAnswerEdge";
        readonly node?:
          | {
              readonly __typename: "MultiChoiceAssessmentAnswerNode";
              readonly id: number;
              readonly choices: ReadonlyArray<number | null>;
              readonly comment?: any | null;
              readonly responder?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly email: string;
                readonly firstName: string;
                readonly avatar?: string | null;
              } | null;
              readonly question:
                | {
                    readonly __typename: "MultiChoiceAssessmentQuestionNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "RangeAssessmentQuestionNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "TextAssessmentQuestionNode";
                    readonly id: number;
                  };
            }
          | {
              readonly __typename: "RangeAssessmentAnswerNode";
              readonly id: number;
              readonly integerAnswer?: number | null;
              readonly comment?: any | null;
              readonly responder?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly email: string;
                readonly firstName: string;
                readonly avatar?: string | null;
              } | null;
              readonly question:
                | {
                    readonly __typename: "MultiChoiceAssessmentQuestionNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "RangeAssessmentQuestionNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "TextAssessmentQuestionNode";
                    readonly id: number;
                  };
            }
          | {
              readonly __typename: "TextAssessmentAnswerNode";
              readonly id: number;
              readonly textAnswer?: any | null;
              readonly comment?: any | null;
              readonly responder?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly email: string;
                readonly firstName: string;
                readonly avatar?: string | null;
              } | null;
              readonly question:
                | {
                    readonly __typename: "MultiChoiceAssessmentQuestionNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "RangeAssessmentQuestionNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "TextAssessmentQuestionNode";
                    readonly id: number;
                  };
            }
          | null;
      } | null>;
    };
  } | null;
};

export type GetAssessmentGroupQueryVariables = Exact<{
  assessmentGroupId: Scalars["Int"]["input"];
}>;

export type GetAssessmentGroupQuery = {
  readonly __typename: "Query";
  readonly assessmentGroup?: {
    readonly __typename: "AssessmentGroupNode";
    readonly id: number;
    readonly title: string;
    readonly isQuestionWeightingEnabled: boolean;
    readonly created: string;
    readonly creator?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
    } | null;
    readonly canUpdate?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
      readonly reason: string;
    } | null;
    readonly sections: {
      readonly __typename: "AssessmentGroupSectionNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "AssessmentGroupSectionNodeEdge";
        readonly node?: {
          readonly __typename: "AssessmentGroupSectionNode";
          readonly id: number;
          readonly title?: string | null;
          readonly description?: any | null;
          readonly questions: {
            readonly __typename: "AssessmentGroupQuestionM2MNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "AssessmentGroupQuestionM2MNodeEdge";
              readonly node?: {
                readonly __typename: "AssessmentGroupQuestionM2MNode";
                readonly id: number;
                readonly weight: number;
                readonly question:
                  | {
                      readonly __typename: "MultiChoiceAssessmentQuestionNode";
                      readonly id: number;
                    }
                  | {
                      readonly __typename: "RangeAssessmentQuestionNode";
                      readonly id: number;
                    }
                  | {
                      readonly __typename: "TextAssessmentQuestionNode";
                      readonly id: number;
                    };
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    };
  } | null;
};

export type GetAssessmentGroupsQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GetAssessmentGroupsQuery = {
  readonly __typename: "Query";
  readonly assessmentGroups: {
    readonly __typename: "AssessmentGroupNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "AssessmentGroupNodeEdge";
      readonly node?: {
        readonly __typename: "AssessmentGroupNode";
        readonly id: number;
        readonly title: string;
        readonly created: string;
        readonly delivery: AssessmentGroupDelivery;
        readonly providers: AssessmentGroupProviders;
        readonly anonymity: AssessmentGroupAnonymity;
        readonly archived: boolean;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
        } | null;
        readonly canUpdate?: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
          readonly reason: string;
        } | null;
        readonly canDelete?: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
          readonly reason: string;
        } | null;
      } | null;
    } | null>;
  };
};

export type AssessmentNominationFragment = {
  readonly __typename: "AssessmentOpenForNominationNode";
  readonly id?: string | null;
  readonly complianceProgram: {
    readonly __typename: "ComplianceProgramNode";
    readonly id: number;
    readonly title: string;
  };
  readonly assessmentTemplate: {
    readonly __typename: "AssessmentTemplateNode";
    readonly id: number;
    readonly assessmentType: AssessmentType;
  };
  readonly targetUser: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  };
  readonly nominations: {
    readonly __typename: "AssessmentNominationNodeConnection";
    readonly totalCount: number;
  };
};

export type GetAssessmentNominationsQueryVariables = Exact<{
  targetId?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetAssessmentNominationsQuery = {
  readonly __typename: "Query";
  readonly assessmentsOpenForNominations: {
    readonly __typename: "AssessmentOpenForNominationConnection";
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor?: string | null;
      readonly hasNextPage: boolean;
    };
    readonly edges: ReadonlyArray<{
      readonly __typename: "AssessmentOpenForNominationEdge";
      readonly node?: {
        readonly __typename: "AssessmentOpenForNominationNode";
        readonly id?: string | null;
        readonly complianceProgram: {
          readonly __typename: "ComplianceProgramNode";
          readonly id: number;
          readonly title: string;
        };
        readonly assessmentTemplate: {
          readonly __typename: "AssessmentTemplateNode";
          readonly id: number;
          readonly assessmentType: AssessmentType;
        };
        readonly targetUser: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        };
        readonly nominations: {
          readonly __typename: "AssessmentNominationNodeConnection";
          readonly totalCount: number;
        };
      } | null;
    } | null>;
  };
};

export type AssessmentNomineeResponderFragment = {
  readonly __typename: "UserNode";
  readonly id: number;
  readonly name: string;
  readonly avatar?: string | null;
  readonly activeAssessmentNominationCount: number;
};

export type AssessmentNomineeFragment = {
  readonly __typename: "AssessmentNominationNode";
  readonly id: number;
  readonly responder: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
    readonly activeAssessmentNominationCount: number;
  };
};

export type GetAssessmentProgramNomineesQueryVariables = Exact<{
  programId: Scalars["Int"]["input"];
  targetId: Scalars["Int"]["input"];
}>;

export type GetAssessmentProgramNomineesQuery = {
  readonly __typename: "Query";
  readonly complianceProgram?: {
    readonly __typename: "ComplianceProgramNode";
    readonly id: number;
    readonly nominationsForUser: {
      readonly __typename: "AssessmentNominationNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "AssessmentNominationNodeEdge";
        readonly node?: {
          readonly __typename: "AssessmentNominationNode";
          readonly id: number;
          readonly responder: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
            readonly activeAssessmentNominationCount: number;
          };
        } | null;
      } | null>;
    };
  } | null;
};

export type GetAssessmentAsessmentGroupSectionFragment = {
  readonly __typename: "AssessmentGroupSectionNode";
  readonly id: number;
  readonly title?: string | null;
  readonly description?: any | null;
  readonly questions: {
    readonly __typename: "AssessmentGroupQuestionM2MNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "AssessmentGroupQuestionM2MNodeEdge";
      readonly node?: {
        readonly __typename: "AssessmentGroupQuestionM2MNode";
        readonly id: number;
        readonly weight: number;
        readonly question:
          | {
              readonly __typename: "MultiChoiceAssessmentQuestionNode";
              readonly id: number;
              readonly title: string;
              readonly description?: any | null;
              readonly options: ReadonlyArray<string | null>;
              readonly optionDescriptions: ReadonlyArray<string | null>;
              readonly isCommentMandatory: boolean;
              readonly responses: AssessmentQuestionResponses;
              readonly questionType: AssessmentQuestionType;
              readonly responseVisibility: AssessmentQuestionResponseVisibility;
            }
          | {
              readonly __typename: "RangeAssessmentQuestionNode";
              readonly id: number;
              readonly title: string;
              readonly description?: any | null;
              readonly startValue: number;
              readonly endValue: number;
              readonly labels: ReadonlyArray<string | null>;
              readonly labelDescriptions: ReadonlyArray<string | null>;
              readonly isCommentMandatory: boolean;
              readonly responses: AssessmentQuestionResponses;
              readonly questionType: AssessmentQuestionType;
              readonly responseVisibility: AssessmentQuestionResponseVisibility;
            }
          | {
              readonly __typename: "TextAssessmentQuestionNode";
              readonly id: number;
              readonly title: string;
              readonly description?: any | null;
              readonly isCommentMandatory: boolean;
              readonly responses: AssessmentQuestionResponses;
              readonly questionType: AssessmentQuestionType;
              readonly responseVisibility: AssessmentQuestionResponseVisibility;
            };
      } | null;
    } | null>;
  };
};

export type GetAssessmentQueryVariables = Exact<{
  assessmentId: Scalars["Int"]["input"];
  organizationId: Scalars["Int"]["input"];
}>;

export type GetAssessmentQuery = {
  readonly __typename: "Query";
  readonly assessment?: {
    readonly __typename: "AssessmentNode";
    readonly id: number;
    readonly state: AssessmentState;
    readonly submittedDatetime?: string | null;
    readonly periodStartDate?: string | null;
    readonly periodEndDate?: string | null;
    readonly dueDate?: string | null;
    readonly canUpdate?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
      readonly reason: string;
    } | null;
    readonly complianceProgram?: {
      readonly __typename: "ComplianceProgramNode";
      readonly id: number;
      readonly title: string;
    } | null;
    readonly template: {
      readonly __typename: "AssessmentTemplateNode";
      readonly id: number;
      readonly assessmentType: AssessmentType;
      readonly delivery: AssessmentGroupDelivery;
      readonly anonymity: AssessmentGroupAnonymity;
      readonly isQuestionWeightingEnabled: boolean;
      readonly questionSets: {
        readonly __typename: "AssessmentGroupNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "AssessmentGroupNodeEdge";
          readonly node?: {
            readonly __typename: "AssessmentGroupNode";
            readonly id: number;
            readonly title: string;
            readonly sections: {
              readonly __typename: "AssessmentGroupSectionNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "AssessmentGroupSectionNodeEdge";
                readonly node?: {
                  readonly __typename: "AssessmentGroupSectionNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly description?: any | null;
                  readonly questions: {
                    readonly __typename: "AssessmentGroupQuestionM2MNodeConnection";
                    readonly edges: ReadonlyArray<{
                      readonly __typename: "AssessmentGroupQuestionM2MNodeEdge";
                      readonly node?: {
                        readonly __typename: "AssessmentGroupQuestionM2MNode";
                        readonly id: number;
                        readonly weight: number;
                        readonly question:
                          | {
                              readonly __typename: "MultiChoiceAssessmentQuestionNode";
                              readonly id: number;
                              readonly title: string;
                              readonly description?: any | null;
                              readonly options: ReadonlyArray<string | null>;
                              readonly optionDescriptions: ReadonlyArray<
                                string | null
                              >;
                              readonly isCommentMandatory: boolean;
                              readonly responses: AssessmentQuestionResponses;
                              readonly questionType: AssessmentQuestionType;
                              readonly responseVisibility: AssessmentQuestionResponseVisibility;
                            }
                          | {
                              readonly __typename: "RangeAssessmentQuestionNode";
                              readonly id: number;
                              readonly title: string;
                              readonly description?: any | null;
                              readonly startValue: number;
                              readonly endValue: number;
                              readonly labels: ReadonlyArray<string | null>;
                              readonly labelDescriptions: ReadonlyArray<
                                string | null
                              >;
                              readonly isCommentMandatory: boolean;
                              readonly responses: AssessmentQuestionResponses;
                              readonly questionType: AssessmentQuestionType;
                              readonly responseVisibility: AssessmentQuestionResponseVisibility;
                            }
                          | {
                              readonly __typename: "TextAssessmentQuestionNode";
                              readonly id: number;
                              readonly title: string;
                              readonly description?: any | null;
                              readonly isCommentMandatory: boolean;
                              readonly responses: AssessmentQuestionResponses;
                              readonly questionType: AssessmentQuestionType;
                              readonly responseVisibility: AssessmentQuestionResponseVisibility;
                            };
                      } | null;
                    } | null>;
                  };
                } | null;
              } | null>;
            };
          } | null;
        } | null>;
      };
    };
    readonly responder?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
      readonly avatar?: string | null;
    } | null;
    readonly target?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
      readonly email: string;
      readonly firstName: string;
      readonly avatar?: string | null;
      readonly organizationSpecificInfo?: {
        readonly __typename: "UserMemberNode";
        readonly position?: string | null;
        readonly jobLevel?: string | null;
      } | null;
    } | null;
    readonly answers: {
      readonly __typename: "AssessmentAnswerConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "AssessmentAnswerEdge";
        readonly node?:
          | {
              readonly __typename: "MultiChoiceAssessmentAnswerNode";
              readonly id: number;
              readonly choices: ReadonlyArray<number | null>;
              readonly comment?: any | null;
              readonly question:
                | {
                    readonly __typename: "MultiChoiceAssessmentQuestionNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "RangeAssessmentQuestionNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "TextAssessmentQuestionNode";
                    readonly id: number;
                  };
            }
          | {
              readonly __typename: "RangeAssessmentAnswerNode";
              readonly id: number;
              readonly integerAnswer?: number | null;
              readonly comment?: any | null;
              readonly question:
                | {
                    readonly __typename: "MultiChoiceAssessmentQuestionNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "RangeAssessmentQuestionNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "TextAssessmentQuestionNode";
                    readonly id: number;
                  };
            }
          | {
              readonly __typename: "TextAssessmentAnswerNode";
              readonly id: number;
              readonly textAnswer?: any | null;
              readonly comment?: any | null;
              readonly question:
                | {
                    readonly __typename: "MultiChoiceAssessmentQuestionNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "RangeAssessmentQuestionNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "TextAssessmentQuestionNode";
                    readonly id: number;
                  };
            }
          | null;
      } | null>;
    };
    readonly relatedMeetings: {
      readonly __typename: "MeetingNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "MeetingNodeEdge";
        readonly node?: {
          readonly __typename: "MeetingNode";
          readonly id: number;
          readonly title?: string | null;
          readonly meetingGroupId: number;
          readonly startDatetime?: string | null;
          readonly endDatetime?: string | null;
          readonly draft: boolean;
        } | null;
      } | null>;
    };
  } | null;
};

export type GetAssessmentsToDeliverQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
  currentUserId: Scalars["Int"]["input"];
  after?: InputMaybe<Scalars["String"]["input"]>;
  orderBy: Scalars["String"]["input"];
}>;

export type GetAssessmentsToDeliverQuery = {
  readonly __typename: "Query";
  readonly assessmentDeliveries: {
    readonly __typename: "AssessmentDeliveryNodeConnection";
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor?: string | null;
      readonly hasNextPage: boolean;
    };
    readonly edges: ReadonlyArray<{
      readonly __typename: "AssessmentDeliveryNodeEdge";
      readonly node?: {
        readonly __typename: "AssessmentDeliveryNode";
        readonly id: number;
        readonly state: AssessmentDeliveryState;
        readonly target?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly email: string;
          readonly firstName: string;
          readonly avatar?: string | null;
        } | null;
        readonly complianceProgram?: {
          readonly __typename: "ComplianceProgramNode";
          readonly id: number;
          readonly title: string;
          readonly dueDate?: string | null;
        } | null;
        readonly template: {
          readonly __typename: "AssessmentTemplateNode";
          readonly id: number;
          readonly assessmentType: AssessmentType;
        };
      } | null;
    } | null>;
  };
};

export type UnmetComplainceProgramAssessmentTemplateFragment = {
  readonly __typename: "AssessmentTemplateNode";
  readonly id: number;
  readonly assessmentType: AssessmentType;
};

export type UnmetComplianceProgramFragment = {
  readonly __typename: "ComplianceProgramNode";
  readonly id: number;
  readonly title: string;
  readonly usersMissingAssessment: {
    readonly __typename: "MissingAssessmentConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "MissingAssessmentEdge";
      readonly node?: {
        readonly __typename: "MissingAssessmentNode";
        readonly assessmentTemplateId?: number | null;
        readonly dueDate: string;
        readonly target: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        };
        readonly responder: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        };
      } | null;
    } | null>;
  };
  readonly performanceAssessmentTemplate?: {
    readonly __typename: "AssessmentTemplateNode";
    readonly id: number;
    readonly assessmentType: AssessmentType;
  } | null;
  readonly managerAssessmentTemplate?: {
    readonly __typename: "AssessmentTemplateNode";
    readonly id: number;
    readonly assessmentType: AssessmentType;
  } | null;
  readonly peerAssessmentTemplate?: {
    readonly __typename: "AssessmentTemplateNode";
    readonly id: number;
    readonly assessmentType: AssessmentType;
  } | null;
  readonly assessments: {
    readonly __typename: "AssessmentNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "AssessmentNodeEdge";
      readonly node?: {
        readonly __typename: "AssessmentNode";
        readonly id: number;
        readonly state: AssessmentState;
        readonly target?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly template: {
          readonly __typename: "AssessmentTemplateNode";
          readonly id: number;
        };
      } | null;
    } | null>;
  };
};

export type GetMyAssessmentsQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
  currentUserId: Scalars["Int"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  afterPerformance?: InputMaybe<Scalars["String"]["input"]>;
  afterPeer?: InputMaybe<Scalars["String"]["input"]>;
  afterManager?: InputMaybe<Scalars["String"]["input"]>;
  orderBy: Scalars["String"]["input"];
}>;

export type GetMyAssessmentsQuery = {
  readonly __typename: "Query";
  readonly unmetPerformanceAssessmentCompliancePrograms: {
    readonly __typename: "ComplianceProgramNodeConnection";
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor?: string | null;
      readonly hasNextPage: boolean;
    };
    readonly edges: ReadonlyArray<{
      readonly __typename: "ComplianceProgramNodeEdge";
      readonly node?: {
        readonly __typename: "ComplianceProgramNode";
        readonly id: number;
        readonly title: string;
        readonly usersMissingAssessment: {
          readonly __typename: "MissingAssessmentConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "MissingAssessmentEdge";
            readonly node?: {
              readonly __typename: "MissingAssessmentNode";
              readonly assessmentTemplateId?: number | null;
              readonly dueDate: string;
              readonly target: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              };
              readonly responder: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              };
            } | null;
          } | null>;
        };
        readonly performanceAssessmentTemplate?: {
          readonly __typename: "AssessmentTemplateNode";
          readonly id: number;
          readonly assessmentType: AssessmentType;
        } | null;
        readonly managerAssessmentTemplate?: {
          readonly __typename: "AssessmentTemplateNode";
          readonly id: number;
          readonly assessmentType: AssessmentType;
        } | null;
        readonly peerAssessmentTemplate?: {
          readonly __typename: "AssessmentTemplateNode";
          readonly id: number;
          readonly assessmentType: AssessmentType;
        } | null;
        readonly assessments: {
          readonly __typename: "AssessmentNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "AssessmentNodeEdge";
            readonly node?: {
              readonly __typename: "AssessmentNode";
              readonly id: number;
              readonly state: AssessmentState;
              readonly target?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
              readonly template: {
                readonly __typename: "AssessmentTemplateNode";
                readonly id: number;
              };
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  };
  readonly unmetPeerAssessmentCompliancePrograms: {
    readonly __typename: "ComplianceProgramNodeConnection";
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor?: string | null;
      readonly hasNextPage: boolean;
    };
    readonly edges: ReadonlyArray<{
      readonly __typename: "ComplianceProgramNodeEdge";
      readonly node?: {
        readonly __typename: "ComplianceProgramNode";
        readonly id: number;
        readonly title: string;
        readonly usersMissingAssessment: {
          readonly __typename: "MissingAssessmentConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "MissingAssessmentEdge";
            readonly node?: {
              readonly __typename: "MissingAssessmentNode";
              readonly assessmentTemplateId?: number | null;
              readonly dueDate: string;
              readonly target: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              };
              readonly responder: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              };
            } | null;
          } | null>;
        };
        readonly performanceAssessmentTemplate?: {
          readonly __typename: "AssessmentTemplateNode";
          readonly id: number;
          readonly assessmentType: AssessmentType;
        } | null;
        readonly managerAssessmentTemplate?: {
          readonly __typename: "AssessmentTemplateNode";
          readonly id: number;
          readonly assessmentType: AssessmentType;
        } | null;
        readonly peerAssessmentTemplate?: {
          readonly __typename: "AssessmentTemplateNode";
          readonly id: number;
          readonly assessmentType: AssessmentType;
        } | null;
        readonly assessments: {
          readonly __typename: "AssessmentNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "AssessmentNodeEdge";
            readonly node?: {
              readonly __typename: "AssessmentNode";
              readonly id: number;
              readonly state: AssessmentState;
              readonly target?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
              readonly template: {
                readonly __typename: "AssessmentTemplateNode";
                readonly id: number;
              };
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  };
  readonly unmetManagerEffectivenessCompliancePrograms: {
    readonly __typename: "ComplianceProgramNodeConnection";
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor?: string | null;
      readonly hasNextPage: boolean;
    };
    readonly edges: ReadonlyArray<{
      readonly __typename: "ComplianceProgramNodeEdge";
      readonly node?: {
        readonly __typename: "ComplianceProgramNode";
        readonly id: number;
        readonly title: string;
        readonly usersMissingAssessment: {
          readonly __typename: "MissingAssessmentConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "MissingAssessmentEdge";
            readonly node?: {
              readonly __typename: "MissingAssessmentNode";
              readonly assessmentTemplateId?: number | null;
              readonly dueDate: string;
              readonly target: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              };
              readonly responder: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              };
            } | null;
          } | null>;
        };
        readonly performanceAssessmentTemplate?: {
          readonly __typename: "AssessmentTemplateNode";
          readonly id: number;
          readonly assessmentType: AssessmentType;
        } | null;
        readonly managerAssessmentTemplate?: {
          readonly __typename: "AssessmentTemplateNode";
          readonly id: number;
          readonly assessmentType: AssessmentType;
        } | null;
        readonly peerAssessmentTemplate?: {
          readonly __typename: "AssessmentTemplateNode";
          readonly id: number;
          readonly assessmentType: AssessmentType;
        } | null;
        readonly assessments: {
          readonly __typename: "AssessmentNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "AssessmentNodeEdge";
            readonly node?: {
              readonly __typename: "AssessmentNode";
              readonly id: number;
              readonly state: AssessmentState;
              readonly target?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
              readonly template: {
                readonly __typename: "AssessmentTemplateNode";
                readonly id: number;
              };
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  };
};

export type PreviousAssessmentFragment = {
  readonly __typename: "AssessmentNode";
  readonly id: number;
  readonly submittedDatetime?: string | null;
  readonly complianceProgram?: {
    readonly __typename: "ComplianceProgramNode";
    readonly id: number;
    readonly title: string;
  } | null;
  readonly target?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
  readonly responder?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
  readonly template: {
    readonly __typename: "AssessmentTemplateNode";
    readonly id: number;
    readonly assessmentType: AssessmentType;
  };
};

export type GetPreviousAssessmentsQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
  responderId?: InputMaybe<Scalars["Int"]["input"]>;
  targetId?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetPreviousAssessmentsQuery = {
  readonly __typename: "Query";
  readonly assessments: {
    readonly __typename: "AssessmentNodeConnection";
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor?: string | null;
      readonly hasNextPage: boolean;
    };
    readonly edges: ReadonlyArray<{
      readonly __typename: "AssessmentNodeEdge";
      readonly node?: {
        readonly __typename: "AssessmentNode";
        readonly id: number;
        readonly submittedDatetime?: string | null;
        readonly complianceProgram?: {
          readonly __typename: "ComplianceProgramNode";
          readonly id: number;
          readonly title: string;
        } | null;
        readonly target?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly responder?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly template: {
          readonly __typename: "AssessmentTemplateNode";
          readonly id: number;
          readonly assessmentType: AssessmentType;
        };
      } | null;
    } | null>;
  };
};

export type GetAssessmentQuestionCategoriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAssessmentQuestionCategoriesQuery = {
  readonly __typename: "Query";
  readonly assessmentQuestionCategories: {
    readonly __typename: "AssessmentQuestionCategoryNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "AssessmentQuestionCategoryNodeEdge";
      readonly node?: {
        readonly __typename: "AssessmentQuestionCategoryNode";
        readonly id: number;
        readonly title: string;
      } | null;
    } | null>;
  };
};

export type GetAssessmentQuestionQueryVariables = Exact<{
  questionId: Scalars["Int"]["input"];
}>;

export type GetAssessmentQuestionQuery = {
  readonly __typename: "Query";
  readonly assessmentQuestion?:
    | {
        readonly __typename: "MultiChoiceAssessmentQuestionNode";
        readonly id: number;
        readonly title: string;
        readonly description?: any | null;
        readonly created: string;
        readonly answersExist: boolean;
        readonly assessmentType: AssessmentType;
        readonly isCommentMandatory: boolean;
        readonly responses: AssessmentQuestionResponses;
        readonly responseVisibility: AssessmentQuestionResponseVisibility;
        readonly questionType: AssessmentQuestionType;
        readonly options: ReadonlyArray<string | null>;
        readonly optionDescriptions: ReadonlyArray<string | null>;
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
          readonly reason: string;
        };
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
        } | null;
        readonly categories: {
          readonly __typename: "AssessmentQuestionCategoryNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "AssessmentQuestionCategoryNodeEdge";
            readonly node?: {
              readonly __typename: "AssessmentQuestionCategoryNode";
              readonly id: number;
              readonly title: string;
            } | null;
          } | null>;
        };
      }
    | {
        readonly __typename: "RangeAssessmentQuestionNode";
        readonly id: number;
        readonly title: string;
        readonly description?: any | null;
        readonly created: string;
        readonly answersExist: boolean;
        readonly assessmentType: AssessmentType;
        readonly isCommentMandatory: boolean;
        readonly responses: AssessmentQuestionResponses;
        readonly responseVisibility: AssessmentQuestionResponseVisibility;
        readonly questionType: AssessmentQuestionType;
        readonly startValue: number;
        readonly endValue: number;
        readonly labels: ReadonlyArray<string | null>;
        readonly labelDescriptions: ReadonlyArray<string | null>;
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
          readonly reason: string;
        };
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
        } | null;
        readonly categories: {
          readonly __typename: "AssessmentQuestionCategoryNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "AssessmentQuestionCategoryNodeEdge";
            readonly node?: {
              readonly __typename: "AssessmentQuestionCategoryNode";
              readonly id: number;
              readonly title: string;
            } | null;
          } | null>;
        };
      }
    | {
        readonly __typename: "TextAssessmentQuestionNode";
        readonly id: number;
        readonly title: string;
        readonly description?: any | null;
        readonly created: string;
        readonly answersExist: boolean;
        readonly assessmentType: AssessmentType;
        readonly isCommentMandatory: boolean;
        readonly responses: AssessmentQuestionResponses;
        readonly responseVisibility: AssessmentQuestionResponseVisibility;
        readonly questionType: AssessmentQuestionType;
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
          readonly reason: string;
        };
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
        } | null;
        readonly categories: {
          readonly __typename: "AssessmentQuestionCategoryNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "AssessmentQuestionCategoryNodeEdge";
            readonly node?: {
              readonly __typename: "AssessmentQuestionCategoryNode";
              readonly id: number;
              readonly title: string;
            } | null;
          } | null>;
        };
      }
    | null;
};

export type GetAssessmentQuestionsQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GetAssessmentQuestionsQuery = {
  readonly __typename: "Query";
  readonly assessmentQuestions?: {
    readonly __typename: "AssessmentQuestionConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "AssessmentQuestionEdge";
      readonly node?:
        | {
            readonly __typename: "MultiChoiceAssessmentQuestionNode";
            readonly id: number;
            readonly title: string;
            readonly description?: any | null;
            readonly archived: boolean;
            readonly answersExist: boolean;
            readonly isCommentMandatory: boolean;
            readonly responses: AssessmentQuestionResponses;
            readonly responseVisibility: AssessmentQuestionResponseVisibility;
            readonly questionType: AssessmentQuestionType;
            readonly created: string;
            readonly assessmentType: AssessmentType;
            readonly options: ReadonlyArray<string | null>;
            readonly optionDescriptions: ReadonlyArray<string | null>;
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
              readonly reason: string;
            };
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
              readonly reason: string;
            };
            readonly categories: {
              readonly __typename: "AssessmentQuestionCategoryNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "AssessmentQuestionCategoryNodeEdge";
                readonly node?: {
                  readonly __typename: "AssessmentQuestionCategoryNode";
                  readonly id: number;
                  readonly title: string;
                } | null;
              } | null>;
            };
            readonly creator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
            } | null;
          }
        | {
            readonly __typename: "RangeAssessmentQuestionNode";
            readonly id: number;
            readonly title: string;
            readonly description?: any | null;
            readonly archived: boolean;
            readonly answersExist: boolean;
            readonly isCommentMandatory: boolean;
            readonly responses: AssessmentQuestionResponses;
            readonly responseVisibility: AssessmentQuestionResponseVisibility;
            readonly questionType: AssessmentQuestionType;
            readonly created: string;
            readonly assessmentType: AssessmentType;
            readonly startValue: number;
            readonly endValue: number;
            readonly labels: ReadonlyArray<string | null>;
            readonly labelDescriptions: ReadonlyArray<string | null>;
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
              readonly reason: string;
            };
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
              readonly reason: string;
            };
            readonly categories: {
              readonly __typename: "AssessmentQuestionCategoryNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "AssessmentQuestionCategoryNodeEdge";
                readonly node?: {
                  readonly __typename: "AssessmentQuestionCategoryNode";
                  readonly id: number;
                  readonly title: string;
                } | null;
              } | null>;
            };
            readonly creator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
            } | null;
          }
        | {
            readonly __typename: "TextAssessmentQuestionNode";
            readonly id: number;
            readonly title: string;
            readonly description?: any | null;
            readonly archived: boolean;
            readonly answersExist: boolean;
            readonly isCommentMandatory: boolean;
            readonly responses: AssessmentQuestionResponses;
            readonly responseVisibility: AssessmentQuestionResponseVisibility;
            readonly questionType: AssessmentQuestionType;
            readonly created: string;
            readonly assessmentType: AssessmentType;
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
              readonly reason: string;
            };
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
              readonly reason: string;
            };
            readonly categories: {
              readonly __typename: "AssessmentQuestionCategoryNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "AssessmentQuestionCategoryNodeEdge";
                readonly node?: {
                  readonly __typename: "AssessmentQuestionCategoryNode";
                  readonly id: number;
                  readonly title: string;
                } | null;
              } | null>;
            };
            readonly creator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
            } | null;
          }
        | null;
    } | null>;
  } | null;
};

export type UnsubmitAssessmentMutationVariables = Exact<{
  assessmentId: Scalars["Int"]["input"];
}>;

export type UnsubmitAssessmentMutation = {
  readonly __typename: "Mutation";
  readonly reopenAssessment: {
    readonly __typename: "ReopenAssessmentMutation";
    readonly assessment: {
      readonly __typename: "AssessmentNode";
      readonly id: number;
      readonly state: AssessmentState;
    };
  };
};

export type SaveAssessmentNomineesMutationVariables = Exact<{
  complianceProgramId: Scalars["Int"]["input"];
  assessmentTemplateId: Scalars["Int"]["input"];
  responderIds:
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>;
  targetId: Scalars["Int"]["input"];
}>;

export type SaveAssessmentNomineesMutation = {
  readonly __typename: "Mutation";
  readonly setAssessmentNominations?: {
    readonly __typename: "SetAssessmentNominationsMutation";
    readonly complianceProgram?: {
      readonly __typename: "ComplianceProgramNode";
      readonly id: number;
      readonly nominationsForUser: {
        readonly __typename: "AssessmentNominationNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "AssessmentNominationNodeEdge";
          readonly node?: {
            readonly __typename: "AssessmentNominationNode";
            readonly id: number;
            readonly responder: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            };
          } | null;
        } | null>;
      };
    } | null;
    readonly assessmentOpenForNomination?: {
      readonly __typename: "AssessmentOpenForNominationNode";
      readonly id?: string | null;
      readonly nominations: {
        readonly __typename: "AssessmentNominationNodeConnection";
        readonly totalCount: number;
      };
    } | null;
  } | null;
};

export type SaveAssessmentDeliveryMutationVariables = Exact<{
  assessmentDeliveryId: Scalars["Int"]["input"];
  excludedAnswerIds?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<AssessmentDeliveryState>;
}>;

export type SaveAssessmentDeliveryMutation = {
  readonly __typename: "Mutation";
  readonly updateAssessmentDelivery: {
    readonly __typename: "UpdateAssessmentDeliveryMutation";
    readonly assessmentDelivery: {
      readonly __typename: "AssessmentDeliveryNode";
      readonly id: number;
    };
  };
};

export type CalendarMeetingFragmentFragment = {
  readonly __typename: "MeetingNode";
  readonly id: number;
  readonly draft: boolean;
  readonly status?: EventStatus | null;
  readonly externalUrl?: string | null;
  readonly ignored: boolean;
  readonly videoConferenceType?: OnlineVideoConference | null;
  readonly videoConferenceUrl?: string | null;
  readonly title?: string | null;
  readonly startDatetime?: string | null;
  readonly isRecurring: boolean;
  readonly endDatetime?: string | null;
  readonly meetingGroupId: number;
  readonly canDelete?: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  } | null;
  readonly canUpdate?: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  } | null;
  readonly transcript?: {
    readonly __typename: "MeetingTranscriptNode";
    readonly id: number;
  } | null;
  readonly organizer?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
  } | null;
  readonly meetingGroup?: {
    readonly __typename: "MeetingGroupNode";
    readonly id: number;
    readonly hasTemplate?: boolean | null;
    readonly isFormalOneonone: boolean;
    readonly facilitator?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
      readonly avatar?: string | null;
    } | null;
  } | null;
};

export type CalendarEventFragmentFragment = {
  readonly __typename: "BasicMeetingNode";
  readonly id: number;
  readonly startDatetime?: string | null;
  readonly endDatetime?: string | null;
  readonly title?: string | null;
  readonly meetingGroupId: number;
  readonly canRead: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly meeting?: {
    readonly __typename: "MeetingNode";
    readonly id: number;
    readonly draft: boolean;
    readonly status?: EventStatus | null;
    readonly externalUrl?: string | null;
    readonly ignored: boolean;
    readonly videoConferenceType?: OnlineVideoConference | null;
    readonly videoConferenceUrl?: string | null;
    readonly title?: string | null;
    readonly startDatetime?: string | null;
    readonly isRecurring: boolean;
    readonly endDatetime?: string | null;
    readonly meetingGroupId: number;
    readonly canDelete?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
    } | null;
    readonly canUpdate?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
    } | null;
    readonly transcript?: {
      readonly __typename: "MeetingTranscriptNode";
      readonly id: number;
    } | null;
    readonly organizer?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
    } | null;
    readonly meetingGroup?: {
      readonly __typename: "MeetingGroupNode";
      readonly id: number;
      readonly hasTemplate?: boolean | null;
      readonly isFormalOneonone: boolean;
      readonly facilitator?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly avatar?: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetCalendarMeetingsQueryVariables = Exact<{
  forUserId: Scalars["Int"]["input"];
  startDatetime_Lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDatetime_Gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  ignored?: InputMaybe<Scalars["Boolean"]["input"]>;
  statusIn?: InputMaybe<
    ReadonlyArray<InputMaybe<EventStatus>> | InputMaybe<EventStatus>
  >;
  participants?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetCalendarMeetingsQuery = {
  readonly __typename: "Query";
  readonly calendar?: {
    readonly __typename: "BasicMeetingNodeConnection";
    readonly totalCount: number;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly startCursor?: string | null;
      readonly endCursor?: string | null;
    };
    readonly edges: ReadonlyArray<{
      readonly __typename: "BasicMeetingNodeEdge";
      readonly node?: {
        readonly __typename: "BasicMeetingNode";
        readonly id: number;
        readonly startDatetime?: string | null;
        readonly endDatetime?: string | null;
        readonly title?: string | null;
        readonly meetingGroupId: number;
        readonly canRead: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly meeting?: {
          readonly __typename: "MeetingNode";
          readonly id: number;
          readonly draft: boolean;
          readonly status?: EventStatus | null;
          readonly externalUrl?: string | null;
          readonly ignored: boolean;
          readonly videoConferenceType?: OnlineVideoConference | null;
          readonly videoConferenceUrl?: string | null;
          readonly title?: string | null;
          readonly startDatetime?: string | null;
          readonly isRecurring: boolean;
          readonly endDatetime?: string | null;
          readonly meetingGroupId: number;
          readonly canDelete?: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          } | null;
          readonly canUpdate?: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          } | null;
          readonly transcript?: {
            readonly __typename: "MeetingTranscriptNode";
            readonly id: number;
          } | null;
          readonly organizer?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
          } | null;
          readonly meetingGroup?: {
            readonly __typename: "MeetingGroupNode";
            readonly id: number;
            readonly hasTemplate?: boolean | null;
            readonly isFormalOneonone: boolean;
            readonly facilitator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetGoalProgressQueryQueryVariables = Exact<{
  artifactId: Scalars["Int"]["input"];
}>;

export type GetGoalProgressQueryQuery = {
  readonly __typename: "Query";
  readonly artifact:
    | {
        readonly __typename: "ActionItemArtifactNode";
        readonly id: number;
        readonly title?: string | null;
      }
    | {
        readonly __typename: "AsanaTaskArtifactNode";
        readonly id: number;
        readonly title?: string | null;
      }
    | {
        readonly __typename: "DecisionArtifactNode";
        readonly id: number;
        readonly title?: string | null;
      }
    | {
        readonly __typename: "DocumentArtifactNode";
        readonly id: number;
        readonly title?: string | null;
      }
    | {
        readonly __typename: "FeedbackArtifactNode";
        readonly id: number;
        readonly title?: string | null;
      }
    | {
        readonly __typename: "GithubIssuesArtifactNode";
        readonly id: number;
        readonly title?: string | null;
      }
    | {
        readonly __typename: "GoalArtifactNode";
        readonly status: GoalStatus;
        readonly startValue: number;
        readonly currentValue: number;
        readonly targetValue: number;
        readonly progress: number;
        readonly progressType: GoalProgressType;
        readonly id: number;
        readonly title?: string | null;
        readonly keyResults: {
          readonly __typename: "KeyResultNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "KeyResultNodeEdge";
            readonly node?: {
              readonly __typename: "KeyResultNode";
              readonly id: number;
              readonly title: string;
              readonly startValue: number;
              readonly currentValue: number;
              readonly targetValue: number;
              readonly progressType: GoalProgressType;
              readonly kpi?: {
                readonly __typename: "KPINode";
                readonly id: number;
                readonly title: string;
                readonly currentMeasurement?: {
                  readonly __typename: "KPIMeasurementNode";
                  readonly id: number;
                  readonly measurement: number;
                } | null;
              } | null;
              readonly canUpdate?: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              } | null;
              readonly assignee?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
                readonly email: string;
              } | null;
            } | null;
          } | null>;
        };
      }
    | {
        readonly __typename: "KPIArtifactNode";
        readonly id: number;
        readonly title?: string | null;
      }
    | {
        readonly __typename: "RatingArtifactNode";
        readonly id: number;
        readonly title?: string | null;
      }
    | {
        readonly __typename: "RecognitionArtifactNode";
        readonly id: number;
        readonly title?: string | null;
      };
};

export type GoalCheckinMutationMutationVariables = Exact<{
  comment?: InputMaybe<Scalars["JSONString"]["input"]>;
  currentValue?: InputMaybe<Scalars["Float"]["input"]>;
  goalStatus?: InputMaybe<GoalStatus>;
  keyResults?: InputMaybe<
    | ReadonlyArray<InputMaybe<GoalCheckinKeyResultInput>>
    | InputMaybe<GoalCheckinKeyResultInput>
  >;
  goalId: Scalars["Int"]["input"];
}>;

export type GoalCheckinMutationMutation = {
  readonly __typename: "Mutation";
  readonly createGoalCheckin?: {
    readonly __typename: "CreateGoalCheckinMutation";
    readonly goal?: {
      readonly __typename: "GoalArtifactNode";
      readonly status: GoalStatus;
      readonly startValue: number;
      readonly currentValue: number;
      readonly targetValue: number;
      readonly progress: number;
      readonly progressType: GoalProgressType;
      readonly id: number;
      readonly keyResults: {
        readonly __typename: "KeyResultNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "KeyResultNodeEdge";
          readonly node?: {
            readonly __typename: "KeyResultNode";
            readonly id: number;
            readonly title: string;
            readonly startValue: number;
            readonly currentValue: number;
            readonly targetValue: number;
            readonly progressType: GoalProgressType;
            readonly kpi?: {
              readonly __typename: "KPINode";
              readonly id: number;
              readonly title: string;
              readonly currentMeasurement?: {
                readonly __typename: "KPIMeasurementNode";
                readonly id: number;
                readonly measurement: number;
              } | null;
            } | null;
            readonly canUpdate?: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            } | null;
            readonly assignee?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
              readonly email: string;
            } | null;
          } | null;
        } | null>;
      };
      readonly activities: {
        readonly __typename: "ArtifactActivityNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "ArtifactActivityNodeEdge";
          readonly node?: {
            readonly __typename: "ArtifactActivityNode";
            readonly id: number;
            readonly created: string;
            readonly activityType: ArtifactActivityType;
            readonly canDelete?: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
              readonly reason: string;
            } | null;
            readonly canUpdate?: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
              readonly reason: string;
            } | null;
            readonly actor?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
            readonly changedFields?: ReadonlyArray<{
              readonly __typename: "ArtifactChangedFieldNode";
              readonly fieldName?: string | null;
              readonly previousValue?: string | null;
              readonly newValue?: string | null;
              readonly changedFields?: ReadonlyArray<{
                readonly __typename: "ArtifactChangedFieldNode";
                readonly newValue?: string | null;
                readonly previousValue?: string | null;
                readonly fieldName?: string | null;
              } | null> | null;
              readonly newValueObject?:
                | {
                    readonly __typename: "BaseArtifactNode";
                    readonly id: number;
                    readonly title: string;
                    readonly artifactType: ArtifactType;
                  }
                | {
                    readonly __typename: "CommentNode";
                    readonly id: number;
                    readonly comment?: any | null;
                    readonly deleted?: boolean | null;
                    readonly creator?: {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    } | null;
                  }
                | {
                    readonly __typename: "KeyResultNode";
                    readonly id: number;
                    readonly title: string;
                  }
                | {
                    readonly __typename: "TopicNode";
                    readonly id: number;
                    readonly title: string;
                    readonly meeting?: {
                      readonly __typename: "MeetingNode";
                      readonly id: number;
                      readonly title?: string | null;
                      readonly meetingGroupId: number;
                    } | null;
                  }
                | {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  }
                | null;
              readonly previousValueObject?:
                | {
                    readonly __typename: "BaseArtifactNode";
                    readonly id: number;
                    readonly title: string;
                    readonly artifactType: ArtifactType;
                  }
                | { readonly __typename: "CommentNode" }
                | {
                    readonly __typename: "KeyResultNode";
                    readonly id: number;
                    readonly title: string;
                  }
                | {
                    readonly __typename: "TopicNode";
                    readonly id: number;
                    readonly title: string;
                    readonly meeting?: {
                      readonly __typename: "MeetingNode";
                      readonly id: number;
                      readonly title?: string | null;
                      readonly meetingGroupId: number;
                    } | null;
                  }
                | {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  }
                | null;
            } | null> | null;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type CreateCommentMutationMutationVariables = Exact<{
  comment: Scalars["String"]["input"];
  topicId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type CreateCommentMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateComment?: {
    readonly __typename: "CreateOrUpdateCommentMutation";
    readonly comment?: {
      readonly __typename: "CommentNode";
      readonly id: number;
      readonly created: string;
      readonly comment?: any | null;
      readonly creator?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly avatar?: string | null;
      } | null;
      readonly topic?: {
        readonly __typename: "TopicNode";
        readonly id: number;
        readonly commentCount?: number | null;
      } | null;
      readonly artifact?:
        | {
            readonly __typename: "ActionItemArtifactNode";
            readonly id: number;
            readonly comments?: {
              readonly __typename: "CommentNodeConnection";
              readonly totalCount: number;
            } | null;
          }
        | {
            readonly __typename: "AsanaTaskArtifactNode";
            readonly id: number;
            readonly comments?: {
              readonly __typename: "CommentNodeConnection";
              readonly totalCount: number;
            } | null;
          }
        | {
            readonly __typename: "DecisionArtifactNode";
            readonly id: number;
            readonly comments?: {
              readonly __typename: "CommentNodeConnection";
              readonly totalCount: number;
            } | null;
          }
        | {
            readonly __typename: "DocumentArtifactNode";
            readonly id: number;
            readonly comments?: {
              readonly __typename: "CommentNodeConnection";
              readonly totalCount: number;
            } | null;
          }
        | {
            readonly __typename: "FeedbackArtifactNode";
            readonly id: number;
            readonly comments?: {
              readonly __typename: "CommentNodeConnection";
              readonly totalCount: number;
            } | null;
          }
        | {
            readonly __typename: "GithubIssuesArtifactNode";
            readonly id: number;
            readonly comments?: {
              readonly __typename: "CommentNodeConnection";
              readonly totalCount: number;
            } | null;
          }
        | {
            readonly __typename: "GoalArtifactNode";
            readonly id: number;
            readonly comments?: {
              readonly __typename: "CommentNodeConnection";
              readonly totalCount: number;
            } | null;
          }
        | {
            readonly __typename: "KPIArtifactNode";
            readonly id: number;
            readonly comments?: {
              readonly __typename: "CommentNodeConnection";
              readonly totalCount: number;
            } | null;
          }
        | {
            readonly __typename: "RatingArtifactNode";
            readonly id: number;
            readonly comments?: {
              readonly __typename: "CommentNodeConnection";
              readonly totalCount: number;
            } | null;
          }
        | {
            readonly __typename: "RecognitionArtifactNode";
            readonly id: number;
            readonly comments?: {
              readonly __typename: "CommentNodeConnection";
              readonly totalCount: number;
            } | null;
          }
        | null;
    } | null;
  } | null;
};

export type DeleteCommentMutationMutationVariables = Exact<{
  commentId: Scalars["Int"]["input"];
}>;

export type DeleteCommentMutationMutation = {
  readonly __typename: "Mutation";
  readonly deleteComment?: {
    readonly __typename: "DeleteCommentMutation";
    readonly topic?: {
      readonly __typename: "TopicNode";
      readonly id: number;
    } | null;
  } | null;
};

export type GetCommentsOfRelatedTopicQueryVariables = Exact<{
  topicId: Scalars["Int"]["input"];
}>;

export type GetCommentsOfRelatedTopicQuery = {
  readonly __typename: "Query";
  readonly me?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly avatar?: string | null;
    readonly name: string;
  } | null;
  readonly comments?: {
    readonly __typename: "CommentNodeConnection";
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly __typename: "CommentNodeEdge";
      readonly node?: {
        readonly __typename: "CommentNode";
        readonly id: number;
        readonly created: string;
        readonly comment?: any | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetCommentsQueryVariables = Exact<{
  topicId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetCommentsQuery = {
  readonly __typename: "Query";
  readonly me?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly avatar?: string | null;
    readonly name: string;
  } | null;
  readonly comments?: {
    readonly __typename: "CommentNodeConnection";
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly __typename: "CommentNodeEdge";
      readonly node?: {
        readonly __typename: "CommentNode";
        readonly id: number;
        readonly created: string;
        readonly comment?: any | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type UpdateCommentMutationMutationVariables = Exact<{
  commentId: Scalars["Int"]["input"];
  comment: Scalars["String"]["input"];
}>;

export type UpdateCommentMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateComment?: {
    readonly __typename: "CreateOrUpdateCommentMutation";
    readonly comment?: {
      readonly __typename: "CommentNode";
      readonly id: number;
      readonly comment?: any | null;
    } | null;
  } | null;
};

type DashboardGoalFragment_ActionItemArtifactNode_Fragment = {
  readonly __typename: "ActionItemArtifactNode";
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly created: string;
  readonly isStale: boolean;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
};

type DashboardGoalFragment_AsanaTaskArtifactNode_Fragment = {
  readonly __typename: "AsanaTaskArtifactNode";
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly created: string;
  readonly isStale: boolean;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
};

type DashboardGoalFragment_DecisionArtifactNode_Fragment = {
  readonly __typename: "DecisionArtifactNode";
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly created: string;
  readonly isStale: boolean;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
};

type DashboardGoalFragment_DocumentArtifactNode_Fragment = {
  readonly __typename: "DocumentArtifactNode";
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly created: string;
  readonly isStale: boolean;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
};

type DashboardGoalFragment_FeedbackArtifactNode_Fragment = {
  readonly __typename: "FeedbackArtifactNode";
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly created: string;
  readonly isStale: boolean;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
};

type DashboardGoalFragment_GithubIssuesArtifactNode_Fragment = {
  readonly __typename: "GithubIssuesArtifactNode";
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly created: string;
  readonly isStale: boolean;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
};

type DashboardGoalFragment_GoalArtifactNode_Fragment = {
  readonly __typename: "GoalArtifactNode";
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly created: string;
  readonly isStale: boolean;
  readonly state: GoalState;
  readonly status: GoalStatus;
  readonly scope: GoalScope;
  readonly goalVisibility: GoalVisibility;
  readonly dueDate?: string | null;
  readonly startDate?: string | null;
  readonly progress: number;
  readonly progressType: GoalProgressType;
  readonly startValue: number;
  readonly currentValue: number;
  readonly targetValue: number;
  readonly ownerCount?: number | null;
  readonly contributorCount?: number | null;
  readonly activities: {
    readonly __typename: "ArtifactActivityNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "ArtifactActivityNodeEdge";
      readonly node?: {
        readonly __typename: "ArtifactActivityNode";
        readonly created: string;
      } | null;
    } | null>;
  };
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
  readonly firstOwner?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly avatar?: string | null;
    readonly name: string;
  } | null;
  readonly canRead: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
};

type DashboardGoalFragment_KpiArtifactNode_Fragment = {
  readonly __typename: "KPIArtifactNode";
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly created: string;
  readonly isStale: boolean;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
};

type DashboardGoalFragment_RatingArtifactNode_Fragment = {
  readonly __typename: "RatingArtifactNode";
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly created: string;
  readonly isStale: boolean;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
};

type DashboardGoalFragment_RecognitionArtifactNode_Fragment = {
  readonly __typename: "RecognitionArtifactNode";
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly created: string;
  readonly isStale: boolean;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
};

export type DashboardGoalFragmentFragment =
  | DashboardGoalFragment_ActionItemArtifactNode_Fragment
  | DashboardGoalFragment_AsanaTaskArtifactNode_Fragment
  | DashboardGoalFragment_DecisionArtifactNode_Fragment
  | DashboardGoalFragment_DocumentArtifactNode_Fragment
  | DashboardGoalFragment_FeedbackArtifactNode_Fragment
  | DashboardGoalFragment_GithubIssuesArtifactNode_Fragment
  | DashboardGoalFragment_GoalArtifactNode_Fragment
  | DashboardGoalFragment_KpiArtifactNode_Fragment
  | DashboardGoalFragment_RatingArtifactNode_Fragment
  | DashboardGoalFragment_RecognitionArtifactNode_Fragment;

export type DashboardTeamUserFragmentFragment = {
  readonly __typename: "UserNode";
  readonly id: number;
  readonly name: string;
  readonly firstName: string;
  readonly email: string;
  readonly avatar?: string | null;
};

export type DismissInsightMutationMutationVariables = Exact<{
  type: InsightType;
  userId: Scalars["Int"]["input"];
  reportId?: InputMaybe<Scalars["Int"]["input"]>;
  managerId?: InputMaybe<Scalars["Int"]["input"]>;
  complianceProgramId?: InputMaybe<Scalars["Int"]["input"]>;
  assessmentGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type DismissInsightMutationMutation = {
  readonly __typename: "Mutation";
  readonly dismissInsight?: {
    readonly __typename: "DismissInsightMutation";
    readonly user?: {
      readonly __typename: "UserNode";
      readonly id: number;
    } | null;
  } | null;
};

export type FavouritesGroupFragmentFragment = {
  readonly __typename: "FavouritesGroupNode";
  readonly id: number;
  readonly users?: {
    readonly __typename: "UserNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "UserNodeEdge";
      readonly node?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly email: string;
        readonly avatar?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type GetNewDashboardFeedbackQueryQueryVariables = Exact<{
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  fetchData: Scalars["Boolean"]["input"];
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackState?: InputMaybe<FeedbackState>;
  createdBetweenDates?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
    | InputMaybe<Scalars["Date"]["input"]>
  >;
  after?: InputMaybe<Scalars["String"]["input"]>;
  first: Scalars["Int"]["input"];
}>;

export type GetNewDashboardFeedbackQueryQuery = {
  readonly __typename: "Query";
  readonly artifacts?: {
    readonly __typename: "ArtifactConnection";
    readonly totalCount: number;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly hasNextPage: boolean;
      readonly endCursor?: string | null;
    };
    readonly edges?: ReadonlyArray<{
      readonly __typename: "ArtifactEdge";
      readonly node?:
        | { readonly __typename: "ActionItemArtifactNode"; readonly id: number }
        | { readonly __typename: "AsanaTaskArtifactNode"; readonly id: number }
        | { readonly __typename: "DecisionArtifactNode"; readonly id: number }
        | { readonly __typename: "DocumentArtifactNode"; readonly id: number }
        | {
            readonly __typename: "FeedbackArtifactNode";
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly created: string;
            readonly updated?: string | null;
            readonly id: number;
            readonly recipientsCanView: boolean;
            readonly recipientsManagersCanView: boolean;
            readonly adminsCanView: boolean;
            readonly creatorCanView: boolean;
            readonly feedbackState: FeedbackState;
            readonly message?: any | null;
            readonly creator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly feedbackRecipients: {
              readonly __typename: "UserNodeConnection";
              readonly totalCount: number;
              readonly edges: ReadonlyArray<{
                readonly __typename: "UserNodeEdge";
                readonly node?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly avatar?: string | null;
                  readonly name: string;
                } | null;
              } | null>;
            };
            readonly sender: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly avatar?: string | null;
              readonly name: string;
            };
          }
        | {
            readonly __typename: "GithubIssuesArtifactNode";
            readonly id: number;
          }
        | { readonly __typename: "GoalArtifactNode"; readonly id: number }
        | { readonly __typename: "KPIArtifactNode"; readonly id: number }
        | { readonly __typename: "RatingArtifactNode"; readonly id: number }
        | {
            readonly __typename: "RecognitionArtifactNode";
            readonly id: number;
          }
        | null;
    } | null>;
  } | null;
};

export type GetDashboardGoalsNewPageQueryQueryVariables = Exact<{
  owners?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  contributors?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  goalInvolvingUsers?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  teamGoalsOfUser?: InputMaybe<Scalars["Int"]["input"]>;
  forUserId: Scalars["Int"]["input"];
  fetchData: Scalars["Boolean"]["input"];
  goalStates: ReadonlyArray<InputMaybe<GoalState>> | InputMaybe<GoalState>;
  goalScopes?: InputMaybe<
    ReadonlyArray<InputMaybe<GoalScope>> | InputMaybe<GoalScope>
  >;
  goalDueBetweenDates?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
    | InputMaybe<Scalars["Date"]["input"]>
  >;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetDashboardGoalsNewPageQueryQuery = {
  readonly __typename: "Query";
  readonly goalsForUser?: {
    readonly __typename: "GoalArtifactConnection";
    readonly totalCount: number;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly hasNextPage: boolean;
      readonly endCursor?: string | null;
    };
    readonly edges?: ReadonlyArray<{
      readonly __typename: "GoalArtifactEdge";
      readonly node?: {
        readonly __typename: "GoalArtifactNode";
        readonly id: number;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly created: string;
        readonly isStale: boolean;
        readonly state: GoalState;
        readonly status: GoalStatus;
        readonly scope: GoalScope;
        readonly goalVisibility: GoalVisibility;
        readonly dueDate?: string | null;
        readonly startDate?: string | null;
        readonly progress: number;
        readonly progressType: GoalProgressType;
        readonly startValue: number;
        readonly currentValue: number;
        readonly targetValue: number;
        readonly ownerCount?: number | null;
        readonly contributorCount?: number | null;
        readonly activities: {
          readonly __typename: "ArtifactActivityNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactActivityNodeEdge";
            readonly node?: {
              readonly __typename: "ArtifactActivityNode";
              readonly created: string;
            } | null;
          } | null>;
        };
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
        readonly firstOwner?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly avatar?: string | null;
          readonly name: string;
        } | null;
        readonly canRead: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      } | null;
    } | null>;
  } | null;
};

export type DashboardInsightFragment = {
  readonly __typename: "InsightNode";
  readonly id: number;
  readonly type: InsightType;
  readonly report?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly firstName: string;
    readonly name: string;
    readonly avatar?: string | null;
    readonly email: string;
  } | null;
  readonly manager?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly firstName: string;
    readonly name: string;
    readonly avatar?: string | null;
    readonly email: string;
  } | null;
  readonly artifact?:
    | { readonly __typename: "ActionItemArtifactNode"; readonly id: number }
    | { readonly __typename: "AsanaTaskArtifactNode"; readonly id: number }
    | { readonly __typename: "DecisionArtifactNode"; readonly id: number }
    | { readonly __typename: "DocumentArtifactNode"; readonly id: number }
    | {
        readonly __typename: "FeedbackArtifactNode";
        readonly id: number;
        readonly recipients: {
          readonly __typename: "UserNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "UserNodeEdge";
            readonly node?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
          } | null>;
        };
      }
    | { readonly __typename: "GithubIssuesArtifactNode"; readonly id: number }
    | {
        readonly __typename: "GoalArtifactNode";
        readonly id: number;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly progressType: GoalProgressType;
      }
    | { readonly __typename: "KPIArtifactNode"; readonly id: number }
    | { readonly __typename: "RatingArtifactNode"; readonly id: number }
    | { readonly __typename: "RecognitionArtifactNode"; readonly id: number }
    | null;
  readonly matchingOneononesForComplianceProgram?: {
    readonly __typename: "MeetingNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "MeetingNodeEdge";
      readonly node?: {
        readonly __typename: "MeetingNode";
        readonly id: number;
        readonly meetingGroupId: number;
      } | null;
    } | null>;
  } | null;
  readonly unscheduledMatchingOneononesForComplianceProgram?: {
    readonly __typename: "MeetingNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "MeetingNodeEdge";
      readonly node?: {
        readonly __typename: "MeetingNode";
        readonly id: number;
        readonly meetingGroupId: number;
      } | null;
    } | null>;
  } | null;
  readonly assessmentGroup?: {
    readonly __typename: "AssessmentGroupNode";
    readonly id: number;
    readonly title: string;
  } | null;
  readonly complianceProgram?: {
    readonly __typename: "ComplianceProgramNode";
    readonly id: number;
    readonly title: string;
    readonly requiredTopicTemplates: {
      readonly __typename: "TopicTemplateNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "TopicTemplateNodeEdge";
        readonly node?: {
          readonly __typename: "TopicTemplateNode";
          readonly id: number;
          readonly title: string;
        } | null;
      } | null>;
    };
  } | null;
};

export type GetDashboardInsightsQueryVariables = Exact<{
  personId: Scalars["Int"]["input"];
}>;

export type GetDashboardInsightsQuery = {
  readonly __typename: "Query";
  readonly user?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly insights?: {
      readonly __typename: "InsightConnection";
      readonly totalCount: number;
      readonly edges: ReadonlyArray<{
        readonly __typename: "InsightEdge";
        readonly node?: {
          readonly __typename: "InsightNode";
          readonly id: number;
          readonly type: InsightType;
          readonly report?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly firstName: string;
            readonly name: string;
            readonly avatar?: string | null;
            readonly email: string;
          } | null;
          readonly manager?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly firstName: string;
            readonly name: string;
            readonly avatar?: string | null;
            readonly email: string;
          } | null;
          readonly artifact?:
            | {
                readonly __typename: "ActionItemArtifactNode";
                readonly id: number;
              }
            | {
                readonly __typename: "AsanaTaskArtifactNode";
                readonly id: number;
              }
            | {
                readonly __typename: "DecisionArtifactNode";
                readonly id: number;
              }
            | {
                readonly __typename: "DocumentArtifactNode";
                readonly id: number;
              }
            | {
                readonly __typename: "FeedbackArtifactNode";
                readonly id: number;
                readonly recipients: {
                  readonly __typename: "UserNodeConnection";
                  readonly edges: ReadonlyArray<{
                    readonly __typename: "UserNodeEdge";
                    readonly node?: {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    } | null;
                  } | null>;
                };
              }
            | {
                readonly __typename: "GithubIssuesArtifactNode";
                readonly id: number;
              }
            | {
                readonly __typename: "GoalArtifactNode";
                readonly id: number;
                readonly title?: string | null;
                readonly artifactType: ArtifactType;
                readonly progressType: GoalProgressType;
              }
            | { readonly __typename: "KPIArtifactNode"; readonly id: number }
            | { readonly __typename: "RatingArtifactNode"; readonly id: number }
            | {
                readonly __typename: "RecognitionArtifactNode";
                readonly id: number;
              }
            | null;
          readonly matchingOneononesForComplianceProgram?: {
            readonly __typename: "MeetingNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "MeetingNodeEdge";
              readonly node?: {
                readonly __typename: "MeetingNode";
                readonly id: number;
                readonly meetingGroupId: number;
              } | null;
            } | null>;
          } | null;
          readonly unscheduledMatchingOneononesForComplianceProgram?: {
            readonly __typename: "MeetingNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "MeetingNodeEdge";
              readonly node?: {
                readonly __typename: "MeetingNode";
                readonly id: number;
                readonly meetingGroupId: number;
              } | null;
            } | null>;
          } | null;
          readonly assessmentGroup?: {
            readonly __typename: "AssessmentGroupNode";
            readonly id: number;
            readonly title: string;
          } | null;
          readonly complianceProgram?: {
            readonly __typename: "ComplianceProgramNode";
            readonly id: number;
            readonly title: string;
            readonly requiredTopicTemplates: {
              readonly __typename: "TopicTemplateNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "TopicTemplateNodeEdge";
                readonly node?: {
                  readonly __typename: "TopicTemplateNode";
                  readonly id: number;
                  readonly title: string;
                } | null;
              } | null>;
            };
          } | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type GetNewDashboardRecognitionsQueryQueryVariables = Exact<{
  asUser?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
    | InputMaybe<Scalars["Date"]["input"]>
  >;
  createdBy?: InputMaybe<Scalars["Int"]["input"]>;
  fetchData: Scalars["Boolean"]["input"];
  after?: InputMaybe<Scalars["String"]["input"]>;
  first: Scalars["Int"]["input"];
}>;

export type GetNewDashboardRecognitionsQueryQuery = {
  readonly __typename: "Query";
  readonly artifacts?: {
    readonly __typename: "ArtifactConnection";
    readonly totalCount: number;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly hasNextPage: boolean;
      readonly endCursor?: string | null;
    };
    readonly edges?: ReadonlyArray<{
      readonly __typename: "ArtifactEdge";
      readonly node?:
        | { readonly __typename: "ActionItemArtifactNode"; readonly id: number }
        | { readonly __typename: "AsanaTaskArtifactNode"; readonly id: number }
        | { readonly __typename: "DecisionArtifactNode"; readonly id: number }
        | { readonly __typename: "DocumentArtifactNode"; readonly id: number }
        | { readonly __typename: "FeedbackArtifactNode"; readonly id: number }
        | {
            readonly __typename: "GithubIssuesArtifactNode";
            readonly id: number;
          }
        | { readonly __typename: "GoalArtifactNode"; readonly id: number }
        | { readonly __typename: "KPIArtifactNode"; readonly id: number }
        | { readonly __typename: "RatingArtifactNode"; readonly id: number }
        | {
            readonly __typename: "RecognitionArtifactNode";
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly created: string;
            readonly updated?: string | null;
            readonly id: number;
            readonly creator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly recipients?: {
              readonly __typename: "UserNodeConnection";
              readonly totalCount: number;
              readonly edges: ReadonlyArray<{
                readonly __typename: "UserNodeEdge";
                readonly node?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
              } | null>;
            } | null;
            readonly recognitionCoreValue?: {
              readonly __typename: "RecognitionCoreValueNode";
              readonly id: number;
              readonly title: string;
              readonly description: string;
              readonly emoji?: string | null;
              readonly image?: string | null;
            } | null;
          }
        | null;
    } | null>;
  } | null;
};

export type GetDashboardSelectedUserQueryQueryVariables = Exact<{
  userId: Scalars["Int"]["input"];
}>;

export type GetDashboardSelectedUserQueryQuery = {
  readonly __typename: "Query";
  readonly user?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly firstName: string;
    readonly email: string;
    readonly avatar?: string | null;
  } | null;
};

export type GetOrganizationMembersForDashboardQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
  search: Scalars["String"]["input"];
  excludeUserIds?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
}>;

export type GetOrganizationMembersForDashboardQuery = {
  readonly __typename: "Query";
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
    readonly members: {
      readonly __typename: "UserMemberNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "UserMemberNodeEdge";
        readonly node?: {
          readonly __typename: "UserMemberNode";
          readonly id: number;
          readonly user?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly email: string;
            readonly avatar?: string | null;
          } | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type DashboardPeopleTableUserMeetingFragmentFragment = {
  readonly __typename: "MeetingNode";
  readonly id: number;
  readonly meetingGroupId: number;
  readonly startDatetime?: string | null;
};

export type DashboardPeopleTableUserFragmentFragment = {
  readonly __typename: "UserNode";
  readonly id: number;
  readonly name: string;
  readonly firstName: string;
  readonly email: string;
  readonly avatar?: string | null;
  readonly goals?: {
    readonly __typename: "ArtifactConnection";
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly __typename: "ArtifactEdge";
      readonly node?:
        | { readonly __typename: "ActionItemArtifactNode"; readonly id: number }
        | { readonly __typename: "AsanaTaskArtifactNode"; readonly id: number }
        | { readonly __typename: "DecisionArtifactNode"; readonly id: number }
        | { readonly __typename: "DocumentArtifactNode"; readonly id: number }
        | { readonly __typename: "FeedbackArtifactNode"; readonly id: number }
        | {
            readonly __typename: "GithubIssuesArtifactNode";
            readonly id: number;
          }
        | {
            readonly __typename: "GoalArtifactNode";
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
            readonly state: GoalState;
            readonly isStale: boolean;
            readonly status: GoalStatus;
            readonly progress: number;
            readonly startValue: number;
            readonly currentValue: number;
            readonly targetValue: number;
            readonly progressType: GoalProgressType;
            readonly id: number;
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | { readonly __typename: "KPIArtifactNode"; readonly id: number }
        | { readonly __typename: "RatingArtifactNode"; readonly id: number }
        | {
            readonly __typename: "RecognitionArtifactNode";
            readonly id: number;
          }
        | null;
    } | null>;
  } | null;
  readonly recognitions?: {
    readonly __typename: "ArtifactConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "ArtifactEdge";
      readonly node?:
        | { readonly __typename: "ActionItemArtifactNode"; readonly id: number }
        | { readonly __typename: "AsanaTaskArtifactNode"; readonly id: number }
        | { readonly __typename: "DecisionArtifactNode"; readonly id: number }
        | { readonly __typename: "DocumentArtifactNode"; readonly id: number }
        | { readonly __typename: "FeedbackArtifactNode"; readonly id: number }
        | {
            readonly __typename: "GithubIssuesArtifactNode";
            readonly id: number;
          }
        | { readonly __typename: "GoalArtifactNode"; readonly id: number }
        | { readonly __typename: "KPIArtifactNode"; readonly id: number }
        | { readonly __typename: "RatingArtifactNode"; readonly id: number }
        | {
            readonly __typename: "RecognitionArtifactNode";
            readonly artifactType: ArtifactType;
            readonly created: string;
            readonly id: number;
          }
        | null;
    } | null>;
  } | null;
};

export type GetPeopleTableQueryQueryVariables = Exact<{
  userId: Scalars["Int"]["input"];
  now: Scalars["DateTime"]["input"];
  peopleTableGoalMax: Scalars["Int"]["input"];
}>;

export type GetPeopleTableQueryQuery = {
  readonly __typename: "Query";
  readonly user?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly directReports: {
      readonly __typename: "UserNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "UserNodeEdge";
        readonly node?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly firstName: string;
          readonly email: string;
          readonly avatar?: string | null;
          readonly meetings?: {
            readonly __typename: "MeetingNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "MeetingNodeEdge";
              readonly node?: {
                readonly __typename: "MeetingNode";
                readonly id: number;
                readonly meetingGroupId: number;
                readonly startDatetime?: string | null;
              } | null;
            } | null>;
          } | null;
          readonly goals?: {
            readonly __typename: "ArtifactConnection";
            readonly totalCount: number;
            readonly edges: ReadonlyArray<{
              readonly __typename: "ArtifactEdge";
              readonly node?:
                | {
                    readonly __typename: "ActionItemArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "AsanaTaskArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "DecisionArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "DocumentArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "FeedbackArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "GithubIssuesArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "GoalArtifactNode";
                    readonly title?: string | null;
                    readonly artifactType: ArtifactType;
                    readonly state: GoalState;
                    readonly isStale: boolean;
                    readonly status: GoalStatus;
                    readonly progress: number;
                    readonly startValue: number;
                    readonly currentValue: number;
                    readonly targetValue: number;
                    readonly progressType: GoalProgressType;
                    readonly id: number;
                    readonly canUpdate: {
                      readonly __typename: "PermissionNode";
                      readonly permission: boolean;
                    };
                  }
                | {
                    readonly __typename: "KPIArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "RatingArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "RecognitionArtifactNode";
                    readonly id: number;
                  }
                | null;
            } | null>;
          } | null;
          readonly recognitions?: {
            readonly __typename: "ArtifactConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "ArtifactEdge";
              readonly node?:
                | {
                    readonly __typename: "ActionItemArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "AsanaTaskArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "DecisionArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "DocumentArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "FeedbackArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "GithubIssuesArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "GoalArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "KPIArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "RatingArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "RecognitionArtifactNode";
                    readonly artifactType: ArtifactType;
                    readonly created: string;
                    readonly id: number;
                  }
                | null;
            } | null>;
          } | null;
        } | null;
      } | null>;
    };
    readonly managers: {
      readonly __typename: "UserNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "UserNodeEdge";
        readonly node?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly firstName: string;
          readonly email: string;
          readonly avatar?: string | null;
          readonly directReports: {
            readonly __typename: "UserNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "UserNodeEdge";
              readonly node?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly firstName: string;
                readonly email: string;
                readonly avatar?: string | null;
                readonly meetings?: {
                  readonly __typename: "MeetingNodeConnection";
                  readonly edges: ReadonlyArray<{
                    readonly __typename: "MeetingNodeEdge";
                    readonly node?: {
                      readonly __typename: "MeetingNode";
                      readonly id: number;
                      readonly meetingGroupId: number;
                      readonly startDatetime?: string | null;
                    } | null;
                  } | null>;
                } | null;
                readonly goals?: {
                  readonly __typename: "ArtifactConnection";
                  readonly totalCount: number;
                  readonly edges: ReadonlyArray<{
                    readonly __typename: "ArtifactEdge";
                    readonly node?:
                      | {
                          readonly __typename: "ActionItemArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "AsanaTaskArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "DecisionArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "DocumentArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "FeedbackArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "GithubIssuesArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "GoalArtifactNode";
                          readonly title?: string | null;
                          readonly artifactType: ArtifactType;
                          readonly state: GoalState;
                          readonly isStale: boolean;
                          readonly status: GoalStatus;
                          readonly progress: number;
                          readonly startValue: number;
                          readonly currentValue: number;
                          readonly targetValue: number;
                          readonly progressType: GoalProgressType;
                          readonly id: number;
                          readonly canUpdate: {
                            readonly __typename: "PermissionNode";
                            readonly permission: boolean;
                          };
                        }
                      | {
                          readonly __typename: "KPIArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "RatingArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "RecognitionArtifactNode";
                          readonly id: number;
                        }
                      | null;
                  } | null>;
                } | null;
                readonly recognitions?: {
                  readonly __typename: "ArtifactConnection";
                  readonly edges: ReadonlyArray<{
                    readonly __typename: "ArtifactEdge";
                    readonly node?:
                      | {
                          readonly __typename: "ActionItemArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "AsanaTaskArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "DecisionArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "DocumentArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "FeedbackArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "GithubIssuesArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "GoalArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "KPIArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "RatingArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "RecognitionArtifactNode";
                          readonly artifactType: ArtifactType;
                          readonly created: string;
                          readonly id: number;
                        }
                      | null;
                  } | null>;
                } | null;
              } | null;
            } | null>;
          };
          readonly meetings?: {
            readonly __typename: "MeetingNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "MeetingNodeEdge";
              readonly node?: {
                readonly __typename: "MeetingNode";
                readonly id: number;
                readonly meetingGroupId: number;
                readonly startDatetime?: string | null;
              } | null;
            } | null>;
          } | null;
          readonly goals?: {
            readonly __typename: "ArtifactConnection";
            readonly totalCount: number;
            readonly edges: ReadonlyArray<{
              readonly __typename: "ArtifactEdge";
              readonly node?:
                | {
                    readonly __typename: "ActionItemArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "AsanaTaskArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "DecisionArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "DocumentArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "FeedbackArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "GithubIssuesArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "GoalArtifactNode";
                    readonly title?: string | null;
                    readonly artifactType: ArtifactType;
                    readonly state: GoalState;
                    readonly isStale: boolean;
                    readonly status: GoalStatus;
                    readonly progress: number;
                    readonly startValue: number;
                    readonly currentValue: number;
                    readonly targetValue: number;
                    readonly progressType: GoalProgressType;
                    readonly id: number;
                    readonly canUpdate: {
                      readonly __typename: "PermissionNode";
                      readonly permission: boolean;
                    };
                  }
                | {
                    readonly __typename: "KPIArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "RatingArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "RecognitionArtifactNode";
                    readonly id: number;
                  }
                | null;
            } | null>;
          } | null;
          readonly recognitions?: {
            readonly __typename: "ArtifactConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "ArtifactEdge";
              readonly node?:
                | {
                    readonly __typename: "ActionItemArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "AsanaTaskArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "DecisionArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "DocumentArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "FeedbackArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "GithubIssuesArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "GoalArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "KPIArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "RatingArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "RecognitionArtifactNode";
                    readonly artifactType: ArtifactType;
                    readonly created: string;
                    readonly id: number;
                  }
                | null;
            } | null>;
          } | null;
        } | null;
      } | null>;
    };
    readonly favouritesGroups?: {
      readonly __typename: "FavouritesGroupNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "FavouritesGroupNodeEdge";
        readonly node?: {
          readonly __typename: "FavouritesGroupNode";
          readonly id: number;
          readonly users?: {
            readonly __typename: "UserNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "UserNodeEdge";
              readonly node?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly firstName: string;
                readonly email: string;
                readonly avatar?: string | null;
                readonly meetings?: {
                  readonly __typename: "MeetingNodeConnection";
                  readonly edges: ReadonlyArray<{
                    readonly __typename: "MeetingNodeEdge";
                    readonly node?: {
                      readonly __typename: "MeetingNode";
                      readonly id: number;
                      readonly meetingGroupId: number;
                      readonly startDatetime?: string | null;
                    } | null;
                  } | null>;
                } | null;
                readonly goals?: {
                  readonly __typename: "ArtifactConnection";
                  readonly totalCount: number;
                  readonly edges: ReadonlyArray<{
                    readonly __typename: "ArtifactEdge";
                    readonly node?:
                      | {
                          readonly __typename: "ActionItemArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "AsanaTaskArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "DecisionArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "DocumentArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "FeedbackArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "GithubIssuesArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "GoalArtifactNode";
                          readonly title?: string | null;
                          readonly artifactType: ArtifactType;
                          readonly state: GoalState;
                          readonly isStale: boolean;
                          readonly status: GoalStatus;
                          readonly progress: number;
                          readonly startValue: number;
                          readonly currentValue: number;
                          readonly targetValue: number;
                          readonly progressType: GoalProgressType;
                          readonly id: number;
                          readonly canUpdate: {
                            readonly __typename: "PermissionNode";
                            readonly permission: boolean;
                          };
                        }
                      | {
                          readonly __typename: "KPIArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "RatingArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "RecognitionArtifactNode";
                          readonly id: number;
                        }
                      | null;
                  } | null>;
                } | null;
                readonly recognitions?: {
                  readonly __typename: "ArtifactConnection";
                  readonly edges: ReadonlyArray<{
                    readonly __typename: "ArtifactEdge";
                    readonly node?:
                      | {
                          readonly __typename: "ActionItemArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "AsanaTaskArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "DecisionArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "DocumentArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "FeedbackArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "GithubIssuesArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "GoalArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "KPIArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "RatingArtifactNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "RecognitionArtifactNode";
                          readonly artifactType: ArtifactType;
                          readonly created: string;
                          readonly id: number;
                        }
                      | null;
                  } | null>;
                } | null;
              } | null;
            } | null>;
          } | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type SetRelationshipsMutationMutationVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
  relationships?: InputMaybe<
    ReadonlyArray<InputMaybe<RelationshipInput>> | InputMaybe<RelationshipInput>
  >;
  userId: Scalars["Int"]["input"];
  favouriteUserIds:
    | ReadonlyArray<
        | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
        | InputMaybe<Scalars["Int"]["input"]>
      >
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>;
  invitations:
    | ReadonlyArray<InputMaybe<InvitationInput>>
    | InputMaybe<InvitationInput>;
}>;

export type SetRelationshipsMutationMutation = {
  readonly __typename: "Mutation";
  readonly setRelationships?: {
    readonly __typename: "SetRelationshipsMutation";
    readonly me?: {
      readonly __typename: "UserNode";
      readonly id: number;
    } | null;
  } | null;
  readonly setFavouriteGroups?: {
    readonly __typename: "SetFavouriteGroupsMutation";
    readonly me?: {
      readonly __typename: "UserNode";
      readonly id: number;
    } | null;
  } | null;
  readonly createInvitations?: {
    readonly __typename: "CreateInvitationsMutation";
    readonly invitationErrors?: ReadonlyArray<{
      readonly __typename: "InviteError";
      readonly inviteeEmail?: string | null;
      readonly message?: string | null;
    } | null> | null;
  } | null;
};

export type DeleteExplorerBookmarkMutationMutationVariables = Exact<{
  explorerBookmarkId: Scalars["Int"]["input"];
}>;

export type DeleteExplorerBookmarkMutationMutation = {
  readonly __typename: "Mutation";
  readonly deleteExplorerBookmark?: {
    readonly __typename: "DeleteExplorerBookmark";
    readonly user?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly explorerBookmarks?: {
        readonly __typename: "ExplorerFilterBookmarkNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "ExplorerFilterBookmarkNodeEdge";
          readonly node?: {
            readonly __typename: "ExplorerFilterBookmarkNode";
            readonly id: number;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type ExportExplorerToCsvMutationVariables = Exact<{
  search: Scalars["String"]["input"];
  options?: InputMaybe<SearchOptions>;
}>;

export type ExportExplorerToCsvMutation = {
  readonly __typename: "Mutation";
  readonly searchCsvExport?: {
    readonly __typename: "SearchCSVExportMutation";
    readonly user?: {
      readonly __typename: "UserNode";
      readonly email: string;
    } | null;
  } | null;
};

export type GetArtifactExplorerMeetingGroupQueryQueryVariables = Exact<{
  meetingGroupId: Scalars["Int"]["input"];
  search?: InputMaybe<Scalars["String"]["input"]>;
  fetchMeetingGroup: Scalars["Boolean"]["input"];
  organizationId: Scalars["Int"]["input"];
}>;

export type GetArtifactExplorerMeetingGroupQueryQuery = {
  readonly __typename: "Query";
  readonly meetingGroups?: {
    readonly __typename: "MeetingGroupNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "MeetingGroupNodeEdge";
      readonly node?: {
        readonly __typename: "MeetingGroupNode";
        readonly id: number;
        readonly recurrenceRule?: string | null;
        readonly meetings?: {
          readonly __typename: "MeetingNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "MeetingNodeEdge";
            readonly node?: {
              readonly __typename: "MeetingNode";
              readonly id: number;
              readonly meetingGroupId: number;
              readonly title?: string | null;
              readonly startDatetime?: string | null;
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  } | null;
  readonly meetingGroup?: {
    readonly __typename: "MeetingGroupNode";
    readonly id: number;
    readonly recurrenceRule?: string | null;
    readonly meetings?: {
      readonly __typename: "MeetingNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "MeetingNodeEdge";
        readonly node?: {
          readonly __typename: "MeetingNode";
          readonly id: number;
          readonly meetingGroupId: number;
          readonly title?: string | null;
          readonly startDatetime?: string | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type GetArtifactExplorerMeetingQueryQueryVariables = Exact<{
  meetingId: Scalars["Int"]["input"];
}>;

export type GetArtifactExplorerMeetingQueryQuery = {
  readonly __typename: "Query";
  readonly meeting?: {
    readonly __typename: "MeetingNode";
    readonly id: number;
    readonly title?: string | null;
  } | null;
};

export type GetArtifactExplorerTeamsQueryQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
}>;

export type GetArtifactExplorerTeamsQueryQuery = {
  readonly __typename: "Query";
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
    readonly teams?: {
      readonly __typename: "TeamNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "TeamNodeEdge";
        readonly node?: {
          readonly __typename: "TeamNode";
          readonly id: number;
          readonly title: string;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type ExplorerMeetingResultFragmentFragment = {
  readonly __typename: "MeetingWithRelevantSectionsNode";
  readonly meeting?: {
    readonly __typename: "MeetingNode";
    readonly id: number;
    readonly title?: string | null;
    readonly startDatetime?: string | null;
    readonly meetingGroupId: number;
    readonly meetingGroup?: {
      readonly __typename: "MeetingGroupNode";
      readonly id: number;
      readonly isFormalOneonone: boolean;
    } | null;
    readonly participants?: {
      readonly __typename: "MeetingParticipantConnection";
      readonly totalCount: number;
      readonly edges: ReadonlyArray<{
        readonly __typename: "MeetingParticipantEdge";
        readonly node?: {
          readonly __typename: "MeetingParticipantNode";
          readonly id?: string | null;
          readonly user?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
          } | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type ExplorerBaseArtifactFragmentFragment = {
  readonly __typename: "BaseArtifactNode";
  readonly id: number;
  readonly specificArtifact?:
    | {
        readonly __typename: "ActionItemArtifactNode";
        readonly isComplete?: boolean | null;
        readonly actionItemState?: number | null;
        readonly dueDate?: string | null;
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly created: string;
        readonly updated?: string | null;
        readonly assignee?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly avatar?: string | null;
          readonly name: string;
        } | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "AsanaTaskArtifactNode";
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly created: string;
        readonly updated?: string | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "DecisionArtifactNode";
        readonly decision: string;
        readonly decisionState?: DecisionState | null;
        readonly isDraft?: boolean | null;
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly created: string;
        readonly updated?: string | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "DocumentArtifactNode";
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly created: string;
        readonly updated?: string | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "FeedbackArtifactNode";
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly created: string;
        readonly updated?: string | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "GithubIssuesArtifactNode";
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly created: string;
        readonly updated?: string | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "GoalArtifactNode";
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly created: string;
        readonly updated?: string | null;
        readonly state: GoalState;
        readonly status: GoalStatus;
        readonly scope: GoalScope;
        readonly goalVisibility: GoalVisibility;
        readonly dueDate?: string | null;
        readonly startDate?: string | null;
        readonly progress: number;
        readonly progressType: GoalProgressType;
        readonly startValue: number;
        readonly currentValue: number;
        readonly targetValue: number;
        readonly isStale: boolean;
        readonly ownerCount?: number | null;
        readonly contributorCount?: number | null;
        readonly activities: {
          readonly __typename: "ArtifactActivityNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactActivityNodeEdge";
            readonly node?: {
              readonly __typename: "ArtifactActivityNode";
              readonly created: string;
            } | null;
          } | null>;
        };
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly firstOwner?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly avatar?: string | null;
          readonly name: string;
        } | null;
        readonly canRead: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "KPIArtifactNode";
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly created: string;
        readonly updated?: string | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "RatingArtifactNode";
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly created: string;
        readonly updated?: string | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "RecognitionArtifactNode";
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly created: string;
        readonly updated?: string | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly recipients?: {
          readonly __typename: "UserNodeConnection";
          readonly totalCount: number;
          readonly edges: ReadonlyArray<{
            readonly __typename: "UserNodeEdge";
            readonly node?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
          } | null>;
        } | null;
        readonly recognitionCoreValue?: {
          readonly __typename: "RecognitionCoreValueNode";
          readonly id: number;
          readonly title: string;
          readonly description: string;
          readonly emoji?: string | null;
          readonly image?: string | null;
        } | null;
      }
    | null;
};

type ExplorerResultFragment_BaseArtifactNode_Fragment = {
  readonly __typename: "BaseArtifactNode";
  readonly id: number;
  readonly specificArtifact?:
    | {
        readonly __typename: "ActionItemArtifactNode";
        readonly isComplete?: boolean | null;
        readonly actionItemState?: number | null;
        readonly dueDate?: string | null;
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly created: string;
        readonly updated?: string | null;
        readonly assignee?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly avatar?: string | null;
          readonly name: string;
        } | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "AsanaTaskArtifactNode";
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly created: string;
        readonly updated?: string | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "DecisionArtifactNode";
        readonly decision: string;
        readonly decisionState?: DecisionState | null;
        readonly isDraft?: boolean | null;
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly created: string;
        readonly updated?: string | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "DocumentArtifactNode";
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly created: string;
        readonly updated?: string | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "FeedbackArtifactNode";
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly created: string;
        readonly updated?: string | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "GithubIssuesArtifactNode";
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly created: string;
        readonly updated?: string | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "GoalArtifactNode";
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly created: string;
        readonly updated?: string | null;
        readonly state: GoalState;
        readonly status: GoalStatus;
        readonly scope: GoalScope;
        readonly goalVisibility: GoalVisibility;
        readonly dueDate?: string | null;
        readonly startDate?: string | null;
        readonly progress: number;
        readonly progressType: GoalProgressType;
        readonly startValue: number;
        readonly currentValue: number;
        readonly targetValue: number;
        readonly isStale: boolean;
        readonly ownerCount?: number | null;
        readonly contributorCount?: number | null;
        readonly activities: {
          readonly __typename: "ArtifactActivityNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactActivityNodeEdge";
            readonly node?: {
              readonly __typename: "ArtifactActivityNode";
              readonly created: string;
            } | null;
          } | null>;
        };
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly firstOwner?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly avatar?: string | null;
          readonly name: string;
        } | null;
        readonly canRead: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "KPIArtifactNode";
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly created: string;
        readonly updated?: string | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "RatingArtifactNode";
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly created: string;
        readonly updated?: string | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "RecognitionArtifactNode";
        readonly id: number;
        readonly artifactType: ArtifactType;
        readonly title?: string | null;
        readonly created: string;
        readonly updated?: string | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly recipients?: {
          readonly __typename: "UserNodeConnection";
          readonly totalCount: number;
          readonly edges: ReadonlyArray<{
            readonly __typename: "UserNodeEdge";
            readonly node?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
          } | null>;
        } | null;
        readonly recognitionCoreValue?: {
          readonly __typename: "RecognitionCoreValueNode";
          readonly id: number;
          readonly title: string;
          readonly description: string;
          readonly emoji?: string | null;
          readonly image?: string | null;
        } | null;
      }
    | null;
};

type ExplorerResultFragment_CommentNode_Fragment = {
  readonly __typename: "CommentNode";
};

type ExplorerResultFragment_FlowNode_Fragment = {
  readonly __typename: "FlowNode";
};

type ExplorerResultFragment_GithubIssueNode_Fragment = {
  readonly __typename: "GithubIssueNode";
  readonly id: number;
  readonly title: string;
  readonly url: string;
  readonly repository?: string | null;
  readonly closedAt?: string | null;
  readonly isMerged?: boolean | null;
  readonly assignees: ReadonlyArray<{
    readonly __typename: "ExternalProfileNode";
    readonly username?: string | null;
    readonly url?: string | null;
  } | null>;
};

type ExplorerResultFragment_HubspotDealNode_Fragment = {
  readonly __typename: "HubspotDealNode";
  readonly id: number;
  readonly title: string;
  readonly url: string;
  readonly closedAt?: string | null;
  readonly amount?: number | null;
  readonly closedWon?: boolean | null;
  readonly stageName?: string | null;
  readonly assignees: ReadonlyArray<{
    readonly __typename: "ExternalProfileNode";
    readonly username?: string | null;
    readonly url?: string | null;
  } | null>;
};

type ExplorerResultFragment_JiraIssueNode_Fragment = {
  readonly __typename: "JiraIssueNode";
  readonly id: number;
  readonly title: string;
  readonly url: string;
  readonly closedAt?: string | null;
  readonly assignees: ReadonlyArray<{
    readonly __typename: "ExternalProfileNode";
    readonly username?: string | null;
    readonly url?: string | null;
  } | null>;
};

type ExplorerResultFragment_MeetingWithRelevantSectionsNode_Fragment = {
  readonly __typename: "MeetingWithRelevantSectionsNode";
  readonly meeting?: {
    readonly __typename: "MeetingNode";
    readonly id: number;
    readonly title?: string | null;
    readonly startDatetime?: string | null;
    readonly meetingGroupId: number;
    readonly meetingGroup?: {
      readonly __typename: "MeetingGroupNode";
      readonly id: number;
      readonly isFormalOneonone: boolean;
    } | null;
    readonly participants?: {
      readonly __typename: "MeetingParticipantConnection";
      readonly totalCount: number;
      readonly edges: ReadonlyArray<{
        readonly __typename: "MeetingParticipantEdge";
        readonly node?: {
          readonly __typename: "MeetingParticipantNode";
          readonly id?: string | null;
          readonly user?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
          } | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

type ExplorerResultFragment_TopicNode_Fragment = {
  readonly __typename: "TopicNode";
};

type ExplorerResultFragment_UserNode_Fragment = {
  readonly __typename: "UserNode";
  readonly id: number;
  readonly name: string;
  readonly email: string;
  readonly avatar?: string | null;
};

export type ExplorerResultFragmentFragment =
  | ExplorerResultFragment_BaseArtifactNode_Fragment
  | ExplorerResultFragment_CommentNode_Fragment
  | ExplorerResultFragment_FlowNode_Fragment
  | ExplorerResultFragment_GithubIssueNode_Fragment
  | ExplorerResultFragment_HubspotDealNode_Fragment
  | ExplorerResultFragment_JiraIssueNode_Fragment
  | ExplorerResultFragment_MeetingWithRelevantSectionsNode_Fragment
  | ExplorerResultFragment_TopicNode_Fragment
  | ExplorerResultFragment_UserNode_Fragment;

export type GetExplorerResultsQueryQueryVariables = Exact<{
  limit: Scalars["Int"]["input"];
  after?: InputMaybe<Scalars["String"]["input"]>;
  search: Scalars["String"]["input"];
  options?: InputMaybe<SearchOptions>;
}>;

export type GetExplorerResultsQueryQuery = {
  readonly __typename: "Query";
  readonly search?: {
    readonly __typename: "SearchConnection";
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor?: string | null;
      readonly hasNextPage: boolean;
    };
    readonly edges: ReadonlyArray<{
      readonly __typename: "SearchEdge";
      readonly node?:
        | {
            readonly __typename: "BaseArtifactNode";
            readonly id: number;
            readonly specificArtifact?:
              | {
                  readonly __typename: "ActionItemArtifactNode";
                  readonly isComplete?: boolean | null;
                  readonly actionItemState?: number | null;
                  readonly dueDate?: string | null;
                  readonly id: number;
                  readonly artifactType: ArtifactType;
                  readonly title?: string | null;
                  readonly created: string;
                  readonly updated?: string | null;
                  readonly assignee?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly avatar?: string | null;
                    readonly name: string;
                  } | null;
                  readonly creator?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly canUpdate: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "AsanaTaskArtifactNode";
                  readonly id: number;
                  readonly artifactType: ArtifactType;
                  readonly title?: string | null;
                  readonly created: string;
                  readonly updated?: string | null;
                  readonly creator?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly canUpdate: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "DecisionArtifactNode";
                  readonly decision: string;
                  readonly decisionState?: DecisionState | null;
                  readonly isDraft?: boolean | null;
                  readonly id: number;
                  readonly artifactType: ArtifactType;
                  readonly title?: string | null;
                  readonly created: string;
                  readonly updated?: string | null;
                  readonly creator?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly canUpdate: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "DocumentArtifactNode";
                  readonly id: number;
                  readonly artifactType: ArtifactType;
                  readonly title?: string | null;
                  readonly created: string;
                  readonly updated?: string | null;
                  readonly creator?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly canUpdate: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "FeedbackArtifactNode";
                  readonly id: number;
                  readonly artifactType: ArtifactType;
                  readonly title?: string | null;
                  readonly created: string;
                  readonly updated?: string | null;
                  readonly creator?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly canUpdate: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "GithubIssuesArtifactNode";
                  readonly id: number;
                  readonly artifactType: ArtifactType;
                  readonly title?: string | null;
                  readonly created: string;
                  readonly updated?: string | null;
                  readonly creator?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly canUpdate: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "GoalArtifactNode";
                  readonly id: number;
                  readonly artifactType: ArtifactType;
                  readonly title?: string | null;
                  readonly created: string;
                  readonly updated?: string | null;
                  readonly state: GoalState;
                  readonly status: GoalStatus;
                  readonly scope: GoalScope;
                  readonly goalVisibility: GoalVisibility;
                  readonly dueDate?: string | null;
                  readonly startDate?: string | null;
                  readonly progress: number;
                  readonly progressType: GoalProgressType;
                  readonly startValue: number;
                  readonly currentValue: number;
                  readonly targetValue: number;
                  readonly isStale: boolean;
                  readonly ownerCount?: number | null;
                  readonly contributorCount?: number | null;
                  readonly activities: {
                    readonly __typename: "ArtifactActivityNodeConnection";
                    readonly edges: ReadonlyArray<{
                      readonly __typename: "ArtifactActivityNodeEdge";
                      readonly node?: {
                        readonly __typename: "ArtifactActivityNode";
                        readonly created: string;
                      } | null;
                    } | null>;
                  };
                  readonly creator?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly canUpdate: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly firstOwner?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly avatar?: string | null;
                    readonly name: string;
                  } | null;
                  readonly canRead: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "KPIArtifactNode";
                  readonly id: number;
                  readonly artifactType: ArtifactType;
                  readonly title?: string | null;
                  readonly created: string;
                  readonly updated?: string | null;
                  readonly creator?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly canUpdate: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "RatingArtifactNode";
                  readonly id: number;
                  readonly artifactType: ArtifactType;
                  readonly title?: string | null;
                  readonly created: string;
                  readonly updated?: string | null;
                  readonly creator?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly canUpdate: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                }
              | {
                  readonly __typename: "RecognitionArtifactNode";
                  readonly id: number;
                  readonly artifactType: ArtifactType;
                  readonly title?: string | null;
                  readonly created: string;
                  readonly updated?: string | null;
                  readonly creator?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly canUpdate: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  };
                  readonly recipients?: {
                    readonly __typename: "UserNodeConnection";
                    readonly totalCount: number;
                    readonly edges: ReadonlyArray<{
                      readonly __typename: "UserNodeEdge";
                      readonly node?: {
                        readonly __typename: "UserNode";
                        readonly id: number;
                        readonly name: string;
                        readonly avatar?: string | null;
                      } | null;
                    } | null>;
                  } | null;
                  readonly recognitionCoreValue?: {
                    readonly __typename: "RecognitionCoreValueNode";
                    readonly id: number;
                    readonly title: string;
                    readonly description: string;
                    readonly emoji?: string | null;
                    readonly image?: string | null;
                  } | null;
                }
              | null;
          }
        | { readonly __typename: "CommentNode" }
        | { readonly __typename: "FlowNode" }
        | {
            readonly __typename: "GithubIssueNode";
            readonly id: number;
            readonly title: string;
            readonly url: string;
            readonly repository?: string | null;
            readonly closedAt?: string | null;
            readonly isMerged?: boolean | null;
            readonly assignees: ReadonlyArray<{
              readonly __typename: "ExternalProfileNode";
              readonly username?: string | null;
              readonly url?: string | null;
            } | null>;
          }
        | {
            readonly __typename: "HubspotDealNode";
            readonly id: number;
            readonly title: string;
            readonly url: string;
            readonly closedAt?: string | null;
            readonly amount?: number | null;
            readonly closedWon?: boolean | null;
            readonly stageName?: string | null;
            readonly assignees: ReadonlyArray<{
              readonly __typename: "ExternalProfileNode";
              readonly username?: string | null;
              readonly url?: string | null;
            } | null>;
          }
        | {
            readonly __typename: "JiraIssueNode";
            readonly id: number;
            readonly title: string;
            readonly url: string;
            readonly closedAt?: string | null;
            readonly assignees: ReadonlyArray<{
              readonly __typename: "ExternalProfileNode";
              readonly username?: string | null;
              readonly url?: string | null;
            } | null>;
          }
        | {
            readonly __typename: "MeetingWithRelevantSectionsNode";
            readonly meeting?: {
              readonly __typename: "MeetingNode";
              readonly id: number;
              readonly title?: string | null;
              readonly startDatetime?: string | null;
              readonly meetingGroupId: number;
              readonly meetingGroup?: {
                readonly __typename: "MeetingGroupNode";
                readonly id: number;
                readonly isFormalOneonone: boolean;
              } | null;
              readonly participants?: {
                readonly __typename: "MeetingParticipantConnection";
                readonly totalCount: number;
                readonly edges: ReadonlyArray<{
                  readonly __typename: "MeetingParticipantEdge";
                  readonly node?: {
                    readonly __typename: "MeetingParticipantNode";
                    readonly id?: string | null;
                    readonly user?: {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                    } | null;
                  } | null;
                } | null>;
              } | null;
            } | null;
          }
        | { readonly __typename: "TopicNode" }
        | {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly email: string;
            readonly avatar?: string | null;
          }
        | null;
    } | null>;
  } | null;
};

export type SaveExplorerBookmarkMutationMutationVariables = Exact<{
  explorerBookmarkId?: InputMaybe<Scalars["Int"]["input"]>;
  filters?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  private?: InputMaybe<Scalars["Boolean"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type SaveExplorerBookmarkMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateExplorerBookmark?: {
    readonly __typename: "CreateOrUpdateExplorerBookmarkMutation";
    readonly user?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly explorerBookmarks?: {
        readonly __typename: "ExplorerFilterBookmarkNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "ExplorerFilterBookmarkNodeEdge";
          readonly node?: {
            readonly __typename: "ExplorerFilterBookmarkNode";
            readonly id: number;
          } | null;
        } | null>;
      } | null;
    } | null;
    readonly explorerBookmark?: {
      readonly __typename: "ExplorerFilterBookmarkNode";
      readonly id: number;
      readonly title: string;
      readonly filters: string;
      readonly private: boolean;
      readonly canUpdate: {
        readonly __typename: "PermissionNode";
        readonly permission: boolean;
      };
      readonly canDelete: {
        readonly __typename: "PermissionNode";
        readonly permission: boolean;
      };
    } | null;
  } | null;
};

export type GetMeetingForExtensionQueryQueryVariables = Exact<{
  googleMeetUrl?: InputMaybe<Scalars["String"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetMeetingForExtensionQueryQuery = {
  readonly __typename: "Query";
  readonly meeting?: {
    readonly __typename: "MeetingNode";
    readonly id: number;
    readonly title?: string | null;
    readonly startDatetime?: string | null;
    readonly startDate?: string | null;
    readonly endDatetime?: string | null;
    readonly externalUrl?: string | null;
    readonly videoConferenceType?: OnlineVideoConference | null;
    readonly videoConferenceUrl?: string | null;
    readonly allowExternalViewers?: boolean | null;
    readonly writableSharingUrl?: string | null;
    readonly websocketToken?: string | null;
    readonly hasPreviousMeetings?: boolean | null;
    readonly hasPreviousMeetingsWithTopics?: boolean | null;
    readonly ignored: boolean;
    readonly hasBotInMeeting?: boolean | null;
    readonly botIsRecording?: boolean | null;
    readonly botIsWaitingToJoinMeeting?: boolean | null;
    readonly finalizedAt?: string | null;
    readonly isFinalized: boolean;
    readonly isSubmitted: boolean;
    readonly hasIncompleteMandatoryTopics: boolean;
    readonly transcriptionStatus?: boolean | null;
    readonly created: string;
    readonly draft: boolean;
    readonly isRecurring: boolean;
    readonly hasExternalParticipants?: boolean | null;
    readonly canFinalize: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
    };
    readonly canDelete?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
    } | null;
    readonly canUpdate?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
    } | null;
    readonly finalizedBy?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
      readonly avatar?: string | null;
    } | null;
    readonly organization?: {
      readonly __typename: "OrganizationNode";
      readonly id: number;
      readonly name: string;
      readonly effectivePricingTier?: PricingTier | null;
      readonly enableMeetingSummarization: boolean;
      readonly domains?: ReadonlyArray<string | null> | null;
    } | null;
    readonly organizer?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
    } | null;
    readonly subject?: {
      readonly __typename: "UserNode";
      readonly id: number;
    } | null;
    readonly creator?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
      readonly firstName: string;
      readonly lastName: string;
      readonly avatar?: string | null;
    } | null;
    readonly transcript?: {
      readonly __typename: "MeetingTranscriptNode";
      readonly id: number;
    } | null;
    readonly meetingGroup?: {
      readonly __typename: "MeetingGroupNode";
      readonly id: number;
      readonly title: string;
      readonly hasTemplate?: boolean | null;
      readonly allowAdminVisibility: boolean;
      readonly allowOrgVisibility: boolean;
      readonly allowManagementTreeVisibility: boolean;
      readonly isFormalOneonone: boolean;
      readonly transcribeMeetingsByDefault?: boolean | null;
      readonly addTranscriptionBotToMeetingsByDefault: boolean;
      readonly canUpdate?: {
        readonly __typename: "PermissionNode";
        readonly permission: boolean;
      } | null;
      readonly facilitator?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly avatar?: string | null;
      } | null;
      readonly organization?: {
        readonly __typename: "OrganizationNode";
        readonly id: number;
        readonly name: string;
      } | null;
      readonly syncCredentials?: {
        readonly __typename: "UserSyncCredentialsNode";
        readonly id: number;
        readonly hasCalendarWriteCredentials?: boolean | null;
        readonly provider?: SyncCredentialsTypeEnum | null;
      } | null;
      readonly topicTemplate?: {
        readonly __typename: "TopicTemplateNode";
        readonly id: number;
        readonly title: string;
        readonly globalTemplate: boolean;
        readonly publicTemplate: boolean;
        readonly meetingTemplate: boolean;
        readonly finalizePermissions: FinalizePermissions;
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
      } | null;
    } | null;
    readonly topics?: {
      readonly __typename: "TopicNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "TopicNodeEdge";
        readonly node?: {
          readonly __typename: "TopicNode";
          readonly id: number;
          readonly title: string;
          readonly description?: string | null;
          readonly discussionNotes?: any | null;
          readonly created: string;
          readonly state?: TopicState | null;
          readonly eventChannelName?: string | null;
          readonly includesIndividualNotes: boolean;
          readonly includesIndividualNotesForCurrentUser: boolean;
          readonly includesSharedNotes: boolean;
          readonly isMandatory: boolean;
          readonly notesRequirement: NotesRequirement;
          readonly individualNotes?: {
            readonly __typename: "IndividualNoteNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "IndividualNoteNodeEdge";
              readonly node?: {
                readonly __typename: "IndividualNoteNode";
                readonly id: number;
                readonly created: string;
                readonly hidden: boolean;
                readonly enabled: boolean;
                readonly notes?: any | null;
                readonly creator: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                };
              } | null;
            } | null>;
          } | null;
          readonly creator?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
          } | null;
          readonly assignee?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly avatar?: string | null;
            readonly name: string;
          } | null;
          readonly previousTopic?: {
            readonly __typename: "TopicNode";
            readonly id: number;
            readonly discussionNotes?: any | null;
          } | null;
          readonly linkedTemplateTopic?: {
            readonly __typename: "TopicNode";
            readonly id: number;
            readonly title: string;
            readonly discussionNotes?: any | null;
            readonly defaultSubjectNotes?: any | null;
            readonly defaultFacilitatorNotes?: any | null;
            readonly topicTemplate?: {
              readonly __typename: "TopicTemplateNode";
              readonly id: number;
              readonly title: string;
            } | null;
          } | null;
          readonly canUpdate: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly copiedFrom?: {
            readonly __typename: "TopicNode";
            readonly id: number;
            readonly title: string;
            readonly meeting?: {
              readonly __typename: "MeetingNode";
              readonly id: number;
              readonly title?: string | null;
              readonly startDatetime?: string | null;
              readonly meetingGroupId: number;
            } | null;
          } | null;
        } | null;
      } | null>;
    } | null;
    readonly participants?: {
      readonly __typename: "MeetingParticipantConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "MeetingParticipantEdge";
        readonly node?: {
          readonly __typename: "MeetingParticipantNode";
          readonly id?: string | null;
          readonly status?: AttendeeStatus | null;
          readonly participantEmail?: string | null;
          readonly role?: AttendeeRole | null;
          readonly user?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly firstName: string;
            readonly lastName: string;
            readonly avatar?: string | null;
            readonly email: string;
            readonly status?: UserStatus | null;
          } | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type GetFavouriteArtifactsQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetFavouriteArtifactsQueryQuery = {
  readonly __typename: "Query";
  readonly me?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly favouriteArtifacts: {
      readonly __typename: "ArtifactConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "ArtifactEdge";
        readonly node?:
          | {
              readonly __typename: "ActionItemArtifactNode";
              readonly isComplete?: boolean | null;
              readonly id: number;
              readonly artifactType: ArtifactType;
              readonly title?: string | null;
              readonly updated?: string | null;
              readonly created: string;
              readonly assignee?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
              readonly canUpdate: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly creator?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
            }
          | {
              readonly __typename: "AsanaTaskArtifactNode";
              readonly id: number;
              readonly artifactType: ArtifactType;
              readonly title?: string | null;
              readonly updated?: string | null;
              readonly created: string;
              readonly canUpdate: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly creator?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
            }
          | {
              readonly __typename: "DecisionArtifactNode";
              readonly decision: string;
              readonly decisionState?: DecisionState | null;
              readonly isDraft?: boolean | null;
              readonly id: number;
              readonly artifactType: ArtifactType;
              readonly title?: string | null;
              readonly updated?: string | null;
              readonly created: string;
              readonly canUpdate: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly creator?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
            }
          | {
              readonly __typename: "DocumentArtifactNode";
              readonly id: number;
              readonly artifactType: ArtifactType;
              readonly title?: string | null;
              readonly updated?: string | null;
              readonly created: string;
              readonly canUpdate: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly creator?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
            }
          | {
              readonly __typename: "FeedbackArtifactNode";
              readonly id: number;
              readonly artifactType: ArtifactType;
              readonly title?: string | null;
              readonly updated?: string | null;
              readonly created: string;
              readonly canUpdate: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly creator?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
            }
          | {
              readonly __typename: "GithubIssuesArtifactNode";
              readonly id: number;
              readonly artifactType: ArtifactType;
              readonly title?: string | null;
              readonly updated?: string | null;
              readonly created: string;
              readonly canUpdate: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly creator?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
            }
          | {
              readonly __typename: "GoalArtifactNode";
              readonly id: number;
              readonly artifactType: ArtifactType;
              readonly title?: string | null;
              readonly updated?: string | null;
              readonly created: string;
              readonly state: GoalState;
              readonly scope: GoalScope;
              readonly ownerCount?: number | null;
              readonly dueDate?: string | null;
              readonly goalVisibility: GoalVisibility;
              readonly status: GoalStatus;
              readonly progress: number;
              readonly progressType: GoalProgressType;
              readonly currentValue: number;
              readonly startValue: number;
              readonly targetValue: number;
              readonly isStale: boolean;
              readonly parentGoalId?: number | null;
              readonly canUpdate: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly creator?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
              readonly firstOwner?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
              readonly owners?: {
                readonly __typename: "UserNodeConnection";
                readonly edges: ReadonlyArray<{
                  readonly __typename: "UserNodeEdge";
                  readonly node?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                } | null>;
              } | null;
              readonly activities: {
                readonly __typename: "ArtifactActivityNodeConnection";
                readonly edges: ReadonlyArray<{
                  readonly __typename: "ArtifactActivityNodeEdge";
                  readonly node?: {
                    readonly __typename: "ArtifactActivityNode";
                    readonly created: string;
                  } | null;
                } | null>;
              };
            }
          | {
              readonly __typename: "KPIArtifactNode";
              readonly id: number;
              readonly artifactType: ArtifactType;
              readonly title?: string | null;
              readonly updated?: string | null;
              readonly created: string;
              readonly canUpdate: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly creator?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
            }
          | {
              readonly __typename: "RatingArtifactNode";
              readonly id: number;
              readonly artifactType: ArtifactType;
              readonly title?: string | null;
              readonly updated?: string | null;
              readonly created: string;
              readonly rating?: {
                readonly __typename: "RatingNode";
                readonly id: number;
                readonly title: string;
              } | null;
              readonly canUpdate: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly creator?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
            }
          | {
              readonly __typename: "RecognitionArtifactNode";
              readonly id: number;
              readonly artifactType: ArtifactType;
              readonly title?: string | null;
              readonly updated?: string | null;
              readonly created: string;
              readonly canUpdate: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly creator?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
              readonly recognitionCoreValue?: {
                readonly __typename: "RecognitionCoreValueNode";
                readonly id: number;
                readonly title: string;
                readonly emoji?: string | null;
                readonly image?: string | null;
              } | null;
              readonly recipients?: {
                readonly __typename: "UserNodeConnection";
                readonly totalCount: number;
                readonly edges: ReadonlyArray<{
                  readonly __typename: "UserNodeEdge";
                  readonly node?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                } | null>;
              } | null;
            }
          | null;
      } | null>;
    };
  } | null;
};

export type GetFeedbackPageQueryQueryVariables = Exact<{
  pendingRequestRecipientId?: InputMaybe<Scalars["Int"]["input"]>;
  pendingRequestRecipientsOfManagerId?: InputMaybe<Scalars["Int"]["input"]>;
  fetchPendingRequest: Scalars["Boolean"]["input"];
  organizationId: Scalars["Int"]["input"];
  feedbackRecipient?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackRecipients?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  feedbackSender?: InputMaybe<Scalars["Int"]["input"]>;
  feedbackState?: InputMaybe<FeedbackState>;
  limit: Scalars["Int"]["input"];
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetFeedbackPageQueryQuery = {
  readonly __typename: "Query";
  readonly artifacts?: {
    readonly __typename: "ArtifactConnection";
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor?: string | null;
      readonly hasNextPage: boolean;
    };
    readonly edges: ReadonlyArray<{
      readonly __typename: "ArtifactEdge";
      readonly node?:
        | { readonly __typename: "ActionItemArtifactNode"; readonly id: number }
        | { readonly __typename: "AsanaTaskArtifactNode"; readonly id: number }
        | { readonly __typename: "DecisionArtifactNode"; readonly id: number }
        | { readonly __typename: "DocumentArtifactNode"; readonly id: number }
        | {
            readonly __typename: "FeedbackArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly recipientsCanView: boolean;
            readonly recipientsManagersCanView: boolean;
            readonly adminsCanView: boolean;
            readonly creatorCanView: boolean;
            readonly feedbackState: FeedbackState;
            readonly message?: any | null;
            readonly created: string;
            readonly creator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
            readonly feedbackRecipients: {
              readonly __typename: "UserNodeConnection";
              readonly totalCount: number;
              readonly edges: ReadonlyArray<{
                readonly __typename: "UserNodeEdge";
                readonly node?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly avatar?: string | null;
                  readonly name: string;
                } | null;
              } | null>;
            };
            readonly sender: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly avatar?: string | null;
              readonly name: string;
            };
          }
        | {
            readonly __typename: "GithubIssuesArtifactNode";
            readonly id: number;
          }
        | { readonly __typename: "GoalArtifactNode"; readonly id: number }
        | { readonly __typename: "KPIArtifactNode"; readonly id: number }
        | { readonly __typename: "RatingArtifactNode"; readonly id: number }
        | {
            readonly __typename: "RecognitionArtifactNode";
            readonly id: number;
          }
        | null;
    } | null>;
  } | null;
  readonly pendingFeedbackRequests?: {
    readonly __typename: "BasicFeedbackArtifactNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "BasicFeedbackArtifactNodeEdge";
      readonly node?: {
        readonly __typename: "BasicFeedbackArtifactNode";
        readonly id: number;
        readonly title?: string | null;
        readonly created: string;
        readonly feedbackState: FeedbackState;
        readonly sender?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly recipients?: {
          readonly __typename: "UserNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "UserNodeEdge";
            readonly node?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetGoalSearchResultsToAlignQueryQueryVariables = Exact<{
  search: Scalars["String"]["input"];
}>;

export type GetGoalSearchResultsToAlignQueryQuery = {
  readonly __typename: "Query";
  readonly artifacts?: {
    readonly __typename: "ArtifactConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "ArtifactEdge";
      readonly node?:
        | { readonly __typename: "ActionItemArtifactNode"; readonly id: number }
        | { readonly __typename: "AsanaTaskArtifactNode"; readonly id: number }
        | { readonly __typename: "DecisionArtifactNode"; readonly id: number }
        | { readonly __typename: "DocumentArtifactNode"; readonly id: number }
        | { readonly __typename: "FeedbackArtifactNode"; readonly id: number }
        | {
            readonly __typename: "GithubIssuesArtifactNode";
            readonly id: number;
          }
        | {
            readonly __typename: "GoalArtifactNode";
            readonly id: number;
            readonly parentGoalId?: number | null;
            readonly title?: string | null;
            readonly created: string;
            readonly artifactType: ArtifactType;
            readonly state: GoalState;
            readonly status: GoalStatus;
            readonly scope: GoalScope;
            readonly goalVisibility: GoalVisibility;
            readonly dueDate?: string | null;
            readonly startDate?: string | null;
            readonly progress: number;
            readonly progressType: GoalProgressType;
            readonly startValue: number;
            readonly currentValue: number;
            readonly targetValue: number;
            readonly isStale: boolean;
            readonly ownerCount?: number | null;
            readonly contributorCount?: number | null;
            readonly firstOwner?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly avatar?: string | null;
              readonly name: string;
            } | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canRead: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | { readonly __typename: "KPIArtifactNode"; readonly id: number }
        | { readonly __typename: "RatingArtifactNode"; readonly id: number }
        | {
            readonly __typename: "RecognitionArtifactNode";
            readonly id: number;
          }
        | null;
    } | null>;
  } | null;
};

export type GetGoalToAlignQueryQueryVariables = Exact<{
  artifactId: Scalars["Int"]["input"];
}>;

export type GetGoalToAlignQueryQuery = {
  readonly __typename: "Query";
  readonly artifact:
    | { readonly __typename: "ActionItemArtifactNode" }
    | { readonly __typename: "AsanaTaskArtifactNode" }
    | { readonly __typename: "DecisionArtifactNode" }
    | { readonly __typename: "DocumentArtifactNode" }
    | { readonly __typename: "FeedbackArtifactNode" }
    | { readonly __typename: "GithubIssuesArtifactNode" }
    | {
        readonly __typename: "GoalArtifactNode";
        readonly parentGoalId?: number | null;
        readonly id: number;
        readonly title?: string | null;
        readonly created: string;
        readonly artifactType: ArtifactType;
        readonly state: GoalState;
        readonly status: GoalStatus;
        readonly scope: GoalScope;
        readonly goalVisibility: GoalVisibility;
        readonly dueDate?: string | null;
        readonly startDate?: string | null;
        readonly progress: number;
        readonly progressType: GoalProgressType;
        readonly startValue: number;
        readonly currentValue: number;
        readonly targetValue: number;
        readonly isStale: boolean;
        readonly ownerCount?: number | null;
        readonly contributorCount?: number | null;
        readonly childGoals?: {
          readonly __typename: "GoalArtifactConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "GoalArtifactEdge";
            readonly node?: {
              readonly __typename: "GoalArtifactNode";
              readonly id: number;
            } | null;
          } | null>;
        } | null;
        readonly firstOwner?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly avatar?: string | null;
          readonly name: string;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canRead: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | { readonly __typename: "KPIArtifactNode" }
    | { readonly __typename: "RatingArtifactNode" }
    | { readonly __typename: "RecognitionArtifactNode" };
};

export type GoalAlignmentDialogGoalFragmentFragment = {
  readonly __typename: "GoalArtifactNode";
  readonly parentGoalId?: number | null;
  readonly id: number;
  readonly title?: string | null;
  readonly created: string;
  readonly artifactType: ArtifactType;
  readonly state: GoalState;
  readonly status: GoalStatus;
  readonly scope: GoalScope;
  readonly goalVisibility: GoalVisibility;
  readonly dueDate?: string | null;
  readonly startDate?: string | null;
  readonly progress: number;
  readonly progressType: GoalProgressType;
  readonly startValue: number;
  readonly currentValue: number;
  readonly targetValue: number;
  readonly isStale: boolean;
  readonly ownerCount?: number | null;
  readonly contributorCount?: number | null;
  readonly firstOwner?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly avatar?: string | null;
    readonly name: string;
  } | null;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canRead: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
};

type AlignmentGoalFragment_ActionItemArtifactNode_Fragment = {
  readonly __typename: "ActionItemArtifactNode";
  readonly id: number;
};

type AlignmentGoalFragment_AsanaTaskArtifactNode_Fragment = {
  readonly __typename: "AsanaTaskArtifactNode";
  readonly id: number;
};

type AlignmentGoalFragment_DecisionArtifactNode_Fragment = {
  readonly __typename: "DecisionArtifactNode";
  readonly id: number;
};

type AlignmentGoalFragment_DocumentArtifactNode_Fragment = {
  readonly __typename: "DocumentArtifactNode";
  readonly id: number;
};

type AlignmentGoalFragment_FeedbackArtifactNode_Fragment = {
  readonly __typename: "FeedbackArtifactNode";
  readonly id: number;
};

type AlignmentGoalFragment_GithubIssuesArtifactNode_Fragment = {
  readonly __typename: "GithubIssuesArtifactNode";
  readonly id: number;
};

type AlignmentGoalFragment_GoalArtifactNode_Fragment = {
  readonly __typename: "GoalArtifactNode";
  readonly artifactType: ArtifactType;
  readonly title?: string | null;
  readonly updated?: string | null;
  readonly state: GoalState;
  readonly scope: GoalScope;
  readonly ownerCount?: number | null;
  readonly dueDate?: string | null;
  readonly goalVisibility: GoalVisibility;
  readonly status: GoalStatus;
  readonly progress: number;
  readonly progressType: GoalProgressType;
  readonly currentValue: number;
  readonly startValue: number;
  readonly targetValue: number;
  readonly isStale: boolean;
  readonly created: string;
  readonly parentGoalId?: number | null;
  readonly id: number;
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly firstOwner?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
  readonly owners?: {
    readonly __typename: "UserNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "UserNodeEdge";
      readonly node?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly avatar?: string | null;
      } | null;
    } | null>;
  } | null;
  readonly canRead: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly keyResults: {
    readonly __typename: "KeyResultNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "KeyResultNodeEdge";
      readonly node?: {
        readonly __typename: "KeyResultNode";
        readonly id: number;
        readonly title: string;
        readonly startValue: number;
        readonly currentValue: number;
        readonly targetValue: number;
        readonly progressType: GoalProgressType;
        readonly kpi?: {
          readonly __typename: "KPINode";
          readonly id: number;
          readonly title: string;
          readonly currentMeasurement?: {
            readonly __typename: "KPIMeasurementNode";
            readonly id: number;
            readonly measurement: number;
          } | null;
        } | null;
        readonly canUpdate?: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        } | null;
        readonly assignee?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
          readonly email: string;
        } | null;
      } | null;
    } | null>;
  };
};

type AlignmentGoalFragment_KpiArtifactNode_Fragment = {
  readonly __typename: "KPIArtifactNode";
  readonly id: number;
};

type AlignmentGoalFragment_RatingArtifactNode_Fragment = {
  readonly __typename: "RatingArtifactNode";
  readonly id: number;
};

type AlignmentGoalFragment_RecognitionArtifactNode_Fragment = {
  readonly __typename: "RecognitionArtifactNode";
  readonly id: number;
};

export type AlignmentGoalFragmentFragment =
  | AlignmentGoalFragment_ActionItemArtifactNode_Fragment
  | AlignmentGoalFragment_AsanaTaskArtifactNode_Fragment
  | AlignmentGoalFragment_DecisionArtifactNode_Fragment
  | AlignmentGoalFragment_DocumentArtifactNode_Fragment
  | AlignmentGoalFragment_FeedbackArtifactNode_Fragment
  | AlignmentGoalFragment_GithubIssuesArtifactNode_Fragment
  | AlignmentGoalFragment_GoalArtifactNode_Fragment
  | AlignmentGoalFragment_KpiArtifactNode_Fragment
  | AlignmentGoalFragment_RatingArtifactNode_Fragment
  | AlignmentGoalFragment_RecognitionArtifactNode_Fragment;

export type GetAlignmentGoalsQueryQueryVariables = Exact<{
  first: Scalars["Int"]["input"];
  after?: InputMaybe<Scalars["String"]["input"]>;
  goalScopes?: InputMaybe<
    ReadonlyArray<InputMaybe<GoalScope>> | InputMaybe<GoalScope>
  >;
  goalStates?: InputMaybe<
    ReadonlyArray<InputMaybe<GoalState>> | InputMaybe<GoalState>
  >;
  goalDueBetweenDates?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
    | InputMaybe<Scalars["Date"]["input"]>
  >;
  idsToMergeInApolloCache?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  searchGoalOwners?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  searchGoalTeams?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  inPathOfGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  hasSearch: Scalars["Boolean"]["input"];
}>;

export type GetAlignmentGoalsQueryQuery = {
  readonly __typename: "Query";
  readonly artifacts?: {
    readonly __typename: "ArtifactConnection";
    readonly totalCount: number;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly hasNextPage: boolean;
      readonly endCursor?: string | null;
    };
    readonly edges: ReadonlyArray<{
      readonly __typename: "ArtifactEdge";
      readonly node?:
        | { readonly __typename: "ActionItemArtifactNode"; readonly id: number }
        | { readonly __typename: "AsanaTaskArtifactNode"; readonly id: number }
        | { readonly __typename: "DecisionArtifactNode"; readonly id: number }
        | { readonly __typename: "DocumentArtifactNode"; readonly id: number }
        | { readonly __typename: "FeedbackArtifactNode"; readonly id: number }
        | {
            readonly __typename: "GithubIssuesArtifactNode";
            readonly id: number;
          }
        | {
            readonly __typename: "GoalArtifactNode";
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly updated?: string | null;
            readonly state: GoalState;
            readonly scope: GoalScope;
            readonly ownerCount?: number | null;
            readonly dueDate?: string | null;
            readonly goalVisibility: GoalVisibility;
            readonly status: GoalStatus;
            readonly progress: number;
            readonly progressType: GoalProgressType;
            readonly currentValue: number;
            readonly startValue: number;
            readonly targetValue: number;
            readonly isStale: boolean;
            readonly created: string;
            readonly parentGoalId?: number | null;
            readonly id: number;
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly firstOwner?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
            readonly owners?: {
              readonly __typename: "UserNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "UserNodeEdge";
                readonly node?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
              } | null>;
            } | null;
            readonly canRead: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly keyResults: {
              readonly __typename: "KeyResultNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "KeyResultNodeEdge";
                readonly node?: {
                  readonly __typename: "KeyResultNode";
                  readonly id: number;
                  readonly title: string;
                  readonly startValue: number;
                  readonly currentValue: number;
                  readonly targetValue: number;
                  readonly progressType: GoalProgressType;
                  readonly kpi?: {
                    readonly __typename: "KPINode";
                    readonly id: number;
                    readonly title: string;
                    readonly currentMeasurement?: {
                      readonly __typename: "KPIMeasurementNode";
                      readonly id: number;
                      readonly measurement: number;
                    } | null;
                  } | null;
                  readonly canUpdate?: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                  } | null;
                  readonly assignee?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                    readonly email: string;
                  } | null;
                } | null;
              } | null>;
            };
          }
        | { readonly __typename: "KPIArtifactNode"; readonly id: number }
        | { readonly __typename: "RatingArtifactNode"; readonly id: number }
        | {
            readonly __typename: "RecognitionArtifactNode";
            readonly id: number;
          }
        | null;
    } | null>;
  } | null;
  readonly searchResults?: {
    readonly __typename: "ArtifactConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "ArtifactEdge";
      readonly node?:
        | { readonly __typename: "ActionItemArtifactNode"; readonly id: number }
        | { readonly __typename: "AsanaTaskArtifactNode"; readonly id: number }
        | { readonly __typename: "DecisionArtifactNode"; readonly id: number }
        | { readonly __typename: "DocumentArtifactNode"; readonly id: number }
        | { readonly __typename: "FeedbackArtifactNode"; readonly id: number }
        | {
            readonly __typename: "GithubIssuesArtifactNode";
            readonly id: number;
          }
        | { readonly __typename: "GoalArtifactNode"; readonly id: number }
        | { readonly __typename: "KPIArtifactNode"; readonly id: number }
        | { readonly __typename: "RatingArtifactNode"; readonly id: number }
        | {
            readonly __typename: "RecognitionArtifactNode";
            readonly id: number;
          }
        | null;
    } | null>;
  } | null;
};

export type DuplicateGoalMutationMutationVariables = Exact<{
  goalId: Scalars["Int"]["input"];
  parentGoalId?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  dueDate?: InputMaybe<Scalars["Date"]["input"]>;
  resetProgress?: InputMaybe<Scalars["Boolean"]["input"]>;
  duplicateKeyResults?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type DuplicateGoalMutationMutation = {
  readonly __typename: "Mutation";
  readonly duplicateGoal?: {
    readonly __typename: "DuplicateGoalMutation";
    readonly duplicatedGoal?:
      | { readonly __typename: "ActionItemArtifactNode"; readonly id: number }
      | { readonly __typename: "AsanaTaskArtifactNode"; readonly id: number }
      | { readonly __typename: "DecisionArtifactNode"; readonly id: number }
      | { readonly __typename: "DocumentArtifactNode"; readonly id: number }
      | { readonly __typename: "FeedbackArtifactNode"; readonly id: number }
      | { readonly __typename: "GithubIssuesArtifactNode"; readonly id: number }
      | { readonly __typename: "GoalArtifactNode"; readonly id: number }
      | { readonly __typename: "KPIArtifactNode"; readonly id: number }
      | { readonly __typename: "RatingArtifactNode"; readonly id: number }
      | { readonly __typename: "RecognitionArtifactNode"; readonly id: number }
      | null;
  } | null;
};

export type GoalOverviewRelatedEntityGoalFragmentFragment = {
  readonly __typename: "GoalArtifactNode";
  readonly id: number;
  readonly title?: string | null;
  readonly scope: GoalScope;
  readonly state: GoalState;
  readonly status: GoalStatus;
  readonly progress: number;
  readonly isStale: boolean;
  readonly startValue: number;
  readonly targetValue: number;
  readonly progressType: GoalProgressType;
  readonly currentValue: number;
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly activities: {
    readonly __typename: "ArtifactActivityNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "ArtifactActivityNodeEdge";
      readonly node?: {
        readonly __typename: "ArtifactActivityNode";
        readonly id: number;
        readonly created: string;
      } | null;
    } | null>;
  };
};

export type GoalOverviewRelatedEntityUserFragmentFragment = {
  readonly __typename: "UserNode";
  readonly id: number;
  readonly name: string;
  readonly avatar?: string | null;
  readonly ownedGoals?: {
    readonly __typename: "ArtifactConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "ArtifactEdge";
      readonly node?:
        | { readonly __typename: "ActionItemArtifactNode"; readonly id: number }
        | { readonly __typename: "AsanaTaskArtifactNode"; readonly id: number }
        | { readonly __typename: "DecisionArtifactNode"; readonly id: number }
        | { readonly __typename: "DocumentArtifactNode"; readonly id: number }
        | { readonly __typename: "FeedbackArtifactNode"; readonly id: number }
        | {
            readonly __typename: "GithubIssuesArtifactNode";
            readonly id: number;
          }
        | {
            readonly __typename: "GoalArtifactNode";
            readonly id: number;
            readonly title?: string | null;
            readonly scope: GoalScope;
            readonly state: GoalState;
            readonly status: GoalStatus;
            readonly progress: number;
            readonly isStale: boolean;
            readonly startValue: number;
            readonly targetValue: number;
            readonly progressType: GoalProgressType;
            readonly currentValue: number;
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly activities: {
              readonly __typename: "ArtifactActivityNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "ArtifactActivityNodeEdge";
                readonly node?: {
                  readonly __typename: "ArtifactActivityNode";
                  readonly id: number;
                  readonly created: string;
                } | null;
              } | null>;
            };
          }
        | { readonly __typename: "KPIArtifactNode"; readonly id: number }
        | { readonly __typename: "RatingArtifactNode"; readonly id: number }
        | {
            readonly __typename: "RecognitionArtifactNode";
            readonly id: number;
          }
        | null;
    } | null>;
  } | null;
};

export type GoalOverviewRelatedEntityTeamFragmentFragment = {
  readonly __typename: "TeamNode";
  readonly id: number;
  readonly title: string;
  readonly teamGoals?: {
    readonly __typename: "ArtifactConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "ArtifactEdge";
      readonly node?:
        | { readonly __typename: "ActionItemArtifactNode"; readonly id: number }
        | { readonly __typename: "AsanaTaskArtifactNode"; readonly id: number }
        | { readonly __typename: "DecisionArtifactNode"; readonly id: number }
        | { readonly __typename: "DocumentArtifactNode"; readonly id: number }
        | { readonly __typename: "FeedbackArtifactNode"; readonly id: number }
        | {
            readonly __typename: "GithubIssuesArtifactNode";
            readonly id: number;
          }
        | {
            readonly __typename: "GoalArtifactNode";
            readonly id: number;
            readonly title?: string | null;
            readonly scope: GoalScope;
            readonly state: GoalState;
            readonly status: GoalStatus;
            readonly progress: number;
            readonly isStale: boolean;
            readonly startValue: number;
            readonly targetValue: number;
            readonly progressType: GoalProgressType;
            readonly currentValue: number;
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly activities: {
              readonly __typename: "ArtifactActivityNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "ArtifactActivityNodeEdge";
                readonly node?: {
                  readonly __typename: "ArtifactActivityNode";
                  readonly id: number;
                  readonly created: string;
                } | null;
              } | null>;
            };
          }
        | { readonly __typename: "KPIArtifactNode"; readonly id: number }
        | { readonly __typename: "RatingArtifactNode"; readonly id: number }
        | {
            readonly __typename: "RecognitionArtifactNode";
            readonly id: number;
          }
        | null;
    } | null>;
  } | null;
};

export type GetGoalOverviewRelatedEntitiesQueryQueryVariables = Exact<{
  teamId: Scalars["Int"]["input"];
  userId: Scalars["Int"]["input"];
  organizationId: Scalars["Int"]["input"];
  hasTeamId: Scalars["Boolean"]["input"];
  hasUserId: Scalars["Boolean"]["input"];
  hasOrganizationId: Scalars["Boolean"]["input"];
  goalDueBetweenDates:
    | ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
    | InputMaybe<Scalars["Date"]["input"]>;
}>;

export type GetGoalOverviewRelatedEntitiesQueryQuery = {
  readonly __typename: "Query";
  readonly user?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly directReports: {
      readonly __typename: "UserNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "UserNodeEdge";
        readonly node?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
          readonly ownedGoals?: {
            readonly __typename: "ArtifactConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "ArtifactEdge";
              readonly node?:
                | {
                    readonly __typename: "ActionItemArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "AsanaTaskArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "DecisionArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "DocumentArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "FeedbackArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "GithubIssuesArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "GoalArtifactNode";
                    readonly id: number;
                    readonly title?: string | null;
                    readonly scope: GoalScope;
                    readonly state: GoalState;
                    readonly status: GoalStatus;
                    readonly progress: number;
                    readonly isStale: boolean;
                    readonly startValue: number;
                    readonly targetValue: number;
                    readonly progressType: GoalProgressType;
                    readonly currentValue: number;
                    readonly canUpdate: {
                      readonly __typename: "PermissionNode";
                      readonly permission: boolean;
                    };
                    readonly activities: {
                      readonly __typename: "ArtifactActivityNodeConnection";
                      readonly edges: ReadonlyArray<{
                        readonly __typename: "ArtifactActivityNodeEdge";
                        readonly node?: {
                          readonly __typename: "ArtifactActivityNode";
                          readonly id: number;
                          readonly created: string;
                        } | null;
                      } | null>;
                    };
                  }
                | {
                    readonly __typename: "KPIArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "RatingArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "RecognitionArtifactNode";
                    readonly id: number;
                  }
                | null;
            } | null>;
          } | null;
        } | null;
      } | null>;
    };
    readonly managers: {
      readonly __typename: "UserNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "UserNodeEdge";
        readonly node?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
          readonly ownedGoals?: {
            readonly __typename: "ArtifactConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "ArtifactEdge";
              readonly node?:
                | {
                    readonly __typename: "ActionItemArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "AsanaTaskArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "DecisionArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "DocumentArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "FeedbackArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "GithubIssuesArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "GoalArtifactNode";
                    readonly id: number;
                    readonly title?: string | null;
                    readonly scope: GoalScope;
                    readonly state: GoalState;
                    readonly status: GoalStatus;
                    readonly progress: number;
                    readonly isStale: boolean;
                    readonly startValue: number;
                    readonly targetValue: number;
                    readonly progressType: GoalProgressType;
                    readonly currentValue: number;
                    readonly canUpdate: {
                      readonly __typename: "PermissionNode";
                      readonly permission: boolean;
                    };
                    readonly activities: {
                      readonly __typename: "ArtifactActivityNodeConnection";
                      readonly edges: ReadonlyArray<{
                        readonly __typename: "ArtifactActivityNodeEdge";
                        readonly node?: {
                          readonly __typename: "ArtifactActivityNode";
                          readonly id: number;
                          readonly created: string;
                        } | null;
                      } | null>;
                    };
                  }
                | {
                    readonly __typename: "KPIArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "RatingArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "RecognitionArtifactNode";
                    readonly id: number;
                  }
                | null;
            } | null>;
          } | null;
        } | null;
      } | null>;
    };
    readonly teams?: {
      readonly __typename: "TeamNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "TeamNodeEdge";
        readonly node?: {
          readonly __typename: "TeamNode";
          readonly id: number;
          readonly title: string;
          readonly teamGoals?: {
            readonly __typename: "ArtifactConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "ArtifactEdge";
              readonly node?:
                | {
                    readonly __typename: "ActionItemArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "AsanaTaskArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "DecisionArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "DocumentArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "FeedbackArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "GithubIssuesArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "GoalArtifactNode";
                    readonly id: number;
                    readonly title?: string | null;
                    readonly scope: GoalScope;
                    readonly state: GoalState;
                    readonly status: GoalStatus;
                    readonly progress: number;
                    readonly isStale: boolean;
                    readonly startValue: number;
                    readonly targetValue: number;
                    readonly progressType: GoalProgressType;
                    readonly currentValue: number;
                    readonly canUpdate: {
                      readonly __typename: "PermissionNode";
                      readonly permission: boolean;
                    };
                    readonly activities: {
                      readonly __typename: "ArtifactActivityNodeConnection";
                      readonly edges: ReadonlyArray<{
                        readonly __typename: "ArtifactActivityNodeEdge";
                        readonly node?: {
                          readonly __typename: "ArtifactActivityNode";
                          readonly id: number;
                          readonly created: string;
                        } | null;
                      } | null>;
                    };
                  }
                | {
                    readonly __typename: "KPIArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "RatingArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "RecognitionArtifactNode";
                    readonly id: number;
                  }
                | null;
            } | null>;
          } | null;
        } | null;
      } | null>;
    } | null;
  } | null;
  readonly team?: {
    readonly __typename: "TeamNode";
    readonly id: number;
    readonly members?: {
      readonly __typename: "UserNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "UserNodeEdge";
        readonly node?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
          readonly ownedGoals?: {
            readonly __typename: "ArtifactConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "ArtifactEdge";
              readonly node?:
                | {
                    readonly __typename: "ActionItemArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "AsanaTaskArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "DecisionArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "DocumentArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "FeedbackArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "GithubIssuesArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "GoalArtifactNode";
                    readonly id: number;
                    readonly title?: string | null;
                    readonly scope: GoalScope;
                    readonly state: GoalState;
                    readonly status: GoalStatus;
                    readonly progress: number;
                    readonly isStale: boolean;
                    readonly startValue: number;
                    readonly targetValue: number;
                    readonly progressType: GoalProgressType;
                    readonly currentValue: number;
                    readonly canUpdate: {
                      readonly __typename: "PermissionNode";
                      readonly permission: boolean;
                    };
                    readonly activities: {
                      readonly __typename: "ArtifactActivityNodeConnection";
                      readonly edges: ReadonlyArray<{
                        readonly __typename: "ArtifactActivityNodeEdge";
                        readonly node?: {
                          readonly __typename: "ArtifactActivityNode";
                          readonly id: number;
                          readonly created: string;
                        } | null;
                      } | null>;
                    };
                  }
                | {
                    readonly __typename: "KPIArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "RatingArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "RecognitionArtifactNode";
                    readonly id: number;
                  }
                | null;
            } | null>;
          } | null;
        } | null;
      } | null>;
    } | null;
  } | null;
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
    readonly teams?: {
      readonly __typename: "TeamNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "TeamNodeEdge";
        readonly node?: {
          readonly __typename: "TeamNode";
          readonly id: number;
          readonly title: string;
          readonly teamGoals?: {
            readonly __typename: "ArtifactConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "ArtifactEdge";
              readonly node?:
                | {
                    readonly __typename: "ActionItemArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "AsanaTaskArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "DecisionArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "DocumentArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "FeedbackArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "GithubIssuesArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "GoalArtifactNode";
                    readonly id: number;
                    readonly title?: string | null;
                    readonly scope: GoalScope;
                    readonly state: GoalState;
                    readonly status: GoalStatus;
                    readonly progress: number;
                    readonly isStale: boolean;
                    readonly startValue: number;
                    readonly targetValue: number;
                    readonly progressType: GoalProgressType;
                    readonly currentValue: number;
                    readonly canUpdate: {
                      readonly __typename: "PermissionNode";
                      readonly permission: boolean;
                    };
                    readonly activities: {
                      readonly __typename: "ArtifactActivityNodeConnection";
                      readonly edges: ReadonlyArray<{
                        readonly __typename: "ArtifactActivityNodeEdge";
                        readonly node?: {
                          readonly __typename: "ArtifactActivityNode";
                          readonly id: number;
                          readonly created: string;
                        } | null;
                      } | null>;
                    };
                  }
                | {
                    readonly __typename: "KPIArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "RatingArtifactNode";
                    readonly id: number;
                  }
                | {
                    readonly __typename: "RecognitionArtifactNode";
                    readonly id: number;
                  }
                | null;
            } | null>;
          } | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type GetGoalOverviewSelectedEntityQueryQueryVariables = Exact<{
  teamId: Scalars["Int"]["input"];
  userId: Scalars["Int"]["input"];
  organizationId: Scalars["Int"]["input"];
  hasTeamId: Scalars["Boolean"]["input"];
  hasUserId: Scalars["Boolean"]["input"];
  hasOrganizationId: Scalars["Boolean"]["input"];
}>;

export type GetGoalOverviewSelectedEntityQueryQuery = {
  readonly __typename: "Query";
  readonly user?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly email: string;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
  readonly team?: {
    readonly __typename: "TeamNode";
    readonly id: number;
    readonly title: string;
  } | null;
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
    readonly name: string;
  } | null;
};

export type GoalOverviewGoalOwnersFragment = {
  readonly __typename: "GoalArtifactNode";
  readonly ownerCount?: number | null;
  readonly firstOwner?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
  readonly owners?: {
    readonly __typename: "UserNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "UserNodeEdge";
      readonly node?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly avatar?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type GoalOverviewGoalFragmentFragment = {
  readonly __typename: "GoalArtifactNode";
  readonly id: number;
  readonly artifactType: ArtifactType;
  readonly title?: string | null;
  readonly created: string;
  readonly updated?: string | null;
  readonly state: GoalState;
  readonly scope: GoalScope;
  readonly dueDate?: string | null;
  readonly goalVisibility: GoalVisibility;
  readonly status: GoalStatus;
  readonly startValue: number;
  readonly currentValue: number;
  readonly targetValue: number;
  readonly progress: number;
  readonly progressType: GoalProgressType;
  readonly isStale: boolean;
  readonly ownerCount?: number | null;
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly creator?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
  readonly activities: {
    readonly __typename: "ArtifactActivityNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "ArtifactActivityNodeEdge";
      readonly node?: {
        readonly __typename: "ArtifactActivityNode";
        readonly created: string;
      } | null;
    } | null>;
  };
  readonly keyResults: {
    readonly __typename: "KeyResultNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "KeyResultNodeEdge";
      readonly node?: {
        readonly __typename: "KeyResultNode";
        readonly id: number;
        readonly title: string;
        readonly startValue: number;
        readonly currentValue: number;
        readonly targetValue: number;
        readonly progressType: GoalProgressType;
        readonly assignee?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly kpi?: {
          readonly __typename: "KPINode";
          readonly id: number;
          readonly title: string;
        } | null;
      } | null;
    } | null>;
  };
  readonly childGoals?: {
    readonly __typename: "GoalArtifactConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "GoalArtifactEdge";
      readonly node?: {
        readonly __typename: "GoalArtifactNode";
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly state: GoalState;
        readonly scope: GoalScope;
        readonly goalVisibility: GoalVisibility;
        readonly status: GoalStatus;
        readonly progress: number;
        readonly isStale: boolean;
        readonly startValue: number;
        readonly currentValue: number;
        readonly targetValue: number;
        readonly progressType: GoalProgressType;
        readonly id: number;
        readonly ownerCount?: number | null;
        readonly firstOwner?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly owners?: {
          readonly __typename: "UserNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "UserNodeEdge";
            readonly node?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  } | null;
  readonly firstOwner?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
  readonly owners?: {
    readonly __typename: "UserNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "UserNodeEdge";
      readonly node?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly avatar?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type GetOverviewGoalsQueryQueryVariables = Exact<{
  ownerIds?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  contributorIds?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  involvingUserIds?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  teamIds?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  goalScopes?: InputMaybe<
    ReadonlyArray<InputMaybe<GoalScope>> | InputMaybe<GoalScope>
  >;
  goalDueBetweenDates:
    | ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
    | InputMaybe<Scalars["Date"]["input"]>;
}>;

export type GetOverviewGoalsQueryQuery = {
  readonly __typename: "Query";
  readonly artifacts?: {
    readonly __typename: "ArtifactConnection";
    readonly totalCount: number;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly hasNextPage: boolean;
      readonly endCursor?: string | null;
    };
    readonly edges: ReadonlyArray<{
      readonly __typename: "ArtifactEdge";
      readonly node?:
        | { readonly __typename: "ActionItemArtifactNode"; readonly id: number }
        | { readonly __typename: "AsanaTaskArtifactNode"; readonly id: number }
        | { readonly __typename: "DecisionArtifactNode"; readonly id: number }
        | { readonly __typename: "DocumentArtifactNode"; readonly id: number }
        | { readonly __typename: "FeedbackArtifactNode"; readonly id: number }
        | {
            readonly __typename: "GithubIssuesArtifactNode";
            readonly id: number;
          }
        | {
            readonly __typename: "GoalArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly created: string;
            readonly updated?: string | null;
            readonly state: GoalState;
            readonly scope: GoalScope;
            readonly dueDate?: string | null;
            readonly goalVisibility: GoalVisibility;
            readonly status: GoalStatus;
            readonly startValue: number;
            readonly currentValue: number;
            readonly targetValue: number;
            readonly progress: number;
            readonly progressType: GoalProgressType;
            readonly isStale: boolean;
            readonly ownerCount?: number | null;
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly creator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
            readonly activities: {
              readonly __typename: "ArtifactActivityNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "ArtifactActivityNodeEdge";
                readonly node?: {
                  readonly __typename: "ArtifactActivityNode";
                  readonly created: string;
                } | null;
              } | null>;
            };
            readonly keyResults: {
              readonly __typename: "KeyResultNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "KeyResultNodeEdge";
                readonly node?: {
                  readonly __typename: "KeyResultNode";
                  readonly id: number;
                  readonly title: string;
                  readonly startValue: number;
                  readonly currentValue: number;
                  readonly targetValue: number;
                  readonly progressType: GoalProgressType;
                  readonly assignee?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                  readonly kpi?: {
                    readonly __typename: "KPINode";
                    readonly id: number;
                    readonly title: string;
                  } | null;
                } | null;
              } | null>;
            };
            readonly childGoals?: {
              readonly __typename: "GoalArtifactConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "GoalArtifactEdge";
                readonly node?: {
                  readonly __typename: "GoalArtifactNode";
                  readonly title?: string | null;
                  readonly artifactType: ArtifactType;
                  readonly state: GoalState;
                  readonly scope: GoalScope;
                  readonly goalVisibility: GoalVisibility;
                  readonly status: GoalStatus;
                  readonly progress: number;
                  readonly isStale: boolean;
                  readonly startValue: number;
                  readonly currentValue: number;
                  readonly targetValue: number;
                  readonly progressType: GoalProgressType;
                  readonly id: number;
                  readonly ownerCount?: number | null;
                  readonly firstOwner?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                  readonly owners?: {
                    readonly __typename: "UserNodeConnection";
                    readonly edges: ReadonlyArray<{
                      readonly __typename: "UserNodeEdge";
                      readonly node?: {
                        readonly __typename: "UserNode";
                        readonly id: number;
                        readonly name: string;
                        readonly avatar?: string | null;
                      } | null;
                    } | null>;
                  } | null;
                } | null;
              } | null>;
            } | null;
            readonly firstOwner?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
            readonly owners?: {
              readonly __typename: "UserNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "UserNodeEdge";
                readonly node?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
              } | null>;
            } | null;
          }
        | { readonly __typename: "KPIArtifactNode"; readonly id: number }
        | { readonly __typename: "RatingArtifactNode"; readonly id: number }
        | {
            readonly __typename: "RecognitionArtifactNode";
            readonly id: number;
          }
        | null;
    } | null>;
  } | null;
};

export type GetLastCheckinActivityQueryQueryVariables = Exact<{
  artifactId: Scalars["Int"]["input"];
}>;

export type GetLastCheckinActivityQueryQuery = {
  readonly __typename: "Query";
  readonly artifact:
    | { readonly __typename: "ActionItemArtifactNode"; readonly id: number }
    | { readonly __typename: "AsanaTaskArtifactNode"; readonly id: number }
    | { readonly __typename: "DecisionArtifactNode"; readonly id: number }
    | { readonly __typename: "DocumentArtifactNode"; readonly id: number }
    | { readonly __typename: "FeedbackArtifactNode"; readonly id: number }
    | { readonly __typename: "GithubIssuesArtifactNode"; readonly id: number }
    | {
        readonly __typename: "GoalArtifactNode";
        readonly currentValue: number;
        readonly targetValue: number;
        readonly progress: number;
        readonly startValue: number;
        readonly id: number;
        readonly keyResults: {
          readonly __typename: "KeyResultNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "KeyResultNodeEdge";
            readonly node?: {
              readonly __typename: "KeyResultNode";
              readonly id: number;
              readonly title: string;
              readonly startValue: number;
              readonly currentValue: number;
              readonly targetValue: number;
              readonly progressType: GoalProgressType;
              readonly kpi?: {
                readonly __typename: "KPINode";
                readonly id: number;
                readonly title: string;
                readonly currentMeasurement?: {
                  readonly __typename: "KPIMeasurementNode";
                  readonly id: number;
                  readonly measurement: number;
                } | null;
              } | null;
              readonly canUpdate?: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              } | null;
              readonly assignee?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
                readonly email: string;
              } | null;
            } | null;
          } | null>;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly activities: {
          readonly __typename: "ArtifactActivityNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactActivityNodeEdge";
            readonly node?: {
              readonly __typename: "ArtifactActivityNode";
              readonly id: number;
              readonly created: string;
              readonly activityType: ArtifactActivityType;
              readonly canDelete?: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
                readonly reason: string;
              } | null;
              readonly canUpdate?: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
                readonly reason: string;
              } | null;
              readonly actor?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
              readonly changedFields?: ReadonlyArray<{
                readonly __typename: "ArtifactChangedFieldNode";
                readonly fieldName?: string | null;
                readonly previousValue?: string | null;
                readonly newValue?: string | null;
                readonly changedFields?: ReadonlyArray<{
                  readonly __typename: "ArtifactChangedFieldNode";
                  readonly newValue?: string | null;
                  readonly previousValue?: string | null;
                  readonly fieldName?: string | null;
                } | null> | null;
                readonly newValueObject?:
                  | {
                      readonly __typename: "BaseArtifactNode";
                      readonly id: number;
                      readonly title: string;
                      readonly artifactType: ArtifactType;
                    }
                  | {
                      readonly __typename: "CommentNode";
                      readonly id: number;
                      readonly comment?: any | null;
                      readonly deleted?: boolean | null;
                      readonly creator?: {
                        readonly __typename: "UserNode";
                        readonly id: number;
                        readonly name: string;
                        readonly avatar?: string | null;
                      } | null;
                    }
                  | {
                      readonly __typename: "KeyResultNode";
                      readonly id: number;
                      readonly title: string;
                    }
                  | {
                      readonly __typename: "TopicNode";
                      readonly id: number;
                      readonly title: string;
                      readonly meeting?: {
                        readonly __typename: "MeetingNode";
                        readonly id: number;
                        readonly title?: string | null;
                        readonly meetingGroupId: number;
                      } | null;
                    }
                  | {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    }
                  | null;
                readonly previousValueObject?:
                  | {
                      readonly __typename: "BaseArtifactNode";
                      readonly id: number;
                      readonly title: string;
                      readonly artifactType: ArtifactType;
                    }
                  | { readonly __typename: "CommentNode" }
                  | {
                      readonly __typename: "KeyResultNode";
                      readonly id: number;
                      readonly title: string;
                    }
                  | {
                      readonly __typename: "TopicNode";
                      readonly id: number;
                      readonly title: string;
                      readonly meeting?: {
                        readonly __typename: "MeetingNode";
                        readonly id: number;
                        readonly title?: string | null;
                        readonly meetingGroupId: number;
                      } | null;
                    }
                  | {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    }
                  | null;
              } | null> | null;
            } | null;
          } | null>;
        };
      }
    | { readonly __typename: "KPIArtifactNode"; readonly id: number }
    | { readonly __typename: "RatingArtifactNode"; readonly id: number }
    | { readonly __typename: "RecognitionArtifactNode"; readonly id: number };
};

export type InviteUsersMutationVariables = Exact<{
  invitationRequests:
    | ReadonlyArray<InputMaybe<InvitationInput>>
    | InputMaybe<InvitationInput>;
}>;

export type InviteUsersMutation = {
  readonly __typename: "Mutation";
  readonly createInvitations?: {
    readonly __typename: "CreateInvitationsMutation";
    readonly invitations?: ReadonlyArray<{
      readonly __typename: "InvitationNode";
      readonly id: number;
      readonly inviteKey: string;
      readonly inviteeEmail: string;
    } | null> | null;
    readonly invitationErrors?: ReadonlyArray<{
      readonly __typename: "InviteError";
      readonly inviteeEmail?: string | null;
      readonly message?: string | null;
    } | null> | null;
  } | null;
};

export type CreateOrUpdateKpiGroupMutationMutationVariables = Exact<{
  title?: InputMaybe<Scalars["String"]["input"]>;
  kpiGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type CreateOrUpdateKpiGroupMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateKpiGroup?: {
    readonly __typename: "CreateOrUpdateKPIGroupMutation";
    readonly kpiGroup?: {
      readonly __typename: "KPIGroupNode";
      readonly id: number;
      readonly title: string;
    } | null;
  } | null;
};

export type CreateOrUpdateKpiMeasurementMutationMutationVariables = Exact<{
  kpiMeasurementId?: InputMaybe<Scalars["Int"]["input"]>;
  kpiId?: InputMaybe<Scalars["Int"]["input"]>;
  measurement: Scalars["Float"]["input"];
  timestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
}>;

export type CreateOrUpdateKpiMeasurementMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateKpiMeasurement?: {
    readonly __typename: "CreateOrUpdateKPIMeasurementMutation";
    readonly kpi?: {
      readonly __typename: "KPINode";
      readonly id: number;
    } | null;
    readonly kpiMeasurement?: {
      readonly __typename: "KPIMeasurementNode";
      readonly id: number;
      readonly measurement: number;
      readonly timestamp: string;
    } | null;
  } | null;
};

export type CreateOrUpdateKpiMutationMutationVariables = Exact<{
  title?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  visibility?: InputMaybe<KpiVisibility>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  kpiId?: InputMaybe<Scalars["Int"]["input"]>;
  integration?: InputMaybe<KpiIntegrationInput>;
  defaultSummaryPeriod?: InputMaybe<KpiSummaryPeriod>;
  defaultSummaryMethod?: InputMaybe<KpiSummaryMethod>;
  targetType?: InputMaybe<KpiTargetType>;
  targetValue?: InputMaybe<Scalars["Float"]["input"]>;
  targetCondition?: InputMaybe<KpiTargetCondition>;
  groupIds?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  teamIds?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
}>;

export type CreateOrUpdateKpiMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateKpi?: {
    readonly __typename: "CreateOrUpdateKPI";
    readonly kpi?: {
      readonly __typename: "KPINode";
      readonly id: number;
      readonly title: string;
      readonly description?: any | null;
      readonly visibility: KpiVisibility;
      readonly defaultSummaryMethod: KpiSummaryMethod;
      readonly defaultSummaryPeriod: KpiSummaryPeriod;
      readonly targetType: KpiTargetType;
      readonly targetValue?: number | null;
      readonly targetCondition: KpiTargetCondition;
      readonly groups?: {
        readonly __typename: "KPIGroupNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "KPIGroupNodeEdge";
          readonly node?: {
            readonly __typename: "KPIGroupNode";
            readonly id: number;
            readonly title: string;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type DeleteKpiMeasurementMutationMutationVariables = Exact<{
  measurementId: Scalars["Int"]["input"];
}>;

export type DeleteKpiMeasurementMutationMutation = {
  readonly __typename: "Mutation";
  readonly deleteKpiMeasurement?: {
    readonly __typename: "DeleteKPIMeasurement";
    readonly ok?: boolean | null;
  } | null;
};

export type DeleteKpiMutationMutationVariables = Exact<{
  kpiId: Scalars["Int"]["input"];
}>;

export type DeleteKpiMutationMutation = {
  readonly __typename: "Mutation";
  readonly deleteKpi?: {
    readonly __typename: "DeleteKPI";
    readonly ok?: boolean | null;
  } | null;
};

export type GetKpiGoalsQueryQueryVariables = Exact<{
  kpiId: Scalars["Int"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetKpiGoalsQueryQuery = {
  readonly __typename: "Query";
  readonly kpi?: {
    readonly __typename: "KPINode";
    readonly id: number;
    readonly linkedKeyResults?: {
      readonly __typename: "KeyResultNodeConnection";
      readonly totalCount: number;
      readonly pageInfo: {
        readonly __typename: "PageInfo";
        readonly endCursor?: string | null;
        readonly hasNextPage: boolean;
      };
      readonly edges: ReadonlyArray<{
        readonly __typename: "KeyResultNodeEdge";
        readonly node?: {
          readonly __typename: "KeyResultNode";
          readonly id: number;
          readonly goal?: {
            readonly __typename: "GoalArtifactNode";
            readonly id: number;
            readonly title?: string | null;
            readonly created: string;
            readonly artifactType: ArtifactType;
            readonly state: GoalState;
            readonly status: GoalStatus;
            readonly scope: GoalScope;
            readonly goalVisibility: GoalVisibility;
            readonly dueDate?: string | null;
            readonly startDate?: string | null;
            readonly progress: number;
            readonly progressType: GoalProgressType;
            readonly startValue: number;
            readonly currentValue: number;
            readonly targetValue: number;
            readonly isStale: boolean;
            readonly ownerCount?: number | null;
            readonly contributorCount?: number | null;
            readonly activities: {
              readonly __typename: "ArtifactActivityNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "ArtifactActivityNodeEdge";
                readonly node?: {
                  readonly __typename: "ArtifactActivityNode";
                  readonly created: string;
                } | null;
              } | null>;
            };
            readonly firstOwner?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly avatar?: string | null;
              readonly name: string;
            } | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canRead: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          } | null;
        } | null;
      } | null>;
    } | null;
    readonly linkedGoals?: {
      readonly __typename: "GoalArtifactConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "GoalArtifactEdge";
        readonly node?: {
          readonly __typename: "GoalArtifactNode";
          readonly id: number;
          readonly title?: string | null;
          readonly created: string;
          readonly artifactType: ArtifactType;
          readonly state: GoalState;
          readonly status: GoalStatus;
          readonly scope: GoalScope;
          readonly goalVisibility: GoalVisibility;
          readonly dueDate?: string | null;
          readonly startDate?: string | null;
          readonly progress: number;
          readonly progressType: GoalProgressType;
          readonly startValue: number;
          readonly currentValue: number;
          readonly targetValue: number;
          readonly isStale: boolean;
          readonly ownerCount?: number | null;
          readonly contributorCount?: number | null;
          readonly activities: {
            readonly __typename: "ArtifactActivityNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "ArtifactActivityNodeEdge";
              readonly node?: {
                readonly __typename: "ArtifactActivityNode";
                readonly created: string;
              } | null;
            } | null>;
          };
          readonly firstOwner?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly avatar?: string | null;
            readonly name: string;
          } | null;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canUpdate: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canRead: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type GetKpiGroupQueryQueryVariables = Exact<{
  kpiGroupId: Scalars["Int"]["input"];
}>;

export type GetKpiGroupQueryQuery = {
  readonly __typename: "Query";
  readonly kpiGroup?: {
    readonly __typename: "KPIGroupNode";
    readonly id: number;
    readonly title: string;
    readonly canDelete?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
      readonly reason: string;
    } | null;
    readonly canUpdate?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
      readonly reason: string;
    } | null;
  } | null;
};

export type KpiMeasurementTableFragment = {
  readonly __typename: "KPIMeasurementNode";
  readonly id: number;
  readonly measurement: number;
  readonly created: string;
  readonly timestamp: string;
  readonly canUpdate?: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
    readonly reason: string;
  } | null;
  readonly canDelete?: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
    readonly reason: string;
  } | null;
};

export type GetKpiMeasurementsQueryQueryVariables = Exact<{
  kpiId: Scalars["Int"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetKpiMeasurementsQueryQuery = {
  readonly __typename: "Query";
  readonly kpi?: {
    readonly __typename: "KPINode";
    readonly id: number;
    readonly measurements?: {
      readonly __typename: "KPIMeasurementNodeConnection";
      readonly pageInfo: {
        readonly __typename: "PageInfo";
        readonly hasNextPage: boolean;
        readonly endCursor?: string | null;
      };
      readonly edges: ReadonlyArray<{
        readonly __typename: "KPIMeasurementNodeEdge";
        readonly node?: {
          readonly __typename: "KPIMeasurementNode";
          readonly id: number;
          readonly measurement: number;
          readonly created: string;
          readonly timestamp: string;
          readonly canUpdate?: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
            readonly reason: string;
          } | null;
          readonly canDelete?: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
            readonly reason: string;
          } | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type GetKpiQueryQueryVariables = Exact<{
  kpiId: Scalars["Int"]["input"];
  hasKpi: Scalars["Boolean"]["input"];
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  hasOrganizationId: Scalars["Boolean"]["input"];
}>;

export type GetKpiQueryQuery = {
  readonly __typename: "Query";
  readonly kpiGroups?: {
    readonly __typename: "KPIGroupNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "KPIGroupNodeEdge";
      readonly node?: {
        readonly __typename: "KPIGroupNode";
        readonly id: number;
        readonly title: string;
      } | null;
    } | null>;
  } | null;
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
    readonly integrations: {
      readonly __typename: "OrganizationIntegrationNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "OrganizationIntegrationNodeEdge";
        readonly node?: {
          readonly __typename: "OrganizationIntegrationNode";
          readonly id: number;
          readonly integrationType: IntegrationTypes;
          readonly socialAuth: {
            readonly __typename: "UserSocialAuthNode";
            readonly id: number;
            readonly extraData: any;
          };
        } | null;
      } | null>;
    };
  } | null;
  readonly kpi?: {
    readonly __typename: "KPINode";
    readonly created: string;
    readonly description?: any | null;
    readonly id: number;
    readonly title: string;
    readonly visibility: KpiVisibility;
    readonly defaultSummaryPeriod: KpiSummaryPeriod;
    readonly defaultSummaryMethod: KpiSummaryMethod;
    readonly targetType: KpiTargetType;
    readonly targetValue?: number | null;
    readonly targetCondition: KpiTargetCondition;
    readonly teams?: {
      readonly __typename: "TeamNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "TeamNodeEdge";
        readonly node?: {
          readonly __typename: "TeamNode";
          readonly id: number;
          readonly title: string;
        } | null;
      } | null>;
    } | null;
    readonly organization?: {
      readonly __typename: "OrganizationNode";
      readonly id: number;
      readonly name: string;
    } | null;
    readonly currentMeasurement?: {
      readonly __typename: "KPIMeasurementNode";
      readonly id: number;
      readonly measurement: number;
    } | null;
    readonly canDelete?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
      readonly reason: string;
    } | null;
    readonly canUpdate: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
      readonly reason: string;
    };
    readonly groups?: {
      readonly __typename: "KPIGroupNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "KPIGroupNodeEdge";
        readonly node?: {
          readonly __typename: "KPIGroupNode";
          readonly id: number;
          readonly title: string;
        } | null;
      } | null>;
    } | null;
    readonly integration?:
      | {
          readonly __typename: "GithubKPIIntegrationNode";
          readonly owner: string;
          readonly repository: string;
          readonly id: number;
          readonly integrationType?: KpiIntegrationType | null;
          readonly ghMetricType?: GithubIntegrationMetricType | null;
          readonly ghTimeBucket?: GithubIntegrationTimeBucket | null;
          readonly organizationIntegrationConnection?: {
            readonly __typename: "OrganizationIntegrationNode";
            readonly id: number;
          } | null;
        }
      | {
          readonly __typename: "GoogleSheetsKPIIntegrationNode";
          readonly spreadsheetId: string;
          readonly cellId?: string | null;
          readonly bottomRowFromColumn?: string | null;
          readonly rightmostColumnFromRow?: string | null;
          readonly id: number;
          readonly integrationType?: KpiIntegrationType | null;
          readonly organizationIntegrationConnection?: {
            readonly __typename: "OrganizationIntegrationNode";
            readonly id: number;
          } | null;
        }
      | {
          readonly __typename: "HubspotKPIIntegrationNode";
          readonly dealStageId?: string | null;
          readonly id: number;
          readonly integrationType?: KpiIntegrationType | null;
          readonly hubspotMetricType?: HubspotIntegrationMetricType | null;
          readonly hubspotTimeBucket?: HubspotIntegrationTimeBucket | null;
          readonly organizationIntegrationConnection?: {
            readonly __typename: "OrganizationIntegrationNode";
            readonly id: number;
          } | null;
        }
      | {
          readonly __typename: "SalesforceKPIIntegrationNode";
          readonly soqlQuery: string;
          readonly kpiFieldName: string;
          readonly id: number;
          readonly integrationType?: KpiIntegrationType | null;
          readonly organizationIntegrationConnection?: {
            readonly __typename: "OrganizationIntegrationNode";
            readonly id: number;
          } | null;
        }
      | {
          readonly __typename: "TopicflowKPIIntegrationNode";
          readonly metricType?: TopicflowIntegrationMetricType | null;
          readonly timeBucket?: TopicflowIntegrationTimeBucket | null;
          readonly value?: TopicflowIntegrationValue | null;
          readonly id: number;
          readonly integrationType?: KpiIntegrationType | null;
          readonly user?: {
            readonly __typename: "UserNode";
            readonly id: number;
          } | null;
          readonly organizationIntegrationConnection?: {
            readonly __typename: "OrganizationIntegrationNode";
            readonly id: number;
          } | null;
        }
      | null;
    readonly creator?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly avatar?: string | null;
      readonly name: string;
    } | null;
  } | null;
};

export type GetKpiSummaryQueryQueryVariables = Exact<{
  kpiId: Scalars["Int"]["input"];
  summaryPeriod?: InputMaybe<KpiSummaryPeriod>;
  summaryMethod?: InputMaybe<KpiSummaryMethod>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetKpiSummaryQueryQuery = {
  readonly __typename: "Query";
  readonly kpi?: {
    readonly __typename: "KPINode";
    readonly id: number;
    readonly title: string;
    readonly visibility: KpiVisibility;
    readonly defaultSummaryPeriod: KpiSummaryPeriod;
    readonly defaultSummaryMethod: KpiSummaryMethod;
    readonly targetType: KpiTargetType;
    readonly targetValue?: number | null;
    readonly targetCondition: KpiTargetCondition;
    readonly summarizedMeasurements: {
      readonly __typename: "SummarizedMeasurementsConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "SummarizedMeasurementsEdge";
        readonly node?: {
          readonly __typename: "SummarizedMeasurements";
          readonly aggregateMeasurementId?: string | null;
          readonly aggregateMeasurement?: number | null;
          readonly date?: string | null;
        } | null;
      } | null>;
    };
    readonly canUpdate: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
      readonly reason: string;
    };
    readonly creator?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly avatar?: string | null;
      readonly name: string;
    } | null;
    readonly organization?: {
      readonly __typename: "OrganizationNode";
      readonly id: number;
      readonly name: string;
    } | null;
    readonly teams?: {
      readonly __typename: "TeamNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "TeamNodeEdge";
        readonly node?: {
          readonly __typename: "TeamNode";
          readonly id: number;
          readonly title: string;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type GetKpiUpdateUrlQueryQueryVariables = Exact<{
  kpiId: Scalars["Int"]["input"];
}>;

export type GetKpiUpdateUrlQueryQuery = {
  readonly __typename: "Query";
  readonly kpi?: {
    readonly __typename: "KPINode";
    readonly id: number;
    readonly updateUrl: string;
    readonly currentMeasurement?: {
      readonly __typename: "KPIMeasurementNode";
      readonly measurement: number;
    } | null;
  } | null;
};

export type KpiDashboardKpiFragmentFragment = {
  readonly __typename: "KPINode";
  readonly id: number;
  readonly title: string;
  readonly visibility: KpiVisibility;
  readonly defaultSummaryPeriod: KpiSummaryPeriod;
  readonly defaultSummaryMethod: KpiSummaryMethod;
  readonly targetType: KpiTargetType;
  readonly targetValue?: number | null;
  readonly targetCondition: KpiTargetCondition;
  readonly groups?: {
    readonly __typename: "KPIGroupNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "KPIGroupNodeEdge";
      readonly node?: {
        readonly __typename: "KPIGroupNode";
        readonly id: number;
        readonly title: string;
      } | null;
    } | null>;
  } | null;
  readonly summarizedMeasurements: {
    readonly __typename: "SummarizedMeasurementsConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "SummarizedMeasurementsEdge";
      readonly node?: {
        readonly __typename: "SummarizedMeasurements";
        readonly aggregateMeasurementId?: string | null;
        readonly aggregateMeasurement?: number | null;
        readonly date?: string | null;
      } | null;
    } | null>;
  };
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
    readonly reason: string;
  };
  readonly creator?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly avatar?: string | null;
    readonly name: string;
  } | null;
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
    readonly name: string;
  } | null;
  readonly teams?: {
    readonly __typename: "TeamNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "TeamNodeEdge";
      readonly node?: {
        readonly __typename: "TeamNode";
        readonly id: number;
        readonly title: string;
      } | null;
    } | null>;
  } | null;
};

export type GetKpisQueryQueryVariables = Exact<{
  first: Scalars["Int"]["input"];
  kpiSummaryPeriod: KpiSummaryPeriod;
}>;

export type GetKpisQueryQuery = {
  readonly __typename: "Query";
  readonly kpis?: {
    readonly __typename: "KPINodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "KPINodeEdge";
      readonly node?: {
        readonly __typename: "KPINode";
        readonly id: number;
        readonly title: string;
        readonly visibility: KpiVisibility;
        readonly defaultSummaryPeriod: KpiSummaryPeriod;
        readonly defaultSummaryMethod: KpiSummaryMethod;
        readonly targetType: KpiTargetType;
        readonly targetValue?: number | null;
        readonly targetCondition: KpiTargetCondition;
        readonly groups?: {
          readonly __typename: "KPIGroupNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "KPIGroupNodeEdge";
            readonly node?: {
              readonly __typename: "KPIGroupNode";
              readonly id: number;
              readonly title: string;
            } | null;
          } | null>;
        } | null;
        readonly summarizedMeasurements: {
          readonly __typename: "SummarizedMeasurementsConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "SummarizedMeasurementsEdge";
            readonly node?: {
              readonly __typename: "SummarizedMeasurements";
              readonly aggregateMeasurementId?: string | null;
              readonly aggregateMeasurement?: number | null;
              readonly date?: string | null;
            } | null;
          } | null>;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
          readonly reason: string;
        };
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly avatar?: string | null;
          readonly name: string;
        } | null;
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
          readonly name: string;
        } | null;
        readonly teams?: {
          readonly __typename: "TeamNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "TeamNodeEdge";
            readonly node?: {
              readonly __typename: "TeamNode";
              readonly id: number;
              readonly title: string;
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  } | null;
  readonly kpiGroups?: {
    readonly __typename: "KPIGroupNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "KPIGroupNodeEdge";
      readonly node?: {
        readonly __typename: "KPIGroupNode";
        readonly id: number;
        readonly title: string;
      } | null;
    } | null>;
  } | null;
};

export type KpiFragmentFragment = {
  readonly __typename: "KPINode";
  readonly id: number;
  readonly title: string;
  readonly visibility: KpiVisibility;
  readonly defaultSummaryPeriod: KpiSummaryPeriod;
  readonly defaultSummaryMethod: KpiSummaryMethod;
  readonly targetType: KpiTargetType;
  readonly targetValue?: number | null;
  readonly targetCondition: KpiTargetCondition;
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
    readonly reason: string;
  };
  readonly creator?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly avatar?: string | null;
    readonly name: string;
  } | null;
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
    readonly name: string;
  } | null;
  readonly teams?: {
    readonly __typename: "TeamNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "TeamNodeEdge";
      readonly node?: {
        readonly __typename: "TeamNode";
        readonly id: number;
        readonly title: string;
      } | null;
    } | null>;
  } | null;
};

export type KpiSummarizedMeasurementFragmentFragment = {
  readonly __typename: "SummarizedMeasurements";
  readonly aggregateMeasurementId?: string | null;
  readonly aggregateMeasurement?: number | null;
  readonly date?: string | null;
};

export type ExplorerBookmarkFragmentFragment = {
  readonly __typename: "ExplorerFilterBookmarkNode";
  readonly id: number;
  readonly title: string;
  readonly filters: string;
  readonly private: boolean;
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
};

type FavouriteArtifactFragment_ActionItemArtifactNode_Fragment = {
  readonly __typename: "ActionItemArtifactNode";
  readonly id: number;
};

type FavouriteArtifactFragment_AsanaTaskArtifactNode_Fragment = {
  readonly __typename: "AsanaTaskArtifactNode";
  readonly id: number;
};

type FavouriteArtifactFragment_DecisionArtifactNode_Fragment = {
  readonly __typename: "DecisionArtifactNode";
  readonly id: number;
};

type FavouriteArtifactFragment_DocumentArtifactNode_Fragment = {
  readonly __typename: "DocumentArtifactNode";
  readonly id: number;
};

type FavouriteArtifactFragment_FeedbackArtifactNode_Fragment = {
  readonly __typename: "FeedbackArtifactNode";
  readonly id: number;
};

type FavouriteArtifactFragment_GithubIssuesArtifactNode_Fragment = {
  readonly __typename: "GithubIssuesArtifactNode";
  readonly id: number;
};

type FavouriteArtifactFragment_GoalArtifactNode_Fragment = {
  readonly __typename: "GoalArtifactNode";
  readonly id: number;
};

type FavouriteArtifactFragment_KpiArtifactNode_Fragment = {
  readonly __typename: "KPIArtifactNode";
  readonly id: number;
};

type FavouriteArtifactFragment_RatingArtifactNode_Fragment = {
  readonly __typename: "RatingArtifactNode";
  readonly id: number;
};

type FavouriteArtifactFragment_RecognitionArtifactNode_Fragment = {
  readonly __typename: "RecognitionArtifactNode";
  readonly id: number;
};

export type FavouriteArtifactFragmentFragment =
  | FavouriteArtifactFragment_ActionItemArtifactNode_Fragment
  | FavouriteArtifactFragment_AsanaTaskArtifactNode_Fragment
  | FavouriteArtifactFragment_DecisionArtifactNode_Fragment
  | FavouriteArtifactFragment_DocumentArtifactNode_Fragment
  | FavouriteArtifactFragment_FeedbackArtifactNode_Fragment
  | FavouriteArtifactFragment_GithubIssuesArtifactNode_Fragment
  | FavouriteArtifactFragment_GoalArtifactNode_Fragment
  | FavouriteArtifactFragment_KpiArtifactNode_Fragment
  | FavouriteArtifactFragment_RatingArtifactNode_Fragment
  | FavouriteArtifactFragment_RecognitionArtifactNode_Fragment;

export type LoggedInUserOrgFragment = {
  readonly __typename: "OrganizationNode";
  readonly id: number;
  readonly name: string;
  readonly topicChannelName?: string | null;
  readonly personal?: boolean | null;
  readonly goalLabel: string;
  readonly keyResultLabel: string;
  readonly teamLabel: string;
  readonly orgLabel: string;
  readonly recognitionLabel: string;
  readonly oneononeLabel: string;
  readonly reviewLabel: string;
  readonly expectationLabel: string;
  readonly conversationLabel: string;
  readonly developmentLabel: string;
  readonly competencyLabel: string;
  readonly daysLeftOnTrial?: number | null;
  readonly pricingTier?: PricingTier | null;
  readonly quarterStartMonth: number;
  readonly enableMeetingSummarization: boolean;
  readonly effectivePricingTier?: PricingTier | null;
  readonly defaultGoalState?: GoalState | null;
  readonly defaultDecisionState?: DecisionState | null;
  readonly teams?: {
    readonly __typename: "TeamNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "TeamNodeEdge";
      readonly node?: {
        readonly __typename: "TeamNode";
        readonly id: number;
        readonly title: string;
      } | null;
    } | null>;
  } | null;
  readonly userMembership?: {
    readonly __typename: "UserMemberNode";
    readonly id: number;
    readonly role?: OrganizationRole | null;
  } | null;
  readonly actionItemStates: ReadonlyArray<{
    readonly __typename: "ActionItemState";
    readonly value: number;
    readonly label: string;
    readonly isComplete: boolean;
    readonly isDefaultIncomplete: boolean;
  } | null>;
  readonly coreValues?: {
    readonly __typename: "RecognitionCoreValueNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "RecognitionCoreValueNodeEdge";
      readonly node?: {
        readonly __typename: "RecognitionCoreValueNode";
        readonly id: number;
        readonly title: string;
        readonly description: string;
        readonly emoji?: string | null;
        readonly image?: string | null;
      } | null;
    } | null>;
  } | null;
  readonly featureFlags: {
    readonly __typename: "OrganizationFeatureFlags";
    readonly actionItems?: boolean | null;
    readonly feedbacks?: boolean | null;
    readonly decisions?: boolean | null;
    readonly goals?: boolean | null;
    readonly documents?: boolean | null;
    readonly recognitions?: boolean | null;
    readonly kpis?: boolean | null;
    readonly explorer?: boolean | null;
    readonly homepageMyConnections?: boolean | null;
  };
};

export type GetLoggedInUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetLoggedInUserQuery = {
  readonly __typename: "Query";
  readonly loggedIn?: boolean | null;
  readonly me?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly email: string;
    readonly emails: ReadonlyArray<string>;
    readonly name: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly status?: UserStatus | null;
    readonly avatar?: string | null;
    readonly hasAsanaAuth?: boolean | null;
    readonly hasHubspotAuth?: boolean | null;
    readonly hasGithubAuth?: boolean | null;
    readonly hasJiraAuth?: boolean | null;
    readonly hasZoomAuth?: boolean | null;
    readonly hasGoogleSheetsAuth?: boolean | null;
    readonly hasSalesforceAuth?: boolean | null;
    readonly eventLinkInDescriptionPreference?: EventDescriptionLinkSetting | null;
    readonly timezone?: string | null;
    readonly pusherChannelName?: string | null;
    readonly hasSyncedCalendar?: boolean | null;
    readonly uiPreferenceCache?: any | null;
    readonly onlyUserInOrg?: boolean | null;
    readonly calendarIsSyncingForFirstTime?: boolean | null;
    readonly hasCalendarSyncError?: boolean | null;
    readonly hasOrgWithTooManyUsers?: boolean | null;
    readonly explorerBookmarks?: {
      readonly __typename: "ExplorerFilterBookmarkNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "ExplorerFilterBookmarkNodeEdge";
        readonly node?: {
          readonly __typename: "ExplorerFilterBookmarkNode";
          readonly id: number;
          readonly title: string;
          readonly filters: string;
          readonly private: boolean;
          readonly canUpdate: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
        } | null;
      } | null>;
    } | null;
    readonly favouriteArtifacts: {
      readonly __typename: "ArtifactConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "ArtifactEdge";
        readonly node?:
          | {
              readonly __typename: "ActionItemArtifactNode";
              readonly id: number;
            }
          | {
              readonly __typename: "AsanaTaskArtifactNode";
              readonly id: number;
            }
          | { readonly __typename: "DecisionArtifactNode"; readonly id: number }
          | { readonly __typename: "DocumentArtifactNode"; readonly id: number }
          | { readonly __typename: "FeedbackArtifactNode"; readonly id: number }
          | {
              readonly __typename: "GithubIssuesArtifactNode";
              readonly id: number;
            }
          | { readonly __typename: "GoalArtifactNode"; readonly id: number }
          | { readonly __typename: "KPIArtifactNode"; readonly id: number }
          | { readonly __typename: "RatingArtifactNode"; readonly id: number }
          | {
              readonly __typename: "RecognitionArtifactNode";
              readonly id: number;
            }
          | null;
      } | null>;
    };
    readonly favouritesGroups?: {
      readonly __typename: "FavouritesGroupNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "FavouritesGroupNodeEdge";
        readonly node?: {
          readonly __typename: "FavouritesGroupNode";
          readonly id: number;
          readonly users?: {
            readonly __typename: "UserNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "UserNodeEdge";
              readonly node?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly email: string;
                readonly avatar?: string | null;
              } | null;
            } | null>;
          } | null;
        } | null;
      } | null>;
    } | null;
    readonly syncCredentials?: {
      readonly __typename: "UserSyncCredentialsNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "UserSyncCredentialsNodeEdge";
        readonly node?: {
          readonly __typename: "UserSyncCredentialsNode";
          readonly id: number;
          readonly provider?: SyncCredentialsTypeEnum | null;
          readonly credentialsUid?: string | null;
          readonly hasValidCalendarCredentials?: boolean | null;
          readonly hasCalendarWriteCredentials?: boolean | null;
          readonly organization?: {
            readonly __typename: "OrganizationNode";
            readonly id: number;
            readonly domains?: ReadonlyArray<string | null> | null;
          } | null;
        } | null;
      } | null>;
    } | null;
    readonly organizations: {
      readonly __typename: "OrganizationNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "OrganizationNodeEdge";
        readonly node?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
          readonly name: string;
          readonly topicChannelName?: string | null;
          readonly personal?: boolean | null;
          readonly goalLabel: string;
          readonly keyResultLabel: string;
          readonly teamLabel: string;
          readonly orgLabel: string;
          readonly recognitionLabel: string;
          readonly oneononeLabel: string;
          readonly reviewLabel: string;
          readonly expectationLabel: string;
          readonly conversationLabel: string;
          readonly developmentLabel: string;
          readonly competencyLabel: string;
          readonly daysLeftOnTrial?: number | null;
          readonly pricingTier?: PricingTier | null;
          readonly quarterStartMonth: number;
          readonly enableMeetingSummarization: boolean;
          readonly effectivePricingTier?: PricingTier | null;
          readonly defaultGoalState?: GoalState | null;
          readonly defaultDecisionState?: DecisionState | null;
          readonly teams?: {
            readonly __typename: "TeamNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "TeamNodeEdge";
              readonly node?: {
                readonly __typename: "TeamNode";
                readonly id: number;
                readonly title: string;
              } | null;
            } | null>;
          } | null;
          readonly userMembership?: {
            readonly __typename: "UserMemberNode";
            readonly id: number;
            readonly role?: OrganizationRole | null;
          } | null;
          readonly actionItemStates: ReadonlyArray<{
            readonly __typename: "ActionItemState";
            readonly value: number;
            readonly label: string;
            readonly isComplete: boolean;
            readonly isDefaultIncomplete: boolean;
          } | null>;
          readonly coreValues?: {
            readonly __typename: "RecognitionCoreValueNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "RecognitionCoreValueNodeEdge";
              readonly node?: {
                readonly __typename: "RecognitionCoreValueNode";
                readonly id: number;
                readonly title: string;
                readonly description: string;
                readonly emoji?: string | null;
                readonly image?: string | null;
              } | null;
            } | null>;
          } | null;
          readonly featureFlags: {
            readonly __typename: "OrganizationFeatureFlags";
            readonly actionItems?: boolean | null;
            readonly feedbacks?: boolean | null;
            readonly decisions?: boolean | null;
            readonly goals?: boolean | null;
            readonly documents?: boolean | null;
            readonly recognitions?: boolean | null;
            readonly kpis?: boolean | null;
            readonly explorer?: boolean | null;
            readonly homepageMyConnections?: boolean | null;
          };
        } | null;
      } | null>;
    };
    readonly paidFeatures: {
      readonly __typename: "PaidFeaturesNode";
      readonly canCreateDecisions?: boolean | null;
      readonly canCreateGoals?: boolean | null;
      readonly canCreateGroupGoals?: boolean | null;
      readonly canCreateOrgTemplates?: boolean | null;
    };
    readonly directReports: {
      readonly __typename: "UserNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "UserNodeEdge";
        readonly node?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
          readonly email: string;
        } | null;
      } | null>;
    };
    readonly managers: {
      readonly __typename: "UserNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "UserNodeEdge";
        readonly node?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
          readonly email: string;
        } | null;
      } | null>;
    };
    readonly teams?: {
      readonly __typename: "TeamNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "TeamNodeEdge";
        readonly node?: {
          readonly __typename: "TeamNode";
          readonly id: number;
          readonly title: string;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type GetRefreshedUserDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetRefreshedUserDataQuery = {
  readonly __typename: "Query";
  readonly me?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly tiptapAiJwt?: string | null;
  } | null;
};

export type SidebarMeetingFragmentFragment = {
  readonly __typename: "MeetingNode";
  readonly id: number;
  readonly title?: string | null;
  readonly draft: boolean;
  readonly isRecurring: boolean;
  readonly startDate?: string | null;
  readonly startDatetime?: string | null;
  readonly endDatetime?: string | null;
  readonly externalUrl?: string | null;
  readonly ignored: boolean;
  readonly videoConferenceType?: OnlineVideoConference | null;
  readonly videoConferenceUrl?: string | null;
  readonly meetingGroup?: {
    readonly __typename: "MeetingGroupNode";
    readonly id: number;
    readonly isFormalOneonone: boolean;
    readonly hasTemplate?: boolean | null;
    readonly facilitator?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
    } | null;
  } | null;
  readonly organizer?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
  } | null;
  readonly canDelete?: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  } | null;
  readonly canUpdate?: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  } | null;
};

export type GetSidebarDataQueryQueryVariables = Exact<{
  participants:
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>;
  startDatetimeGte: Scalars["DateTime"]["input"];
  startDatetimeLte: Scalars["DateTime"]["input"];
}>;

export type GetSidebarDataQueryQuery = {
  readonly __typename: "Query";
  readonly meetings?: {
    readonly __typename: "MeetingNodeConnection";
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly hasNextPage: boolean;
    };
    readonly edges: ReadonlyArray<{
      readonly __typename: "MeetingNodeEdge";
      readonly node?: {
        readonly __typename: "MeetingNode";
        readonly id: number;
        readonly title?: string | null;
        readonly draft: boolean;
        readonly isRecurring: boolean;
        readonly startDate?: string | null;
        readonly startDatetime?: string | null;
        readonly endDatetime?: string | null;
        readonly externalUrl?: string | null;
        readonly ignored: boolean;
        readonly videoConferenceType?: OnlineVideoConference | null;
        readonly videoConferenceUrl?: string | null;
        readonly meetingGroup?: {
          readonly __typename: "MeetingGroupNode";
          readonly id: number;
          readonly isFormalOneonone: boolean;
          readonly hasTemplate?: boolean | null;
          readonly facilitator?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
          } | null;
        } | null;
        readonly organizer?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
        } | null;
        readonly canDelete?: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        } | null;
        readonly canUpdate?: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        } | null;
      } | null;
    } | null>;
  } | null;
  readonly unscheduledMeetings?: {
    readonly __typename: "MeetingNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "MeetingNodeEdge";
      readonly node?: {
        readonly __typename: "MeetingNode";
        readonly id: number;
        readonly title?: string | null;
        readonly draft: boolean;
        readonly isRecurring: boolean;
        readonly startDate?: string | null;
        readonly startDatetime?: string | null;
        readonly endDatetime?: string | null;
        readonly externalUrl?: string | null;
        readonly ignored: boolean;
        readonly videoConferenceType?: OnlineVideoConference | null;
        readonly videoConferenceUrl?: string | null;
        readonly meetingGroup?: {
          readonly __typename: "MeetingGroupNode";
          readonly id: number;
          readonly isFormalOneonone: boolean;
          readonly hasTemplate?: boolean | null;
          readonly facilitator?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
          } | null;
        } | null;
        readonly organizer?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
        } | null;
        readonly canDelete?: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        } | null;
        readonly canUpdate?: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        } | null;
      } | null;
    } | null>;
  } | null;
  readonly me?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly calendarIsSyncingForFirstTime?: boolean | null;
    readonly hasCalendarSyncError?: boolean | null;
  } | null;
};

export type UpdateUserTimezoneMutationVariables = Exact<{
  userId: Scalars["Int"]["input"];
  timezone: Scalars["String"]["input"];
}>;

export type UpdateUserTimezoneMutation = {
  readonly __typename: "Mutation";
  readonly updateUser?: {
    readonly __typename: "UpdateUserMutation";
    readonly user?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly timezone?: string | null;
    } | null;
  } | null;
};

export type CancelMeetingMutationMutationVariables = Exact<{
  meetingId: Scalars["Int"]["input"];
  editType: MeetingEditTypeEnum;
}>;

export type CancelMeetingMutationMutation = {
  readonly __typename: "Mutation";
  readonly cancelMeeting?: {
    readonly __typename: "CancelMeetingMutation";
    readonly meeting?: {
      readonly __typename: "MeetingNode";
      readonly id: number;
      readonly status?: EventStatus | null;
    } | null;
  } | null;
};

export type CreateOrUpdateMeetingInExternalCalendarMutationMutationVariables =
  Exact<{
    attendees:
      | ReadonlyArray<InputMaybe<AttendeeInput>>
      | InputMaybe<AttendeeInput>;
    onlineConference: OnlineConference;
    editType: MeetingEditTypeEnum;
    credentialsId?: InputMaybe<Scalars["Int"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    startDatetime?: InputMaybe<Scalars["DateTime"]["input"]>;
    endDatetime?: InputMaybe<Scalars["DateTime"]["input"]>;
    recurrence?: InputMaybe<FrequencyInput>;
    startTimezone?: InputMaybe<Scalars["String"]["input"]>;
    endTimezone?: InputMaybe<Scalars["String"]["input"]>;
    meetingId?: InputMaybe<Scalars["Int"]["input"]>;
    organizationId?: InputMaybe<Scalars["Int"]["input"]>;
    publish: Scalars["Boolean"]["input"];
    title: Scalars["String"]["input"];
    isFormalOneonone: Scalars["Boolean"]["input"];
    facilitatorId?: InputMaybe<Scalars["Int"]["input"]>;
    templateId?: InputMaybe<Scalars["Int"]["input"]>;
  }>;

export type CreateOrUpdateMeetingInExternalCalendarMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateMeetingInExternalCalendar?: {
    readonly __typename: "CreateOrUpdateMeetingInExternalCalendarMutation";
    readonly meeting?: {
      readonly __typename: "MeetingNode";
      readonly id: number;
      readonly title?: string | null;
      readonly description?: string | null;
      readonly draft: boolean;
      readonly meetingGroupId: number;
      readonly ignored: boolean;
      readonly videoConferenceType?: OnlineVideoConference | null;
      readonly videoConferenceUrl?: string | null;
      readonly startDatetime?: string | null;
      readonly endDatetime?: string | null;
      readonly isRecurring: boolean;
      readonly organizer?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
      } | null;
      readonly meetingGroup?: {
        readonly __typename: "MeetingGroupNode";
        readonly id: number;
        readonly title: string;
        readonly description?: string | null;
        readonly isFormalOneonone: boolean;
        readonly recurrenceRule?: string | null;
        readonly onlineConference: OnlineConference;
        readonly facilitator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly topicTemplate?: {
          readonly __typename: "TopicTemplateNode";
          readonly id: number;
        } | null;
        readonly syncCredentials?: {
          readonly __typename: "UserSyncCredentialsNode";
          readonly id: number;
          readonly credentialsUid?: string | null;
        } | null;
        readonly recurrenceParameters?: {
          readonly __typename: "FrequencyNode";
          readonly byMonthDay?: ReadonlyArray<number | null> | null;
          readonly byYearDay?: ReadonlyArray<number | null> | null;
          readonly byWeekDay?: ReadonlyArray<number | null> | null;
          readonly bySetPos?: ReadonlyArray<number | null> | null;
          readonly frequency: FrequencyEnum;
          readonly interval: number;
        } | null;
      } | null;
      readonly canRead?: {
        readonly __typename: "PermissionNode";
        readonly permission: boolean;
        readonly reason: string;
      } | null;
      readonly canUpdate?: {
        readonly __typename: "PermissionNode";
        readonly permission: boolean;
        readonly reason: string;
      } | null;
      readonly canDelete?: {
        readonly __typename: "PermissionNode";
        readonly permission: boolean;
        readonly reason: string;
      } | null;
      readonly creator?: {
        readonly __typename: "UserNode";
        readonly id: number;
      } | null;
      readonly participants?: {
        readonly __typename: "MeetingParticipantConnection";
        readonly totalCount: number;
        readonly edges: ReadonlyArray<{
          readonly __typename: "MeetingParticipantEdge";
          readonly node?: {
            readonly __typename: "MeetingParticipantNode";
            readonly id?: string | null;
            readonly status?: AttendeeStatus | null;
            readonly participantEmail?: string | null;
            readonly role?: AttendeeRole | null;
            readonly user?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly firstName: string;
              readonly lastName: string;
              readonly avatar?: string | null;
              readonly email: string;
              readonly status?: UserStatus | null;
            } | null;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type GetMeetingToEditQueryQueryVariables = Exact<{
  meetingId: Scalars["Int"]["input"];
}>;

export type GetMeetingToEditQueryQuery = {
  readonly __typename: "Query";
  readonly meeting?: {
    readonly __typename: "MeetingNode";
    readonly id: number;
    readonly title?: string | null;
    readonly description?: string | null;
    readonly draft: boolean;
    readonly meetingGroupId: number;
    readonly ignored: boolean;
    readonly videoConferenceType?: OnlineVideoConference | null;
    readonly videoConferenceUrl?: string | null;
    readonly startDatetime?: string | null;
    readonly endDatetime?: string | null;
    readonly isRecurring: boolean;
    readonly organizer?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
    } | null;
    readonly meetingGroup?: {
      readonly __typename: "MeetingGroupNode";
      readonly id: number;
      readonly title: string;
      readonly description?: string | null;
      readonly isFormalOneonone: boolean;
      readonly recurrenceRule?: string | null;
      readonly onlineConference: OnlineConference;
      readonly facilitator?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly avatar?: string | null;
      } | null;
      readonly topicTemplate?: {
        readonly __typename: "TopicTemplateNode";
        readonly id: number;
      } | null;
      readonly syncCredentials?: {
        readonly __typename: "UserSyncCredentialsNode";
        readonly id: number;
        readonly credentialsUid?: string | null;
      } | null;
      readonly recurrenceParameters?: {
        readonly __typename: "FrequencyNode";
        readonly byMonthDay?: ReadonlyArray<number | null> | null;
        readonly byYearDay?: ReadonlyArray<number | null> | null;
        readonly byWeekDay?: ReadonlyArray<number | null> | null;
        readonly bySetPos?: ReadonlyArray<number | null> | null;
        readonly frequency: FrequencyEnum;
        readonly interval: number;
      } | null;
    } | null;
    readonly canRead?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
      readonly reason: string;
    } | null;
    readonly canUpdate?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
      readonly reason: string;
    } | null;
    readonly canDelete?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
      readonly reason: string;
    } | null;
    readonly creator?: {
      readonly __typename: "UserNode";
      readonly id: number;
    } | null;
    readonly participants?: {
      readonly __typename: "MeetingParticipantConnection";
      readonly totalCount: number;
      readonly edges: ReadonlyArray<{
        readonly __typename: "MeetingParticipantEdge";
        readonly node?: {
          readonly __typename: "MeetingParticipantNode";
          readonly id?: string | null;
          readonly status?: AttendeeStatus | null;
          readonly participantEmail?: string | null;
          readonly role?: AttendeeRole | null;
          readonly user?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly firstName: string;
            readonly lastName: string;
            readonly avatar?: string | null;
            readonly email: string;
            readonly status?: UserStatus | null;
          } | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type GetTeamMembersQueryQueryVariables = Exact<{
  teamId: Scalars["Int"]["input"];
}>;

export type GetTeamMembersQueryQuery = {
  readonly __typename: "Query";
  readonly team?: {
    readonly __typename: "TeamNode";
    readonly id: number;
    readonly title: string;
    readonly members?: {
      readonly __typename: "UserNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "UserNodeEdge";
        readonly node?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly firstName: string;
          readonly email: string;
          readonly avatar?: string | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type MeetingDialogFragmentFragment = {
  readonly __typename: "MeetingNode";
  readonly id: number;
  readonly title?: string | null;
  readonly description?: string | null;
  readonly draft: boolean;
  readonly meetingGroupId: number;
  readonly ignored: boolean;
  readonly videoConferenceType?: OnlineVideoConference | null;
  readonly videoConferenceUrl?: string | null;
  readonly startDatetime?: string | null;
  readonly endDatetime?: string | null;
  readonly isRecurring: boolean;
  readonly organizer?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
  } | null;
  readonly meetingGroup?: {
    readonly __typename: "MeetingGroupNode";
    readonly id: number;
    readonly title: string;
    readonly description?: string | null;
    readonly isFormalOneonone: boolean;
    readonly recurrenceRule?: string | null;
    readonly onlineConference: OnlineConference;
    readonly facilitator?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
      readonly avatar?: string | null;
    } | null;
    readonly topicTemplate?: {
      readonly __typename: "TopicTemplateNode";
      readonly id: number;
    } | null;
    readonly syncCredentials?: {
      readonly __typename: "UserSyncCredentialsNode";
      readonly id: number;
      readonly credentialsUid?: string | null;
    } | null;
    readonly recurrenceParameters?: {
      readonly __typename: "FrequencyNode";
      readonly byMonthDay?: ReadonlyArray<number | null> | null;
      readonly byYearDay?: ReadonlyArray<number | null> | null;
      readonly byWeekDay?: ReadonlyArray<number | null> | null;
      readonly bySetPos?: ReadonlyArray<number | null> | null;
      readonly frequency: FrequencyEnum;
      readonly interval: number;
    } | null;
  } | null;
  readonly canRead?: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
    readonly reason: string;
  } | null;
  readonly canUpdate?: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
    readonly reason: string;
  } | null;
  readonly canDelete?: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
    readonly reason: string;
  } | null;
  readonly creator?: {
    readonly __typename: "UserNode";
    readonly id: number;
  } | null;
  readonly participants?: {
    readonly __typename: "MeetingParticipantConnection";
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly __typename: "MeetingParticipantEdge";
      readonly node?: {
        readonly __typename: "MeetingParticipantNode";
        readonly id?: string | null;
        readonly status?: AttendeeStatus | null;
        readonly participantEmail?: string | null;
        readonly role?: AttendeeRole | null;
        readonly user?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly firstName: string;
          readonly lastName: string;
          readonly avatar?: string | null;
          readonly email: string;
          readonly status?: UserStatus | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type RefreshCalendarsQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RefreshCalendarsQueryQuery = {
  readonly __typename: "Query";
  readonly loggedIn?: boolean | null;
  readonly me?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly syncCredentials?: {
      readonly __typename: "UserSyncCredentialsNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "UserSyncCredentialsNodeEdge";
        readonly node?: {
          readonly __typename: "UserSyncCredentialsNode";
          readonly id: number;
          readonly provider?: SyncCredentialsTypeEnum | null;
          readonly credentialsUid?: string | null;
          readonly hasValidCalendarCredentials?: boolean | null;
          readonly hasCalendarWriteCredentials?: boolean | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type UpdateAttendanceStatusMutationMutationVariables = Exact<{
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  status: AttendeeStatus;
}>;

export type UpdateAttendanceStatusMutationMutation = {
  readonly __typename: "Mutation";
  readonly updateAttendanceStatus?: {
    readonly __typename: "UpdateAttendanceStatusMutation";
    readonly meeting?: {
      readonly __typename: "MeetingNode";
      readonly id: number;
      readonly title?: string | null;
      readonly description?: string | null;
      readonly draft: boolean;
      readonly meetingGroupId: number;
      readonly ignored: boolean;
      readonly videoConferenceType?: OnlineVideoConference | null;
      readonly videoConferenceUrl?: string | null;
      readonly startDatetime?: string | null;
      readonly endDatetime?: string | null;
      readonly isRecurring: boolean;
      readonly organizer?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
      } | null;
      readonly meetingGroup?: {
        readonly __typename: "MeetingGroupNode";
        readonly id: number;
        readonly title: string;
        readonly description?: string | null;
        readonly isFormalOneonone: boolean;
        readonly recurrenceRule?: string | null;
        readonly onlineConference: OnlineConference;
        readonly facilitator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly topicTemplate?: {
          readonly __typename: "TopicTemplateNode";
          readonly id: number;
        } | null;
        readonly syncCredentials?: {
          readonly __typename: "UserSyncCredentialsNode";
          readonly id: number;
          readonly credentialsUid?: string | null;
        } | null;
        readonly recurrenceParameters?: {
          readonly __typename: "FrequencyNode";
          readonly byMonthDay?: ReadonlyArray<number | null> | null;
          readonly byYearDay?: ReadonlyArray<number | null> | null;
          readonly byWeekDay?: ReadonlyArray<number | null> | null;
          readonly bySetPos?: ReadonlyArray<number | null> | null;
          readonly frequency: FrequencyEnum;
          readonly interval: number;
        } | null;
      } | null;
      readonly canRead?: {
        readonly __typename: "PermissionNode";
        readonly permission: boolean;
        readonly reason: string;
      } | null;
      readonly canUpdate?: {
        readonly __typename: "PermissionNode";
        readonly permission: boolean;
        readonly reason: string;
      } | null;
      readonly canDelete?: {
        readonly __typename: "PermissionNode";
        readonly permission: boolean;
        readonly reason: string;
      } | null;
      readonly creator?: {
        readonly __typename: "UserNode";
        readonly id: number;
      } | null;
      readonly participants?: {
        readonly __typename: "MeetingParticipantConnection";
        readonly totalCount: number;
        readonly edges: ReadonlyArray<{
          readonly __typename: "MeetingParticipantEdge";
          readonly node?: {
            readonly __typename: "MeetingParticipantNode";
            readonly id?: string | null;
            readonly status?: AttendeeStatus | null;
            readonly participantEmail?: string | null;
            readonly role?: AttendeeRole | null;
            readonly user?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly firstName: string;
              readonly lastName: string;
              readonly avatar?: string | null;
              readonly email: string;
              readonly status?: UserStatus | null;
            } | null;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type CreateTemplateFromMeetingMutationMutationVariables = Exact<{
  meetingId: Scalars["Int"]["input"];
  title: Scalars["String"]["input"];
}>;

export type CreateTemplateFromMeetingMutationMutation = {
  readonly __typename: "Mutation";
  readonly createTemplateFromMeeting?: {
    readonly __typename: "CreateTemplateFromMeetingMutation";
    readonly meeting?: {
      readonly __typename: "MeetingNode";
      readonly id: number;
    } | null;
    readonly topicTemplate?: {
      readonly __typename: "TopicTemplateNode";
      readonly id: number;
    } | null;
  } | null;
};

export type IgnoreMeetingMutationMutationVariables = Exact<{
  meetingId: Scalars["Int"]["input"];
  ignore: Scalars["Boolean"]["input"];
}>;

export type IgnoreMeetingMutationMutation = {
  readonly __typename: "Mutation";
  readonly ignoreMeeting?: {
    readonly __typename: "IgnoreMeetingMutation";
    readonly meeting?: {
      readonly __typename: "MeetingNode";
      readonly id: number;
      readonly ignored: boolean;
      readonly meetingGroup?: {
        readonly __typename: "MeetingGroupNode";
        readonly id: number;
      } | null;
    } | null;
  } | null;
};

export type DeleteMeetingTranscriptsNewPageMutationMutationVariables = Exact<{
  meetingId: Scalars["Int"]["input"];
}>;

export type DeleteMeetingTranscriptsNewPageMutationMutation = {
  readonly __typename: "Mutation";
  readonly deleteMeetingTranscripts?: {
    readonly __typename: "DeleteMeetingTranscriptsMutation";
    readonly meeting?: {
      readonly __typename: "MeetingNode";
      readonly id: number;
      readonly hasTranscript?: boolean | null;
      readonly transcript?: {
        readonly __typename: "MeetingTranscriptNode";
        readonly id: number;
      } | null;
    } | null;
  } | null;
};

export type SubmitOneononeNewPageMutationVariables = Exact<{
  meetingId: Scalars["Int"]["input"];
  unhideIndividualNotes?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type SubmitOneononeNewPageMutation = {
  readonly __typename: "Mutation";
  readonly submitOneonone?: {
    readonly __typename: "SubmitOneOnOneMutation";
    readonly meeting?: {
      readonly __typename: "MeetingNode";
      readonly id: number;
      readonly isSubmitted: boolean;
      readonly topics?: {
        readonly __typename: "TopicNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "TopicNodeEdge";
          readonly node?: {
            readonly __typename: "TopicNode";
            readonly individualNotes?: {
              readonly __typename: "IndividualNoteNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "IndividualNoteNodeEdge";
                readonly node?: {
                  readonly __typename: "IndividualNoteNode";
                  readonly id: number;
                  readonly created: string;
                  readonly hidden: boolean;
                  readonly enabled: boolean;
                  readonly notes?: any | null;
                  readonly creator: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                  };
                } | null;
              } | null>;
            } | null;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type UpdateMeetingFinalizationStatusNewPageMutationMutationVariables =
  Exact<{
    meetingId: Scalars["Int"]["input"];
    isFinalized: Scalars["Boolean"]["input"];
  }>;

export type UpdateMeetingFinalizationStatusNewPageMutationMutation = {
  readonly __typename: "Mutation";
  readonly updateMeetingFinalizationStatus?: {
    readonly __typename: "UpdateMeetingFinalizationStatus";
    readonly meeting?: {
      readonly __typename: "MeetingNode";
      readonly id: number;
      readonly isFinalized: boolean;
      readonly finalizedAt?: string | null;
      readonly finalizedBy?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
      } | null;
      readonly topics?: {
        readonly __typename: "TopicNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "TopicNodeEdge";
          readonly node?: {
            readonly __typename: "TopicNode";
            readonly id: number;
            readonly isMandatory: boolean;
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type AddBotToMeetingNewPageMutationMutationVariables = Exact<{
  meetingId: Scalars["Int"]["input"];
}>;

export type AddBotToMeetingNewPageMutationMutation = {
  readonly __typename: "Mutation";
  readonly addBotToMeeting?: {
    readonly __typename: "AddBotToMeetingMutation";
    readonly meeting?: {
      readonly __typename: "MeetingNode";
      readonly hasBotInMeeting?: boolean | null;
      readonly botIsWaitingToJoinMeeting?: boolean | null;
      readonly id: number;
    } | null;
  } | null;
};

export type ChangeBotRecordingStatusNewPageMutationMutationVariables = Exact<{
  meetingId: Scalars["Int"]["input"];
  isRecording: Scalars["Boolean"]["input"];
}>;

export type ChangeBotRecordingStatusNewPageMutationMutation = {
  readonly __typename: "Mutation";
  readonly changeBotRecordingStatus?: {
    readonly __typename: "ChangeBotRecordingStatusMutation";
    readonly meeting?: {
      readonly __typename: "MeetingNode";
      readonly botIsRecording?: boolean | null;
      readonly botIsWaitingToJoinMeeting?: boolean | null;
      readonly id: number;
    } | null;
  } | null;
};

export type CreateTopicNewPageMutationMutationVariables = Exact<{
  topicId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  afterTopicId?: InputMaybe<Scalars["Int"]["input"]>;
  beforeTopicId?: InputMaybe<Scalars["Int"]["input"]>;
  includesIndividualNotes?: InputMaybe<Scalars["Boolean"]["input"]>;
  includesSharedNotes?: InputMaybe<Scalars["Boolean"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  discussionNotes?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type CreateTopicNewPageMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateTopic?: {
    readonly __typename: "CreateOrUpdateTopicMutation";
    readonly topic?: {
      readonly __typename: "TopicNode";
      readonly id: number;
      readonly title: string;
      readonly description?: string | null;
      readonly discussionNotes?: any | null;
      readonly created: string;
      readonly state?: TopicState | null;
      readonly eventChannelName?: string | null;
      readonly includesIndividualNotes: boolean;
      readonly includesIndividualNotesForCurrentUser: boolean;
      readonly includesSharedNotes: boolean;
      readonly isMandatory: boolean;
      readonly notesRequirement: NotesRequirement;
      readonly individualNotes?: {
        readonly __typename: "IndividualNoteNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "IndividualNoteNodeEdge";
          readonly node?: {
            readonly __typename: "IndividualNoteNode";
            readonly id: number;
            readonly created: string;
            readonly hidden: boolean;
            readonly enabled: boolean;
            readonly notes?: any | null;
            readonly creator: {
              readonly __typename: "UserNode";
              readonly id: number;
            };
          } | null;
        } | null>;
      } | null;
      readonly creator?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
      } | null;
      readonly assignee?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly avatar?: string | null;
        readonly name: string;
      } | null;
      readonly previousTopic?: {
        readonly __typename: "TopicNode";
        readonly id: number;
        readonly discussionNotes?: any | null;
      } | null;
      readonly linkedTemplateTopic?: {
        readonly __typename: "TopicNode";
        readonly id: number;
        readonly title: string;
        readonly discussionNotes?: any | null;
        readonly defaultSubjectNotes?: any | null;
        readonly defaultFacilitatorNotes?: any | null;
        readonly topicTemplate?: {
          readonly __typename: "TopicTemplateNode";
          readonly id: number;
          readonly title: string;
        } | null;
      } | null;
      readonly canUpdate: {
        readonly __typename: "PermissionNode";
        readonly permission: boolean;
      };
      readonly canDelete: {
        readonly __typename: "PermissionNode";
        readonly permission: boolean;
      };
      readonly copiedFrom?: {
        readonly __typename: "TopicNode";
        readonly id: number;
        readonly title: string;
        readonly meeting?: {
          readonly __typename: "MeetingNode";
          readonly id: number;
          readonly title?: string | null;
          readonly startDatetime?: string | null;
          readonly meetingGroupId: number;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteDraftMeetingNewPageMutationMutationVariables = Exact<{
  meetingId: Scalars["Int"]["input"];
}>;

export type DeleteDraftMeetingNewPageMutationMutation = {
  readonly __typename: "Mutation";
  readonly deleteMeeting?: {
    readonly __typename: "DeleteMeetingMutation";
    readonly meeting?: {
      readonly __typename: "MeetingNode";
      readonly id: number;
    } | null;
  } | null;
};

export type GetFirstMeetingOfMeetingGroupQueryQueryVariables = Exact<{
  meetingGroupId: Scalars["Int"]["input"];
  today: Scalars["DateTime"]["input"];
}>;

export type GetFirstMeetingOfMeetingGroupQueryQuery = {
  readonly __typename: "Query";
  readonly meetingGroup?: {
    readonly __typename: "MeetingGroupNode";
    readonly id: number;
    readonly nextMeetings?: {
      readonly __typename: "MeetingNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "MeetingNodeEdge";
        readonly node?: {
          readonly __typename: "MeetingNode";
          readonly id: number;
        } | null;
      } | null>;
    } | null;
    readonly lastMeetings?: {
      readonly __typename: "MeetingNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "MeetingNodeEdge";
        readonly node?: {
          readonly __typename: "MeetingNode";
          readonly id: number;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type GetMeetingFeedbackNewPageQueryQueryVariables = Exact<{
  feedbackRecipients?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  feedbackSenders?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  fetchData: Scalars["Boolean"]["input"];
  after?: InputMaybe<Scalars["String"]["input"]>;
  first: Scalars["Int"]["input"];
}>;

export type GetMeetingFeedbackNewPageQueryQuery = {
  readonly __typename: "Query";
  readonly artifacts?: {
    readonly __typename: "ArtifactConnection";
    readonly totalCount: number;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly hasNextPage: boolean;
      readonly endCursor?: string | null;
    };
    readonly edges?: ReadonlyArray<{
      readonly __typename: "ArtifactEdge";
      readonly node?:
        | {
            readonly __typename: "ActionItemArtifactNode";
            readonly created: string;
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "AsanaTaskArtifactNode";
            readonly created: string;
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "DecisionArtifactNode";
            readonly created: string;
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "DocumentArtifactNode";
            readonly created: string;
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "FeedbackArtifactNode";
            readonly created: string;
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly recipientsCanView: boolean;
            readonly recipientsManagersCanView: boolean;
            readonly adminsCanView: boolean;
            readonly creatorCanView: boolean;
            readonly feedbackState: FeedbackState;
            readonly message?: any | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly creator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
            readonly feedbackRecipients: {
              readonly __typename: "UserNodeConnection";
              readonly totalCount: number;
              readonly edges: ReadonlyArray<{
                readonly __typename: "UserNodeEdge";
                readonly node?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly avatar?: string | null;
                  readonly name: string;
                } | null;
              } | null>;
            };
            readonly sender: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly avatar?: string | null;
              readonly name: string;
            };
          }
        | {
            readonly __typename: "GithubIssuesArtifactNode";
            readonly created: string;
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "GoalArtifactNode";
            readonly created: string;
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "KPIArtifactNode";
            readonly created: string;
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "RatingArtifactNode";
            readonly created: string;
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "RecognitionArtifactNode";
            readonly created: string;
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | null;
    } | null>;
  } | null;
};

export type GetMeetingGoalsNewPageQueryQueryVariables = Exact<{
  fetchData: Scalars["Boolean"]["input"];
  goalInvolvingUsers:
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>;
  asUsers?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  goalState: GoalState;
  goalScopes?: InputMaybe<
    ReadonlyArray<InputMaybe<GoalScope>> | InputMaybe<GoalScope>
  >;
  goalsCompletedInTheLastXDays?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  first: Scalars["Int"]["input"];
}>;

export type GetMeetingGoalsNewPageQueryQuery = {
  readonly __typename: "Query";
  readonly artifacts?: {
    readonly __typename: "ArtifactConnection";
    readonly totalCount: number;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly hasNextPage: boolean;
      readonly endCursor?: string | null;
    };
    readonly edges?: ReadonlyArray<{
      readonly __typename: "ArtifactEdge";
      readonly node?:
        | {
            readonly __typename: "ActionItemArtifactNode";
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
            readonly created: string;
            readonly isStale: boolean;
            readonly activities: {
              readonly __typename: "ArtifactActivityNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "ArtifactActivityNodeEdge";
                readonly node?: {
                  readonly __typename: "ArtifactActivityNode";
                  readonly created: string;
                } | null;
              } | null>;
            };
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly organization?: {
              readonly __typename: "OrganizationNode";
              readonly id: number;
            } | null;
          }
        | {
            readonly __typename: "AsanaTaskArtifactNode";
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
            readonly created: string;
            readonly isStale: boolean;
            readonly activities: {
              readonly __typename: "ArtifactActivityNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "ArtifactActivityNodeEdge";
                readonly node?: {
                  readonly __typename: "ArtifactActivityNode";
                  readonly created: string;
                } | null;
              } | null>;
            };
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly organization?: {
              readonly __typename: "OrganizationNode";
              readonly id: number;
            } | null;
          }
        | {
            readonly __typename: "DecisionArtifactNode";
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
            readonly created: string;
            readonly isStale: boolean;
            readonly activities: {
              readonly __typename: "ArtifactActivityNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "ArtifactActivityNodeEdge";
                readonly node?: {
                  readonly __typename: "ArtifactActivityNode";
                  readonly created: string;
                } | null;
              } | null>;
            };
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly organization?: {
              readonly __typename: "OrganizationNode";
              readonly id: number;
            } | null;
          }
        | {
            readonly __typename: "DocumentArtifactNode";
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
            readonly created: string;
            readonly isStale: boolean;
            readonly activities: {
              readonly __typename: "ArtifactActivityNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "ArtifactActivityNodeEdge";
                readonly node?: {
                  readonly __typename: "ArtifactActivityNode";
                  readonly created: string;
                } | null;
              } | null>;
            };
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly organization?: {
              readonly __typename: "OrganizationNode";
              readonly id: number;
            } | null;
          }
        | {
            readonly __typename: "FeedbackArtifactNode";
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
            readonly created: string;
            readonly isStale: boolean;
            readonly activities: {
              readonly __typename: "ArtifactActivityNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "ArtifactActivityNodeEdge";
                readonly node?: {
                  readonly __typename: "ArtifactActivityNode";
                  readonly created: string;
                } | null;
              } | null>;
            };
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly organization?: {
              readonly __typename: "OrganizationNode";
              readonly id: number;
            } | null;
          }
        | {
            readonly __typename: "GithubIssuesArtifactNode";
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
            readonly created: string;
            readonly isStale: boolean;
            readonly activities: {
              readonly __typename: "ArtifactActivityNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "ArtifactActivityNodeEdge";
                readonly node?: {
                  readonly __typename: "ArtifactActivityNode";
                  readonly created: string;
                } | null;
              } | null>;
            };
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly organization?: {
              readonly __typename: "OrganizationNode";
              readonly id: number;
            } | null;
          }
        | {
            readonly __typename: "GoalArtifactNode";
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
            readonly created: string;
            readonly isStale: boolean;
            readonly state: GoalState;
            readonly status: GoalStatus;
            readonly scope: GoalScope;
            readonly goalVisibility: GoalVisibility;
            readonly dueDate?: string | null;
            readonly startDate?: string | null;
            readonly progress: number;
            readonly progressType: GoalProgressType;
            readonly startValue: number;
            readonly currentValue: number;
            readonly targetValue: number;
            readonly ownerCount?: number | null;
            readonly contributorCount?: number | null;
            readonly activities: {
              readonly __typename: "ArtifactActivityNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "ArtifactActivityNodeEdge";
                readonly node?: {
                  readonly __typename: "ArtifactActivityNode";
                  readonly created: string;
                } | null;
              } | null>;
            };
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly organization?: {
              readonly __typename: "OrganizationNode";
              readonly id: number;
            } | null;
            readonly firstOwner?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly avatar?: string | null;
              readonly name: string;
            } | null;
            readonly canRead: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "KPIArtifactNode";
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
            readonly created: string;
            readonly isStale: boolean;
            readonly activities: {
              readonly __typename: "ArtifactActivityNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "ArtifactActivityNodeEdge";
                readonly node?: {
                  readonly __typename: "ArtifactActivityNode";
                  readonly created: string;
                } | null;
              } | null>;
            };
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly organization?: {
              readonly __typename: "OrganizationNode";
              readonly id: number;
            } | null;
          }
        | {
            readonly __typename: "RatingArtifactNode";
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
            readonly created: string;
            readonly isStale: boolean;
            readonly activities: {
              readonly __typename: "ArtifactActivityNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "ArtifactActivityNodeEdge";
                readonly node?: {
                  readonly __typename: "ArtifactActivityNode";
                  readonly created: string;
                } | null;
              } | null>;
            };
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly organization?: {
              readonly __typename: "OrganizationNode";
              readonly id: number;
            } | null;
          }
        | {
            readonly __typename: "RecognitionArtifactNode";
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
            readonly created: string;
            readonly isStale: boolean;
            readonly activities: {
              readonly __typename: "ArtifactActivityNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "ArtifactActivityNodeEdge";
                readonly node?: {
                  readonly __typename: "ArtifactActivityNode";
                  readonly created: string;
                } | null;
              } | null>;
            };
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly organization?: {
              readonly __typename: "OrganizationNode";
              readonly id: number;
            } | null;
          }
        | null;
    } | null>;
  } | null;
};

export type MeetingViewMeetingGroupNewPageFragmentFragment = {
  readonly __typename: "MeetingGroupNode";
  readonly id: number;
  readonly title: string;
  readonly hasTemplate?: boolean | null;
  readonly allowAdminVisibility: boolean;
  readonly allowOrgVisibility: boolean;
  readonly allowManagementTreeVisibility: boolean;
  readonly isFormalOneonone: boolean;
  readonly transcribeMeetingsByDefault?: boolean | null;
  readonly addTranscriptionBotToMeetingsByDefault: boolean;
  readonly canUpdate?: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  } | null;
  readonly facilitator?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
    readonly name: string;
  } | null;
  readonly syncCredentials?: {
    readonly __typename: "UserSyncCredentialsNode";
    readonly id: number;
    readonly hasCalendarWriteCredentials?: boolean | null;
    readonly provider?: SyncCredentialsTypeEnum | null;
  } | null;
  readonly topicTemplate?: {
    readonly __typename: "TopicTemplateNode";
    readonly id: number;
    readonly title: string;
    readonly globalTemplate: boolean;
    readonly publicTemplate: boolean;
    readonly meetingTemplate: boolean;
    readonly finalizePermissions: FinalizePermissions;
    readonly organization?: {
      readonly __typename: "OrganizationNode";
      readonly id: number;
    } | null;
  } | null;
};

export type GetMeetingGroupNewPageQueryQueryVariables = Exact<{
  meetingGroupId: Scalars["Int"]["input"];
}>;

export type GetMeetingGroupNewPageQueryQuery = {
  readonly __typename: "Query";
  readonly meetingGroup?: {
    readonly __typename: "MeetingGroupNode";
    readonly id: number;
    readonly title: string;
    readonly hasTemplate?: boolean | null;
    readonly allowAdminVisibility: boolean;
    readonly allowOrgVisibility: boolean;
    readonly allowManagementTreeVisibility: boolean;
    readonly isFormalOneonone: boolean;
    readonly transcribeMeetingsByDefault?: boolean | null;
    readonly addTranscriptionBotToMeetingsByDefault: boolean;
    readonly canUpdate?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
    } | null;
    readonly facilitator?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
      readonly avatar?: string | null;
    } | null;
    readonly organization?: {
      readonly __typename: "OrganizationNode";
      readonly id: number;
      readonly name: string;
    } | null;
    readonly syncCredentials?: {
      readonly __typename: "UserSyncCredentialsNode";
      readonly id: number;
      readonly hasCalendarWriteCredentials?: boolean | null;
      readonly provider?: SyncCredentialsTypeEnum | null;
    } | null;
    readonly topicTemplate?: {
      readonly __typename: "TopicTemplateNode";
      readonly id: number;
      readonly title: string;
      readonly globalTemplate: boolean;
      readonly publicTemplate: boolean;
      readonly meetingTemplate: boolean;
      readonly finalizePermissions: FinalizePermissions;
      readonly organization?: {
        readonly __typename: "OrganizationNode";
        readonly id: number;
      } | null;
    } | null;
  } | null;
};

export type MeetingViewComplainceProgramAssessmentTemplateNewPageFragment = {
  readonly __typename: "AssessmentTemplateNode";
  readonly id: number;
  readonly assessmentType: AssessmentType;
  readonly assessments: {
    readonly __typename: "AssessmentNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "AssessmentNodeEdge";
      readonly node?: {
        readonly __typename: "AssessmentNode";
        readonly id: number;
        readonly responder?: {
          readonly __typename: "UserNode";
          readonly id: number;
        } | null;
        readonly target?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
        } | null;
      } | null;
    } | null>;
  };
};

export type GetMeetingInsightsAndRequirementsNewPageQueryQueryVariables =
  Exact<{
    meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  }>;

export type GetMeetingInsightsAndRequirementsNewPageQueryQuery = {
  readonly __typename: "Query";
  readonly meeting?: {
    readonly __typename: "MeetingNode";
    readonly id: number;
    readonly insights: {
      readonly __typename: "InsightConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "InsightEdge";
        readonly node?: {
          readonly __typename: "InsightNode";
          readonly id: number;
          readonly type: InsightType;
          readonly manager?: {
            readonly __typename: "UserNode";
            readonly id: number;
          } | null;
          readonly report?: {
            readonly __typename: "UserNode";
            readonly id: number;
          } | null;
          readonly complianceProgram?: {
            readonly __typename: "ComplianceProgramNode";
            readonly id: number;
            readonly title: string;
            readonly requiredTopicTemplates: {
              readonly __typename: "TopicTemplateNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "TopicTemplateNodeEdge";
                readonly node?: {
                  readonly __typename: "TopicTemplateNode";
                  readonly id: number;
                  readonly title: string;
                } | null;
              } | null>;
            };
            readonly performanceAssessmentTemplate?: {
              readonly __typename: "AssessmentTemplateNode";
              readonly id: number;
              readonly assessmentType: AssessmentType;
              readonly assessments: {
                readonly __typename: "AssessmentNodeConnection";
                readonly edges: ReadonlyArray<{
                  readonly __typename: "AssessmentNodeEdge";
                  readonly node?: {
                    readonly __typename: "AssessmentNode";
                    readonly id: number;
                    readonly responder?: {
                      readonly __typename: "UserNode";
                      readonly id: number;
                    } | null;
                    readonly target?: {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                    } | null;
                  } | null;
                } | null>;
              };
            } | null;
            readonly managerAssessmentTemplate?: {
              readonly __typename: "AssessmentTemplateNode";
              readonly id: number;
              readonly assessmentType: AssessmentType;
              readonly assessments: {
                readonly __typename: "AssessmentNodeConnection";
                readonly edges: ReadonlyArray<{
                  readonly __typename: "AssessmentNodeEdge";
                  readonly node?: {
                    readonly __typename: "AssessmentNode";
                    readonly id: number;
                    readonly responder?: {
                      readonly __typename: "UserNode";
                      readonly id: number;
                    } | null;
                    readonly target?: {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                    } | null;
                  } | null;
                } | null>;
              };
            } | null;
            readonly peerAssessmentTemplate?: {
              readonly __typename: "AssessmentTemplateNode";
              readonly id: number;
              readonly assessmentType: AssessmentType;
              readonly assessments: {
                readonly __typename: "AssessmentNodeConnection";
                readonly edges: ReadonlyArray<{
                  readonly __typename: "AssessmentNodeEdge";
                  readonly node?: {
                    readonly __typename: "AssessmentNode";
                    readonly id: number;
                    readonly responder?: {
                      readonly __typename: "UserNode";
                      readonly id: number;
                    } | null;
                    readonly target?: {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                    } | null;
                  } | null;
                } | null>;
              };
            } | null;
          } | null;
          readonly assessmentTemplate?: {
            readonly __typename: "AssessmentTemplateNode";
            readonly id: number;
          } | null;
        } | null;
      } | null>;
    };
    readonly relatedAssessments: {
      readonly __typename: "AssessmentNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "AssessmentNodeEdge";
        readonly node?: {
          readonly __typename: "AssessmentNode";
          readonly id: number;
          readonly complianceProgram?: {
            readonly __typename: "ComplianceProgramNode";
            readonly id: number;
            readonly title: string;
          } | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type GetMeetingNewPageQueryQueryVariables = Exact<{
  meetingId: Scalars["Int"]["input"];
}>;

export type GetMeetingNewPageQueryQuery = {
  readonly __typename: "Query";
  readonly meeting?: {
    readonly __typename: "MeetingNode";
    readonly id: number;
    readonly title?: string | null;
    readonly startDatetime?: string | null;
    readonly startDate?: string | null;
    readonly endDatetime?: string | null;
    readonly externalUrl?: string | null;
    readonly videoConferenceType?: OnlineVideoConference | null;
    readonly videoConferenceUrl?: string | null;
    readonly allowExternalViewers?: boolean | null;
    readonly writableSharingUrl?: string | null;
    readonly websocketToken?: string | null;
    readonly hasPreviousMeetings?: boolean | null;
    readonly hasPreviousMeetingsWithTopics?: boolean | null;
    readonly ignored: boolean;
    readonly hasBotInMeeting?: boolean | null;
    readonly botIsRecording?: boolean | null;
    readonly botIsWaitingToJoinMeeting?: boolean | null;
    readonly finalizedAt?: string | null;
    readonly isFinalized: boolean;
    readonly isSubmitted: boolean;
    readonly hasIncompleteMandatoryTopics: boolean;
    readonly transcriptionStatus?: boolean | null;
    readonly created: string;
    readonly draft: boolean;
    readonly isRecurring: boolean;
    readonly hasExternalParticipants?: boolean | null;
    readonly canFinalize: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
    };
    readonly canDelete?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
    } | null;
    readonly canUpdate?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
    } | null;
    readonly finalizedBy?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
      readonly avatar?: string | null;
    } | null;
    readonly organization?: {
      readonly __typename: "OrganizationNode";
      readonly id: number;
      readonly name: string;
      readonly effectivePricingTier?: PricingTier | null;
      readonly enableMeetingSummarization: boolean;
      readonly domains?: ReadonlyArray<string | null> | null;
    } | null;
    readonly organizer?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
    } | null;
    readonly subject?: {
      readonly __typename: "UserNode";
      readonly id: number;
    } | null;
    readonly creator?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
      readonly firstName: string;
      readonly lastName: string;
      readonly avatar?: string | null;
    } | null;
    readonly transcript?: {
      readonly __typename: "MeetingTranscriptNode";
      readonly id: number;
    } | null;
    readonly meetingGroup?: {
      readonly __typename: "MeetingGroupNode";
      readonly id: number;
      readonly title: string;
      readonly hasTemplate?: boolean | null;
      readonly allowAdminVisibility: boolean;
      readonly allowOrgVisibility: boolean;
      readonly allowManagementTreeVisibility: boolean;
      readonly isFormalOneonone: boolean;
      readonly transcribeMeetingsByDefault?: boolean | null;
      readonly addTranscriptionBotToMeetingsByDefault: boolean;
      readonly canUpdate?: {
        readonly __typename: "PermissionNode";
        readonly permission: boolean;
      } | null;
      readonly facilitator?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly avatar?: string | null;
      } | null;
      readonly organization?: {
        readonly __typename: "OrganizationNode";
        readonly id: number;
        readonly name: string;
      } | null;
      readonly syncCredentials?: {
        readonly __typename: "UserSyncCredentialsNode";
        readonly id: number;
        readonly hasCalendarWriteCredentials?: boolean | null;
        readonly provider?: SyncCredentialsTypeEnum | null;
      } | null;
      readonly topicTemplate?: {
        readonly __typename: "TopicTemplateNode";
        readonly id: number;
        readonly title: string;
        readonly globalTemplate: boolean;
        readonly publicTemplate: boolean;
        readonly meetingTemplate: boolean;
        readonly finalizePermissions: FinalizePermissions;
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
      } | null;
    } | null;
    readonly topics?: {
      readonly __typename: "TopicNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "TopicNodeEdge";
        readonly node?: {
          readonly __typename: "TopicNode";
          readonly id: number;
          readonly title: string;
          readonly description?: string | null;
          readonly discussionNotes?: any | null;
          readonly created: string;
          readonly state?: TopicState | null;
          readonly eventChannelName?: string | null;
          readonly includesIndividualNotes: boolean;
          readonly includesIndividualNotesForCurrentUser: boolean;
          readonly includesSharedNotes: boolean;
          readonly isMandatory: boolean;
          readonly notesRequirement: NotesRequirement;
          readonly individualNotes?: {
            readonly __typename: "IndividualNoteNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "IndividualNoteNodeEdge";
              readonly node?: {
                readonly __typename: "IndividualNoteNode";
                readonly id: number;
                readonly created: string;
                readonly hidden: boolean;
                readonly enabled: boolean;
                readonly notes?: any | null;
                readonly creator: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                };
              } | null;
            } | null>;
          } | null;
          readonly creator?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
          } | null;
          readonly assignee?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly avatar?: string | null;
            readonly name: string;
          } | null;
          readonly previousTopic?: {
            readonly __typename: "TopicNode";
            readonly id: number;
            readonly discussionNotes?: any | null;
          } | null;
          readonly linkedTemplateTopic?: {
            readonly __typename: "TopicNode";
            readonly id: number;
            readonly title: string;
            readonly discussionNotes?: any | null;
            readonly defaultSubjectNotes?: any | null;
            readonly defaultFacilitatorNotes?: any | null;
            readonly topicTemplate?: {
              readonly __typename: "TopicTemplateNode";
              readonly id: number;
              readonly title: string;
            } | null;
          } | null;
          readonly canUpdate: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly copiedFrom?: {
            readonly __typename: "TopicNode";
            readonly id: number;
            readonly title: string;
            readonly meeting?: {
              readonly __typename: "MeetingNode";
              readonly id: number;
              readonly title?: string | null;
              readonly startDatetime?: string | null;
              readonly meetingGroupId: number;
            } | null;
          } | null;
        } | null;
      } | null>;
    } | null;
    readonly participants?: {
      readonly __typename: "MeetingParticipantConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "MeetingParticipantEdge";
        readonly node?: {
          readonly __typename: "MeetingParticipantNode";
          readonly id?: string | null;
          readonly status?: AttendeeStatus | null;
          readonly participantEmail?: string | null;
          readonly role?: AttendeeRole | null;
          readonly user?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly firstName: string;
            readonly lastName: string;
            readonly avatar?: string | null;
            readonly email: string;
            readonly status?: UserStatus | null;
          } | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type RecentlyCompletedAssessmentDeliveryNewPageFragment = {
  readonly __typename: "AssessmentDeliveryNode";
  readonly id: number;
  readonly deliveryDatetime?: string | null;
  readonly lastSubmissionDatetime?: string | null;
  readonly state: AssessmentDeliveryState;
  readonly complianceProgram?: {
    readonly __typename: "ComplianceProgramNode";
    readonly id: number;
    readonly title: string;
    readonly dueDate?: string | null;
  } | null;
  readonly template: {
    readonly __typename: "AssessmentTemplateNode";
    readonly id: number;
    readonly hasSelfAssessment: boolean;
    readonly assessmentType: AssessmentType;
  };
  readonly target?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
  } | null;
  readonly creator?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
  } | null;
};

export type GetMeetingRecentlyCompletedAssessmentDeliveriesNewPageQueryQueryVariables =
  Exact<{
    meetingId: Scalars["Int"]["input"];
    assessmentType?: InputMaybe<AssessmentType>;
    after?: InputMaybe<Scalars["String"]["input"]>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
  }>;

export type GetMeetingRecentlyCompletedAssessmentDeliveriesNewPageQueryQuery = {
  readonly __typename: "Query";
  readonly meeting?: {
    readonly __typename: "MeetingNode";
    readonly id: number;
    readonly recentlyCompletedAssessmentDeliveries: {
      readonly __typename: "AssessmentDeliveryNodeConnection";
      readonly totalCount: number;
      readonly pageInfo: {
        readonly __typename: "PageInfo";
        readonly endCursor?: string | null;
        readonly hasNextPage: boolean;
      };
      readonly edges: ReadonlyArray<{
        readonly __typename: "AssessmentDeliveryNodeEdge";
        readonly node?: {
          readonly __typename: "AssessmentDeliveryNode";
          readonly id: number;
          readonly deliveryDatetime?: string | null;
          readonly lastSubmissionDatetime?: string | null;
          readonly state: AssessmentDeliveryState;
          readonly complianceProgram?: {
            readonly __typename: "ComplianceProgramNode";
            readonly id: number;
            readonly title: string;
            readonly dueDate?: string | null;
          } | null;
          readonly template: {
            readonly __typename: "AssessmentTemplateNode";
            readonly id: number;
            readonly hasSelfAssessment: boolean;
            readonly assessmentType: AssessmentType;
          };
          readonly target?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
          } | null;
          readonly creator?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
          } | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type RecentlyCompletedAssessmentFragment = {
  readonly __typename: "AssessmentNode";
  readonly submittedDatetime?: string | null;
  readonly id: number;
  readonly template: {
    readonly __typename: "AssessmentTemplateNode";
    readonly id: number;
    readonly assessmentType: AssessmentType;
  };
  readonly target?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
  } | null;
  readonly responder?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
  } | null;
  readonly complianceProgram?: {
    readonly __typename: "ComplianceProgramNode";
    readonly id: number;
    readonly title: string;
  } | null;
};

export type GetMeetingRecentlyCompletedAssessmentsNewPageQueryQueryVariables =
  Exact<{
    meetingId: Scalars["Int"]["input"];
  }>;

export type GetMeetingRecentlyCompletedAssessmentsNewPageQueryQuery = {
  readonly __typename: "Query";
  readonly meeting?: {
    readonly __typename: "MeetingNode";
    readonly id: number;
    readonly recentlyCompletedAssessments: {
      readonly __typename: "AssessmentNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "AssessmentNodeEdge";
        readonly node?: {
          readonly __typename: "AssessmentNode";
          readonly submittedDatetime?: string | null;
          readonly id: number;
          readonly template: {
            readonly __typename: "AssessmentTemplateNode";
            readonly id: number;
            readonly assessmentType: AssessmentType;
          };
          readonly target?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
          } | null;
          readonly responder?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
          } | null;
          readonly complianceProgram?: {
            readonly __typename: "ComplianceProgramNode";
            readonly id: number;
            readonly title: string;
          } | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type GetMeetingRecognitionsNewPageQueryQueryVariables = Exact<{
  recognitionRecipients?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  createdByIds?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  fetchData: Scalars["Boolean"]["input"];
  after?: InputMaybe<Scalars["String"]["input"]>;
  first: Scalars["Int"]["input"];
}>;

export type GetMeetingRecognitionsNewPageQueryQuery = {
  readonly __typename: "Query";
  readonly artifacts?: {
    readonly __typename: "ArtifactConnection";
    readonly totalCount: number;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly hasNextPage: boolean;
      readonly endCursor?: string | null;
    };
    readonly edges?: ReadonlyArray<{
      readonly __typename: "ArtifactEdge";
      readonly node?:
        | {
            readonly __typename: "ActionItemArtifactNode";
            readonly id: number;
            readonly created: string;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "AsanaTaskArtifactNode";
            readonly id: number;
            readonly created: string;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "DecisionArtifactNode";
            readonly id: number;
            readonly created: string;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "DocumentArtifactNode";
            readonly id: number;
            readonly created: string;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "FeedbackArtifactNode";
            readonly id: number;
            readonly created: string;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "GithubIssuesArtifactNode";
            readonly id: number;
            readonly created: string;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "GoalArtifactNode";
            readonly id: number;
            readonly created: string;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "KPIArtifactNode";
            readonly id: number;
            readonly created: string;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "RatingArtifactNode";
            readonly id: number;
            readonly created: string;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "RecognitionArtifactNode";
            readonly id: number;
            readonly created: string;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly creator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
            readonly recipients?: {
              readonly __typename: "UserNodeConnection";
              readonly totalCount: number;
              readonly edges: ReadonlyArray<{
                readonly __typename: "UserNodeEdge";
                readonly node?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
              } | null>;
            } | null;
            readonly recognitionCoreValue?: {
              readonly __typename: "RecognitionCoreValueNode";
              readonly id: number;
              readonly title: string;
              readonly description: string;
              readonly emoji?: string | null;
              readonly image?: string | null;
            } | null;
          }
        | null;
    } | null>;
  } | null;
};

export type GetPreviousMeetingsQueryQueryVariables = Exact<{
  meetingGroupId: Scalars["Int"]["input"];
  beforeDate: Scalars["DateTime"]["input"];
  after?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
}>;

export type GetPreviousMeetingsQueryQuery = {
  readonly __typename: "Query";
  readonly meetings?: {
    readonly __typename: "MeetingNodeConnection";
    readonly totalCount: number;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor?: string | null;
      readonly hasNextPage: boolean;
    };
    readonly edges: ReadonlyArray<{
      readonly __typename: "MeetingNodeEdge";
      readonly node?: {
        readonly __typename: "MeetingNode";
        readonly id: number;
        readonly title?: string | null;
        readonly startDatetime?: string | null;
        readonly endDatetime?: string | null;
        readonly meetingGroupId: number;
      } | null;
    } | null>;
  } | null;
};

export type GetPreviousRecurringMeetingQueryQueryVariables = Exact<{
  beforeMeetingId: Scalars["Int"]["input"];
}>;

export type GetPreviousRecurringMeetingQueryQuery = {
  readonly __typename: "Query";
  readonly previousMeeting?: {
    readonly __typename: "MeetingNode";
    readonly id: number;
  } | null;
};

export type GetRecurringMeetingPaginationQueryQueryVariables = Exact<{
  meetingId: Scalars["Int"]["input"];
}>;

export type GetRecurringMeetingPaginationQueryQuery = {
  readonly __typename: "Query";
  readonly beforeMeeting?: {
    readonly __typename: "MeetingNode";
    readonly id: number;
    readonly meetingGroupId: number;
    readonly startDatetime?: string | null;
  } | null;
  readonly afterMeeting?: {
    readonly __typename: "MeetingNode";
    readonly id: number;
    readonly meetingGroupId: number;
    readonly startDatetime?: string | null;
  } | null;
};

export type IndividualNoteNodeFragmentFragment = {
  readonly __typename: "IndividualNoteNode";
  readonly id: number;
  readonly created: string;
  readonly hidden: boolean;
  readonly enabled: boolean;
  readonly notes?: any | null;
  readonly creator: { readonly __typename: "UserNode"; readonly id: number };
};

export type MeetingGroupGoalsNewPageFragmentFragment = {
  readonly __typename: "MeetingGroupNode";
  readonly id: number;
  readonly incompleteGoals?: {
    readonly __typename: "ArtifactConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "ArtifactEdge";
      readonly node?:
        | {
            readonly __typename: "ActionItemArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "AsanaTaskArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "DecisionArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "DocumentArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "FeedbackArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "GithubIssuesArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "GoalArtifactNode";
            readonly currentUserIsOwner?: boolean | null;
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly created: string;
            readonly state: GoalState;
            readonly status: GoalStatus;
            readonly scope: GoalScope;
            readonly goalVisibility: GoalVisibility;
            readonly dueDate?: string | null;
            readonly startDate?: string | null;
            readonly progress: number;
            readonly progressType: GoalProgressType;
            readonly startValue: number;
            readonly currentValue: number;
            readonly targetValue: number;
            readonly isStale: boolean;
            readonly ownerCount?: number | null;
            readonly contributorCount?: number | null;
            readonly owners?: {
              readonly __typename: "UserNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "UserNodeEdge";
                readonly node?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
              } | null>;
            } | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly firstOwner?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly avatar?: string | null;
              readonly name: string;
            } | null;
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canRead: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "KPIArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "RatingArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "RecognitionArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | null;
    } | null>;
  } | null;
  readonly draftGoals?: {
    readonly __typename: "ArtifactConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "ArtifactEdge";
      readonly node?:
        | {
            readonly __typename: "ActionItemArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "AsanaTaskArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "DecisionArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "DocumentArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "FeedbackArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "GithubIssuesArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "GoalArtifactNode";
            readonly currentUserIsOwner?: boolean | null;
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly created: string;
            readonly state: GoalState;
            readonly status: GoalStatus;
            readonly scope: GoalScope;
            readonly goalVisibility: GoalVisibility;
            readonly dueDate?: string | null;
            readonly startDate?: string | null;
            readonly progress: number;
            readonly progressType: GoalProgressType;
            readonly startValue: number;
            readonly currentValue: number;
            readonly targetValue: number;
            readonly isStale: boolean;
            readonly ownerCount?: number | null;
            readonly contributorCount?: number | null;
            readonly owners?: {
              readonly __typename: "UserNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "UserNodeEdge";
                readonly node?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
              } | null>;
            } | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly firstOwner?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly avatar?: string | null;
              readonly name: string;
            } | null;
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canRead: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "KPIArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "RatingArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "RecognitionArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | null;
    } | null>;
  } | null;
  readonly completeGoals?: {
    readonly __typename: "ArtifactConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "ArtifactEdge";
      readonly node?:
        | {
            readonly __typename: "ActionItemArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "AsanaTaskArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "DecisionArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "DocumentArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "FeedbackArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "GithubIssuesArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "GoalArtifactNode";
            readonly currentUserIsOwner?: boolean | null;
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly created: string;
            readonly state: GoalState;
            readonly status: GoalStatus;
            readonly scope: GoalScope;
            readonly goalVisibility: GoalVisibility;
            readonly dueDate?: string | null;
            readonly startDate?: string | null;
            readonly progress: number;
            readonly progressType: GoalProgressType;
            readonly startValue: number;
            readonly currentValue: number;
            readonly targetValue: number;
            readonly isStale: boolean;
            readonly ownerCount?: number | null;
            readonly contributorCount?: number | null;
            readonly owners?: {
              readonly __typename: "UserNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "UserNodeEdge";
                readonly node?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
              } | null>;
            } | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly firstOwner?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly avatar?: string | null;
              readonly name: string;
            } | null;
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canRead: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "KPIArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "RatingArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | {
            readonly __typename: "RecognitionArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
          }
        | null;
    } | null>;
  } | null;
};

export type MeetingParticipantFragmentFragment = {
  readonly __typename: "MeetingParticipantNode";
  readonly id?: string | null;
  readonly status?: AttendeeStatus | null;
  readonly participantEmail?: string | null;
  readonly role?: AttendeeRole | null;
  readonly user?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly avatar?: string | null;
    readonly email: string;
    readonly status?: UserStatus | null;
  } | null;
};

export type MeetingParticipantsNewPageFragmentFragment = {
  readonly __typename: "MeetingNode";
  readonly participants?: {
    readonly __typename: "MeetingParticipantConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "MeetingParticipantEdge";
      readonly node?: {
        readonly __typename: "MeetingParticipantNode";
        readonly id?: string | null;
        readonly status?: AttendeeStatus | null;
        readonly participantEmail?: string | null;
        readonly role?: AttendeeRole | null;
        readonly user?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly firstName: string;
          readonly lastName: string;
          readonly avatar?: string | null;
          readonly email: string;
          readonly status?: UserStatus | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type MeetingViewMeetingNodeNewPageFragmentFragment = {
  readonly __typename: "MeetingNode";
  readonly id: number;
  readonly title?: string | null;
  readonly startDatetime?: string | null;
  readonly startDate?: string | null;
  readonly endDatetime?: string | null;
  readonly externalUrl?: string | null;
  readonly videoConferenceType?: OnlineVideoConference | null;
  readonly videoConferenceUrl?: string | null;
  readonly allowExternalViewers?: boolean | null;
  readonly writableSharingUrl?: string | null;
  readonly websocketToken?: string | null;
  readonly hasPreviousMeetings?: boolean | null;
  readonly hasPreviousMeetingsWithTopics?: boolean | null;
  readonly ignored: boolean;
  readonly hasBotInMeeting?: boolean | null;
  readonly botIsRecording?: boolean | null;
  readonly botIsWaitingToJoinMeeting?: boolean | null;
  readonly finalizedAt?: string | null;
  readonly isFinalized: boolean;
  readonly isSubmitted: boolean;
  readonly hasIncompleteMandatoryTopics: boolean;
  readonly transcriptionStatus?: boolean | null;
  readonly created: string;
  readonly draft: boolean;
  readonly isRecurring: boolean;
  readonly hasExternalParticipants?: boolean | null;
  readonly canFinalize: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canDelete?: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  } | null;
  readonly canUpdate?: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  } | null;
  readonly finalizedBy?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
    readonly name: string;
    readonly effectivePricingTier?: PricingTier | null;
    readonly enableMeetingSummarization: boolean;
    readonly domains?: ReadonlyArray<string | null> | null;
  } | null;
  readonly organizer?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
  } | null;
  readonly subject?: {
    readonly __typename: "UserNode";
    readonly id: number;
  } | null;
  readonly creator?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly avatar?: string | null;
  } | null;
  readonly transcript?: {
    readonly __typename: "MeetingTranscriptNode";
    readonly id: number;
  } | null;
  readonly meetingGroup?: {
    readonly __typename: "MeetingGroupNode";
    readonly id: number;
    readonly title: string;
    readonly hasTemplate?: boolean | null;
    readonly allowAdminVisibility: boolean;
    readonly allowOrgVisibility: boolean;
    readonly allowManagementTreeVisibility: boolean;
    readonly isFormalOneonone: boolean;
    readonly transcribeMeetingsByDefault?: boolean | null;
    readonly addTranscriptionBotToMeetingsByDefault: boolean;
    readonly canUpdate?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
    } | null;
    readonly facilitator?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
      readonly avatar?: string | null;
    } | null;
    readonly organization?: {
      readonly __typename: "OrganizationNode";
      readonly id: number;
      readonly name: string;
    } | null;
    readonly syncCredentials?: {
      readonly __typename: "UserSyncCredentialsNode";
      readonly id: number;
      readonly hasCalendarWriteCredentials?: boolean | null;
      readonly provider?: SyncCredentialsTypeEnum | null;
    } | null;
    readonly topicTemplate?: {
      readonly __typename: "TopicTemplateNode";
      readonly id: number;
      readonly title: string;
      readonly globalTemplate: boolean;
      readonly publicTemplate: boolean;
      readonly meetingTemplate: boolean;
      readonly finalizePermissions: FinalizePermissions;
      readonly organization?: {
        readonly __typename: "OrganizationNode";
        readonly id: number;
      } | null;
    } | null;
  } | null;
  readonly topics?: {
    readonly __typename: "TopicNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "TopicNodeEdge";
      readonly node?: {
        readonly __typename: "TopicNode";
        readonly id: number;
        readonly title: string;
        readonly description?: string | null;
        readonly discussionNotes?: any | null;
        readonly created: string;
        readonly state?: TopicState | null;
        readonly eventChannelName?: string | null;
        readonly includesIndividualNotes: boolean;
        readonly includesIndividualNotesForCurrentUser: boolean;
        readonly includesSharedNotes: boolean;
        readonly isMandatory: boolean;
        readonly notesRequirement: NotesRequirement;
        readonly individualNotes?: {
          readonly __typename: "IndividualNoteNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "IndividualNoteNodeEdge";
            readonly node?: {
              readonly __typename: "IndividualNoteNode";
              readonly id: number;
              readonly created: string;
              readonly hidden: boolean;
              readonly enabled: boolean;
              readonly notes?: any | null;
              readonly creator: {
                readonly __typename: "UserNode";
                readonly id: number;
              };
            } | null;
          } | null>;
        } | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
        } | null;
        readonly assignee?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly avatar?: string | null;
          readonly name: string;
        } | null;
        readonly previousTopic?: {
          readonly __typename: "TopicNode";
          readonly id: number;
          readonly discussionNotes?: any | null;
        } | null;
        readonly linkedTemplateTopic?: {
          readonly __typename: "TopicNode";
          readonly id: number;
          readonly title: string;
          readonly discussionNotes?: any | null;
          readonly defaultSubjectNotes?: any | null;
          readonly defaultFacilitatorNotes?: any | null;
          readonly topicTemplate?: {
            readonly __typename: "TopicTemplateNode";
            readonly id: number;
            readonly title: string;
          } | null;
        } | null;
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly copiedFrom?: {
          readonly __typename: "TopicNode";
          readonly id: number;
          readonly title: string;
          readonly meeting?: {
            readonly __typename: "MeetingNode";
            readonly id: number;
            readonly title?: string | null;
            readonly startDatetime?: string | null;
            readonly meetingGroupId: number;
          } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
  readonly participants?: {
    readonly __typename: "MeetingParticipantConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "MeetingParticipantEdge";
      readonly node?: {
        readonly __typename: "MeetingParticipantNode";
        readonly id?: string | null;
        readonly status?: AttendeeStatus | null;
        readonly participantEmail?: string | null;
        readonly role?: AttendeeRole | null;
        readonly user?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly firstName: string;
          readonly lastName: string;
          readonly avatar?: string | null;
          readonly email: string;
          readonly status?: UserStatus | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type RemoveBotFromMeetingNewPageMutationMutationVariables = Exact<{
  meetingId: Scalars["Int"]["input"];
}>;

export type RemoveBotFromMeetingNewPageMutationMutation = {
  readonly __typename: "Mutation";
  readonly removeBotFromMeeting?: {
    readonly __typename: "RemoveBotFromMeetingMutation";
    readonly meeting?: {
      readonly __typename: "MeetingNode";
      readonly hasBotInMeeting?: boolean | null;
      readonly botIsWaitingToJoinMeeting?: boolean | null;
      readonly id: number;
    } | null;
  } | null;
};

export type ReorderTopicNewPageMutationMutationVariables = Exact<{
  afterTopicId?: InputMaybe<Scalars["Int"]["input"]>;
  beforeTopicId?: InputMaybe<Scalars["Int"]["input"]>;
  topicId: Scalars["Int"]["input"];
}>;

export type ReorderTopicNewPageMutationMutation = {
  readonly __typename: "Mutation";
  readonly reorderTopic?: {
    readonly __typename: "ReorderTopicMutation";
    readonly topic?: {
      readonly __typename: "TopicNode";
      readonly id: number;
    } | null;
    readonly afterTopic?: {
      readonly __typename: "TopicNode";
      readonly id: number;
    } | null;
    readonly beforeTopic?: {
      readonly __typename: "TopicNode";
      readonly id: number;
    } | null;
    readonly meeting?: {
      readonly __typename: "MeetingNode";
      readonly id: number;
    } | null;
  } | null;
};

export type EmailMeetingInvitesNewPageMutationMutationVariables = Exact<{
  meetingId: Scalars["Int"]["input"];
  recipientEmails:
    | ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type EmailMeetingInvitesNewPageMutationMutation = {
  readonly __typename: "Mutation";
  readonly emailMeetingInvites?: {
    readonly __typename: "EmailMeetingInvitesMutation";
    readonly meeting?: {
      readonly __typename: "MeetingNode";
      readonly id: number;
    } | null;
  } | null;
};

export type EmailMeetingNotesNewPageMutationMutationVariables = Exact<{
  meetingId: Scalars["Int"]["input"];
  recipientEmails:
    | ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type EmailMeetingNotesNewPageMutationMutation = {
  readonly __typename: "Mutation";
  readonly emailMeetingNotes?: {
    readonly __typename: "EmailMeetingNotesMutation";
    readonly meeting?: {
      readonly __typename: "MeetingNode";
      readonly id: number;
    } | null;
  } | null;
};

export type ToggleTopicIndividualNoteVisibilityNewPageMutationMutationVariables =
  Exact<{
    topicIds:
      | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
      | InputMaybe<Scalars["Int"]["input"]>;
    hidden?: InputMaybe<Scalars["Boolean"]["input"]>;
    enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  }>;

export type ToggleTopicIndividualNoteVisibilityNewPageMutationMutation = {
  readonly __typename: "Mutation";
  readonly toggleIndividualNoteVisibility?: {
    readonly __typename: "ToggleIndividualNoteVisibilityMutation";
    readonly topics?: {
      readonly __typename: "TopicNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "TopicNodeEdge";
        readonly node?: {
          readonly __typename: "TopicNode";
          readonly id: number;
          readonly includesIndividualNotesForCurrentUser: boolean;
          readonly includesSharedNotes: boolean;
          readonly individualNotes?: {
            readonly __typename: "IndividualNoteNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "IndividualNoteNodeEdge";
              readonly node?: {
                readonly __typename: "IndividualNoteNode";
                readonly id: number;
                readonly created: string;
                readonly hidden: boolean;
                readonly enabled: boolean;
                readonly notes?: any | null;
                readonly creator: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                };
              } | null;
            } | null>;
          } | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type TopicIndividualNotesFragmentFragment = {
  readonly __typename: "IndividualNoteNode";
  readonly id: number;
  readonly created: string;
  readonly hidden: boolean;
  readonly enabled: boolean;
  readonly notes?: any | null;
  readonly creator: { readonly __typename: "UserNode"; readonly id: number };
};

export type TopicNodeNewPageFragmentFragment = {
  readonly __typename: "TopicNode";
  readonly id: number;
  readonly title: string;
  readonly description?: string | null;
  readonly discussionNotes?: any | null;
  readonly created: string;
  readonly state?: TopicState | null;
  readonly eventChannelName?: string | null;
  readonly includesIndividualNotes: boolean;
  readonly includesIndividualNotesForCurrentUser: boolean;
  readonly includesSharedNotes: boolean;
  readonly isMandatory: boolean;
  readonly notesRequirement: NotesRequirement;
  readonly individualNotes?: {
    readonly __typename: "IndividualNoteNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "IndividualNoteNodeEdge";
      readonly node?: {
        readonly __typename: "IndividualNoteNode";
        readonly id: number;
        readonly created: string;
        readonly hidden: boolean;
        readonly enabled: boolean;
        readonly notes?: any | null;
        readonly creator: {
          readonly __typename: "UserNode";
          readonly id: number;
        };
      } | null;
    } | null>;
  } | null;
  readonly creator?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
  } | null;
  readonly assignee?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly avatar?: string | null;
    readonly name: string;
  } | null;
  readonly previousTopic?: {
    readonly __typename: "TopicNode";
    readonly id: number;
    readonly discussionNotes?: any | null;
  } | null;
  readonly linkedTemplateTopic?: {
    readonly __typename: "TopicNode";
    readonly id: number;
    readonly title: string;
    readonly discussionNotes?: any | null;
    readonly defaultSubjectNotes?: any | null;
    readonly defaultFacilitatorNotes?: any | null;
    readonly topicTemplate?: {
      readonly __typename: "TopicTemplateNode";
      readonly id: number;
      readonly title: string;
    } | null;
  } | null;
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly copiedFrom?: {
    readonly __typename: "TopicNode";
    readonly id: number;
    readonly title: string;
    readonly meeting?: {
      readonly __typename: "MeetingNode";
      readonly id: number;
      readonly title?: string | null;
      readonly startDatetime?: string | null;
      readonly meetingGroupId: number;
    } | null;
  } | null;
};

export type UpdateMeetingGroupNewPageMutationMutationVariables = Exact<{
  meetingGroupId: Scalars["Int"]["input"];
  facilitatorId?: InputMaybe<Scalars["Int"]["input"]>;
  allowManagementTreeVisibility?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowAdminVisibility?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowOrgVisibility?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFormalOneonone?: InputMaybe<Scalars["Boolean"]["input"]>;
  transcribeMeetingsByDefault?: InputMaybe<Scalars["Boolean"]["input"]>;
  addTranscriptionBotToMeetingsByDefault?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
}>;

export type UpdateMeetingGroupNewPageMutationMutation = {
  readonly __typename: "Mutation";
  readonly updateMeetingGroup?: {
    readonly __typename: "UpdateMeetingGroupMutation";
    readonly meetingGroup?: {
      readonly __typename: "MeetingGroupNode";
      readonly id: number;
      readonly allowAdminVisibility: boolean;
      readonly allowManagementTreeVisibility: boolean;
      readonly allowOrgVisibility: boolean;
      readonly isFormalOneonone: boolean;
      readonly transcribeMeetingsByDefault?: boolean | null;
      readonly addTranscriptionBotToMeetingsByDefault: boolean;
      readonly facilitator?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly avatar?: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateMeetingNewPageMutationMutationVariables = Exact<{
  meetingId: Scalars["Int"]["input"];
  allowExternalViewers?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type UpdateMeetingNewPageMutationMutation = {
  readonly __typename: "Mutation";
  readonly updateMeeting?: {
    readonly __typename: "UpdateMeetingMutation";
    readonly meeting?: {
      readonly __typename: "MeetingNode";
      readonly id: number;
      readonly allowExternalViewers?: boolean | null;
      readonly writableSharingUrl?: string | null;
    } | null;
  } | null;
};

export type CreateOrUpdateSlackNotificationsForMeetingGroupMutationMutationVariables =
  Exact<{
    meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
    notificationId?: InputMaybe<Scalars["Int"]["input"]>;
    channel?: InputMaybe<Scalars["String"]["input"]>;
  }>;

export type CreateOrUpdateSlackNotificationsForMeetingGroupMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateSlackNotificationsForMeetingGroup?: {
    readonly __typename: "CreateOrUpdateSlackNotificationsForMeetingGroup";
    readonly meetingGroup?: {
      readonly __typename: "MeetingGroupNode";
      readonly id: number;
      readonly slackNotifications?: {
        readonly __typename: "SlackNotificationConnectionNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "SlackNotificationConnectionNodeEdge";
          readonly node?: {
            readonly __typename: "SlackNotificationConnectionNode";
            readonly id: number;
            readonly channel: string;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type CreateOrUpdateTemplateSeriesForMeetingMutationMutationVariables =
  Exact<{
    meetingGroupId: Scalars["Int"]["input"];
    templateId?: InputMaybe<Scalars["Int"]["input"]>;
    templateSeriesId?: InputMaybe<Scalars["Int"]["input"]>;
    topics:
      | ReadonlyArray<InputMaybe<TemplateTopicInput>>
      | InputMaybe<TemplateTopicInput>;
  }>;

export type CreateOrUpdateTemplateSeriesForMeetingMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateTemplateSeriesForMeeting?: {
    readonly __typename: "CreateOrUpdateTemplateSeriesForMeetingMutation";
    readonly templateSeries?: {
      readonly __typename: "TemplateSeriesNode";
      readonly id: number;
      readonly title: string;
      readonly description: string;
      readonly topicTemplates?: {
        readonly __typename: "TemplateSeriesToTopicTemplatesNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "TemplateSeriesToTopicTemplatesNodeEdge";
          readonly node?: {
            readonly __typename: "TemplateSeriesToTopicTemplatesNode";
            readonly id: number;
            readonly topicTemplate?: {
              readonly __typename: "TopicTemplateNode";
              readonly id: number;
              readonly title: string;
              readonly description: string;
              readonly publicTemplate: boolean;
              readonly topics?: {
                readonly __typename: "TopicNodeConnection";
                readonly edges: ReadonlyArray<{
                  readonly __typename: "TopicNodeEdge";
                  readonly node?: {
                    readonly __typename: "TopicNode";
                    readonly id: number;
                    readonly title: string;
                    readonly description?: string | null;
                    readonly discussionNotes?: any | null;
                  } | null;
                } | null>;
              } | null;
            } | null;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type CreateOrUpdateMeetingTopicTemplateMutationMutationVariables =
  Exact<{
    title: Scalars["String"]["input"];
    organization: Scalars["Int"]["input"];
    topics:
      | ReadonlyArray<InputMaybe<TemplateTopicInput>>
      | InputMaybe<TemplateTopicInput>;
  }>;

export type CreateOrUpdateMeetingTopicTemplateMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateTopicTemplate?: {
    readonly __typename: "CreateOrUpdateTopicTemplateMutation";
    readonly topicTemplate?: {
      readonly __typename: "TopicTemplateNode";
      readonly id: number;
      readonly title: string;
      readonly description: string;
      readonly publicTemplate: boolean;
      readonly topics?: {
        readonly __typename: "TopicNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "TopicNodeEdge";
          readonly node?: {
            readonly __typename: "TopicNode";
            readonly id: number;
            readonly title: string;
            readonly description?: string | null;
            readonly discussionNotes?: any | null;
            readonly includesSharedNotes: boolean;
            readonly includesIndividualNotes: boolean;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type DeleteSlackNotificationsForMeetingGroupMutationVariables = Exact<{
  notificationId: Scalars["Int"]["input"];
}>;

export type DeleteSlackNotificationsForMeetingGroupMutation = {
  readonly __typename: "Mutation";
  readonly deleteSlackNotificationsForMeetingGroup?: {
    readonly __typename: "DeleteSlackNotificationsForMeetingGroup";
    readonly meetingGroup?: {
      readonly __typename: "MeetingGroupNode";
      readonly id: number;
      readonly slackNotifications?: {
        readonly __typename: "SlackNotificationConnectionNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "SlackNotificationConnectionNodeEdge";
          readonly node?: {
            readonly __typename: "SlackNotificationConnectionNode";
            readonly id: number;
            readonly channel: string;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type SlackNotificationFragmentFragment = {
  readonly __typename: "SlackNotificationConnectionNode";
  readonly id: number;
  readonly channel: string;
};

export type SlackChannelFragmentFragment = {
  readonly __typename: "SlackChannelNode";
  readonly id?: string | null;
  readonly name?: string | null;
};

export type GetMeetingGroupSettingsQueryQueryVariables = Exact<{
  meetingGroupId: Scalars["Int"]["input"];
}>;

export type GetMeetingGroupSettingsQueryQuery = {
  readonly __typename: "Query";
  readonly meetingGroup?: {
    readonly __typename: "MeetingGroupNode";
    readonly id: number;
    readonly transcribeMeetingsByDefault?: boolean | null;
    readonly title: string;
    readonly hasTemplate?: boolean | null;
    readonly allowAdminVisibility: boolean;
    readonly allowOrgVisibility: boolean;
    readonly allowManagementTreeVisibility: boolean;
    readonly isFormalOneonone: boolean;
    readonly addTranscriptionBotToMeetingsByDefault: boolean;
    readonly canUpdate?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
    } | null;
    readonly slackNotifications?: {
      readonly __typename: "SlackNotificationConnectionNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "SlackNotificationConnectionNodeEdge";
        readonly node?: {
          readonly __typename: "SlackNotificationConnectionNode";
          readonly id: number;
          readonly channel: string;
        } | null;
      } | null>;
    } | null;
    readonly organization?: {
      readonly __typename: "OrganizationNode";
      readonly id: number;
      readonly hasSlackConnection?: boolean | null;
      readonly name: string;
      readonly publicSlackChannels?: {
        readonly __typename: "SlackChannelConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "SlackChannelEdge";
          readonly node?: {
            readonly __typename: "SlackChannelNode";
            readonly id?: string | null;
            readonly name?: string | null;
          } | null;
        } | null>;
      } | null;
    } | null;
    readonly facilitator?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
      readonly avatar?: string | null;
    } | null;
    readonly syncCredentials?: {
      readonly __typename: "UserSyncCredentialsNode";
      readonly id: number;
      readonly hasCalendarWriteCredentials?: boolean | null;
      readonly provider?: SyncCredentialsTypeEnum | null;
    } | null;
    readonly topicTemplate?: {
      readonly __typename: "TopicTemplateNode";
      readonly id: number;
      readonly title: string;
      readonly globalTemplate: boolean;
      readonly publicTemplate: boolean;
      readonly meetingTemplate: boolean;
      readonly finalizePermissions: FinalizePermissions;
      readonly organization?: {
        readonly __typename: "OrganizationNode";
        readonly id: number;
      } | null;
    } | null;
  } | null;
};

export type GetTemplateSeriesQueryQueryVariables = Exact<{
  meetingGroupId: Scalars["Int"]["input"];
}>;

export type GetTemplateSeriesQueryQuery = {
  readonly __typename: "Query";
  readonly meetingGroup?: {
    readonly __typename: "MeetingGroupNode";
    readonly id: number;
    readonly isFormalOneonone: boolean;
    readonly allowAdminVisibility: boolean;
    readonly templateSeries?: {
      readonly __typename: "TemplateSeriesNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "TemplateSeriesNodeEdge";
        readonly node?: {
          readonly __typename: "TemplateSeriesNode";
          readonly id: number;
          readonly topicTemplates?: {
            readonly __typename: "TemplateSeriesToTopicTemplatesNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "TemplateSeriesToTopicTemplatesNodeEdge";
              readonly node?: {
                readonly __typename: "TemplateSeriesToTopicTemplatesNode";
                readonly id: number;
                readonly topicTemplate?: {
                  readonly __typename: "TopicTemplateNode";
                  readonly id: number;
                  readonly title: string;
                  readonly description: string;
                  readonly globalTemplate: boolean;
                  readonly publicTemplate: boolean;
                  readonly oneononeTemplate: boolean;
                  readonly finalizePermissions: FinalizePermissions;
                  readonly managerInstructions: string;
                  readonly reportInstructions: string;
                  readonly allowAdminVisibility: boolean;
                  readonly allowOrgVisibility: boolean;
                  readonly allowManagementTreeVisibility: boolean;
                  readonly organization?: {
                    readonly __typename: "OrganizationNode";
                    readonly id: number;
                  } | null;
                  readonly canDelete: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                    readonly reason: string;
                  };
                  readonly canUpdate: {
                    readonly __typename: "PermissionNode";
                    readonly permission: boolean;
                    readonly reason: string;
                  };
                  readonly creator?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                  readonly categoriesList: ReadonlyArray<{
                    readonly __typename: "TemplateCategoryNode";
                    readonly id: number;
                    readonly title: string;
                    readonly ordinal?: number | null;
                    readonly organization?: {
                      readonly __typename: "OrganizationNode";
                      readonly id: number;
                    } | null;
                    readonly canUpdate?: {
                      readonly __typename: "PermissionNode";
                      readonly permission: boolean;
                    } | null;
                    readonly canDelete?: {
                      readonly __typename: "PermissionNode";
                      readonly permission: boolean;
                    } | null;
                  } | null>;
                  readonly topicsList: ReadonlyArray<{
                    readonly __typename: "TopicNode";
                    readonly id: number;
                    readonly title: string;
                    readonly description?: string | null;
                    readonly discussionNotes?: any | null;
                    readonly defaultSubjectNotes?: any | null;
                    readonly defaultFacilitatorNotes?: any | null;
                    readonly includesIndividualNotes: boolean;
                    readonly includesSharedNotes: boolean;
                    readonly isMandatory: boolean;
                    readonly notesRequirement: NotesRequirement;
                  } | null>;
                } | null;
              } | null;
            } | null>;
          } | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type RemoveTemplateSeriesFromMeetingMutationMutationVariables = Exact<{
  meetingGroupId: Scalars["Int"]["input"];
}>;

export type RemoveTemplateSeriesFromMeetingMutationMutation = {
  readonly __typename: "Mutation";
  readonly removeTemplateSeriesFromMeeting?: {
    readonly __typename: "RemoveTemplateSeriesFromMeetingMutation";
    readonly meetingGroup?: {
      readonly __typename: "MeetingGroupNode";
      readonly id: number;
      readonly templateSeries?: {
        readonly __typename: "TemplateSeriesNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "TemplateSeriesNodeEdge";
          readonly node?: {
            readonly __typename: "TemplateSeriesNode";
            readonly id: number;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type DeleteMeetingTranscriptMessagesMutationVariables = Exact<{
  meetingId: Scalars["Int"]["input"];
  removeMessageIds?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
}>;

export type DeleteMeetingTranscriptMessagesMutation = {
  readonly __typename: "Mutation";
  readonly updateMeetingTranscript?: {
    readonly __typename: "UpdateMeetingTranscriptMutation";
    readonly meeting?: {
      readonly __typename: "MeetingNode";
      readonly id: number;
      readonly transcript?: {
        readonly __typename: "MeetingTranscriptNode";
        readonly userMapping?: any | null;
        readonly orderedTranscriptData?: ReadonlyArray<{
          readonly __typename: "MeetingTranscriptMessageNode";
          readonly text: string;
          readonly speaker?: string | null;
          readonly created?: number | null;
          readonly messageId?: string | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type GetMeetingTranscriptQueryQueryVariables = Exact<{
  meetingId: Scalars["Int"]["input"];
}>;

export type GetMeetingTranscriptQueryQuery = {
  readonly __typename: "Query";
  readonly meeting?: {
    readonly __typename: "MeetingNode";
    readonly id: number;
    readonly endDatetime?: string | null;
    readonly hasBotInMeeting?: boolean | null;
    readonly botIsRecording?: boolean | null;
    readonly botIsWaitingToJoinMeeting?: boolean | null;
    readonly videoConferenceType?: OnlineVideoConference | null;
    readonly hasTranscript?: boolean | null;
    readonly notesSummary?: string | null;
    readonly organization?: {
      readonly __typename: "OrganizationNode";
      readonly id: number;
      readonly name: string;
      readonly effectivePricingTier?: PricingTier | null;
      readonly enableMeetingSummarization: boolean;
    } | null;
    readonly meetingGroup?: {
      readonly __typename: "MeetingGroupNode";
      readonly id: number;
      readonly addTranscriptionBotToMeetingsByDefault: boolean;
    } | null;
    readonly artifacts?: {
      readonly __typename: "ArtifactConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "ArtifactEdge";
        readonly node?:
          | {
              readonly __typename: "ActionItemArtifactNode";
              readonly isComplete?: boolean | null;
              readonly actionItemState?: number | null;
              readonly dueDate?: string | null;
              readonly id: number;
              readonly title?: string | null;
              readonly artifactType: ArtifactType;
              readonly created: string;
              readonly assignee?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly avatar?: string | null;
                readonly name: string;
              } | null;
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly canUpdate: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
            }
          | {
              readonly __typename: "AsanaTaskArtifactNode";
              readonly id: number;
              readonly title?: string | null;
              readonly artifactType: ArtifactType;
              readonly created: string;
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly canUpdate: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
            }
          | {
              readonly __typename: "DecisionArtifactNode";
              readonly isDraft?: boolean | null;
              readonly decision: string;
              readonly decisionState?: DecisionState | null;
              readonly id: number;
              readonly title?: string | null;
              readonly artifactType: ArtifactType;
              readonly created: string;
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly canUpdate: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
            }
          | {
              readonly __typename: "DocumentArtifactNode";
              readonly id: number;
              readonly title?: string | null;
              readonly artifactType: ArtifactType;
              readonly created: string;
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly canUpdate: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
            }
          | {
              readonly __typename: "FeedbackArtifactNode";
              readonly id: number;
              readonly title?: string | null;
              readonly artifactType: ArtifactType;
              readonly created: string;
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly canUpdate: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
            }
          | {
              readonly __typename: "GithubIssuesArtifactNode";
              readonly id: number;
              readonly title?: string | null;
              readonly artifactType: ArtifactType;
              readonly created: string;
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly canUpdate: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
            }
          | {
              readonly __typename: "GoalArtifactNode";
              readonly id: number;
              readonly title?: string | null;
              readonly artifactType: ArtifactType;
              readonly created: string;
              readonly state: GoalState;
              readonly status: GoalStatus;
              readonly scope: GoalScope;
              readonly goalVisibility: GoalVisibility;
              readonly dueDate?: string | null;
              readonly startDate?: string | null;
              readonly progress: number;
              readonly progressType: GoalProgressType;
              readonly startValue: number;
              readonly currentValue: number;
              readonly targetValue: number;
              readonly isStale: boolean;
              readonly ownerCount?: number | null;
              readonly contributorCount?: number | null;
              readonly contributors?: {
                readonly __typename: "UserNodeConnection";
                readonly edges: ReadonlyArray<{
                  readonly __typename: "UserNodeEdge";
                  readonly node?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                } | null>;
              } | null;
              readonly activities: {
                readonly __typename: "ArtifactActivityNodeConnection";
                readonly edges: ReadonlyArray<{
                  readonly __typename: "ArtifactActivityNodeEdge";
                  readonly node?: {
                    readonly __typename: "ArtifactActivityNode";
                    readonly created: string;
                  } | null;
                } | null>;
              };
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly canUpdate: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly firstOwner?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly avatar?: string | null;
                readonly name: string;
              } | null;
              readonly canRead: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
            }
          | {
              readonly __typename: "KPIArtifactNode";
              readonly id: number;
              readonly title?: string | null;
              readonly artifactType: ArtifactType;
              readonly created: string;
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly canUpdate: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
            }
          | {
              readonly __typename: "RatingArtifactNode";
              readonly id: number;
              readonly title?: string | null;
              readonly artifactType: ArtifactType;
              readonly created: string;
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly canUpdate: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
            }
          | {
              readonly __typename: "RecognitionArtifactNode";
              readonly id: number;
              readonly title?: string | null;
              readonly artifactType: ArtifactType;
              readonly created: string;
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly canUpdate: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly creator?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
              readonly recipients?: {
                readonly __typename: "UserNodeConnection";
                readonly totalCount: number;
                readonly edges: ReadonlyArray<{
                  readonly __typename: "UserNodeEdge";
                  readonly node?: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                    readonly name: string;
                    readonly avatar?: string | null;
                  } | null;
                } | null>;
              } | null;
              readonly recognitionCoreValue?: {
                readonly __typename: "RecognitionCoreValueNode";
                readonly id: number;
                readonly title: string;
                readonly description: string;
                readonly emoji?: string | null;
                readonly image?: string | null;
              } | null;
            }
          | null;
      } | null>;
    } | null;
    readonly transcript?: {
      readonly __typename: "MeetingTranscriptNode";
      readonly id: number;
      readonly summary?: string | null;
      readonly mediumSummary?: string | null;
      readonly longSummary?: string | null;
      readonly highlights?: string | null;
      readonly orderedTranscriptData?: ReadonlyArray<{
        readonly __typename: "MeetingTranscriptMessageNode";
        readonly text: string;
        readonly speaker?: string | null;
        readonly created?: number | null;
        readonly messageId?: string | null;
      } | null> | null;
      readonly suggestedArtifacts?: {
        readonly __typename: "SuggestedArtifactNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "SuggestedArtifactNodeEdge";
          readonly node?: {
            readonly __typename: "SuggestedArtifactNode";
            readonly id: number;
            readonly title: string;
            readonly description?: string | null;
            readonly hidden: boolean;
            readonly artifactType?: ArtifactType | null;
            readonly suggestedAssignee?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
          } | null;
        } | null>;
      } | null;
      readonly highlightList?: {
        readonly __typename: "TranscriptHighlightConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "TranscriptHighlightEdge";
          readonly node?: {
            readonly __typename: "TranscriptHighlightNode";
            readonly id?: string | null;
            readonly highlight?: string | null;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type HideSuggestedArtifactMutationMutationVariables = Exact<{
  suggestedArtifactId: Scalars["Int"]["input"];
}>;

export type HideSuggestedArtifactMutationMutation = {
  readonly __typename: "Mutation";
  readonly updateSuggestedArtifact?: {
    readonly __typename: "UpdateSuggestedArtifactMutation";
    readonly suggestedArtifact?: {
      readonly __typename: "SuggestedArtifactNode";
      readonly id: number;
      readonly hidden: boolean;
    } | null;
  } | null;
};

export type GetMeetingOverviewMeetingsQueryQueryVariables = Exact<{
  forUserId: Scalars["Int"]["input"];
  dateRangeStart?: InputMaybe<Scalars["DateTime"]["input"]>;
  dateRangeEnd?: InputMaybe<Scalars["DateTime"]["input"]>;
  isOneonone?: InputMaybe<Scalars["Boolean"]["input"]>;
  searchQuery?: InputMaybe<Scalars["String"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetMeetingOverviewMeetingsQueryQuery = {
  readonly __typename: "Query";
  readonly calendar?: {
    readonly __typename: "BasicMeetingNodeConnection";
    readonly totalCount: number;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly startCursor?: string | null;
      readonly endCursor?: string | null;
      readonly hasNextPage: boolean;
    };
    readonly edges: ReadonlyArray<{
      readonly __typename: "BasicMeetingNodeEdge";
      readonly node?: {
        readonly __typename: "BasicMeetingNode";
        readonly id: number;
        readonly meeting?: {
          readonly __typename: "MeetingNode";
          readonly id: number;
          readonly title?: string | null;
          readonly description?: string | null;
          readonly draft: boolean;
          readonly meetingGroupId: number;
          readonly ignored: boolean;
          readonly videoConferenceType?: OnlineVideoConference | null;
          readonly videoConferenceUrl?: string | null;
          readonly startDatetime?: string | null;
          readonly endDatetime?: string | null;
          readonly isRecurring: boolean;
          readonly canRead?: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
            readonly reason: string;
          } | null;
          readonly canUpdate?: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
            readonly reason: string;
          } | null;
          readonly canDelete?: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
            readonly reason: string;
          } | null;
          readonly organizer?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
          } | null;
          readonly meetingGroup?: {
            readonly __typename: "MeetingGroupNode";
            readonly id: number;
            readonly title: string;
            readonly description?: string | null;
            readonly isFormalOneonone: boolean;
            readonly onlineConference: OnlineConference;
            readonly facilitator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
            readonly topicTemplate?: {
              readonly __typename: "TopicTemplateNode";
              readonly id: number;
            } | null;
            readonly syncCredentials?: {
              readonly __typename: "UserSyncCredentialsNode";
              readonly id: number;
              readonly credentialsUid?: string | null;
            } | null;
          } | null;
          readonly creator?: {
            readonly __typename: "UserNode";
            readonly id: number;
          } | null;
          readonly participants?: {
            readonly __typename: "MeetingParticipantConnection";
            readonly totalCount: number;
            readonly edges: ReadonlyArray<{
              readonly __typename: "MeetingParticipantEdge";
              readonly node?: {
                readonly __typename: "MeetingParticipantNode";
                readonly id?: string | null;
                readonly status?: AttendeeStatus | null;
                readonly participantEmail?: string | null;
                readonly role?: AttendeeRole | null;
                readonly user?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly firstName: string;
                  readonly lastName: string;
                  readonly avatar?: string | null;
                  readonly email: string;
                  readonly status?: UserStatus | null;
                } | null;
              } | null;
            } | null>;
          } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
  readonly unscheduledMeetings?: {
    readonly __typename: "BasicMeetingNodeConnection";
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly __typename: "BasicMeetingNodeEdge";
      readonly node?: {
        readonly __typename: "BasicMeetingNode";
        readonly id: number;
        readonly meeting?: {
          readonly __typename: "MeetingNode";
          readonly id: number;
          readonly title?: string | null;
          readonly description?: string | null;
          readonly draft: boolean;
          readonly meetingGroupId: number;
          readonly ignored: boolean;
          readonly videoConferenceType?: OnlineVideoConference | null;
          readonly videoConferenceUrl?: string | null;
          readonly startDatetime?: string | null;
          readonly endDatetime?: string | null;
          readonly isRecurring: boolean;
          readonly canRead?: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
            readonly reason: string;
          } | null;
          readonly canUpdate?: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
            readonly reason: string;
          } | null;
          readonly canDelete?: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
            readonly reason: string;
          } | null;
          readonly organizer?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
          } | null;
          readonly meetingGroup?: {
            readonly __typename: "MeetingGroupNode";
            readonly id: number;
            readonly title: string;
            readonly description?: string | null;
            readonly isFormalOneonone: boolean;
            readonly onlineConference: OnlineConference;
            readonly facilitator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
            readonly topicTemplate?: {
              readonly __typename: "TopicTemplateNode";
              readonly id: number;
            } | null;
            readonly syncCredentials?: {
              readonly __typename: "UserSyncCredentialsNode";
              readonly id: number;
              readonly credentialsUid?: string | null;
            } | null;
          } | null;
          readonly creator?: {
            readonly __typename: "UserNode";
            readonly id: number;
          } | null;
          readonly participants?: {
            readonly __typename: "MeetingParticipantConnection";
            readonly totalCount: number;
            readonly edges: ReadonlyArray<{
              readonly __typename: "MeetingParticipantEdge";
              readonly node?: {
                readonly __typename: "MeetingParticipantNode";
                readonly id?: string | null;
                readonly status?: AttendeeStatus | null;
                readonly participantEmail?: string | null;
                readonly role?: AttendeeRole | null;
                readonly user?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly firstName: string;
                  readonly lastName: string;
                  readonly avatar?: string | null;
                  readonly email: string;
                  readonly status?: UserStatus | null;
                } | null;
              } | null;
            } | null>;
          } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type MeetingsOverviewMeetingFragment = {
  readonly __typename: "MeetingNode";
  readonly id: number;
  readonly title?: string | null;
  readonly description?: string | null;
  readonly draft: boolean;
  readonly meetingGroupId: number;
  readonly ignored: boolean;
  readonly videoConferenceType?: OnlineVideoConference | null;
  readonly videoConferenceUrl?: string | null;
  readonly startDatetime?: string | null;
  readonly endDatetime?: string | null;
  readonly isRecurring: boolean;
  readonly canRead?: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
    readonly reason: string;
  } | null;
  readonly canUpdate?: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
    readonly reason: string;
  } | null;
  readonly canDelete?: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
    readonly reason: string;
  } | null;
  readonly organizer?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
  } | null;
  readonly meetingGroup?: {
    readonly __typename: "MeetingGroupNode";
    readonly id: number;
    readonly title: string;
    readonly description?: string | null;
    readonly isFormalOneonone: boolean;
    readonly onlineConference: OnlineConference;
    readonly facilitator?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
      readonly avatar?: string | null;
    } | null;
    readonly topicTemplate?: {
      readonly __typename: "TopicTemplateNode";
      readonly id: number;
    } | null;
    readonly syncCredentials?: {
      readonly __typename: "UserSyncCredentialsNode";
      readonly id: number;
      readonly credentialsUid?: string | null;
    } | null;
  } | null;
  readonly creator?: {
    readonly __typename: "UserNode";
    readonly id: number;
  } | null;
  readonly participants?: {
    readonly __typename: "MeetingParticipantConnection";
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly __typename: "MeetingParticipantEdge";
      readonly node?: {
        readonly __typename: "MeetingParticipantNode";
        readonly id?: string | null;
        readonly status?: AttendeeStatus | null;
        readonly participantEmail?: string | null;
        readonly role?: AttendeeRole | null;
        readonly user?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly firstName: string;
          readonly lastName: string;
          readonly avatar?: string | null;
          readonly email: string;
          readonly status?: UserStatus | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type AcceptInvitationMutationMutationVariables = Exact<{
  invitationId: Scalars["Int"]["input"];
}>;

export type AcceptInvitationMutationMutation = {
  readonly __typename: "Mutation";
  readonly acceptInvitation?: {
    readonly __typename: "AcceptInvitationMutation";
    readonly user?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly syncCredentials?: {
        readonly __typename: "UserSyncCredentialsNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "UserSyncCredentialsNodeEdge";
          readonly node?: {
            readonly __typename: "UserSyncCredentialsNode";
            readonly id: number;
            readonly provider?: SyncCredentialsTypeEnum | null;
            readonly credentialsUid?: string | null;
            readonly hasValidCalendarCredentials?: boolean | null;
            readonly hasCalendarWriteCredentials?: boolean | null;
            readonly organization?: {
              readonly __typename: "OrganizationNode";
              readonly id: number;
              readonly domains?: ReadonlyArray<string | null> | null;
              readonly personal?: boolean | null;
            } | null;
          } | null;
        } | null>;
      } | null;
      readonly organizations: {
        readonly __typename: "OrganizationNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "OrganizationNodeEdge";
          readonly node?: {
            readonly __typename: "OrganizationNode";
            readonly id: number;
            readonly name: string;
            readonly personal?: boolean | null;
            readonly domains?: ReadonlyArray<string | null> | null;
            readonly members: {
              readonly __typename: "UserMemberNodeConnection";
              readonly totalCount: number;
            };
          } | null;
        } | null>;
      };
      readonly invitations?: {
        readonly __typename: "InvitationNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "InvitationNodeEdge";
          readonly node?: {
            readonly __typename: "InvitationNode";
            readonly id: number;
            readonly organizationId?: number | null;
            readonly organizationName?: string | null;
            readonly organizationMemberCount?: number | null;
            readonly status?: InvitationStatus | null;
            readonly inviteeEmail: string;
          } | null;
        } | null>;
      } | null;
    } | null;
    readonly invitation?: {
      readonly __typename: "InvitationNode";
      readonly id: number;
      readonly organizationId?: number | null;
      readonly organizationName?: string | null;
      readonly organizationMemberCount?: number | null;
      readonly status?: InvitationStatus | null;
      readonly inviteeEmail: string;
    } | null;
  } | null;
};

export type CreateOrganizationMutationMutationVariables = Exact<{
  name: Scalars["String"]["input"];
  membershipSetting: OrganizationMembershipSetting;
  associatedDomainName?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type CreateOrganizationMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateOrganization?: {
    readonly __typename: "CreateOrUpdateOrganizationMutation";
    readonly organization?: {
      readonly __typename: "OrganizationNode";
      readonly id: number;
      readonly name: string;
      readonly personal?: boolean | null;
      readonly domains?: ReadonlyArray<string | null> | null;
      readonly members: {
        readonly __typename: "UserMemberNodeConnection";
        readonly totalCount: number;
      };
    } | null;
  } | null;
};

export type GetInvitationQueryQueryVariables = Exact<{
  inviteKey: Scalars["String"]["input"];
}>;

export type GetInvitationQueryQuery = {
  readonly __typename: "Query";
  readonly invitation?: {
    readonly __typename: "InvitationNode";
    readonly id: number;
    readonly organizationId?: number | null;
    readonly organizationName?: string | null;
    readonly organizationMemberCount?: number | null;
    readonly status?: InvitationStatus | null;
    readonly inviteeEmail: string;
  } | null;
};

export type GetLoggedInUserOnboardingQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetLoggedInUserOnboardingQueryQuery = {
  readonly __typename: "Query";
  readonly loggedIn?: boolean | null;
  readonly me?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly email: string;
    readonly emails: ReadonlyArray<string>;
    readonly emailsWithNonFreeDomains?: ReadonlyArray<string | null> | null;
    readonly name: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly avatar?: string | null;
    readonly syncCredentials?: {
      readonly __typename: "UserSyncCredentialsNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "UserSyncCredentialsNodeEdge";
        readonly node?: {
          readonly __typename: "UserSyncCredentialsNode";
          readonly id: number;
          readonly provider?: SyncCredentialsTypeEnum | null;
          readonly credentialsUid?: string | null;
          readonly hasValidCalendarCredentials?: boolean | null;
          readonly hasCalendarWriteCredentials?: boolean | null;
          readonly organization?: {
            readonly __typename: "OrganizationNode";
            readonly id: number;
            readonly domains?: ReadonlyArray<string | null> | null;
            readonly personal?: boolean | null;
          } | null;
        } | null;
      } | null>;
    } | null;
    readonly organizations: {
      readonly __typename: "OrganizationNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "OrganizationNodeEdge";
        readonly node?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
          readonly name: string;
          readonly personal?: boolean | null;
          readonly domains?: ReadonlyArray<string | null> | null;
          readonly members: {
            readonly __typename: "UserMemberNodeConnection";
            readonly totalCount: number;
          };
        } | null;
      } | null>;
    };
  } | null;
};

export type GetOrganizationQueryQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
}>;

export type GetOrganizationQueryQuery = {
  readonly __typename: "Query";
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
    readonly name: string;
    readonly personal?: boolean | null;
    readonly domains?: ReadonlyArray<string | null> | null;
    readonly members: {
      readonly __typename: "UserMemberNodeConnection";
      readonly totalCount: number;
    };
  } | null;
};

export type GetSuggestedOrganizationsQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSuggestedOrganizationsQueryQuery = {
  readonly __typename: "Query";
  readonly me?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly organizations: {
      readonly __typename: "OrganizationNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "OrganizationNodeEdge";
        readonly node?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
          readonly name: string;
          readonly personal?: boolean | null;
          readonly domains?: ReadonlyArray<string | null> | null;
          readonly members: {
            readonly __typename: "UserMemberNodeConnection";
            readonly totalCount: number;
          };
        } | null;
      } | null>;
    };
    readonly organizationsAvailableToJoin?: ReadonlyArray<{
      readonly __typename: "OrganizationInfoNode";
      readonly organizationId?: number | null;
      readonly organizationName?: string | null;
      readonly organizationMemberCount?: number | null;
    } | null> | null;
    readonly invitations?: {
      readonly __typename: "InvitationNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "InvitationNodeEdge";
        readonly node?: {
          readonly __typename: "InvitationNode";
          readonly id: number;
          readonly organizationId?: number | null;
          readonly organizationName?: string | null;
          readonly organizationMemberCount?: number | null;
          readonly status?: InvitationStatus | null;
          readonly inviteeEmail: string;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type InvitationFragmentFragment = {
  readonly __typename: "InvitationNode";
  readonly id: number;
  readonly organizationId?: number | null;
  readonly organizationName?: string | null;
  readonly organizationMemberCount?: number | null;
  readonly status?: InvitationStatus | null;
  readonly inviteeEmail: string;
};

export type LinkCalendarToOrganizationOnboardingMutationMutationVariables =
  Exact<{
    credentialsId: Scalars["Int"]["input"];
    organizationId: Scalars["Int"]["input"];
  }>;

export type LinkCalendarToOrganizationOnboardingMutationMutation = {
  readonly __typename: "Mutation";
  readonly linkCalendarToOrganization?: {
    readonly __typename: "LinkCalendarToOrganizationMutation";
    readonly user?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly syncCredentials?: {
        readonly __typename: "UserSyncCredentialsNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "UserSyncCredentialsNodeEdge";
          readonly node?: {
            readonly __typename: "UserSyncCredentialsNode";
            readonly id: number;
            readonly provider?: SyncCredentialsTypeEnum | null;
            readonly credentialsUid?: string | null;
            readonly hasValidCalendarCredentials?: boolean | null;
            readonly hasCalendarWriteCredentials?: boolean | null;
            readonly organization?: {
              readonly __typename: "OrganizationNode";
              readonly id: number;
              readonly domains?: ReadonlyArray<string | null> | null;
              readonly personal?: boolean | null;
            } | null;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type OrganizationFragmentFragment = {
  readonly __typename: "OrganizationNode";
  readonly id: number;
  readonly name: string;
  readonly personal?: boolean | null;
  readonly domains?: ReadonlyArray<string | null> | null;
  readonly members: {
    readonly __typename: "UserMemberNodeConnection";
    readonly totalCount: number;
  };
};

export type SyncCredentialFragmentFragment = {
  readonly __typename: "UserSyncCredentialsNode";
  readonly id: number;
  readonly provider?: SyncCredentialsTypeEnum | null;
  readonly credentialsUid?: string | null;
  readonly hasValidCalendarCredentials?: boolean | null;
  readonly hasCalendarWriteCredentials?: boolean | null;
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
    readonly domains?: ReadonlyArray<string | null> | null;
    readonly personal?: boolean | null;
  } | null;
};

export type SaveOrgWideCareerTrackMutationVariables = Exact<{
  careerTrackId?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  teamId?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  roles?: InputMaybe<
    | ReadonlyArray<InputMaybe<CareerTrackRoleInput>>
    | InputMaybe<CareerTrackRoleInput>
  >;
  additionalCompetencies?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
}>;

export type SaveOrgWideCareerTrackMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateCareerTrack?: {
    readonly __typename: "CreateOrUpdateCareerTrackMutation";
    readonly careerTrack?: {
      readonly __typename: "CareerTrackNode";
      readonly id: number;
      readonly title: string;
      readonly team?: {
        readonly __typename: "TeamNode";
        readonly id: number;
        readonly title: string;
      } | null;
      readonly roles: {
        readonly __typename: "CareerTrackRoleNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "CareerTrackRoleNodeEdge";
          readonly node?: {
            readonly __typename: "CareerTrackRoleNode";
            readonly id: number;
            readonly title: string;
            readonly level: number;
            readonly roleType: CareerTrackRoleType;
            readonly assignedUsers: {
              readonly __typename: "UserNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "UserNodeEdge";
                readonly node?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly email: string;
                  readonly avatar?: string | null;
                } | null;
              } | null>;
            };
            readonly responsibilities: {
              readonly __typename: "CareerTrackRoleResponsibilityNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "CareerTrackRoleResponsibilityNodeEdge";
                readonly node?: {
                  readonly __typename: "CareerTrackRoleResponsibilityNode";
                  readonly id: number;
                  readonly content: any;
                } | null;
              } | null>;
            };
            readonly competencies: {
              readonly __typename: "CompetencyConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "CompetencyEdge";
                readonly node?: {
                  readonly __typename: "CompetencyNode";
                  readonly id: number;
                  readonly title: string;
                  readonly appliesTo: CompetencyAppliesTo;
                  readonly criteriaUniqueness: CompetencyCriteriaUniqueness;
                  readonly criteria: {
                    readonly __typename: "CompetencyCriteriaConnection";
                    readonly edges: ReadonlyArray<{
                      readonly __typename: "CompetencyCriteriaEdge";
                      readonly node?: {
                        readonly __typename: "CompetencyCriteriaNode";
                        readonly id: number;
                        readonly content: any;
                        readonly level: number;
                      } | null;
                    } | null>;
                  };
                } | null;
              } | null>;
            };
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type OrgSettingsCreateOrUpdateCoreValueMutationVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  emoji?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<RecognitionCoreValueStatus>;
}>;

export type OrgSettingsCreateOrUpdateCoreValueMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateCoreValue?: {
    readonly __typename: "CreateOrUpdateCoreValueMutation";
    readonly coreValue?: {
      readonly __typename: "RecognitionCoreValueNode";
      readonly id: number;
      readonly title: string;
      readonly description: string;
      readonly emoji?: string | null;
      readonly image?: string | null;
      readonly status?: RecognitionCoreValueStatus | null;
    } | null;
  } | null;
};

export type SaveOrgWideCompetencyMutationVariables = Exact<{
  competencyId?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  appliesTo?: InputMaybe<CompetencyAppliesTo>;
  criteriaUniqueness?: InputMaybe<CompetencyCriteriaUniqueness>;
  appliesToManagementRoles?: InputMaybe<Scalars["Boolean"]["input"]>;
  appliesToIcRoles?: InputMaybe<Scalars["Boolean"]["input"]>;
  appliesToTeams?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  appliesToCareerTracks?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  criteria?: InputMaybe<
    | ReadonlyArray<InputMaybe<CompetencyCriteriaInput>>
    | InputMaybe<CompetencyCriteriaInput>
  >;
}>;

export type SaveOrgWideCompetencyMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateCompetency?: {
    readonly __typename: "CreateOrUpdateCompetencyMutation";
    readonly competency?: {
      readonly __typename: "CompetencyNode";
      readonly id: number;
      readonly title: string;
      readonly description: any;
      readonly appliesTo: CompetencyAppliesTo;
    } | null;
  } | null;
};

export type CreateOrUpdateSlackNotificationForChannelMutationMutationVariables =
  Exact<{
    channel?: InputMaybe<Scalars["String"]["input"]>;
    organizationId?: InputMaybe<Scalars["Int"]["input"]>;
    notificationId?: InputMaybe<Scalars["Int"]["input"]>;
    recognitions?: InputMaybe<Scalars["Boolean"]["input"]>;
    goalCheckins?: InputMaybe<Scalars["Boolean"]["input"]>;
  }>;

export type CreateOrUpdateSlackNotificationForChannelMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateSlackNotificationForChannel?: {
    readonly __typename: "CreateOrUpdateSlackNotificationForChannelMutation";
    readonly organization?: {
      readonly __typename: "OrganizationNode";
      readonly id: number;
      readonly slackNotifications?: {
        readonly __typename: "SlackNotificationForChannelNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "SlackNotificationForChannelNodeEdge";
          readonly node?: {
            readonly __typename: "SlackNotificationForChannelNode";
            readonly id: number;
            readonly channel: string;
            readonly recognitions: boolean;
            readonly goalCheckins: boolean;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type DeleteCoreValueMutationMutationVariables = Exact<{
  coreValueId: Scalars["Int"]["input"];
}>;

export type DeleteCoreValueMutationMutation = {
  readonly __typename: "Mutation";
  readonly deleteCoreValue?: {
    readonly __typename: "DeleteCoreValueMutation";
    readonly ok?: boolean | null;
  } | null;
};

export type DeleteOrgWideCareerTrackMutationVariables = Exact<{
  careerTrackId: Scalars["Int"]["input"];
}>;

export type DeleteOrgWideCareerTrackMutation = {
  readonly __typename: "Mutation";
  readonly deleteCareerTrack?: {
    readonly __typename: "DeleteCareerTrackMutation";
    readonly ok?: boolean | null;
  } | null;
};

export type DeleteOrgWideCompetencyMutationVariables = Exact<{
  competencyId: Scalars["Int"]["input"];
}>;

export type DeleteOrgWideCompetencyMutation = {
  readonly __typename: "Mutation";
  readonly deleteCompetency?: {
    readonly __typename: "DeleteCompetencyMutation";
    readonly ok?: boolean | null;
  } | null;
};

export type DeleteSlackNotificationForChannelMutationMutationVariables = Exact<{
  notificationId: Scalars["Int"]["input"];
}>;

export type DeleteSlackNotificationForChannelMutationMutation = {
  readonly __typename: "Mutation";
  readonly deleteSlackNotificationForChannel?: {
    readonly __typename: "DeleteSlackNotificationForChannelMutation";
    readonly organization?: {
      readonly __typename: "OrganizationNode";
      readonly id: number;
      readonly slackNotifications?: {
        readonly __typename: "SlackNotificationForChannelNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "SlackNotificationForChannelNodeEdge";
          readonly node?: {
            readonly __typename: "SlackNotificationForChannelNode";
            readonly id: number;
            readonly channel: string;
            readonly recognitions: boolean;
            readonly goalCheckins: boolean;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type DeleteTeamMutationMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type DeleteTeamMutationMutation = {
  readonly __typename: "Mutation";
  readonly deleteTeam?: {
    readonly __typename: "DeleteTeamMutation";
    readonly team?: {
      readonly __typename: "TeamNode";
      readonly id: number;
    } | null;
  } | null;
};

export type GetMeetingsByManagerReportQueryQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
  personId?: InputMaybe<Scalars["Int"]["input"]>;
  templateId?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetMeetingsByManagerReportQueryQuery = {
  readonly __typename: "Query";
  readonly meetingsByManagerReport?: {
    readonly __typename: "MeetingsByManagerReportConnection";
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor?: string | null;
      readonly hasNextPage: boolean;
    };
    readonly edges: ReadonlyArray<{
      readonly __typename: "MeetingsByManagerReportEdge";
      readonly node?: {
        readonly __typename: "MeetingsByManagerReportNode";
        readonly id?: number | null;
        readonly reports?: {
          readonly __typename: "UserReportConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "UserReportEdge";
            readonly node?: {
              readonly __typename: "UserReportNode";
              readonly user?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly email: string;
                readonly avatar?: string | null;
              } | null;
              readonly lastMeeting?: {
                readonly __typename: "MeetingInfoNode";
                readonly id?: number | null;
                readonly startDatetime?: string | null;
                readonly meeting?: {
                  readonly __typename: "MeetingNode";
                  readonly id: number;
                  readonly meetingGroupId: number;
                  readonly title?: string | null;
                } | null;
              } | null;
              readonly nextMeeting?: {
                readonly __typename: "MeetingInfoNode";
                readonly id?: number | null;
                readonly startDatetime?: string | null;
                readonly meeting?: {
                  readonly __typename: "MeetingNode";
                  readonly id: number;
                  readonly meetingGroupId: number;
                  readonly title?: string | null;
                } | null;
              } | null;
            } | null;
          } | null>;
        } | null;
        readonly manager?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
          readonly email: string;
          readonly managers: {
            readonly __typename: "UserNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "UserNodeEdge";
              readonly node?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
                readonly email: string;
              } | null;
            } | null>;
          };
        } | null;
      } | null;
    } | null>;
  } | null;
  readonly users: {
    readonly __typename: "UserNodeConnection";
    readonly totalCount: number;
  };
};

export type OrganizationCareerTrackFragmentFragment = {
  readonly __typename: "CareerTrackNode";
  readonly id: number;
  readonly title: string;
  readonly team?: {
    readonly __typename: "TeamNode";
    readonly id: number;
    readonly title: string;
  } | null;
  readonly roles: {
    readonly __typename: "CareerTrackRoleNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "CareerTrackRoleNodeEdge";
      readonly node?: {
        readonly __typename: "CareerTrackRoleNode";
        readonly id: number;
        readonly title: string;
        readonly level: number;
        readonly roleType: CareerTrackRoleType;
        readonly assignedUsers: {
          readonly __typename: "UserNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "UserNodeEdge";
            readonly node?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly email: string;
              readonly avatar?: string | null;
            } | null;
          } | null>;
        };
        readonly responsibilities: {
          readonly __typename: "CareerTrackRoleResponsibilityNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "CareerTrackRoleResponsibilityNodeEdge";
            readonly node?: {
              readonly __typename: "CareerTrackRoleResponsibilityNode";
              readonly id: number;
              readonly content: any;
            } | null;
          } | null>;
        };
        readonly competencies: {
          readonly __typename: "CompetencyConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "CompetencyEdge";
            readonly node?: {
              readonly __typename: "CompetencyNode";
              readonly id: number;
              readonly title: string;
              readonly appliesTo: CompetencyAppliesTo;
              readonly criteriaUniqueness: CompetencyCriteriaUniqueness;
              readonly criteria: {
                readonly __typename: "CompetencyCriteriaConnection";
                readonly edges: ReadonlyArray<{
                  readonly __typename: "CompetencyCriteriaEdge";
                  readonly node?: {
                    readonly __typename: "CompetencyCriteriaNode";
                    readonly id: number;
                    readonly content: any;
                    readonly level: number;
                  } | null;
                } | null>;
              };
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  };
};

export type GetOrganizationCareerTrackQueryVariables = Exact<{
  careerTrackId: Scalars["Int"]["input"];
}>;

export type GetOrganizationCareerTrackQuery = {
  readonly __typename: "Query";
  readonly careerTrack?: {
    readonly __typename: "CareerTrackNode";
    readonly id: number;
    readonly title: string;
    readonly team?: {
      readonly __typename: "TeamNode";
      readonly id: number;
      readonly title: string;
    } | null;
    readonly roles: {
      readonly __typename: "CareerTrackRoleNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "CareerTrackRoleNodeEdge";
        readonly node?: {
          readonly __typename: "CareerTrackRoleNode";
          readonly id: number;
          readonly title: string;
          readonly level: number;
          readonly roleType: CareerTrackRoleType;
          readonly assignedUsers: {
            readonly __typename: "UserNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "UserNodeEdge";
              readonly node?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly email: string;
                readonly avatar?: string | null;
              } | null;
            } | null>;
          };
          readonly responsibilities: {
            readonly __typename: "CareerTrackRoleResponsibilityNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "CareerTrackRoleResponsibilityNodeEdge";
              readonly node?: {
                readonly __typename: "CareerTrackRoleResponsibilityNode";
                readonly id: number;
                readonly content: any;
              } | null;
            } | null>;
          };
          readonly competencies: {
            readonly __typename: "CompetencyConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "CompetencyEdge";
              readonly node?: {
                readonly __typename: "CompetencyNode";
                readonly id: number;
                readonly title: string;
                readonly appliesTo: CompetencyAppliesTo;
                readonly criteriaUniqueness: CompetencyCriteriaUniqueness;
                readonly criteria: {
                  readonly __typename: "CompetencyCriteriaConnection";
                  readonly edges: ReadonlyArray<{
                    readonly __typename: "CompetencyCriteriaEdge";
                    readonly node?: {
                      readonly __typename: "CompetencyCriteriaNode";
                      readonly id: number;
                      readonly content: any;
                      readonly level: number;
                    } | null;
                  } | null>;
                };
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    };
  } | null;
};

export type GetOrganizationCareerTracksQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
  teamId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetOrganizationCareerTracksQuery = {
  readonly __typename: "Query";
  readonly careerTracks: {
    readonly __typename: "CareerTrackNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "CareerTrackNodeEdge";
      readonly node?: {
        readonly __typename: "CareerTrackNode";
        readonly id: number;
        readonly title: string;
        readonly roles: {
          readonly __typename: "CareerTrackRoleNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "CareerTrackRoleNodeEdge";
            readonly node?: {
              readonly __typename: "CareerTrackRoleNode";
              readonly id: number;
              readonly title: string;
              readonly level: number;
              readonly roleType: CareerTrackRoleType;
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  };
};

export type OrganizationCompetencyCriteriaFragment = {
  readonly __typename: "CompetencyNode";
  readonly criteria: {
    readonly __typename: "CompetencyCriteriaConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "CompetencyCriteriaEdge";
      readonly node?: {
        readonly __typename: "CompetencyCriteriaNode";
        readonly id: number;
        readonly content: any;
        readonly level: number;
      } | null;
    } | null>;
  };
};

export type OrganizationCompetencyFragment = {
  readonly __typename: "CompetencyNode";
  readonly id: number;
  readonly title: string;
  readonly description: any;
  readonly appliesTo: CompetencyAppliesTo;
  readonly appliesToManagementRoles: boolean;
  readonly appliesToIcRoles: boolean;
  readonly criteriaUniqueness: CompetencyCriteriaUniqueness;
  readonly appliesToTeams: {
    readonly __typename: "TeamNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "TeamNodeEdge";
      readonly node?: {
        readonly __typename: "TeamNode";
        readonly id: number;
        readonly title: string;
      } | null;
    } | null>;
  };
  readonly appliesToCareerTracks: {
    readonly __typename: "CareerTrackNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "CareerTrackNodeEdge";
      readonly node?: {
        readonly __typename: "CareerTrackNode";
        readonly id: number;
      } | null;
    } | null>;
  };
  readonly criteria: {
    readonly __typename: "CompetencyCriteriaConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "CompetencyCriteriaEdge";
      readonly node?: {
        readonly __typename: "CompetencyCriteriaNode";
        readonly id: number;
        readonly content: any;
        readonly level: number;
      } | null;
    } | null>;
  };
};

export type GetOrganizationCompetenciesQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
}>;

export type GetOrganizationCompetenciesQuery = {
  readonly __typename: "Query";
  readonly competencies: {
    readonly __typename: "CompetencyConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "CompetencyEdge";
      readonly node?: {
        readonly __typename: "CompetencyNode";
        readonly id: number;
        readonly title: string;
        readonly description: any;
        readonly appliesTo: CompetencyAppliesTo;
        readonly appliesToManagementRoles: boolean;
        readonly appliesToIcRoles: boolean;
        readonly criteriaUniqueness: CompetencyCriteriaUniqueness;
        readonly appliesToTeams: {
          readonly __typename: "TeamNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "TeamNodeEdge";
            readonly node?: {
              readonly __typename: "TeamNode";
              readonly id: number;
              readonly title: string;
            } | null;
          } | null>;
        };
        readonly appliesToCareerTracks: {
          readonly __typename: "CareerTrackNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "CareerTrackNodeEdge";
            readonly node?: {
              readonly __typename: "CareerTrackNode";
              readonly id: number;
            } | null;
          } | null>;
        };
        readonly criteria: {
          readonly __typename: "CompetencyCriteriaConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "CompetencyCriteriaEdge";
            readonly node?: {
              readonly __typename: "CompetencyCriteriaNode";
              readonly id: number;
              readonly content: any;
              readonly level: number;
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  };
};

export type GetOrgWideCompetencyQueryVariables = Exact<{
  competencyId: Scalars["Int"]["input"];
}>;

export type GetOrgWideCompetencyQuery = {
  readonly __typename: "Query";
  readonly competency?: {
    readonly __typename: "CompetencyNode";
    readonly id: number;
    readonly title: string;
    readonly description: any;
    readonly appliesTo: CompetencyAppliesTo;
    readonly appliesToManagementRoles: boolean;
    readonly appliesToIcRoles: boolean;
    readonly criteriaUniqueness: CompetencyCriteriaUniqueness;
    readonly appliesToTeams: {
      readonly __typename: "TeamNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "TeamNodeEdge";
        readonly node?: {
          readonly __typename: "TeamNode";
          readonly id: number;
          readonly title: string;
        } | null;
      } | null>;
    };
    readonly appliesToCareerTracks: {
      readonly __typename: "CareerTrackNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "CareerTrackNodeEdge";
        readonly node?: {
          readonly __typename: "CareerTrackNode";
          readonly id: number;
          readonly title: string;
        } | null;
      } | null>;
    };
    readonly criteria: {
      readonly __typename: "CompetencyCriteriaConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "CompetencyCriteriaEdge";
        readonly node?: {
          readonly __typename: "CompetencyCriteriaNode";
          readonly id: number;
          readonly content: any;
          readonly level: number;
        } | null;
      } | null>;
    };
  } | null;
};

export type GetOrganizationCoreValuesQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
}>;

export type GetOrganizationCoreValuesQuery = {
  readonly __typename: "Query";
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
    readonly coreValues?: {
      readonly __typename: "RecognitionCoreValueNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "RecognitionCoreValueNodeEdge";
        readonly node?: {
          readonly __typename: "RecognitionCoreValueNode";
          readonly id: number;
          readonly title: string;
          readonly description: string;
          readonly emoji?: string | null;
          readonly image?: string | null;
          readonly status?: RecognitionCoreValueStatus | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type GetOrganizationIntegrationsQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
}>;

export type GetOrganizationIntegrationsQuery = {
  readonly __typename: "Query";
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
    readonly integrations: {
      readonly __typename: "OrganizationIntegrationNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "OrganizationIntegrationNodeEdge";
        readonly node?: {
          readonly __typename: "OrganizationIntegrationNode";
          readonly id: number;
          readonly integrationType: IntegrationTypes;
          readonly created: string;
          readonly creator: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
          };
          readonly socialAuth: {
            readonly __typename: "UserSocialAuthNode";
            readonly id: number;
            readonly extraData: any;
          };
        } | null;
      } | null>;
    };
  } | null;
};

export type OrganizationMemberFragment = {
  readonly __typename: "UserMemberNode";
  readonly id: number;
  readonly position?: string | null;
  readonly role?: OrganizationRole | null;
  readonly startDate?: string | null;
  readonly employeeId?: string | null;
  readonly jobLevel?: string | null;
  readonly membershipStatus?: AccountToOrganizationMembershipStatus | null;
  readonly user?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
    readonly email: string;
    readonly status?: UserStatus | null;
    readonly lastSeenStr?: UserLastSeen | null;
    readonly managers: {
      readonly __typename: "UserNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "UserNodeEdge";
        readonly node?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type GetOrganizationMembersQueryQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
  after?: InputMaybe<Scalars["String"]["input"]>;
  hasManager?: InputMaybe<Scalars["Boolean"]["input"]>;
  showAdminsOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  orderBy?: InputMaybe<Scalars["String"]["input"]>;
  membershipStatus?: InputMaybe<AccountToOrganizationMembershipStatus>;
}>;

export type GetOrganizationMembersQueryQuery = {
  readonly __typename: "Query";
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
    readonly members: {
      readonly __typename: "UserMemberNodeConnection";
      readonly totalCount: number;
      readonly pageInfo: {
        readonly __typename: "PageInfo";
        readonly endCursor?: string | null;
        readonly hasNextPage: boolean;
      };
      readonly edges: ReadonlyArray<{
        readonly __typename: "UserMemberNodeEdge";
        readonly node?: {
          readonly __typename: "UserMemberNode";
          readonly id: number;
          readonly position?: string | null;
          readonly role?: OrganizationRole | null;
          readonly startDate?: string | null;
          readonly employeeId?: string | null;
          readonly jobLevel?: string | null;
          readonly membershipStatus?: AccountToOrganizationMembershipStatus | null;
          readonly user?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
            readonly email: string;
            readonly status?: UserStatus | null;
            readonly lastSeenStr?: UserLastSeen | null;
            readonly managers: {
              readonly __typename: "UserNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "UserNodeEdge";
                readonly node?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
              } | null>;
            };
          } | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type GetOrganizationNotificationsQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
}>;

export type GetOrganizationNotificationsQuery = {
  readonly __typename: "Query";
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
    readonly hasSlackConnection?: boolean | null;
    readonly slackNotifications?: {
      readonly __typename: "SlackNotificationForChannelNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "SlackNotificationForChannelNodeEdge";
        readonly node?: {
          readonly __typename: "SlackNotificationForChannelNode";
          readonly id: number;
          readonly channel: string;
          readonly recognitions: boolean;
          readonly goalCheckins: boolean;
        } | null;
      } | null>;
    } | null;
    readonly publicSlackChannels?: {
      readonly __typename: "SlackChannelConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "SlackChannelEdge";
        readonly node?: {
          readonly __typename: "SlackChannelNode";
          readonly id?: string | null;
          readonly name?: string | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type OrgSettingsOrganizationFragmentFragment = {
  readonly __typename: "OrganizationNode";
  readonly id: number;
  readonly name: string;
  readonly pricingTier?: PricingTier | null;
  readonly pricingTierValidUntil?: string | null;
  readonly membershipSetting?: OrganizationMembershipSetting | null;
  readonly domains?: ReadonlyArray<string | null> | null;
  readonly goalLabel: string;
  readonly personal?: boolean | null;
  readonly keyResultLabel: string;
  readonly teamLabel: string;
  readonly orgLabel: string;
  readonly recognitionLabel: string;
  readonly oneononeLabel: string;
  readonly reviewLabel: string;
  readonly expectationLabel: string;
  readonly conversationLabel: string;
  readonly developmentLabel: string;
  readonly competencyLabel: string;
  readonly enableMeetingSummarization: boolean;
  readonly transcribeMeetingsByDefault?: boolean | null;
  readonly effectivePricingTier?: PricingTier | null;
  readonly defaultGoalState?: GoalState | null;
  readonly defaultDecisionState?: DecisionState | null;
  readonly hasSlackConnection?: boolean | null;
  readonly hasTeamsTranscriptConnection?: boolean | null;
  readonly hasGithubInstallation?: boolean | null;
  readonly quarterStartMonth: number;
  readonly directorySync?: {
    readonly __typename: "DirectorySyncNode";
    readonly id?: string | null;
    readonly state?: string | null;
    readonly type?: string | null;
    readonly name?: string | null;
  } | null;
  readonly userMembership?: {
    readonly __typename: "UserMemberNode";
    readonly id: number;
    readonly role?: OrganizationRole | null;
  } | null;
  readonly featureFlags: {
    readonly __typename: "OrganizationFeatureFlags";
    readonly actionItems?: boolean | null;
    readonly decisions?: boolean | null;
    readonly feedbacks?: boolean | null;
    readonly goals?: boolean | null;
    readonly documents?: boolean | null;
    readonly recognitions?: boolean | null;
    readonly kpis?: boolean | null;
    readonly explorer?: boolean | null;
    readonly homepageMyConnections?: boolean | null;
  };
};

export type GetOrganizationSettingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetOrganizationSettingsQuery = {
  readonly __typename: "Query";
  readonly me?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly organizations: {
      readonly __typename: "OrganizationNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "OrganizationNodeEdge";
        readonly node?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
          readonly name: string;
          readonly pricingTier?: PricingTier | null;
          readonly pricingTierValidUntil?: string | null;
          readonly membershipSetting?: OrganizationMembershipSetting | null;
          readonly domains?: ReadonlyArray<string | null> | null;
          readonly goalLabel: string;
          readonly personal?: boolean | null;
          readonly keyResultLabel: string;
          readonly teamLabel: string;
          readonly orgLabel: string;
          readonly recognitionLabel: string;
          readonly oneononeLabel: string;
          readonly reviewLabel: string;
          readonly expectationLabel: string;
          readonly conversationLabel: string;
          readonly developmentLabel: string;
          readonly competencyLabel: string;
          readonly enableMeetingSummarization: boolean;
          readonly transcribeMeetingsByDefault?: boolean | null;
          readonly effectivePricingTier?: PricingTier | null;
          readonly defaultGoalState?: GoalState | null;
          readonly defaultDecisionState?: DecisionState | null;
          readonly hasSlackConnection?: boolean | null;
          readonly hasTeamsTranscriptConnection?: boolean | null;
          readonly hasGithubInstallation?: boolean | null;
          readonly quarterStartMonth: number;
          readonly directorySync?: {
            readonly __typename: "DirectorySyncNode";
            readonly id?: string | null;
            readonly state?: string | null;
            readonly type?: string | null;
            readonly name?: string | null;
          } | null;
          readonly userMembership?: {
            readonly __typename: "UserMemberNode";
            readonly id: number;
            readonly role?: OrganizationRole | null;
          } | null;
          readonly featureFlags: {
            readonly __typename: "OrganizationFeatureFlags";
            readonly actionItems?: boolean | null;
            readonly decisions?: boolean | null;
            readonly feedbacks?: boolean | null;
            readonly goals?: boolean | null;
            readonly documents?: boolean | null;
            readonly recognitions?: boolean | null;
            readonly kpis?: boolean | null;
            readonly explorer?: boolean | null;
            readonly homepageMyConnections?: boolean | null;
          };
        } | null;
      } | null>;
    };
  } | null;
};

export type OrgSettingOrgTeamFragment = {
  readonly __typename: "TeamNode";
  readonly id: number;
  readonly title: string;
  readonly members?: {
    readonly __typename: "UserNodeConnection";
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly __typename: "UserNodeEdge";
      readonly node?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly avatar?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type GetOrganizationTeamsQueryQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetOrganizationTeamsQueryQuery = {
  readonly __typename: "Query";
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
    readonly teams?: {
      readonly __typename: "TeamNodeConnection";
      readonly totalCount: number;
      readonly pageInfo: {
        readonly __typename: "PageInfo";
        readonly endCursor?: string | null;
        readonly hasNextPage: boolean;
      };
      readonly edges: ReadonlyArray<{
        readonly __typename: "TeamNodeEdge";
        readonly node?: {
          readonly __typename: "TeamNode";
          readonly id: number;
          readonly title: string;
          readonly members?: {
            readonly __typename: "UserNodeConnection";
            readonly totalCount: number;
            readonly edges: ReadonlyArray<{
              readonly __typename: "UserNodeEdge";
              readonly node?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
            } | null>;
          } | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type GetOrganizationTemplatesQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetOrganizationTemplatesQueryQuery = {
  readonly __typename: "Query";
  readonly topicTemplates?: {
    readonly __typename: "TopicTemplateNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "TopicTemplateNodeEdge";
      readonly node?: {
        readonly __typename: "TopicTemplateNode";
        readonly id: number;
        readonly title: string;
      } | null;
    } | null>;
  } | null;
};

export type RemoveGitHubFromOrganizationMutationMutationVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
}>;

export type RemoveGitHubFromOrganizationMutationMutation = {
  readonly __typename: "Mutation";
  readonly removeOrgGithubIntegration?: {
    readonly __typename: "RemoveOrganizationGitHubIntegrationMutation";
    readonly organization?: {
      readonly __typename: "OrganizationNode";
      readonly id: number;
      readonly hasGithubInstallation?: boolean | null;
    } | null;
  } | null;
};

export type RemoveSlackIntegrationMutationMutationVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
}>;

export type RemoveSlackIntegrationMutationMutation = {
  readonly __typename: "Mutation";
  readonly removeSlackIntegration?: {
    readonly __typename: "RemoveSlackIntegrationMutation";
    readonly organization?: {
      readonly __typename: "OrganizationNode";
      readonly id: number;
      readonly hasSlackConnection?: boolean | null;
    } | null;
  } | null;
};

export type RemoveUserFromOrganizationMutationMutationVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
  userToRemoveId: Scalars["Int"]["input"];
}>;

export type RemoveUserFromOrganizationMutationMutation = {
  readonly __typename: "Mutation";
  readonly removeUserFromOrganization?: {
    readonly __typename: "RemoveUserFromOrganizationMutation";
    readonly org?: {
      readonly __typename: "OrganizationNode";
      readonly id: number;
    } | null;
  } | null;
};

export type SendNudgeEmailMutationMutationVariables = Exact<{
  managerId: Scalars["Int"]["input"];
  reportId: Scalars["Int"]["input"];
  topicTemplateId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type SendNudgeEmailMutationMutation = {
  readonly __typename: "Mutation";
  readonly email1on1Nudge?: {
    readonly __typename: "Email1on1NudgeMutation";
    readonly report?: {
      readonly __typename: "UserNode";
      readonly id: number;
    } | null;
    readonly manager?: {
      readonly __typename: "UserNode";
      readonly id: number;
    } | null;
  } | null;
};

export type UnlinkIntegrationFromOrganizationMutationMutationVariables = Exact<{
  organizationIntegrationId: Scalars["Int"]["input"];
}>;

export type UnlinkIntegrationFromOrganizationMutationMutation = {
  readonly __typename: "Mutation";
  readonly unlinkIntegrationFromOrganization?: {
    readonly __typename: "UnlinkIntegrationFromOrganizationMutation";
    readonly organization?: {
      readonly __typename: "OrganizationNode";
      readonly id: number;
      readonly integrations: {
        readonly __typename: "OrganizationIntegrationNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "OrganizationIntegrationNodeEdge";
          readonly node?: {
            readonly __typename: "OrganizationIntegrationNode";
            readonly id: number;
            readonly integrationType: IntegrationTypes;
            readonly created: string;
            readonly creator: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
            };
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type UpdateMemberMutationMutationVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
  role?: InputMaybe<OrganizationRole>;
  userId: Scalars["Int"]["input"];
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  employeeId?: InputMaybe<Scalars["String"]["input"]>;
  position?: InputMaybe<Scalars["String"]["input"]>;
  jobLevel?: InputMaybe<Scalars["String"]["input"]>;
  membershipStatus?: InputMaybe<AccountToOrganizationMembershipStatus>;
}>;

export type UpdateMemberMutationMutation = {
  readonly __typename: "Mutation";
  readonly updateOrganizationMembership?: {
    readonly __typename: "UpdateOrganizationMembershipMutation";
    readonly member?: {
      readonly __typename: "UserMemberNode";
      readonly id: number;
      readonly role?: OrganizationRole | null;
      readonly startDate?: string | null;
      readonly employeeId?: string | null;
      readonly position?: string | null;
      readonly jobLevel?: string | null;
      readonly user?: {
        readonly __typename: "UserNode";
        readonly id: number;
      } | null;
    } | null;
  } | null;
};

export type CreateOrUpdateOrganizationSettingsMutationVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  goalLabel?: InputMaybe<Scalars["String"]["input"]>;
  orgLabel?: InputMaybe<Scalars["String"]["input"]>;
  recognitionLabel?: InputMaybe<Scalars["String"]["input"]>;
  teamLabel?: InputMaybe<Scalars["String"]["input"]>;
  reviewLabel?: InputMaybe<Scalars["String"]["input"]>;
  expectationLabel?: InputMaybe<Scalars["String"]["input"]>;
  conversationLabel?: InputMaybe<Scalars["String"]["input"]>;
  developmentLabel?: InputMaybe<Scalars["String"]["input"]>;
  competencyLabel?: InputMaybe<Scalars["String"]["input"]>;
  membershipSetting?: InputMaybe<OrganizationMembershipSetting>;
  enableMeetingSummarization?: InputMaybe<Scalars["Boolean"]["input"]>;
  transcribeMeetingsByDefault?: InputMaybe<Scalars["Boolean"]["input"]>;
  defaultGoalState?: InputMaybe<GoalState>;
  defaultDecisionState?: InputMaybe<DecisionState>;
  quarterStartMonth?: InputMaybe<Scalars["Int"]["input"]>;
  featureFlags?: InputMaybe<OrganizationFeatureFlagsInput>;
}>;

export type CreateOrUpdateOrganizationSettingsMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateOrganization?: {
    readonly __typename: "CreateOrUpdateOrganizationMutation";
    readonly organization?: {
      readonly __typename: "OrganizationNode";
      readonly id: number;
      readonly name: string;
      readonly pricingTier?: PricingTier | null;
      readonly pricingTierValidUntil?: string | null;
      readonly membershipSetting?: OrganizationMembershipSetting | null;
      readonly domains?: ReadonlyArray<string | null> | null;
      readonly goalLabel: string;
      readonly personal?: boolean | null;
      readonly keyResultLabel: string;
      readonly teamLabel: string;
      readonly orgLabel: string;
      readonly recognitionLabel: string;
      readonly oneononeLabel: string;
      readonly reviewLabel: string;
      readonly expectationLabel: string;
      readonly conversationLabel: string;
      readonly developmentLabel: string;
      readonly competencyLabel: string;
      readonly enableMeetingSummarization: boolean;
      readonly transcribeMeetingsByDefault?: boolean | null;
      readonly effectivePricingTier?: PricingTier | null;
      readonly defaultGoalState?: GoalState | null;
      readonly defaultDecisionState?: DecisionState | null;
      readonly hasSlackConnection?: boolean | null;
      readonly hasTeamsTranscriptConnection?: boolean | null;
      readonly hasGithubInstallation?: boolean | null;
      readonly quarterStartMonth: number;
      readonly directorySync?: {
        readonly __typename: "DirectorySyncNode";
        readonly id?: string | null;
        readonly state?: string | null;
        readonly type?: string | null;
        readonly name?: string | null;
      } | null;
      readonly userMembership?: {
        readonly __typename: "UserMemberNode";
        readonly id: number;
        readonly role?: OrganizationRole | null;
      } | null;
      readonly featureFlags: {
        readonly __typename: "OrganizationFeatureFlags";
        readonly actionItems?: boolean | null;
        readonly decisions?: boolean | null;
        readonly feedbacks?: boolean | null;
        readonly goals?: boolean | null;
        readonly documents?: boolean | null;
        readonly recognitions?: boolean | null;
        readonly kpis?: boolean | null;
        readonly explorer?: boolean | null;
        readonly homepageMyConnections?: boolean | null;
      };
    } | null;
  } | null;
};

export type UpdateTeamMutationMutationVariables = Exact<{
  id?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  memberIds?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  title?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateTeamMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateTeam?: {
    readonly __typename: "CreateOrUpdateTeamMutation";
    readonly team?: {
      readonly __typename: "TeamNode";
      readonly id: number;
      readonly title: string;
      readonly members?: {
        readonly __typename: "UserNodeConnection";
        readonly totalCount: number;
        readonly edges: ReadonlyArray<{
          readonly __typename: "UserNodeEdge";
          readonly node?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type SaveComplianceProgramMutationVariables = Exact<{
  complianceProgramId?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["Date"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  periodStartDate?: InputMaybe<Scalars["Date"]["input"]>;
  periodEndDate?: InputMaybe<Scalars["Date"]["input"]>;
  removeProgramPeriod?: InputMaybe<Scalars["Boolean"]["input"]>;
  topicTemplates?: InputMaybe<
    | ReadonlyArray<InputMaybe<TopicTemplateComplianceRequirement>>
    | InputMaybe<TopicTemplateComplianceRequirement>
  >;
  assessmentTemplates?: InputMaybe<
    | ReadonlyArray<InputMaybe<AssessmentTemplateComplianceRequirement>>
    | InputMaybe<AssessmentTemplateComplianceRequirement>
  >;
  state?: InputMaybe<ComplianceProgramState>;
  appliesTo?: InputMaybe<ComplianceProgramAppliesTo>;
  appliesToTeams?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  appliesToManagers?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  appliesToUsers?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  excludedUsers?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  excludedHireDateAfter?: InputMaybe<Scalars["Date"]["input"]>;
  removeExcludedHireDateAfter?: InputMaybe<Scalars["Boolean"]["input"]>;
  recurrence?: InputMaybe<ComplianceProgramRecurrence>;
  ongoing?: InputMaybe<Scalars["Boolean"]["input"]>;
  startDateOffset?: InputMaybe<Scalars["Int"]["input"]>;
  startDateDueDateOffset?: InputMaybe<Scalars["Int"]["input"]>;
  startDatePeriodStartOffset?: InputMaybe<Scalars["Int"]["input"]>;
  startDatePeriodEndOffset?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type SaveComplianceProgramMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateComplianceProgram?: {
    readonly __typename: "CreateOrUpdateComplianceProgramMutation";
    readonly complianceProgram?: {
      readonly __typename: "ComplianceProgramNode";
      readonly id: number;
    } | null;
  } | null;
};

export type DeleteProgramMutationVariables = Exact<{
  complianceProgramId: Scalars["Int"]["input"];
}>;

export type DeleteProgramMutation = {
  readonly __typename: "Mutation";
  readonly deleteComplianceProgram?: {
    readonly __typename: "DeleteComplianceProgramMutation";
    readonly organization?: {
      readonly __typename: "OrganizationNode";
      readonly id: number;
    } | null;
  } | null;
};

export type ExportComplianceProgramToCsvMutationMutationVariables = Exact<{
  complianceProgramId: Scalars["Int"]["input"];
}>;

export type ExportComplianceProgramToCsvMutationMutation = {
  readonly __typename: "Mutation";
  readonly exportComplianceProgramToCsv?: {
    readonly __typename: "ExportComplianceProgramToCSV";
    readonly complianceProgram?: {
      readonly __typename: "ComplianceProgramNode";
      readonly id: number;
    } | null;
  } | null;
};

export type ComplianceProgramAssessmentGroupFragment = {
  readonly __typename: "AssessmentGroupNode";
  readonly id: number;
  readonly title: string;
  readonly sections: {
    readonly __typename: "AssessmentGroupSectionNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "AssessmentGroupSectionNodeEdge";
      readonly node?: {
        readonly __typename: "AssessmentGroupSectionNode";
        readonly id: number;
        readonly title?: string | null;
        readonly description?: any | null;
        readonly questions: {
          readonly __typename: "AssessmentGroupQuestionM2MNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "AssessmentGroupQuestionM2MNodeEdge";
            readonly node?: {
              readonly __typename: "AssessmentGroupQuestionM2MNode";
              readonly id: number;
              readonly question:
                | {
                    readonly __typename: "MultiChoiceAssessmentQuestionNode";
                    readonly id: number;
                    readonly title: string;
                    readonly description?: any | null;
                    readonly options: ReadonlyArray<string | null>;
                    readonly questionType: AssessmentQuestionType;
                  }
                | {
                    readonly __typename: "RangeAssessmentQuestionNode";
                    readonly id: number;
                    readonly title: string;
                    readonly description?: any | null;
                    readonly startValue: number;
                    readonly endValue: number;
                    readonly labels: ReadonlyArray<string | null>;
                    readonly questionType: AssessmentQuestionType;
                  }
                | {
                    readonly __typename: "TextAssessmentQuestionNode";
                    readonly id: number;
                    readonly title: string;
                    readonly description?: any | null;
                    readonly questionType: AssessmentQuestionType;
                  };
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  };
};

export type GetComplianceProgramAssessmentAnswersQueryVariables = Exact<{
  complianceProgramId: Scalars["Int"]["input"];
  includesUserMissingAssessments: Scalars["Boolean"]["input"];
  assessmentType: AssessmentType;
  selfAssessment: Scalars["Boolean"]["input"];
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetComplianceProgramAssessmentAnswersQuery = {
  readonly __typename: "Query";
  readonly complianceProgram?: {
    readonly __typename: "ComplianceProgramNode";
    readonly id: number;
    readonly performanceAssessmentTemplate?: {
      readonly __typename: "AssessmentTemplateNode";
      readonly id: number;
      readonly assessmentType: AssessmentType;
      readonly hasSelfAssessment: boolean;
      readonly questionSets: {
        readonly __typename: "AssessmentGroupNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "AssessmentGroupNodeEdge";
          readonly node?: {
            readonly __typename: "AssessmentGroupNode";
            readonly id: number;
            readonly title: string;
            readonly sections: {
              readonly __typename: "AssessmentGroupSectionNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "AssessmentGroupSectionNodeEdge";
                readonly node?: {
                  readonly __typename: "AssessmentGroupSectionNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly description?: any | null;
                  readonly questions: {
                    readonly __typename: "AssessmentGroupQuestionM2MNodeConnection";
                    readonly edges: ReadonlyArray<{
                      readonly __typename: "AssessmentGroupQuestionM2MNodeEdge";
                      readonly node?: {
                        readonly __typename: "AssessmentGroupQuestionM2MNode";
                        readonly id: number;
                        readonly question:
                          | {
                              readonly __typename: "MultiChoiceAssessmentQuestionNode";
                              readonly id: number;
                              readonly title: string;
                              readonly description?: any | null;
                              readonly options: ReadonlyArray<string | null>;
                              readonly questionType: AssessmentQuestionType;
                            }
                          | {
                              readonly __typename: "RangeAssessmentQuestionNode";
                              readonly id: number;
                              readonly title: string;
                              readonly description?: any | null;
                              readonly startValue: number;
                              readonly endValue: number;
                              readonly labels: ReadonlyArray<string | null>;
                              readonly questionType: AssessmentQuestionType;
                            }
                          | {
                              readonly __typename: "TextAssessmentQuestionNode";
                              readonly id: number;
                              readonly title: string;
                              readonly description?: any | null;
                              readonly questionType: AssessmentQuestionType;
                            };
                      } | null;
                    } | null>;
                  };
                } | null;
              } | null>;
            };
          } | null;
        } | null>;
      };
    } | null;
    readonly managerAssessmentTemplate?: {
      readonly __typename: "AssessmentTemplateNode";
      readonly id: number;
      readonly assessmentType: AssessmentType;
      readonly hasSelfAssessment: boolean;
      readonly questionSets: {
        readonly __typename: "AssessmentGroupNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "AssessmentGroupNodeEdge";
          readonly node?: {
            readonly __typename: "AssessmentGroupNode";
            readonly id: number;
            readonly title: string;
            readonly sections: {
              readonly __typename: "AssessmentGroupSectionNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "AssessmentGroupSectionNodeEdge";
                readonly node?: {
                  readonly __typename: "AssessmentGroupSectionNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly description?: any | null;
                  readonly questions: {
                    readonly __typename: "AssessmentGroupQuestionM2MNodeConnection";
                    readonly edges: ReadonlyArray<{
                      readonly __typename: "AssessmentGroupQuestionM2MNodeEdge";
                      readonly node?: {
                        readonly __typename: "AssessmentGroupQuestionM2MNode";
                        readonly id: number;
                        readonly question:
                          | {
                              readonly __typename: "MultiChoiceAssessmentQuestionNode";
                              readonly id: number;
                              readonly title: string;
                              readonly description?: any | null;
                              readonly options: ReadonlyArray<string | null>;
                              readonly questionType: AssessmentQuestionType;
                            }
                          | {
                              readonly __typename: "RangeAssessmentQuestionNode";
                              readonly id: number;
                              readonly title: string;
                              readonly description?: any | null;
                              readonly startValue: number;
                              readonly endValue: number;
                              readonly labels: ReadonlyArray<string | null>;
                              readonly questionType: AssessmentQuestionType;
                            }
                          | {
                              readonly __typename: "TextAssessmentQuestionNode";
                              readonly id: number;
                              readonly title: string;
                              readonly description?: any | null;
                              readonly questionType: AssessmentQuestionType;
                            };
                      } | null;
                    } | null>;
                  };
                } | null;
              } | null>;
            };
          } | null;
        } | null>;
      };
    } | null;
    readonly peerAssessmentTemplate?: {
      readonly __typename: "AssessmentTemplateNode";
      readonly id: number;
      readonly assessmentType: AssessmentType;
      readonly hasSelfAssessment: boolean;
      readonly questionSets: {
        readonly __typename: "AssessmentGroupNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "AssessmentGroupNodeEdge";
          readonly node?: {
            readonly __typename: "AssessmentGroupNode";
            readonly id: number;
            readonly title: string;
            readonly sections: {
              readonly __typename: "AssessmentGroupSectionNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "AssessmentGroupSectionNodeEdge";
                readonly node?: {
                  readonly __typename: "AssessmentGroupSectionNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly description?: any | null;
                  readonly questions: {
                    readonly __typename: "AssessmentGroupQuestionM2MNodeConnection";
                    readonly edges: ReadonlyArray<{
                      readonly __typename: "AssessmentGroupQuestionM2MNodeEdge";
                      readonly node?: {
                        readonly __typename: "AssessmentGroupQuestionM2MNode";
                        readonly id: number;
                        readonly question:
                          | {
                              readonly __typename: "MultiChoiceAssessmentQuestionNode";
                              readonly id: number;
                              readonly title: string;
                              readonly description?: any | null;
                              readonly options: ReadonlyArray<string | null>;
                              readonly questionType: AssessmentQuestionType;
                            }
                          | {
                              readonly __typename: "RangeAssessmentQuestionNode";
                              readonly id: number;
                              readonly title: string;
                              readonly description?: any | null;
                              readonly startValue: number;
                              readonly endValue: number;
                              readonly labels: ReadonlyArray<string | null>;
                              readonly questionType: AssessmentQuestionType;
                            }
                          | {
                              readonly __typename: "TextAssessmentQuestionNode";
                              readonly id: number;
                              readonly title: string;
                              readonly description?: any | null;
                              readonly questionType: AssessmentQuestionType;
                            };
                      } | null;
                    } | null>;
                  };
                } | null;
              } | null>;
            };
          } | null;
        } | null>;
      };
    } | null;
    readonly assessments: {
      readonly __typename: "AssessmentNodeConnection";
      readonly pageInfo: {
        readonly __typename: "PageInfo";
        readonly endCursor?: string | null;
        readonly hasNextPage: boolean;
      };
      readonly edges: ReadonlyArray<{
        readonly __typename: "AssessmentNodeEdge";
        readonly node?: {
          readonly __typename: "AssessmentNode";
          readonly id: number;
          readonly state: AssessmentState;
          readonly responder?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
          } | null;
          readonly target?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
          } | null;
          readonly answers: {
            readonly __typename: "AssessmentAnswerConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "AssessmentAnswerEdge";
              readonly node?:
                | {
                    readonly __typename: "MultiChoiceAssessmentAnswerNode";
                    readonly id: number;
                    readonly choices: ReadonlyArray<number | null>;
                    readonly question:
                      | {
                          readonly __typename: "MultiChoiceAssessmentQuestionNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "RangeAssessmentQuestionNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "TextAssessmentQuestionNode";
                          readonly id: number;
                        };
                  }
                | {
                    readonly __typename: "RangeAssessmentAnswerNode";
                    readonly id: number;
                    readonly integerAnswer?: number | null;
                    readonly question:
                      | {
                          readonly __typename: "MultiChoiceAssessmentQuestionNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "RangeAssessmentQuestionNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "TextAssessmentQuestionNode";
                          readonly id: number;
                        };
                  }
                | {
                    readonly __typename: "TextAssessmentAnswerNode";
                    readonly id: number;
                    readonly textAnswer?: any | null;
                    readonly question:
                      | {
                          readonly __typename: "MultiChoiceAssessmentQuestionNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "RangeAssessmentQuestionNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "TextAssessmentQuestionNode";
                          readonly id: number;
                        };
                  }
                | null;
            } | null>;
          };
          readonly template: {
            readonly __typename: "AssessmentTemplateNode";
            readonly id: number;
          };
        } | null;
      } | null>;
    };
    readonly usersMissingAssessment?: {
      readonly __typename: "MissingAssessmentConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "MissingAssessmentEdge";
        readonly node?: {
          readonly __typename: "MissingAssessmentNode";
          readonly responder: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
          };
          readonly target: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
          };
          readonly assessmentTemplate: {
            readonly __typename: "AssessmentTemplateNode";
            readonly id: number;
          };
        } | null;
      } | null>;
    };
  } | null;
};

export type ComplianceProgramParticipantStatusFragment = {
  readonly __typename: "ParticipantStatusNode";
  readonly oneononeStatus: ComplianceProgramParticipantStatus;
  readonly performanceAssessmentStatus: ComplianceProgramParticipantStatus;
  readonly performanceSelfAssessmentStatus: ComplianceProgramParticipantStatus;
  readonly managerAssessmentStatus: ComplianceProgramParticipantStatus;
  readonly managerSelfAssessmentStatus: ComplianceProgramParticipantStatus;
  readonly peerAssessmentStatus: ComplianceProgramParticipantStatus;
  readonly peerSelfAssessmentStatus: ComplianceProgramParticipantStatus;
  readonly meeting?: {
    readonly __typename: "MeetingNode";
    readonly id: number;
    readonly title?: string | null;
    readonly meetingGroupId: number;
  } | null;
  readonly user: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly email: string;
    readonly avatar?: string | null;
    readonly managers: {
      readonly __typename: "UserNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "UserNodeEdge";
        readonly node?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
      } | null>;
    };
  };
};

export type GetComplianceProgramQueryVariables = Exact<{
  complianceProgramId: Scalars["Int"]["input"];
}>;

export type GetComplianceProgramQuery = {
  readonly __typename: "Query";
  readonly complianceProgram?: {
    readonly __typename: "ComplianceProgramNode";
    readonly id: number;
    readonly title: string;
    readonly created: string;
    readonly updated: string;
    readonly dueDate?: string | null;
    readonly startDate?: string | null;
    readonly periodStartDate?: string | null;
    readonly periodEndDate?: string | null;
    readonly ongoing: boolean;
    readonly startDateOffset: number;
    readonly startDateDueDateOffset: number;
    readonly startDatePeriodStartOffset: number;
    readonly startDatePeriodEndOffset: number;
    readonly state: ComplianceProgramState;
    readonly recurrence: ComplianceProgramRecurrence;
    readonly appliesTo: ComplianceProgramAppliesTo;
    readonly excludedHireDateAfter?: string | null;
    readonly canUpdate?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
      readonly reason: string;
    } | null;
    readonly canDelete?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
      readonly reason: string;
    } | null;
    readonly appliesToTeams: {
      readonly __typename: "TeamNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "TeamNodeEdge";
        readonly node?: {
          readonly __typename: "TeamNode";
          readonly id: number;
          readonly title: string;
        } | null;
      } | null>;
    };
    readonly appliesToManagers: {
      readonly __typename: "UserNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "UserNodeEdge";
        readonly node?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
      } | null>;
    };
    readonly appliesToUsers: {
      readonly __typename: "UserNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "UserNodeEdge";
        readonly node?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
      } | null>;
    };
    readonly excludedUsers: {
      readonly __typename: "UserNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "UserNodeEdge";
        readonly node?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
      } | null>;
    };
    readonly requiredTopicTemplates: {
      readonly __typename: "TopicTemplateNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "TopicTemplateNodeEdge";
        readonly node?: {
          readonly __typename: "TopicTemplateNode";
          readonly id: number;
          readonly title: string;
        } | null;
      } | null>;
    };
    readonly performanceAssessmentTemplate?: {
      readonly __typename: "AssessmentTemplateNode";
      readonly id: number;
      readonly assessmentType: AssessmentType;
      readonly anonymity: AssessmentGroupAnonymity;
      readonly providers: AssessmentGroupProviders;
      readonly delivery: AssessmentGroupDelivery;
      readonly hasSelfAssessment: boolean;
      readonly isOnlySelfAssessment: boolean;
      readonly questionSets: {
        readonly __typename: "AssessmentGroupNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "AssessmentGroupNodeEdge";
          readonly node?: {
            readonly __typename: "AssessmentGroupNode";
            readonly id: number;
          } | null;
        } | null>;
      };
    } | null;
    readonly managerAssessmentTemplate?: {
      readonly __typename: "AssessmentTemplateNode";
      readonly id: number;
      readonly assessmentType: AssessmentType;
      readonly anonymity: AssessmentGroupAnonymity;
      readonly providers: AssessmentGroupProviders;
      readonly delivery: AssessmentGroupDelivery;
      readonly hasSelfAssessment: boolean;
      readonly isOnlySelfAssessment: boolean;
      readonly questionSets: {
        readonly __typename: "AssessmentGroupNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "AssessmentGroupNodeEdge";
          readonly node?: {
            readonly __typename: "AssessmentGroupNode";
            readonly id: number;
          } | null;
        } | null>;
      };
    } | null;
    readonly peerAssessmentTemplate?: {
      readonly __typename: "AssessmentTemplateNode";
      readonly id: number;
      readonly assessmentType: AssessmentType;
      readonly anonymity: AssessmentGroupAnonymity;
      readonly providers: AssessmentGroupProviders;
      readonly delivery: AssessmentGroupDelivery;
      readonly hasSelfAssessment: boolean;
      readonly isOnlySelfAssessment: boolean;
      readonly questionSets: {
        readonly __typename: "AssessmentGroupNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "AssessmentGroupNodeEdge";
          readonly node?: {
            readonly __typename: "AssessmentGroupNode";
            readonly id: number;
          } | null;
        } | null>;
      };
    } | null;
    readonly participantStatus: {
      readonly __typename: "ParticipantStatusConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "ParticipantStatusEdge";
        readonly node?: {
          readonly __typename: "ParticipantStatusNode";
          readonly oneononeStatus: ComplianceProgramParticipantStatus;
          readonly performanceAssessmentStatus: ComplianceProgramParticipantStatus;
          readonly performanceSelfAssessmentStatus: ComplianceProgramParticipantStatus;
          readonly managerAssessmentStatus: ComplianceProgramParticipantStatus;
          readonly managerSelfAssessmentStatus: ComplianceProgramParticipantStatus;
          readonly peerAssessmentStatus: ComplianceProgramParticipantStatus;
          readonly peerSelfAssessmentStatus: ComplianceProgramParticipantStatus;
          readonly meeting?: {
            readonly __typename: "MeetingNode";
            readonly id: number;
            readonly title?: string | null;
            readonly meetingGroupId: number;
          } | null;
          readonly user: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly email: string;
            readonly avatar?: string | null;
            readonly managers: {
              readonly __typename: "UserNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "UserNodeEdge";
                readonly node?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
              } | null>;
            };
          };
        } | null;
      } | null>;
    };
  } | null;
};

export type ComplianceProgramReportParticipantStatusFragment = {
  readonly __typename: "ParticipantStatusNode";
  readonly oneononeStatus: ComplianceProgramParticipantStatus;
  readonly performanceAssessmentStatus: ComplianceProgramParticipantStatus;
  readonly performanceSelfAssessmentStatus: ComplianceProgramParticipantStatus;
  readonly managerAssessmentStatus: ComplianceProgramParticipantStatus;
  readonly managerSelfAssessmentStatus: ComplianceProgramParticipantStatus;
  readonly peerAssessmentStatus: ComplianceProgramParticipantStatus;
  readonly peerSelfAssessmentStatus: ComplianceProgramParticipantStatus;
  readonly meeting?: {
    readonly __typename: "MeetingNode";
    readonly id: number;
    readonly title?: string | null;
    readonly meetingGroupId: number;
  } | null;
  readonly user: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly email: string;
    readonly avatar?: string | null;
    readonly managers: {
      readonly __typename: "UserNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "UserNodeEdge";
        readonly node?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
      } | null>;
    };
    readonly teams?: {
      readonly __typename: "TeamNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "TeamNodeEdge";
        readonly node?: {
          readonly __typename: "TeamNode";
          readonly id: number;
          readonly title: string;
        } | null;
      } | null>;
    } | null;
  };
};

export type GetComplianceProgramReportQueryVariables = Exact<{
  complianceProgramId: Scalars["Int"]["input"];
}>;

export type GetComplianceProgramReportQuery = {
  readonly __typename: "Query";
  readonly assessmentsOpenForNominations: {
    readonly __typename: "AssessmentOpenForNominationConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "AssessmentOpenForNominationEdge";
      readonly node?: {
        readonly __typename: "AssessmentOpenForNominationNode";
        readonly id?: string | null;
        readonly targetUser: {
          readonly __typename: "UserNode";
          readonly id: number;
        };
        readonly nominations: {
          readonly __typename: "AssessmentNominationNodeConnection";
          readonly totalCount: number;
        };
      } | null;
    } | null>;
  };
  readonly complianceProgram?: {
    readonly __typename: "ComplianceProgramNode";
    readonly id: number;
    readonly title: string;
    readonly created: string;
    readonly updated: string;
    readonly dueDate?: string | null;
    readonly startDate?: string | null;
    readonly periodStartDate?: string | null;
    readonly periodEndDate?: string | null;
    readonly ongoing: boolean;
    readonly startDateOffset: number;
    readonly startDateDueDateOffset: number;
    readonly startDatePeriodStartOffset: number;
    readonly startDatePeriodEndOffset: number;
    readonly state: ComplianceProgramState;
    readonly recurrence: ComplianceProgramRecurrence;
    readonly appliesTo: ComplianceProgramAppliesTo;
    readonly excludedHireDateAfter?: string | null;
    readonly canUpdate?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
      readonly reason: string;
    } | null;
    readonly canDelete?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
      readonly reason: string;
    } | null;
    readonly appliesToTeams: {
      readonly __typename: "TeamNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "TeamNodeEdge";
        readonly node?: {
          readonly __typename: "TeamNode";
          readonly id: number;
          readonly title: string;
        } | null;
      } | null>;
    };
    readonly appliesToManagers: {
      readonly __typename: "UserNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "UserNodeEdge";
        readonly node?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
      } | null>;
    };
    readonly appliesToUsers: {
      readonly __typename: "UserNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "UserNodeEdge";
        readonly node?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
      } | null>;
    };
    readonly excludedUsers: {
      readonly __typename: "UserNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "UserNodeEdge";
        readonly node?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
      } | null>;
    };
    readonly requiredTopicTemplates: {
      readonly __typename: "TopicTemplateNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "TopicTemplateNodeEdge";
        readonly node?: {
          readonly __typename: "TopicTemplateNode";
          readonly id: number;
          readonly title: string;
        } | null;
      } | null>;
    };
    readonly performanceAssessmentTemplate?: {
      readonly __typename: "AssessmentTemplateNode";
      readonly id: number;
      readonly assessmentType: AssessmentType;
      readonly hasSelfAssessment: boolean;
      readonly isOnlySelfAssessment: boolean;
    } | null;
    readonly managerAssessmentTemplate?: {
      readonly __typename: "AssessmentTemplateNode";
      readonly id: number;
      readonly assessmentType: AssessmentType;
      readonly hasSelfAssessment: boolean;
      readonly isOnlySelfAssessment: boolean;
    } | null;
    readonly peerAssessmentTemplate?: {
      readonly __typename: "AssessmentTemplateNode";
      readonly id: number;
      readonly assessmentType: AssessmentType;
      readonly hasSelfAssessment: boolean;
      readonly isOnlySelfAssessment: boolean;
    } | null;
    readonly participantStatus: {
      readonly __typename: "ParticipantStatusConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "ParticipantStatusEdge";
        readonly node?: {
          readonly __typename: "ParticipantStatusNode";
          readonly oneononeStatus: ComplianceProgramParticipantStatus;
          readonly performanceAssessmentStatus: ComplianceProgramParticipantStatus;
          readonly performanceSelfAssessmentStatus: ComplianceProgramParticipantStatus;
          readonly managerAssessmentStatus: ComplianceProgramParticipantStatus;
          readonly managerSelfAssessmentStatus: ComplianceProgramParticipantStatus;
          readonly peerAssessmentStatus: ComplianceProgramParticipantStatus;
          readonly peerSelfAssessmentStatus: ComplianceProgramParticipantStatus;
          readonly meeting?: {
            readonly __typename: "MeetingNode";
            readonly id: number;
            readonly title?: string | null;
            readonly meetingGroupId: number;
          } | null;
          readonly user: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly email: string;
            readonly avatar?: string | null;
            readonly managers: {
              readonly __typename: "UserNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "UserNodeEdge";
                readonly node?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
              } | null>;
            };
            readonly teams?: {
              readonly __typename: "TeamNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "TeamNodeEdge";
                readonly node?: {
                  readonly __typename: "TeamNode";
                  readonly id: number;
                  readonly title: string;
                } | null;
              } | null>;
            } | null;
          };
        } | null;
      } | null>;
    };
  } | null;
};

export type GetComplianceProgramsQueryVariables = Exact<{
  applicableUser?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId: Scalars["Int"]["input"];
  state?: InputMaybe<ComplianceProgramState>;
  assessmentType?: InputMaybe<AssessmentType>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  dueDateGte?: InputMaybe<Scalars["Date"]["input"]>;
  dueDateLte?: InputMaybe<Scalars["Date"]["input"]>;
}>;

export type GetComplianceProgramsQuery = {
  readonly __typename: "Query";
  readonly compliancePrograms: {
    readonly __typename: "ComplianceProgramNodeConnection";
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor?: string | null;
      readonly hasNextPage: boolean;
    };
    readonly edges: ReadonlyArray<{
      readonly __typename: "ComplianceProgramNodeEdge";
      readonly node?: {
        readonly __typename: "ComplianceProgramNode";
        readonly id: number;
        readonly title: string;
        readonly created: string;
        readonly updated: string;
        readonly dueDate?: string | null;
        readonly startDate?: string | null;
        readonly ongoing: boolean;
        readonly periodStartDate?: string | null;
        readonly periodEndDate?: string | null;
        readonly state: ComplianceProgramState;
        readonly recurrence: ComplianceProgramRecurrence;
        readonly requiredTopicTemplates: {
          readonly __typename: "TopicTemplateNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "TopicTemplateNodeEdge";
            readonly node?: {
              readonly __typename: "TopicTemplateNode";
              readonly id: number;
              readonly title: string;
            } | null;
          } | null>;
        };
        readonly canUpdate?: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        } | null;
        readonly performanceAssessmentTemplate?: {
          readonly __typename: "AssessmentTemplateNode";
          readonly id: number;
        } | null;
        readonly managerAssessmentTemplate?: {
          readonly __typename: "AssessmentTemplateNode";
          readonly id: number;
        } | null;
        readonly peerAssessmentTemplate?: {
          readonly __typename: "AssessmentTemplateNode";
          readonly id: number;
        } | null;
      } | null;
    } | null>;
  };
};

export type UserActivityComplianceGroupAssessmentDeliveryFragment = {
  readonly __typename: "AssessmentDeliveryNode";
  readonly id: number;
  readonly state: AssessmentDeliveryState;
  readonly deliveryDatetime?: string | null;
  readonly lastSubmissionDatetime?: string | null;
  readonly target?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly email: string;
    readonly firstName: string;
    readonly avatar?: string | null;
  } | null;
  readonly complianceProgram?: {
    readonly __typename: "ComplianceProgramNode";
    readonly id: number;
    readonly title: string;
  } | null;
  readonly template: {
    readonly __typename: "AssessmentTemplateNode";
    readonly id: number;
    readonly assessmentType: AssessmentType;
  };
};

export type UserActivityComplianceGroupAssessmentTemplateFragment = {
  readonly __typename: "AssessmentTemplateNode";
  readonly id: number;
  readonly assessmentType: AssessmentType;
  readonly hasSelfAssessment: boolean;
  readonly assessments: {
    readonly __typename: "AssessmentNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "AssessmentNodeEdge";
      readonly node?: {
        readonly __typename: "AssessmentNode";
        readonly id: number;
        readonly state: AssessmentState;
        readonly target?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
      } | null;
    } | null>;
  };
};

export type UserActivityComplianceProgramMeetingFragment = {
  readonly __typename: "MeetingNode";
  readonly id: number;
  readonly title?: string | null;
  readonly meetingGroupId: number;
  readonly isFinalized: boolean;
  readonly startDatetime?: string | null;
  readonly meetingGroup?: {
    readonly __typename: "MeetingGroupNode";
    readonly id: number;
    readonly topicTemplate?: {
      readonly __typename: "TopicTemplateNode";
      readonly id: number;
    } | null;
  } | null;
  readonly participants?: {
    readonly __typename: "MeetingParticipantConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "MeetingParticipantEdge";
      readonly node?: {
        readonly __typename: "MeetingParticipantNode";
        readonly id?: string | null;
        readonly status?: AttendeeStatus | null;
        readonly user?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
          readonly email: string;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetUserActivityInComplianceProgramQueryVariables = Exact<{
  complianceProgramId: Scalars["Int"]["input"];
  responderId: Scalars["Int"]["input"];
}>;

export type GetUserActivityInComplianceProgramQuery = {
  readonly __typename: "Query";
  readonly assessmentsOpenForNominations: {
    readonly __typename: "AssessmentOpenForNominationConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "AssessmentOpenForNominationEdge";
      readonly node?: {
        readonly __typename: "AssessmentOpenForNominationNode";
        readonly id?: string | null;
        readonly complianceProgram: {
          readonly __typename: "ComplianceProgramNode";
          readonly id: number;
          readonly title: string;
        };
        readonly assessmentTemplate: {
          readonly __typename: "AssessmentTemplateNode";
          readonly id: number;
          readonly assessmentType: AssessmentType;
        };
        readonly targetUser: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        };
        readonly nominations: {
          readonly __typename: "AssessmentNominationNodeConnection";
          readonly totalCount: number;
        };
      } | null;
    } | null>;
  };
  readonly assessmentDeliveries: {
    readonly __typename: "AssessmentDeliveryNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "AssessmentDeliveryNodeEdge";
      readonly node?: {
        readonly __typename: "AssessmentDeliveryNode";
        readonly id: number;
        readonly state: AssessmentDeliveryState;
        readonly deliveryDatetime?: string | null;
        readonly lastSubmissionDatetime?: string | null;
        readonly target?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly email: string;
          readonly firstName: string;
          readonly avatar?: string | null;
        } | null;
        readonly complianceProgram?: {
          readonly __typename: "ComplianceProgramNode";
          readonly id: number;
          readonly title: string;
        } | null;
        readonly template: {
          readonly __typename: "AssessmentTemplateNode";
          readonly id: number;
          readonly assessmentType: AssessmentType;
        };
      } | null;
    } | null>;
  };
  readonly complianceProgram?: {
    readonly __typename: "ComplianceProgramNode";
    readonly id: number;
    readonly title: string;
    readonly dueDate?: string | null;
    readonly startDate?: string | null;
    readonly ongoing: boolean;
    readonly state: ComplianceProgramState;
    readonly canUpdate?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
      readonly reason: string;
    } | null;
    readonly requiredTopicTemplates: {
      readonly __typename: "TopicTemplateNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "TopicTemplateNodeEdge";
        readonly node?: {
          readonly __typename: "TopicTemplateNode";
          readonly id: number;
          readonly title: string;
        } | null;
      } | null>;
    };
    readonly matchingOneonones?: {
      readonly __typename: "MeetingNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "MeetingNodeEdge";
        readonly node?: {
          readonly __typename: "MeetingNode";
          readonly id: number;
          readonly title?: string | null;
          readonly meetingGroupId: number;
          readonly isFinalized: boolean;
          readonly startDatetime?: string | null;
          readonly meetingGroup?: {
            readonly __typename: "MeetingGroupNode";
            readonly id: number;
            readonly topicTemplate?: {
              readonly __typename: "TopicTemplateNode";
              readonly id: number;
            } | null;
          } | null;
          readonly participants?: {
            readonly __typename: "MeetingParticipantConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "MeetingParticipantEdge";
              readonly node?: {
                readonly __typename: "MeetingParticipantNode";
                readonly id?: string | null;
                readonly status?: AttendeeStatus | null;
                readonly user?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                  readonly email: string;
                } | null;
              } | null;
            } | null>;
          } | null;
        } | null;
      } | null>;
    } | null;
    readonly usersMissingAssessment: {
      readonly __typename: "MissingAssessmentConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "MissingAssessmentEdge";
        readonly node?: {
          readonly __typename: "MissingAssessmentNode";
          readonly responder: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
          };
          readonly target: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
          };
          readonly assessmentTemplate: {
            readonly __typename: "AssessmentTemplateNode";
            readonly id: number;
          };
        } | null;
      } | null>;
    };
    readonly performanceAssessmentTemplate?: {
      readonly __typename: "AssessmentTemplateNode";
      readonly id: number;
      readonly assessmentType: AssessmentType;
      readonly hasSelfAssessment: boolean;
      readonly assessments: {
        readonly __typename: "AssessmentNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "AssessmentNodeEdge";
          readonly node?: {
            readonly __typename: "AssessmentNode";
            readonly id: number;
            readonly state: AssessmentState;
            readonly target?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
          } | null;
        } | null>;
      };
    } | null;
    readonly managerAssessmentTemplate?: {
      readonly __typename: "AssessmentTemplateNode";
      readonly id: number;
      readonly assessmentType: AssessmentType;
      readonly hasSelfAssessment: boolean;
      readonly assessments: {
        readonly __typename: "AssessmentNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "AssessmentNodeEdge";
          readonly node?: {
            readonly __typename: "AssessmentNode";
            readonly id: number;
            readonly state: AssessmentState;
            readonly target?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
          } | null;
        } | null>;
      };
    } | null;
    readonly peerAssessmentTemplate?: {
      readonly __typename: "AssessmentTemplateNode";
      readonly id: number;
      readonly assessmentType: AssessmentType;
      readonly hasSelfAssessment: boolean;
      readonly assessments: {
        readonly __typename: "AssessmentNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "AssessmentNodeEdge";
          readonly node?: {
            readonly __typename: "AssessmentNode";
            readonly id: number;
            readonly state: AssessmentState;
            readonly target?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
          } | null;
        } | null>;
      };
    } | null;
    readonly assessments: {
      readonly __typename: "AssessmentNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "AssessmentNodeEdge";
        readonly node?: {
          readonly __typename: "AssessmentNode";
          readonly id: number;
          readonly state: AssessmentState;
          readonly target?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
          } | null;
          readonly template: {
            readonly __typename: "AssessmentTemplateNode";
            readonly id: number;
          };
        } | null;
      } | null>;
    };
    readonly applicableReports: {
      readonly __typename: "UserNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "UserNodeEdge";
        readonly node?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
          readonly email: string;
        } | null;
      } | null>;
    };
    readonly applicableManagers: {
      readonly __typename: "UserNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "UserNodeEdge";
        readonly node?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
          readonly email: string;
        } | null;
      } | null>;
    };
  } | null;
};

export type SendAssessmentReminderMutationVariables = Exact<{
  complianceProgramId: Scalars["Int"]["input"];
}>;

export type SendAssessmentReminderMutation = {
  readonly __typename: "Mutation";
  readonly emailAssessmentReminder?: {
    readonly __typename: "EmailAssessmentReminderMutation";
    readonly complianceProgram?: {
      readonly __typename: "ComplianceProgramNode";
      readonly id: number;
    } | null;
  } | null;
};

export type SendOneononeProgramReminderMutationVariables = Exact<{
  complianceProgramId: Scalars["Int"]["input"];
}>;

export type SendOneononeProgramReminderMutation = {
  readonly __typename: "Mutation";
  readonly emailOneononeReminder?: {
    readonly __typename: "EmailOneOnOneReminderMutation";
    readonly complianceProgram?: {
      readonly __typename: "ComplianceProgramNode";
      readonly id: number;
    } | null;
  } | null;
};

export type GetPusherArtifactQueryQueryVariables = Exact<{
  artifactId: Scalars["Int"]["input"];
  keyResultLimit: Scalars["Int"]["input"];
}>;

export type GetPusherArtifactQueryQuery = {
  readonly __typename: "Query";
  readonly artifact:
    | {
        readonly __typename: "ActionItemArtifactNode";
        readonly isComplete?: boolean | null;
        readonly actionItemState?: number | null;
        readonly dueDate?: string | null;
        readonly id: number;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly assignee?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly avatar?: string | null;
          readonly name: string;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "AsanaTaskArtifactNode";
        readonly id: number;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "DecisionArtifactNode";
        readonly decision: string;
        readonly isDraft?: boolean | null;
        readonly decisionState?: DecisionState | null;
        readonly id: number;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "DocumentArtifactNode";
        readonly id: number;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "FeedbackArtifactNode";
        readonly id: number;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "GithubIssuesArtifactNode";
        readonly id: number;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "GoalArtifactNode";
        readonly id: number;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly created: string;
        readonly state: GoalState;
        readonly status: GoalStatus;
        readonly scope: GoalScope;
        readonly goalVisibility: GoalVisibility;
        readonly dueDate?: string | null;
        readonly startDate?: string | null;
        readonly progress: number;
        readonly progressType: GoalProgressType;
        readonly startValue: number;
        readonly currentValue: number;
        readonly targetValue: number;
        readonly isStale: boolean;
        readonly ownerCount?: number | null;
        readonly contributorCount?: number | null;
        readonly keyResults: {
          readonly __typename: "KeyResultNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "KeyResultNodeEdge";
            readonly node?: {
              readonly __typename: "KeyResultNode";
              readonly id: number;
              readonly title: string;
              readonly startValue: number;
              readonly currentValue: number;
              readonly targetValue: number;
              readonly progressType: GoalProgressType;
              readonly kpi?: {
                readonly __typename: "KPINode";
                readonly id: number;
                readonly title: string;
                readonly currentMeasurement?: {
                  readonly __typename: "KPIMeasurementNode";
                  readonly id: number;
                  readonly measurement: number;
                } | null;
              } | null;
              readonly canUpdate?: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              } | null;
              readonly assignee?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
                readonly email: string;
              } | null;
            } | null;
          } | null>;
        };
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly firstOwner?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly avatar?: string | null;
          readonly name: string;
        } | null;
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canRead: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "KPIArtifactNode";
        readonly id: number;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "RatingArtifactNode";
        readonly id: number;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "RecognitionArtifactNode";
        readonly id: number;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly created: string;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly recipients?: {
          readonly __typename: "UserNodeConnection";
          readonly totalCount: number;
          readonly edges: ReadonlyArray<{
            readonly __typename: "UserNodeEdge";
            readonly node?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
          } | null>;
        } | null;
        readonly recognitionCoreValue?: {
          readonly __typename: "RecognitionCoreValueNode";
          readonly id: number;
          readonly title: string;
          readonly description: string;
          readonly emoji?: string | null;
          readonly image?: string | null;
        } | null;
      };
};

export type GetMeetingGroupOneononeStatusQueryQueryVariables = Exact<{
  meetingGroupId: Scalars["Int"]["input"];
}>;

export type GetMeetingGroupOneononeStatusQueryQuery = {
  readonly __typename: "Query";
  readonly meetingGroup?: {
    readonly __typename: "MeetingGroupNode";
    readonly id: number;
    readonly title: string;
    readonly isFormalOneonone: boolean;
    readonly canUpdate?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
    } | null;
    readonly canDelete?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
    } | null;
    readonly participants: {
      readonly __typename: "MeetingParticipantConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "MeetingParticipantEdge";
        readonly node?: {
          readonly __typename: "MeetingParticipantNode";
          readonly id?: string | null;
          readonly user?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly firstName: string;
            readonly name: string;
            readonly avatar?: string | null;
          } | null;
        } | null;
      } | null>;
    };
    readonly facilitator?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
      readonly avatar?: string | null;
    } | null;
  } | null;
};

export type GetPusherMeetingTranscriptionStatusQueryQueryVariables = Exact<{
  meetingId: Scalars["Int"]["input"];
}>;

export type GetPusherMeetingTranscriptionStatusQueryQuery = {
  readonly __typename: "Query";
  readonly meeting?: {
    readonly __typename: "MeetingNode";
    readonly id: number;
    readonly transcriptionStatus?: boolean | null;
  } | null;
};

export type GetTopicQueryVariables = Exact<{
  topicId: Scalars["Int"]["input"];
}>;

export type GetTopicQuery = {
  readonly __typename: "Query";
  readonly topic?: {
    readonly __typename: "TopicNode";
    readonly id: number;
    readonly title: string;
    readonly description?: string | null;
    readonly discussionNotes?: any | null;
    readonly includesIndividualNotes: boolean;
    readonly includesIndividualNotesForCurrentUser: boolean;
    readonly state?: TopicState | null;
    readonly created: string;
    readonly assignee?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly avatar?: string | null;
    } | null;
    readonly creator?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
    } | null;
    readonly meeting?: {
      readonly __typename: "MeetingNode";
      readonly id: number;
      readonly topics?: {
        readonly __typename: "TopicNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "TopicNodeEdge";
          readonly node?: {
            readonly __typename: "TopicNode";
            readonly id: number;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type CreateOrUpdateRatingAnswerMutationMutationVariables = Exact<{
  integerAnswer: Scalars["Int"]["input"];
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  ratingAnswerId?: InputMaybe<Scalars["Int"]["input"]>;
  ratingArtifactId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingRatingRequirementId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type CreateOrUpdateRatingAnswerMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateRatingAnswer?: {
    readonly __typename: "CreateOrUpdateRatingAnswerMutation";
    readonly ratingRequirement?: {
      readonly __typename: "MeetingRatingRequirementNode";
      readonly id: number;
      readonly ratingArtifact?: {
        readonly __typename: "RatingArtifactNode";
        readonly id: number;
        readonly canAnswer?: boolean | null;
        readonly canViewAnswers?: boolean | null;
        readonly artifactType: ArtifactType;
        readonly answers?: {
          readonly __typename: "RatingAnswerNodeConnection";
          readonly totalCount?: number | null;
          readonly edges: ReadonlyArray<{
            readonly __typename: "RatingAnswerNodeEdge";
            readonly node?: {
              readonly __typename: "RatingAnswerNode";
              readonly id: number;
              readonly integerAnswer: number;
              readonly creator: {
                readonly __typename: "UserNode";
                readonly id: number;
              };
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null;
    readonly ratingAnswer?: {
      readonly __typename: "RatingAnswerNode";
      readonly id: number;
      readonly integerAnswer: number;
      readonly creator: {
        readonly __typename: "UserNode";
        readonly id: number;
      };
      readonly createdInMeeting?: {
        readonly __typename: "MeetingNode";
        readonly id: number;
      } | null;
      readonly rating?: {
        readonly __typename: "RatingNode";
        readonly id: number;
      } | null;
      readonly createdFromArtifact?: {
        readonly __typename: "RatingArtifactNode";
        readonly id: number;
        readonly canAnswer?: boolean | null;
        readonly canViewAnswers?: boolean | null;
        readonly answers?: {
          readonly __typename: "RatingAnswerNodeConnection";
          readonly totalCount?: number | null;
          readonly edges: ReadonlyArray<{
            readonly __typename: "RatingAnswerNodeEdge";
            readonly node?: {
              readonly __typename: "RatingAnswerNode";
              readonly id: number;
              readonly integerAnswer: number;
              readonly creator: {
                readonly __typename: "UserNode";
                readonly id: number;
              };
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CreateOrUpdateRatingMutationMutationVariables = Exact<{
  title?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  ratingId?: InputMaybe<Scalars["Int"]["input"]>;
  startValue?: InputMaybe<Scalars["Int"]["input"]>;
  endValue?: InputMaybe<Scalars["Int"]["input"]>;
  labels:
    | ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>;
}>;

export type CreateOrUpdateRatingMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateRating?: {
    readonly __typename: "CreateOrUpdateRatingMutation";
    readonly rating?: {
      readonly __typename: "RatingNode";
      readonly id: number;
      readonly title: string;
    } | null;
  } | null;
};

export type DeleteRatingMutationMutationVariables = Exact<{
  ratingId: Scalars["Int"]["input"];
}>;

export type DeleteRatingMutationMutation = {
  readonly __typename: "Mutation";
  readonly deleteRating?: {
    readonly __typename: "DeleteRatingMutation";
    readonly ok?: boolean | null;
  } | null;
};

export type GetRatingArtifactAnswersQueryVariables = Exact<{
  artifactId: Scalars["Int"]["input"];
}>;

export type GetRatingArtifactAnswersQuery = {
  readonly __typename: "Query";
  readonly artifact:
    | { readonly __typename: "ActionItemArtifactNode"; readonly id: number }
    | { readonly __typename: "AsanaTaskArtifactNode"; readonly id: number }
    | { readonly __typename: "DecisionArtifactNode"; readonly id: number }
    | { readonly __typename: "DocumentArtifactNode"; readonly id: number }
    | { readonly __typename: "FeedbackArtifactNode"; readonly id: number }
    | { readonly __typename: "GithubIssuesArtifactNode"; readonly id: number }
    | { readonly __typename: "GoalArtifactNode"; readonly id: number }
    | { readonly __typename: "KPIArtifactNode"; readonly id: number }
    | {
        readonly __typename: "RatingArtifactNode";
        readonly canAnswer?: boolean | null;
        readonly canViewAnswers?: boolean | null;
        readonly id: number;
        readonly rating?: {
          readonly __typename: "RatingNode";
          readonly id: number;
          readonly title: string;
          readonly startValue: number;
          readonly endValue: number;
          readonly labels: ReadonlyArray<string | null>;
        } | null;
        readonly answers?: {
          readonly __typename: "RatingAnswerNodeConnection";
          readonly totalCount?: number | null;
          readonly edges: ReadonlyArray<{
            readonly __typename: "RatingAnswerNodeEdge";
            readonly node?: {
              readonly __typename: "RatingAnswerNode";
              readonly id: number;
              readonly created: string;
              readonly integerAnswer: number;
              readonly creator: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
              };
            } | null;
          } | null>;
        } | null;
      }
    | { readonly __typename: "RecognitionArtifactNode"; readonly id: number };
};

export type GetRatingQueryVariables = Exact<{
  ratingId: Scalars["Int"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetRatingQuery = {
  readonly __typename: "Query";
  readonly rating?: {
    readonly __typename: "RatingNode";
    readonly id: number;
    readonly title: string;
    readonly description: any;
    readonly startValue: number;
    readonly endValue: number;
    readonly labels: ReadonlyArray<string | null>;
    readonly created: string;
    readonly creator?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
    } | null;
    readonly organization?: {
      readonly __typename: "OrganizationNode";
      readonly id: number;
      readonly name: string;
    } | null;
    readonly canDelete?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
      readonly reason: string;
    } | null;
    readonly canUpdate?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
      readonly reason: string;
    } | null;
    readonly answers?: {
      readonly __typename: "RatingAnswerNodeConnection";
      readonly totalCount?: number | null;
      readonly averageRating?: number | null;
      readonly pageInfo: {
        readonly __typename: "PageInfo";
        readonly endCursor?: string | null;
        readonly hasNextPage: boolean;
      };
      readonly edges: ReadonlyArray<{
        readonly __typename: "RatingAnswerNodeEdge";
        readonly node?: {
          readonly __typename: "RatingAnswerNode";
          readonly id: number;
          readonly created: string;
          readonly integerAnswer: number;
          readonly creator: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
          };
          readonly createdInMeeting?: {
            readonly __typename: "MeetingNode";
            readonly id: number;
            readonly title?: string | null;
            readonly meetingGroupId: number;
            readonly startDatetime?: string | null;
          } | null;
        } | null;
      } | null>;
    } | null;
  } | null;
  readonly topicTemplates?: {
    readonly __typename: "TopicTemplateNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "TopicTemplateNodeEdge";
      readonly node?: {
        readonly __typename: "TopicTemplateNode";
        readonly id: number;
        readonly title: string;
      } | null;
    } | null>;
  } | null;
};

export type GetRatingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetRatingsQuery = {
  readonly __typename: "Query";
  readonly ratings?: {
    readonly __typename: "RatingNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "RatingNodeEdge";
      readonly node?: {
        readonly __typename: "RatingNode";
        readonly id: number;
        readonly title: string;
        readonly created: string;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
        } | null;
        readonly answers?: {
          readonly __typename: "RatingAnswerNodeConnection";
          readonly totalCount?: number | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetRecognitionBoardCoreValuesQueryQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
  recipientId?: InputMaybe<Scalars["Int"]["input"]>;
  coreValueId?: InputMaybe<Scalars["Int"]["input"]>;
  receivedByMembersOfTeamId?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
    | InputMaybe<Scalars["Date"]["input"]>
  >;
  statsStartDate?: InputMaybe<Scalars["Date"]["input"]>;
  statsEndDate?: InputMaybe<Scalars["Date"]["input"]>;
}>;

export type GetRecognitionBoardCoreValuesQueryQuery = {
  readonly __typename: "Query";
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
    readonly coreValues?: {
      readonly __typename: "RecognitionCoreValueNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "RecognitionCoreValueNodeEdge";
        readonly node?: {
          readonly __typename: "RecognitionCoreValueNode";
          readonly id: number;
          readonly title: string;
          readonly description: string;
          readonly emoji?: string | null;
          readonly image?: string | null;
          readonly recognitions?: {
            readonly __typename: "ArtifactConnection";
            readonly totalCount: number;
          } | null;
        } | null;
      } | null>;
    } | null;
    readonly recognitionStats?: {
      readonly __typename: "RecognitionStatsNode";
      readonly recognitionCount?: number | null;
      readonly userWhoGaveMostRecognitionCount?: number | null;
      readonly userWhoReceivedMostRecognitionCount?: number | null;
      readonly userWhoGaveMostRecognition?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly avatar?: string | null;
        readonly name: string;
      } | null;
      readonly userWhoReceivedMostRecognition?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly avatar?: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetRecognitionBoardQueryQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
  limit: Scalars["Int"]["input"];
  after?: InputMaybe<Scalars["String"]["input"]>;
  coreValueId?: InputMaybe<Scalars["Int"]["input"]>;
  recipientId?: InputMaybe<Scalars["Int"]["input"]>;
  receivedByMembersOfTeamId?: InputMaybe<Scalars["Int"]["input"]>;
  createdBetweenDates?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Date"]["input"]>>
    | InputMaybe<Scalars["Date"]["input"]>
  >;
}>;

export type GetRecognitionBoardQueryQuery = {
  readonly __typename: "Query";
  readonly artifacts?: {
    readonly __typename: "ArtifactConnection";
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor?: string | null;
      readonly hasNextPage: boolean;
    };
    readonly edges: ReadonlyArray<{
      readonly __typename: "ArtifactEdge";
      readonly node?:
        | { readonly __typename: "ActionItemArtifactNode"; readonly id: number }
        | { readonly __typename: "AsanaTaskArtifactNode"; readonly id: number }
        | { readonly __typename: "DecisionArtifactNode"; readonly id: number }
        | { readonly __typename: "DocumentArtifactNode"; readonly id: number }
        | { readonly __typename: "FeedbackArtifactNode"; readonly id: number }
        | {
            readonly __typename: "GithubIssuesArtifactNode";
            readonly id: number;
          }
        | { readonly __typename: "GoalArtifactNode"; readonly id: number }
        | { readonly __typename: "KPIArtifactNode"; readonly id: number }
        | { readonly __typename: "RatingArtifactNode"; readonly id: number }
        | {
            readonly __typename: "RecognitionArtifactNode";
            readonly id: number;
            readonly artifactType: ArtifactType;
            readonly title?: string | null;
            readonly created: string;
            readonly creator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
            readonly recipients?: {
              readonly __typename: "UserNodeConnection";
              readonly totalCount: number;
              readonly edges: ReadonlyArray<{
                readonly __typename: "UserNodeEdge";
                readonly node?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
              } | null>;
            } | null;
            readonly recognitionCoreValue?: {
              readonly __typename: "RecognitionCoreValueNode";
              readonly id: number;
              readonly title: string;
              readonly description: string;
              readonly emoji?: string | null;
              readonly image?: string | null;
            } | null;
          }
        | null;
    } | null>;
  } | null;
};

export type GetAdoptionReportQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
  attributes:
    | ReadonlyArray<InputMaybe<AdoptionReportAttribute>>
    | InputMaybe<AdoptionReportAttribute>;
  metrics:
    | ReadonlyArray<InputMaybe<AdoptionReportMetric>>
    | InputMaybe<AdoptionReportMetric>;
  filters:
    | ReadonlyArray<InputMaybe<ReportFilterInput>>
    | InputMaybe<ReportFilterInput>;
}>;

export type GetAdoptionReportQuery = {
  readonly __typename: "Query";
  readonly adoptionReport: {
    readonly __typename: "ReportResponseNode";
    readonly totalValues: ReadonlyArray<number | null>;
    readonly data: ReadonlyArray<{
      readonly __typename: "ReportResponseRowNode";
      readonly attributeValues: ReadonlyArray<string | null>;
      readonly metricValues: ReadonlyArray<number | null>;
    } | null>;
  };
};

export type ComplianceProgramTargetFragment = {
  readonly __typename: "UserNode";
  readonly id: number;
  readonly name: string;
  readonly avatar?: string | null;
  readonly managers: {
    readonly __typename: "UserNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "UserNodeEdge";
      readonly node?: {
        readonly __typename: "UserNode";
        readonly id: number;
      } | null;
    } | null>;
  };
  readonly teams?: {
    readonly __typename: "TeamNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "TeamNodeEdge";
      readonly node?: {
        readonly __typename: "TeamNode";
        readonly id: number;
      } | null;
    } | null>;
  } | null;
};

export type ComplianceProgramForReportAssessmentGroupSectionFragment = {
  readonly __typename: "AssessmentGroupSectionNode";
  readonly id: number;
  readonly title?: string | null;
  readonly description?: any | null;
  readonly questions: {
    readonly __typename: "AssessmentGroupQuestionM2MNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "AssessmentGroupQuestionM2MNodeEdge";
      readonly node?: {
        readonly __typename: "AssessmentGroupQuestionM2MNode";
        readonly id: number;
        readonly question:
          | {
              readonly __typename: "MultiChoiceAssessmentQuestionNode";
              readonly id: number;
              readonly title: string;
              readonly description?: any | null;
              readonly options: ReadonlyArray<string | null>;
              readonly questionType: AssessmentQuestionType;
              readonly responses: AssessmentQuestionResponses;
            }
          | {
              readonly __typename: "RangeAssessmentQuestionNode";
              readonly id: number;
              readonly title: string;
              readonly description?: any | null;
              readonly startValue: number;
              readonly endValue: number;
              readonly labels: ReadonlyArray<string | null>;
              readonly questionType: AssessmentQuestionType;
              readonly responses: AssessmentQuestionResponses;
            }
          | {
              readonly __typename: "TextAssessmentQuestionNode";
              readonly id: number;
              readonly title: string;
              readonly description?: any | null;
              readonly questionType: AssessmentQuestionType;
              readonly responses: AssessmentQuestionResponses;
            };
      } | null;
    } | null>;
  };
};

export type ComplianceProgramForReportAssessmentGroupFragment = {
  readonly __typename: "AssessmentGroupNode";
  readonly id: number;
  readonly title: string;
  readonly sections: {
    readonly __typename: "AssessmentGroupSectionNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "AssessmentGroupSectionNodeEdge";
      readonly node?: {
        readonly __typename: "AssessmentGroupSectionNode";
        readonly id: number;
        readonly title?: string | null;
        readonly description?: any | null;
        readonly questions: {
          readonly __typename: "AssessmentGroupQuestionM2MNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "AssessmentGroupQuestionM2MNodeEdge";
            readonly node?: {
              readonly __typename: "AssessmentGroupQuestionM2MNode";
              readonly id: number;
              readonly question:
                | {
                    readonly __typename: "MultiChoiceAssessmentQuestionNode";
                    readonly id: number;
                    readonly title: string;
                    readonly description?: any | null;
                    readonly options: ReadonlyArray<string | null>;
                    readonly questionType: AssessmentQuestionType;
                    readonly responses: AssessmentQuestionResponses;
                  }
                | {
                    readonly __typename: "RangeAssessmentQuestionNode";
                    readonly id: number;
                    readonly title: string;
                    readonly description?: any | null;
                    readonly startValue: number;
                    readonly endValue: number;
                    readonly labels: ReadonlyArray<string | null>;
                    readonly questionType: AssessmentQuestionType;
                    readonly responses: AssessmentQuestionResponses;
                  }
                | {
                    readonly __typename: "TextAssessmentQuestionNode";
                    readonly id: number;
                    readonly title: string;
                    readonly description?: any | null;
                    readonly questionType: AssessmentQuestionType;
                    readonly responses: AssessmentQuestionResponses;
                  };
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  };
};

export type GetComplianceProgramForReportQueryVariables = Exact<{
  complianceProgramId: Scalars["Int"]["input"];
}>;

export type GetComplianceProgramForReportQuery = {
  readonly __typename: "Query";
  readonly complianceProgram?: {
    readonly __typename: "ComplianceProgramNode";
    readonly id: number;
    readonly title: string;
    readonly performanceAssessmentTemplate?: {
      readonly __typename: "AssessmentTemplateNode";
      readonly id: number;
      readonly assessmentType: AssessmentType;
      readonly isOnlySelfAssessment: boolean;
      readonly questionSets: {
        readonly __typename: "AssessmentGroupNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "AssessmentGroupNodeEdge";
          readonly node?: {
            readonly __typename: "AssessmentGroupNode";
            readonly id: number;
            readonly title: string;
            readonly sections: {
              readonly __typename: "AssessmentGroupSectionNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "AssessmentGroupSectionNodeEdge";
                readonly node?: {
                  readonly __typename: "AssessmentGroupSectionNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly description?: any | null;
                  readonly questions: {
                    readonly __typename: "AssessmentGroupQuestionM2MNodeConnection";
                    readonly edges: ReadonlyArray<{
                      readonly __typename: "AssessmentGroupQuestionM2MNodeEdge";
                      readonly node?: {
                        readonly __typename: "AssessmentGroupQuestionM2MNode";
                        readonly id: number;
                        readonly question:
                          | {
                              readonly __typename: "MultiChoiceAssessmentQuestionNode";
                              readonly id: number;
                              readonly title: string;
                              readonly description?: any | null;
                              readonly options: ReadonlyArray<string | null>;
                              readonly questionType: AssessmentQuestionType;
                              readonly responses: AssessmentQuestionResponses;
                            }
                          | {
                              readonly __typename: "RangeAssessmentQuestionNode";
                              readonly id: number;
                              readonly title: string;
                              readonly description?: any | null;
                              readonly startValue: number;
                              readonly endValue: number;
                              readonly labels: ReadonlyArray<string | null>;
                              readonly questionType: AssessmentQuestionType;
                              readonly responses: AssessmentQuestionResponses;
                            }
                          | {
                              readonly __typename: "TextAssessmentQuestionNode";
                              readonly id: number;
                              readonly title: string;
                              readonly description?: any | null;
                              readonly questionType: AssessmentQuestionType;
                              readonly responses: AssessmentQuestionResponses;
                            };
                      } | null;
                    } | null>;
                  };
                } | null;
              } | null>;
            };
          } | null;
        } | null>;
      };
    } | null;
    readonly managerAssessmentTemplate?: {
      readonly __typename: "AssessmentTemplateNode";
      readonly id: number;
      readonly assessmentType: AssessmentType;
      readonly isOnlySelfAssessment: boolean;
      readonly questionSets: {
        readonly __typename: "AssessmentGroupNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "AssessmentGroupNodeEdge";
          readonly node?: {
            readonly __typename: "AssessmentGroupNode";
            readonly id: number;
            readonly title: string;
            readonly sections: {
              readonly __typename: "AssessmentGroupSectionNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "AssessmentGroupSectionNodeEdge";
                readonly node?: {
                  readonly __typename: "AssessmentGroupSectionNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly description?: any | null;
                  readonly questions: {
                    readonly __typename: "AssessmentGroupQuestionM2MNodeConnection";
                    readonly edges: ReadonlyArray<{
                      readonly __typename: "AssessmentGroupQuestionM2MNodeEdge";
                      readonly node?: {
                        readonly __typename: "AssessmentGroupQuestionM2MNode";
                        readonly id: number;
                        readonly question:
                          | {
                              readonly __typename: "MultiChoiceAssessmentQuestionNode";
                              readonly id: number;
                              readonly title: string;
                              readonly description?: any | null;
                              readonly options: ReadonlyArray<string | null>;
                              readonly questionType: AssessmentQuestionType;
                              readonly responses: AssessmentQuestionResponses;
                            }
                          | {
                              readonly __typename: "RangeAssessmentQuestionNode";
                              readonly id: number;
                              readonly title: string;
                              readonly description?: any | null;
                              readonly startValue: number;
                              readonly endValue: number;
                              readonly labels: ReadonlyArray<string | null>;
                              readonly questionType: AssessmentQuestionType;
                              readonly responses: AssessmentQuestionResponses;
                            }
                          | {
                              readonly __typename: "TextAssessmentQuestionNode";
                              readonly id: number;
                              readonly title: string;
                              readonly description?: any | null;
                              readonly questionType: AssessmentQuestionType;
                              readonly responses: AssessmentQuestionResponses;
                            };
                      } | null;
                    } | null>;
                  };
                } | null;
              } | null>;
            };
          } | null;
        } | null>;
      };
    } | null;
    readonly peerAssessmentTemplate?: {
      readonly __typename: "AssessmentTemplateNode";
      readonly id: number;
      readonly assessmentType: AssessmentType;
      readonly isOnlySelfAssessment: boolean;
      readonly questionSets: {
        readonly __typename: "AssessmentGroupNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "AssessmentGroupNodeEdge";
          readonly node?: {
            readonly __typename: "AssessmentGroupNode";
            readonly id: number;
            readonly title: string;
            readonly sections: {
              readonly __typename: "AssessmentGroupSectionNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "AssessmentGroupSectionNodeEdge";
                readonly node?: {
                  readonly __typename: "AssessmentGroupSectionNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly description?: any | null;
                  readonly questions: {
                    readonly __typename: "AssessmentGroupQuestionM2MNodeConnection";
                    readonly edges: ReadonlyArray<{
                      readonly __typename: "AssessmentGroupQuestionM2MNodeEdge";
                      readonly node?: {
                        readonly __typename: "AssessmentGroupQuestionM2MNode";
                        readonly id: number;
                        readonly question:
                          | {
                              readonly __typename: "MultiChoiceAssessmentQuestionNode";
                              readonly id: number;
                              readonly title: string;
                              readonly description?: any | null;
                              readonly options: ReadonlyArray<string | null>;
                              readonly questionType: AssessmentQuestionType;
                              readonly responses: AssessmentQuestionResponses;
                            }
                          | {
                              readonly __typename: "RangeAssessmentQuestionNode";
                              readonly id: number;
                              readonly title: string;
                              readonly description?: any | null;
                              readonly startValue: number;
                              readonly endValue: number;
                              readonly labels: ReadonlyArray<string | null>;
                              readonly questionType: AssessmentQuestionType;
                              readonly responses: AssessmentQuestionResponses;
                            }
                          | {
                              readonly __typename: "TextAssessmentQuestionNode";
                              readonly id: number;
                              readonly title: string;
                              readonly description?: any | null;
                              readonly questionType: AssessmentQuestionType;
                              readonly responses: AssessmentQuestionResponses;
                            };
                      } | null;
                    } | null>;
                  };
                } | null;
              } | null>;
            };
          } | null;
        } | null>;
      };
    } | null;
    readonly assessments: {
      readonly __typename: "AssessmentNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "AssessmentNodeEdge";
        readonly node?: {
          readonly __typename: "AssessmentNode";
          readonly id: number;
          readonly state: AssessmentState;
          readonly responder?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
          } | null;
          readonly target?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
            readonly managers: {
              readonly __typename: "UserNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "UserNodeEdge";
                readonly node?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                } | null;
              } | null>;
            };
            readonly teams?: {
              readonly __typename: "TeamNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "TeamNodeEdge";
                readonly node?: {
                  readonly __typename: "TeamNode";
                  readonly id: number;
                } | null;
              } | null>;
            } | null;
          } | null;
          readonly answers: {
            readonly __typename: "AssessmentAnswerConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "AssessmentAnswerEdge";
              readonly node?:
                | {
                    readonly __typename: "MultiChoiceAssessmentAnswerNode";
                    readonly id: number;
                    readonly choices: ReadonlyArray<number | null>;
                    readonly comment?: any | null;
                    readonly question:
                      | {
                          readonly __typename: "MultiChoiceAssessmentQuestionNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "RangeAssessmentQuestionNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "TextAssessmentQuestionNode";
                          readonly id: number;
                        };
                  }
                | {
                    readonly __typename: "RangeAssessmentAnswerNode";
                    readonly id: number;
                    readonly integerAnswer?: number | null;
                    readonly comment?: any | null;
                    readonly question:
                      | {
                          readonly __typename: "MultiChoiceAssessmentQuestionNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "RangeAssessmentQuestionNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "TextAssessmentQuestionNode";
                          readonly id: number;
                        };
                  }
                | {
                    readonly __typename: "TextAssessmentAnswerNode";
                    readonly id: number;
                    readonly textAnswer?: any | null;
                    readonly comment?: any | null;
                    readonly question:
                      | {
                          readonly __typename: "MultiChoiceAssessmentQuestionNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "RangeAssessmentQuestionNode";
                          readonly id: number;
                        }
                      | {
                          readonly __typename: "TextAssessmentQuestionNode";
                          readonly id: number;
                        };
                  }
                | null;
            } | null>;
          };
          readonly template: {
            readonly __typename: "AssessmentTemplateNode";
            readonly id: number;
          };
        } | null;
      } | null>;
    };
    readonly managerEffectivenessReport: {
      readonly __typename: "ManagerEffectivenessReportConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "ManagerEffectivenessReportEdge";
        readonly node?: {
          readonly __typename: "ManagerEffectivenessReportRowNode";
          readonly manager: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
            readonly directReports: {
              readonly __typename: "UserNodeConnection";
              readonly totalCount: number;
            };
            readonly managers: {
              readonly __typename: "UserNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "UserNodeEdge";
                readonly node?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                } | null;
              } | null>;
            };
            readonly teams?: {
              readonly __typename: "TeamNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "TeamNodeEdge";
                readonly node?: {
                  readonly __typename: "TeamNode";
                  readonly id: number;
                } | null;
              } | null>;
            } | null;
          };
          readonly assessments: {
            readonly __typename: "AssessmentNodeConnection";
            readonly totalCount: number;
          };
          readonly questions: {
            readonly __typename: "ManagerEffectivenessReportQuestionConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "ManagerEffectivenessReportQuestionEdge";
              readonly node?: {
                readonly __typename: "ManagerEffectivenessReportQuestionNode";
                readonly avgAnswer: number;
                readonly question:
                  | {
                      readonly __typename: "MultiChoiceAssessmentQuestionNode";
                      readonly id: number;
                    }
                  | {
                      readonly __typename: "RangeAssessmentQuestionNode";
                      readonly id: number;
                    }
                  | {
                      readonly __typename: "TextAssessmentQuestionNode";
                      readonly id: number;
                    };
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    };
  } | null;
};

export type SetAgendaAsTemplateMutationVariables = Exact<{
  meetingId: Scalars["Int"]["input"];
}>;

export type SetAgendaAsTemplateMutation = {
  readonly __typename: "Mutation";
  readonly setMeetingAgendaAsTemplate?: {
    readonly __typename: "SetMeetingAgendaAsTemplateMutation";
    readonly meeting?: {
      readonly __typename: "MeetingNode";
      readonly id: number;
    } | null;
  } | null;
};

export type AddTopicToMeetingMutationMutationVariables = Exact<{
  topicId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  includesIndividualNotes?: InputMaybe<Scalars["Boolean"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  discussionNotes?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type AddTopicToMeetingMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateTopic?: {
    readonly __typename: "CreateOrUpdateTopicMutation";
    readonly topic?: {
      readonly __typename: "TopicNode";
      readonly id: number;
      readonly title: string;
      readonly description?: string | null;
      readonly discussionNotes?: any | null;
      readonly created: string;
      readonly state?: TopicState | null;
      readonly eventChannelName?: string | null;
      readonly includesIndividualNotes: boolean;
      readonly includesIndividualNotesForCurrentUser: boolean;
      readonly includesSharedNotes: boolean;
      readonly isMandatory: boolean;
      readonly notesRequirement: NotesRequirement;
      readonly individualNotes?: {
        readonly __typename: "IndividualNoteNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "IndividualNoteNodeEdge";
          readonly node?: {
            readonly __typename: "IndividualNoteNode";
            readonly id: number;
            readonly created: string;
            readonly hidden: boolean;
            readonly enabled: boolean;
            readonly notes?: any | null;
            readonly creator: {
              readonly __typename: "UserNode";
              readonly id: number;
            };
          } | null;
        } | null>;
      } | null;
      readonly creator?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
      } | null;
      readonly assignee?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly avatar?: string | null;
        readonly name: string;
      } | null;
      readonly previousTopic?: {
        readonly __typename: "TopicNode";
        readonly id: number;
        readonly discussionNotes?: any | null;
      } | null;
      readonly linkedTemplateTopic?: {
        readonly __typename: "TopicNode";
        readonly id: number;
        readonly title: string;
        readonly discussionNotes?: any | null;
        readonly defaultSubjectNotes?: any | null;
        readonly defaultFacilitatorNotes?: any | null;
        readonly topicTemplate?: {
          readonly __typename: "TopicTemplateNode";
          readonly id: number;
          readonly title: string;
        } | null;
      } | null;
      readonly canUpdate: {
        readonly __typename: "PermissionNode";
        readonly permission: boolean;
      };
      readonly canDelete: {
        readonly __typename: "PermissionNode";
        readonly permission: boolean;
      };
      readonly copiedFrom?: {
        readonly __typename: "TopicNode";
        readonly id: number;
        readonly title: string;
        readonly meeting?: {
          readonly __typename: "MeetingNode";
          readonly id: number;
          readonly title?: string | null;
          readonly startDatetime?: string | null;
          readonly meetingGroupId: number;
        } | null;
      } | null;
    } | null;
    readonly meeting?: {
      readonly __typename: "MeetingNode";
      readonly id: number;
      readonly topics?: {
        readonly __typename: "TopicNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "TopicNodeEdge";
          readonly node?: {
            readonly __typename: "TopicNode";
            readonly id: number;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type SuggestedTemplateCategoryFragmentFragment = {
  readonly __typename: "TemplateCategoryNode";
  readonly id: number;
  readonly title: string;
};

export type SuggestedTemplateTopicsFragmentFragment = {
  readonly __typename: "TopicNode";
  readonly id: number;
  readonly title: string;
  readonly isMandatory: boolean;
  readonly description?: string | null;
  readonly discussionNotes?: any | null;
  readonly includesIndividualNotes: boolean;
};

export type SuggestedTopicTemplateFragmentFragment = {
  readonly __typename: "TopicTemplateNode";
  readonly id: number;
  readonly title: string;
  readonly allowAdminVisibility: boolean;
  readonly allowOrgVisibility: boolean;
  readonly allowManagementTreeVisibility: boolean;
  readonly categories?: {
    readonly __typename: "TemplateCategoryNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "TemplateCategoryNodeEdge";
      readonly node?: {
        readonly __typename: "TemplateCategoryNode";
        readonly id: number;
        readonly title: string;
      } | null;
    } | null>;
  } | null;
  readonly topics?: {
    readonly __typename: "TopicNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "TopicNodeEdge";
      readonly node?: {
        readonly __typename: "TopicNode";
        readonly id: number;
        readonly title: string;
        readonly isMandatory: boolean;
        readonly description?: string | null;
        readonly discussionNotes?: any | null;
        readonly includesIndividualNotes: boolean;
      } | null;
    } | null>;
  } | null;
};

export type GetGlobalTemplatesQueryQueryVariables = Exact<{
  meetingId: Scalars["Int"]["input"];
}>;

export type GetGlobalTemplatesQueryQuery = {
  readonly __typename: "Query";
  readonly topicTemplates?: {
    readonly __typename: "TopicTemplateNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "TopicTemplateNodeEdge";
      readonly node?: {
        readonly __typename: "TopicTemplateNode";
        readonly id: number;
        readonly title: string;
        readonly allowAdminVisibility: boolean;
        readonly allowOrgVisibility: boolean;
        readonly allowManagementTreeVisibility: boolean;
        readonly categories?: {
          readonly __typename: "TemplateCategoryNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "TemplateCategoryNodeEdge";
            readonly node?: {
              readonly __typename: "TemplateCategoryNode";
              readonly id: number;
              readonly title: string;
            } | null;
          } | null>;
        } | null;
        readonly topics?: {
          readonly __typename: "TopicNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "TopicNodeEdge";
            readonly node?: {
              readonly __typename: "TopicNode";
              readonly id: number;
              readonly title: string;
              readonly isMandatory: boolean;
              readonly description?: string | null;
              readonly discussionNotes?: any | null;
              readonly includesIndividualNotes: boolean;
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  } | null;
  readonly meeting?: {
    readonly __typename: "MeetingNode";
    readonly id: number;
    readonly suggestedTemplates?: {
      readonly __typename: "TopicTemplateNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "TopicTemplateNodeEdge";
        readonly node?: {
          readonly __typename: "TopicTemplateNode";
          readonly id: number;
          readonly title: string;
          readonly allowAdminVisibility: boolean;
          readonly allowOrgVisibility: boolean;
          readonly allowManagementTreeVisibility: boolean;
          readonly categories?: {
            readonly __typename: "TemplateCategoryNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "TemplateCategoryNodeEdge";
              readonly node?: {
                readonly __typename: "TemplateCategoryNode";
                readonly id: number;
                readonly title: string;
              } | null;
            } | null>;
          } | null;
          readonly topics?: {
            readonly __typename: "TopicNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "TopicNodeEdge";
              readonly node?: {
                readonly __typename: "TopicNode";
                readonly id: number;
                readonly title: string;
                readonly isMandatory: boolean;
                readonly description?: string | null;
                readonly discussionNotes?: any | null;
                readonly includesIndividualNotes: boolean;
              } | null;
            } | null>;
          } | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type GetPastTopicsQueryQueryVariables = Exact<{
  meetingGroupId: Scalars["Int"]["input"];
  search?: InputMaybe<Scalars["String"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetPastTopicsQueryQuery = {
  readonly __typename: "Query";
  readonly topics?: {
    readonly __typename: "TopicNodeConnection";
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly hasNextPage: boolean;
      readonly endCursor?: string | null;
    };
    readonly edges: ReadonlyArray<{
      readonly __typename: "TopicNodeEdge";
      readonly node?: {
        readonly __typename: "TopicNode";
        readonly id: number;
        readonly title: string;
        readonly discussionNotes?: any | null;
        readonly isMandatory: boolean;
        readonly meeting?: {
          readonly __typename: "MeetingNode";
          readonly id: number;
          readonly title?: string | null;
          readonly startDatetime?: string | null;
          readonly meetingGroupId: number;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type AddTemplateToMeetingMutationMutationVariables = Exact<{
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  meetingGroupId?: InputMaybe<Scalars["Int"]["input"]>;
  topicTemplateId: Scalars["Int"]["input"];
  setAsSeriesTemplate: Scalars["Boolean"]["input"];
  allowAdminVisibility?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowOrgVisibility?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowManagementTreeVisibility?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type AddTemplateToMeetingMutationMutation = {
  readonly __typename: "Mutation";
  readonly addTemplateToMeeting?: {
    readonly __typename: "AddTemplateToMeetingMutation";
    readonly meeting?: {
      readonly __typename: "MeetingNode";
      readonly id: number;
      readonly topics?: {
        readonly __typename: "TopicNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "TopicNodeEdge";
          readonly node?: {
            readonly __typename: "TopicNode";
            readonly id: number;
            readonly title: string;
            readonly description?: string | null;
            readonly discussionNotes?: any | null;
            readonly includesSharedNotes: boolean;
            readonly created: string;
            readonly state?: TopicState | null;
            readonly eventChannelName?: string | null;
            readonly isMandatory: boolean;
            readonly creator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
            } | null;
            readonly assignee?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly avatar?: string | null;
              readonly name: string;
            } | null;
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly previousTopic?: {
              readonly __typename: "TopicNode";
              readonly id: number;
              readonly discussionNotes?: any | null;
            } | null;
          } | null;
        } | null>;
      } | null;
    } | null;
    readonly meetingGroup?: {
      readonly __typename: "MeetingGroupNode";
      readonly id: number;
      readonly allowAdminVisibility: boolean;
      readonly allowOrgVisibility: boolean;
      readonly allowManagementTreeVisibility: boolean;
      readonly templateSeries?: {
        readonly __typename: "TemplateSeriesNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "TemplateSeriesNodeEdge";
          readonly node?: {
            readonly __typename: "TemplateSeriesNode";
            readonly id: number;
            readonly topicTemplates?: {
              readonly __typename: "TemplateSeriesToTopicTemplatesNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "TemplateSeriesToTopicTemplatesNodeEdge";
                readonly node?: {
                  readonly __typename: "TemplateSeriesToTopicTemplatesNode";
                  readonly id: number;
                  readonly topicTemplate?: {
                    readonly __typename: "TopicTemplateNode";
                    readonly id: number;
                    readonly title: string;
                    readonly description: string;
                    readonly publicTemplate: boolean;
                    readonly topics?: {
                      readonly __typename: "TopicNodeConnection";
                      readonly edges: ReadonlyArray<{
                        readonly __typename: "TopicNodeEdge";
                        readonly node?: {
                          readonly __typename: "TopicNode";
                          readonly id: number;
                          readonly title: string;
                          readonly description?: string | null;
                          readonly includesIndividualNotes: boolean;
                        } | null;
                      } | null>;
                    } | null;
                  } | null;
                } | null;
              } | null>;
            } | null;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type CreateOrUpdateTemplateCategoryMutationMutationVariables = Exact<{
  title: Scalars["String"]["input"];
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  topicTemplateCategoryId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type CreateOrUpdateTemplateCategoryMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateTopicTemplateCategory?: {
    readonly __typename: "CreateOrUpdateTopicTemplateCategoryMutation";
    readonly topicTemplateCategory?: {
      readonly __typename: "TemplateCategoryNode";
      readonly id: number;
      readonly title: string;
      readonly ordinal?: number | null;
    } | null;
  } | null;
};

export type CreateOrUpdateTopicTemplateMutationMutationVariables = Exact<{
  title: Scalars["String"]["input"];
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  topicTemplateId?: InputMaybe<Scalars["Int"]["input"]>;
  oneononeTemplate?: InputMaybe<Scalars["Boolean"]["input"]>;
  categories?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  publicTemplate: Scalars["Boolean"]["input"];
  allowAdminVisibility: Scalars["Boolean"]["input"];
  allowOrgVisibility: Scalars["Boolean"]["input"];
  allowManagementTreeVisibility: Scalars["Boolean"]["input"];
  finalizePermissions?: InputMaybe<FinalizePermissions>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  managerInstructions?: InputMaybe<Scalars["String"]["input"]>;
  reportInstructions?: InputMaybe<Scalars["String"]["input"]>;
  topics?: InputMaybe<
    | ReadonlyArray<InputMaybe<TemplateTopicInput>>
    | InputMaybe<TemplateTopicInput>
  >;
}>;

export type CreateOrUpdateTopicTemplateMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateTopicTemplate?: {
    readonly __typename: "CreateOrUpdateTopicTemplateMutation";
    readonly topicTemplate?: {
      readonly __typename: "TopicTemplateNode";
      readonly id: number;
      readonly title: string;
      readonly description: string;
      readonly globalTemplate: boolean;
      readonly publicTemplate: boolean;
      readonly oneononeTemplate: boolean;
      readonly finalizePermissions: FinalizePermissions;
      readonly managerInstructions: string;
      readonly reportInstructions: string;
      readonly allowAdminVisibility: boolean;
      readonly allowOrgVisibility: boolean;
      readonly allowManagementTreeVisibility: boolean;
      readonly organization?: {
        readonly __typename: "OrganizationNode";
        readonly id: number;
      } | null;
      readonly canDelete: {
        readonly __typename: "PermissionNode";
        readonly permission: boolean;
        readonly reason: string;
      };
      readonly canUpdate: {
        readonly __typename: "PermissionNode";
        readonly permission: boolean;
        readonly reason: string;
      };
      readonly creator?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly avatar?: string | null;
      } | null;
      readonly categoriesList: ReadonlyArray<{
        readonly __typename: "TemplateCategoryNode";
        readonly id: number;
        readonly title: string;
        readonly ordinal?: number | null;
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
        readonly canUpdate?: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        } | null;
        readonly canDelete?: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        } | null;
      } | null>;
      readonly topicsList: ReadonlyArray<{
        readonly __typename: "TopicNode";
        readonly id: number;
        readonly title: string;
        readonly description?: string | null;
        readonly discussionNotes?: any | null;
        readonly defaultSubjectNotes?: any | null;
        readonly defaultFacilitatorNotes?: any | null;
        readonly includesIndividualNotes: boolean;
        readonly includesSharedNotes: boolean;
        readonly isMandatory: boolean;
        readonly notesRequirement: NotesRequirement;
      } | null>;
    } | null;
  } | null;
};

export type DeleteTopicTemplateCategoryMutationMutationVariables = Exact<{
  topicTemplateCategoryId: Scalars["Int"]["input"];
}>;

export type DeleteTopicTemplateCategoryMutationMutation = {
  readonly __typename: "Mutation";
  readonly deleteTopicTemplateCategory?: {
    readonly __typename: "DeleteTopicTemplateCategoryMutation";
    readonly ok?: boolean | null;
  } | null;
};

export type DeleteTopicTemplateMutationMutationVariables = Exact<{
  topicTemplateId: Scalars["Int"]["input"];
}>;

export type DeleteTopicTemplateMutationMutation = {
  readonly __typename: "Mutation";
  readonly deleteTopicTemplate?: {
    readonly __typename: "DeleteTopicTemplateMutation";
    readonly topicTemplate?: {
      readonly __typename: "TopicTemplateNode";
      readonly id: number;
    } | null;
  } | null;
};

export type GetTopicTemplateCategoryQueryVariables = Exact<{
  categoryId: Scalars["Int"]["input"];
}>;

export type GetTopicTemplateCategoryQuery = {
  readonly __typename: "Query";
  readonly topicTemplateCategory?: {
    readonly __typename: "TemplateCategoryNode";
    readonly id: number;
    readonly title: string;
    readonly ordinal?: number | null;
    readonly canUpdate?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
    } | null;
    readonly canDelete?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
    } | null;
  } | null;
};

export type GetTopicTemplateQueryVariables = Exact<{
  templateId: Scalars["Int"]["input"];
}>;

export type GetTopicTemplateQuery = {
  readonly __typename: "Query";
  readonly topicTemplate?: {
    readonly __typename: "TopicTemplateNode";
    readonly id: number;
    readonly title: string;
    readonly description: string;
    readonly globalTemplate: boolean;
    readonly publicTemplate: boolean;
    readonly oneononeTemplate: boolean;
    readonly finalizePermissions: FinalizePermissions;
    readonly managerInstructions: string;
    readonly reportInstructions: string;
    readonly allowAdminVisibility: boolean;
    readonly allowOrgVisibility: boolean;
    readonly allowManagementTreeVisibility: boolean;
    readonly organization?: {
      readonly __typename: "OrganizationNode";
      readonly id: number;
    } | null;
    readonly canDelete: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
      readonly reason: string;
    };
    readonly canUpdate: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
      readonly reason: string;
    };
    readonly creator?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
      readonly avatar?: string | null;
    } | null;
    readonly categoriesList: ReadonlyArray<{
      readonly __typename: "TemplateCategoryNode";
      readonly id: number;
      readonly title: string;
      readonly ordinal?: number | null;
      readonly organization?: {
        readonly __typename: "OrganizationNode";
        readonly id: number;
      } | null;
      readonly canUpdate?: {
        readonly __typename: "PermissionNode";
        readonly permission: boolean;
      } | null;
      readonly canDelete?: {
        readonly __typename: "PermissionNode";
        readonly permission: boolean;
      } | null;
    } | null>;
    readonly topicsList: ReadonlyArray<{
      readonly __typename: "TopicNode";
      readonly id: number;
      readonly title: string;
      readonly description?: string | null;
      readonly discussionNotes?: any | null;
      readonly defaultSubjectNotes?: any | null;
      readonly defaultFacilitatorNotes?: any | null;
      readonly includesIndividualNotes: boolean;
      readonly includesSharedNotes: boolean;
      readonly isMandatory: boolean;
      readonly notesRequirement: NotesRequirement;
    } | null>;
  } | null;
};

export type GetTopicTemplateToEditQueryQueryVariables = Exact<{
  templateId: Scalars["Int"]["input"];
  hasTemplateId: Scalars["Boolean"]["input"];
}>;

export type GetTopicTemplateToEditQueryQuery = {
  readonly __typename: "Query";
  readonly topicTemplate?: {
    readonly __typename: "TopicTemplateNode";
    readonly canUpdateRequirements: boolean;
    readonly id: number;
    readonly title: string;
    readonly description: string;
    readonly globalTemplate: boolean;
    readonly publicTemplate: boolean;
    readonly oneononeTemplate: boolean;
    readonly finalizePermissions: FinalizePermissions;
    readonly managerInstructions: string;
    readonly reportInstructions: string;
    readonly allowAdminVisibility: boolean;
    readonly allowOrgVisibility: boolean;
    readonly allowManagementTreeVisibility: boolean;
    readonly organization?: {
      readonly __typename: "OrganizationNode";
      readonly id: number;
    } | null;
    readonly canDelete: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
      readonly reason: string;
    };
    readonly canUpdate: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
      readonly reason: string;
    };
    readonly creator?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
      readonly avatar?: string | null;
    } | null;
    readonly categoriesList: ReadonlyArray<{
      readonly __typename: "TemplateCategoryNode";
      readonly id: number;
      readonly title: string;
      readonly ordinal?: number | null;
      readonly organization?: {
        readonly __typename: "OrganizationNode";
        readonly id: number;
      } | null;
      readonly canUpdate?: {
        readonly __typename: "PermissionNode";
        readonly permission: boolean;
      } | null;
      readonly canDelete?: {
        readonly __typename: "PermissionNode";
        readonly permission: boolean;
      } | null;
    } | null>;
    readonly topicsList: ReadonlyArray<{
      readonly __typename: "TopicNode";
      readonly id: number;
      readonly title: string;
      readonly description?: string | null;
      readonly discussionNotes?: any | null;
      readonly defaultSubjectNotes?: any | null;
      readonly defaultFacilitatorNotes?: any | null;
      readonly includesIndividualNotes: boolean;
      readonly includesSharedNotes: boolean;
      readonly isMandatory: boolean;
      readonly notesRequirement: NotesRequirement;
    } | null>;
  } | null;
  readonly topicTemplateCategories: {
    readonly __typename: "TemplateCategoryNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "TemplateCategoryNodeEdge";
      readonly node?: {
        readonly __typename: "TemplateCategoryNode";
        readonly id: number;
        readonly title: string;
        readonly ordinal?: number | null;
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
        readonly canUpdate?: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        } | null;
        readonly canDelete?: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        } | null;
      } | null;
    } | null>;
  };
};

export type GetTopicTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type GetTopicTemplatesQuery = {
  readonly __typename: "Query";
  readonly topicTemplates?: {
    readonly __typename: "TopicTemplateNodeConnection";
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly __typename: "TopicTemplateNodeEdge";
      readonly node?: {
        readonly __typename: "TopicTemplateNode";
        readonly id: number;
        readonly title: string;
        readonly description: string;
        readonly globalTemplate: boolean;
        readonly publicTemplate: boolean;
        readonly oneononeTemplate: boolean;
        readonly finalizePermissions: FinalizePermissions;
        readonly managerInstructions: string;
        readonly reportInstructions: string;
        readonly allowAdminVisibility: boolean;
        readonly allowOrgVisibility: boolean;
        readonly allowManagementTreeVisibility: boolean;
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
          readonly reason: string;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
          readonly reason: string;
        };
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly categoriesList: ReadonlyArray<{
          readonly __typename: "TemplateCategoryNode";
          readonly id: number;
          readonly title: string;
          readonly ordinal?: number | null;
          readonly organization?: {
            readonly __typename: "OrganizationNode";
            readonly id: number;
          } | null;
          readonly canUpdate?: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          } | null;
          readonly canDelete?: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          } | null;
        } | null>;
        readonly topicsList: ReadonlyArray<{
          readonly __typename: "TopicNode";
          readonly id: number;
          readonly title: string;
          readonly description?: string | null;
          readonly discussionNotes?: any | null;
          readonly defaultSubjectNotes?: any | null;
          readonly defaultFacilitatorNotes?: any | null;
          readonly includesIndividualNotes: boolean;
          readonly includesSharedNotes: boolean;
          readonly isMandatory: boolean;
          readonly notesRequirement: NotesRequirement;
        } | null>;
      } | null;
    } | null>;
  } | null;
  readonly topicTemplateCategories: {
    readonly __typename: "TemplateCategoryNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "TemplateCategoryNodeEdge";
      readonly node?: {
        readonly __typename: "TemplateCategoryNode";
        readonly id: number;
        readonly title: string;
        readonly ordinal?: number | null;
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
        readonly canUpdate?: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        } | null;
        readonly canDelete?: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        } | null;
      } | null;
    } | null>;
  };
};

export type TopicTemplateCategoryFragmentFragment = {
  readonly __typename: "TemplateCategoryNode";
  readonly id: number;
  readonly title: string;
  readonly ordinal?: number | null;
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
  readonly canUpdate?: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  } | null;
  readonly canDelete?: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  } | null;
};

export type TemplateAppTopicFragment = {
  readonly __typename: "TopicNode";
  readonly id: number;
  readonly title: string;
  readonly description?: string | null;
  readonly discussionNotes?: any | null;
  readonly defaultSubjectNotes?: any | null;
  readonly defaultFacilitatorNotes?: any | null;
  readonly includesIndividualNotes: boolean;
  readonly includesSharedNotes: boolean;
  readonly isMandatory: boolean;
  readonly notesRequirement: NotesRequirement;
};

export type TopicTemplateFragmentFragment = {
  readonly __typename: "TopicTemplateNode";
  readonly id: number;
  readonly title: string;
  readonly description: string;
  readonly globalTemplate: boolean;
  readonly publicTemplate: boolean;
  readonly oneononeTemplate: boolean;
  readonly finalizePermissions: FinalizePermissions;
  readonly managerInstructions: string;
  readonly reportInstructions: string;
  readonly allowAdminVisibility: boolean;
  readonly allowOrgVisibility: boolean;
  readonly allowManagementTreeVisibility: boolean;
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
    readonly reason: string;
  };
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
    readonly reason: string;
  };
  readonly creator?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
  readonly categoriesList: ReadonlyArray<{
    readonly __typename: "TemplateCategoryNode";
    readonly id: number;
    readonly title: string;
    readonly ordinal?: number | null;
    readonly organization?: {
      readonly __typename: "OrganizationNode";
      readonly id: number;
    } | null;
    readonly canUpdate?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
    } | null;
    readonly canDelete?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
    } | null;
  } | null>;
  readonly topicsList: ReadonlyArray<{
    readonly __typename: "TopicNode";
    readonly id: number;
    readonly title: string;
    readonly description?: string | null;
    readonly discussionNotes?: any | null;
    readonly defaultSubjectNotes?: any | null;
    readonly defaultFacilitatorNotes?: any | null;
    readonly includesIndividualNotes: boolean;
    readonly includesSharedNotes: boolean;
    readonly isMandatory: boolean;
    readonly notesRequirement: NotesRequirement;
  } | null>;
};

export type UpdateTopicAssigneeMutationMutationVariables = Exact<{
  topicId?: InputMaybe<Scalars["Int"]["input"]>;
  assignee?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type UpdateTopicAssigneeMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateTopic?: {
    readonly __typename: "CreateOrUpdateTopicMutation";
    readonly topic?: {
      readonly __typename: "TopicNode";
      readonly id: number;
      readonly assignee?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly avatar?: string | null;
      } | null;
    } | null;
  } | null;
};

export type CloseTopicMutationMutationVariables = Exact<{
  topicId: Scalars["Int"]["input"];
  topicState: TopicState;
}>;

export type CloseTopicMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateTopic?: {
    readonly __typename: "CreateOrUpdateTopicMutation";
    readonly topic?: {
      readonly __typename: "TopicNode";
      readonly id: number;
      readonly state?: TopicState | null;
    } | null;
  } | null;
};

export type CopyTopicToMeetingMutationMutationVariables = Exact<{
  topicIds:
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>;
  meetingId: Scalars["Int"]["input"];
}>;

export type CopyTopicToMeetingMutationMutation = {
  readonly __typename: "Mutation";
  readonly copyTopicsToMeeting?: {
    readonly __typename: "CopyTopicsToMeetingMutation";
    readonly copiedTopics?: ReadonlyArray<{
      readonly __typename: "TopicNode";
      readonly id: number;
      readonly title: string;
      readonly description?: string | null;
      readonly discussionNotes?: any | null;
      readonly created: string;
      readonly state?: TopicState | null;
      readonly eventChannelName?: string | null;
      readonly includesIndividualNotes: boolean;
      readonly includesIndividualNotesForCurrentUser: boolean;
      readonly includesSharedNotes: boolean;
      readonly isMandatory: boolean;
      readonly notesRequirement: NotesRequirement;
      readonly individualNotes?: {
        readonly __typename: "IndividualNoteNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "IndividualNoteNodeEdge";
          readonly node?: {
            readonly __typename: "IndividualNoteNode";
            readonly id: number;
            readonly created: string;
            readonly hidden: boolean;
            readonly enabled: boolean;
            readonly notes?: any | null;
            readonly creator: {
              readonly __typename: "UserNode";
              readonly id: number;
            };
          } | null;
        } | null>;
      } | null;
      readonly creator?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
      } | null;
      readonly assignee?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly avatar?: string | null;
        readonly name: string;
      } | null;
      readonly previousTopic?: {
        readonly __typename: "TopicNode";
        readonly id: number;
        readonly discussionNotes?: any | null;
      } | null;
      readonly linkedTemplateTopic?: {
        readonly __typename: "TopicNode";
        readonly id: number;
        readonly title: string;
        readonly discussionNotes?: any | null;
        readonly defaultSubjectNotes?: any | null;
        readonly defaultFacilitatorNotes?: any | null;
        readonly topicTemplate?: {
          readonly __typename: "TopicTemplateNode";
          readonly id: number;
          readonly title: string;
        } | null;
      } | null;
      readonly canUpdate: {
        readonly __typename: "PermissionNode";
        readonly permission: boolean;
      };
      readonly canDelete: {
        readonly __typename: "PermissionNode";
        readonly permission: boolean;
      };
      readonly copiedFrom?: {
        readonly __typename: "TopicNode";
        readonly id: number;
        readonly title: string;
        readonly meeting?: {
          readonly __typename: "MeetingNode";
          readonly id: number;
          readonly title?: string | null;
          readonly startDatetime?: string | null;
          readonly meetingGroupId: number;
        } | null;
      } | null;
    } | null> | null;
    readonly meeting?: {
      readonly __typename: "MeetingNode";
      readonly id: number;
      readonly topics?: {
        readonly __typename: "TopicNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "TopicNodeEdge";
          readonly node?: {
            readonly __typename: "TopicNode";
            readonly id: number;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type DeleteTopicMutationMutationVariables = Exact<{
  topicId: Scalars["Int"]["input"];
}>;

export type DeleteTopicMutationMutation = {
  readonly __typename: "Mutation";
  readonly deleteTopic?: {
    readonly __typename: "DeleteTopicMutation";
    readonly ok?: boolean | null;
  } | null;
};

export type GetTopicMeetingsQueryVariables = Exact<{
  topicId: Scalars["Int"]["input"];
  meetingGroupId: Scalars["Int"]["input"];
  startDatetime: Scalars["DateTime"]["input"];
  excludeIds:
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetTopicMeetingsQuery = {
  readonly __typename: "Query";
  readonly topic?: {
    readonly __typename: "TopicNode";
    readonly id: number;
  } | null;
  readonly meetingGroup?: {
    readonly __typename: "MeetingGroupNode";
    readonly id: number;
    readonly meetings?: {
      readonly __typename: "MeetingNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "MeetingNodeEdge";
        readonly node?: {
          readonly __typename: "MeetingNode";
          readonly id: number;
          readonly title?: string | null;
          readonly startDatetime?: string | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type UpdateTopicSharedOrIndividualNotesMutationMutationVariables =
  Exact<{
    topicId?: InputMaybe<Scalars["Int"]["input"]>;
    includesIndividualNotes?: InputMaybe<Scalars["Boolean"]["input"]>;
    includesSharedNotes?: InputMaybe<Scalars["Boolean"]["input"]>;
  }>;

export type UpdateTopicSharedOrIndividualNotesMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateTopic?: {
    readonly __typename: "CreateOrUpdateTopicMutation";
    readonly topic?: {
      readonly __typename: "TopicNode";
      readonly id: number;
      readonly includesIndividualNotes: boolean;
      readonly includesSharedNotes: boolean;
    } | null;
  } | null;
};

export type GetRelatedTopicArtifactsQueryVariables = Exact<{
  topicId: Scalars["Int"]["input"];
  meetingGroupId: Scalars["Int"]["input"];
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetRelatedTopicArtifactsQuery = {
  readonly __typename: "Query";
  readonly topic?: {
    readonly __typename: "TopicNode";
    readonly id: number;
    readonly artifacts?: {
      readonly __typename: "ArtifactConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "ArtifactEdge";
        readonly node?:
          | {
              readonly __typename: "ActionItemArtifactNode";
              readonly isComplete?: boolean | null;
              readonly dueDate?: string | null;
              readonly id: number;
              readonly artifactType: ArtifactType;
              readonly title?: string | null;
              readonly assignee?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
            }
          | {
              readonly __typename: "AsanaTaskArtifactNode";
              readonly id: number;
              readonly artifactType: ArtifactType;
              readonly title?: string | null;
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
            }
          | {
              readonly __typename: "DecisionArtifactNode";
              readonly decision: string;
              readonly decisionState?: DecisionState | null;
              readonly isDraft?: boolean | null;
              readonly id: number;
              readonly artifactType: ArtifactType;
              readonly title?: string | null;
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
            }
          | {
              readonly __typename: "DocumentArtifactNode";
              readonly id: number;
              readonly artifactType: ArtifactType;
              readonly title?: string | null;
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
            }
          | {
              readonly __typename: "FeedbackArtifactNode";
              readonly id: number;
              readonly artifactType: ArtifactType;
              readonly title?: string | null;
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
            }
          | {
              readonly __typename: "GithubIssuesArtifactNode";
              readonly id: number;
              readonly artifactType: ArtifactType;
              readonly title?: string | null;
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
            }
          | {
              readonly __typename: "GoalArtifactNode";
              readonly id: number;
              readonly artifactType: ArtifactType;
              readonly title?: string | null;
              readonly created: string;
              readonly state: GoalState;
              readonly status: GoalStatus;
              readonly scope: GoalScope;
              readonly goalVisibility: GoalVisibility;
              readonly dueDate?: string | null;
              readonly startDate?: string | null;
              readonly progress: number;
              readonly progressType: GoalProgressType;
              readonly startValue: number;
              readonly currentValue: number;
              readonly targetValue: number;
              readonly isStale: boolean;
              readonly ownerCount?: number | null;
              readonly contributorCount?: number | null;
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly firstOwner?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly avatar?: string | null;
                readonly name: string;
              } | null;
              readonly canUpdate: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
              readonly canRead: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
            }
          | {
              readonly __typename: "KPIArtifactNode";
              readonly id: number;
              readonly artifactType: ArtifactType;
              readonly title?: string | null;
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
            }
          | {
              readonly __typename: "RatingArtifactNode";
              readonly id: number;
              readonly artifactType: ArtifactType;
              readonly title?: string | null;
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
            }
          | {
              readonly __typename: "RecognitionArtifactNode";
              readonly id: number;
              readonly artifactType: ArtifactType;
              readonly title?: string | null;
              readonly canDelete: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              };
            }
          | null;
      } | null>;
    } | null;
    readonly meeting?: {
      readonly __typename: "MeetingNode";
      readonly id: number;
      readonly meetingGroupId: number;
      readonly startDatetime?: string | null;
    } | null;
    readonly relatedTopics?: {
      readonly __typename: "TopicNodeConnection";
      readonly totalCount: number;
      readonly pageInfo: {
        readonly __typename: "PageInfo";
        readonly hasNextPage: boolean;
        readonly endCursor?: string | null;
      };
      readonly edges: ReadonlyArray<{
        readonly __typename: "TopicNodeEdge";
        readonly node?: {
          readonly __typename: "TopicNode";
          readonly id: number;
          readonly title: string;
          readonly meeting?: {
            readonly __typename: "MeetingNode";
            readonly id: number;
            readonly meetingGroupId: number;
            readonly startDatetime?: string | null;
          } | null;
          readonly artifacts?: {
            readonly __typename: "ArtifactConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "ArtifactEdge";
              readonly node?:
                | {
                    readonly __typename: "ActionItemArtifactNode";
                    readonly isComplete?: boolean | null;
                    readonly id: number;
                    readonly artifactType: ArtifactType;
                    readonly title?: string | null;
                    readonly created: string;
                    readonly updated?: string | null;
                    readonly assignee?: {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    } | null;
                    readonly canDelete: {
                      readonly __typename: "PermissionNode";
                      readonly permission: boolean;
                    };
                    readonly creator?: {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    } | null;
                  }
                | {
                    readonly __typename: "AsanaTaskArtifactNode";
                    readonly id: number;
                    readonly artifactType: ArtifactType;
                    readonly title?: string | null;
                    readonly created: string;
                    readonly updated?: string | null;
                    readonly canDelete: {
                      readonly __typename: "PermissionNode";
                      readonly permission: boolean;
                    };
                    readonly creator?: {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    } | null;
                  }
                | {
                    readonly __typename: "DecisionArtifactNode";
                    readonly decision: string;
                    readonly decisionState?: DecisionState | null;
                    readonly isDraft?: boolean | null;
                    readonly id: number;
                    readonly artifactType: ArtifactType;
                    readonly title?: string | null;
                    readonly created: string;
                    readonly updated?: string | null;
                    readonly canDelete: {
                      readonly __typename: "PermissionNode";
                      readonly permission: boolean;
                    };
                    readonly creator?: {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    } | null;
                  }
                | {
                    readonly __typename: "DocumentArtifactNode";
                    readonly id: number;
                    readonly artifactType: ArtifactType;
                    readonly title?: string | null;
                    readonly created: string;
                    readonly updated?: string | null;
                    readonly canDelete: {
                      readonly __typename: "PermissionNode";
                      readonly permission: boolean;
                    };
                    readonly creator?: {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    } | null;
                  }
                | {
                    readonly __typename: "FeedbackArtifactNode";
                    readonly id: number;
                    readonly artifactType: ArtifactType;
                    readonly title?: string | null;
                    readonly created: string;
                    readonly updated?: string | null;
                    readonly canDelete: {
                      readonly __typename: "PermissionNode";
                      readonly permission: boolean;
                    };
                    readonly creator?: {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    } | null;
                  }
                | {
                    readonly __typename: "GithubIssuesArtifactNode";
                    readonly id: number;
                    readonly artifactType: ArtifactType;
                    readonly title?: string | null;
                    readonly created: string;
                    readonly updated?: string | null;
                    readonly canDelete: {
                      readonly __typename: "PermissionNode";
                      readonly permission: boolean;
                    };
                    readonly creator?: {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    } | null;
                  }
                | {
                    readonly __typename: "GoalArtifactNode";
                    readonly id: number;
                    readonly artifactType: ArtifactType;
                    readonly title?: string | null;
                    readonly created: string;
                    readonly updated?: string | null;
                    readonly state: GoalState;
                    readonly status: GoalStatus;
                    readonly scope: GoalScope;
                    readonly goalVisibility: GoalVisibility;
                    readonly dueDate?: string | null;
                    readonly startDate?: string | null;
                    readonly progress: number;
                    readonly progressType: GoalProgressType;
                    readonly startValue: number;
                    readonly currentValue: number;
                    readonly targetValue: number;
                    readonly isStale: boolean;
                    readonly ownerCount?: number | null;
                    readonly contributorCount?: number | null;
                    readonly canDelete: {
                      readonly __typename: "PermissionNode";
                      readonly permission: boolean;
                    };
                    readonly creator?: {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    } | null;
                    readonly firstOwner?: {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly avatar?: string | null;
                      readonly name: string;
                    } | null;
                    readonly canUpdate: {
                      readonly __typename: "PermissionNode";
                      readonly permission: boolean;
                    };
                    readonly canRead: {
                      readonly __typename: "PermissionNode";
                      readonly permission: boolean;
                    };
                  }
                | {
                    readonly __typename: "KPIArtifactNode";
                    readonly id: number;
                    readonly artifactType: ArtifactType;
                    readonly title?: string | null;
                    readonly created: string;
                    readonly updated?: string | null;
                    readonly canDelete: {
                      readonly __typename: "PermissionNode";
                      readonly permission: boolean;
                    };
                    readonly creator?: {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    } | null;
                  }
                | {
                    readonly __typename: "RatingArtifactNode";
                    readonly id: number;
                    readonly artifactType: ArtifactType;
                    readonly title?: string | null;
                    readonly created: string;
                    readonly updated?: string | null;
                    readonly canDelete: {
                      readonly __typename: "PermissionNode";
                      readonly permission: boolean;
                    };
                    readonly creator?: {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    } | null;
                  }
                | {
                    readonly __typename: "RecognitionArtifactNode";
                    readonly id: number;
                    readonly artifactType: ArtifactType;
                    readonly title?: string | null;
                    readonly created: string;
                    readonly updated?: string | null;
                    readonly recipients?: {
                      readonly __typename: "UserNodeConnection";
                      readonly totalCount: number;
                      readonly edges: ReadonlyArray<{
                        readonly __typename: "UserNodeEdge";
                        readonly node?: {
                          readonly __typename: "UserNode";
                          readonly id: number;
                          readonly name: string;
                          readonly avatar?: string | null;
                        } | null;
                      } | null>;
                    } | null;
                    readonly canDelete: {
                      readonly __typename: "PermissionNode";
                      readonly permission: boolean;
                    };
                    readonly creator?: {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    } | null;
                  }
                | null;
            } | null>;
          } | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type GetRelatedTopicsQueryVariables = Exact<{
  topicId: Scalars["Int"]["input"];
  meetingGroupId: Scalars["Int"]["input"];
}>;

export type GetRelatedTopicsQuery = {
  readonly __typename: "Query";
  readonly topic?: {
    readonly __typename: "TopicNode";
    readonly id: number;
    readonly relatedTopics?: {
      readonly __typename: "TopicNodeConnection";
      readonly totalCount: number;
      readonly edges: ReadonlyArray<{
        readonly __typename: "TopicNodeEdge";
        readonly node?: {
          readonly __typename: "TopicNode";
          readonly id: number;
          readonly meeting?: {
            readonly __typename: "MeetingNode";
            readonly id: number;
            readonly startDatetime?: string | null;
          } | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type TopicSidebarTopicNodeFragment = {
  readonly __typename: "TopicNode";
  readonly discussionNotes?: any | null;
  readonly includesIndividualNotes: boolean;
  readonly includesIndividualNotesForCurrentUser: boolean;
  readonly includesSharedNotes: boolean;
  readonly notesRequirement: NotesRequirement;
  readonly id: number;
  readonly title: string;
  readonly description?: string | null;
  readonly created: string;
  readonly state?: TopicState | null;
  readonly eventChannelName?: string | null;
  readonly isMandatory: boolean;
  readonly individualNotes?: {
    readonly __typename: "IndividualNoteNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "IndividualNoteNodeEdge";
      readonly node?: {
        readonly __typename: "IndividualNoteNode";
        readonly id: number;
        readonly created: string;
        readonly hidden: boolean;
        readonly enabled: boolean;
        readonly notes?: any | null;
        readonly creator: {
          readonly __typename: "UserNode";
          readonly id: number;
        };
      } | null;
    } | null>;
  } | null;
  readonly meeting?: {
    readonly __typename: "MeetingNode";
    readonly id: number;
    readonly title?: string | null;
    readonly startDatetime?: string | null;
    readonly websocketToken?: string | null;
    readonly organization?: {
      readonly __typename: "OrganizationNode";
      readonly id: number;
    } | null;
    readonly meetingGroup?: {
      readonly __typename: "MeetingGroupNode";
      readonly id: number;
      readonly isFormalOneonone: boolean;
    } | null;
    readonly participants?: {
      readonly __typename: "MeetingParticipantConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "MeetingParticipantEdge";
        readonly node?: {
          readonly __typename: "MeetingParticipantNode";
          readonly id?: string | null;
          readonly status?: AttendeeStatus | null;
          readonly user?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly firstName: string;
            readonly lastName: string;
            readonly avatar?: string | null;
          } | null;
        } | null;
      } | null>;
    } | null;
  } | null;
  readonly creator?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
  } | null;
  readonly assignee?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly avatar?: string | null;
    readonly name: string;
  } | null;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly previousTopic?: {
    readonly __typename: "TopicNode";
    readonly id: number;
    readonly discussionNotes?: any | null;
  } | null;
};

export type GetTopicSidebarQueryVariables = Exact<{
  topicId: Scalars["Int"]["input"];
}>;

export type GetTopicSidebarQuery = {
  readonly __typename: "Query";
  readonly topic?: {
    readonly __typename: "TopicNode";
    readonly discussionNotes?: any | null;
    readonly includesIndividualNotes: boolean;
    readonly includesIndividualNotesForCurrentUser: boolean;
    readonly includesSharedNotes: boolean;
    readonly notesRequirement: NotesRequirement;
    readonly id: number;
    readonly title: string;
    readonly description?: string | null;
    readonly created: string;
    readonly state?: TopicState | null;
    readonly eventChannelName?: string | null;
    readonly isMandatory: boolean;
    readonly individualNotes?: {
      readonly __typename: "IndividualNoteNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "IndividualNoteNodeEdge";
        readonly node?: {
          readonly __typename: "IndividualNoteNode";
          readonly id: number;
          readonly notes?: any | null;
          readonly enabled: boolean;
          readonly hidden: boolean;
          readonly created: string;
          readonly creator: {
            readonly __typename: "UserNode";
            readonly id: number;
          };
        } | null;
      } | null>;
    } | null;
    readonly meeting?: {
      readonly __typename: "MeetingNode";
      readonly id: number;
      readonly title?: string | null;
      readonly startDatetime?: string | null;
      readonly websocketToken?: string | null;
      readonly startDate?: string | null;
      readonly endDatetime?: string | null;
      readonly externalUrl?: string | null;
      readonly videoConferenceType?: OnlineVideoConference | null;
      readonly videoConferenceUrl?: string | null;
      readonly allowExternalViewers?: boolean | null;
      readonly writableSharingUrl?: string | null;
      readonly hasPreviousMeetings?: boolean | null;
      readonly hasPreviousMeetingsWithTopics?: boolean | null;
      readonly ignored: boolean;
      readonly hasBotInMeeting?: boolean | null;
      readonly botIsRecording?: boolean | null;
      readonly botIsWaitingToJoinMeeting?: boolean | null;
      readonly finalizedAt?: string | null;
      readonly isFinalized: boolean;
      readonly isSubmitted: boolean;
      readonly hasIncompleteMandatoryTopics: boolean;
      readonly transcriptionStatus?: boolean | null;
      readonly created: string;
      readonly draft: boolean;
      readonly isRecurring: boolean;
      readonly hasExternalParticipants?: boolean | null;
      readonly organization?: {
        readonly __typename: "OrganizationNode";
        readonly id: number;
        readonly name: string;
        readonly effectivePricingTier?: PricingTier | null;
        readonly enableMeetingSummarization: boolean;
        readonly domains?: ReadonlyArray<string | null> | null;
      } | null;
      readonly meetingGroup?: {
        readonly __typename: "MeetingGroupNode";
        readonly id: number;
        readonly isFormalOneonone: boolean;
        readonly title: string;
        readonly hasTemplate?: boolean | null;
        readonly allowAdminVisibility: boolean;
        readonly allowOrgVisibility: boolean;
        readonly allowManagementTreeVisibility: boolean;
        readonly transcribeMeetingsByDefault?: boolean | null;
        readonly addTranscriptionBotToMeetingsByDefault: boolean;
        readonly canUpdate?: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        } | null;
        readonly facilitator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
          readonly name: string;
        } | null;
        readonly syncCredentials?: {
          readonly __typename: "UserSyncCredentialsNode";
          readonly id: number;
          readonly hasCalendarWriteCredentials?: boolean | null;
          readonly provider?: SyncCredentialsTypeEnum | null;
        } | null;
        readonly topicTemplate?: {
          readonly __typename: "TopicTemplateNode";
          readonly id: number;
          readonly title: string;
          readonly globalTemplate: boolean;
          readonly publicTemplate: boolean;
          readonly meetingTemplate: boolean;
          readonly finalizePermissions: FinalizePermissions;
          readonly organization?: {
            readonly __typename: "OrganizationNode";
            readonly id: number;
          } | null;
        } | null;
      } | null;
      readonly participants?: {
        readonly __typename: "MeetingParticipantConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "MeetingParticipantEdge";
          readonly node?: {
            readonly __typename: "MeetingParticipantNode";
            readonly id?: string | null;
            readonly status?: AttendeeStatus | null;
            readonly participantEmail?: string | null;
            readonly role?: AttendeeRole | null;
            readonly user?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly firstName: string;
              readonly lastName: string;
              readonly avatar?: string | null;
              readonly email: string;
              readonly status?: UserStatus | null;
            } | null;
          } | null;
        } | null>;
      } | null;
      readonly canFinalize: {
        readonly __typename: "PermissionNode";
        readonly permission: boolean;
      };
      readonly canDelete?: {
        readonly __typename: "PermissionNode";
        readonly permission: boolean;
      } | null;
      readonly canUpdate?: {
        readonly __typename: "PermissionNode";
        readonly permission: boolean;
      } | null;
      readonly finalizedBy?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly avatar?: string | null;
      } | null;
      readonly organizer?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
      } | null;
      readonly subject?: {
        readonly __typename: "UserNode";
        readonly id: number;
      } | null;
      readonly creator?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly avatar?: string | null;
      } | null;
      readonly transcript?: {
        readonly __typename: "MeetingTranscriptNode";
        readonly id: number;
      } | null;
      readonly topics?: {
        readonly __typename: "TopicNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "TopicNodeEdge";
          readonly node?: {
            readonly __typename: "TopicNode";
            readonly id: number;
            readonly title: string;
            readonly description?: string | null;
            readonly discussionNotes?: any | null;
            readonly created: string;
            readonly state?: TopicState | null;
            readonly eventChannelName?: string | null;
            readonly includesIndividualNotes: boolean;
            readonly includesIndividualNotesForCurrentUser: boolean;
            readonly includesSharedNotes: boolean;
            readonly isMandatory: boolean;
            readonly notesRequirement: NotesRequirement;
            readonly individualNotes?: {
              readonly __typename: "IndividualNoteNodeConnection";
              readonly edges: ReadonlyArray<{
                readonly __typename: "IndividualNoteNodeEdge";
                readonly node?: {
                  readonly __typename: "IndividualNoteNode";
                  readonly id: number;
                  readonly created: string;
                  readonly hidden: boolean;
                  readonly enabled: boolean;
                  readonly notes?: any | null;
                  readonly creator: {
                    readonly __typename: "UserNode";
                    readonly id: number;
                  };
                } | null;
              } | null>;
            } | null;
            readonly creator?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
            } | null;
            readonly assignee?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly avatar?: string | null;
              readonly name: string;
            } | null;
            readonly previousTopic?: {
              readonly __typename: "TopicNode";
              readonly id: number;
              readonly discussionNotes?: any | null;
            } | null;
            readonly linkedTemplateTopic?: {
              readonly __typename: "TopicNode";
              readonly id: number;
              readonly title: string;
              readonly discussionNotes?: any | null;
              readonly defaultSubjectNotes?: any | null;
              readonly defaultFacilitatorNotes?: any | null;
              readonly topicTemplate?: {
                readonly __typename: "TopicTemplateNode";
                readonly id: number;
                readonly title: string;
              } | null;
            } | null;
            readonly canUpdate: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly canDelete: {
              readonly __typename: "PermissionNode";
              readonly permission: boolean;
            };
            readonly copiedFrom?: {
              readonly __typename: "TopicNode";
              readonly id: number;
              readonly title: string;
              readonly meeting?: {
                readonly __typename: "MeetingNode";
                readonly id: number;
                readonly title?: string | null;
                readonly startDatetime?: string | null;
                readonly meetingGroupId: number;
              } | null;
            } | null;
          } | null;
        } | null>;
      } | null;
    } | null;
    readonly creator?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
    } | null;
    readonly assignee?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly avatar?: string | null;
      readonly name: string;
    } | null;
    readonly canDelete: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
    };
    readonly canUpdate: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
    };
    readonly previousTopic?: {
      readonly __typename: "TopicNode";
      readonly id: number;
      readonly discussionNotes?: any | null;
    } | null;
  } | null;
};

export type UpdateTopicTitleDescriptionMutationMutationVariables = Exact<{
  topicId: Scalars["Int"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateTopicTitleDescriptionMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateTopic?: {
    readonly __typename: "CreateOrUpdateTopicMutation";
    readonly topic?: {
      readonly __typename: "TopicNode";
      readonly id: number;
      readonly title: string;
      readonly description?: string | null;
    } | null;
  } | null;
};

export type SaveUserUiPreferenceMutationMutationVariables = Exact<{
  userId: Scalars["Int"]["input"];
  uiPreferenceCache?: InputMaybe<Scalars["JSONString"]["input"]>;
}>;

export type SaveUserUiPreferenceMutationMutation = {
  readonly __typename: "Mutation";
  readonly updateUser?: {
    readonly __typename: "UpdateUserMutation";
    readonly user?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly uiPreferenceCache?: any | null;
    } | null;
  } | null;
};

export type GetMyWorkHistoryQueryVariables = Exact<{
  userId: Scalars["Int"]["input"];
  organizationId: Scalars["Int"]["input"];
  start: Scalars["Date"]["input"];
  end: Scalars["Date"]["input"];
}>;

export type GetMyWorkHistoryQuery = {
  readonly __typename: "Query";
  readonly workHistory?: {
    readonly __typename: "WorkHistorySummaryNode";
    readonly id: number;
    readonly summary: string;
    readonly modifiedSummary?: string | null;
    readonly meetingCount: number;
    readonly meetingTimeHours: number;
    readonly created: string;
    readonly summaryStartDate: string;
    readonly summaryEndDate: string;
    readonly externalEventData: any;
    readonly subject?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
      readonly avatar?: string | null;
    } | null;
    readonly collaborators: {
      readonly __typename: "WorkHistoryCollaborationConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "WorkHistoryCollaborationEdge";
        readonly node?: {
          readonly __typename: "WorkHistoryCollaborationNode";
          readonly collaborationCount: number;
          readonly user?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
          } | null;
        } | null;
      } | null>;
    };
    readonly sections: {
      readonly __typename: "WorkHistorySummarySectionNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "WorkHistorySummarySectionNodeEdge";
        readonly node?: {
          readonly __typename: "WorkHistorySummarySectionNode";
          readonly id: number;
          readonly title: string;
          readonly modifiedTitle?: string | null;
          readonly content: string;
          readonly modifiedContent?: string | null;
          readonly hidden: boolean;
        } | null;
      } | null>;
    };
  } | null;
};

export type MyWorkHistoryItemFragmentFragment = {
  readonly __typename: "WorkHistorySummaryNode";
  readonly id: number;
  readonly summary: string;
  readonly modifiedSummary?: string | null;
  readonly meetingCount: number;
  readonly meetingTimeHours: number;
  readonly created: string;
  readonly summaryStartDate: string;
  readonly summaryEndDate: string;
  readonly externalEventData: any;
  readonly subject?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
  readonly collaborators: {
    readonly __typename: "WorkHistoryCollaborationConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "WorkHistoryCollaborationEdge";
      readonly node?: {
        readonly __typename: "WorkHistoryCollaborationNode";
        readonly collaborationCount: number;
        readonly user?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
      } | null;
    } | null>;
  };
  readonly sections: {
    readonly __typename: "WorkHistorySummarySectionNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "WorkHistorySummarySectionNodeEdge";
      readonly node?: {
        readonly __typename: "WorkHistorySummarySectionNode";
        readonly id: number;
        readonly title: string;
        readonly modifiedTitle?: string | null;
        readonly content: string;
        readonly modifiedContent?: string | null;
        readonly hidden: boolean;
      } | null;
    } | null>;
  };
};

export type UpdateMyWorkHistoryMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  data: WorkHistorySummaryInput;
}>;

export type UpdateMyWorkHistoryMutation = {
  readonly __typename: "Mutation";
  readonly updateWorkHistory?: {
    readonly __typename: "UpdateWorkHistoryMutation";
    readonly workHistorySummary?: {
      readonly __typename: "WorkHistorySummaryNode";
      readonly id: number;
      readonly summary: string;
      readonly modifiedSummary?: string | null;
      readonly meetingCount: number;
      readonly meetingTimeHours: number;
      readonly created: string;
      readonly summaryStartDate: string;
      readonly summaryEndDate: string;
      readonly externalEventData: any;
      readonly subject?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly avatar?: string | null;
      } | null;
      readonly collaborators: {
        readonly __typename: "WorkHistoryCollaborationConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "WorkHistoryCollaborationEdge";
          readonly node?: {
            readonly __typename: "WorkHistoryCollaborationNode";
            readonly collaborationCount: number;
            readonly user?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
          } | null;
        } | null>;
      };
      readonly sections: {
        readonly __typename: "WorkHistorySummarySectionNodeConnection";
        readonly edges: ReadonlyArray<{
          readonly __typename: "WorkHistorySummarySectionNodeEdge";
          readonly node?: {
            readonly __typename: "WorkHistorySummarySectionNode";
            readonly id: number;
            readonly title: string;
            readonly modifiedTitle?: string | null;
            readonly content: string;
            readonly modifiedContent?: string | null;
            readonly hidden: boolean;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type UpdateTopicQueryVariables = Exact<{
  topicId: Scalars["Int"]["input"];
}>;

export type UpdateTopicQuery = {
  readonly __typename: "Query";
  readonly topic?: {
    readonly __typename: "TopicNode";
    readonly id: number;
  } | null;
};

export type GetHubspotStagesQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetHubspotStagesQueryQuery = {
  readonly __typename: "Query";
  readonly hubspotPipelineStages?: {
    readonly __typename: "HubspotStageConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "HubspotStageEdge";
      readonly node?: {
        readonly __typename: "HubspotStageNode";
        readonly id: number;
        readonly stageName?: string | null;
        readonly pipelineName?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type UserComboboxTeamOptionFragment = {
  readonly __typename: "TeamNode";
  readonly id: number;
  readonly title: string;
};

export type GetUserComboboxOptionsQueryQueryVariables = Exact<{
  userId: Scalars["Int"]["input"];
  hasUserId: Scalars["Boolean"]["input"];
  teamId: Scalars["Int"]["input"];
  hasTeamId: Scalars["Boolean"]["input"];
  search?: InputMaybe<Scalars["String"]["input"]>;
  limitUser: Scalars["Int"]["input"];
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  excludeUserIds?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
  excludeTeamIds?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>
  >;
}>;

export type GetUserComboboxOptionsQueryQuery = {
  readonly __typename: "Query";
  readonly users: {
    readonly __typename: "UserNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "UserNodeEdge";
      readonly node?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly firstName: string;
        readonly email: string;
        readonly avatar?: string | null;
      } | null;
    } | null>;
  };
  readonly teams: {
    readonly __typename: "TeamNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "TeamNodeEdge";
      readonly node?: {
        readonly __typename: "TeamNode";
        readonly id: number;
        readonly title: string;
      } | null;
    } | null>;
  };
  readonly team?: {
    readonly __typename: "TeamNode";
    readonly id: number;
    readonly title: string;
  } | null;
  readonly user?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly firstName: string;
    readonly email: string;
    readonly avatar?: string | null;
  } | null;
};

type WysiwygArtifactFragment_ActionItemArtifactNode_Fragment = {
  readonly __typename: "ActionItemArtifactNode";
  readonly isComplete?: boolean | null;
  readonly actionItemState?: number | null;
  readonly dueDate?: string | null;
  readonly id: number;
  readonly uuid?: string | null;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly websocketToken?: string | null;
  readonly description?: any | null;
  readonly assignee?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly avatar?: string | null;
    readonly name: string;
  } | null;
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canRead: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
};

type WysiwygArtifactFragment_AsanaTaskArtifactNode_Fragment = {
  readonly __typename: "AsanaTaskArtifactNode";
  readonly id: number;
  readonly uuid?: string | null;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly websocketToken?: string | null;
  readonly description?: any | null;
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canRead: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
};

type WysiwygArtifactFragment_DecisionArtifactNode_Fragment = {
  readonly __typename: "DecisionArtifactNode";
  readonly decision: string;
  readonly decisionState?: DecisionState | null;
  readonly isDraft?: boolean | null;
  readonly id: number;
  readonly uuid?: string | null;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly websocketToken?: string | null;
  readonly description?: any | null;
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canRead: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
};

type WysiwygArtifactFragment_DocumentArtifactNode_Fragment = {
  readonly __typename: "DocumentArtifactNode";
  readonly documentVisibility?: DocumentVisibility | null;
  readonly id: number;
  readonly uuid?: string | null;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly websocketToken?: string | null;
  readonly description?: any | null;
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canRead: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
};

type WysiwygArtifactFragment_FeedbackArtifactNode_Fragment = {
  readonly __typename: "FeedbackArtifactNode";
  readonly id: number;
  readonly uuid?: string | null;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly websocketToken?: string | null;
  readonly description?: any | null;
  readonly sender: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
  };
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canRead: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
};

type WysiwygArtifactFragment_GithubIssuesArtifactNode_Fragment = {
  readonly __typename: "GithubIssuesArtifactNode";
  readonly id: number;
  readonly uuid?: string | null;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly websocketToken?: string | null;
  readonly description?: any | null;
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canRead: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
};

type WysiwygArtifactFragment_GoalArtifactNode_Fragment = {
  readonly __typename: "GoalArtifactNode";
  readonly currentUserIsOwner?: boolean | null;
  readonly id: number;
  readonly uuid?: string | null;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly websocketToken?: string | null;
  readonly description?: any | null;
  readonly created: string;
  readonly state: GoalState;
  readonly status: GoalStatus;
  readonly scope: GoalScope;
  readonly goalVisibility: GoalVisibility;
  readonly dueDate?: string | null;
  readonly startDate?: string | null;
  readonly progress: number;
  readonly progressType: GoalProgressType;
  readonly startValue: number;
  readonly currentValue: number;
  readonly targetValue: number;
  readonly isStale: boolean;
  readonly ownerCount?: number | null;
  readonly contributorCount?: number | null;
  readonly activities: {
    readonly __typename: "ArtifactActivityNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "ArtifactActivityNodeEdge";
      readonly node?: {
        readonly __typename: "ArtifactActivityNode";
        readonly id: number;
        readonly created: string;
        readonly activityType: ArtifactActivityType;
        readonly canDelete?: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
          readonly reason: string;
        } | null;
        readonly canUpdate?: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
          readonly reason: string;
        } | null;
        readonly actor?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly changedFields?: ReadonlyArray<{
          readonly __typename: "ArtifactChangedFieldNode";
          readonly fieldName?: string | null;
          readonly previousValue?: string | null;
          readonly newValue?: string | null;
          readonly changedFields?: ReadonlyArray<{
            readonly __typename: "ArtifactChangedFieldNode";
            readonly newValue?: string | null;
            readonly previousValue?: string | null;
            readonly fieldName?: string | null;
          } | null> | null;
          readonly newValueObject?:
            | {
                readonly __typename: "BaseArtifactNode";
                readonly id: number;
                readonly title: string;
                readonly artifactType: ArtifactType;
              }
            | {
                readonly __typename: "CommentNode";
                readonly id: number;
                readonly comment?: any | null;
                readonly deleted?: boolean | null;
                readonly creator?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
              }
            | {
                readonly __typename: "KeyResultNode";
                readonly id: number;
                readonly title: string;
              }
            | {
                readonly __typename: "TopicNode";
                readonly id: number;
                readonly title: string;
                readonly meeting?: {
                  readonly __typename: "MeetingNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly meetingGroupId: number;
                } | null;
              }
            | {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              }
            | null;
          readonly previousValueObject?:
            | {
                readonly __typename: "BaseArtifactNode";
                readonly id: number;
                readonly title: string;
                readonly artifactType: ArtifactType;
              }
            | { readonly __typename: "CommentNode" }
            | {
                readonly __typename: "KeyResultNode";
                readonly id: number;
                readonly title: string;
              }
            | {
                readonly __typename: "TopicNode";
                readonly id: number;
                readonly title: string;
                readonly meeting?: {
                  readonly __typename: "MeetingNode";
                  readonly id: number;
                  readonly title?: string | null;
                  readonly meetingGroupId: number;
                } | null;
              }
            | {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              }
            | null;
        } | null> | null;
      } | null;
    } | null>;
  };
  readonly keyResults: {
    readonly __typename: "KeyResultNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "KeyResultNodeEdge";
      readonly node?: {
        readonly __typename: "KeyResultNode";
        readonly id: number;
        readonly title: string;
        readonly startValue: number;
        readonly currentValue: number;
        readonly targetValue: number;
        readonly progressType: GoalProgressType;
        readonly kpi?: {
          readonly __typename: "KPINode";
          readonly id: number;
          readonly title: string;
          readonly currentMeasurement?: {
            readonly __typename: "KPIMeasurementNode";
            readonly id: number;
            readonly measurement: number;
          } | null;
        } | null;
        readonly canUpdate?: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        } | null;
        readonly assignee?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
          readonly email: string;
        } | null;
      } | null;
    } | null>;
  };
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canRead: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly firstOwner?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly avatar?: string | null;
    readonly name: string;
  } | null;
};

type WysiwygArtifactFragment_KpiArtifactNode_Fragment = {
  readonly __typename: "KPIArtifactNode";
  readonly id: number;
  readonly uuid?: string | null;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly websocketToken?: string | null;
  readonly description?: any | null;
  readonly measurement?: {
    readonly __typename: "KPIMeasurementNode";
    readonly id: number;
    readonly kpi?: {
      readonly __typename: "KPINode";
      readonly id: number;
      readonly title: string;
    } | null;
  } | null;
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canRead: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
};

type WysiwygArtifactFragment_RatingArtifactNode_Fragment = {
  readonly __typename: "RatingArtifactNode";
  readonly canAnswer?: boolean | null;
  readonly canViewAnswers?: boolean | null;
  readonly id: number;
  readonly uuid?: string | null;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly websocketToken?: string | null;
  readonly description?: any | null;
  readonly answers?: {
    readonly __typename: "RatingAnswerNodeConnection";
    readonly totalCount?: number | null;
    readonly edges: ReadonlyArray<{
      readonly __typename: "RatingAnswerNodeEdge";
      readonly node?: {
        readonly __typename: "RatingAnswerNode";
        readonly id: number;
        readonly integerAnswer: number;
        readonly creator: {
          readonly __typename: "UserNode";
          readonly id: number;
        };
      } | null;
    } | null>;
  } | null;
  readonly rating?: {
    readonly __typename: "RatingNode";
    readonly id: number;
    readonly title: string;
    readonly description: any;
    readonly startValue: number;
    readonly endValue: number;
    readonly labels: ReadonlyArray<string | null>;
  } | null;
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canRead: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
};

type WysiwygArtifactFragment_RecognitionArtifactNode_Fragment = {
  readonly __typename: "RecognitionArtifactNode";
  readonly id: number;
  readonly uuid?: string | null;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly websocketToken?: string | null;
  readonly description?: any | null;
  readonly created: string;
  readonly organization?: {
    readonly __typename: "OrganizationNode";
    readonly id: number;
  } | null;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canRead: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly creator?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
  readonly recipients?: {
    readonly __typename: "UserNodeConnection";
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly __typename: "UserNodeEdge";
      readonly node?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly avatar?: string | null;
      } | null;
    } | null>;
  } | null;
  readonly recognitionCoreValue?: {
    readonly __typename: "RecognitionCoreValueNode";
    readonly id: number;
    readonly title: string;
    readonly description: string;
    readonly emoji?: string | null;
    readonly image?: string | null;
  } | null;
};

export type WysiwygArtifactFragmentFragment =
  | WysiwygArtifactFragment_ActionItemArtifactNode_Fragment
  | WysiwygArtifactFragment_AsanaTaskArtifactNode_Fragment
  | WysiwygArtifactFragment_DecisionArtifactNode_Fragment
  | WysiwygArtifactFragment_DocumentArtifactNode_Fragment
  | WysiwygArtifactFragment_FeedbackArtifactNode_Fragment
  | WysiwygArtifactFragment_GithubIssuesArtifactNode_Fragment
  | WysiwygArtifactFragment_GoalArtifactNode_Fragment
  | WysiwygArtifactFragment_KpiArtifactNode_Fragment
  | WysiwygArtifactFragment_RatingArtifactNode_Fragment
  | WysiwygArtifactFragment_RecognitionArtifactNode_Fragment;

export type AssociateArtifactWithTopicOrArtifactMutationMutationVariables =
  Exact<{
    artifactId: Scalars["Int"]["input"];
    topicId?: InputMaybe<Scalars["Int"]["input"]>;
    otherArtifactId?: InputMaybe<Scalars["Int"]["input"]>;
  }>;

export type AssociateArtifactWithTopicOrArtifactMutationMutation = {
  readonly __typename: "Mutation";
  readonly associateArtifactWithTopicOrArtifact?: {
    readonly __typename: "AssociateArtifactWithTopicOrArtifactMutation";
    readonly artifact?:
      | { readonly __typename: "ActionItemArtifactNode"; readonly id: number }
      | { readonly __typename: "AsanaTaskArtifactNode"; readonly id: number }
      | { readonly __typename: "DecisionArtifactNode"; readonly id: number }
      | { readonly __typename: "DocumentArtifactNode"; readonly id: number }
      | { readonly __typename: "FeedbackArtifactNode"; readonly id: number }
      | { readonly __typename: "GithubIssuesArtifactNode"; readonly id: number }
      | { readonly __typename: "GoalArtifactNode"; readonly id: number }
      | { readonly __typename: "KPIArtifactNode"; readonly id: number }
      | { readonly __typename: "RatingArtifactNode"; readonly id: number }
      | { readonly __typename: "RecognitionArtifactNode"; readonly id: number }
      | null;
  } | null;
};

export type CreateAsanaArtifactMutationMutationVariables = Exact<{
  additionalFields?: InputMaybe<AdditionalArtifactInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  relatedTopicId?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  relatedArtifactId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type CreateAsanaArtifactMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateArtifact?: {
    readonly __typename: "CreateOrUpdateArtifactMutation";
    readonly artifact?:
      | { readonly __typename: "ActionItemArtifactNode" }
      | {
          readonly __typename: "AsanaTaskArtifactNode";
          readonly id: number;
          readonly title?: string | null;
          readonly isSynced?: boolean | null;
          readonly completedAt?: string | null;
          readonly dueDate?: string | null;
          readonly assignee?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
          } | null;
        }
      | { readonly __typename: "DecisionArtifactNode" }
      | { readonly __typename: "DocumentArtifactNode" }
      | { readonly __typename: "FeedbackArtifactNode" }
      | { readonly __typename: "GithubIssuesArtifactNode" }
      | { readonly __typename: "GoalArtifactNode" }
      | { readonly __typename: "KPIArtifactNode" }
      | { readonly __typename: "RatingArtifactNode" }
      | { readonly __typename: "RecognitionArtifactNode" }
      | null;
  } | null;
};

export type UpdateArtifactMutationMutationVariables = Exact<{
  additionalFields?: InputMaybe<AdditionalArtifactInput>;
  artifactId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactType?: InputMaybe<ArtifactType>;
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  relatedTopicId?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  relatedArtifactId?: InputMaybe<Scalars["Int"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateArtifactMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateArtifact?: {
    readonly __typename: "CreateOrUpdateArtifactMutation";
    readonly artifact?:
      | {
          readonly __typename: "ActionItemArtifactNode";
          readonly isComplete?: boolean | null;
          readonly actionItemState?: number | null;
          readonly dueDate?: string | null;
          readonly id: number;
          readonly uuid?: string | null;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly websocketToken?: string | null;
          readonly description?: any | null;
          readonly assignee?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly avatar?: string | null;
            readonly name: string;
          } | null;
          readonly organization?: {
            readonly __typename: "OrganizationNode";
            readonly id: number;
          } | null;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canUpdate: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canRead: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
        }
      | {
          readonly __typename: "AsanaTaskArtifactNode";
          readonly id: number;
          readonly uuid?: string | null;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly websocketToken?: string | null;
          readonly description?: any | null;
          readonly organization?: {
            readonly __typename: "OrganizationNode";
            readonly id: number;
          } | null;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canUpdate: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canRead: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
        }
      | {
          readonly __typename: "DecisionArtifactNode";
          readonly decision: string;
          readonly decisionState?: DecisionState | null;
          readonly isDraft?: boolean | null;
          readonly id: number;
          readonly uuid?: string | null;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly websocketToken?: string | null;
          readonly description?: any | null;
          readonly organization?: {
            readonly __typename: "OrganizationNode";
            readonly id: number;
          } | null;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canUpdate: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canRead: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
        }
      | {
          readonly __typename: "DocumentArtifactNode";
          readonly documentVisibility?: DocumentVisibility | null;
          readonly id: number;
          readonly uuid?: string | null;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly websocketToken?: string | null;
          readonly description?: any | null;
          readonly organization?: {
            readonly __typename: "OrganizationNode";
            readonly id: number;
          } | null;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canUpdate: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canRead: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
        }
      | {
          readonly __typename: "FeedbackArtifactNode";
          readonly id: number;
          readonly uuid?: string | null;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly websocketToken?: string | null;
          readonly description?: any | null;
          readonly sender: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
          };
          readonly organization?: {
            readonly __typename: "OrganizationNode";
            readonly id: number;
          } | null;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canUpdate: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canRead: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
        }
      | {
          readonly __typename: "GithubIssuesArtifactNode";
          readonly id: number;
          readonly uuid?: string | null;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly websocketToken?: string | null;
          readonly description?: any | null;
          readonly organization?: {
            readonly __typename: "OrganizationNode";
            readonly id: number;
          } | null;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canUpdate: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canRead: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
        }
      | {
          readonly __typename: "GoalArtifactNode";
          readonly currentUserIsOwner?: boolean | null;
          readonly id: number;
          readonly uuid?: string | null;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly websocketToken?: string | null;
          readonly description?: any | null;
          readonly created: string;
          readonly state: GoalState;
          readonly status: GoalStatus;
          readonly scope: GoalScope;
          readonly goalVisibility: GoalVisibility;
          readonly dueDate?: string | null;
          readonly startDate?: string | null;
          readonly progress: number;
          readonly progressType: GoalProgressType;
          readonly startValue: number;
          readonly currentValue: number;
          readonly targetValue: number;
          readonly isStale: boolean;
          readonly ownerCount?: number | null;
          readonly contributorCount?: number | null;
          readonly activities: {
            readonly __typename: "ArtifactActivityNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "ArtifactActivityNodeEdge";
              readonly node?: {
                readonly __typename: "ArtifactActivityNode";
                readonly id: number;
                readonly created: string;
                readonly activityType: ArtifactActivityType;
                readonly canDelete?: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                  readonly reason: string;
                } | null;
                readonly canUpdate?: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                  readonly reason: string;
                } | null;
                readonly actor?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                } | null;
                readonly changedFields?: ReadonlyArray<{
                  readonly __typename: "ArtifactChangedFieldNode";
                  readonly fieldName?: string | null;
                  readonly previousValue?: string | null;
                  readonly newValue?: string | null;
                  readonly changedFields?: ReadonlyArray<{
                    readonly __typename: "ArtifactChangedFieldNode";
                    readonly newValue?: string | null;
                    readonly previousValue?: string | null;
                    readonly fieldName?: string | null;
                  } | null> | null;
                  readonly newValueObject?:
                    | {
                        readonly __typename: "BaseArtifactNode";
                        readonly id: number;
                        readonly title: string;
                        readonly artifactType: ArtifactType;
                      }
                    | {
                        readonly __typename: "CommentNode";
                        readonly id: number;
                        readonly comment?: any | null;
                        readonly deleted?: boolean | null;
                        readonly creator?: {
                          readonly __typename: "UserNode";
                          readonly id: number;
                          readonly name: string;
                          readonly avatar?: string | null;
                        } | null;
                      }
                    | {
                        readonly __typename: "KeyResultNode";
                        readonly id: number;
                        readonly title: string;
                      }
                    | {
                        readonly __typename: "TopicNode";
                        readonly id: number;
                        readonly title: string;
                        readonly meeting?: {
                          readonly __typename: "MeetingNode";
                          readonly id: number;
                          readonly title?: string | null;
                          readonly meetingGroupId: number;
                        } | null;
                      }
                    | {
                        readonly __typename: "UserNode";
                        readonly id: number;
                        readonly name: string;
                        readonly avatar?: string | null;
                      }
                    | null;
                  readonly previousValueObject?:
                    | {
                        readonly __typename: "BaseArtifactNode";
                        readonly id: number;
                        readonly title: string;
                        readonly artifactType: ArtifactType;
                      }
                    | { readonly __typename: "CommentNode" }
                    | {
                        readonly __typename: "KeyResultNode";
                        readonly id: number;
                        readonly title: string;
                      }
                    | {
                        readonly __typename: "TopicNode";
                        readonly id: number;
                        readonly title: string;
                        readonly meeting?: {
                          readonly __typename: "MeetingNode";
                          readonly id: number;
                          readonly title?: string | null;
                          readonly meetingGroupId: number;
                        } | null;
                      }
                    | {
                        readonly __typename: "UserNode";
                        readonly id: number;
                        readonly name: string;
                        readonly avatar?: string | null;
                      }
                    | null;
                } | null> | null;
              } | null;
            } | null>;
          };
          readonly keyResults: {
            readonly __typename: "KeyResultNodeConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "KeyResultNodeEdge";
              readonly node?: {
                readonly __typename: "KeyResultNode";
                readonly id: number;
                readonly title: string;
                readonly startValue: number;
                readonly currentValue: number;
                readonly targetValue: number;
                readonly progressType: GoalProgressType;
                readonly kpi?: {
                  readonly __typename: "KPINode";
                  readonly id: number;
                  readonly title: string;
                  readonly currentMeasurement?: {
                    readonly __typename: "KPIMeasurementNode";
                    readonly id: number;
                    readonly measurement: number;
                  } | null;
                } | null;
                readonly canUpdate?: {
                  readonly __typename: "PermissionNode";
                  readonly permission: boolean;
                } | null;
                readonly assignee?: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                  readonly name: string;
                  readonly avatar?: string | null;
                  readonly email: string;
                } | null;
              } | null;
            } | null>;
          };
          readonly organization?: {
            readonly __typename: "OrganizationNode";
            readonly id: number;
          } | null;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canUpdate: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canRead: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly firstOwner?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly avatar?: string | null;
            readonly name: string;
          } | null;
        }
      | {
          readonly __typename: "KPIArtifactNode";
          readonly id: number;
          readonly uuid?: string | null;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly websocketToken?: string | null;
          readonly description?: any | null;
          readonly measurement?: {
            readonly __typename: "KPIMeasurementNode";
            readonly id: number;
            readonly kpi?: {
              readonly __typename: "KPINode";
              readonly id: number;
              readonly title: string;
            } | null;
          } | null;
          readonly organization?: {
            readonly __typename: "OrganizationNode";
            readonly id: number;
          } | null;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canUpdate: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canRead: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
        }
      | {
          readonly __typename: "RatingArtifactNode";
          readonly canAnswer?: boolean | null;
          readonly canViewAnswers?: boolean | null;
          readonly id: number;
          readonly uuid?: string | null;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly websocketToken?: string | null;
          readonly description?: any | null;
          readonly answers?: {
            readonly __typename: "RatingAnswerNodeConnection";
            readonly totalCount?: number | null;
            readonly edges: ReadonlyArray<{
              readonly __typename: "RatingAnswerNodeEdge";
              readonly node?: {
                readonly __typename: "RatingAnswerNode";
                readonly id: number;
                readonly integerAnswer: number;
                readonly creator: {
                  readonly __typename: "UserNode";
                  readonly id: number;
                };
              } | null;
            } | null>;
          } | null;
          readonly rating?: {
            readonly __typename: "RatingNode";
            readonly id: number;
            readonly title: string;
            readonly description: any;
            readonly startValue: number;
            readonly endValue: number;
            readonly labels: ReadonlyArray<string | null>;
          } | null;
          readonly organization?: {
            readonly __typename: "OrganizationNode";
            readonly id: number;
          } | null;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canUpdate: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canRead: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
        }
      | {
          readonly __typename: "RecognitionArtifactNode";
          readonly id: number;
          readonly uuid?: string | null;
          readonly title?: string | null;
          readonly artifactType: ArtifactType;
          readonly websocketToken?: string | null;
          readonly description?: any | null;
          readonly created: string;
          readonly organization?: {
            readonly __typename: "OrganizationNode";
            readonly id: number;
          } | null;
          readonly canDelete: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canUpdate: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly canRead: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
          };
          readonly creator?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
          } | null;
          readonly recipients?: {
            readonly __typename: "UserNodeConnection";
            readonly totalCount: number;
            readonly edges: ReadonlyArray<{
              readonly __typename: "UserNodeEdge";
              readonly node?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
            } | null>;
          } | null;
          readonly recognitionCoreValue?: {
            readonly __typename: "RecognitionCoreValueNode";
            readonly id: number;
            readonly title: string;
            readonly description: string;
            readonly emoji?: string | null;
            readonly image?: string | null;
          } | null;
        }
      | null;
  } | null;
};

export type AsanaArtifactFragmentFragment = {
  readonly __typename: "AsanaTaskArtifactNode";
  readonly id: number;
  readonly title?: string | null;
  readonly isSynced?: boolean | null;
  readonly completedAt?: string | null;
  readonly dueDate?: string | null;
  readonly assignee?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
  } | null;
};

export type GetAsanaArtifactQueryQueryVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type GetAsanaArtifactQueryQuery = {
  readonly __typename: "Query";
  readonly artifact:
    | { readonly __typename: "ActionItemArtifactNode" }
    | {
        readonly __typename: "AsanaTaskArtifactNode";
        readonly id: number;
        readonly title?: string | null;
        readonly isSynced?: boolean | null;
        readonly completedAt?: string | null;
        readonly dueDate?: string | null;
        readonly assignee?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
        } | null;
      }
    | { readonly __typename: "DecisionArtifactNode" }
    | { readonly __typename: "DocumentArtifactNode" }
    | { readonly __typename: "FeedbackArtifactNode" }
    | { readonly __typename: "GithubIssuesArtifactNode" }
    | { readonly __typename: "GoalArtifactNode" }
    | { readonly __typename: "KPIArtifactNode" }
    | { readonly __typename: "RatingArtifactNode" }
    | { readonly __typename: "RecognitionArtifactNode" };
};

export type GetEmbedKpiGroupQueryQueryVariables = Exact<{
  kpiGroupId: Scalars["Int"]["input"];
  teamId: Scalars["Int"]["input"];
  hasTeamId: Scalars["Boolean"]["input"];
  summaryPeriod?: InputMaybe<KpiSummaryPeriod>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetEmbedKpiGroupQueryQuery = {
  readonly __typename: "Query";
  readonly kpiGroup?: {
    readonly __typename: "KPIGroupNode";
    readonly id: number;
    readonly title: string;
    readonly kpis?: {
      readonly __typename: "KPINodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "KPINodeEdge";
        readonly node?: {
          readonly __typename: "KPINode";
          readonly id: number;
          readonly title: string;
          readonly created: string;
          readonly targetType: KpiTargetType;
          readonly targetValue?: number | null;
          readonly targetCondition: KpiTargetCondition;
          readonly defaultSummaryPeriod: KpiSummaryPeriod;
          readonly canUpdate: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
            readonly reason: string;
          };
          readonly currentMeasurement?: {
            readonly __typename: "KPIMeasurementNode";
            readonly id: number;
            readonly measurement: number;
          } | null;
          readonly summarizedMeasurements: {
            readonly __typename: "SummarizedMeasurementsConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "SummarizedMeasurementsEdge";
              readonly node?: {
                readonly __typename: "SummarizedMeasurements";
                readonly aggregateMeasurementId?: string | null;
                readonly aggregateMeasurement?: number | null;
                readonly date?: string | null;
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    } | null;
  } | null;
  readonly team?: {
    readonly __typename: "TeamNode";
    readonly id: number;
    readonly title: string;
    readonly kpis?: {
      readonly __typename: "KPINodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "KPINodeEdge";
        readonly node?: {
          readonly __typename: "KPINode";
          readonly id: number;
          readonly title: string;
          readonly created: string;
          readonly targetType: KpiTargetType;
          readonly targetValue?: number | null;
          readonly targetCondition: KpiTargetCondition;
          readonly defaultSummaryPeriod: KpiSummaryPeriod;
          readonly canUpdate: {
            readonly __typename: "PermissionNode";
            readonly permission: boolean;
            readonly reason: string;
          };
          readonly currentMeasurement?: {
            readonly __typename: "KPIMeasurementNode";
            readonly id: number;
            readonly measurement: number;
          } | null;
          readonly summarizedMeasurements: {
            readonly __typename: "SummarizedMeasurementsConnection";
            readonly edges: ReadonlyArray<{
              readonly __typename: "SummarizedMeasurementsEdge";
              readonly node?: {
                readonly __typename: "SummarizedMeasurements";
                readonly aggregateMeasurementId?: string | null;
                readonly aggregateMeasurement?: number | null;
                readonly date?: string | null;
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type GetEmbedKpiQueryQueryVariables = Exact<{
  kpiId: Scalars["Int"]["input"];
  summaryPeriod?: InputMaybe<KpiSummaryPeriod>;
  summaryMethod?: InputMaybe<KpiSummaryMethod>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetEmbedKpiQueryQuery = {
  readonly __typename: "Query";
  readonly kpi?: {
    readonly __typename: "KPINode";
    readonly id: number;
    readonly title: string;
    readonly created: string;
    readonly targetType: KpiTargetType;
    readonly targetValue?: number | null;
    readonly targetCondition: KpiTargetCondition;
    readonly defaultSummaryPeriod: KpiSummaryPeriod;
    readonly canUpdate: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
      readonly reason: string;
    };
    readonly currentMeasurement?: {
      readonly __typename: "KPIMeasurementNode";
      readonly id: number;
      readonly measurement: number;
    } | null;
    readonly summarizedMeasurements: {
      readonly __typename: "SummarizedMeasurementsConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "SummarizedMeasurementsEdge";
        readonly node?: {
          readonly __typename: "SummarizedMeasurements";
          readonly aggregateMeasurementId?: string | null;
          readonly aggregateMeasurement?: number | null;
          readonly date?: string | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type GetKpiGroupsQueryQueryVariables = Exact<{ [key: string]: never }>;

export type GetKpiGroupsQueryQuery = {
  readonly __typename: "Query";
  readonly kpiGroups?: {
    readonly __typename: "KPIGroupNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "KPIGroupNodeEdge";
      readonly node?: {
        readonly __typename: "KPIGroupNode";
        readonly id: number;
        readonly title: string;
        readonly kpis?: {
          readonly __typename: "KPINodeConnection";
          readonly totalCount: number;
        } | null;
      } | null;
    } | null>;
  } | null;
  readonly teams: {
    readonly __typename: "TeamNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "TeamNodeEdge";
      readonly node?: {
        readonly __typename: "TeamNode";
        readonly id: number;
        readonly title: string;
        readonly kpis?: {
          readonly __typename: "KPINodeConnection";
          readonly totalCount: number;
        } | null;
      } | null;
    } | null>;
  };
};

export type GetKpIsQueryQueryVariables = Exact<{ [key: string]: never }>;

export type GetKpIsQueryQuery = {
  readonly __typename: "Query";
  readonly kpis?: {
    readonly __typename: "KPINodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "KPINodeEdge";
      readonly node?: {
        readonly __typename: "KPINode";
        readonly id: number;
        readonly title: string;
        readonly description?: any | null;
      } | null;
    } | null>;
  } | null;
};

export type GetRatingsQueryQueryVariables = Exact<{ [key: string]: never }>;

export type GetRatingsQueryQuery = {
  readonly __typename: "Query";
  readonly ratings?: {
    readonly __typename: "RatingNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "RatingNodeEdge";
      readonly node?: {
        readonly __typename: "RatingNode";
        readonly id: number;
        readonly title: string;
      } | null;
    } | null>;
  } | null;
};

export type GetWysiwygArtifactCommentQueryQueryVariables = Exact<{
  commentId: Scalars["Int"]["input"];
}>;

export type GetWysiwygArtifactCommentQueryQuery = {
  readonly __typename: "Query";
  readonly comment?: {
    readonly __typename: "CommentNode";
    readonly id: number;
    readonly created: string;
    readonly comment?: any | null;
    readonly artifact?:
      | { readonly __typename: "ActionItemArtifactNode"; readonly id: number }
      | { readonly __typename: "AsanaTaskArtifactNode"; readonly id: number }
      | { readonly __typename: "DecisionArtifactNode"; readonly id: number }
      | { readonly __typename: "DocumentArtifactNode"; readonly id: number }
      | { readonly __typename: "FeedbackArtifactNode"; readonly id: number }
      | { readonly __typename: "GithubIssuesArtifactNode"; readonly id: number }
      | { readonly __typename: "GoalArtifactNode"; readonly id: number }
      | { readonly __typename: "KPIArtifactNode"; readonly id: number }
      | { readonly __typename: "RatingArtifactNode"; readonly id: number }
      | { readonly __typename: "RecognitionArtifactNode"; readonly id: number }
      | null;
    readonly creator?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
      readonly avatar?: string | null;
    } | null;
    readonly canUpdate?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
    } | null;
    readonly canDelete?: {
      readonly __typename: "PermissionNode";
      readonly permission: boolean;
    } | null;
  } | null;
};

export type GetWysiwygArtifactQueryQueryVariables = Exact<{
  artifactId: Scalars["Int"]["input"];
}>;

export type GetWysiwygArtifactQueryQuery = {
  readonly __typename: "Query";
  readonly artifact:
    | {
        readonly __typename: "ActionItemArtifactNode";
        readonly isComplete?: boolean | null;
        readonly actionItemState?: number | null;
        readonly dueDate?: string | null;
        readonly id: number;
        readonly uuid?: string | null;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly websocketToken?: string | null;
        readonly description?: any | null;
        readonly assignee?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly avatar?: string | null;
          readonly name: string;
        } | null;
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canRead: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "AsanaTaskArtifactNode";
        readonly id: number;
        readonly uuid?: string | null;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly websocketToken?: string | null;
        readonly description?: any | null;
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canRead: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "DecisionArtifactNode";
        readonly decision: string;
        readonly decisionState?: DecisionState | null;
        readonly isDraft?: boolean | null;
        readonly id: number;
        readonly uuid?: string | null;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly websocketToken?: string | null;
        readonly description?: any | null;
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canRead: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "DocumentArtifactNode";
        readonly documentVisibility?: DocumentVisibility | null;
        readonly id: number;
        readonly uuid?: string | null;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly websocketToken?: string | null;
        readonly description?: any | null;
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canRead: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "FeedbackArtifactNode";
        readonly id: number;
        readonly uuid?: string | null;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly websocketToken?: string | null;
        readonly description?: any | null;
        readonly sender: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
        };
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canRead: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "GithubIssuesArtifactNode";
        readonly id: number;
        readonly uuid?: string | null;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly websocketToken?: string | null;
        readonly description?: any | null;
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canRead: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "GoalArtifactNode";
        readonly currentUserIsOwner?: boolean | null;
        readonly id: number;
        readonly uuid?: string | null;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly websocketToken?: string | null;
        readonly description?: any | null;
        readonly created: string;
        readonly state: GoalState;
        readonly status: GoalStatus;
        readonly scope: GoalScope;
        readonly goalVisibility: GoalVisibility;
        readonly dueDate?: string | null;
        readonly startDate?: string | null;
        readonly progress: number;
        readonly progressType: GoalProgressType;
        readonly startValue: number;
        readonly currentValue: number;
        readonly targetValue: number;
        readonly isStale: boolean;
        readonly ownerCount?: number | null;
        readonly contributorCount?: number | null;
        readonly activities: {
          readonly __typename: "ArtifactActivityNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "ArtifactActivityNodeEdge";
            readonly node?: {
              readonly __typename: "ArtifactActivityNode";
              readonly id: number;
              readonly created: string;
              readonly activityType: ArtifactActivityType;
              readonly canDelete?: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
                readonly reason: string;
              } | null;
              readonly canUpdate?: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
                readonly reason: string;
              } | null;
              readonly actor?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
              } | null;
              readonly changedFields?: ReadonlyArray<{
                readonly __typename: "ArtifactChangedFieldNode";
                readonly fieldName?: string | null;
                readonly previousValue?: string | null;
                readonly newValue?: string | null;
                readonly changedFields?: ReadonlyArray<{
                  readonly __typename: "ArtifactChangedFieldNode";
                  readonly newValue?: string | null;
                  readonly previousValue?: string | null;
                  readonly fieldName?: string | null;
                } | null> | null;
                readonly newValueObject?:
                  | {
                      readonly __typename: "BaseArtifactNode";
                      readonly id: number;
                      readonly title: string;
                      readonly artifactType: ArtifactType;
                    }
                  | {
                      readonly __typename: "CommentNode";
                      readonly id: number;
                      readonly comment?: any | null;
                      readonly deleted?: boolean | null;
                      readonly creator?: {
                        readonly __typename: "UserNode";
                        readonly id: number;
                        readonly name: string;
                        readonly avatar?: string | null;
                      } | null;
                    }
                  | {
                      readonly __typename: "KeyResultNode";
                      readonly id: number;
                      readonly title: string;
                    }
                  | {
                      readonly __typename: "TopicNode";
                      readonly id: number;
                      readonly title: string;
                      readonly meeting?: {
                        readonly __typename: "MeetingNode";
                        readonly id: number;
                        readonly title?: string | null;
                        readonly meetingGroupId: number;
                      } | null;
                    }
                  | {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    }
                  | null;
                readonly previousValueObject?:
                  | {
                      readonly __typename: "BaseArtifactNode";
                      readonly id: number;
                      readonly title: string;
                      readonly artifactType: ArtifactType;
                    }
                  | { readonly __typename: "CommentNode" }
                  | {
                      readonly __typename: "KeyResultNode";
                      readonly id: number;
                      readonly title: string;
                    }
                  | {
                      readonly __typename: "TopicNode";
                      readonly id: number;
                      readonly title: string;
                      readonly meeting?: {
                        readonly __typename: "MeetingNode";
                        readonly id: number;
                        readonly title?: string | null;
                        readonly meetingGroupId: number;
                      } | null;
                    }
                  | {
                      readonly __typename: "UserNode";
                      readonly id: number;
                      readonly name: string;
                      readonly avatar?: string | null;
                    }
                  | null;
              } | null> | null;
            } | null;
          } | null>;
        };
        readonly keyResults: {
          readonly __typename: "KeyResultNodeConnection";
          readonly edges: ReadonlyArray<{
            readonly __typename: "KeyResultNodeEdge";
            readonly node?: {
              readonly __typename: "KeyResultNode";
              readonly id: number;
              readonly title: string;
              readonly startValue: number;
              readonly currentValue: number;
              readonly targetValue: number;
              readonly progressType: GoalProgressType;
              readonly kpi?: {
                readonly __typename: "KPINode";
                readonly id: number;
                readonly title: string;
                readonly currentMeasurement?: {
                  readonly __typename: "KPIMeasurementNode";
                  readonly id: number;
                  readonly measurement: number;
                } | null;
              } | null;
              readonly canUpdate?: {
                readonly __typename: "PermissionNode";
                readonly permission: boolean;
              } | null;
              readonly assignee?: {
                readonly __typename: "UserNode";
                readonly id: number;
                readonly name: string;
                readonly avatar?: string | null;
                readonly email: string;
              } | null;
            } | null;
          } | null>;
        };
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canRead: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly firstOwner?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly avatar?: string | null;
          readonly name: string;
        } | null;
      }
    | {
        readonly __typename: "KPIArtifactNode";
        readonly id: number;
        readonly uuid?: string | null;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly websocketToken?: string | null;
        readonly description?: any | null;
        readonly measurement?: {
          readonly __typename: "KPIMeasurementNode";
          readonly id: number;
          readonly kpi?: {
            readonly __typename: "KPINode";
            readonly id: number;
            readonly title: string;
          } | null;
        } | null;
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canRead: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "RatingArtifactNode";
        readonly canAnswer?: boolean | null;
        readonly canViewAnswers?: boolean | null;
        readonly id: number;
        readonly uuid?: string | null;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly websocketToken?: string | null;
        readonly description?: any | null;
        readonly answers?: {
          readonly __typename: "RatingAnswerNodeConnection";
          readonly totalCount?: number | null;
          readonly edges: ReadonlyArray<{
            readonly __typename: "RatingAnswerNodeEdge";
            readonly node?: {
              readonly __typename: "RatingAnswerNode";
              readonly id: number;
              readonly integerAnswer: number;
              readonly creator: {
                readonly __typename: "UserNode";
                readonly id: number;
              };
            } | null;
          } | null>;
        } | null;
        readonly rating?: {
          readonly __typename: "RatingNode";
          readonly id: number;
          readonly title: string;
          readonly description: any;
          readonly startValue: number;
          readonly endValue: number;
          readonly labels: ReadonlyArray<string | null>;
        } | null;
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canRead: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
      }
    | {
        readonly __typename: "RecognitionArtifactNode";
        readonly id: number;
        readonly uuid?: string | null;
        readonly title?: string | null;
        readonly artifactType: ArtifactType;
        readonly websocketToken?: string | null;
        readonly description?: any | null;
        readonly created: string;
        readonly organization?: {
          readonly __typename: "OrganizationNode";
          readonly id: number;
        } | null;
        readonly canDelete: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canUpdate: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly canRead: {
          readonly __typename: "PermissionNode";
          readonly permission: boolean;
        };
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
        readonly recipients?: {
          readonly __typename: "UserNodeConnection";
          readonly totalCount: number;
          readonly edges: ReadonlyArray<{
            readonly __typename: "UserNodeEdge";
            readonly node?: {
              readonly __typename: "UserNode";
              readonly id: number;
              readonly name: string;
              readonly avatar?: string | null;
            } | null;
          } | null>;
        } | null;
        readonly recognitionCoreValue?: {
          readonly __typename: "RecognitionCoreValueNode";
          readonly id: number;
          readonly title: string;
          readonly description: string;
          readonly emoji?: string | null;
          readonly image?: string | null;
        } | null;
      };
};

export type CreateNoteCommentMutationMutationVariables = Exact<{
  uuid: Scalars["String"]["input"];
  comment: Scalars["String"]["input"];
  topicId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type CreateNoteCommentMutationMutation = {
  readonly __typename: "Mutation";
  readonly createOrUpdateComment?: {
    readonly __typename: "CreateOrUpdateCommentMutation";
    readonly comment?: {
      readonly __typename: "CommentNode";
      readonly id: number;
      readonly created: string;
      readonly comment?: any | null;
      readonly creator?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly avatar?: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetInlineCommentsQueryQueryVariables = Exact<{
  uuid: Scalars["String"]["input"];
}>;

export type GetInlineCommentsQueryQuery = {
  readonly __typename: "Query";
  readonly me?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly avatar?: string | null;
    readonly name: string;
  } | null;
  readonly comments?: {
    readonly __typename: "CommentNodeConnection";
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly __typename: "CommentNodeEdge";
      readonly node?: {
        readonly __typename: "CommentNode";
        readonly id: number;
        readonly created: string;
        readonly comment?: any | null;
        readonly creator?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly avatar?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetMeetingEmbedQueryQueryVariables = Exact<{
  meetingId: Scalars["Int"]["input"];
}>;

export type GetMeetingEmbedQueryQuery = {
  readonly __typename: "Query";
  readonly meeting?: {
    readonly __typename: "MeetingNode";
    readonly id: number;
    readonly title?: string | null;
    readonly startDatetime?: string | null;
    readonly meetingGroupId: number;
  } | null;
};

export type GetUrlPreviewQueryVariables = Exact<{
  url: Scalars["String"]["input"];
  forceRefresh?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GetUrlPreviewQuery = {
  readonly __typename: "Query";
  readonly urlToInfoCard:
    | {
        readonly __typename: "ClickupTaskNode";
        readonly id: number;
        readonly title: string;
        readonly url: string;
        readonly closedAt?: string | null;
        readonly assignees: ReadonlyArray<{
          readonly __typename: "ExternalProfileNode";
          readonly username?: string | null;
          readonly url?: string | null;
        } | null>;
      }
    | {
        readonly __typename: "GithubIssueNode";
        readonly isMerged?: boolean | null;
        readonly status?: InfoTaskStatus | null;
        readonly id: number;
        readonly title: string;
        readonly url: string;
        readonly closedAt?: string | null;
        readonly assignees: ReadonlyArray<{
          readonly __typename: "ExternalProfileNode";
          readonly username?: string | null;
          readonly url?: string | null;
        } | null>;
      }
    | {
        readonly __typename: "HubspotDealNode";
        readonly amount?: number | null;
        readonly closedWon?: boolean | null;
        readonly stageName?: string | null;
        readonly id: number;
        readonly title: string;
        readonly url: string;
        readonly closedAt?: string | null;
        readonly assignees: ReadonlyArray<{
          readonly __typename: "ExternalProfileNode";
          readonly username?: string | null;
          readonly url?: string | null;
        } | null>;
      }
    | {
        readonly __typename: "JiraIssueNode";
        readonly id: number;
        readonly title: string;
        readonly url: string;
        readonly closedAt?: string | null;
        readonly assignees: ReadonlyArray<{
          readonly __typename: "ExternalProfileNode";
          readonly username?: string | null;
          readonly url?: string | null;
        } | null>;
      }
    | {
        readonly __typename: "SalesforceOpportunityNode";
        readonly amount?: number | null;
        readonly id: number;
        readonly title: string;
        readonly url: string;
        readonly closedAt?: string | null;
        readonly assignees: ReadonlyArray<{
          readonly __typename: "ExternalProfileNode";
          readonly username?: string | null;
          readonly url?: string | null;
        } | null>;
      };
};

export type PrepareFileUploadMutationMutationVariables = Exact<{
  filename: Scalars["String"]["input"];
  contentType: Scalars["String"]["input"];
  topicId?: InputMaybe<Scalars["Int"]["input"]>;
  artifactId?: InputMaybe<Scalars["Int"]["input"]>;
  recognitionCoreValueId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type PrepareFileUploadMutationMutation = {
  readonly __typename: "Mutation";
  readonly prepareFileUpload?: {
    readonly __typename: "PrepareFileUploadMutation";
    readonly fileUploadUrl?: string | null;
    readonly fileViewingUrl?: string | null;
  } | null;
};

export type GetCachedMeetingGroupSuggestionsQueryQueryVariables = Exact<{
  meetingGroupId: Scalars["Int"]["input"];
  flowId?: InputMaybe<Scalars["Int"]["input"]>;
  hasMeeting: Scalars["Boolean"]["input"];
  participantSearch?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetCachedMeetingGroupSuggestionsQueryQuery = {
  readonly __typename: "Query";
  readonly meetingGroup?: {
    readonly __typename: "MeetingGroupNode";
    readonly id: number;
    readonly facilitator?: {
      readonly __typename: "UserNode";
      readonly id: number;
      readonly name: string;
      readonly firstName: string;
      readonly lastName: string;
      readonly avatar?: string | null;
    } | null;
    readonly participants: {
      readonly __typename: "MeetingParticipantConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "MeetingParticipantEdge";
        readonly node?: {
          readonly __typename: "MeetingParticipantNode";
          readonly id?: string | null;
          readonly user?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly firstName: string;
            readonly lastName: string;
            readonly avatar?: string | null;
          } | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type GetCachedMeetingSuggestionsQueryQueryVariables = Exact<{
  meetingId?: InputMaybe<Scalars["Int"]["input"]>;
  participantSearch?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetCachedMeetingSuggestionsQueryQuery = {
  readonly __typename: "Query";
  readonly meeting?: {
    readonly __typename: "MeetingNode";
    readonly id: number;
    readonly participants?: {
      readonly __typename: "MeetingParticipantConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "MeetingParticipantEdge";
        readonly node?: {
          readonly __typename: "MeetingParticipantNode";
          readonly id?: string | null;
          readonly status?: AttendeeStatus | null;
          readonly participantEmail?: string | null;
          readonly role?: AttendeeRole | null;
          readonly user?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly firstName: string;
            readonly lastName: string;
            readonly avatar?: string | null;
            readonly email: string;
            readonly status?: UserStatus | null;
          } | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type SearchAtMentionsQueryQueryVariables = Exact<{
  limit: Scalars["Int"]["input"];
  search: Scalars["String"]["input"];
  onlyUsers: Scalars["Boolean"]["input"];
}>;

export type SearchAtMentionsQueryQuery = {
  readonly __typename: "Query";
  readonly users: {
    readonly __typename: "UserNodeConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "UserNodeEdge";
      readonly node?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly avatar?: string | null;
      } | null;
    } | null>;
  };
  readonly artifacts?: {
    readonly __typename: "ArtifactConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "ArtifactEdge";
      readonly node?:
        | {
            readonly __typename: "ActionItemArtifactNode";
            readonly isComplete?: boolean | null;
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
            readonly assignee?: {
              readonly __typename: "UserNode";
              readonly id: number;
            } | null;
          }
        | {
            readonly __typename: "AsanaTaskArtifactNode";
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
          }
        | {
            readonly __typename: "DecisionArtifactNode";
            readonly decision: string;
            readonly decisionState?: DecisionState | null;
            readonly isDraft?: boolean | null;
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
          }
        | {
            readonly __typename: "DocumentArtifactNode";
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
          }
        | {
            readonly __typename: "FeedbackArtifactNode";
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
          }
        | {
            readonly __typename: "GithubIssuesArtifactNode";
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
          }
        | {
            readonly __typename: "GoalArtifactNode";
            readonly progress: number;
            readonly goalVisibility: GoalVisibility;
            readonly scope: GoalScope;
            readonly state: GoalState;
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
          }
        | {
            readonly __typename: "KPIArtifactNode";
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
          }
        | {
            readonly __typename: "RatingArtifactNode";
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
          }
        | {
            readonly __typename: "RecognitionArtifactNode";
            readonly id: number;
            readonly title?: string | null;
            readonly artifactType: ArtifactType;
          }
        | null;
    } | null>;
  } | null;
};

type ArtifactAssigneeFragment_ActionItemArtifactNode_Fragment = {
  readonly __typename: "ActionItemArtifactNode";
  readonly id: number;
  readonly artifactType: ArtifactType;
  readonly assignee?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
};

type ArtifactAssigneeFragment_AsanaTaskArtifactNode_Fragment = {
  readonly __typename: "AsanaTaskArtifactNode";
  readonly id: number;
  readonly artifactType: ArtifactType;
};

type ArtifactAssigneeFragment_DecisionArtifactNode_Fragment = {
  readonly __typename: "DecisionArtifactNode";
  readonly id: number;
  readonly artifactType: ArtifactType;
};

type ArtifactAssigneeFragment_DocumentArtifactNode_Fragment = {
  readonly __typename: "DocumentArtifactNode";
  readonly id: number;
  readonly artifactType: ArtifactType;
};

type ArtifactAssigneeFragment_FeedbackArtifactNode_Fragment = {
  readonly __typename: "FeedbackArtifactNode";
  readonly id: number;
  readonly artifactType: ArtifactType;
};

type ArtifactAssigneeFragment_GithubIssuesArtifactNode_Fragment = {
  readonly __typename: "GithubIssuesArtifactNode";
  readonly id: number;
  readonly artifactType: ArtifactType;
};

type ArtifactAssigneeFragment_GoalArtifactNode_Fragment = {
  readonly __typename: "GoalArtifactNode";
  readonly id: number;
  readonly artifactType: ArtifactType;
};

type ArtifactAssigneeFragment_KpiArtifactNode_Fragment = {
  readonly __typename: "KPIArtifactNode";
  readonly id: number;
  readonly artifactType: ArtifactType;
};

type ArtifactAssigneeFragment_RatingArtifactNode_Fragment = {
  readonly __typename: "RatingArtifactNode";
  readonly id: number;
  readonly artifactType: ArtifactType;
};

type ArtifactAssigneeFragment_RecognitionArtifactNode_Fragment = {
  readonly __typename: "RecognitionArtifactNode";
  readonly id: number;
  readonly artifactType: ArtifactType;
};

export type ArtifactAssigneeFragmentFragment =
  | ArtifactAssigneeFragment_ActionItemArtifactNode_Fragment
  | ArtifactAssigneeFragment_AsanaTaskArtifactNode_Fragment
  | ArtifactAssigneeFragment_DecisionArtifactNode_Fragment
  | ArtifactAssigneeFragment_DocumentArtifactNode_Fragment
  | ArtifactAssigneeFragment_FeedbackArtifactNode_Fragment
  | ArtifactAssigneeFragment_GithubIssuesArtifactNode_Fragment
  | ArtifactAssigneeFragment_GoalArtifactNode_Fragment
  | ArtifactAssigneeFragment_KpiArtifactNode_Fragment
  | ArtifactAssigneeFragment_RatingArtifactNode_Fragment
  | ArtifactAssigneeFragment_RecognitionArtifactNode_Fragment;

type ArtifactMentionFragment_ActionItemArtifactNode_Fragment = {
  readonly __typename: "ActionItemArtifactNode";
  readonly isComplete?: boolean | null;
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
  readonly assignee?: {
    readonly __typename: "UserNode";
    readonly id: number;
  } | null;
};

type ArtifactMentionFragment_AsanaTaskArtifactNode_Fragment = {
  readonly __typename: "AsanaTaskArtifactNode";
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
};

type ArtifactMentionFragment_DecisionArtifactNode_Fragment = {
  readonly __typename: "DecisionArtifactNode";
  readonly decision: string;
  readonly decisionState?: DecisionState | null;
  readonly isDraft?: boolean | null;
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
};

type ArtifactMentionFragment_DocumentArtifactNode_Fragment = {
  readonly __typename: "DocumentArtifactNode";
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
};

type ArtifactMentionFragment_FeedbackArtifactNode_Fragment = {
  readonly __typename: "FeedbackArtifactNode";
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
};

type ArtifactMentionFragment_GithubIssuesArtifactNode_Fragment = {
  readonly __typename: "GithubIssuesArtifactNode";
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
};

type ArtifactMentionFragment_GoalArtifactNode_Fragment = {
  readonly __typename: "GoalArtifactNode";
  readonly progress: number;
  readonly goalVisibility: GoalVisibility;
  readonly scope: GoalScope;
  readonly state: GoalState;
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
};

type ArtifactMentionFragment_KpiArtifactNode_Fragment = {
  readonly __typename: "KPIArtifactNode";
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
};

type ArtifactMentionFragment_RatingArtifactNode_Fragment = {
  readonly __typename: "RatingArtifactNode";
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
};

type ArtifactMentionFragment_RecognitionArtifactNode_Fragment = {
  readonly __typename: "RecognitionArtifactNode";
  readonly id: number;
  readonly title?: string | null;
  readonly artifactType: ArtifactType;
};

export type ArtifactMentionFragmentFragment =
  | ArtifactMentionFragment_ActionItemArtifactNode_Fragment
  | ArtifactMentionFragment_AsanaTaskArtifactNode_Fragment
  | ArtifactMentionFragment_DecisionArtifactNode_Fragment
  | ArtifactMentionFragment_DocumentArtifactNode_Fragment
  | ArtifactMentionFragment_FeedbackArtifactNode_Fragment
  | ArtifactMentionFragment_GithubIssuesArtifactNode_Fragment
  | ArtifactMentionFragment_GoalArtifactNode_Fragment
  | ArtifactMentionFragment_KpiArtifactNode_Fragment
  | ArtifactMentionFragment_RatingArtifactNode_Fragment
  | ArtifactMentionFragment_RecognitionArtifactNode_Fragment;

export type CommonFeedbackArtifactFragmentFragment = {
  readonly __typename: "FeedbackArtifactNode";
  readonly id: number;
  readonly artifactType: ArtifactType;
  readonly title?: string | null;
  readonly recipientsCanView: boolean;
  readonly recipientsManagersCanView: boolean;
  readonly adminsCanView: boolean;
  readonly creatorCanView: boolean;
  readonly feedbackState: FeedbackState;
  readonly message?: any | null;
  readonly created: string;
  readonly creator?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
  readonly feedbackRecipients: {
    readonly __typename: "UserNodeConnection";
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly __typename: "UserNodeEdge";
      readonly node?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly avatar?: string | null;
        readonly name: string;
      } | null;
    } | null>;
  };
  readonly sender: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly avatar?: string | null;
    readonly name: string;
  };
};

export type CommonGoalArtifactFragmentFragment = {
  readonly __typename: "GoalArtifactNode";
  readonly id: number;
  readonly title?: string | null;
  readonly created: string;
  readonly artifactType: ArtifactType;
  readonly state: GoalState;
  readonly status: GoalStatus;
  readonly scope: GoalScope;
  readonly goalVisibility: GoalVisibility;
  readonly dueDate?: string | null;
  readonly startDate?: string | null;
  readonly progress: number;
  readonly progressType: GoalProgressType;
  readonly startValue: number;
  readonly currentValue: number;
  readonly targetValue: number;
  readonly isStale: boolean;
  readonly ownerCount?: number | null;
  readonly contributorCount?: number | null;
  readonly firstOwner?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly avatar?: string | null;
    readonly name: string;
  } | null;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canRead: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
};

export type CommonRecognitionArtifactFragmentFragment = {
  readonly __typename: "RecognitionArtifactNode";
  readonly id: number;
  readonly artifactType: ArtifactType;
  readonly title?: string | null;
  readonly created: string;
  readonly creator?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
  } | null;
  readonly recipients?: {
    readonly __typename: "UserNodeConnection";
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly __typename: "UserNodeEdge";
      readonly node?: {
        readonly __typename: "UserNode";
        readonly id: number;
        readonly name: string;
        readonly avatar?: string | null;
      } | null;
    } | null>;
  } | null;
  readonly recognitionCoreValue?: {
    readonly __typename: "RecognitionCoreValueNode";
    readonly id: number;
    readonly title: string;
    readonly description: string;
    readonly emoji?: string | null;
    readonly image?: string | null;
  } | null;
};

export type TopicDataFragmentFragment = {
  readonly __typename: "TopicNode";
  readonly id: number;
  readonly title: string;
  readonly description?: string | null;
  readonly discussionNotes?: any | null;
  readonly includesSharedNotes: boolean;
  readonly created: string;
  readonly state?: TopicState | null;
  readonly eventChannelName?: string | null;
  readonly isMandatory: boolean;
  readonly creator?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
  } | null;
  readonly assignee?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly avatar?: string | null;
    readonly name: string;
  } | null;
  readonly canDelete: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly canUpdate: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  };
  readonly previousTopic?: {
    readonly __typename: "TopicNode";
    readonly id: number;
    readonly discussionNotes?: any | null;
  } | null;
};

export type GetUserQueryQueryVariables = Exact<{
  userId: Scalars["Int"]["input"];
}>;

export type GetUserQueryQuery = {
  readonly __typename: "Query";
  readonly user?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly firstName: string;
    readonly email: string;
    readonly avatar?: string | null;
  } | null;
};

export type KeyResultFragmentFragment = {
  readonly __typename: "KeyResultNode";
  readonly id: number;
  readonly title: string;
  readonly startValue: number;
  readonly currentValue: number;
  readonly targetValue: number;
  readonly progressType: GoalProgressType;
  readonly kpi?: {
    readonly __typename: "KPINode";
    readonly id: number;
    readonly title: string;
    readonly currentMeasurement?: {
      readonly __typename: "KPIMeasurementNode";
      readonly id: number;
      readonly measurement: number;
    } | null;
  } | null;
  readonly canUpdate?: {
    readonly __typename: "PermissionNode";
    readonly permission: boolean;
  } | null;
  readonly assignee?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly avatar?: string | null;
    readonly email: string;
  } | null;
};

export type MeetingGroupParticipantsFragmentFragment = {
  readonly __typename: "MeetingGroupNode";
  readonly facilitator?: {
    readonly __typename: "UserNode";
    readonly id: number;
    readonly name: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly avatar?: string | null;
  } | null;
  readonly participants: {
    readonly __typename: "MeetingParticipantConnection";
    readonly edges: ReadonlyArray<{
      readonly __typename: "MeetingParticipantEdge";
      readonly node?: {
        readonly __typename: "MeetingParticipantNode";
        readonly id?: string | null;
        readonly user?: {
          readonly __typename: "UserNode";
          readonly id: number;
          readonly name: string;
          readonly firstName: string;
          readonly lastName: string;
          readonly avatar?: string | null;
        } | null;
      } | null;
    } | null>;
  };
};

export type MeetingTranscriptFragmentFragment = {
  readonly __typename: "MeetingNode";
  readonly hasTranscript?: boolean | null;
  readonly notesSummary?: string | null;
  readonly transcript?: {
    readonly __typename: "MeetingTranscriptNode";
    readonly id: number;
    readonly summary?: string | null;
    readonly mediumSummary?: string | null;
    readonly longSummary?: string | null;
    readonly highlights?: string | null;
    readonly orderedTranscriptData?: ReadonlyArray<{
      readonly __typename: "MeetingTranscriptMessageNode";
      readonly text: string;
      readonly speaker?: string | null;
      readonly created?: number | null;
      readonly messageId?: string | null;
    } | null> | null;
    readonly suggestedArtifacts?: {
      readonly __typename: "SuggestedArtifactNodeConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "SuggestedArtifactNodeEdge";
        readonly node?: {
          readonly __typename: "SuggestedArtifactNode";
          readonly id: number;
          readonly title: string;
          readonly description?: string | null;
          readonly hidden: boolean;
          readonly artifactType?: ArtifactType | null;
          readonly suggestedAssignee?: {
            readonly __typename: "UserNode";
            readonly id: number;
            readonly name: string;
            readonly avatar?: string | null;
          } | null;
        } | null;
      } | null>;
    } | null;
    readonly highlightList?: {
      readonly __typename: "TranscriptHighlightConnection";
      readonly edges: ReadonlyArray<{
        readonly __typename: "TranscriptHighlightEdge";
        readonly node?: {
          readonly __typename: "TranscriptHighlightNode";
          readonly id?: string | null;
          readonly highlight?: string | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type UserMentionFragmentFragment = {
  readonly __typename: "UserNode";
  readonly id: number;
  readonly name: string;
  readonly avatar?: string | null;
};
