import {
  AnnotationIcon,
  DocumentRemoveIcon,
  ExternalLinkIcon,
  LinkIcon,
} from "@heroicons/react/outline";
import copy from "copy-to-clipboard";
import { useState } from "react";
import { TbArrowBarDown, TbArrowBarUp, TbArrowsDiagonal } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import { TFLocationState } from "types/topicflow";

import CommentFormDialog from "@apps/comments/components/comment-form-dialog";
import AppLink, { useLink } from "@components/link/link";
import Tooltip from "@components/tooltip/tooltip";
import { classNames } from "@helpers/css";
import { getUrl, toWithBackground } from "@helpers/helpers";

/**
 * We need a useQuery for 2 reasons:
 * - To fetch initially the artifact using the extension attr id
 * - Then to keep the artifact data up to date when its cache is updated in another view
 *
 * Then we use useMutation to create or update the artifact. We define a no-cache policy and ignore api response
 * to prevent the cache to be updated after the api call succeed, instead we update the cache optimistically before the api call.
 * We prevent async update of the artifact which would provide a broken UX while user types.
 *
 */
const ArtifactToolbar = ({
  syncedArtifact,
  onToggleDescription,
  onRemoveFromNotes,
  descriptionIsExpanded,
  extension,
  onSavedComment,
}: {
  syncedArtifact: any;
  onToggleDescription: () => void;
  onRemoveFromNotes: () => void;
  descriptionIsExpanded: boolean;
  extension: any;
  onSavedComment: (comment: any) => void;
}) => {
  const [isOpeningCommentFormDialog, setIsOpeningCommentFormDialog] =
    useState(false);
  const link = useLink();
  const artifactUrl = getUrl({
    artifactType: syncedArtifact.artifactType,
    artifactId: syncedArtifact.id,
  });
  const location = useLocation<TFLocationState>();

  const handleCopy = () => {
    copy(`${window.location.origin}${artifactUrl}`);
  };

  const handleClickEditDescriptionFullscreen = () => {
    link.redirect({
      pathname: `${artifactUrl}/fullscreen`,
      state: { previousPathname: location.pathname },
    });
  };

  const handleSavedComment = (comment: any) => {
    setIsOpeningCommentFormDialog(false);
    onSavedComment(comment);
  };

  // RENDER
  const sidebarTo = toWithBackground({
    pathname: getUrl({
      artifactId: syncedArtifact.id,
      artifactType: syncedArtifact.artifactType,
      meetingGroupId: extension.options.meetingGroupId,
      meetingId: extension.options.meetingId,
    }),
    location,
  });

  const toolbarButtonClassName =
    "text-gray-500 px-2 py-0.5 rounded hover:bg-gray-100";
  return (
    <div
      className={classNames(
        "absolute right-5 -top-5 border rounded-lg bg-white z-tooltip shadow-sm p-0.5",
        "hidden group-hover:flex"
      )}
    >
      {isOpeningCommentFormDialog && (
        <CommentFormDialog
          artifactId={syncedArtifact.id}
          onClose={() => setIsOpeningCommentFormDialog(false)}
          onSavedComment={handleSavedComment}
        />
      )}
      {syncedArtifact.id && (
        <>
          <Tooltip text="Open in sidebar">
            <div className="flex">
              <AppLink
                to={sidebarTo}
                className={toolbarButtonClassName}
                aria-label="Artifact open sidebar button"
              >
                <ExternalLinkIcon className="w-4 h-4" />
              </AppLink>
            </div>
          </Tooltip>
          <Tooltip text="Copy link">
            <button onClick={handleCopy} className={toolbarButtonClassName}>
              <LinkIcon className="w-4 h-4" />
            </button>
          </Tooltip>
          <div className="w-px bg-gray-200 my-1 mx-1" />
          <Tooltip text="Edit full screen">
            <button
              onClick={handleClickEditDescriptionFullscreen}
              className={toolbarButtonClassName}
            >
              <TbArrowsDiagonal className="w-4 h-4" />
            </button>
          </Tooltip>
          <div className="w-px bg-gray-200 my-1 mx-1" />
          <Tooltip
            text={`${
              descriptionIsExpanded ? "Collapse" : "Expand"
            } description`}
          >
            <button
              onClick={onToggleDescription}
              className={toolbarButtonClassName}
            >
              {descriptionIsExpanded ? (
                <TbArrowBarUp className="h-4 w-4" />
              ) : (
                <TbArrowBarDown className="h-4 w-4" />
              )}
            </button>
          </Tooltip>
          <div className="w-px bg-gray-200 my-1 mx-1" />
          <Tooltip text="Add comment">
            <button
              onClick={() => setIsOpeningCommentFormDialog(true)}
              className={toolbarButtonClassName}
            >
              <AnnotationIcon className="w-4 h-4" />
            </button>
          </Tooltip>
          <div className="w-px bg-gray-200 my-1 mx-1" />
        </>
      )}
      <Tooltip text={`Remove from notes`}>
        <button
          onClick={() => onRemoveFromNotes()}
          className={toolbarButtonClassName}
        >
          <DocumentRemoveIcon className="h-4 w-4" />
        </button>
      </Tooltip>
    </div>
  );
};

export default ArtifactToolbar;
