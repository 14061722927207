import { ClockIcon } from "@heroicons/react/outline";
import moment from "moment";
import { MouseEvent } from "react";
import { CalendarEventFragmentFragment } from "types/graphql-schema";

import { classNames } from "@helpers/css";
import { formatTime, getUrl, isTailwindLgActive } from "@helpers/helpers";

const MonthListItem = ({
  event,
  onClickMeetingLink,
}: {
  event: CalendarEventFragmentFragment;
  onClickMeetingLink: (meetingId: number) => void;
}) => {
  const handleClickMeetingLink = (e: MouseEvent<HTMLAnchorElement>) => {
    if (isTailwindLgActive()) {
      e.preventDefault();
      e.stopPropagation();
      onClickMeetingLink(event.id);
    }
  };

  if (!event.meeting) {
    return null;
  }

  const isDisabled =
    event.meeting.status === "cancelled" || event.meeting.ignored;

  return (
    <li
      key={event.id}
      className={classNames(
        "group flex py-2 px-4 rounded-lg border",
        isDisabled
          ? "bg-gray-50 border-transparent"
          : event.meeting.draft
          ? "bg-amber-50 hover:bg-amber-100 border-dashed border-amber-300"
          : "bg-blue-50 hover:bg-blue-100 border-transparent"
      )}
    >
      <a
        href={getUrl({
          meetingId: event.id,
          meetingGroupId: event.meetingGroupId,
        })}
        onClick={handleClickMeetingLink}
      >
        <div
          className={classNames(
            "font-semibold",
            isDisabled
              ? "text-gray-400 line-through"
              : event.meeting.draft
              ? "text-amber-800"
              : "text-blue-600"
          )}
        >
          {event.title}
        </div>
        {event.startDatetime && (
          <time
            dateTime={event.startDatetime}
            className="mt-2 flex items-center text-gray-700"
          >
            <ClockIcon className="mr-2 h-5 w-5 text-gray-400" />
            {formatTime(moment(event.startDatetime).format("h:mma"))}
          </time>
        )}
      </a>
    </li>
  );
};

export default MonthListItem;
