import { classNames } from "@helpers/css";

import {
  emptyRoleColWidth,
  roleColWidth,
  roleLevelIterator,
  stickyColWidth,
} from "../helpers";
import StickyColumnShadow from "../sticky-column-shadow";

const TitleRow = ({
  levelCount,
  title,
  roleLevels,
}: {
  levelCount: number;
  title: string;
  roleLevels: number[];
}) => {
  return (
    <tr className="border-t border-b">
      <td
        className={classNames(
          stickyColWidth,
          "p-2 font-bold sticky left-0 bg-gray-100"
        )}
      >
        {title}
        <StickyColumnShadow />
      </td>
      {roleLevelIterator(levelCount).map((level) => {
        const roleExists = roleLevels.includes(level);
        return (
          <td
            className={classNames(
              roleExists && roleColWidth,
              !roleExists && emptyRoleColWidth,
              "border bg-gray-100"
            )}
            key={level}
          ></td>
        );
      })}
    </tr>
  );
};

export default TitleRow;
