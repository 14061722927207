import { useMutation } from "@apollo/client";
import { useCallback, useState } from "react";
import {
  SaveAssessmentMutation,
  SaveAssessmentMutationVariables,
} from "types/graphql-schema";

import { currentOrganizationVar } from "@cache/cache";
import { useLink } from "@components/link/link";
import { useNotificationError } from "@components/use-error/use-error";

import createOrUpdateAssessmentMutation from "../graphql/create-or-update-assessment-mutation";

const useCreateEmptyAssessment = (previousPathname: string) => {
  const link = useLink();
  const organization = currentOrganizationVar();
  const { onError } = useNotificationError();
  const [saveAssessment] = useMutation<
    SaveAssessmentMutation,
    SaveAssessmentMutationVariables
  >(createOrUpdateAssessmentMutation);
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateEmptyAssessment = useCallback(
    (
      complianceProgramId: number,
      assessmentTemplateId: number,
      targetId: number
    ) => {
      setIsLoading(true);
      saveAssessment({
        variables: {
          targetId,
          assessmentTemplateId,
          complianceProgramId,
          organizationId: organization.id,
        },
        onError,
        onCompleted: (response) => {
          setIsLoading(false);
          link.redirect({
            pathname: `/assessments/assessment/${response.createOrUpdateAssessment.assessment.id}`,
            state: {
              previousPathname,
            },
          });
        },
      });
    },
    [link, onError, organization, previousPathname, saveAssessment]
  );

  return { handleCreateEmptyAssessment, isLoading };
};

export default useCreateEmptyAssessment;
