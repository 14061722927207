import { ApolloError, NetworkStatus, useQuery } from "@apollo/client";
import { compact, concat, uniq } from "lodash";
import { useEffect, useMemo, useState } from "react";
import {
  AlignmentGoalFragmentFragment,
  GetAlignmentGoalsQueryQuery,
  GetAlignmentGoalsQueryQueryVariables,
} from "types/graphql-schema";

import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull, isGoalArtifactNode } from "@helpers/helpers";

import getAlignmentGoalsQuery from "./graphql/get-alignment-goals-query";

export enum AllDateRangeEnum {
  all = "all",
}

export type AlignedGoalType = {
  __typename: "GoalArtifactNode";
} & AlignmentGoalFragmentFragment;

export type AlignmentGoalsByIdType = {
  [key: number]: AlignedGoalType;
};

export type AlignmentChildGoalIdsByParentIdType = {
  [key: number]: number[];
};

export const goalAlignmentPageCount = 50;

export const getFirst3LevelGoalIds = (
  topLevelGoalIds: number[],
  childGoalIdsByParentId: AlignmentChildGoalIdsByParentIdType
): number[] => {
  return topLevelGoalIds.reduce((memo, goalId) => {
    return memo.concat(goalId, childGoalIdsByParentId[goalId]);
  }, [] as number[]);
};

const getCachedGoals = (
  data: undefined | GetAlignmentGoalsQueryQuery,
  networkStatus: NetworkStatus
) => {
  return networkStatus === NetworkStatus.loading && data?.artifacts
    ? assertEdgesNonNull(data.artifacts).filter(isGoalArtifactNode)
    : [];
};

const getPathParentIds = (
  goalId: number,
  goalsById: AlignmentGoalsByIdType
): number[] => {
  const goal = goalsById[goalId];
  if (!goal || !goal.parentGoalId) return [];
  return [goal.parentGoalId].concat(
    getPathParentIds(goal.parentGoalId, goalsById)
  );
};

export const getGoalDescendantIds = (
  goalId: number,
  allChildGoalIdsByParentId: AlignmentChildGoalIdsByParentIdType
): number[] => {
  const childGoalIds = allChildGoalIdsByParentId[goalId];
  if (!childGoalIds) return [];
  return childGoalIds.concat(
    ...childGoalIds.map((childGoalId) =>
      getGoalDescendantIds(childGoalId, allChildGoalIdsByParentId)
    )
  );
};

export const isGoalInSearchResultPath = (
  goalId: number,
  searchResultGoalIds?: number[],
  goalIdsInSearchResultPaths?: number[]
) => {
  return (
    searchResultGoalIds === undefined ||
    goalIdsInSearchResultPaths === undefined ||
    searchResultGoalIds?.includes(goalId) ||
    goalIdsInSearchResultPaths?.includes(goalId)
  );
};

export const useFetchAlignedGoals = ({
  variables,
}: {
  variables: GetAlignmentGoalsQueryQueryVariables;
}): {
  goals: AlignedGoalType[];
  loading: boolean;
  goalsById: AlignmentGoalsByIdType;
  searchResultGoalIds?: number[];
  searchResultAncestorGoalIds?: number[];
  childGoalIdsByParentId: AlignmentChildGoalIdsByParentIdType;
} => {
  const handleError = (error: ApolloError) => {
    setLoading(false);
    onNotificationErrorHandler()(error);
  };

  const [loading, setLoading] = useState(true);
  const { data, fetchMore, networkStatus } = useQuery<
    GetAlignmentGoalsQueryQuery,
    GetAlignmentGoalsQueryQueryVariables
  >(getAlignmentGoalsQuery, {
    notifyOnNetworkStatusChange: true,
    variables,
    onError: handleError,
  });

  const [cachedGoals, setCachedGoals] = useState<AlignedGoalType[]>(
    getCachedGoals(data, networkStatus)
  );

  // Load more goals or switch between cached and fresh goals
  useEffect(() => {
    if (networkStatus !== NetworkStatus.ready) return;
    if (!data?.artifacts?.pageInfo) return;

    // fetch more goals if there are more
    if (data.artifacts.pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          merge: true,
          after: data?.artifacts?.pageInfo.endCursor,
        },
      });
    }

    if (data.artifacts.pageInfo.hasNextPage === false) {
      setCachedGoals([]);
      setLoading(false);
    }
  }, [data, networkStatus, fetchMore]);

  // Reset cached goals when variables change
  useEffect(() => {
    setCachedGoals([]);
    setLoading(true);
  }, [variables]);

  useEffect(() => {
    // if we have cached data, set the goals to the cached data
    if (networkStatus === NetworkStatus.loading && data) {
      setCachedGoals(getCachedGoals(data, networkStatus));
    }
  }, [data, networkStatus]);

  const searchResultGoalIds = useMemo(() => {
    return !loading && data?.searchResults?.edges
      ? assertEdgesNonNull(data.searchResults).map(({ id }) => id)
      : undefined;
  }, [loading, data]);

  // If we have cached goals, use them, otherwise use the fresh goals
  const allGoals = useMemo(() => {
    return !loading && data?.artifacts
      ? assertEdgesNonNull(data.artifacts).filter(isGoalArtifactNode)
      : loading && variables.hasSearch
      ? []
      : cachedGoals;
  }, [cachedGoals, loading, data, variables]);

  const goalsById: AlignmentGoalsByIdType = useMemo(
    () =>
      allGoals.reduce((acc, goal) => {
        acc[goal.id] = goal;
        return acc;
      }, {} as AlignmentGoalsByIdType),
    [allGoals]
  );

  const allChildGoalIdsByParentId: AlignmentChildGoalIdsByParentIdType =
    useMemo(
      () =>
        allGoals.reduce((acc, goal) => {
          const parentId = goal.parentGoalId;
          if (!parentId) return acc;
          if (!acc[parentId]) acc[parentId] = [];
          acc[parentId].push(goal.id);
          return acc;
        }, {} as AlignmentChildGoalIdsByParentIdType),
      [allGoals]
    );

  const searchResultAncestorGoalIds = useMemo(() => {
    return searchResultGoalIds === undefined
      ? undefined
      : uniq(
          searchResultGoalIds.reduce((memo, goalId) => {
            const pathParentIds = getPathParentIds(goalId, goalsById);
            return memo.concat(goalId, pathParentIds);
          }, [] as number[])
        );
  }, [searchResultGoalIds, goalsById]);

  const searchResultDescendantGoalIds = useMemo(() => {
    return searchResultGoalIds === undefined
      ? undefined
      : uniq(
          searchResultGoalIds.reduce((memo, goalId) => {
            const pathParentIds = getGoalDescendantIds(
              goalId,
              allChildGoalIdsByParentId
            );
            return memo.concat(goalId, pathParentIds);
          }, [] as number[])
        );
  }, [searchResultGoalIds, allChildGoalIdsByParentId]);

  const goals = useMemo(() => {
    if (searchResultGoalIds === undefined) return allGoals;
    const concatenatedSearchResultIds = concat(
      searchResultAncestorGoalIds,
      searchResultDescendantGoalIds,
      searchResultGoalIds
    );
    const goalsInSearchResult = uniq(compact(concatenatedSearchResultIds));
    return compact(goalsInSearchResult.map((goalId) => goalsById[goalId]));
  }, [
    allGoals,
    goalsById,
    searchResultAncestorGoalIds,
    searchResultDescendantGoalIds,
    searchResultGoalIds,
  ]);

  const childGoalIdsByParentId: AlignmentChildGoalIdsByParentIdType = useMemo(
    () =>
      goals.reduce((acc, goal) => {
        if (!goal || !goal.parentGoalId) return acc;
        if (!acc[goal.parentGoalId]) acc[goal.parentGoalId] = [];
        acc[goal.parentGoalId].push(goal.id);
        return acc;
      }, {} as AlignmentChildGoalIdsByParentIdType),
    [goals]
  );

  return {
    goals,
    goalsById,
    childGoalIdsByParentId,
    searchResultGoalIds,
    searchResultAncestorGoalIds,
    loading,
  };
};

export default useFetchAlignedGoals;
