import { useMutation } from "@apollo/client";
import { MenuDivider, MenuItem } from "@szhsin/react-menu";
import { TbPlaylistX } from "react-icons/tb";
import {
  AlignGoalMutationMutation,
  AlignGoalMutationMutationVariables,
  ArtifactComponentFragmentFragment,
  GoalArtifactSidebarFragmentFragment,
} from "types/graphql-schema";

import Artifact from "@apps/artifact/artifact";
import ArtifactDropdownMenu from "@apps/artifact/artifact-dropdown-menu";
import { refreshAlignmentOfGoalIds } from "@apps/goal-alignment/helpers";
import { GoalProgressView } from "@apps/goal-progress/goal-progress";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { graphqlNone } from "@helpers/constants";
import { classNames } from "@helpers/css";
import useConfirm from "@helpers/hooks/use-confirm";

import alignGoalMutation from "./graphql/align-goal-mutation";
import getArtifactSidebarQuery from "./graphql/get-artifact-sidebar-query";

const ArtifactSidebarAlignedGoalItem = ({
  artifact,
  alignedGoal,
  alignmentType,
}: {
  artifact: GoalArtifactSidebarFragmentFragment;
  alignedGoal:
    | GoalArtifactSidebarFragmentFragment
    | (ArtifactComponentFragmentFragment & {
        __typename: "GoalArtifactNode";
      });
  alignmentType?: "parent" | "child" | "ancestor";
}) => {
  const { confirm, ConfirmationDialog } = useConfirm(
    "Are you sure you want to remove this alignment?",
    ""
  );
  const [updateGoal] = useMutation<
    AlignGoalMutationMutation,
    AlignGoalMutationMutationVariables
  >(alignGoalMutation);

  const handleClearAlignment = async () => {
    if (alignmentType !== "parent" && alignmentType !== "child") return;
    const confirmed = await confirm();
    const goalIdsToRefresh = [
      artifact.parentGoalId,
      alignedGoal.parentGoalId,
      artifact.id,
      alignedGoal.id,
    ];
    if (confirmed) {
      updateGoal({
        variables: {
          goalId: alignmentType === "parent" ? artifact.id : alignedGoal.id,
          parentGoalId: graphqlNone,
        },
        refetchQueries: [getArtifactSidebarQuery],
        onError: onNotificationErrorHandler(),
        onCompleted: () => {
          refreshAlignmentOfGoalIds(goalIdsToRefresh);
        },
      });
    }
  };

  return (
    <>
      <ConfirmationDialog />
      <div
        className={classNames("flex items-start gap-4 flex-1 relative")}
        key={alignedGoal.id}
      >
        <div className="flex-1 flex items-center gap-2.5 hover:bg-gray-50 rounded-md pl-2 pr-2 -mx-2 py-1.5 ">
          <div className="flex-1">
            <Artifact
              artifact={alignedGoal}
              hideActionDropdown
              goalProgressView={GoalProgressView.pillAndProgressBar}
            />
          </div>
          <ArtifactDropdownMenu artifact={alignedGoal} portal>
            {(alignmentType === "child" || alignmentType === "parent") &&
              artifact.canUpdate.permission &&
              alignmentType && (
                <>
                  <MenuItem onClick={handleClearAlignment}>
                    <TbPlaylistX className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
                    Remove alignment
                  </MenuItem>
                  <MenuDivider />
                </>
              )}
          </ArtifactDropdownMenu>
        </div>
      </div>
    </>
  );
};

export default ArtifactSidebarAlignedGoalItem;
