import { useLazyQuery } from "@apollo/client";
import { AnimatePresence } from "framer-motion";
import { compact, take } from "lodash";
import moment from "moment";
import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  GetMeetingOverviewMeetingsQueryQuery,
  GetMeetingOverviewMeetingsQueryQueryVariables,
  MeetingDialogFragmentFragment,
} from "types/graphql-schema";

import useUiPreferenceCache from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import { currentUserVar } from "@cache/cache";
import Input from "@components/input/input";
import Loading from "@components/loading/loading";
import MotionDiv from "@components/motion/motion-div";
import Select from "@components/select/select";
import useDebounce from "@components/use-debounce/use-debounce";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import { UserComboboxOption } from "@components/user-combobox/user-combobox-list";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

import getMeetingOverviewMeetingsQuery from "../graphql/get-meeting-overview-meetings-query";
import OverviewMeetingList from "./overview-meeting-list";
import UnscheduledMeeting from "./unscheduled-meeting";

export enum MeetingType {
  ALL,
  ONE_ON_ONE,
  GROUP,
}

const MeetingOverview = ({
  selectedUser,
  embeddedInSidebar = false,
  filterClassName,
  meetingListClassName,
  onClickMeetingLink,
}: {
  selectedUser: UserComboboxOption;
  embeddedInSidebar?: boolean;
  filterClassName?: string;
  meetingListClassName?: string;
  onClickMeetingLink?: (meetingId: number, meetingGroupId: number) => void;
}) => {
  const todayRef = useRef<HTMLDivElement>(null);
  const currentUser = currentUserVar();
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [initComplete, setInitComplete] = useState(false);
  const [loadMore, setLoadMore] = useState<"past" | "future" | "search" | null>(
    null
  );
  const [meetings, setMeetings] = useState<MeetingDialogFragmentFragment[]>([]);
  const [unscheduledMeetings, setUnscheduledMeetings] = useState<
    MeetingDialogFragmentFragment[]
  >([]);
  const [participantFilter, setParticipantFilter] = useState<number | null>(
    null
  );
  const [meetingTypeFilter, setMeetingTypeFilter] = useState(MeetingType.ALL);
  const [isShowingWeekends, setIsShowingWeekends] = useState(
    embeddedInSidebar ? true : uiPreferenceCache.meetingOverviewShowWeekends
  );
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const hasSearchQuery = debouncedSearchQuery.trim() !== "";
  const [meetingDisplayedRange, setMeetingDisplayedRange] = useState<{
    start: string;
    end: string;
  }>({
    start: moment().startOf("isoWeek").format(),
    end: moment().startOf("isoWeek").add(1, "week").format(),
  });

  const [fetchMeetings, { data, loading }] = useLazyQuery<
    GetMeetingOverviewMeetingsQueryQuery,
    GetMeetingOverviewMeetingsQueryQueryVariables
  >(getMeetingOverviewMeetingsQuery, {
    onError: onNotificationErrorHandler(),
  });
  const hasMoreSearchResults = data?.calendar?.pageInfo.hasNextPage;
  const afterSearchResults = data?.calendar?.pageInfo.endCursor;

  const handleReceiveMeetingsResponse = useCallback(
    (response: GetMeetingOverviewMeetingsQueryQuery) => {
      const unscheduled = compact(
        assertEdgesNonNull(response.unscheduledMeetings).map(
          (calendarItem) => calendarItem.meeting
        )
      );
      setUnscheduledMeetings(unscheduled);

      const meetings = compact(
        assertEdgesNonNull(response.calendar).map(
          (calendarItem) => calendarItem.meeting
        )
      );
      setMeetings((prev) => {
        const existingIds = prev.map(({ id }) => id);
        const updatedMeetings = prev.map((prevMeeting) => {
          if (meetings.map(({ id }) => id).includes(prevMeeting.id)) {
            return assertNonNull(
              meetings.find(({ id }) => id === prevMeeting.id)
            );
          }
          return prevMeeting;
        });
        const newMeetings = meetings.filter(
          ({ id }) => !existingIds.includes(id)
        );
        return [...updatedMeetings, ...newMeetings];
      });
      setInitComplete(true);
      setLoadMore(null);
    },
    []
  );

  useEffect(() => {
    if (initComplete && !embeddedInSidebar) {
      const scrollInterval = setTimeout(() => {
        if (todayRef.current && scrollContainerRef.current) {
          const scrollTop = todayRef.current.offsetTop;
          scrollContainerRef.current.scrollTo({
            top: scrollTop - 10,
            behavior: "smooth",
          });
        }
      }, 700);
      return () => clearTimeout(scrollInterval);
    }
    // scroll to today only on page load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initComplete, embeddedInSidebar]);

  // prevents re-fetching when selected user remains the same
  const contextKey = useMemo(
    () => `${selectedUser.type}-${selectedUser.id}`,
    [selectedUser.id, selectedUser.type]
  );

  useEffect(() => {
    if (searchQuery !== debouncedSearchQuery) return; // prevents re-fetching when search query is being changed
    setInitComplete(false);
    setLoadMore(null);
    setMeetings([]);
    setUnscheduledMeetings([]);
    setMeetingDisplayedRange({
      start: moment().startOf("isoWeek").format(),
      end: moment().startOf("isoWeek").add(1, "week").format(),
    });
    // reset when user changes or search query changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextKey, debouncedSearchQuery]);

  useEffect(() => {
    if (loadMore === null && initComplete) {
      return;
    }
    const newStart = !initComplete
      ? meetingDisplayedRange.start
      : loadMore === "past"
      ? moment(meetingDisplayedRange.start).subtract(1, "week").format()
      : meetingDisplayedRange.end;

    const hasSearchQuery = debouncedSearchQuery.trim() !== "";
    fetchMeetings({
      variables: {
        forUserId: selectedUser.id,
        dateRangeStart: hasSearchQuery ? null : newStart,
        dateRangeEnd: hasSearchQuery
          ? null
          : moment(newStart).add(1, "week").format(),
        orderBy: hasSearchQuery ? "-start_datetime" : "start_datetime",
        first: !hasSearchQuery ? null : loadMore === "search" ? 10 : 5,
        after:
          hasSearchQuery && loadMore === "search" && hasMoreSearchResults
            ? afterSearchResults
            : undefined,
        searchQuery: debouncedSearchQuery.trim(),
      },
      onCompleted: (response) => {
        handleReceiveMeetingsResponse(response);
        if (loadMore === "past") {
          setMeetingDisplayedRange((prev) => ({
            ...prev,
            start: newStart,
          }));
        } else if (loadMore === "future") {
          setMeetingDisplayedRange((prev) => ({
            ...prev,
            end: moment(newStart).add(1, "week").format(),
          }));
        }
      },
    });
    // only want to fetch more when index changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMore, initComplete]);

  const {
    options: assessmentUserFilterOptions,
    query,
    setQuery,
  } = useUserComboboxQuery({});
  const userComboboxValue =
    assessmentUserFilterOptions.find(({ id }) => id === participantFilter) ||
    null;

  const handleChangeParticipantFilter = useCallback(
    (option?: UserComboboxOption) => {
      setParticipantFilter(option ? option.id : null);
    },
    []
  );

  const handleToggleShowWeekends: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        const showWeekends = e.target.checked;
        saveUiPreference({
          meetingOverviewShowWeekends: showWeekends,
        });
        setIsShowingWeekends(showWeekends);
      },
      [saveUiPreference]
    );

  const handleMeetingUpdated = useCallback(
    (data: { meeting: MeetingDialogFragmentFragment }) => {
      if (data.meeting.startDatetime) {
        // if meeting is scheduled, remove from unscheduledMeetings and update meetings list
        setUnscheduledMeetings((prev) =>
          prev.filter((meeting) => meeting.id !== data.meeting.id)
        );
        setMeetings((prev) => {
          if (prev.find((meeting) => meeting.id === data.meeting.id)) {
            return prev.map((meeting) => {
              if (meeting.id === data.meeting.id) {
                return data.meeting;
              }
              return meeting;
            });
          }
          return [...prev, data.meeting];
        });
      } else {
        // else remove from meetings list and update unscheduledMeetings
        setMeetings((prev) =>
          prev.filter((meeting) => meeting.id !== data.meeting.id)
        );
        setUnscheduledMeetings((prev) => {
          if (prev.find((meeting) => meeting.id === data.meeting.id)) {
            return prev.map((meeting) => {
              if (meeting.id === data.meeting.id) {
                return data.meeting;
              }
              return meeting;
            });
          }
          return [...prev, data.meeting];
        });
      }
    },
    []
  );

  const handleMeetingDeleted = useCallback((meetingId: number) => {
    setMeetings((prev) => {
      return prev.filter((meeting) => meeting.id !== meetingId);
    });
    setUnscheduledMeetings((prev) => {
      return prev.filter((meeting) => meeting.id !== meetingId);
    });
  }, []);

  const showUnscheduledMeetings =
    !embeddedInSidebar &&
    selectedUser.id === currentUser.id &&
    unscheduledMeetings.length > 0;

  const handleChangeSearchQuery: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="@container/overview-meetings h-full">
      <div
        className={classNames(
          "sticky z-40 bg-white",
          showUnscheduledMeetings && "h-40",
          !showUnscheduledMeetings && "h-18"
        )}
      >
        {showUnscheduledMeetings && (
          <div className="p-4 border-b">
            <div className="text-sm font-medium mb-2">{`Unscheduled meetings (${unscheduledMeetings.length})`}</div>
            <div className="grid grid-cols-3 gap-2 @2xl/overview-meetings:gap-4">
              <AnimatePresence>
                {take(unscheduledMeetings, 3).map((meeting) => {
                  return (
                    <MotionDiv
                      key={meeting.id}
                      className=""
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <UnscheduledMeeting
                        meeting={meeting}
                        onMeetingUpdated={handleMeetingUpdated}
                        onMeetingDeleted={handleMeetingDeleted}
                      />
                    </MotionDiv>
                  );
                })}
              </AnimatePresence>
            </div>
          </div>
        )}
        <div
          className={classNames("flex items-center gap-4 p-4", filterClassName)}
        >
          <div
            className={classNames(
              "w-48 @2xl/overview-meetings:w-64",
              embeddedInSidebar && "flex-1"
            )}
          >
            <UserCombobox
              onChangeValue={handleChangeParticipantFilter}
              options={assessmentUserFilterOptions}
              value={userComboboxValue}
              placeholder="All people"
              clearable={participantFilter !== null}
              query={query}
              onChangeQuery={setQuery}
              onClearValue={handleChangeParticipantFilter}
            />
          </div>
          <Select
            value={meetingTypeFilter}
            options={[
              {
                value: MeetingType.ALL,
                label: "All meetings",
              },
              {
                value: MeetingType.ONE_ON_ONE,
                label: "1-on-1s",
              },
              {
                value: MeetingType.GROUP,
                label: "Group meetings",
              },
            ]}
            onChange={(opt) => setMeetingTypeFilter(opt.value)}
            aria-label="Meeting type filter"
            className={classNames(embeddedInSidebar && " text-sm w-48")}
          />
          {!embeddedInSidebar && (
            <label className="flex items-center gap-1 text-sm text-gray-600 cursor-pointer">
              <input
                type="checkbox"
                checked={isShowingWeekends}
                onChange={handleToggleShowWeekends}
              />
              <span className="hidden md:inline">Show </span>weekends
            </label>
          )}
        </div>
      </div>
      {embeddedInSidebar && (
        <div
          className={classNames(
            "px-6 pb-4 border-b",
            "pt-px" // so outline of input do not appear cut
          )}
        >
          <Input
            placeholder="Search meetings"
            value={searchQuery}
            onChange={handleChangeSearchQuery}
          />
        </div>
      )}

      <div
        className={classNames(
          !embeddedInSidebar &&
            "absolute inset-0 overflow-y-scroll px-4 @2xl/overview-meetings:px-24 pt-4 pb-12",
          !embeddedInSidebar && showUnscheduledMeetings && "top-[172px]",
          !embeddedInSidebar && !showUnscheduledMeetings && "top-18",
          embeddedInSidebar && "py-4 px-6",
          meetingListClassName
        )}
        ref={scrollContainerRef}
      >
        {/* Main loader on first page hit */}
        {loading && !initComplete && loadMore !== "search" && (
          <Loading className="p-6" size={6}>
            Loading meetings...
          </Loading>
        )}

        {initComplete && (
          <OverviewMeetingList
            ref={todayRef}
            hasMoreSearchResults={hasMoreSearchResults}
            showSearchResults={hasSearchQuery}
            meetings={meetings}
            loading={loading}
            participantFilter={participantFilter}
            meetingTypeFilter={meetingTypeFilter}
            meetingDisplayedRange={meetingDisplayedRange}
            onMeetingUpdated={handleMeetingUpdated}
            hideEditingButtons={embeddedInSidebar}
            onClickMeetingLink={onClickMeetingLink}
            loadMore={loadMore}
            onChangeLoadMore={setLoadMore}
            isShowingWeekends={isShowingWeekends}
          />
        )}

        {/* Main loader on first page hit */}
        {loading && loadMore === "search" && (
          <Loading className="p-6" size={6}>
            Loading meetings...
          </Loading>
        )}
      </div>
    </div>
  );
};

export default MeetingOverview;
