import { gql } from "@apollo/client";

import MeetingViewMeetingNodeNewPageFragment from "@apps/meeting-new/graphql/meeting-view-meeting-node-fragment";

export default gql`
  ${MeetingViewMeetingNodeNewPageFragment}
  query getMeetingForExtensionQuery($googleMeetUrl: String, $meetingId: Int) {
    meeting(googleMeetUrl: $googleMeetUrl, meetingId: $meetingId) {
      ...MeetingViewMeetingNodeNewPageFragment
    }
  }
`;
