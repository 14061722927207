import { useLazyQuery, useQuery } from "@apollo/client";
import { compact, flatMap } from "lodash";
import pluralize from "pluralize";
import { useCallback, useEffect, useMemo, useState } from "react";
import { MdFilterAlt } from "react-icons/md";
import { useHistory, useLocation } from "react-router-dom";
import {
  AssessmentQuestionResponses,
  AssessmentType,
  ComplianceProgramForReportAssessmentGroupSectionFragment,
  ComplianceProgramState,
  GetComplianceProgramForReportQuery,
  GetComplianceProgramForReportQueryVariables,
  GetComplianceProgramsQuery,
  GetComplianceProgramsQueryVariables,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import RecipientForm from "@apps/artifact/components/recipient-form";
import getComplianceProgramsQuery from "@apps/programs/graphql/get-compliance-programs-query";
import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import ComboboxGeneric, {
  ComboboxGenericOption,
} from "@components/combobox/generic-combobox";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull, getUrlParam } from "@helpers/helpers";
import { capitalize } from "@helpers/string";

import getComplianceProgramForReportQuery from "../graphql/get-compliance-program-for-report-query";
import ManagerEffectivenessChart from "./assessment-report/manager-effectiveness-chart";
import ManagerEffectivenessTable from "./assessment-report/manager-effectiveness-table";
import PerformanceAssessmentChart from "./assessment-report/performance-assessment-chart";
import PerformanceAssessmentTable from "./assessment-report/performance-assessment-table";
import TeamPicker, { TeamPickerTeam } from "./team-picker";

export type PerformanceAssessmentAnswerFilter = {
  questionId: number;
  integerAnswer: number;
};

type Props = {
  assessmentType: AssessmentType;
};

const AssessmentReport: React.FC<Props> = ({ assessmentType }) => {
  const location = useLocation();
  const history = useHistory();
  const label = useLabel();
  const programIdFromUrl = getUrlParam(location, "programId");
  const [selectedProgramId, setSelectedProgramId] = useState<number | null>(
    programIdFromUrl ? parseInt(programIdFromUrl) : null
  );
  const [availablePrograms, setAvailablePrograms] = useState<
    ComboboxGenericOption<number>[]
  >([]);
  const [showFilters, setShowFilters] = useState(false);
  const [userFilterList, setUserFilterList] = useState<BasicUser[]>([]);
  const [reportsToFilterList, setReportsToFilterList] = useState<BasicUser[]>(
    []
  );
  const [teamFilterList, setTeamFilterList] = useState<TeamPickerTeam[]>([]);
  const [answerFilterList, setAnswerFilterList] = useState<
    PerformanceAssessmentAnswerFilter[]
  >([]);

  const resetFilters = useCallback(() => {
    setShowFilters(false);
    setUserFilterList([]);
    setReportsToFilterList([]);
    setAnswerFilterList([]);
    setTeamFilterList([]);
  }, []);

  const handleChangeProgam = useCallback(
    (programId: number, overrideUrl = false) => {
      const params = new URLSearchParams(location.search);
      params.set("programId", `${programId}`);
      const url = `${location.pathname}?${params}`;
      if (overrideUrl) {
        history.replace(url);
      } else {
        history.push(url);
      }
      resetFilters();
    },
    [history, location, resetFilters]
  );

  const handleChangeAnswerFilterList = useCallback(
    (filters: PerformanceAssessmentAnswerFilter[]) =>
      setAnswerFilterList(filters),
    []
  );

  const [loadProgramData, { data: programData, loading: isLoadingProgram }] =
    useLazyQuery<
      GetComplianceProgramForReportQuery,
      GetComplianceProgramForReportQueryVariables
    >(getComplianceProgramForReportQuery, {
      onError: onNotificationErrorHandler(),
    });

  const organization = currentOrganizationVar();
  const { loading: isLoadingProgamList } = useQuery<
    GetComplianceProgramsQuery,
    GetComplianceProgramsQueryVariables
  >(getComplianceProgramsQuery, {
    variables: {
      organizationId: organization.id,
      state: ComplianceProgramState.Published,
      assessmentType,
    },
    onCompleted: (response) => {
      const programs = response.compliancePrograms
        ? assertEdgesNonNull(response.compliancePrograms)
        : [];
      setAvailablePrograms(
        programs.map(({ id, title }) => ({ value: id, label: title }))
      );

      if (programs.length > 0) {
        const programIds = programs.map((program) => program.id);
        const validProgramInUrl =
          selectedProgramId !== null && programIds.includes(selectedProgramId);
        const programId = validProgramInUrl
          ? selectedProgramId
          : programs[0].id;
        handleChangeProgam(programId, true);
      } else {
        setSelectedProgramId(null);
      }
    },
    onError: onNotificationErrorHandler(),
  });

  useEffect(() => {
    const programIdFromUrl = getUrlParam(location, "programId");
    if (programIdFromUrl) {
      const programId = parseInt(programIdFromUrl);
      setSelectedProgramId(programId);
      loadProgramData({
        variables: { complianceProgramId: programId },
      });
    }
    // only load program data when location changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const complianceProgram = useMemo(
    () => (selectedProgramId ? programData?.complianceProgram : null),
    [programData, selectedProgramId]
  );

  const assessmentTemplate = useMemo(() => {
    if (!complianceProgram) {
      return null;
    }
    return assessmentType === AssessmentType.Performance
      ? complianceProgram.performanceAssessmentTemplate
      : complianceProgram.managerAssessmentTemplate;
  }, [assessmentType, complianceProgram]);

  const questions = useMemo(() => {
    if (!assessmentTemplate) {
      return [];
    }
    const questionSets = assertEdgesNonNull(assessmentTemplate.questionSets);
    const sections = questionSets.reduce((acc, set) => {
      return [...acc, ...assertEdgesNonNull(set.sections)];
    }, [] as ComplianceProgramForReportAssessmentGroupSectionFragment[]);
    const allQuestions = flatMap(sections, (section) =>
      assertEdgesNonNull(section.questions)
    );
    return compact(
      allQuestions.map((question) =>
        question.question.__typename === "RangeAssessmentQuestionNode" &&
        [
          AssessmentQuestionResponses.Both,
          AssessmentQuestionResponses.ExcludeSelfAssessment,
        ].includes(question.question.responses)
          ? question.question
          : null
      )
    );
  }, [assessmentTemplate]);

  const filterCount = useMemo(() => {
    let count = 0;
    if (userFilterList.length > 0) {
      count++;
    }
    if (reportsToFilterList.length > 0) {
      count++;
    }
    if (teamFilterList.length > 0) {
      count++;
    }
    return count;
  }, [reportsToFilterList, userFilterList, teamFilterList]);

  if (isLoadingProgamList) {
    return (
      <div className="flex-1 flex justify-center p-10">
        <Loading>Loading...</Loading>
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="p-6 flex items-center">
        <div className="font-bold mr-2">Program:</div>
        <div className="flex-1 max-w-sm">
          <ComboboxGeneric
            options={availablePrograms}
            onChangeValue={(opt) => handleChangeProgam(opt.value)}
            value={
              availablePrograms.find(
                ({ value }) => value === selectedProgramId
              ) ?? null
            }
          />
        </div>
        <Button
          className="flex items-center ml-4"
          theme={buttonTheme.text}
          onClick={() => setShowFilters(!showFilters)}
        >
          <MdFilterAlt className="h-5 w-5 mr-1" />
          {showFilters ? "Hide filters" : "Show filters"}
          {filterCount > 0 && (
            <span className="ml-1 text-sm italic text-gray-500">
              ({filterCount} {pluralize("filter", filterCount)} active)
            </span>
          )}
        </Button>
      </div>
      {!isLoadingProgram && showFilters && (
        <div className="px-8 pb-6 flex flex-col gap-4">
          <div className="flex gap-8">
            <div className="mt-1">Users:</div>
            <RecipientForm
              canChange
              showCurrentUser
              recipients={userFilterList}
              onAddRecipient={(user) =>
                setUserFilterList([...userFilterList, user])
              }
              onRemoveRecipient={(user) =>
                setUserFilterList(
                  userFilterList.filter((u) => u.id !== user.id)
                )
              }
            />
          </div>
          <div className="flex gap-8">
            <div className="mt-1">Reports to:</div>
            <RecipientForm
              canChange
              showCurrentUser
              recipients={reportsToFilterList}
              onAddRecipient={(user) =>
                setReportsToFilterList([...reportsToFilterList, user])
              }
              onRemoveRecipient={(user) =>
                setReportsToFilterList(
                  reportsToFilterList.filter((u) => u.id !== user.id)
                )
              }
            />
          </div>
          <div className="flex gap-8">
            <div className="mt-1">
              {pluralize(capitalize(label("team")), 2)}:
            </div>
            <TeamPicker
              canChange
              teams={teamFilterList}
              onAddTeam={(team) => setTeamFilterList([...teamFilterList, team])}
              onRemoveTeam={(team) =>
                setTeamFilterList(
                  teamFilterList.filter((t) => t.id !== team.id)
                )
              }
            />
          </div>
        </div>
      )}
      <div className="px-6 pb-6">
        {isLoadingProgram && (
          <div className="flex-1 flex justify-center">
            <Loading>Loading program data...</Loading>
          </div>
        )}

        {!isLoadingProgram &&
          complianceProgram &&
          (assessmentType === AssessmentType.Manager ? (
            <ManagerEffectivenessChart
              questions={questions}
              complianceProgram={complianceProgram}
              userFilterList={userFilterList}
              reportsToFilterList={reportsToFilterList}
              teamFilterList={teamFilterList}
            />
          ) : (
            <PerformanceAssessmentChart
              questions={questions}
              complianceProgram={complianceProgram}
              userFilterList={userFilterList}
              reportsToFilterList={reportsToFilterList}
              answerFilterList={answerFilterList}
              teamFilterList={teamFilterList}
              onChangeAnswerFilterList={handleChangeAnswerFilterList}
            />
          ))}

        {!isLoadingProgram &&
          complianceProgram &&
          assessmentTemplate &&
          (assessmentType === AssessmentType.Manager ? (
            <ManagerEffectivenessTable
              questions={questions}
              complianceProgram={complianceProgram}
              userFilterList={userFilterList}
              reportsToFilterList={reportsToFilterList}
              teamFilterList={teamFilterList}
            />
          ) : (
            <PerformanceAssessmentTable
              questions={questions}
              complianceProgram={complianceProgram}
              userFilterList={userFilterList}
              reportsToFilterList={reportsToFilterList}
              answerFilterList={answerFilterList}
              teamFilterList={teamFilterList}
            />
          ))}
      </div>
    </div>
  );
};

export default AssessmentReport;
