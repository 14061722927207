import { compact, range } from "lodash";
import { ChangeEventHandler, useMemo } from "react";
import { MdRadioButtonUnchecked } from "react-icons/md";
import {
  AssessmentQuestionResponseVisibility,
  AssessmentQuestionResponses,
  AssessmentQuestionType,
} from "types/graphql-schema";

import { getRatingScaleClassName } from "@apps/ratings/helpers";
import useLabel from "@apps/use-label/use-label";
import Input from "@components/input/input";
import Select, { SelectOption } from "@components/select/select";
import ToggleSwitch from "@components/toggle-switch/toggle-switch";
import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";
import { assessmentQuestionTypeOptions } from "@helpers/constants";
import { classNames } from "@helpers/css";

import {
  ASSESSMENT_QUESTION_SCALE_MAX,
  ASSESSMENT_QUESTION_SCALE_MIN,
  getAssessmentQuestionScale,
} from "../helpers";
import AssessmentQuestionCategoryPicker, {
  AssessmentQuestionCategoryPickerCategory,
} from "./assessment-question-category-picker";

export type AssessmentQuestion = {
  title: string;
  description: string | null;
  optionCount?: number;
  options?: (string | null)[];
  optionDescriptions?: (string | null)[];
  startValue?: number;
  endValue?: number;
  labels?: (string | null)[];
  labelDescriptions?: (string | null)[];
  questionType: AssessmentQuestionType;
  isCommentMandatory: boolean;
  responses: AssessmentQuestionResponses;
  responseVisibility: AssessmentQuestionResponseVisibility;
  categories: AssessmentQuestionCategoryPickerCategory[];
};

const AssessmentQuestionForm = ({
  canUpdate,
  proposedQuestion,
  isNew,
  hideAssessmentType,
  onChangeQuestion,
  answersExist,
}: {
  canUpdate: boolean;
  answersExist?: boolean;
  proposedQuestion: AssessmentQuestion;
  isNew: boolean;
  hideAssessmentType?: boolean;
  onChangeQuestion: (question: AssessmentQuestion) => void;
}) => {
  const label = useLabel();
  const scale = getAssessmentQuestionScale(proposedQuestion);
  const gridScaleClassName = getRatingScaleClassName(
    scale.scaleStartValue,
    scale.scaleEndValue
  );

  const handleChangeLabel =
    (labelIndexX: number): ChangeEventHandler<HTMLInputElement> =>
    (e) => {
      const labels = range(0, scale.scaleDiff).map((x) => {
        if (x === labelIndexX) {
          return e.target.value;
        }
        return proposedQuestion.labels?.[x] || "";
      });
      onChangeQuestion({ ...proposedQuestion, labels });
    };
  const handleChangeLabelDescription =
    (labelIndexX: number): ChangeEventHandler<HTMLTextAreaElement> =>
    (e) => {
      const labelDescriptions = range(0, scale.scaleDiff).map((x) => {
        if (x === labelIndexX) {
          return e.target.value;
        }
        return proposedQuestion.labelDescriptions?.[x] || "";
      });
      onChangeQuestion({ ...proposedQuestion, labelDescriptions });
    };
  const handleChangeOption =
    (optionIndexX: number): ChangeEventHandler<HTMLInputElement> =>
    (e) => {
      const options = range(0, proposedQuestion.optionCount).map((x) => {
        if (x === optionIndexX) {
          return e.target.value;
        }
        return proposedQuestion.options?.[x] || "";
      });
      onChangeQuestion({ ...proposedQuestion, options });
    };
  const handleChangeOptionDescription =
    (optionIndexX: number): ChangeEventHandler<HTMLTextAreaElement> =>
    (e) => {
      const optionDescriptions = range(0, proposedQuestion.optionCount).map(
        (x) => {
          if (x === optionIndexX) {
            return e.target.value;
          }
          return proposedQuestion.optionDescriptions?.[x] || "";
        }
      );
      onChangeQuestion({ ...proposedQuestion, optionDescriptions });
    };

  const handleChangeCategories = (
    newCategories: AssessmentQuestionCategoryPickerCategory[]
  ) => {
    onChangeQuestion({ ...proposedQuestion, categories: newCategories });
  };

  const handleChangeStartValue: ChangeEventHandler<HTMLInputElement> = (e) => {
    const startValue = parseInt(e.target.value);
    onChangeQuestion({ ...proposedQuestion, startValue });
  };
  const handleChangeEndValue: ChangeEventHandler<HTMLInputElement> = (e) => {
    const endValue = parseInt(e.target.value);
    onChangeQuestion({ ...proposedQuestion, endValue });
  };
  const handleChangeOptionCount: ChangeEventHandler<HTMLInputElement> = (e) => {
    const optionCount = parseInt(e.target.value);
    onChangeQuestion({ ...proposedQuestion, optionCount });
  };
  const handleChangeDescription = (description: string) => {
    onChangeQuestion({ ...proposedQuestion, description });
  };
  const handleChangeMandatoryComment = (isCommentMandatory: boolean) => {
    onChangeQuestion({ ...proposedQuestion, isCommentMandatory });
  };
  const handleChangeResponsesType = (
    opt: SelectOption<AssessmentQuestionResponses>
  ) => {
    onChangeQuestion({ ...proposedQuestion, responses: opt.value });
  };
  const handleChangeResponseVisibility = (
    opt: SelectOption<AssessmentQuestionResponseVisibility>
  ) => {
    onChangeQuestion({ ...proposedQuestion, responseVisibility: opt.value });
  };
  const handleChangeQuestionType = (
    opt: SelectOption<AssessmentQuestionType>
  ) => {
    onChangeQuestion({
      ...proposedQuestion,
      isCommentMandatory:
        opt.value === AssessmentQuestionType.Text
          ? false
          : proposedQuestion.isCommentMandatory,
      startValue: opt.value === AssessmentQuestionType.Range ? 1 : undefined,
      endValue: opt.value === AssessmentQuestionType.Range ? 5 : undefined,
      labels:
        opt.value === AssessmentQuestionType.Range
          ? range(0, 5).map(() => "")
          : undefined,
      labelDescriptions:
        opt.value === AssessmentQuestionType.Range
          ? range(0, 5).map(() => "")
          : undefined,
      optionCount:
        opt.value === AssessmentQuestionType.Multichoice ? 2 : undefined,
      options:
        opt.value === AssessmentQuestionType.Multichoice
          ? ["Option 1", "Option 2"]
          : undefined,
      optionDescriptions:
        opt.value === AssessmentQuestionType.Multichoice ? ["", ""] : undefined,
      questionType: opt.value,
    });
  };

  const responsesOptions = useMemo(
    () =>
      compact([
        {
          label: "Everyone",
          description: "Everyone answers this question",
          value: AssessmentQuestionResponses.Both,
        },
        proposedQuestion.responseVisibility !==
          AssessmentQuestionResponseVisibility.HiddenFromSubject && {
          label: "Self assessment only",
          description: `Only the subject of the ${label(
            "review"
          )} answers this question`,
          value: AssessmentQuestionResponses.SelfAssessmentOnly,
        },
        {
          label: "Exclude self assessment",
          description: `The ${label(
            "review"
          )} subject does not answer this question`,
          value: AssessmentQuestionResponses.ExcludeSelfAssessment,
        },
      ]),
    [proposedQuestion, label]
  );
  const responseVisibilityOptions = useMemo(
    () =>
      compact([
        {
          label: "Everyone",
          description: `Responses to this question are visible by anyone who can access the ${label(
            "review"
          )}`,
          value: AssessmentQuestionResponseVisibility.All,
        },
        proposedQuestion.responses !==
          AssessmentQuestionResponses.SelfAssessmentOnly && {
          label: "Hide from subject",
          description: `Responses to this question, except for their own, are hidden from the subject of the ${label(
            "review"
          )}`,
          value: AssessmentQuestionResponseVisibility.HiddenFromSubject,
        },
      ]),
    [proposedQuestion, label]
  );

  return (
    <div className="w-full flex flex-col gap-6">
      {answersExist && (
        <div className="p-4 border rounded border-yellow-300 bg-yellow-50 mt-2 text-gray-600 text-sm">
          <div className="font-bold">
            Use caution when updating this question
          </div>
          <div>
            Answers already exist for this question in its current form and any
            substantial changes could affect the historical trend analysis
          </div>
        </div>
      )}
      <div className="max-w-92 flex flex-col gap-2">
        <div className="text-gray-500 text-xs uppercase font-semibold">
          Title
        </div>
        <Input
          disabled={!canUpdate}
          aria-label="Question title input"
          className="w-full"
          value={proposedQuestion.title}
          onChange={(e) =>
            onChangeQuestion({ ...proposedQuestion, title: e.target.value })
          }
        />
      </div>
      <div className="max-w-92 flex flex-col gap-2">
        <div className="text-gray-500 text-xs uppercase font-semibold">
          Description
        </div>
        <TextareaWysiwyg
          editable={canUpdate}
          className="mt-1 bg-white"
          value={proposedQuestion.description}
          deps={[proposedQuestion.title]}
          onChangeValue={handleChangeDescription}
        />
      </div>
      <div className="max-w-92 flex flex-col gap-2">
        <div className="text-gray-500 text-xs uppercase font-semibold">
          Categories
        </div>
        <AssessmentQuestionCategoryPicker
          canChange
          canCreate
          categories={proposedQuestion.categories}
          onAddCategory={(category) => {
            handleChangeCategories(
              proposedQuestion.categories.concat([category])
            );
          }}
          onRemoveCategory={(category) => {
            handleChangeCategories(
              proposedQuestion.categories.filter(
                (c) => c.title !== category.title
              )
            );
          }}
        />
      </div>
      <div className="max-w-92 flex flex-col gap-2">
        <div className="text-gray-500 text-xs uppercase font-semibold">
          Responders
        </div>
        <Select
          width="full"
          disabled={!canUpdate || answersExist}
          value={proposedQuestion.responses}
          options={responsesOptions}
          onChange={handleChangeResponsesType}
          aria-label="Resposnes type select"
        />
        {(!canUpdate || answersExist) &&
          responsesOptions.find(
            (opt) => opt.value === proposedQuestion.responses
          ) && (
            <div className="text-sm text-gray-400">
              {
                responsesOptions.find(
                  (opt) => opt.value === proposedQuestion.responses
                )?.description
              }
            </div>
          )}
      </div>
      <div className="max-w-92 flex flex-col gap-2">
        <div className="text-gray-500 text-xs uppercase font-semibold">
          Response visibility
        </div>
        <Select
          width="full"
          disabled={
            !canUpdate ||
            answersExist ||
            proposedQuestion.responses ===
              AssessmentQuestionResponses.SelfAssessmentOnly
          }
          value={proposedQuestion.responseVisibility}
          options={responseVisibilityOptions}
          onChange={handleChangeResponseVisibility}
          aria-label="Resposne visibility type select"
        />
        {(!canUpdate || answersExist) &&
          responseVisibilityOptions.find(
            (opt) => opt.value === proposedQuestion.responseVisibility
          ) && (
            <div className="text-sm text-gray-400">
              {
                responseVisibilityOptions.find(
                  (opt) => opt.value === proposedQuestion.responseVisibility
                )?.description
              }
            </div>
          )}
      </div>
      <div className="max-w-92 flex flex-col gap-2">
        <div className="text-gray-500 text-xs uppercase font-semibold">
          Question type
        </div>
        <Select
          disabled={!isNew || !canUpdate}
          width="full"
          value={proposedQuestion.questionType}
          options={assessmentQuestionTypeOptions}
          onChange={handleChangeQuestionType}
          aria-label="Question type select"
        />
      </div>

      {proposedQuestion.questionType !== AssessmentQuestionType.Text && (
        <div className="max-w-92 flex gap-4">
          <div className="text-gray-500 text-xs uppercase font-semibold">
            Mandatory comment
          </div>
          <ToggleSwitch
            disabled={!canUpdate || answersExist}
            checked={proposedQuestion.isCommentMandatory}
            onChange={handleChangeMandatoryComment}
          />
        </div>
      )}

      {proposedQuestion.questionType === AssessmentQuestionType.Range && (
        <>
          <div className="flex flex-col gap-2">
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Scale
            </div>
            <div className="mt-1 flex gap-6">
              <Input
                disabled={!canUpdate || answersExist}
                aria-label="Question start value input"
                className="w-18"
                type="number"
                min={ASSESSMENT_QUESTION_SCALE_MIN}
                max={Math.min(
                  ASSESSMENT_QUESTION_SCALE_MAX,
                  scale.scaleEndValue - 1
                )}
                step={1}
                pattern="\d*"
                value={proposedQuestion.startValue}
                placeholder="Start value"
                onChange={handleChangeStartValue}
              />
              <Input
                disabled={!canUpdate || answersExist}
                aria-label="Question end value input"
                className="w-18"
                type="number"
                min={Math.max(
                  ASSESSMENT_QUESTION_SCALE_MIN,
                  scale.scaleStartValue + 1
                )}
                max={ASSESSMENT_QUESTION_SCALE_MAX}
                step={1}
                pattern="\d*"
                value={proposedQuestion.endValue}
                placeholder="End value"
                onChange={handleChangeEndValue}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Labels
            </div>
            <div className={classNames("mt-1 gap-2 grid", gridScaleClassName)}>
              {range(
                proposedQuestion.startValue ?? 1,
                (proposedQuestion.endValue ?? 5) + 1
              ).map((value, indexX) => (
                <span
                  key={value}
                  className="flex flex-col gap-1 items-center border bg-gray-100 rounded p-1"
                >
                  <input
                    disabled={!canUpdate || answersExist}
                    type="text"
                    value={proposedQuestion.labels?.[indexX] || ""}
                    onChange={handleChangeLabel(indexX)}
                    placeholder="Label"
                    className="border rounded text-sm py-0.5 px-1 w-full"
                  />
                  <textarea
                    disabled={!canUpdate || answersExist}
                    value={proposedQuestion.labelDescriptions?.[indexX] || ""}
                    onChange={handleChangeLabelDescription(indexX)}
                    placeholder="Tooltip"
                    className="resize-none h-24 border rounded text-sm py-0.5 px-1 w-full"
                  />
                  <MdRadioButtonUnchecked className="h-4 w-4 text-gray-500" />
                </span>
              ))}
            </div>
          </div>
        </>
      )}
      {proposedQuestion.questionType === AssessmentQuestionType.Multichoice && (
        <>
          <div className="flex flex-col gap-2">
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Options
            </div>
            <div className="mt-1">
              <Input
                disabled={!canUpdate || answersExist}
                aria-label="Question option count"
                className="w-18"
                type="number"
                min={1}
                step={1}
                pattern="\d*"
                value={proposedQuestion.optionCount ?? 1}
                placeholder="Options"
                onChange={handleChangeOptionCount}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2 max-w-92">
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Option text
            </div>
            <div className="mt-1 flex flex-col gap-2">
              {range(1, (proposedQuestion.optionCount ?? 1) + 1).map(
                (value, indexX) => (
                  <div key={value} className="flex items-start">
                    <div className="font-bold text-lg mr-4">{value}.</div>
                    <div className="flex-1 flex flex-col gap-2">
                      <input
                        disabled={!canUpdate || answersExist}
                        type="text"
                        value={proposedQuestion.options?.[indexX] || ""}
                        onChange={handleChangeOption(indexX)}
                        placeholder="Option"
                        className="border rounded text-sm py-0.5 px-1 w-full"
                      />
                      <textarea
                        disabled={!canUpdate || answersExist}
                        value={
                          proposedQuestion.optionDescriptions?.[indexX] || ""
                        }
                        onChange={handleChangeOptionDescription(indexX)}
                        placeholder="Description"
                        className="resize-none h-24 border rounded text-sm py-0.5 px-1 w-full"
                      />
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AssessmentQuestionForm;
