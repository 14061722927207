import { Popover, PopoverButtonProps, Portal } from "@headlessui/react";
import { SelectorIcon, XIcon } from "@heroicons/react/outline";
import { compact } from "lodash";
import { ElementType, Ref, useState } from "react";
import { RiTeamLine } from "react-icons/ri";
import { TbBuilding } from "react-icons/tb";
import { usePopper } from "react-popper";

import Avatar from "@components/avatar/avatar";
import {
  classNames,
  inputBorderClassName,
  inputFocusClassName,
} from "@helpers/css";

import UserComboboxList, {
  UserComboboxOption,
  UserComboboxOptionType,
} from "./user-combobox-list";

export type UserComboboxChildrenProps = {
  value: UserComboboxOption | null;
  setReferenceElement?: Ref<HTMLButtonElement>;
  clearable: boolean;
  disabled?: boolean;
  onClearValue?: () => void;
  onClickButton?: () => void;
};

export default function UserCombobox({
  disabled = false,
  width = "",
  value,
  options = [],
  loading = false,
  query = "",
  clearable = false,
  portal = true,
  onClearValue,
  onChangeValue,
  onChangeQuery,
  onClickButton,
  showEmail = false,
  name = "user-combobox",
  searchPlaceholder = "Search users...",
  placeholder = "Pick a user",
  className = "",
  inputClassName = "",
  children,
  ...props
}: Omit<PopoverButtonProps<ElementType>, "children"> & {
  disabled?: boolean;
  width?: string;
  value: UserComboboxOption | null;
  options: UserComboboxOption[];
  loading?: boolean;
  query: string;
  clearable?: boolean;
  portal?: boolean;
  onClearValue?: () => void;
  onChangeValue: (
    value: UserComboboxOption,
    metadata: { name?: string }
  ) => void;
  onChangeQuery: (query: string) => void;
  onClickButton?: () => void;
  showEmail?: boolean;
  name?: string;
  searchPlaceholder?: string;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  children?: React.FC<UserComboboxChildrenProps>;
}) {
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
  });
  const filteredOptions =
    query === ""
      ? options
      : options.filter(
          (option) =>
            (option.type === UserComboboxOptionType.USER &&
              (option.name.toLowerCase().includes(query.toLowerCase()) ||
                option.email?.toLowerCase().includes(query.toLowerCase()))) ||
            (option.type === UserComboboxOptionType.TEAM &&
              option.title.toLowerCase().includes(query.toLowerCase())) ||
            (option.type === UserComboboxOptionType.ORG &&
              option.name.toLowerCase().includes(query.toLowerCase()))
        );
  const validOptions = compact(filteredOptions);

  const handleChange = (close: () => void) => (option: UserComboboxOption) => {
    close();
    onChangeValue(option, { name });
  };

  const popoverButtonLabel = value
    ? value.type === UserComboboxOptionType.USER ||
      value.type === UserComboboxOptionType.ORG
      ? value.name
      : value.title
    : placeholder;

  return (
    <Popover className="relative" {...props}>
      {children ? (
        children({
          clearable,
          onClearValue,
          value,
          onClickButton,
          setReferenceElement,
          disabled,
        })
      ) : (
        <div
          className={classNames(
            "flex justify-between bg-white rounded-md",
            inputBorderClassName,
            inputFocusClassName,
            inputClassName
          )}
        >
          <Popover.Button
            className={classNames(
              "pl-3 pr-2 py-2 flex items-center gap-2 w-full text-sm text-gray-800",
              "min-w-0", // prevent child el with flex-1 to overflow and will add ellipsis in that case
              disabled && "opacity-50",
              className
            )}
            disabled={disabled}
            onClick={onClickButton}
            ref={setReferenceElement}
          >
            {value ? (
              value.type === UserComboboxOptionType.USER ? (
                <Avatar user={value} size="5" />
              ) : value.type === UserComboboxOptionType.ORG ? (
                <TbBuilding className="w-5 h-5" />
              ) : (
                <RiTeamLine className="w-5 h-5" />
              )
            ) : null}
            <div
              className={classNames(
                "flex-1 text-left truncate overflow-hidden",
                popoverButtonLabel === placeholder && "text-gray-400"
              )}
            >
              {popoverButtonLabel}
            </div>
            {!clearable && <SelectorIcon className="h-4 w-4" />}
          </Popover.Button>
          {clearable && onClearValue && (
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClearValue();
              }}
              className="px-1 py-1 flex items-center text-gray-600"
              aria-label="Combobox clear button"
            >
              <span className="px-1 py-1 rounded hover:bg-gray-100">
                <XIcon className="h-4 w-4" />
              </span>
            </button>
          )}
        </div>
      )}

      <Portal key={"user-combobox"}>
        <Popover.Panel
          aria-label="User combobox popover"
          className="absolute z-dropdown bg-white border rounded-md shadow-md"
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {({ close, open }) => (
            <UserComboboxList
              close={close}
              open={open}
              onChange={handleChange}
              width={width}
              onChangeQuery={onChangeQuery}
              placeholder={searchPlaceholder}
              loading={loading}
              validOptions={validOptions}
              showEmail={showEmail}
              value={value}
              query={query}
            />
          )}
        </Popover.Panel>
      </Portal>
    </Popover>
  );
}
