import { useQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { Dialog } from "@headlessui/react";
import { useState } from "react";
import { usePopper } from "react-popper";
import {
  AlignGoalMutationMutation,
  AlignGoalMutationMutationVariables,
  AlignmentGoalFragmentFragment,
  GetGoalToAlignQueryQuery,
  GetGoalToAlignQueryQueryVariables,
  GoalAlignmentDialogGoalFragmentFragment,
} from "types/graphql-schema";
import { GoalScope } from "types/graphql-schema";

import alignGoalMutation from "@apps/artifact-sidebar/graphql/align-goal-mutation";
import getArtifactSidebarQuery from "@apps/artifact-sidebar/graphql/get-artifact-sidebar-query";
import { refreshAlignmentOfGoalIds } from "@apps/goal-alignment/helpers";
import useLabel from "@apps/use-label/use-label";
import { successNotificationVar } from "@cache/cache";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames, popoverClassName } from "@helpers/css";
import { isGoalArtifactNode } from "@helpers/helpers";

import GoalAlignmentPopoverContent from "./components/goal-alignment-dialog-content";
import getGoalToAlignQuery from "./graphql/get-goal-to-align-query";

const indexedGoalScopes = [
  GoalScope.Organization,
  GoalScope.Team,
  GoalScope.Personal,
];

const GoalAlignmentPickerDialogWithExistingGoal = ({
  artifactId,
  alignmentType,
  externalReferenceElement,
  onClose,
}: {
  artifactId: number;
  alignmentType: "child" | "parent";
  externalReferenceElement: HTMLElement;
  onClose: () => void;
}) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = usePopper(
    externalReferenceElement,
    popperElement,
    { placement: "right-start" }
  );

  const label = useLabel();
  const [updateGoal] = useMutation<
    AlignGoalMutationMutation,
    AlignGoalMutationMutationVariables
  >(alignGoalMutation);
  const {
    data: dataArtifact,
    loading: loadingArtifact,
    error: errorArtifact,
  } = useQuery<GetGoalToAlignQueryQuery, GetGoalToAlignQueryQueryVariables>(
    getGoalToAlignQuery,
    {
      skip: !artifactId,
      variables: { artifactId: artifactId || -1 },
      onError: onNotificationErrorHandler(),
    }
  );
  const goal =
    dataArtifact?.artifact && isGoalArtifactNode(dataArtifact.artifact)
      ? dataArtifact.artifact
      : undefined;

  const indexOfCurrentGoal = indexedGoalScopes.findIndex(
    (scope) => goal && isGoalArtifactNode(goal) && scope === goal.scope
  );
  const allowedGoalScopes =
    alignmentType === "parent"
      ? indexedGoalScopes.slice(0, indexOfCurrentGoal + 1)
      : indexedGoalScopes.slice(indexOfCurrentGoal);

  const errorMatches = [
    {
      match: "Cannot create parent loops",
      title: `You cannot select this ${label(
        "goal"
      )} as it would create an infinite loop.`,
    },
  ];

  const handleSucceedAlignment = (
    goalToRefreshIds: (number | null | undefined | false)[]
  ) => {
    successNotificationVar({
      title: `${label("goal", { capitalize: true })} alignment saved.`,
      timeout: 1000,
    });
    refreshAlignmentOfGoalIds(goalToRefreshIds);
    onClose();
  };

  const handleSelectGoal = (
    selectedGoal:
      | GoalAlignmentDialogGoalFragmentFragment
      | AlignmentGoalFragmentFragment
  ) => {
    if (!goal) return;
    if (goal.__typename !== "GoalArtifactNode") return;
    if (selectedGoal.__typename !== "GoalArtifactNode") return;
    if (!allowedGoalScopes.includes(selectedGoal.scope)) return;
    if (selectedGoal.id === goal.id) return;

    // If we align goal to a new parent:
    // - we have to refresh the old parent goal to show the goal removed
    // - we have the refresh the new parent goal to show the goal added
    // If we align the selected goal as a child of goal
    // - we have to refresh the child goals of goal
    // - we have to refresh the child goals of the old parent of the selected goal
    const goalIdsToRefresh = [
      goal.parentGoalId,
      goal.id,
      selectedGoal.id,
      selectedGoal.parentGoalId,
    ];
    if (alignmentType === "parent") {
      updateGoal({
        variables: {
          goalId: goal.id,
          parentGoalId: selectedGoal.id,
        },
        onError: onNotificationErrorHandler(errorMatches),
        onCompleted: () => {
          handleSucceedAlignment(goalIdsToRefresh);
        },
        refetchQueries: [getArtifactSidebarQuery],
      });
    } else {
      updateGoal({
        variables: {
          goalId: selectedGoal.id,
          parentGoalId: goal.id,
        },
        onError: onNotificationErrorHandler(errorMatches),
        onCompleted: () => {
          handleSucceedAlignment(goalIdsToRefresh);
        },
        refetchQueries: [getArtifactSidebarQuery],
      });
    }
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      className="fixed z-modal inset-0 overflow-y-auto text-gray-800"
    >
      <Dialog.Overlay className="fixed inset-0" />
      <div
        className={classNames(
          "rounded-lg bg-white text-left shadow-xl w-112 h-144 overflow-y-scroll",
          popoverClassName
        )}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        {loadingArtifact ? (
          <div className="p-4 gap-6 flex flex-col">
            <Loading />
          </div>
        ) : (
          !loadingArtifact &&
          !errorArtifact && (
            <GoalAlignmentPopoverContent
              goal={goal}
              alignmentType={alignmentType}
              allowedGoalScopes={allowedGoalScopes}
              onSelectGoal={handleSelectGoal}
            />
          )
        )}
      </div>
    </Dialog>
  );
};

export default GoalAlignmentPickerDialogWithExistingGoal;
