import { isArray, range } from "lodash";
import {
  CareerTrackRoleType,
  CompetencyAppliesTo,
  OrganizationCompetencyFragment,
  SaveOrgWideCareerTrackMutationVariables,
} from "types/graphql-schema";

import { assertEdgesNonNull } from "@helpers/helpers";

import { CareerTrackFormRole } from "./career-track-form/career-track-form";

export const roleLevelIterator = (levelCount: number) =>
  range(1, levelCount + 1);

export const stickyColWidth = "w-60 min-w-60 max-w-60";
export const roleColWidth = "w-92 max-w-92 min-w-92";
export const emptyRoleColWidth = "w-32 max-w-32 min-w-32";

/**
# get_role_filter(), resolved_criteria() [from career.models.Competency on backend]
# and doesCompetencyApplyToCareerTrack() [in career-track-from on frontend] are all
# used to determine whether a competency is applicable to a career track and any changes
# to one should be reflected in the others
*/
export const doesCompetencyApplyToCareerTrack = (
  competency: OrganizationCompetencyFragment,
  roles: CareerTrackFormRole[],
  careerTrackData: Omit<SaveOrgWideCareerTrackMutationVariables, "roles">,
  careerTrackId?: number | null,
  associatedTeam?: { id: number } | null
) => {
  const competencyAppliesToRoleType =
    competency.appliesToManagementRoles || competency.appliesToIcRoles;

  const competencyAppliesToMgmt =
    competency.appliesToManagementRoles &&
    (roles.length === 0 ||
      roles.some((role) => role.roleType === CareerTrackRoleType.Management));
  const competencyAppliesToIc =
    competency.appliesToIcRoles &&
    (roles.length === 0 ||
      roles.some((role) => role.roleType === CareerTrackRoleType.Ic));

  const appliesToTeamIds = assertEdgesNonNull(competency.appliesToTeams).map(
    (team) => team.id
  );
  const appliesToCareerTrackIds = assertEdgesNonNull(
    competency.appliesToCareerTracks
  ).map((track) => track.id);
  const hasSpecificTrackFilters =
    appliesToCareerTrackIds.length > 0 || appliesToTeamIds.length > 0;
  const competencyAppliesToTeam =
    associatedTeam && appliesToTeamIds.includes(associatedTeam.id);
  const competencyAppliesToCareerTrack =
    careerTrackId && appliesToCareerTrackIds.includes(careerTrackId);
  const competencyManuallyAdded =
    careerTrackData.additionalCompetencies &&
    isArray(careerTrackData.additionalCompetencies) &&
    careerTrackData.additionalCompetencies.includes(competency.id);

  if (
    competencyManuallyAdded ||
    competency.appliesTo === CompetencyAppliesTo.AllRoles
  ) {
    return true;
  }

  return (
    (competencyAppliesToRoleType || hasSpecificTrackFilters) &&
    (!competencyAppliesToRoleType ||
      competencyAppliesToIc ||
      competencyAppliesToMgmt) &&
    (!hasSpecificTrackFilters ||
      competencyAppliesToTeam ||
      competencyAppliesToCareerTrack)
  );
};
