import { ComplianceProgramParticipantStatus } from "types/graphql-schema";

import { classNames } from "@helpers/css";

const ProgramProgressPill = ({
  className,
  status,
  label,
}: {
  className?: string;
  status: ComplianceProgramParticipantStatus;
  label?: string;
}) => {
  const iconClassName =
    status === ComplianceProgramParticipantStatus.NotApplicable
      ? "border-gray-300"
      : status === ComplianceProgramParticipantStatus.NotStarted
      ? "border-amber-400"
      : status === ComplianceProgramParticipantStatus.InProgress
      ? "border-blue-400 "
      : "border-emerald-400 bg-emerald-400";
  const statusClassName =
    status === ComplianceProgramParticipantStatus.NotApplicable
      ? "bg-gray-100 text-gray-500"
      : status === ComplianceProgramParticipantStatus.NotStarted
      ? "bg-amber-100 text-amber-500"
      : status === ComplianceProgramParticipantStatus.InProgress
      ? "bg-blue-100 text-blue-500"
      : "bg-emerald-100 text-emerald-500";
  const defaultStatusLabel =
    status === ComplianceProgramParticipantStatus.NotStarted
      ? "Not Started"
      : status === ComplianceProgramParticipantStatus.InProgress
      ? "In Progress"
      : status === ComplianceProgramParticipantStatus.Complete
      ? "Complete"
      : "Not Applicable";
  const statusLabel = label || defaultStatusLabel;
  return (
    <div className="flex">
      <span
        className={classNames(
          "flex items-center gap-2 px-2 py-1 text-xs break-keep rounded-full text-gray-600",
          statusClassName,
          className
        )}
      >
        <div
          className={classNames(
            "w-2 h-2 border-2 rounded-full relative",
            iconClassName
          )}
        >
          {status === ComplianceProgramParticipantStatus.InProgress && (
            <div className="absolute top-1/2 left-0 w-full h-1/2 bg-blue-400"></div>
          )}
        </div>
        {statusLabel}
      </span>
    </div>
  );
};

export default ProgramProgressPill;
