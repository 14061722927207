import { AnimatePresence } from "framer-motion";
import { groupBy, range, uniq } from "lodash";
import moment from "moment";
import { forwardRef } from "react";
import { MeetingDialogFragmentFragment } from "types/graphql-schema";

import Button, { buttonTheme } from "@components/button/button";
import Loading from "@components/loading/loading";
import MotionDiv from "@components/motion/motion-div";
import { classNames } from "@helpers/css";
import { assertEdgesNonNullWithStringId } from "@helpers/helpers";

import OverviewMeeting from "./overview-meeting";

enum MeetingType {
  ALL,
  ONE_ON_ONE,
  GROUP,
}

type Props = {
  showSearchResults: boolean;
  hasMoreSearchResults?: boolean;
  meetings: MeetingDialogFragmentFragment[];
  loading: boolean;
  participantFilter: number | null;
  meetingTypeFilter: MeetingType;
  hideEditingButtons?: boolean;
  meetingDisplayedRange: {
    start: string;
    end: string;
  };
  isShowingWeekends: boolean;
  loadMore: "past" | "future" | "search" | null;
  onChangeLoadMore: (loadMore: "past" | "future" | "search" | null) => void;
  onMeetingUpdated: (data: { meeting: MeetingDialogFragmentFragment }) => void;
  onClickMeetingLink?: (meetingId: number, meetingGroupId: number) => void;
};

const OverviewMeetingList = forwardRef<HTMLDivElement, Props>(
  (
    {
      showSearchResults,
      hasMoreSearchResults = false,
      meetings,
      loading,
      participantFilter,
      meetingTypeFilter,
      meetingDisplayedRange,
      hideEditingButtons = false,
      loadMore,
      isShowingWeekends,
      onChangeLoadMore,
      onMeetingUpdated,
      onClickMeetingLink,
    },
    todayRef
  ) => {
    const meetingsByStartDate: Record<string, MeetingDialogFragmentFragment[]> =
      groupBy(meetings, (meeting) =>
        moment(meeting.startDatetime).format("YYYY-MM-DD")
      );
    const daysInRange = showSearchResults
      ? uniq(Object.keys(meetingsByStartDate))
      : range(
          moment(meetingDisplayedRange.end).diff(
            meetingDisplayedRange.start,
            "days"
          )
        )
          .map((dayIndex) => {
            return moment(meetingDisplayedRange.start)
              .add(dayIndex, "days")
              .format("YYYY-MM-DD");
          })
          .filter((day) => {
            return (
              isShowingWeekends || ![6, 7].includes(moment(day).isoWeekday())
            );
          });

    const currentYear = moment().format("YYYY");
    return (
      <>
        {!showSearchResults && (
          <div className="flex justify-center">
            <Button
              className="text-xs"
              theme={buttonTheme.text}
              disabled={loading}
              onClick={() => {
                onChangeLoadMore("past");
              }}
            >
              {loadMore === "past" ? (
                <Loading size={4} />
              ) : (
                `Load previous week`
              )}
            </Button>
          </div>
        )}
        <div className="flex flex-col gap-8">
          <AnimatePresence>
            {daysInRange.map((date) => {
              const isToday = moment(date).isSame(moment(), "day");
              const dayMeetings = meetingsByStartDate[date]
                ? meetingsByStartDate[date]
                    .filter((meeting) => {
                      if (participantFilter === null) {
                        return true;
                      }
                      const participantIds = meeting.participants
                        ? assertEdgesNonNullWithStringId(
                            meeting.participants
                          ).map((participantNode) => participantNode.user?.id)
                        : [];
                      return participantIds.includes(participantFilter);
                    })
                    .filter((meeting) => {
                      if (meetingTypeFilter === MeetingType.ALL) {
                        return true;
                      }
                      return (
                        (meeting.meetingGroup?.isFormalOneonone &&
                          meetingTypeFilter === MeetingType.ONE_ON_ONE) ||
                        (!meeting.meetingGroup?.isFormalOneonone &&
                          meetingTypeFilter === MeetingType.GROUP)
                      );
                    })
                : [];
              const pastMeetings = dayMeetings.filter((meeting) => {
                return meeting.endDatetime
                  ? moment().isAfter(meeting.endDatetime)
                  : false;
              });
              const futureMeetings = dayMeetings.filter((meeting) => {
                return meeting.endDatetime
                  ? moment().isSameOrBefore(meeting.endDatetime)
                  : false;
              });
              return (
                <MotionDiv
                  key={date}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <AnimatePresence>
                    <div
                      className={classNames(
                        "text-sm font-semibold mb-4",
                        isToday && "text-green-700",
                        !isToday && "text-gray-700"
                      )}
                      ref={isToday ? todayRef : undefined}
                      key="date"
                    >
                      {moment(date)
                        .format(
                          isToday
                            ? "MMMM D, YYYY ([Today])"
                            : "MMM D, YYYY (dddd)"
                        )
                        .replace(`, ${currentYear}`, "")}
                    </div>
                    <div key="meetings">
                      {dayMeetings.length === 0 && (
                        <MotionDiv
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          className="p-2 @2xl/overview-meetings:p-4 border-t text-sm text-gray-400"
                        >
                          No meetings
                        </MotionDiv>
                      )}
                      <div>
                        {pastMeetings.map((meeting) => {
                          return (
                            <OverviewMeeting
                              key={meeting.id}
                              meeting={meeting}
                              onMeetingUpdated={onMeetingUpdated}
                              hideEditingButtons={hideEditingButtons}
                              onClickMeetingLink={onClickMeetingLink}
                            />
                          );
                        })}
                      </div>
                      <div>
                        {futureMeetings.map((meeting) => {
                          return (
                            <OverviewMeeting
                              key={meeting.id}
                              meeting={meeting}
                              onMeetingUpdated={onMeetingUpdated}
                              hideEditingButtons={hideEditingButtons}
                              onClickMeetingLink={onClickMeetingLink}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </AnimatePresence>
                </MotionDiv>
              );
            })}
          </AnimatePresence>
        </div>
        {hasMoreSearchResults && showSearchResults && (
          <div className="flex justify-center mt-2">
            <Button
              className="text-xs"
              theme={buttonTheme.text}
              disabled={loading}
              onClick={() => {
                onChangeLoadMore("search");
              }}
            >
              {loadMore === "search" ? <Loading size={4} /> : `Load more`}
            </Button>
          </div>
        )}
        {!showSearchResults && (
          <div className="flex justify-center">
            <Button
              className="text-xs"
              theme={buttonTheme.text}
              disabled={loading}
              onClick={() => {
                onChangeLoadMore("future");
              }}
            >
              {loadMore === "future" ? <Loading size={4} /> : `Load next week`}
            </Button>
          </div>
        )}
      </>
    );
  }
);

export default OverviewMeetingList;
