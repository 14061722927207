import { gql } from "@apollo/client";

export default gql`
  query getRecurringMeetingPaginationQuery($meetingId: Int!) {
    beforeMeeting: meeting(beforeMeetingId: $meetingId) {
      id
      meetingGroupId
      startDatetime
    }
    afterMeeting: meeting(afterMeetingId: $meetingId) {
      id
      meetingGroupId
      startDatetime
    }
  }
`;
