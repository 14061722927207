import { gql } from "@apollo/client";

export default gql`
  mutation duplicateGoalMutation(
    $goalId: Int!
    $parentGoalId: Int
    $title: String
    $startDate: Date
    $dueDate: Date
    $resetProgress: Boolean
    $duplicateKeyResults: Boolean
  ) {
    duplicateGoal(
      goalId: $goalId
      parentGoalId: $parentGoalId
      title: $title
      startDate: $startDate
      dueDate: $dueDate
      resetProgress: $resetProgress
      duplicateKeyResults: $duplicateKeyResults
    ) {
      duplicatedGoal {
        id
      }
    }
  }
`;
