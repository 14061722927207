import { gql } from "@apollo/client";

import ArtifactComponentFragment from "@apps/artifact/graphql/artifact-component-fragment";
import CommonFeedbackArtifactFragment from "@graphql/common-feedback-artifact-fragment";
import CommonGoalArtifactFragment from "@graphql/common-goal-artifact-fragment";
import CommonRecognitionArtifactFragment from "@graphql/common-recognition-artifact-fragment";
import KeyResultFragment from "@graphql/key-result-fragment";

import GoalTeamsFragment from "./goal-teams-fragment";

export const GoalArtifactSidebarFragment = gql`
  ${KeyResultFragment}
  ${GoalTeamsFragment}
  ${CommonGoalArtifactFragment}
  ${ArtifactComponentFragment}
  fragment GoalArtifactSidebarFragment on GoalArtifactNode {
    id
    ...CommonGoalArtifactFragment
    childGoals {
      edges {
        node {
          ...ArtifactComponentFragment
        }
      }
    }
    parentGoal {
      ...ArtifactComponentFragment
    }
    parentGoalId
    topLevelGoal {
      ...ArtifactComponentFragment
    }
    kpi {
      id
      title
      currentMeasurement {
        id
        measurement
      }
    }
    owners {
      totalCount
      edges {
        node {
          id
          avatar
          name
        }
      }
    }
    contributors {
      edges {
        node {
          id
          avatar
          name
        }
      }
    }
    keyResults(first: $keyResultLimit) {
      edges {
        node {
          ...KeyResultFragment
        }
      }
    }
    ...GoalTeamsFragment
  }
`;

export default gql`
  ${GoalArtifactSidebarFragment}
  ${CommonRecognitionArtifactFragment}
  ${CommonFeedbackArtifactFragment}
  query getArtifactSidebarQuery($artifactId: Int!, $keyResultLimit: Int!) {
    artifact(artifactId: $artifactId) {
      __typename
      id
      title
      description
      artifactType
      created
      updated
      creator {
        id
        name
      }
      canDelete {
        permission
      }
      canUpdate {
        permission
      }
      canRead {
        permission
      }
      websocketToken
      description
      organization {
        id
      }
      topics {
        edges {
          connectionCreated
          connectionCreator {
            id
            name
          }
          node {
            id
            title
            meeting {
              id
              meetingGroupId
            }
          }
        }
      }
      relatedArtifacts {
        edges {
          connectionCreated
          connectionCreator {
            id
            name
          }
          node {
            id
            title
            artifactType
            canDelete {
              permission
            }
          }
        }
      }
      ... on ActionItemArtifactNode {
        isComplete
        actionItemState
        dueDate
        assignee {
          id
          avatar
          name
        }
      }
      ... on RecognitionArtifactNode {
        ...CommonRecognitionArtifactFragment
      }
      ... on FeedbackArtifactNode {
        ...CommonFeedbackArtifactFragment
      }
      ... on GoalArtifactNode {
        ...GoalArtifactSidebarFragment
        owners {
          edges {
            node {
              id
              name
              avatar
            }
          }
        }
        contributors {
          edges {
            node {
              id
              name
              avatar
            }
          }
        }
      }
      ... on DecisionArtifactNode {
        decision
        decisionState
        isDraft
      }
      ... on DocumentArtifactNode {
        documentVisibility
      }
      ... on RatingArtifactNode {
        rating {
          id
          title
          description
          startValue
          endValue
          labels
        }
        createdInMeeting {
          id
          meetingGroupId
        }
        canAnswer
        canViewAnswers
        answers {
          totalCount
          edges {
            node {
              id
              integerAnswer
              creator {
                id
              }
            }
          }
        }
      }
    }
  }
`;
