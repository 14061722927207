import { gql } from "@apollo/client";

export default gql`
  query getFirstMeetingOfMeetingGroupQuery(
    $meetingGroupId: Int!
    $today: DateTime!
  ) {
    meetingGroup(meetingGroupId: $meetingGroupId) {
      id
      nextMeetings: meetings(
        first: 1
        startDatetime_Gte: $today
        status: confirmed
        ignored: false
      ) {
        edges {
          node {
            id
          }
        }
      }
      lastMeetings: meetings(
        last: 1
        orderBy: "start_datetime"
        status: confirmed
        ignored: false
      ) {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;
