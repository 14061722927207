import moment from "moment";
import { RiCollapseDiagonal2Line, RiExpandDiagonal2Line } from "react-icons/ri";
import { AlignmentGoalFragmentFragment } from "types/graphql-schema";
import { DateRangeEnum } from "types/topicflow";

import useLabel from "@apps/use-label/use-label";
import useUiPreferenceCache from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import { currentOrganizationVar } from "@cache/cache";
import { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import { Select, SelectOption } from "@components/select/select";
import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import {
  UserComboboxOption,
  UserComboboxOptionType,
} from "@components/user-combobox/user-combobox-list";
import { dateRangeToDateArray } from "@helpers/helpers";

import { AllDateRangeEnum } from "./goal-alignment";

type GoalAlignmentDateRangeType = AllDateRangeEnum | DateRangeEnum;

const GoalAlignmentHeader = ({
  selectedDateRange,
  onChangeSelectedDateRange,
  selectedEntity,
  onChangeSelectedEntity,
  onClickCollapseAll,
  onClickExpandAll,
  focusedGoal,
  loading,
}: {
  focusedGoal?: AlignmentGoalFragmentFragment;
  selectedDateRange: GoalAlignmentDateRangeType;
  onChangeSelectedDateRange: (newDateRange: GoalAlignmentDateRangeType) => void;
  selectedEntity: UserComboboxOption | null;
  onChangeSelectedEntity: (newEntity: null | UserComboboxOption) => void;
  onClickCollapseAll: () => void;
  onClickExpandAll: () => void;
  loading: boolean;
}) => {
  const label = useLabel();
  const link = useLink();
  const currentOrganization = currentOrganizationVar();
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();

  const dateRangeOptions = Object.values(DateRangeEnum).map((dateRange) => ({
    value: dateRange,
    label: label(dateRange, { capitalize: true }),
    selected: selectedDateRange === dateRange,
    description: dateRangeToDateArray({
      range: dateRange,
      quarterStartMonth: currentOrganization.quarterStartMonth,
    })
      .map((date) => moment(date).format("ll"))
      .join(" - "),
  }));

  const allDateRangeOptions = [
    {
      value: AllDateRangeEnum.all,
      label: "Anytime",
      selected: selectedDateRange === null,
    },
    ...dateRangeOptions,
  ];
  const handleChangeDateRange = (
    option: SelectOption<GoalAlignmentDateRangeType>
  ) => {
    const search = new URLSearchParams(window.location.search);
    if (option.value !== DateRangeEnum.thisQuarter) {
      search.set("dateRange", option.value);
    } else {
      search.delete("dateRange");
    }
    link.replace({
      pathname: "/goal-alignment",
      search: search.toString(),
    });
    onChangeSelectedDateRange(option.value);
  };

  const handleChangeEntity = (newEntity?: UserComboboxOption) => {
    const search = new URLSearchParams(window.location.search);
    if (newEntity) {
      search.set("contextId", String(newEntity.id));
      search.set("contextType", newEntity.type);
    } else {
      search.delete("contextId");
      search.delete("contextType");
    }
    link.replace({
      pathname: "/goal-alignment",
      search: search.toString(),
    });
    onChangeSelectedEntity(newEntity || null);
  };

  const { options, setQuery, query } = useUserComboboxQuery({
    types: [UserComboboxOptionType.TEAM, UserComboboxOptionType.USER],
    selected: selectedEntity,
  });

  return (
    <div className="flex items-center gap-6 text-sm flex-wrap justify-between">
      <div className="flex items-center gap-6">
        {!focusedGoal && (
          <UserCombobox
            options={options}
            value={selectedEntity}
            onChangeValue={handleChangeEntity}
            query={query}
            onChangeQuery={setQuery}
            placeholder={`Filter...`}
            clearable={selectedEntity !== null}
            onClearValue={handleChangeEntity}
            className="min-w-36"
          />
        )}
        {!focusedGoal && (
          <Select<GoalAlignmentDateRangeType>
            options={allDateRangeOptions}
            value={selectedDateRange}
            onChange={handleChangeDateRange}
          />
        )}
        <label className="flex items-center gap-1 tracking-tight shrink-0">
          <input
            type="checkbox"
            checked={uiPreferenceCache.objectiveAlignmentIsShowingKeyResults}
            onChange={(e) =>
              saveUiPreference({
                objectiveAlignmentIsShowingKeyResults: e.target.checked,
              })
            }
          />
          Show {label("key result", { pluralize: true })}
        </label>
        {!focusedGoal && (
          <label className="flex items-center gap-1 tracking-tight shrink-0">
            <input
              type="checkbox"
              checked={uiPreferenceCache.objectiveAlignmentIsShowingClosed}
              onChange={(e) =>
                saveUiPreference({
                  objectiveAlignmentIsShowingClosed: e.target.checked,
                })
              }
            />
            Show closed {label("goal", { pluralize: true })}
          </label>
        )}
        {loading && (
          <div className="flex items-center gap-2 text-sm text-gray-500">
            <Loading mini size={5} /> Updating alignment
          </div>
        )}
      </div>
      <div className="flex items-center gap-6">
        <button
          className="flex items-center gap-1.5 tracking-tight -mx-2 px-2 py-1 rounded-md hover:bg-gray-100"
          onClick={onClickExpandAll}
        >
          <RiExpandDiagonal2Line className="w-4 h-4 text-gray-500" />
          Expand all
        </button>
        <button
          className="flex items-center gap-1.5 tracking-tight -mx-2 px-2 py-1 rounded-md hover:bg-gray-100"
          onClick={onClickCollapseAll}
        >
          <RiCollapseDiagonal2Line className="w-4 h-4 text-gray-500" />
          Collapse all
        </button>
      </div>
    </div>
  );
};

export default GoalAlignmentHeader;
