import { gql } from "@apollo/client";

export default gql`
  fragment PreviousAssessment on AssessmentNode {
    id
    submittedDatetime
    complianceProgram {
      id
      title
    }
    target {
      id
      name
      avatar
    }
    responder {
      id
      name
      avatar
    }
    template {
      id
      assessmentType
    }
  }
  query getPreviousAssessments(
    $organizationId: Int!
    $responderId: Int
    $targetId: Int
    $after: String
    $orderBy: String
  ) {
    assessments(
      first: 10
      after: $after
      responderId: $responderId
      targetId: $targetId
      state: submitted
      organizationId: $organizationId
      orderBy: $orderBy
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...PreviousAssessment
        }
      }
    }
  }
`;
