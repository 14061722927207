import { DotsHorizontalIcon, PencilIcon } from "@heroicons/react/outline";
import { compact } from "lodash";
import moment from "moment";
import { ReactEventHandler, useCallback, useMemo, useState } from "react";
import { MeetingsOverviewMeetingFragment } from "types/graphql-schema";

import MeetingDialog, {
  meetingDialogAction,
} from "@apps/meeting-dialog/meeting-dialog";
import MeetingDropdownMenu from "@apps/meeting-dropdown-menu/meeting-dropdown-menu";
import MeetingVideoConferenceButton from "@apps/meeting-new/components/meeting/video-conference-button";
import { currentUserVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Avatars from "@components/avatar/avatars";
import Button, { buttonTheme } from "@components/button/button";
import Layout from "@components/layout/layout";
import AppLink, { useLink } from "@components/link/link";
import MotionDiv from "@components/motion/motion-div";
import Tooltip from "@components/tooltip/tooltip";
import { classNames } from "@helpers/css";
import {
  assertEdgesNonNullWithStringId,
  getUrl,
  isTailwindLgActive,
} from "@helpers/helpers";

const OverviewMeeting = ({
  meeting,
  onMeetingUpdated,
  hideEditingButtons = false,
  onClickMeetingLink,
}: {
  meeting: MeetingsOverviewMeetingFragment;
  onMeetingUpdated: (data: {
    meeting: MeetingsOverviewMeetingFragment;
  }) => void;
  hideEditingButtons?: boolean;
  onClickMeetingLink?: (meetingId: number, meetingGroupId: number) => void;
}) => {
  const link = useLink();
  const [isShowingMeetingDialog, setIsShowingMeetingDialog] = useState(false);
  const currentUser = currentUserVar();
  const participants = assertEdgesNonNullWithStringId(meeting.participants);
  const otherParticipant = participants.find(
    (participant) => participant.user && participant.user.id !== currentUser.id
  );
  const isPast = meeting.endDatetime && moment().isAfter(meeting.endDatetime);
  const isToday = moment(meeting.startDatetime).isSame(moment(), "day");

  const isClickable = meeting.canRead?.permission;

  const meetingUrl = useMemo(
    () =>
      getUrl({
        meetingGroupId: meeting.meetingGroupId,
        meetingId: meeting.id,
      }),
    [meeting]
  );

  const handleCloseMeetingDialog = useCallback(() => {
    setIsShowingMeetingDialog(false);
  }, []);

  const handleOpenMeetingEditDialog: ReactEventHandler<HTMLButtonElement> =
    useCallback((evt) => {
      evt.stopPropagation();
      setIsShowingMeetingDialog(true);
    }, []);

  const handleGoToMeeting = useCallback(() => {
    link.redirect(meetingUrl);
  }, [link, meetingUrl]);

  const handleClickMeetingLink = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement>) => {
      if (onClickMeetingLink && meeting.meetingGroup?.id) {
        if (isTailwindLgActive()) {
          e.stopPropagation();
          e.preventDefault();
          onClickMeetingLink(meeting.id, meeting.meetingGroup.id);
        } else {
          handleGoToMeeting();
        }
      }
    },
    [
      meeting.id,
      onClickMeetingLink,
      meeting.meetingGroup?.id,
      handleGoToMeeting,
    ]
  );

  return (
    <>
      {isShowingMeetingDialog && (
        <MeetingDialog
          meetingId={meeting.id}
          meetingGroupId={meeting.meetingGroup?.id}
          onClose={handleCloseMeetingDialog}
          defaultAction={meetingDialogAction.edit}
          onSaved={onMeetingUpdated}
        />
      )}
      <MotionDiv
        className={classNames(
          "border-t border-gray-300 text-sm",
          isToday && !isPast && "first:border-t-green-700 first:border-t-2",
          isPast && "text-gray-400",
          !isPast && "text-gray-500",
          isClickable && "hover:bg-gray-50 cursor-pointer",
          meeting.draft && "bg-yellow-50"
        )}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        role={isClickable ? "button" : undefined}
        onClick={
          isClickable && onClickMeetingLink
            ? handleClickMeetingLink
            : isClickable && !onClickMeetingLink
            ? handleGoToMeeting
            : undefined
        }
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <div className="m-2 @2xl/overview-meetings:m-4 w-[100px] @2xl/overview-meetings:w-[110px]">
              {`${moment(meeting.startDatetime)
                .format("h:mm")
                .replace(":00", "")} - ${moment(meeting.endDatetime)
                .format("h:mma")
                .replace(":00", "")}`}
            </div>
            <AppLink
              className={classNames(
                "flex-1 m-2 @2xl/overview-meetings:m-4 font-medium select-none",
                meeting.canRead?.permission && "hover:underline",
                !isPast && "text-gray-700"
              )}
              to={meetingUrl}
              disabled={!isClickable}
              onClick={handleClickMeetingLink}
            >
              {meeting.canRead?.permission ? (
                `${meeting.title}`
              ) : (
                <Tooltip text={meeting.canRead?.reason}>
                  <span>{meeting.title}</span>
                </Tooltip>
              )}
            </AppLink>
          </div>

          <div
            className="flex items-center gap-4"
            // stop click events bubbling up and triggering a page switch to the meeting
            onClick={(evt) => evt.stopPropagation()}
          >
            {meeting.draft && (
              <div className="px-1.5 py-1 bg-yellow-200 text-yellow-600 font-semibold rounded-md">
                Draft
              </div>
            )}
            {meeting.videoConferenceUrl &&
              meeting.startDatetime &&
              moment().isBetween(
                moment(meeting.startDatetime).subtract(5, "minutes"),
                meeting.endDatetime
              ) && (
                <div className="text-sm text-gray-700 flex">
                  <MeetingVideoConferenceButton
                    textClassName="hidden"
                    iconClassName="h-5 w-5"
                    meeting={meeting}
                    className="hover:text-gray-800 hover:bg-black/5 px-1.5 py-1 rounded"
                  />
                </div>
              )}
            <div
              className={classNames(
                "flex items-center justify-end w-[60px]",
                hideEditingButtons && "pr-4"
              )}
            >
              {meeting.meetingGroup?.isFormalOneonone && otherParticipant ? (
                <Avatar user={otherParticipant.user} size={5} />
              ) : (
                <Avatars
                  max={3}
                  users={compact(participants.map(({ user }) => user))}
                  className={classNames(
                    "flex items-center w-[58px] justify-end",
                    participants.length === 1 ? "w-5" : "pl-2" // otherwise single avatar gets squished
                  )}
                  avatarClassName={classNames(
                    "contrast-100 rounded-full -ml-1 w-5 h-5"
                  )}
                  extraClassName="w-6 h-6 rounded-full -ml-1.5 text-2xs flex items-center justify-center z-1 bg-gray-100 text-gray-600"
                  size="5"
                />
              )}
            </div>

            {!hideEditingButtons && (
              <div className="flex items-center gap-1 pr-4">
                <Button
                  theme={buttonTheme.text}
                  icon
                  className={classNames(
                    "text-inherit",
                    Layout.headerIconButtonClassName
                  )}
                  onClick={handleOpenMeetingEditDialog}
                >
                  <PencilIcon className="h-5 w-5" />
                </Button>
                {meeting.meetingGroup && meeting.canRead?.permission && (
                  <MeetingDropdownMenu
                    meeting={meeting}
                    meetingGroup={meeting.meetingGroup}
                    isContextMenu={true}
                    className={Layout.headerIconButtonClassName}
                    size="5"
                    onMeetingUpdated={onMeetingUpdated}
                  />
                )}
                {!meeting.canRead?.permission && (
                  <Tooltip text={meeting.canRead?.reason}>
                    <span>
                      <button
                        className={classNames(
                          "flex items-center text-gray-400",
                          Layout.headerIconButtonClassName
                        )}
                        disabled
                      >
                        <DotsHorizontalIcon className="h-5 w-5" />
                      </button>
                    </span>
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        </div>
      </MotionDiv>
    </>
  );
};

export default OverviewMeeting;
