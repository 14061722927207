import { gql } from "@apollo/client";

export default gql`
  query getOrganizationCareerTracks($organizationId: Int!, $teamId: Int) {
    careerTracks(organizationId: $organizationId, teamId: $teamId) {
      edges {
        node {
          id
          title
          roles {
            edges {
              node {
                id
                title
                level
                roleType
              }
            }
          }
        }
      }
    }
  }
`;
