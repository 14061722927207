import { gql } from "@apollo/client";

export const UserComboboxTeamOptionFragment = gql`
  fragment UserComboboxTeamOption on TeamNode {
    id
    title
  }
`;

export default gql`
  ${UserComboboxTeamOptionFragment}
  query getUserComboboxOptionsQuery(
    $userId: Int!
    $hasUserId: Boolean!
    $teamId: Int!
    $hasTeamId: Boolean!
    $search: String
    $limitUser: Int!
    $organizationId: Int
    $excludeUserIds: [Int]
    $excludeTeamIds: [Int]
  ) {
    users(
      orderBy: "affinity"
      first: $limitUser
      search: $search
      organization: $organizationId
      excludeIds: $excludeUserIds
    ) {
      edges {
        node {
          id
          name
          firstName
          email
          avatar
        }
      }
    }
    teams(
      organization: $organizationId
      search: $search
      first: 10
      excludeIds: $excludeTeamIds
      orderBy: "title"
    ) {
      edges {
        node {
          ...UserComboboxTeamOption
        }
      }
    }
    team(teamId: $teamId) @include(if: $hasTeamId) {
      ...UserComboboxTeamOption
    }
    user(userId: $userId) @include(if: $hasUserId) {
      id
      name
      firstName
      email
      avatar
    }
  }
`;
