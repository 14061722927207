import { ArrowLeftIcon } from "@heroicons/react/outline";
import { PropsWithChildren } from "react";

import Button, { buttonTheme } from "@components/button/button";
import Layout from "@components/layout/layout";
import { classNames } from "@helpers/css";

type Props = {
  title: string;
  small?: boolean;
  backUrl?: string;
};

const Heading: React.FC<PropsWithChildren<Props>> = ({
  title,
  children,
  small = false,
  backUrl,
}) => {
  return (
    <div className="flex items-center gap-2">
      {backUrl && (
        <Button
          theme={buttonTheme.text}
          icon
          className={classNames(
            "text-inherit",
            Layout.headerIconButtonClassName
          )}
          to={backUrl}
        >
          <ArrowLeftIcon className="h-4 w-4" />
        </Button>
      )}
      {small ? (
        <h2 className="text-2xl font-medium text-gray-800">{title}</h2>
      ) : (
        <header className="pb-4 sm:pb-6">
          <h1 className="capitalize text-3xl font-bold text-gray-800">
            {title}
          </h1>
          {children}
        </header>
      )}
    </div>
  );
};

export default Heading;
