import { useQuery } from "@apollo/client";
import moment from "moment";
import { Redirect, useParams } from "react-router-dom";
import {
  GetFirstMeetingOfMeetingGroupQueryQuery,
  GetFirstMeetingOfMeetingGroupQueryQueryVariables,
} from "types/graphql-schema";

import { currentUserVar } from "@cache/cache";
import Container from "@components/container/container";
import GraphqlError from "@components/error/graphql-error";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

import getFirstMeetingOfMeetingGroupQuery from "./graphql/get-first-meeting-of-meeting-group-query";

const MeetingGroupRoutes = () => {
  const params = useParams<{ meetingGroupId: string }>();
  const currentUser = currentUserVar();
  const meetingGroupId = parseInt(params.meetingGroupId);

  const today = moment().startOf("day").format();
  const { loading, error, data } = useQuery<
    GetFirstMeetingOfMeetingGroupQueryQuery,
    GetFirstMeetingOfMeetingGroupQueryQueryVariables
  >(getFirstMeetingOfMeetingGroupQuery, {
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-first",
    variables: { meetingGroupId, today },
    onError: onNotificationErrorHandler(),
  });

  const closestMeetingId = // pick meeting closest to today
    data?.meetingGroup?.nextMeetings?.edges?.[0]?.node?.id || // or pick the next meeting
    data?.meetingGroup?.lastMeetings?.edges?.[0]?.node?.id; // or the last meeting that ever happened (case of past one of event)

  // Renders
  if (loading || !data) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }
  if (closestMeetingId) {
    return <Redirect to={`/meeting/${meetingGroupId}/${closestMeetingId}`} />;
  }
  if (error || !data || !closestMeetingId) {
    return (
      <Container>
        <GraphqlError
          error={new Error("Meeting could not be loaded")}
          whatDidNotWork="We can't show you this meeting right now."
          descriptionList={[
            "The meeting might have been deleted by you or another participant.",
            "The meeting is a draft meeting and is only accessible by its creator.",
            `Or, make sure you're logged in using the email address matching the meeting invite. You're currently logged in as ${currentUser.emails.join(
              ", "
            )}.`,
            "Or, an unexpected error might have occurred on our side. We have been notified. Try again by refreshing the page.",
          ]}
        />
      </Container>
    );
  }
};

export default MeetingGroupRoutes;
