import { gql } from "@apollo/client";

export const OrganizationCareerTrackFragment = gql`
  fragment OrganizationCareerTrackFragment on CareerTrackNode {
    id
    title
    team {
      id
      title
    }
    roles {
      edges {
        node {
          id
          title
          level
          roleType
          assignedUsers {
            edges {
              node {
                id
                name
                email
                avatar
              }
            }
          }
          responsibilities {
            edges {
              node {
                id
                content
              }
            }
          }
          competencies {
            edges {
              node {
                id
                title
                appliesTo
                criteriaUniqueness
                criteria {
                  edges {
                    node {
                      id
                      content
                      level
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default gql`
  ${OrganizationCareerTrackFragment}
  query getOrganizationCareerTrack($careerTrackId: Int!) {
    careerTrack(id: $careerTrackId) {
      ...OrganizationCareerTrackFragment
    }
  }
`;
