import { Dialog } from "@headlessui/react";
import { useState } from "react";
import { usePopper } from "react-popper";
import { AlignmentGoalFragmentFragment } from "types/graphql-schema";
import { GoalAlignmentDialogGoalFragmentFragment } from "types/graphql-schema";
import { GoalScope } from "types/graphql-schema";

import { classNames, popoverClassName } from "@helpers/css";

import GoalAlignmentPopoverContent from "./components/goal-alignment-dialog-content";

const GoalAlignmentPickerDialog = ({
  allowedGoalScopes,
  alignmentType,
  externalReferenceElement,
  onClose,
  onSelectGoal,
}: {
  allowedGoalScopes: GoalScope[];
  alignmentType: "child" | "parent";
  externalReferenceElement: HTMLElement;
  onClose: () => void;
  onSelectGoal: (
    selectedGoal:
      | GoalAlignmentDialogGoalFragmentFragment
      | AlignmentGoalFragmentFragment
  ) => void;
}) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = usePopper(
    externalReferenceElement,
    popperElement,
    { placement: "right-start" }
  );

  return (
    <Dialog
      open={true}
      onClose={onClose}
      className="fixed z-modal inset-0 overflow-y-auto text-gray-800"
    >
      <Dialog.Overlay className="fixed inset-0" />
      <div
        className={classNames(
          "rounded-lg bg-white text-left shadow-xl w-112 h-144 overflow-y-scroll",
          popoverClassName
        )}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        <GoalAlignmentPopoverContent
          alignmentType={alignmentType}
          allowedGoalScopes={allowedGoalScopes}
          onSelectGoal={onSelectGoal}
        />
      </div>
    </Dialog>
  );
};

export default GoalAlignmentPickerDialog;
