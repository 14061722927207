import aroundcoLogo from "@static/img/aroundco.png";
import googleMeetLogo from "@static/img/meet-64.png";
import teamsLogo from "@static/img/teams.svg";
import wherebyLogo from "@static/img/whereby.png";
import zoomLogo from "@static/img/zoom-64.png";
import moment from "moment";
import { OnlineVideoConference } from "types/graphql-schema";

import Tooltip from "@components/tooltip/tooltip";
import { onlineVideoConferenceType } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { getAssetUrl } from "@helpers/helpers";

const MeetingVideoConferenceButton = ({
  meeting,
  className = "",
  textClassName = "",
  iconClassName = "",
  compact = false,
}: {
  meeting: {
    startDatetime?: string | null;
    videoConferenceType?: OnlineVideoConference | null;
    videoConferenceUrl?: string | null;
  };
  className?: string;
  textClassName?: string;
  iconClassName?: string;
  compact?: boolean;
}) => {
  const isCompleted = moment(meeting.startDatetime).isBefore(moment(), "day");
  const label =
    meeting.videoConferenceType === onlineVideoConferenceType.google
      ? compact
        ? "Meet"
        : "Google Meet"
      : meeting.videoConferenceType === onlineVideoConferenceType.zoom
      ? "Zoom"
      : meeting.videoConferenceType === onlineVideoConferenceType.teams
      ? compact
        ? "Teams"
        : "Microsoft Teams"
      : meeting.videoConferenceType === onlineVideoConferenceType.whereby
      ? "Whereby"
      : meeting.videoConferenceType === onlineVideoConferenceType.aroundco
      ? "Around"
      : compact
      ? "Conference"
      : "Video Conference";

  return meeting.videoConferenceUrl && !isCompleted ? (
    <Tooltip text={`Open ${label}`}>
      <button
        className={classNames("flex items-center gap-2 px-2.5 py-2", className)}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (meeting.videoConferenceUrl) {
            window.open(meeting.videoConferenceUrl, "_blank");
          }
        }}
      >
        {(meeting.videoConferenceType === onlineVideoConferenceType.google && (
          <img
            src={getAssetUrl(googleMeetLogo)}
            className={classNames("h-4 w-4 shrunk-0", iconClassName)}
            alt="Google Meet"
          />
        )) ||
          (meeting.videoConferenceType === onlineVideoConferenceType.zoom && (
            <img
              src={getAssetUrl(zoomLogo)}
              className={classNames("h-4 w-4 shrunk-0", iconClassName)}
              alt="Zoom"
            />
          )) ||
          (meeting.videoConferenceType === onlineVideoConferenceType.teams && (
            <img
              src={getAssetUrl(teamsLogo)}
              className={classNames("h-4 w-4 shrunk-0", iconClassName)}
              alt="Microsoft Teams"
            />
          )) ||
          (meeting.videoConferenceType ===
            onlineVideoConferenceType.whereby && (
            <img
              src={getAssetUrl(wherebyLogo)}
              className={classNames("h-4 w-4 shrunk-0", iconClassName)}
              alt="Whereby"
            />
          )) ||
          (meeting.videoConferenceType ===
            onlineVideoConferenceType.aroundco && (
            <img
              src={getAssetUrl(aroundcoLogo)}
              className={classNames("h-4 w-4 shrunk-0", iconClassName)}
              alt="Around"
            />
          ))}
        <span className={classNames(textClassName)}>{label}</span>
      </button>
    </Tooltip>
  ) : null;
};

export default MeetingVideoConferenceButton;
