import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  GetDashboardSelectedUserQueryQuery,
  GetDashboardSelectedUserQueryQueryVariables,
} from "types/graphql-schema";

import Calendar from "@apps/calendar/calendar";
import getDashboardSelectedUserQuery from "@apps/dashboard-new/graphql/get-dashboard-selected-user-query";
import useUiPreferenceCache, {
  MeetingsViewEnum,
} from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import { currentUserVar } from "@cache/cache";
import AppError from "@components/error/error";
import Loading from "@components/loading/loading";
import useDocumentTitle from "@components/use-document-title/use-document-title";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import {
  UserComboboxOption,
  UserComboboxOptionType,
} from "@components/user-combobox/user-combobox-list";
import { assertNonNull } from "@helpers/helpers";

import MeetingsOverview from "./components/meetings-overview";
import MeetingsLayout from "./layout";

const MeetingsPage = ({ view }: { view?: MeetingsViewEnum }) => {
  const { uiPreferenceCache } = useUiPreferenceCache();
  const materializedView = view ?? uiPreferenceCache.meetingsView;
  useDocumentTitle(
    materializedView === MeetingsViewEnum.calendar ? "Calendar" : "Meetings"
  );
  const currentUser = currentUserVar();

  const { userId } = useParams<{ userId: string | undefined }>();
  const selectedUserId = userId ? parseInt(userId) : currentUser.id;

  const { data, loading } = useQuery<
    GetDashboardSelectedUserQueryQuery,
    GetDashboardSelectedUserQueryQueryVariables
  >(getDashboardSelectedUserQuery, {
    variables: { userId: selectedUserId },
    onError: onNotificationErrorHandler(),
  });
  const selectedUser: UserComboboxOption = useMemo(
    () => ({ type: UserComboboxOptionType.USER, ...assertNonNull(data?.user) }),
    [data]
  );

  // RENDER
  return !data && loading ? (
    <div className="mt-10">
      <Loading />
    </div>
  ) : !selectedUser ? (
    <AppError title="User cannot be found" />
  ) : (
    <MeetingsLayout view={materializedView} selectedUser={selectedUser}>
      {({ onChangeSidebarMeetingId }) => (
        <>
          {materializedView === MeetingsViewEnum.overview && (
            <MeetingsOverview
              selectedUser={selectedUser}
              onClickMeetingLink={onChangeSidebarMeetingId}
            />
          )}
          {materializedView === MeetingsViewEnum.calendar && (
            <Calendar
              selectedUser={selectedUser}
              onClickMeetingLink={onChangeSidebarMeetingId}
            />
          )}
        </>
      )}
    </MeetingsLayout>
  );
};

export default MeetingsPage;
