import { classNames } from "@helpers/css";

import { emptyRoleColWidth } from "../helpers";

const EmptyCriteriaCell = () => (
  <td
    className={classNames(
      emptyRoleColWidth,
      "p-4 border align-center text-center align-middle bg-gray-50 text-gray-300"
    )}
  >
    No role
  </td>
);

export default EmptyCriteriaCell;
