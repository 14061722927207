import { gql } from "@apollo/client";

import { MeetingsOverviewMeeting } from "./meetings-overview-meeting-fragment";

export default gql`
  ${MeetingsOverviewMeeting}
  query getMeetingOverviewMeetingsQuery(
    $forUserId: Int!
    $dateRangeStart: DateTime
    $dateRangeEnd: DateTime
    $isOneonone: Boolean
    $searchQuery: String
    $orderBy: String
    $first: Int
    $after: String
  ) {
    calendar(
      forUserId: $forUserId
      ignored: false
      startDatetime_Gte: $dateRangeStart
      startDatetime_Lte: $dateRangeEnd
      orderBy: $orderBy
      isFormalOneonone: $isOneonone
      search: $searchQuery
      first: $first
      after: $after
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          meeting {
            ...MeetingsOverviewMeeting
          }
        }
      }
    }
    unscheduledMeetings: calendar(forUserId: $forUserId, unscheduled: true) {
      totalCount
      edges {
        node {
          id
          meeting {
            ...MeetingsOverviewMeeting
          }
        }
      }
    }
  }
`;
