import { useQuery } from "@apollo/client";
import { compact } from "lodash";
import uniqBy from "lodash/uniqBy";
import { useMemo, useState } from "react";
import {
  GetUserComboboxOptionsQueryQuery,
  GetUserComboboxOptionsQueryQueryVariables,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import cache, { currentOrganizationVar, currentUserVar } from "@cache/cache";
import useDebounce from "@components/use-debounce/use-debounce";
import { useNotificationError } from "@components/use-error/use-error";
import {
  UserComboboxOption,
  UserComboboxOptionType,
  UserComboboxOrgOption,
  UserComboboxTeamOption,
  UserComboboxUserOption,
} from "@components/user-combobox/user-combobox-list";
import { delay, graphqlNone } from "@helpers/constants";
import { assertEdgesNonNull } from "@helpers/helpers";

import getUserComboboxOptionsQuery, {
  UserComboboxTeamOptionFragment,
} from "../people-dropdown/get-user-combobox-options-query";

export const getCacheTeamOption = (teamId: number) => {
  const team = cache.readFragment<UserComboboxTeamOption>({
    id: cache.identify({ __typename: "TeamNode", id: teamId }),
    fragment: UserComboboxTeamOptionFragment,
  });
  return team;
};

const useUserComboboxQuery = ({
  queryOptions,
  selected,
  emptyOption,
  excludeUserIds = [],
  excludeTeamIds = [],
  types = [UserComboboxOptionType.USER],
  fetchOutsideCurrentOrganization = false,
}: {
  queryOptions?: {
    skip?: boolean;
  };
  emptyOption?: UserComboboxOption;
  selected?: UserComboboxOption | null;
  excludeUserIds?: number[];
  excludeTeamIds?: number[];
  types?: UserComboboxOptionType[];
  fetchOutsideCurrentOrganization?: boolean;
}) => {
  const { onError } = useNotificationError();
  const currentUser = currentUserVar();
  const currentOrganization = currentOrganizationVar();

  if (selected && !types.includes(selected.type)) {
    throw new Error(`UserComboBox selected type not allowed ${selected.type}`);
  }

  const initialOptions: UserComboboxOption[] = useMemo(() => {
    const managers: UserComboboxOption[] =
      currentUser && types.includes(UserComboboxOptionType.USER)
        ? assertEdgesNonNull(currentUser.managers).map((u) => ({
            type: UserComboboxOptionType.USER,
            ...u,
          }))
        : [];
    const directReports: UserComboboxOption[] =
      currentUser && types.includes(UserComboboxOptionType.USER)
        ? assertEdgesNonNull(currentUser.directReports).map((u) => ({
            type: UserComboboxOptionType.USER,
            ...u,
          }))
        : [];
    const currentUserOption: UserComboboxOption = {
      type: UserComboboxOptionType.USER,
      ...currentUser,
    };
    const favouriteGroups =
      currentUser?.favouritesGroups &&
      types.includes(UserComboboxOptionType.USER)
        ? assertEdgesNonNull(currentUser.favouritesGroups)
        : [];
    const favouriteUsers = favouriteGroups.reduce((memo, favouriteGroup) => {
      return [...memo, ...assertEdgesNonNull(favouriteGroup.users)];
    }, [] as BasicUser[]);
    const favouriteUserOptions: UserComboboxOption[] = favouriteUsers.map(
      (u) => ({ type: UserComboboxOptionType.USER, ...u })
    );

    const teams: UserComboboxTeamOption[] = types.includes(
      UserComboboxOptionType.TEAM
    )
      ? assertEdgesNonNull(currentUser.teams).map((team) => ({
          type: UserComboboxOptionType.TEAM,
          ...team,
        }))
      : [];
    const org: UserComboboxOrgOption = {
      type: UserComboboxOptionType.ORG,
      ...currentOrganization,
    };
    return uniqBy(
      compact([
        emptyOption,
        selected,
        types.includes(UserComboboxOptionType.USER) && currentUserOption,
        types.includes(UserComboboxOptionType.ORG) && org,
        ...managers,
        ...directReports,
        ...favouriteUserOptions,
        ...teams,
      ]),
      (opt) => `${opt.type}${opt.id}`
    );
  }, [currentUser, emptyOption, selected, types]);
  const userInitialOptions = initialOptions.filter(
    (option) => option.type === UserComboboxOptionType.USER
  );
  const [query, setQuery] = useState("");
  const [_options, setOptions] = useState<UserComboboxOption[]>(initialOptions);
  const debouncedQuery = useDebounce(query, delay.searchDebounce);

  // HOOKS
  const { loading } = useQuery<
    GetUserComboboxOptionsQueryQuery,
    GetUserComboboxOptionsQueryQueryVariables
  >(getUserComboboxOptionsQuery, {
    onError,
    notifyOnNetworkStatusChange: true,
    skip: queryOptions?.skip,
    variables: {
      organizationId: fetchOutsideCurrentOrganization
        ? null
        : currentOrganization.id,
      userId:
        selected?.type === UserComboboxOptionType.USER
          ? selected.id
          : graphqlNone,
      hasUserId:
        selected?.type === UserComboboxOptionType.USER &&
        !!selected.id &&
        !debouncedQuery,
      teamId:
        selected?.type === UserComboboxOptionType.TEAM
          ? selected.id
          : graphqlNone,
      hasTeamId:
        selected?.type === UserComboboxOptionType.TEAM &&
        !!selected.id &&
        !debouncedQuery,
      search: debouncedQuery,
      excludeUserIds,
      excludeTeamIds,
      // if no queries, we try to show a max of 10 initial user options
      limitUser: query ? 10 : Math.max(0, 10 - userInitialOptions.length),
    },
    onCompleted: (data) => {
      const selectedUser: UserComboboxUserOption | null = data?.user
        ? { type: UserComboboxOptionType.USER, ...data.user }
        : null;
      const users: UserComboboxUserOption[] =
        data?.users && types.includes(UserComboboxOptionType.USER)
          ? assertEdgesNonNull(data.users).map((u) => ({
              type: UserComboboxOptionType.USER,
              ...u,
            }))
          : [];
      const selectedTeam: UserComboboxTeamOption | null = data?.team
        ? { type: UserComboboxOptionType.TEAM, ...data.team }
        : null;
      const teams: UserComboboxTeamOption[] =
        data?.teams && types.includes(UserComboboxOptionType.TEAM)
          ? assertEdgesNonNull(data.teams).map((t) => ({
              type: UserComboboxOptionType.TEAM,
              ...t,
            }))
          : [];

      const newOptions = uniqBy(
        compact([
          selectedUser,
          selectedTeam,
          ...initialOptions,
          ...options,
          ...users,
          ...teams,
        ]),
        (opt) => `${opt.type}${opt.id}`
      );
      setOptions(newOptions);
    },
  });

  const optionsWithoutExcludedUsers = _options.filter(
    ({ type, id }) =>
      type === UserComboboxOptionType.ORG ||
      (type === UserComboboxOptionType.USER && !excludeUserIds.includes(id)) ||
      (type === UserComboboxOptionType.TEAM && !excludeTeamIds.includes(id))
  );
  const validOptions = optionsWithoutExcludedUsers.filter(({ type }) =>
    types.includes(type)
  );

  const options = validOptions;

  return {
    options,
    loading: loading || query !== debouncedQuery,
    query,
    setQuery,
  };
};

export default useUserComboboxQuery;
