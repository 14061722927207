import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";

import ArtifactSidebarRoute from "@apps/artifact-sidebar/artifact-sidebar-route";
import { artifactTypePaths } from "@apps/main/routes";
import TopicSidebar from "@apps/topic-sidebar/topic-sidebar";
import Layout from "@components/layout/layout";

import MeetingPage from "./components/meeting-page";

const MeetingRoutes = () => {
  const { path } = useRouteMatch();
  const params = useParams<{
    meetingId: string;
  }>();
  const meetingId = parseInt(params.meetingId);

  return (
    <Layout aria-label="Meeting page" className="bg-white">
      <Switch>
        <Route path={path}>
          <MeetingPage meetingId={meetingId} />
        </Route>
      </Switch>
      <Route path={`${path}/(${artifactTypePaths})/:artifactId`}>
        <ArtifactSidebarRoute />
      </Route>
      <Route path={`${path}/topic/:topicId`}>
        <TopicSidebar />
      </Route>
    </Layout>
  );
};

export default MeetingRoutes;
