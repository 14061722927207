import { gql } from "@apollo/client";

export default gql`
  mutation saveAssessment(
    $organizationId: Int
    $targetId: Int!
    $assessmentTemplateId: Int!
    $assessmentId: Int
    $complianceProgramId: Int
    $state: AssessmentState
    $answers: [AssessmentQuestionAnswerInput]
  ) {
    createOrUpdateAssessment(
      organizationId: $organizationId
      targetId: $targetId
      assessmentTemplateId: $assessmentTemplateId
      assessmentId: $assessmentId
      complianceProgramId: $complianceProgramId
      state: $state
      answers: $answers
    ) {
      assessment {
        id
      }
    }
  }
`;
